/**
 * @generated SignedSource<<29ecba8b9b09025e2f0a444992cc963a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type ResourceNodeShortcutCreateInput = {
  membershipId: string;
  resourceNodeRevisionId: string;
  rootTopicNodeRevisionId: string;
};
export type CpShortcutButtonCreateMutation$variables = {
  input: ResourceNodeShortcutCreateInput;
  locale?: Locale | null;
};
export type CpShortcutButtonCreateMutation$data = {
  readonly resourceNodeShortcutCreate: {
    readonly resourceNodeShortcut: {
      readonly _id: string;
      readonly icon: string | null;
      readonly id: string;
      readonly names: any;
      readonly resourceNode: {
        readonly revisionId: string;
        readonly type: string;
      };
    };
  } | null;
};
export type CpShortcutButtonCreateMutation = {
  response: CpShortcutButtonCreateMutation$data;
  variables: CpShortcutButtonCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v5 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpShortcutButtonCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResourceNodeShortcutCreatePayload",
        "kind": "LinkedField",
        "name": "resourceNodeShortcutCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceNodeShortcut",
            "kind": "LinkedField",
            "name": "resourceNodeShortcut",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "resourceNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpShortcutButtonCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ResourceNodeShortcutCreatePayload",
        "kind": "LinkedField",
        "name": "resourceNodeShortcutCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceNodeShortcut",
            "kind": "LinkedField",
            "name": "resourceNodeShortcut",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "resourceNode",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2535a1439c38c8764bf7f396621ed46",
    "id": null,
    "metadata": {},
    "name": "CpShortcutButtonCreateMutation",
    "operationKind": "mutation",
    "text": "mutation CpShortcutButtonCreateMutation(\n  $input: ResourceNodeShortcutCreateInput!\n  $locale: Locale\n) {\n  resourceNodeShortcutCreate(input: $input) {\n    resourceNodeShortcut {\n      _id\n      id\n      icon(locale: $locale)\n      names(locale: $locale)\n      resourceNode {\n        revisionId\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "927843440f6552f0e5f27cb1867e0c70";

export default node;
