import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - arrow-drop-down
const ArrowDropDown: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M9.313 13.313h13.375l-6.688 6.688z" />
    </Svg>
  )
}

export default ArrowDropDown
