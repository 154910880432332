import React, { useMemo } from "react"
import { ReactNotifications, Store, iNotification } from "react-notifications-component"

import useAppTheme from "hooks/useAppTheme"
import useScreenLayout from "hooks/useScreenLayout"

const NOTIFICATION_WIDTH = 400

export enum SnackbarIds {
  InactivityReminder = "inactivity-reminder",
  InactivityClearHistory = "inactivity-clear-history",
  InsecurePassword = "insecure-password",
}

/**
 * Renders a simple app-level notification with an optional action button.
 * If we need to create a native app, this will need to be wrapped in a `.common` file
 */
export const SnackbarProvider: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <ReactNotifications />
      {children}
    </React.Fragment>
  )
}

/**
 * Quasi-private export, not intended or direct use.
 */
export const useSnackbarImpl = (): {
  addSnackbar(notification: iNotification): string
  notificationWidth: number
  removeAllSnackbars: () => void
  removeSnackbar: (id: string) => void
} => {
  const { spacing } = useAppTheme()
  const { width } = useScreenLayout()

  const notificationWidth = useMemo(() => {
    return width < NOTIFICATION_WIDTH + spacing.double ? width - spacing.double : NOTIFICATION_WIDTH
  }, [spacing.double, width])

  return useMemo(
    () => ({
      addSnackbar: (notification) => Store.addNotification(notification),
      notificationWidth,
      removeAllSnackbars: () => Store.removeAllNotifications(),
      removeSnackbar: (id) => Store.removeNotification(id),
    }),
    [notificationWidth],
  )
}
