import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import { css, styles } from "../../services/Theme";
import PrText from "../primitives/PrText";
const localStyles = {
  headerLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
  `,
  headerLineAccent: css`
    border-bottom-width: 2px;
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    width: 100px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
  `
};

const CpSectionHeader = ({
  label
}) => {
  return <_StyledView $_css={styles.paddingDouble}>
      <_StyledPrText accessibilityRole="header" aria-label="3" children={label} $_css2={localStyles.headerLabel} />
      <_StyledView2 $_css3={localStyles.headerLineAccent} />
    </_StyledView>;
};

export default CpSectionHeader;

var _StyledView = _styled(View).withConfig({
  displayName: "CpSectionHeader___StyledView",
  componentId: "sc-1bwa3uw-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpSectionHeader___StyledPrText",
  componentId: "sc-1bwa3uw-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpSectionHeader___StyledView2",
  componentId: "sc-1bwa3uw-2"
})(["", ""], p => p.$_css3);