import React, { createContext, memo, useCallback, useEffect, useMemo, useState } from "react"

import CxPresentation from "contexts/CxPresentation"
import QlTopicToc from "query/QlTopicToc"
import { QlTopicToc_resourceNodeTreeQuery$data } from "query/__generated__/QlTopicToc_resourceNodeTreeQuery.graphql"
import { useBackendSupportedLocale } from "services/Intl"

interface TopicContextData {
  resourceId?: string
  rootRevisionId: string
  selectedRevisionId?: string
}

export interface TopicContextType extends TopicContextData {
  setDefaultNodeRevisionId?: (id: string) => void
}

/**
 * Contains information about the topic being displayed, and provisions the presentation context if necessary
 */
export const TopicContext = createContext<undefined | TopicContextType>(undefined)

/**
 * Provides common information about the node being viewed, including the TOC.
 */
const CxTopic_Provider: React.FC<
  TopicContextData & {
    children: React.ReactNode
    response?: null | QlTopicToc_resourceNodeTreeQuery$data
  }
> = ({ children, resourceId, response, rootRevisionId, selectedRevisionId }) => {
  const [defaultNodeRevisionId, setDefaultNodeRevisionId] = useState<string>()

  // When we open a topic our route will only have /topic/:resourceId, and we won't be able to fetch the default resource node revision id
  // So with this we can update the default node revision id in case we don't have :selectedRevisionId in the route
  const handleUpdateDefaultNodeRevisionId = useCallback((revisionId: undefined | string) => {
    setDefaultNodeRevisionId(revisionId)
  }, [])

  // Resets the default node revision id when we navigate away from the topic
  useEffect(() => {
    handleUpdateDefaultNodeRevisionId(undefined)
  }, [handleUpdateDefaultNodeRevisionId])

  const topicContextValues = useMemo<TopicContextType>(() => {
    return {
      resourceId,
      rootRevisionId,
      selectedRevisionId: selectedRevisionId || defaultNodeRevisionId,
      setDefaultNodeRevisionId,
    }
  }, [defaultNodeRevisionId, resourceId, rootRevisionId, selectedRevisionId])

  const provider = (
    <TopicContext.Provider value={topicContextValues}>{children}</TopicContext.Provider>
  )
  return response?.presentationToc ? (
    // Presentations pull extra data about the descendants for tracking completion, so it has its own context
    <CxPresentation
      currentRevisionId={selectedRevisionId || defaultNodeRevisionId || rootRevisionId}
      rootResourceNodeKey={response.presentationToc}
    >
      {provider}
    </CxPresentation>
  ) : (
    provider
  )
}

/**
 * Contains information about the topic being displayed
 */
const CxTopic: React.FC<TopicContextData & { children: React.ReactNode }> = ({
  children,
  rootRevisionId,
  ...topicParams
}) => {
  const locale = useBackendSupportedLocale()

  const tocVariables = useMemo(() => ({ locale, rootRevisionId }), [rootRevisionId, locale])

  return (
    <QlTopicToc variables={tocVariables}>
      {(response) => (
        <CxTopic_Provider response={response} rootRevisionId={rootRevisionId} {...topicParams}>
          {children}
        </CxTopic_Provider>
      )}
    </QlTopicToc>
  )
}

export default memo(CxTopic)
