import { useContext } from "react"

import { PatientSessionContext, PatientSessionType } from "contexts/CxPatientSession"

/**
 * Hook for accessing Patient Session context
 */
function usePatientSessionContext(): PatientSessionType {
  const patientSessionContext = useContext(PatientSessionContext)
  if (!patientSessionContext) throw new Error("Patient Session Context is required")

  return patientSessionContext
}

export default usePatientSessionContext
