import _styled from "styled-components";

/* eslint-disable no-restricted-imports */
import Color from "color";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Platform, Pressable, StyleSheet } from "react-native";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css } from "services/Theme";
const isWeb = Platform.OS === "web";
const rnStyles = StyleSheet.create({
  busy: isWeb ? {
    cursor: "wait"
  } : {},
  disabled: isWeb ? {
    cursor: "not-allowed"
  } : {},
  enabled: isWeb ? {
    cursor: "pointer"
  } : {},
  inactive: {}
});
const localStyles = {
  text: css`
    color: ${({
    theme
  }) => theme.buttonColors.text};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
    display: flex;
    user-select: none;
  `
};
/**
 * A basic text button with 3 style types - raised, outline and text.
 * The color is specified as one of the buttonColors from the Theme.
 * If you need something more special than text, just use Pressable.
 */

const PrButton = ({
  accessibilityRole = "button",
  accessibilityState,
  busy,
  children,
  color = "primary",
  disabled,
  inactive,
  onAuxClick,
  style,
  transparent = true,
  type = "raised",
  ...otherProps
}) => {
  const {
    borderRadii,
    buttonColors,
    buttonHeights,
    spacing
  } = useAppTheme(); // Button styles keyed by button type and state.

  const buttonStyles = useMemo(() => {
    const commonStyle = {
      alignItems: "center",
      borderRadius: borderRadii.default,
      flexDirection: "row",
      height: buttonHeights.large,
      justifyContent: "center",
      // NB: For some reason padding has super precedence that will override the passed style prop, but this works
      paddingBottom: spacing.single,
      paddingLeft: spacing.single,
      paddingRight: spacing.single,
      paddingTop: spacing.single
    };
    const fadedColor = Color(buttonColors[color]).alpha(0.2).string();
    const lightColor = Color(buttonColors[color]).lighten(0.2).string();
    return {
      outline: {
        default: { ...commonStyle,
          backgroundColor: transparent ? "transparent" : buttonColors.surface,
          borderColor: buttonColors[color],
          borderWidth: 1
        },
        hovered: { ...commonStyle,
          backgroundColor: fadedColor,
          borderColor: fadedColor,
          borderWidth: 1
        },
        pressed: { ...commonStyle,
          backgroundColor: lightColor,
          borderColor: lightColor,
          borderWidth: 1,
          opacity: 0.7
        }
      },
      raised: {
        default: { ...commonStyle,
          backgroundColor: buttonColors[color]
        },
        hovered: { ...commonStyle,
          backgroundColor: Color(buttonColors[color]).alpha(0.6).string()
        },
        pressed: { ...commonStyle,
          backgroundColor: lightColor,
          opacity: 0.7
        }
      },
      text: {
        default: { ...commonStyle,
          backgroundColor: transparent ? "transparent" : buttonColors.surface
        },
        hovered: { ...commonStyle,
          backgroundColor: fadedColor
        },
        pressed: { ...commonStyle,
          backgroundColor: lightColor,
          opacity: 0.7
        }
      }
    };
  }, [borderRadii, buttonColors, buttonHeights, color, spacing, transparent]); // Returns a button style containing:
  // 1. style based on type & state
  // 2. the passed style prop
  // 3. style based on whether disabled
  // 4. style based on whether busy

  const buttonStyle = useCallback(({
    focused,
    hovered,
    pressed
  }) => {
    let buttonState = "default";

    if (pressed) {
      buttonState = "pressed";
    } else if (hovered) {
      buttonState = "hovered";
    }

    let cursorStyle = rnStyles.enabled; // A disabled cursor is a big red warning sign

    if (disabled) cursorStyle = rnStyles.disabled; // An inactive cursor is the normal pointer. It wins over the big red sign if both are present

    if (inactive) cursorStyle = rnStyles.inactive;
    return [buttonStyles[type][buttonState], typeof style === "function" ? style({
      focused,
      hovered,
      pressed
    }) : style, cursorStyle, busy ? rnStyles.busy : undefined];
  }, [busy, buttonStyles, disabled, inactive, style, type]);
  const localAccessibilityState = {
    busy,
    disabled: disabled || inactive || undefined,
    ...accessibilityState
  }; // Registers the middle mouse button event for web

  const pressableRef = useRef(null);
  useEffect(() => {
    if (Platform.OS === "web" && pressableRef.current && onAuxClick) {
      const pressableDiv = pressableRef.current;

      const onlyAcceptMiddleClick = function (ev) {
        if (ev.button === 1) onAuxClick();
      };

      pressableDiv.addEventListener("auxclick", onlyAcceptMiddleClick);

      const onlyAcceptCtrlEnter = function (ev) {
        if (ev.ctrlKey && ev.key === "Enter") onAuxClick();
      };

      pressableDiv.addEventListener("keypress", onlyAcceptCtrlEnter);
      return () => {
        pressableDiv.removeEventListener("auxclick", onlyAcceptMiddleClick);
        pressableDiv.removeEventListener("keypress", onlyAcceptCtrlEnter);
      };
    }

    return;
  }, [onAuxClick]);
  return (// NB: Using the css prop prevents the style fn from being called
    <Pressable disabled={disabled || busy || inactive} ref={pressableRef} style={buttonStyle} {...otherProps} accessibilityRole={accessibilityRole} accessibilityState={localAccessibilityState}>
      {({
        pressed
      }) => {
        return busy ? <PrActivityIndicator color={type === "raised" ? buttonColors.text : buttonColors[color]} /> : <div onAuxClick={onAuxClick}>
            <_StyledPrText children={children} $_css={[localStyles.text, type !== "raised" && !pressed ? css`
                      color: ${buttonColors[color]};
                    ` : undefined]} />
          </div>;
      }}
    </Pressable>
  );
};

export default PrButton;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "PrButton___StyledPrText",
  componentId: "sc-1cdlc02-0"
})(["", ""], p => p.$_css);