/**
 * @generated SignedSource<<72826b1117a1c28ba6886550c4bcafef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpResourceCommon_resource$data = {
  readonly _id: string;
  readonly additionalIframeAttributes: string | null;
  readonly aspectRatio: number | null;
  readonly caption: string | null;
  readonly embedMarkup: string | null;
  readonly embeddedResources: ReadonlyArray<{
    readonly _id: string;
    readonly caption: string | null;
    readonly names: any;
    readonly url: string | null;
  }> | null;
  readonly extraResource: {
    readonly embedMarkup: string | null;
    readonly type: string;
    readonly url: string | null;
  } | null;
  readonly icon: string | null;
  readonly names: any;
  readonly publisher: {
    readonly name: string;
  };
  readonly thumbnailUrl: string | null;
  readonly type: string;
  readonly url: string | null;
  readonly userFormLocalization: any | null;
  readonly userFormStructure: any | null;
  readonly " $fragmentType": "CpResourceCommon_resource";
};
export type CpResourceCommon_resource$key = {
  readonly " $data"?: CpResourceCommon_resource$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpResourceCommon_resource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v1/*: any*/),
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "extraResourceId"
    },
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpResourceCommon_resource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalIframeAttributes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "aspectRatio",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "embeddedResources",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "embedMarkup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "extraResourceId",
          "variableName": "extraResourceId"
        }
      ],
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "extraResource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "embedMarkup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "publisher",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    (v5/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "userFormLocalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userFormStructure",
      "storageKey": null
    }
  ],
  "type": "Resource",
  "abstractKey": null
};
})();

(node as any).hash = "4881c1fc785557334f2f2bb158cc1d22";

export default node;
