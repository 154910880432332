import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CpHeaderButton from "components/common/CpHeaderButton";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import translations from "translations";

/**
 * Buttons to handle opening the PackageSession modal from either the navHeader or the account menu
 */
const PackageSessionButton = ({
  onPressBeforeDispatch,
  type
}) => {
  const {
    dispatchPatientSession,
    patientSessionData: {
      isPackageSession
    }
  } = usePatientSessionContext();
  const {
    formatMessage
  } = useIntl();
  const {
    appConfig,
    user
  } = useImdSession();
  const userExists = Boolean(user);
  const patientPackagesTool = appConfig?.appTools?.find(tool => tool.name === "Packages");
  const isPatientPackagesDisabled = patientPackagesTool?.status !== "active";
  const shouldDisablePackageSession = useMemo(() => {
    return !appConfig?.appTools?.length || !patientPackagesTool || isPatientPackagesDisabled;
  }, [appConfig?.appTools, isPatientPackagesDisabled, patientPackagesTool]);
  const handleOpenPackageSession = useCallback(() => {
    onPressBeforeDispatch && onPressBeforeDispatch();
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.PackageStart
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession, onPressBeforeDispatch]); // Guard - patient information packages should only be available if:
  // - It is an authenticated non-kiosk/patient user
  // - The packages tool is not disabled

  if (!userExists || user?.isPatient || shouldDisablePackageSession) {
    return null;
  }

  return <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderPackageSessionButton)} icon={isPackageSession ? IconName.iconPackageOpen : IconName.iconPackageClosed} label={<FormattedMessage id="3PAoc9" defaultMessage="Send an Information Package" />} onPress={handleOpenPackageSession} shortLabel={<FormattedMessage id="v8f8hA" defaultMessage="Packages" />} testID={type === ActionButtonUserType.AccountMenu ? "PackageSessionButton-accountMenu" : "PackageSessionButton-header"} type={type} />;
};

export default PackageSessionButton;