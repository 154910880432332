import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Entypo - List
const List: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M8.2184,21.0631397 L6.6344,21.0631397 C5.7592,21.0631397 5.0504,21.7719397 5.0504,22.6471397 C5.0504,23.5223397 5.7592,24.2311397 6.6344,24.2311397 L8.2184,24.2311397 C9.0936,24.2311397 9.8024,23.5223397 9.8024,22.6471397 C9.8024,21.7719397 9.0936,21.0631397 8.2184,21.0631397 L8.2184,21.0631397 Z M8.2184,14.7271397 L6.6344,14.7271397 C5.7592,14.7271397 5.0504,15.4359397 5.0504,16.3111397 C5.0504,17.1863397 5.7592,17.8951397 6.6344,17.8951397 L8.2184,17.8951397 C9.0936,17.8951397 9.8024,17.1863397 9.8024,16.3111397 C9.8024,15.4359397 9.0936,14.7271397 8.2184,14.7271397 L8.2184,14.7271397 Z M8.2184,8.38953967 L6.6344,8.38953967 C5.7592,8.38953967 5.0504,9.09833967 5.0504,9.97353967 C5.0504,10.8487397 5.7592,11.5575397 6.6344,11.5575397 L8.2184,11.5575397 C9.0936,11.5575397 9.8024,10.8487397 9.8024,9.97353967 C9.8024,9.09833967 9.0936,8.38953967 8.2184,8.38953967 L8.2184,8.38953967 Z M14.5544,11.5591397 L25.644,11.5591397 C26.5192,11.5591397 27.228,10.8503397 27.228,9.97513967 C27.228,9.09993967 26.5192,8.39113967 25.644,8.39113967 L14.5544,8.39113967 C13.6792,8.39113967 12.9704,9.09993967 12.9704,9.97513967 C12.9704,10.8503397 13.6792,11.5591397 14.5544,11.5591397 L14.5544,11.5591397 Z M25.644,14.7271397 L14.5544,14.7271397 C13.6792,14.7271397 12.9704,15.4359397 12.9704,16.3111397 C12.9704,17.1863397 13.6792,17.8951397 14.5544,17.8951397 L25.644,17.8951397 C26.5192,17.8951397 27.228,17.1863397 27.228,16.3111397 C27.228,15.4359397 26.5192,14.7271397 25.644,14.7271397 L25.644,14.7271397 Z M25.644,21.0631397 L14.5544,21.0631397 C13.6792,21.0631397 12.9704,21.7719397 12.9704,22.6471397 C12.9704,23.5223397 13.6792,24.2311397 14.5544,24.2311397 L25.644,24.2311397 C26.5192,24.2311397 27.228,23.5223397 27.228,22.6471397 C27.228,21.7719397 26.5192,21.0631397 25.644,21.0631397 L25.644,21.0631397 Z" />
    </Svg>
  )
}

export default List
