/**
 * @generated SignedSource<<7c4b6a093b95683df0f755b6c9cb4459>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type CpMediaSectionNodeQuery$variables = {
  id: string;
  locale?: Locale | null;
};
export type CpMediaSectionNodeQuery$data = {
  readonly node: {
    readonly children?: ReadonlyArray<{
      readonly names: any;
      readonly resourceType: string | null;
      readonly revisionId: string;
      readonly thumbnail: string | null;
    }>;
    readonly names?: any;
  } | null;
};
export type CpMediaSectionNodeQuery = {
  response: CpMediaSectionNodeQuery$data;
  variables: CpMediaSectionNodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpMediaSectionNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ResourceNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpMediaSectionNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ResourceNode",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff8d4f8a3cf9282af703865fbe676a8a",
    "id": null,
    "metadata": {},
    "name": "CpMediaSectionNodeQuery",
    "operationKind": "query",
    "text": "query CpMediaSectionNodeQuery(\n  $id: ID!\n  $locale: Locale\n) {\n  node(id: $id) {\n    __typename\n    ... on ResourceNode {\n      names(locale: $locale)\n      children(locale: $locale) {\n        names(locale: $locale)\n        revisionId\n        resourceType\n        thumbnail(locale: $locale)\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a6ec1d60654ca378bd80a42fa2ac21b5";

export default node;
