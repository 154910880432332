import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpAccountResetPassword from "components/account/CpAccountResetPassword";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlVerifyPasswordResetToken from "query/QlVerifyPasswordResetToken";
import { styles } from "services/Theme";
import translations from "translations";
const localTranslations = defineMessages({
  requestNewTokenButton: {
    id: "HgOGsU",
    defaultMessage: "Request new password reset token"
  }
});

/**
 * Container that manages the layout and renders the form that allows a user to reset their password
 */
const CtResetPassword = ({
  token
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    navigate
  } = useNavigation();
  const handleNavigateToForgotPassword = useCallback(() => {
    navigate(Routes.forgot_password);
  }, [navigate]);
  const variables = useMemo(() => ({
    input: token
  }), [token]);
  return <CpBackgroundHeader childStyle={styles.flexSingle} header={translations.screens.reset_password}>
      <_StyledView $_css={[styles.paddingDouble, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
        <QlVerifyPasswordResetToken variables={variables}>
          {response => {
          return response.verifyPasswordResetToken ? <CpAccountResetPassword token={token} /> : <View>
                <_StyledCpError error={formatMessage(translations.errors.invalidConfirmationCode)} $_css2={[styles.marginVerticalSingle, styles.errorBox]} />
                <_StyledPrButton onPress={handleNavigateToForgotPassword} testID="CtResetPasswordRequestToken" $_css3={styles.marginTopDouble}>
                  <_StyledPrText children={formatMessage(localTranslations.requestNewTokenButton)} $_css4={styles.buttonText} />
                </_StyledPrButton>
              </View>;
        }}
        </QlVerifyPasswordResetToken>
      </_StyledView>
    </CpBackgroundHeader>;
};

export default CtResetPassword;

var _StyledView = _styled(View).withConfig({
  displayName: "CtResetPassword___StyledView",
  componentId: "sc-wn40zo-0"
})(["", ""], p => p.$_css);

var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CtResetPassword___StyledCpError",
  componentId: "sc-wn40zo-1"
})(["", ""], p => p.$_css2);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtResetPassword___StyledPrButton",
  componentId: "sc-wn40zo-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtResetPassword___StyledPrText",
  componentId: "sc-wn40zo-3"
})(["", ""], p => p.$_css4);