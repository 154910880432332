import { AppBackendContext, AppBackendContextType } from "contexts/CxAppBackend"
import { useContext } from "react"

/**
 * Returns various types as a value that can be used with a Text component.
 */
function useAppBackend(): AppBackendContextType {
  const appRegionContext = useContext(AppBackendContext)
  if (!appRegionContext) throw new Error("App Backend Context is required")
  return appRegionContext
}

export default useAppBackend
