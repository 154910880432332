const httpRegex = /^https?:\/\//

/**
 * Returns a boolean the confirms whether the provided value is a url string.
 */
const isUrlString = (value: string): boolean => {
  const result = value.match(httpRegex)
  return Boolean(result?.[0])
}

export default isUrlString
