import { useNavigationState } from "@react-navigation/native"

/**
 * Returns the react-navigation state object of the screen currently being displayed
 */
function useActiveNavigationKey(): undefined | string {
  // React-navigation is a horrible library, ane everyone invovled in it should feel bad
  const navState = useNavigationState((state) => {
    let currentState = state
    let stateRoute

    // Look up the current key by digging into the state
    while (currentState?.routes !== undefined) {
      stateRoute = currentState.routes[currentState.index ?? 0]
      currentState = stateRoute.state as typeof currentState
    }

    return stateRoute
  })

  return navState?.key
}

export default useActiveNavigationKey
