/**
 * @generated SignedSource<<235766da4cb5163530c443700868f08b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlPatientSessionResourceNodeQuery$variables = {
  locale?: Locale | null;
  revisionIds: ReadonlyArray<string>;
};
export type QlPatientSessionResourceNodeQuery$data = {
  readonly resourceNodesByRevision: {
    readonly nodes: ReadonlyArray<{
      readonly icon: string | null;
      readonly id: string;
      readonly names: any;
      readonly nodeType: string;
      readonly publisher: {
        readonly name: string;
      };
      readonly relatedNode: {
        readonly icon: string | null;
      } | null;
      readonly resourceNodeRevisionId: string | null;
      readonly resourceType: string | null;
      readonly revisionId: string;
      readonly rootNames: any;
      readonly rootRevisionId: string;
      readonly type: string;
    } | null> | null;
  } | null;
};
export type QlPatientSessionResourceNodeQuery = {
  response: QlPatientSessionResourceNodeQuery$data;
  variables: QlPatientSessionResourceNodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "revisionIds"
},
v2 = [
  {
    "kind": "Variable",
    "name": "revisionIds",
    "variableName": "revisionIds"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v5 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "rootNames",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootRevisionId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceType",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlPatientSessionResourceNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ResourceNodeConnection",
        "kind": "LinkedField",
        "name": "resourceNodesByRevision",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceNode",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "publisher",
                "plural": false,
                "selections": [
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "relatedNode",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlPatientSessionResourceNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ResourceNodeConnection",
        "kind": "LinkedField",
        "name": "resourceNodesByRevision",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceNode",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "publisher",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "relatedNode",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4efc46b06000546323032ba9a03122a",
    "id": null,
    "metadata": {},
    "name": "QlPatientSessionResourceNodeQuery",
    "operationKind": "query",
    "text": "query QlPatientSessionResourceNodeQuery(\n  $revisionIds: [String!]!\n  $locale: Locale\n) {\n  resourceNodesByRevision(revisionIds: $revisionIds) {\n    nodes {\n      id\n      icon(locale: $locale)\n      names(locale: $locale)\n      publisher {\n        name\n        id\n      }\n      relatedNode {\n        icon(locale: $locale)\n        id\n      }\n      resourceNodeRevisionId\n      revisionId\n      rootNames(locale: $locale)\n      rootRevisionId\n      type\n      resourceType\n      nodeType\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "91264861289fbcf777f908ddfe8a6f62";

export default node;
