import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpAccountChangeEmailMutation;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay";
import * as zod from "zod";
import CpError from "components/common/CpError";
import CpLinkButton from "components/common/CpLinkButton";
import CpModal from "components/common/CpModal";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import { SubmitStatus, maxEmailLength, maxPasswordLength } from "services/Constants";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import { emailValidation } from "services/Validation";
import translations from "translations";
const changeEmailMutation = _CpAccountChangeEmailMutation !== void 0 ? _CpAccountChangeEmailMutation : _CpAccountChangeEmailMutation = require("./__generated__/CpAccountChangeEmailMutation.graphql");
const localStyles = {
  buttonContainer: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  confirmButtonContainers: css`
    flex-direction: row;
    justify-content: space-between;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopButtonContainer: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  emailBottomInfo: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  heading: css`
    ${styles.headingFont};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  textInputWrapper: css`
    margin-bottom: 0;
  `,
  unconfirmedEmail: css`
    ${styles.italicText};
    ${styles.boldText};
    word-wrap: anywhere;
  `
};
const localTranslations = defineMessages({
  confirmationText: {
    id: "6C+9hM",
    defaultMessage: "Are you sure you want to change your account email to {newEmail}? You will receive a verification email to confirm the change, and your account will remain 'pending' until verified."
  },
  currentEmail: {
    id: "LbMq0f",
    defaultMessage: "Current account email"
  },
  differentEmail: {
    id: "JYV1nt",
    defaultMessage: "This email matches the existing email. Please input a new email and try again."
  },
  newEmailText: {
    id: "g0ng99",
    defaultMessage: "Enter the new email you would like to use for your account. For your security, we require your password to submit a new account email."
  },
  submitButtonText: {
    id: "ddLomv",
    defaultMessage: "Submit new email"
  },
  successMessage: {
    id: "6zsumw",
    defaultMessage: "You successfully changed your account email to {newEmail}. You should receive a verification email shortly."
  }
});

/**
 * Form that allows the user to change its account email and if successful sends a new confirmation email
 */
const CpAccountChangeEmail = ({
  onClose
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl();
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.Waiting);
  const [submitChangeEmail, isSubmittingChangeEmail] = useMutation(changeEmailMutation);
  const [formError, setFormError] = useState();
  const [newEmail, setNewEmail] = useState("");
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      currentPassword: zod.string().min(1),
      email: zod.string().refine(...emailValidation())
    }), []))
  });
  const handleSubmit = useMemo(() => form.handleSubmit(async values => {
    submitChangeEmail({
      onCompleted: ({
        changeUserEmail
      }) => {
        Log.info(`CpAccountChangeEmail - ${changeUserEmail?.user.email}`);

        if (changeUserEmail?.user.email) {
          setNewEmail(changeUserEmail?.user.email);
          setSubmitStatus(SubmitStatus.Successful);
        }
      },
      onError: err => {
        setFormError(err);
        setSubmitStatus(SubmitStatus.Error);
      },
      variables: {
        input: values
      }
    });
  }), [form, submitChangeEmail]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const handleOpenConfirmationModal = useCallback(async () => {
    setSubmitStatus(SubmitStatus.Waiting);
    await form.trigger();

    if (form.getValues("email") === user?.email) {
      setFormError(formatMessage(localTranslations.differentEmail));
      return;
    }

    if (form.formState.isValid) {
      setConfirmationModalOpen(true);
    }
  }, [form, formatMessage, user?.email]);
  const handleCloseConfirmationModal = useCallback(() => {
    setSubmitStatus(SubmitStatus.Waiting);
    setConfirmationModalOpen(false);
  }, []); // Closes the modal and returns to form if failure, or closes the modal and returns to readonly mode if successful

  const handlePressContinue = useCallback(() => {
    submitStatus === SubmitStatus.Successful ? onClose() : handleCloseConfirmationModal();
  }, [handleCloseConfirmationModal, onClose, submitStatus]); // Resets form error when modal opens or closes

  useEffect(() => {
    setFormError("");
  }, [confirmationModalOpen]);
  return <CpForm {...form} onSubmit={handleSubmit}>
      <_StyledView $_css={styles.marginVerticalSingle}>
        <_StyledPrText children={localTranslations.newEmailText} $_css2={localStyles.heading} />
      </_StyledView>
      <_StyledCpModal onBackgroundPress={submitStatus === SubmitStatus.Waiting ? handleCloseConfirmationModal : handlePressContinue} visible={confirmationModalOpen} $_css3={styles.marginHorizontalSingle}>
        <_StyledView2 $_css4={styles.modalContainer}>
          <_StyledPrText2 accessibilityRole="header" children={translations.screens.change_account_email} $_css5={styles.modalHeaderText} />
          {submitStatus === SubmitStatus.Waiting ? <>
              <_StyledView3 $_css6={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                <PrText children={formatMessage(localTranslations.confirmationText, {
              newEmail: <_StyledPrText3 children={form.getValues("email")} $_css7={localStyles.unconfirmedEmail} />
            })} />
              </_StyledView3>
              <_StyledView4 $_css8={localStyles.confirmButtonContainers}>
                <PrButton busy={isSubmittingChangeEmail} onPress={handleSubmit} testID="CpAccountChangeEmailModalSubmit">
                  <_StyledPrText4 children={translations.buttons.confirm} $_css9={localStyles.buttonText} />
                </PrButton>
                <PrButton onPress={handleCloseConfirmationModal} type="text">
                  <_StyledPrText5 children={translations.buttons.cancel} $_css10={localStyles.buttonText} />
                </PrButton>
              </_StyledView4>
            </> : <>
              <_StyledView5 $_css11={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                <PrText children={submitStatus === SubmitStatus.Error ? formatMessage(translations.errors.customError, {
              customError: formatMessage(translations.errors.genericError),
              supportEmail: <CpLinkButton url="mailto:support@imdhealth.com">
                              <PrText>support@imdhealth.com</PrText>
                            </CpLinkButton>
            }) : formatMessage(localTranslations.successMessage, {
              newEmail: <_StyledPrText6 children={newEmail} $_css12={localStyles.unconfirmedEmail} />
            })} />
              </_StyledView5>
              <_StyledPrButton busy={isSubmittingChangeEmail} onPress={handlePressContinue} testID="CpAccountChangeEmailContinue" $_css13={styles.marginTopDouble}>
                <_StyledPrText7 children={translations.buttons.continue} $_css14={localStyles.buttonText} />
              </_StyledPrButton>
            </>}
        </_StyledView2>
      </_StyledCpModal>
      <CpReadonlyInput label={localTranslations.currentEmail} value={user?.email} />
      <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.accountEmailLabel)} autoCapitalize="none" autoComplete="email" autoCorrect={false} autoFocus={true} keyboardType="email-address" label={translations.labels.accountEmailLabel} maxLength={maxEmailLength} name="email" placeholder="newemail@healthco.com" returnKeyType="next" textContentType="emailAddress" wrapperStyle={localStyles.textInputWrapper} />
      <View>
        <_StyledPrText8 children={translations.labels.emailInfo} $_css15={localStyles.emailBottomInfo} />
      </View>
      <CpFormTextInput accessibilityLabel={formatMessage(translations.attributes.user.password)} autoCapitalize="none" autoCorrect={false} label={translations.attributes.user.password} maxLength={maxPasswordLength} name="currentPassword" password={true} placeholder={translations.labels.accountPasswordPlaceholder} returnKeyType="go" textContentType="password" />
      {
      /* Just want to show this CpError in case the error is with the form, not with the submission, as we have the modal for that */
    }
      {!confirmationModalOpen && <_StyledCpError error={formError} $_css16={[styles.formField, styles.errorBox]} />}
      <_StyledView6 $_css17={[localStyles.buttonContainer, !isNarrowScreen ? localStyles.desktopButtonContainer : undefined]}>
        <PrButton accessibilityHint={formatMessage(translations.accessibility.hint.changeEmailButton)} onPress={handleOpenConfirmationModal} testID="CpAccountChangeEmailSubmit">
          <_StyledPrText9 children={localTranslations.submitButtonText} $_css18={localStyles.buttonText} />
        </PrButton>
        <_StyledPrButton2 onPress={onClose} type="text" $_css19={styles.marginVerticalSingle}>
          <_StyledPrText10 children={translations.buttons.cancel} $_css20={localStyles.buttonText} />
        </_StyledPrButton2>
      </_StyledView6>
    </CpForm>;
};

export default CpAccountChangeEmail;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAccountChangeEmail___StyledView",
  componentId: "sc-oplz2-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText",
  componentId: "sc-oplz2-1"
})(["", ""], p => p.$_css2);

var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CpAccountChangeEmail___StyledCpModal",
  componentId: "sc-oplz2-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAccountChangeEmail___StyledView2",
  componentId: "sc-oplz2-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText2",
  componentId: "sc-oplz2-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpAccountChangeEmail___StyledView3",
  componentId: "sc-oplz2-5"
})(["", ""], p => p.$_css6);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText3",
  componentId: "sc-oplz2-6"
})(["", ""], p => p.$_css7);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpAccountChangeEmail___StyledView4",
  componentId: "sc-oplz2-7"
})(["", ""], p => p.$_css8);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText4",
  componentId: "sc-oplz2-8"
})(["", ""], p => p.$_css9);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText5",
  componentId: "sc-oplz2-9"
})(["", ""], p => p.$_css10);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpAccountChangeEmail___StyledView5",
  componentId: "sc-oplz2-10"
})(["", ""], p => p.$_css11);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText6",
  componentId: "sc-oplz2-11"
})(["", ""], p => p.$_css12);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrButton",
  componentId: "sc-oplz2-12"
})(["", ""], p => p.$_css13);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText7",
  componentId: "sc-oplz2-13"
})(["", ""], p => p.$_css14);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText8",
  componentId: "sc-oplz2-14"
})(["", ""], p => p.$_css15);

var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpAccountChangeEmail___StyledCpError",
  componentId: "sc-oplz2-15"
})(["", ""], p => p.$_css16);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpAccountChangeEmail___StyledView6",
  componentId: "sc-oplz2-16"
})(["", ""], p => p.$_css17);

var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText9",
  componentId: "sc-oplz2-17"
})(["", ""], p => p.$_css18);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrButton2",
  componentId: "sc-oplz2-18"
})(["", ""], p => p.$_css19);

var _StyledPrText10 = _styled(PrText).withConfig({
  displayName: "CpAccountChangeEmail___StyledPrText10",
  componentId: "sc-oplz2-19"
})(["", ""], p => p.$_css20);