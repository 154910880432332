/**
 * @generated SignedSource<<320c2c03952f4f45b5327bd4ae19a731>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TrainingNodeCompletionCreateInput = {
  resourceNodeRevisionId: string;
};
export type CpResourceNodeTrainingNodeCompletionCreateMutation$variables = {
  input: TrainingNodeCompletionCreateInput;
};
export type CpResourceNodeTrainingNodeCompletionCreateMutation$data = {
  readonly trainingNodeCompletionCreate: {
    readonly trainingNodeCompletion: {
      readonly resourceNodeRevisionId: string;
    };
  } | null;
};
export type CpResourceNodeTrainingNodeCompletionCreateMutation = {
  response: CpResourceNodeTrainingNodeCompletionCreateMutation$data;
  variables: CpResourceNodeTrainingNodeCompletionCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpResourceNodeTrainingNodeCompletionCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TrainingNodeCompletionCreatePayload",
        "kind": "LinkedField",
        "name": "trainingNodeCompletionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrainingNodeCompletion",
            "kind": "LinkedField",
            "name": "trainingNodeCompletion",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpResourceNodeTrainingNodeCompletionCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TrainingNodeCompletionCreatePayload",
        "kind": "LinkedField",
        "name": "trainingNodeCompletionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TrainingNodeCompletion",
            "kind": "LinkedField",
            "name": "trainingNodeCompletion",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe348fbf2a277502bdf457340f63f162",
    "id": null,
    "metadata": {},
    "name": "CpResourceNodeTrainingNodeCompletionCreateMutation",
    "operationKind": "mutation",
    "text": "mutation CpResourceNodeTrainingNodeCompletionCreateMutation(\n  $input: TrainingNodeCompletionCreateInput!\n) {\n  trainingNodeCompletionCreate(input: $input) {\n    trainingNodeCompletion {\n      resourceNodeRevisionId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c086bcf38fe467e46c5d524db2b4ea4c";

export default node;
