/**
 * @generated SignedSource<<18c6c670a1ee846a9539884783b60272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppTabLinkStyle = "Card" | "Default" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CpResourceNodeTab_tabData$data = {
  readonly linkStyle?: AppTabLinkStyle;
  readonly renderWithoutHeaders?: boolean | null;
  readonly resourceNode?: {
    readonly _id: string;
    readonly revisionId: string;
  } | null;
  readonly " $fragmentType": "CpResourceNodeTab_tabData";
};
export type CpResourceNodeTab_tabData$key = {
  readonly " $data"?: CpResourceNodeTab_tabData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpResourceNodeTab_tabData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpResourceNodeTab_tabData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linkStyle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "renderWithoutHeaders",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceNode",
          "kind": "LinkedField",
          "name": "resourceNode",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revisionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ResourceNodeTab",
      "abstractKey": null
    }
  ],
  "type": "AppTab",
  "abstractKey": "__isAppTab"
};

(node as any).hash = "52505055afd025e401fa714d39761498";

export default node;
