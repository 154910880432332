import { ThemeHelpers, ThemeHelpersContext } from "contexts/CxTheme"
import { useContext } from "react"

/**
 * Convenience hook to get the current theme.
 */
const useAppThemeHelpers = (): ThemeHelpers => {
  const themeHelpers = useContext(ThemeHelpersContext)
  if (!themeHelpers) throw new Error("useAppThemeHelpers must be inside a CxTheme provider")
  return themeHelpers
}

export default useAppThemeHelpers
