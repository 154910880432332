import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { getSelectedActiveSegments, getSessionDuration } from "services/PatientSession";
import { css } from "services/Theme";
import translations from "translations";
const localStyles = {
  marginLeft: css`
    margin-left: ${({
    theme
  }) => 3 * theme.spacing.half}px;
  `,
  marginRight: css`
    margin-right: ${({
    theme
  }) => 3 * theme.spacing.half}px;
  `,
  sessionDetailLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  sessionDetailValue: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
  `,
  sessionDetailsContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: center;
  `
};

/**
 * Renders the statistics for a PatientSession. Defaults to using activeSegments and selectedResources, but can render stats based on all viewedResources as well.
 */
const CpPatientSessionStats = ({
  showViewed = false
}) => {
  const {
    patientSessionData: {
      activeSegments,
      selectedResources,
      viewedResources
    }
  } = usePatientSessionContext(); // Determine the length of the current session based on activeSegments OR when a selected resource was viewed

  const sessionDuration = useMemo(() => {
    return getSessionDuration(showViewed ? activeSegments : getSelectedActiveSegments(activeSegments, selectedResources));
  }, [activeSegments, selectedResources, showViewed]);
  const resourceCount = useMemo(() => selectedResources.reduce((acc, resource) => !resource.isTopic && resource.selected ? acc + 1 : acc, 0), [selectedResources]);
  const topicCount = useMemo(() => selectedResources.reduce((acc, resource) => resource.isTopic && resource.selected ? acc + 1 : acc, 0), [selectedResources]);
  return <_StyledView $_css={localStyles.sessionDetailsContainer}>
      {showViewed ? <_StyledView2 $_css2={localStyles.marginRight}>
          <_StyledPrText $_css3={localStyles.sessionDetailLabel}>
            <FormattedMessage {...translations.entities.resource} values={{
          count: 2
        }} />
          </_StyledPrText>
          <_StyledPrText2 children={viewedResources.length} $_css4={localStyles.sessionDetailValue} />
        </_StyledView2> : <>
          <_StyledView3 $_css5={localStyles.marginRight}>
            <_StyledPrText3 $_css6={localStyles.sessionDetailLabel}>
              <FormattedMessage {...translations.entities.topic} values={{
            count: 2
          }} />
            </_StyledPrText3>
            <_StyledPrText4 children={topicCount} $_css7={localStyles.sessionDetailValue} />
          </_StyledView3>
          <_StyledView4 $_css8={localStyles.marginRight}>
            <_StyledPrText5 $_css9={localStyles.sessionDetailLabel}>
              <FormattedMessage {...translations.entities.resource} values={{
            count: 2
          }} />
            </_StyledPrText5>
            <_StyledPrText6 children={resourceCount} $_css10={localStyles.sessionDetailValue} />
          </_StyledView4>
        </>}
      <_StyledView5 $_css11={localStyles.marginLeft}>
        <_StyledPrText7 children={translations.labels.sessionLength} $_css12={localStyles.sessionDetailLabel} />
        <_StyledPrText8 children={sessionDuration} $_css13={localStyles.sessionDetailValue} />
      </_StyledView5>
    </_StyledView>;
};

export default CpPatientSessionStats;

var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionStats___StyledView",
  componentId: "sc-h15nr8-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionStats___StyledView2",
  componentId: "sc-h15nr8-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText",
  componentId: "sc-h15nr8-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText2",
  componentId: "sc-h15nr8-3"
})(["", ""], p => p.$_css4);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionStats___StyledView3",
  componentId: "sc-h15nr8-4"
})(["", ""], p => p.$_css5);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText3",
  componentId: "sc-h15nr8-5"
})(["", ""], p => p.$_css6);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText4",
  componentId: "sc-h15nr8-6"
})(["", ""], p => p.$_css7);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPatientSessionStats___StyledView4",
  componentId: "sc-h15nr8-7"
})(["", ""], p => p.$_css8);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText5",
  componentId: "sc-h15nr8-8"
})(["", ""], p => p.$_css9);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText6",
  componentId: "sc-h15nr8-9"
})(["", ""], p => p.$_css10);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPatientSessionStats___StyledView5",
  componentId: "sc-h15nr8-10"
})(["", ""], p => p.$_css11);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText7",
  componentId: "sc-h15nr8-11"
})(["", ""], p => p.$_css12);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStats___StyledPrText8",
  componentId: "sc-h15nr8-12"
})(["", ""], p => p.$_css13);