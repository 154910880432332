import React, { useCallback } from "react"
import { View } from "react-native"

import CpPackageSessionTemplatesSelectItem from "components/patientSession/CpPackageSessionTemplatesSelectItem"
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession"
import { CommonTopicResource, TopicTreeResource } from "contexts/CxPatientSession"
import usePatientSessionContext from "hooks/usePatientSessionContext"
import { QlPatientPackagesQuery$data } from "query/__generated__/QlPatientPackagesQuery.graphql"
import Log from "services/Log"

interface CpPackageSessionTemplatesSelectProps {
  data: QlPatientPackagesQuery$data["patientPackages"]
}

/**
 * Renders a list of saved patient packages for a user to select for sending or deleting entirely
 */
const CpPackageSessionTemplatesSelect: React.FC<CpPackageSessionTemplatesSelectProps> = ({
  data,
}) => {
  const { dispatchPatientSession } = usePatientSessionContext()

  const handleSelectTemplate = useCallback(
    (id: string) => {
      Log.info("CpPackageSessionTemplatesSelect - selecting saved template.", { id })
      const selectedPackage = { ...data?.find((currentPackage) => currentPackage.id === id) }

      if (!selectedPackage?.children?.length || !selectedPackage.id || !selectedPackage.name) {
        // Guard
        Log.warn("CpPackageSessionTemplatesSelect - selected template not found", {
          data,
          id,
          selectedPackage,
        })
        return
      }

      const newPackageResources: Array<CommonTopicResource | TopicTreeResource> = []
      selectedPackage.children?.forEach((node) => {
        const basePackage = {
          revisionId: node.resourceNodeRevisionId,
          selected: true,
        }
        newPackageResources.push(
          node.commonTopic
            ? {
                ...basePackage,
                commonTopicId: node.commonTopic._id,
                sectionHeader: "Common Topics", // TODO: Determine how this will actually be saved and retrieved
                type: "commonTopicsSection",
              }
            : {
                ...basePackage,
                sectionHeader: node.topicNode?.parentNames.default,
                topicTreeId: node.topicNode?.treeId ?? "",
                type: "topicSection",
              },
        )
      })
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.PackageReview,
          packageResources: newPackageResources,
          packageTemplate: { id: selectedPackage.id, name: selectedPackage.name },
        },
        type: PatientSessionActions.SetSelectedPackages,
      })
    },
    [data, dispatchPatientSession],
  )

  return (
    <View>
      {data?.map((patientPackage) => {
        const { _id, description, id, name } = patientPackage
        const resourceCount = patientPackage.children.length
        return (
          <CpPackageSessionTemplatesSelectItem
            description={description}
            id={id}
            key={id}
            name={name}
            onReviewAndSend={handleSelectTemplate}
            packageId={_id}
            resourceCount={resourceCount}
          />
        )
      })}
    </View>
  )
}

export default CpPackageSessionTemplatesSelect
