import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpContentSearchItem_resourceNode;

import React from "react";
import { graphql } from "react-relay";
import { useFragment } from "react-relay/hooks";
import CpItem from "components/common/CpItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import { getNameVariant, getRootNameVariant, iconTypeMapping } from "services/Graphql";
import { css } from "services/Theme";
import translations from "translations";
const localStyles = {
  container: css`
    margin-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  iconContainer: css`
    align-items: center;
    min-width: 50px;
  `,
  subtitle: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-style: italic;
  `,
  title: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
  `,
  typeText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
    letter-spacing: 0.55px;
    text-transform: uppercase;
  `
};

const SearchItemIcon = ({
  resourceType,
  root
}) => {
  const icon = resourceType ? iconTypeMapping[resourceType] : IconName.folderOpen;
  return !root && !icon ? null : <_StyledView $_css={localStyles.iconContainer}>
      <PrIcon icon={root ? IconName.iconTopic : icon} />
      <_StyledPrText $_css2={localStyles.typeText}>
        {root ? <FormattedMessage id="CXyIFT" defaultMessage="Topic" /> : resourceType && <FormattedMessage {...translations.enums.resourceTypes[resourceType]} />}
      </_StyledPrText>
    </_StyledView>;
};

/**
 * Renders a content search result as a list item.
 */
const CpContentSearchItem = ({
  contentItem,
  onAuxPress,
  onPress,
  ...otherProps
}) => {
  const data = useFragment(_CpContentSearchItem_resourceNode !== void 0 ? _CpContentSearchItem_resourceNode : _CpContentSearchItem_resourceNode = require("./__generated__/CpContentSearchItem_resourceNode.graphql"), contentItem);
  const nodeName = getNameVariant(data);
  const rootNodeName = getRootNameVariant(data, "default");
  return <_StyledCpItem {...otherProps} accessibilityRole="link" onAuxCallback={onAuxPress} onPressCallback={onPress} onPressValue={data} right={<SearchItemIcon root={!data.parentId} resourceType={data.resourceType ?? undefined} />} testID="CpContentSearchItem" $_css3={localStyles.container}>
      <_StyledPrText2 children={nodeName} $_css4={localStyles.title} />
      {nodeName !== rootNodeName && <_StyledPrText3 $_css5={localStyles.subtitle}>
          <FormattedMessage id="6/J84j" defaultMessage="Found in: {root}" values={{
        root: rootNodeName
      }} />
        </_StyledPrText3>}
    </_StyledCpItem>;
};

export default CpContentSearchItem;

var _StyledView = _styled(View).withConfig({
  displayName: "CpContentSearchItem___StyledView",
  componentId: "sc-1fkaj3z-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpContentSearchItem___StyledPrText",
  componentId: "sc-1fkaj3z-1"
})(["", ""], p => p.$_css2);

var _StyledCpItem = _styled(CpItem).withConfig({
  displayName: "CpContentSearchItem___StyledCpItem",
  componentId: "sc-1fkaj3z-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpContentSearchItem___StyledPrText2",
  componentId: "sc-1fkaj3z-3"
})(["", ""], p => p.$_css4);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpContentSearchItem___StyledPrText3",
  componentId: "sc-1fkaj3z-4"
})(["", ""], p => p.$_css5);