var _CpMembershipItem_membership;

import React, { useCallback, useEffect } from "react";
import { useFragment } from "react-relay/hooks";
import { graphql } from "relay-runtime";
import CpOrganizationItem from "./CpOrganizationItem";
const membershipFragment = _CpMembershipItem_membership !== void 0 ? _CpMembershipItem_membership : _CpMembershipItem_membership = require("./__generated__/CpMembershipItem_membership.graphql");

/**
 * Renders a membership as a list item
 */
const CpMembershipItem = ({
  autoSelect,
  currentMembership,
  isFirst,
  isLast,
  membership,
  onSelect,
  ...otherProps
}) => {
  const membershipData = useFragment(membershipFragment, membership);
  const {
    organization,
    role
  } = membershipData; // Use by the parent to automatically select this item

  useEffect(() => {
    if (autoSelect) {
      onSelect(membershipData);
    }
  }, [autoSelect, membershipData, onSelect]);
  const isSelected = membershipData._id === currentMembership?._id;
  const handleOnSelect = useCallback(() => {
    onSelect(membershipData);
  }, [membershipData, onSelect]);
  return <CpOrganizationItem isFirst={isFirst} isLast={isLast} isSelected={isSelected} onSelect={handleOnSelect} organization={organization} role={role} testID={`CpMembershipItem-${membershipData.organization._id}`} {...otherProps} />;
};

export default CpMembershipItem;