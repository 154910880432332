/**
 * @generated SignedSource<<d7ae8de7a8a42af00151919e71820828>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpTopicPresentationTableOfContents_topicPresentationTocFragment$data = {
  readonly _id: string;
  readonly defaultResourceNode: {
    readonly revisionId: string;
  } | null;
  readonly descendants: ReadonlyArray<{
    readonly _id: string;
    readonly isLeaf: boolean;
    readonly parentRevisionId: string | null;
    readonly revisionId: string;
    readonly type: string;
  }>;
  readonly revisionId: string;
  readonly trainingNodeCompletions: ReadonlyArray<{
    readonly resourceNodeRevisionId: string;
  }> | null;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"useDescendantsAsTree_rootResourceNode">;
  readonly " $fragmentType": "CpTopicPresentationTableOfContents_topicPresentationTocFragment";
};
export type CpTopicPresentationTableOfContents_topicPresentationTocFragment$key = {
  readonly " $data"?: CpTopicPresentationTableOfContents_topicPresentationTocFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicPresentationTableOfContents_topicPresentationTocFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicPresentationTableOfContents_topicPresentationTocFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "defaultResourceNode",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrainingNodeCompletion",
      "kind": "LinkedField",
      "name": "trainingNodeCompletions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resourceNodeRevisionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "locale",
          "variableName": "locale"
        }
      ],
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "descendants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentRevisionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isLeaf",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDescendantsAsTree_rootResourceNode"
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "2cbc218f9464622d12660f51ce58b713";

export default node;
