import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconShoulder: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M137.17 149.1c-27.9 12-30 36.2-32.4 60-3.2 32.4-6.1 64.9-9.2 97.4-28.7-3.5-32.6-10.2-30.8-35.2 3.3-44.9 12.7-89.5 5.2-134.9-3.7-22.4 2.3-28.7 23.2-35.8 6.7-2.3 12.1-8.2 15.1-10.2-3.1-8.8-10.6-19.2-8.2-26.4 3.8-11.5 14.1-18.6 29.4-16.9a346.88 346.88 0 0092.7-2.3c20.5-3.2 32.3 8.6 42.6 26-5 .3-8.9.5-12.7.8 1.4 13.9 8.8 20.1 21.7 24 7 2.1 11.7 11.6 17.5 17.7-1.1 1.5-2.2 3-3.3 4.6-5.2 0-10.6.8-15.7-.1-19.3-3.6-19.3-3.8-23.4 18.3 9.7.9 19.3 2 28.9 2.7 19.2 1.3 19.6 1.7 16.2 23.3-17.9-.9-36-1.7-56-2.7-1.7 3.4-4 8.1-7.6 15.3 18.3 0 34.8-.4 51.3.3 4.7.2 9.3 3.4 13.9 5.3-3 5.5-5.4 15.2-9 15.6-16.6 2-33.7 3.4-50.1 1.3-14.6-1.9-21.1 3.1-25.2 16.4 26.1 8.6 51 4.8 77.1-4.2-2.2 17.6-7.1 29.9-24.7 30.3a413.37 413.37 0 01-47.2-1.3c-10.4-1-20.3-5-25.1 9.1-1.1 3.2-5.6 5.2-9.3 8.4 26.9 20.2 53.7 7.6 80.9 0-13.1 37.1-63.9 48.5-102.6 8.6 3.2 10.2 4.7 18.6 8.4 25.8s9.6 13.4 15.3 21.1c-6 1.3-10.7 2.5-15.4 3.2-17.8 2.6-18.2 2-16.3-15.9.4-3.3-1.3-6.7-1.4-10.1-.2-17.6 0-35.3-.4-52.9a281.49 281.49 0 00-2.6-30.3c-1.9-14.2-4.4-28.3-6.9-42.4-.7-4.7-2.5-9.2-3.9-13.9zm95.1-81.7c-28.4-2.1-51.7-4-75.1-5.2a28.41 28.41 0 00-14.3 3.3c-2.6 1.3-3.6 5.7-7 11.5 22.8-4.1 44.2-14.6 53.4 16.1 13.8-8.3 26.73-16 43-25.7z" />
    </Svg>
  )
}

export default IconShoulder
