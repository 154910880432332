import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material
const Brush: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 24 24" width={height} {...otherProps}>
      <Path d="M20.719 4.641q0.281 0.281 0.281 0.703t-0.281 0.703l-8.953 8.953-2.766-2.766 8.953-8.953q0.281-0.281 0.703-0.281t0.703 0.281zM6.984 14.016q1.219 0 2.109 0.891t0.891 2.109q0 1.641-1.172 2.813t-2.813 1.172q-2.438 0-3.984-2.016 0.703 0 1.336-0.539t0.633-1.43q0-1.219 0.891-2.109t2.109-0.891z" />
    </Svg>
  )
}

export default Brush
