import _styled from "styled-components";
import React, { useCallback } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpSignin from "components/common/CpSignin";
import CpTokenExpired from "components/common/CpTokenExpired";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import useAppParams from "hooks/useAppParams";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * The signin page for the provider app.
 */
const ScSigninProvider = ({
  route
}) => {
  const {
    dispatchPatientSession
  } = usePatientSessionContext();
  const {
    stringParams
  } = useAppParams();
  const handleProviderSignin = useCallback(() => {
    dispatchPatientSession({
      type: PatientSessionActions.SignIn
    });
  }, [dispatchPatientSession]);
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      {stringParams.access_token ? <CpTokenExpired /> : <>
          <CpUpdatePageTitle title={translations.screens[route.name]} />
          <CpSignin onSuccess={handleProviderSignin} />
        </>}
    </_StyledSafeAreaView>;
};

export default ScSigninProvider;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScSigninProvider___StyledSafeAreaView",
  componentId: "sc-1iz1i3j-0"
})(["", ""], p => p.$_css);