import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconTools: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M269.45 282.34a23.07 23.07 0 00-3.3 5.68l6 23v.3l-64.22 16.18a2.25 2.25 0 01-.3-1.5c-1.51-5.08-5.71-20.63-10.21-23.62-15.91-10.47-31.51-23.63-48.62-41.87l-.6-.6a53 53 0 01-9-11.66 12.64 12.64 0 01-1.2-3V245a11 11 0 01-.3-5.39 9.08 9.08 0 011.2-2.39 11 11 0 017.5-6 12.73 12.73 0 0111.41 1.8 238.52 238.52 0 0120.4 15.25.29.29 0 01.3.3c0-2.1-.3-6.88-3-16.75 0 0-16.5-61.31-16.8-62.21a6.53 6.53 0 01-.3-2.39 11.92 11.92 0 0123.4-3.89c.3 1.5 6.91 24.53 10.51 39.18a11.35 11.35 0 018.1-7.48 11.82 11.82 0 0113.81 6.28 15.14 15.14 0 011.5 3.89l.6 2.39a11.36 11.36 0 018.1-7.47 10.21 10.21 0 013-.3 11.59 11.59 0 017.51 2.69 11.11 11.11 0 014.5 6.28 17.12 17.12 0 011.2 4.19c1.2-3.59 4.5-6 8.4-6.88h.6a10.89 10.89 0 016.9.3 15.89 15.89 0 013 1.79 11.49 11.49 0 013.3 4.19 14 14 0 011.8 4.49l4.2 15.25a28.18 28.18 0 001.2 3.89c3.9 12.86 8.1 27.21 3.3 41a51.1 51.1 0 00-3.89 5.33z" />
      <Path d="M225.89 37.29a16.7 16.7 0 00-12-4.79H101.28A16.74 16.74 0 0084.5 49.25v144.5a16.74 16.74 0 0016.78 16.75h63.2c-4.79-17.95-10.78-40.39-11.08-41a12.76 12.76 0 01-.6-3.3 15.88 15.88 0 012.1-9 14.13 14.13 0 018.08-6.88v-3.29a4.09 4.09 0 014.2-4.19h12.58a4.08 4.08 0 014.19 4.19v12a4.34 4.34 0 01.6 2.1 19.13 19.13 0 00.9 3c1.5 5.68 4.79 17.35 7.49 27.82a7.24 7.24 0 012.1-1.19 3.26 3.26 0 01-.3-1.5V176.4a4.09 4.09 0 014.19-4.19h12.58a4.09 4.09 0 014.2 4.19V189a3.87 3.87 0 01-3 3.89 13.25 13.25 0 013.6 4.49 20.26 20.26 0 015.69-3 17.47 17.47 0 013.89-.6h.3a20.26 20.26 0 014.2.6V49.25a13.54 13.54 0 00-4.5-11.96zm-105.74 152a4.1 4.1 0 01-4.2 4.19h-12.58a4.1 4.1 0 01-4.19-4.19V176.4a4.09 4.09 0 014.19-4.19H116a4.09 4.09 0 014.2 4.19zm0-29.62a4.09 4.09 0 01-4.2 4.19h-12.58a4.09 4.09 0 01-4.19-4.19v-12.59a4.09 4.09 0 014.19-4.19H116a4.09 4.09 0 014.2 4.19zm0-29.31a4.09 4.09 0 01-4.2 4.18h-12.58a4.09 4.09 0 01-4.19-4.18v-12.6a4.1 4.1 0 014.19-4.19H116a4.1 4.1 0 014.2 4.19zm32 58.93a4.1 4.1 0 01-4.19 4.19h-12.53a4.1 4.1 0 01-4.2-4.19V176.4a4.09 4.09 0 014.2-4.19H148a4.09 4.09 0 014.19 4.19zm0-29.62a4.09 4.09 0 01-4.19 4.19h-12.53a4.09 4.09 0 01-4.2-4.19v-12.59a4.09 4.09 0 014.2-4.19H148a4.09 4.09 0 014.19 4.19zm0-29.31a4.09 4.09 0 01-4.19 4.18h-12.53a4.09 4.09 0 01-4.2-4.18v-12.6a4.1 4.1 0 014.2-4.19H148a4.1 4.1 0 014.19 4.19zm32.05 0a4.08 4.08 0 01-4.19 4.18h-12.53a4.09 4.09 0 01-4.2-4.18v-12.6a4.1 4.1 0 014.2-4.19h12.58a4.09 4.09 0 014.19 4.19zm32.06 29.31a4.09 4.09 0 01-4.2 4.19h-12.53a4.08 4.08 0 01-4.19-4.19v-12.59a4.08 4.08 0 014.19-4.19h12.58a4.09 4.09 0 014.2 4.19zm0-29.31a4.09 4.09 0 01-4.2 4.18h-12.53a4.08 4.08 0 01-4.19-4.18v-12.6a4.09 4.09 0 014.19-4.19h12.58a4.1 4.1 0 014.2 4.19zm0-38.3a8.57 8.57 0 01-8.39 8.38h-100.3A8.57 8.57 0 0199.18 92V53.74a8.57 8.57 0 018.39-8.38h100.05a8.57 8.57 0 018.38 8.38V92z" />
    </Svg>
  )
}

export default IconTools
