/**
 * @generated SignedSource<<e0d94eddcce0850675c2ac884ce73877>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppTabComponent = "DefaultTab" | "ResourceNodeSearchTab" | "ResourceNodeTab" | "ResourceNodeViewTab" | "TopicNodeTab" | "VideoShowcaseTab" | "%future added value";
export type ScreenLayoutHeights = "isShortScreen" | "isTallScreen" | "%future added value";
export type ScreenLayoutWidth = "isMediumOrSmallerScreen" | "isMediumScreen" | "isNarrowScreen" | "isWideScreen" | "%future added value";
export type TabPosition = "BOTTOM" | "LEFT" | "RIGHT" | "TOP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CpHomeTabView_tabsConfig$data = {
  readonly hideBarIfOneTab: boolean | null;
  readonly tabPositionRules: ReadonlyArray<{
    readonly invertMatch: boolean | null;
    readonly position: TabPosition;
    readonly rank: number | null;
    readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>;
    readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
  }> | null;
  readonly tabs: ReadonlyArray<{
    readonly _id: string;
    readonly isDefault: boolean | null;
    readonly tabLabel: string | null;
    readonly type: AppTabComponent;
    readonly " $fragmentSpreads": FragmentRefs<"CpResourceNodeSearchTab_tabData" | "CpResourceNodeTab_tabData" | "CpResourceNodeViewTab_tabData" | "CpTopicNodeTab_tabData">;
  }>;
  readonly " $fragmentType": "CpHomeTabView_tabsConfig";
};
export type CpHomeTabView_tabsConfig$key = {
  readonly " $data"?: CpHomeTabView_tabsConfig$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpHomeTabView_tabsConfig">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpHomeTabView_tabsConfig",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hideBarIfOneTab",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TabPositionRule",
      "kind": "LinkedField",
      "name": "tabPositionRules",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "screenHeightIs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "screenWidthIs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invertMatch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "position",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "tabs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDefault",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabLabel",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpTopicNodeTab_tabData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpResourceNodeTab_tabData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpResourceNodeViewTab_tabData"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpResourceNodeSearchTab_tabData"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AppHome",
  "abstractKey": null
};

(node as any).hash = "13ff1d071b157e4b384d78be55974b30";

export default node;
