import EventEmitter from "eventemitter3"
import { useEffect } from "react"

import useForceRender from "hooks/useForceRender"
import { FetchStatus } from "services/Constants"

interface CacheValue {
  status: FetchStatus
  value: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

function getKey(args: unknown): string {
  return JSON.stringify(args || null)
}

/**
 * Create useResource hook that handles the loading of an async resource. It takes any async function and will return a Ready Hook that will
 * - suspend when loading
 * - throw an error when an error occurs
 * - return the value synchronously once the promise resolves
 * - cause rerender if the value in the cache changes
 *
 * const useResource = createUseAsyncResource(async (args) => await someCoolValue(args))
 * const SomeComponent = () => {
 *   const data = useResource(args) // args are passed through to async function
 *   ...
 * }
 *
 * the hook will have some static methods to help out as well
 *
 * useResource.preload(args) - calls the async function and caches the promise, but doesn't throw/suspend/return value. This is useful if you want to start multiple things loading in parallel.
 * useResource.load(args) - does everything the hook will do except cause re-renders
 * useResource.invalidate(args) - this will remove the entry from the cache
 *
 * @returns A React Hook
 */
export function createUseAsyncResource<Data, Args = void>(
  getData: (args: Args) => Promise<Data>,
): ((args: Args) => Data) & {
  invalidate: (args: Args) => void
  preload: (args: Args) => void
  read: (args: Args) => Data
} {
  const cache: {
    [key: string]: CacheValue
  } = {}

  const emitter = new EventEmitter()

  const load = (args: Args): CacheValue => {
    const key = getKey(args)
    if (!cache[key]) {
      cache[key] = {
        status: FetchStatus.Pending,
        value: getData(args)
          .then((value) => {
            cache[key] = { status: FetchStatus.Resolved, value }
          })
          .catch((value) => {
            cache[key] = { status: FetchStatus.Rejected, value }
          }),
      }
    }
    return cache[key]
  }

  const read = (args: Args): Data => {
    const cached = load(args)

    switch (cached.status) {
      case FetchStatus.Pending:
        throw cached.value
      case FetchStatus.Rejected:
        throw cached.value
      case FetchStatus.Resolved:
        return cached.value
      default:
        throw new Error("unknown status")
    }
  }

  return Object.assign(
    (args: Args) => {
      const forceRender = useForceRender()
      const key = getKey(args)

      useEffect(() => {
        emitter.on(key, forceRender)
        return (): void => {
          emitter.off(key, forceRender)
        }
      }, [key, forceRender])

      return read(args)
    },
    {
      invalidate: (args: Args) => {
        const key = getKey(args)
        delete cache[key]
        emitter.emit(key)
      },
      preload: (args: Args) => {
        load(args)
      },
      read,
    },
  )
}
