/**
 * @generated SignedSource<<6b48fe5689fd0cc7025fbe3142d59b36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonalProfileUpdateInput = {
  firstName: string;
  lastName: string;
  preferredLocale: string;
  prefix?: string | null;
};
export type PractitionerProfileUpdateInput = {
  externalId: string;
  licenseCountryCode: string;
  licenseSubdivisionCode: string;
  otherType?: string | null;
  signupOrganizationName: string;
  specialties?: ReadonlyArray<string> | null;
  type: string;
};
export type CpProfileDetailsFormPractitionerMutation$variables = {
  personalInput: PersonalProfileUpdateInput;
  practitionerInput: PractitionerProfileUpdateInput;
};
export type CpProfileDetailsFormPractitionerMutation$data = {
  readonly personal: {
    readonly user: {
      readonly name: string | null;
      readonly personalProfile: {
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly preferredLocale: string;
        readonly prefix: string | null;
      } | null;
    };
  } | null;
  readonly practitioner: {
    readonly user: {
      readonly practitionerProfile: {
        readonly externalId: string | null;
        readonly licenseCountryCode: string | null;
        readonly licenseSubdivisionCode: string | null;
        readonly otherType: string | null;
        readonly signupOrganizationName: string | null;
        readonly specialties: ReadonlyArray<string> | null;
        readonly type: string | null;
      } | null;
    };
  } | null;
};
export type CpProfileDetailsFormPractitionerMutation = {
  response: CpProfileDetailsFormPractitionerMutation$data;
  variables: CpProfileDetailsFormPractitionerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "personalInput"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "practitionerInput"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "practitionerInput"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseCountryCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseSubdivisionCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signupOrganizationName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialties",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "personalInput"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredLocale",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpProfileDetailsFormPractitionerMutation",
    "selections": [
      {
        "alias": "practitioner",
        "args": (v1/*: any*/),
        "concreteType": "PractitionerProfileUpdatePayload",
        "kind": "LinkedField",
        "name": "practitionerProfileUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerProfile",
                "kind": "LinkedField",
                "name": "practitionerProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "personal",
        "args": (v9/*: any*/),
        "concreteType": "PersonalProfileUpdatePayload",
        "kind": "LinkedField",
        "name": "personalProfileUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalProfile",
                "kind": "LinkedField",
                "name": "personalProfile",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpProfileDetailsFormPractitionerMutation",
    "selections": [
      {
        "alias": "practitioner",
        "args": (v1/*: any*/),
        "concreteType": "PractitionerProfileUpdatePayload",
        "kind": "LinkedField",
        "name": "practitionerProfileUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerProfile",
                "kind": "LinkedField",
                "name": "practitionerProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "personal",
        "args": (v9/*: any*/),
        "concreteType": "PersonalProfileUpdatePayload",
        "kind": "LinkedField",
        "name": "personalProfileUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalProfile",
                "kind": "LinkedField",
                "name": "personalProfile",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f89935a3656b3a2e0d9f8d7d2e9d3b0",
    "id": null,
    "metadata": {},
    "name": "CpProfileDetailsFormPractitionerMutation",
    "operationKind": "mutation",
    "text": "mutation CpProfileDetailsFormPractitionerMutation(\n  $personalInput: PersonalProfileUpdateInput!\n  $practitionerInput: PractitionerProfileUpdateInput!\n) {\n  practitioner: practitionerProfileUpdate(input: $practitionerInput) {\n    user {\n      practitionerProfile {\n        externalId\n        licenseCountryCode\n        licenseSubdivisionCode\n        otherType\n        signupOrganizationName\n        specialties\n        type\n        id\n      }\n      id\n    }\n  }\n  personal: personalProfileUpdate(input: $personalInput) {\n    user {\n      name\n      personalProfile {\n        firstName\n        lastName\n        preferredLocale\n        prefix\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9c67e3152767c22c659523676058ccd3";

export default node;
