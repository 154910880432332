import { defineMessages } from "react-intl";
export const user = defineMessages({
  email: {
    id: "m3THOr",
    defaultMessage: "Email"
  },
  emailConfirmationCode: {
    id: "UAEZLK",
    defaultMessage: "Email confirmation code"
  },
  newPassword: {
    id: "lBTq9r",
    defaultMessage: "New account password"
  },
  password: {
    id: "tCJPO+",
    defaultMessage: "Account password"
  },
  passwordResetCode: {
    id: "YbDdRh",
    defaultMessage: "Password reset code"
  }
});
export const appConfiguration = defineMessages({
  advertisementSlots: {
    id: "qQSq6O",
    defaultMessage: "Advertisement Slots"
  },
  advertisements: {
    id: "7HTnzs",
    defaultMessage: "Advertisements"
  },
  configuration: {
    id: "v/yAU9",
    defaultMessage: "Configuration"
  },
  disclaimer: {
    id: "8Kg93/",
    defaultMessage: "Disclaimer"
  },
  footer: {
    id: "pS/+DO",
    defaultMessage: "Footer"
  },
  header: {
    id: "ImUxGm",
    defaultMessage: "Header"
  },
  hideAdvertisements: {
    id: "+FZk+O",
    defaultMessage: "Hide advertisements"
  },
  hideBacksplash: {
    id: "iB/GI3",
    defaultMessage: "Hide backsplash"
  },
  hideBarIfOneTab: {
    id: "h12WdF",
    defaultMessage: "Hide tabs (if only one tab)"
  },
  hideMenu: {
    id: "CdMK3u",
    defaultMessage: "Hide account menu"
  },
  hideSearch: {
    id: "W0NYK/",
    defaultMessage: "Hide search"
  },
  homeConfig: {
    id: "JsFD2c",
    defaultMessage: "Home"
  },
  justifyContent: {
    id: "do48iB",
    defaultMessage: "Justify content"
  },
  letterPicker: {
    id: "78BtAK",
    defaultMessage: "Alphabetical Index"
  },
  placeholder: {
    id: "nlHygh",
    defaultMessage: "Placeholder"
  },
  renderWithoutHeaders: {
    id: "vcA/5K",
    defaultMessage: "Render Without Headers"
  },
  search: {
    id: "jKW1tU",
    defaultMessage: "Search"
  },
  searchMethod: {
    id: "VgyXxI",
    defaultMessage: "Search Method"
  },
  showProfessionalContent: {
    id: "9LhHtf",
    defaultMessage: "Show professional content"
  },
  styles: {
    id: "lLg3rD",
    defaultMessage: "Styling"
  },
  tabParagraph: {
    id: "R+pSSu",
    defaultMessage: "Intro Paragraph"
  },
  tabPosition: {
    id: "vU+fLz",
    defaultMessage: "Tab Position"
  },
  tabs: {
    id: "PhGzh6",
    defaultMessage: "Tabs"
  },
  tagline: {
    id: "qCEVUd",
    defaultMessage: "Tagline"
  },
  tools: {
    id: "LNo66h",
    defaultMessage: "Tools"
  }
});
export const appStyles = defineMessages({
  buttonFont: {
    id: "tyxzKz",
    defaultMessage: "Button Font"
  },
  colorThemes: {
    id: "w+cjek",
    defaultMessage: "Color Themes"
  },
  defaultFont: {
    id: "00UchU",
    defaultMessage: "Default Font"
  },
  headingFont: {
    id: "dKfQx4",
    defaultMessage: "Heading Font"
  },
  systemDefault: {
    id: "MAtTyV",
    defaultMessage: "System Default"
  }
});