import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - keyboard_arrow_left
const KeyboardArrowLeft: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M20.563 22.125l-1.875 1.875-8-8 8-8 1.875 1.875-6.125 6.125z" />
    </Svg>
  )
}

export default KeyboardArrowLeft
