import React, { useEffect } from "react"

import { useNavigation } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import useAppThemeHelpers from "hooks/useAppThemeHelpers"
import useImdSession from "hooks/useImdSession"
import Routes, { RouteParams } from "navigators/Routes"

/**
 * The signin page for the admin app.
 */
const ScSignout: React.FC = () => {
  const { signout } = useImdSession()
  const { navigate } = useNavigation<StackNavigationProp<RouteParams>>()

  const { isCustomTheme, resetTheme } = useAppThemeHelpers()

  useEffect(() => {
    signout().then(() => {
      if (isCustomTheme) resetTheme()
      navigate(Routes.signin)
    })
  }, [isCustomTheme, navigate, resetTheme, signout])

  return null
}

export default ScSignout
