/**
 * @generated SignedSource<<fc563a0b3fd12ba4528bd0fb8c1633b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Locale = "en" | "es" | "fr" | "%future added value";
export type ResourceNodeFiltersInput = {
  text?: string | null;
  type?: string | null;
};
export type QlTopicSubtree_resourceNodeTreeQuery$variables = {
  filters?: ResourceNodeFiltersInput | null;
  id: string;
  locale?: Locale | null;
};
export type QlTopicSubtree_resourceNodeTreeQuery$data = {
  readonly node: {
    readonly _id?: string;
    readonly descendants?: ReadonlyArray<{
      readonly _id: string;
      readonly isSectionNode: boolean;
      readonly names: any;
      readonly parentId: string | null;
      readonly pinSelfToBottom: boolean;
      readonly pinSelfToTop: boolean;
      readonly rank: number;
      readonly shouldSortChildrenByRank: boolean;
      readonly type: string;
      readonly " $fragmentSpreads": FragmentRefs<"QlTopicSubtree_resourceNode">;
    }>;
    readonly names?: any;
    readonly shouldSortChildrenByRank?: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"QlTopicSubtree_resourceNode">;
  } | null;
};
export type QlTopicSubtree_resourceNodeTreeQuery = {
  response: QlTopicSubtree_resourceNodeTreeQuery$data;
  variables: QlTopicSubtree_resourceNodeTreeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "locale",
  "variableName": "locale"
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldSortChildrenByRank",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  (v5/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSectionNode",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToBottom",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToTop",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v16 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "QlTopicSubtree_resourceNode"
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLeaf",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceType",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Resource",
  "kind": "LinkedField",
  "name": "taggedResources",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    (v18/*: any*/),
    (v7/*: any*/),
    (v15/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlTopicSubtree_resourceNodeTreeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "descendants",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v4/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v7/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v8/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "type": "ResourceNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlTopicSubtree_resourceNodeTreeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v17/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "descendants",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v4/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v7/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v8/*: any*/),
                  (v15/*: any*/),
                  (v18/*: any*/),
                  (v17/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              (v18/*: any*/),
              (v19/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v11/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v15/*: any*/)
            ],
            "type": "ResourceNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6ecfc0ce5888aa8e92d070e368bb107a",
    "id": null,
    "metadata": {},
    "name": "QlTopicSubtree_resourceNodeTreeQuery",
    "operationKind": "query",
    "text": "query QlTopicSubtree_resourceNodeTreeQuery(\n  $id: ID!\n  $filters: ResourceNodeFiltersInput\n  $locale: Locale\n) {\n  node(id: $id) {\n    __typename\n    ... on ResourceNode {\n      _id\n      names(locale: $locale)\n      shouldSortChildrenByRank\n      descendants(filters: $filters, locale: $locale) {\n        rank\n        _id\n        parentId\n        isSectionNode\n        names(locale: $locale)\n        pinSelfToBottom\n        pinSelfToTop\n        shouldSortChildrenByRank\n        type\n        ...QlTopicSubtree_resourceNode\n        id\n      }\n      ...QlTopicSubtree_resourceNode\n    }\n    id\n  }\n}\n\nfragment CpTopicSubtreeResourceItem_resource on Resource {\n  _id\n  icon(locale: $locale)\n  names(locale: $locale)\n  type\n}\n\nfragment QlTopicSubtree_resourceNode on ResourceNode {\n  icon(locale: $locale)\n  id\n  _id\n  isLeaf\n  isSectionNode\n  names(locale: $locale)\n  pinSelfToBottom\n  pinSelfToTop\n  resourceType\n  revisionId\n  parentId\n  resourceNodeRevisionId\n  shouldSortChildrenByRank\n  taggedResources {\n    _id\n    ...CpTopicSubtreeResourceItem_resource\n    id\n  }\n  type\n}\n"
  }
};
})();

(node as any).hash = "a6849f299f885c79179219ef7b4b0517";

export default node;
