import React, { memo, useEffect, useState } from "react"
import { RelayEnvironmentProvider } from "react-relay"
import { Environment } from "relay-runtime"

import CpAppLoading from "components/app/CpAppLoading"
import useAppBackend from "hooks/useAppBackend"
import { makeRelayEnvironment } from "services/RelayEnvironment"

const CxRelayEnvironment: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Get backend for current region
  const { currentBackend } = useAppBackend()

  // The environment used by the app
  const [relayEnvironment, setRelayEnvironment] = useState<undefined | Environment>(undefined)

  // The environment the app should switch to once the app has time to unmount any previous environments
  const [pendingRelayEnvironment, setPendingRelayEnvironment] = useState<undefined | Environment>(
    undefined,
  )

  // Triggers a new pending environment when the backend changes
  useEffect(() => {
    setRelayEnvironment(undefined)
    setPendingRelayEnvironment(makeRelayEnvironment(currentBackend))
  }, [currentBackend])

  // Create / Update the relay environment when the backend changes
  useEffect(() => {
    if (!pendingRelayEnvironment) return undefined

    // Ensures there is a bit of time for components to unmount before changing the relay environment
    const newBackendTimeout = setTimeout(() => {
      setRelayEnvironment(pendingRelayEnvironment)
      setPendingRelayEnvironment(undefined)
    }, 100)
    return () => clearTimeout(newBackendTimeout)
  }, [pendingRelayEnvironment])

  // If the environment is in the process of changing unmount all child components. They will need to be remounted from start when the new env is ready.

  if (!relayEnvironment) {
    return <CpAppLoading />
  } else {
    return (
      <RelayEnvironmentProvider environment={relayEnvironment}>{children}</RelayEnvironmentProvider>
    )
  }
}

export default memo(CxRelayEnvironment)
