/**
 * Possible advertisement slots available for use.
 */
export enum AdSlots {
  hcpBox = "hcpBox",
  largeHcpLeaderboard = "largeHcpLeaderboard",
  largePatientLeaderboard = "largePatientLeaderboard",
  smallPatientLeaderboard = "smallPatientLeaderboard",
}

export enum AppThemes {
  Dark = "dark",
  Light = "light",
}

/**
 * The apps built on imd-react.
 */
export enum AppName {
  ImdAdmin = "imd-admin",
  ImdProvider = "imd-provider",
  ImdPublic = "imd-public",
  Unspecified = "unspecified",
}

/**
 * Header actions available in the header as a quick action &|| menu item
 */
export enum AppHeaderActions {
  ChangeClinic = "CHANGE_CLINIC",
  Educate = "EDUCATE",
  Help = "HELP",
  ImdForProfessionals = "IMD_FOR_PROFESSIONALS",
  ManageAccount = "MANAGE_ACCOUNT",
  Mode = "MODE",
  Navigate = "NAVIGATE",
  Packages = "PACKAGES",
  PickLanguage = "PICK_LANGUAGE",
  PickLanguageAndRegion = "PICK_LANGUAGE_AND_REGION",
  PickRegion = "PICK_REGION",
  Shortcuts = "SHORTCUTS",
  SignOut = "SIGN_OUT",
  Submenu = "SUBMENU",
}

/**
 * Header action placements
 */
export enum AppHeaderPlacements {
  Common = "COMMON_ACTION",
  Menu = "MENU_ACTION",
}

/**
 * The search types available within the app
 */
export enum AppSearchTypes {
  PublicAccessible = "public_accessible",
  OrgAccessible = "org_accessible",
  SingleTab = "single_tab",
  AllTabs = "all_tabs",
  FilterContent = "filter_content",
}

/**
 * The available positions for the app tabs
 */
export enum AppTabPositions {
  Top = "TOP",
  Right = "RIGHT",
  Bottom = "BOTTOM",
  Left = "LEFT",
}

/**
 * The tab types available on the home page
 */
export enum AppTabTypes {
  DefaultTab = "DefaultTab",
  TopicNodeTab = "TopicNodeTab",
  ResourceNodeSearchTab = "ResourceNodeSearchTab",
  ResourceNodeTab = "ResourceNodeTab",
  ResourceNodeViewTab = "ResourceNodeViewTab",
  VideoShowcaseTab = "VideoShowcaseTab",
}

/**
 * The tools available within the app.
 */
export enum AppTool {
  Draw = "Draw",
  Shortcuts = "Shortcuts",
  Educate = "Educate",
  Packages = "Packages",
}

/**
 * Status for asynchrously loaded resources.
 */
export enum FetchStatus {
  Pending,
  Resolved,
  Rejected,
}

// These enums must stay in sync with constants/GraphqlErrorCodess.json
export enum GraphqlErrorCode {
  Forbidden = "forbidden",
  Invalid = "invalid",
  InvalidTransition = "invalid_transition",
  NotFound = "not_found",
  Stale = "stale",
}

// String constants for screen sizes found in common.tsx, and screen_layout_interface.rb
export enum ScreenSizes {
  // ScreenLayoutHeights
  IsShortScreen = "isShortScreen",
  IsTallScreen = "isTallScreen",
  // ScreenLayoutWidth
  IsMediumOrSmallerScreen = "isMediumOrSmallerScreen",
  IsMediumScreen = "isMediumScreen",
  IsNarrowScreen = "isNarrowScreen",
  IsWideScreen = "isWideScreen",
}

// Submit Status enum for confirmation modals
export enum SubmitStatus {
  Error = "error",
  Successful = "successful",
  Waiting = "waiting",
}

export const imdLogoAspectRatio = 474 / 248

export const maxEmailLength = 320
export const maxPasswordLength = 72
