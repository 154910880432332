import Config from "services/Config"
import { version } from "../../../package.json"

export const getAppName = (): string => {
  return Config.APP_NAME
}

export const getBuildNumber = (): string => {
  return Config.COMMIT_HASH || "unknown - COMMIT_HASH should be set when building for deployment"
}

export const getCodepushBundle = (): string | undefined => undefined

export const setCodepushBundle = (_bundle: string): string | undefined => undefined

export const getVersion = (): string => version
