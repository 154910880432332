import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconSmokingCessation: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M23.79 182.28v-4.5a13.89 13.89 0 001-2.6c.8-7.6 1.3-15.3 2.4-22.9 2.3-16.5 8.1-32 16.1-46.5 13.8-25 32.9-45.2 57.7-59.7 22.8-13.4 47.3-21 73.9-21.4a14.67 14.67 0 004-1h3.6c1.2.3 2.4 1 3.5 1 24.3.1 47 6.8 68.3 18a154.09 154.09 0 0154.5 48.9q30 43.8 27.2 97.1a155.14 155.14 0 01-57.5 113.1c-21.1 16.9-44.9 27.9-71.8 32.3a160.44 160.44 0 01-57.4-.7 157.15 157.15 0 01-112.8-92c-7.4-17.1-11.9-35.3-11.9-54.2a20.89 20.89 0 00-.8-4.9zm59.3-74.8c-31.2 37-41.2 115.9 19.8 167.3 46.4 39 113.8 34 150.2 2.8q-84.9-85.05-170-170.1zm196.1 145.2c2.1-3.7 3.8-6.7 5.7-9.7 12.3-19.7 17.8-41.4 17.5-64.4a118.67 118.67 0 00-12.3-51.6 121.42 121.42 0 00-90.2-66.9 127.6 127.6 0 00-43.6.6 120.16 120.16 0 00-44.2 18.4c-3 2-3.5 3.2-.7 5.9 7.3 6.9 14.3 14.2 21.4 21.3l56.9 56.9 64.4 64.4c8.1 8 16.2 16.1 25.1 25.1z" />
      <Path d="M78.29 212.28v-26.9h4.6c23.5 0 46.9-.1 70.4.1a11.26 11.26 0 016.9 2.9c8.1 7.5 15.9 15.4 24.6 23.9zM266.89 179.18h-7.8c-.3-5.1-.4-10.1-1.1-15a12.68 12.68 0 00-12.7-11.2c-8.3-.1-16.6-.2-24.9 0-16.7.3-25.4-10.6-24-25.3.3-3.2-1.2-3.9-3.6-4.3-12.2-2.5-19.2-10.2-22.2-22-2.4-9.2 1.5-16.8 6.8-23.7 4.6-6 11.1-8.5 18.9-8.4v7.5a17 17 0 01-3.4 1.2c-10.6 1.6-14.9 12.9-14.2 19.8 1.3 11.4 8.3 16.8 19 18.2 1 .1 2.1 0 3.1.1 5 .3 6.3 2.4 5.1 7.3-.8 3.4-1.8 7.1-1.1 10.3 1.6 8.1 5.4 10.5 13.8 10.6 9.5.2 19 .2 28.4.9 11.8.8 18.7 8.9 19.8 19.5.5 4.4.1 9.2.1 14.5z" />
      <Path d="M207.89 85.78v-7c7.9-1.9 20.5 4.6 25.8 13.2 5.8 9.4 4.8 19.4 1.9 30.1 15-.9 27.9 2.7 37.3 14.9 9.8 12.7 8.8 27.4 8.2 42.3h-7.8c0-4.3.2-8.4 0-12.5-.5-8.2-1.3-16.3-6.3-23.5-6.5-9.4-15.9-12.2-26.3-13-3.7-.3-7.4.2-11.1-.1-1.8-.2-4.6-.9-5.1-2.2-.7-1.6 0-4.3 1-5.9 3.9-6.2 5.4-12.8 3.4-19.8-3-10.6-11-14.7-21-16.5zM252.29 212c-8.5.6-9.9-.1-13.7-4.5-8.1-7.7-16.7-14.8-23.6-22.2h7.8c8.5 0 16.9.1 25.4-.1 2.9-.1 4.3.6 4.2 3.9-.2 7.78-.1 15.38-.1 22.9zM258.89 211.68v-26.6h7.8v26.6zM281 211.78h-7.4v-26.7h7.4z" />
    </Svg>
  )
}

export default IconSmokingCessation
