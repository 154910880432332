import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconTopic: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M21.2212709,8 C21.9600526,8 22.6627749,8.30629761 23.1648602,8.84084626 L23.2861063,8.97919349 L26.494389,12.9049304 C26.9618919,13.4769789 27.6437466,13.8272802 28.3753755,13.8777879 L28.5592244,13.8841239 L56.505741,13.8841239 C57.9218557,13.8841239 59.0801561,14.9879583 59.1671613,16.3821464 L59.1724076,16.5507906 L59.1724076,53.3333333 C59.1724076,54.7494481 58.0685732,55.9077485 56.6743852,55.9947537 L56.505741,56 L6.66666668,56 C5.25055193,56 4.09225154,54.8961656 4.00524635,53.5019775 L4.00000001,53.3333333 L4.00000001,14.8343917 C4.00000001,14.2880948 4.16773138,13.756703 4.47797356,13.3109908 L4.60094531,13.1480032 L8.00334212,8.98027812 C8.47083083,8.40763344 9.15303445,8.05691238 9.88510292,8.00634372 L10.0690635,8 L21.2212709,8 Z M21.4432666,12.04295 L11.0916988,12.04295 C10.8066307,12.04295 10.5344983,12.1594067 10.3409217,12.3642385 L7.50597354,15.3640209 C7.33113289,15.5490274 7.23401699,15.7917765 7.23401699,16.0437986 L7.23401699,51.4418837 C7.23401699,51.9947574 7.69191045,52.44295 8.25675065,52.44295 L54.7911313,52.44295 C55.3559715,52.44295 55.813865,51.9947574 55.813865,51.4418837 L55.813865,18.3100316 C55.813865,17.7571579 55.3559715,17.3089653 54.7911313,17.3089653 L27.0740907,17.3089653 C26.7789635,17.3089653 26.4982232,17.1841761 26.3040108,16.9666643 L22.2133465,12.385251 C22.019134,12.1677392 21.7383938,12.04295 21.4432666,12.04295 Z M35.5,24.5 L35.5,30.999 L42,31 L42,39 L35.5,39 L35.5,45.5 L27.5,45.5 L27.5,39 L21,39 L21,31 L27.5,31 L27.5,24.5 L35.5,24.5 Z" />
    </Svg>
  )
}

export default IconTopic
