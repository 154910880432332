import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconSurveys: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M118 164.6a7.92 7.92 0 01.8-11.2 8.05 8.05 0 0111.3.8l13.2 15.3 20.7-24.1a8.15 8.15 0 0111.3-.9 8 8 0 01.9 11.2l-26.7 31.2a7.91 7.91 0 01-12.1 0L118 164.6zm8 42.4h42a6 6 0 016 6v42a6 6 0 01-6 6h-42a6 6 0 01-6-6v-42a6 6 0 016-6zm36 12h-30v30h30v-30zm32-12h42a6 6 0 016 6v42a6 6 0 01-6 6h-42a6 6 0 01-6-6v-42a6 6 0 016-6zm36 12h-30v30h30v-30zm-36-80h42a6 6 0 016 6v42a6 6 0 01-6 6h-42a6 6 0 01-6-6v-42a6 6 0 016-6zm36 12h-30v30h30v-30z" />
      <Path d="M285 107v175a26.08 26.08 0 01-26 26H101a26.08 26.08 0 01-26-26V107a26.08 26.08 0 0126-26h26v20h-26a6 6 0 00-6 6v175a6 6 0 006 6h158a6 6 0 006-6V107a6 6 0 00-6-6h-26V81h26a26.08 26.08 0 0126 26zM180 70a11 11 0 1011 11 11 11 0 00-11-11zm45 11h-16c0-25.7-31.3-38.7-49.5-20.5A28.85 28.85 0 00151 81h-16v20a12 12 0 0012 12h66a12 12 0 0012-12V81z" />
    </Svg>
  )
}

export default IconSurveys
