import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconElearning: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M302.85 246.2h-97.7v6.3a6.27 6.27 0 01-6.3 6.3h-37.8a6.27 6.27 0 01-6.3-6.3v-6.3h-97.7a6.27 6.27 0 00-6.3 6.3v12.6a6.27 6.27 0 006.3 6.3H303a6.27 6.27 0 006.3-6.3v-12.6a6.42 6.42 0 00-6.4-6.3zM268.25 88.6H91.65a12.55 12.55 0 00-12.6 12.6v138.7h201.7V101.2a12.46 12.46 0 00-12.5-12.6zm0 138.7H91.65V101.2h176.5v126.1z" />
      <Path d="M246.15 155.6L180 122.5l-66.2 33.1 66.2 33.1 66.2-33.1zm-97.7 20.5v16.5c0 18.9 63 18.9 63 0v-16.5L180 191.8l-31.5-15.7zm100.8 22.8L246 186.3a2.72 2.72 0 001.7-2.5 2.92 2.92 0 00-1.6-2.5v-22.6l-3.2 1.6v21a3.11 3.11 0 00-1.6 2.7 2.46 2.46 0 001.7 2.3l-3.3 12.6z" />
    </Svg>
  )
}

export default IconElearning
