/**
 * @generated SignedSource<<b1c68430400f5b0bed766cf8cc396a01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadAnnotationInput = {
  mimeType: string;
};
export type CpDrawingToolsButtons_PresignedUrlMutation$variables = {
  input: UploadAnnotationInput;
};
export type CpDrawingToolsButtons_PresignedUrlMutation$data = {
  readonly uploadAnnotation: {
    readonly fields: {
      readonly contentType: string;
      readonly key: string;
      readonly policy: string | null;
      readonly xAmzAlgorithm: string | null;
      readonly xAmzCredential: string | null;
      readonly xAmzDate: string | null;
      readonly xAmzMetaOriginalFilename: string;
      readonly xAmzSecurityToken: string | null;
      readonly xAmzSignature: string | null;
    };
    readonly url: string;
  } | null;
};
export type CpDrawingToolsButtons_PresignedUrlMutation = {
  response: CpDrawingToolsButtons_PresignedUrlMutation$data;
  variables: CpDrawingToolsButtons_PresignedUrlMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "policy",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contentType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xAmzAlgorithm",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xAmzCredential",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xAmzDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xAmzMetaOriginalFilename",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xAmzSecurityToken",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "xAmzSignature",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpDrawingToolsButtons_PresignedUrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadAnnotationPayload",
        "kind": "LinkedField",
        "name": "uploadAnnotation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Presigned",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpDrawingToolsButtons_PresignedUrlMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UploadAnnotationPayload",
        "kind": "LinkedField",
        "name": "uploadAnnotation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Presigned",
            "kind": "LinkedField",
            "name": "fields",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c12495ba4f15abcfa618dbb919a93ca",
    "id": null,
    "metadata": {},
    "name": "CpDrawingToolsButtons_PresignedUrlMutation",
    "operationKind": "mutation",
    "text": "mutation CpDrawingToolsButtons_PresignedUrlMutation(\n  $input: UploadAnnotationInput!\n) {\n  uploadAnnotation(input: $input) {\n    url\n    fields {\n      policy\n      key\n      contentType\n      xAmzAlgorithm\n      xAmzCredential\n      xAmzDate\n      xAmzMetaOriginalFilename\n      xAmzSecurityToken\n      xAmzSignature\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d134c439bee1c56e3f5fae41b7fd4015";

export default node;
