import React, { createContext, memo, useMemo } from "react"
import { ScaledSize, useWindowDimensions } from "react-native"

import { SizeBreakdowns, getSizeBreakdowns } from "services/Theme/common"

export interface ScreenLayoutContextType extends ScaledSize, SizeBreakdowns {
  contentMinHeight: number
  footerHeight: number
  headerHeight: number
  setFooterHeight: (height: number) => void
  setHeaderHeight: (height: number) => void
}

const defaultSizeBreakdowns = getSizeBreakdowns(window.innerWidth, window.innerHeight)

/**
 * Contains screen information along with determination of the class of device. This is populated in the `App` definition.
 */
export const ScreenLayoutContext = createContext<ScreenLayoutContextType>({
  ...defaultSizeBreakdowns,
  contentMinHeight: 0,
  fontScale: 0,
  footerHeight: 0,
  headerHeight: 0,
  height: 0,
  scale: 1,
  setFooterHeight: () => {
    return
  },
  setHeaderHeight: () => {
    return
  },
  width: 0,
})

/**
 * Tracks the window size in one place, so as not to set up a whole bunch of listeners for ever call to `useScreenLayout`
 */
const CxScreenLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const screenLayout = useWindowDimensions()
  const { height, width } = screenLayout

  const [footerHeight, setFooterHeight] = React.useState(0)
  const [headerHeight, setHeaderHeight] = React.useState(0)

  const contentMinHeight = height - footerHeight - headerHeight

  const handleSetFooterHeight = React.useCallback(
    (newHeight: number) => {
      setFooterHeight(newHeight)
    },
    [setFooterHeight],
  )

  const handleSetHeaderHeight = React.useCallback(
    (newHeight: number) => {
      setHeaderHeight(newHeight)
    },
    [setHeaderHeight],
  )

  const screenSizes = useMemo<ScreenLayoutContextType>(() => {
    return {
      ...screenLayout,
      ...getSizeBreakdowns(width, height),
      contentMinHeight,
      footerHeight,
      headerHeight,
      setFooterHeight: handleSetFooterHeight,
      setHeaderHeight: handleSetHeaderHeight,
    }
  }, [
    contentMinHeight,
    footerHeight,
    handleSetFooterHeight,
    handleSetHeaderHeight,
    headerHeight,
    height,
    screenLayout,
    width,
  ])

  return <ScreenLayoutContext.Provider value={screenSizes}>{children}</ScreenLayoutContext.Provider>
}

export default memo(CxScreenLayout)
