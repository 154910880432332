import Color from "color";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Platform, Pressable } from "react-native";
import useAppTheme from "hooks/useAppTheme";

/**
 * An extension of pressable that applies theming to the background color based on the 3 pressable states:
 * focused, hovered, pressed
 */
const PrPressable = ({
  accessibilityRole = "button",
  accessibilityState,
  children,
  color,
  hoveredBackgroundColor,
  onAuxClick,
  onPress,
  pressedBackgroundColor,
  style,
  ...otherProps
}) => {
  const {
    buttonColors,
    spacing
  } = useAppTheme();
  const defaultStyle = useMemo(() => ({
    backgroundColor: color ? buttonColors[color] : undefined,
    // NB: For some reason padding has super precedence that will override the passed style prop, but this works
    paddingBottom: spacing.single,
    paddingLeft: spacing.single,
    paddingRight: spacing.single,
    paddingTop: spacing.single
  }), [buttonColors, color, spacing]);
  const statefulStyle = useCallback(({
    focused,
    hovered,
    pressed
  }) => {
    const backgroundEffectColor = buttonColors[color || "primary"];
    const newStyle = [defaultStyle, typeof style === "function" ? style({
      focused,
      hovered,
      pressed
    }) : style]; // The problem with applying background color when focused, is that this completely overrides any background color passed as part of the base style
    // if (focused) {
    //   newStyle.push({
    //     backgroundColor: focusedBackgroundColor
    //       ? buttonColors[focusedBackgroundColor]
    //       : undefined,
    //   })
    // }

    if (hovered) {
      newStyle.push({
        backgroundColor: hoveredBackgroundColor ? buttonColors[hoveredBackgroundColor] : Color(backgroundEffectColor).alpha(0.2).string()
      });
    }

    if (pressed) {
      newStyle.push({
        backgroundColor: pressedBackgroundColor ? buttonColors[pressedBackgroundColor] : Color(backgroundEffectColor).lighten(0.2).string()
      });
    }

    return newStyle;
  }, [buttonColors, color, defaultStyle, hoveredBackgroundColor, pressedBackgroundColor, style]);
  const localAccessibilityState = {
    disabled: otherProps.disabled || undefined,
    ...accessibilityState
  }; // Registers the middle mouse button event for web

  const pressableRef = useRef(null);
  useEffect(() => {
    if (Platform.OS === "web" && pressableRef.current && onAuxClick) {
      const pressableDiv = pressableRef.current; // Middle mouse button should trigger the aux click

      const onlyAcceptMiddleClick = function (ev) {
        if (ev.button === 1) onAuxClick(ev);
      }; // Control + Click on Windows/Linux, and Command + Click on Mac is the same as middle click


      const onlyAcceptMetaClick = function (ev) {
        if ((ev.ctrlKey || ev.metaKey) && ev.button === 0) onAuxClick(ev);
      }; // Control + Enter on Windows/Linux, and Command + Enter on Mac is the same as middle click


      const onlyAcceptMetaEnter = function (ev) {
        if ((ev.ctrlKey || ev.metaKey) && ev.key === "Enter") onAuxClick(ev);
      }; // Control + Enter on Windows/Linux, and Command + Enter on Mac is the same as middle click


      const onlyAcceptEnter = function (ev) {
        if (!ev.ctrlKey && !ev.metaKey && !ev.altKey && ev.key === "Enter") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onPress?.(ev);
        }
      };

      pressableDiv.addEventListener("auxclick", onlyAcceptMiddleClick);
      pressableDiv.addEventListener("click", onlyAcceptMetaClick);
      pressableDiv.addEventListener("keypress", onlyAcceptMetaEnter);
      pressableDiv.addEventListener("keypress", onlyAcceptEnter); // Can be used in tests to find a button with aux press functionality

      pressableDiv.dataset.auxpress = "1";
      return () => {
        pressableDiv.removeEventListener("auxclick", onlyAcceptMiddleClick);
        pressableDiv.removeEventListener("click", onlyAcceptMetaClick);
        pressableDiv.removeEventListener("keypress", onlyAcceptMetaEnter);
        pressableDiv.removeEventListener("keypress", onlyAcceptEnter);
      };
    }

    return;
  }, [onAuxClick, onPress]);
  return <Pressable accessibilityRole={accessibilityRole} accessibilityState={localAccessibilityState} onLongPress={onAuxClick} onPress={onPress} ref={pressableRef} style={statefulStyle} {...otherProps}>
      {children}
    </Pressable>;
};

export default PrPressable;