// Apparently ES-Lint doesn't consider =0, =1 format to be a valid plural format.
import { defineMessages } from "react-intl";
export default defineMessages({
  "imd-admin": {
    id: "mXiaWG",
    defaultMessage: "IMD Admin"
  },
  "imd-provider": {
    id: "gbCB2a",
    defaultMessage: "IMD Pro"
  },
  "imd-public": {
    id: "S+qSW8",
    defaultMessage: "IMD"
  },
  unspecified: {
    id: "lUYij0",
    defaultMessage: "IMD"
  }
});