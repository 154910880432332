import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Font Awesome - lock-closed
const LockClosed: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 24 24" width={height} {...otherProps}>
      <Path d="M7.75 14C7.75 14.4375 7.40625 14.75 7 14.75C6.5625 14.75 6.25 14.4375 6.25 14V12C6.25 11.5938 6.5625 11.25 7 11.25C7.40625 11.25 7.75 11.5938 7.75 12V14ZM7 2C9.1875 2 11 3.8125 11 6V8H12C13.0938 8 14 8.90625 14 10V16C14 17.125 13.0938 18 12 18H2C0.875 18 0 17.125 0 16V10C0 8.90625 0.875 8 2 8H3V6C3 3.8125 4.78125 2 7 2ZM7 3.5C5.59375 3.5 4.5 4.625 4.5 6V8H9.5V6C9.5 4.625 8.375 3.5 7 3.5ZM2 9.5C1.71875 9.5 1.5 9.75 1.5 10V16C1.5 16.2812 1.71875 16.5 2 16.5H12C12.25 16.5 12.5 16.2812 12.5 16V10C12.5 9.75 12.25 9.5 12 9.5H2Z" />
    </Svg>
  )
}

export default LockClosed
