import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - keyboard_arrow_up
const KeyboardArrowUp: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M9.875 20.563l-1.875-1.875 8-8 8 8-1.875 1.875-6.125-6.125z" />
    </Svg>
  )
}

export default KeyboardArrowUp
