/**
 * @generated SignedSource<<82f1cec8b3758f889a84504d7bcc7311>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PractitionerProfileCreateInput = {
  externalId: string;
  licenseCountryCode: string;
  licenseSubdivisionCode: string;
  otherType?: string | null;
  signupOrganizationName: string;
  specialties?: ReadonlyArray<string> | null;
  type: string;
};
export type CpAccountPractitionerProfileWizardProfileMutation$variables = {
  input: PractitionerProfileCreateInput;
};
export type CpAccountPractitionerProfileWizardProfileMutation$data = {
  readonly practitionerProfileCreate: {
    readonly user: {
      readonly practitionerProfile: {
        readonly externalId: string | null;
        readonly licenseCountryCode: string | null;
        readonly licenseSubdivisionCode: string | null;
        readonly otherType: string | null;
        readonly signupOrganizationName: string | null;
        readonly specialties: ReadonlyArray<string> | null;
        readonly type: string | null;
      } | null;
    };
  } | null;
};
export type CpAccountPractitionerProfileWizardProfileMutation = {
  response: CpAccountPractitionerProfileWizardProfileMutation$data;
  variables: CpAccountPractitionerProfileWizardProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseCountryCode",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "licenseSubdivisionCode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "otherType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signupOrganizationName",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "specialties",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpAccountPractitionerProfileWizardProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PractitionerProfileCreatePayload",
        "kind": "LinkedField",
        "name": "practitionerProfileCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerProfile",
                "kind": "LinkedField",
                "name": "practitionerProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpAccountPractitionerProfileWizardProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PractitionerProfileCreatePayload",
        "kind": "LinkedField",
        "name": "practitionerProfileCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerProfile",
                "kind": "LinkedField",
                "name": "practitionerProfile",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebd4e26143698c135d2186f147cd42c5",
    "id": null,
    "metadata": {},
    "name": "CpAccountPractitionerProfileWizardProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CpAccountPractitionerProfileWizardProfileMutation(\n  $input: PractitionerProfileCreateInput!\n) {\n  practitionerProfileCreate(input: $input) {\n    user {\n      practitionerProfile {\n        externalId\n        licenseCountryCode\n        licenseSubdivisionCode\n        otherType\n        signupOrganizationName\n        specialties\n        type\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a758826b7871ffedc471796334a56fb3";

export default node;
