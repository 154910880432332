import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom - based on Material play_arrow
const ArrowRight: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 24 24" width={height} {...otherProps}>
      <Path
        d="M21.3041908,3.61820469 C22.1675454,3.8857381 22.1675454,4.7659197 21.6322755,5.71665422 L13.0468368,20.0744408 C12.6171129,20.7085922 11.3265773,20.7936348 10.8943748,20.0744408 L2.30893608,5.71665422 C1.7917226,4.79159538 1.93516073,3.8857381 2.65507711,3.61820469 C3.37499348,3.35067128 20.4408362,3.35067128 21.3041908,3.61820469 Z"
        transform="translate(12.000000, 12.000000) scale(-1, 1) rotate(90.000000) translate(-12.000000, -12.000000) "
      />
    </Svg>
  )
}

export default ArrowRight
