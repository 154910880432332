import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconFemale: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M161.46 141.07v.15c1.84 9 5.84 15.11 10.61 18.3a14.29 14.29 0 0016.35 0c4.77-3.19 8.77-9.27 10.61-18.3v-.15l7.74 2c-2.32 11.15-7.58 18.87-13.94 23.12a22.12 22.12 0 01-25.23 0c-6.36-4.25-11.62-12-13.94-23.12l7.74-2z" />
      <Path d="M180.28 52.06a31.35 31.35 0 014.38.31A28.47 28.47 0 01204.75 65c7.38 10.84 6.55 21.67 6.53 33.68v3.81c0 6.59.73 12.22 2.48 16.68a17.67 17.67 0 007.54 9.17 6 6 0 11-6 10.31 29.31 29.31 0 01-12.65-15.12c-.39-1-.74-2-1.05-3-.29.43-.58.87-.88 1.29-5.37 7.63-12.4 12.33-20.41 12.33s-15.05-4.7-20.42-12.33q-.45-.63-.87-1.29c-.31 1-.66 2.06-1.05 3a29.31 29.31 0 01-12.65 15.12 6 6 0 01-6-10.31 17.67 17.67 0 007.54-9.17c1.75-4.46 2.48-10.09 2.48-16.68v-3.81c0-12.1-.88-22.81 6.52-33.68a28.5 28.5 0 0120.09-12.59 31.38 31.38 0 014.39-.31zm49.65 221.82a250.15 250.15 0 017.35 32.92 6.06 6.06 0 01.13 1.21H123.16a6.58 6.58 0 01.06-.87 250.07 250.07 0 017.41-33.26 225.47 225.47 0 01-8.61-25.6c-4-14.61-5.76-26.51-5.76-29.83 0-3.4 2.19-27.22 7.35-45.47 3.09-10.95 7.48-20.36 13.63-23.91l1.8-1.13a6 6 0 019.38 3.87c1.86 10.8 5.81 18.1 10.83 22.81 5.92 5.56 13.56 7.63 21 7.63s15.08-2.07 21-7.63c5-4.71 9-12 10.83-22.81a6.09 6.09 0 01.84-2.18 6 6 0 018.25-1.87l2.08 1.3c6.14 3.55 10.53 12.95 13.62 23.9 5.16 18.25 7.35 42.07 7.35 45.47 0 3.32-1.77 15.22-5.76 29.83a225.47 225.47 0 01-8.61 25.6zm-10.7-96.14c-.36 2.81.21 6 .76 9.11 1.49 8.34 2.95 16.55-5.71 25.34 2.08 16.38 5.85 29.61 9.85 42.63 1.07-3.33 2-6.58 2.88-9.68 3.67-13.42 5.29-23.91 5.29-26.69 0-3.09-2.07-25-6.93-42.23a87.93 87.93 0 00-3.13-9.35c-1.08 3.57-2.1 7.21-3 10.87zm-72.95 34.45c-8.66-8.79-7.2-17-5.72-25.34.56-3.14 1.13-6.3.77-9.12-.93-3.66-2-7.29-3-10.86a87.93 87.93 0 00-3.13 9.35c-4.86 17.2-6.93 39.14-6.93 42.23 0 2.78 1.62 13.27 5.29 26.69.85 3.1 1.8 6.35 2.88 9.68 4-13 7.76-26.25 9.85-42.63zm15-123.59a78.24 78.24 0 0015.24-8.15 85.41 85.41 0 0014.61-12.79 16.4 16.4 0 00-8.08-3.43 22.9 22.9 0 00-5.57 0 16.79 16.79 0 00-11.8 7.48 28.3 28.3 0 00-4.41 15.81v1.08zm34.9-14.66A91.74 91.74 0 01181 87a85.89 85.89 0 01-18.68 9.69 49.41 49.41 0 007.3 18.11c3.15 4.47 6.84 7.22 10.63 7.22s7.47-2.75 10.62-7.22c3.94-5.62 6.83-13.62 8.07-22.58a40.86 40.86 0 00.31-4.74 29.6 29.6 0 00-3.09-13.58z" />
    </Svg>
  )
}

export default IconFemale
