/**
 * @generated SignedSource<<120b0f7b0f53c22856ffe8695281f5d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QlTopic_combo$data = {
  readonly id: string;
  readonly names: any;
  readonly revisionId: string;
  readonly root: {
    readonly id: string;
    readonly revisionId: string;
  };
  readonly taggedResources: ReadonlyArray<{
    readonly _id: string;
    readonly names: any;
  }> | null;
  readonly type: string;
  readonly " $fragmentSpreads": FragmentRefs<"CpPublisherAttribution_resourceNode" | "CpResourceNode_resourceNode" | "CpShortcutButton_resourceNode" | "CpTopicBreadcrumbs_resourceNode" | "CpTopicDepthOneAncestorButton_resourceNode" | "CpTopicHeading_resourceNode" | "CpTopicParentButton_resourceNode" | "CpTopicSiblingNavigator_resourceNode" | "CpTopicTableOfContentsCommon_selectedResourceNode" | "CtTopicSubtreeNavigator_data">;
  readonly " $fragmentType": "QlTopic_combo";
};
export type QlTopic_combo$key = {
  readonly " $data"?: QlTopic_combo$data;
  readonly " $fragmentSpreads": FragmentRefs<"QlTopic_combo">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "locale",
      "variableName": "locale"
    }
  ],
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "QlTopic_combo",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "taggedResources",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtTopicSubtreeNavigator_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpPublisherAttribution_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpResourceNode_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpShortcutButton_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpTopicDepthOneAncestorButton_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpTopicParentButton_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpTopicBreadcrumbs_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpTopicHeading_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpTopicSiblingNavigator_resourceNode"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CpTopicTableOfContentsCommon_selectedResourceNode"
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "4f255eb0ecc7d55c38ffb4b99a307903";

export default node;
