import { defineMessages } from "react-intl";
export default defineMessages({
  friday: {
    id: "d67UxP",
    defaultMessage: "Friday"
  },
  monday: {
    id: "qQ/ZpI",
    defaultMessage: "Monday"
  },
  saturday: {
    id: "rNI7aY",
    defaultMessage: "Saturday"
  },
  sunday: {
    id: "iIrISF",
    defaultMessage: "Sunday"
  },
  thursday: {
    id: "vHpx5k",
    defaultMessage: "Thursday"
  },
  tuesday: {
    id: "GfSnIP",
    defaultMessage: "Tuesday"
  },
  wednesday: {
    id: "y3Lfez",
    defaultMessage: "Wednesday"
  }
});