/**
 * @generated SignedSource<<421a6be7b521957921563093b1f0164b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlTopicToc_resourceNodeTreeQuery$variables = {
  locale?: Locale | null;
  rootRevisionId: string;
};
export type QlTopicToc_resourceNodeTreeQuery$data = {
  readonly normalToc: {
    readonly " $fragmentSpreads": FragmentRefs<"CpTopicTableOfContents_rootResourceNode">;
  } | null;
  readonly presentationToc: {
    readonly " $fragmentSpreads": FragmentRefs<"CpTopicPresentationTableOfContents_topicPresentationTocFragment">;
  } | null;
};
export type QlTopicToc_resourceNodeTreeQuery = {
  response: QlTopicToc_resourceNodeTreeQuery$data;
  variables: QlTopicToc_resourceNodeTreeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rootRevisionId"
},
v2 = {
  "kind": "Variable",
  "name": "rootRevisionId",
  "variableName": "rootRevisionId"
},
v3 = [
  {
    "kind": "Literal",
    "name": "presentation",
    "value": false
  },
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Literal",
    "name": "presentation",
    "value": true
  },
  (v2/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTaggedResources",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToBottom",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToTop",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceId",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldSortChildrenByRank",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSectionNode",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ResourceNode",
  "kind": "LinkedField",
  "name": "defaultResourceNode",
  "plural": false,
  "selections": [
    (v15/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlTopicToc_resourceNodeTreeQuery",
    "selections": [
      {
        "alias": "normalToc",
        "args": (v3/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeToc",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CpTopicTableOfContents_rootResourceNode"
          }
        ],
        "storageKey": null
      },
      {
        "alias": "presentationToc",
        "args": (v4/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeToc",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CpTopicPresentationTableOfContents_topicPresentationTocFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlTopicToc_resourceNodeTreeQuery",
    "selections": [
      {
        "alias": "normalToc",
        "args": (v3/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeToc",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ResourceNode",
            "kind": "LinkedField",
            "name": "descendants",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ResourceNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": "presentationToc",
        "args": (v4/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeToc",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v15/*: any*/),
          (v20/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TrainingNodeCompletion",
            "kind": "LinkedField",
            "name": "trainingNodeCompletions",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v18/*: any*/),
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ResourceNode",
            "kind": "LinkedField",
            "name": "descendants",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v18/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentRevisionId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isLeaf",
                "storageKey": null
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "872d28b0d116dcf89a522f205f6ffe4e",
    "id": null,
    "metadata": {},
    "name": "QlTopicToc_resourceNodeTreeQuery",
    "operationKind": "query",
    "text": "query QlTopicToc_resourceNodeTreeQuery(\n  $rootRevisionId: String!\n  $locale: Locale\n) {\n  normalToc: resourceNodeToc(rootRevisionId: $rootRevisionId, presentation: false) {\n    ...CpTopicTableOfContents_rootResourceNode\n    id\n  }\n  presentationToc: resourceNodeToc(rootRevisionId: $rootRevisionId, presentation: true) {\n    ...CpTopicPresentationTableOfContents_topicPresentationTocFragment\n    id\n  }\n}\n\nfragment CpTopicPresentationTableOfContents_topicPresentationTocFragment on ResourceNode {\n  _id\n  revisionId\n  defaultResourceNode {\n    revisionId\n    id\n  }\n  trainingNodeCompletions {\n    resourceNodeRevisionId\n    id\n  }\n  type\n  descendants(locale: $locale) {\n    _id\n    type\n    revisionId\n    parentRevisionId\n    isLeaf\n    id\n  }\n  ...useDescendantsAsTree_rootResourceNode\n}\n\nfragment CpTopicTableOfContents_rootResourceNode on ResourceNode {\n  ...useDescendantsAsTree_rootResourceNode\n  children(locale: $locale) {\n    _id\n    id\n    names(locale: $locale)\n    rank\n    resourceId\n    resourceNodeRevisionId\n    revisionId\n    type\n  }\n  defaultResourceNode {\n    revisionId\n    id\n  }\n  revisionId\n  id\n}\n\nfragment useDescendantsAsTree_rootResourceNode on ResourceNode {\n  descendants(locale: $locale) {\n    _id\n    id\n    hasTaggedResources\n    names(locale: $locale)\n    pinSelfToBottom\n    pinSelfToTop\n    rank\n    resourceId\n    resourceNodeRevisionId\n    revisionId\n    shouldSortChildrenByRank\n    parentId\n    type\n    isSectionNode\n  }\n  _id\n  id\n  hasTaggedResources\n  names(locale: $locale)\n  pinSelfToBottom\n  pinSelfToTop\n  rank\n  resourceId\n  resourceNodeRevisionId\n  revisionId\n  shouldSortChildrenByRank\n  parentId\n  type\n  isSectionNode\n}\n"
  }
};
})();

(node as any).hash = "c7c22bbd38eb2b163ed2586ebcf3c6f8";

export default node;
