import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpPackageSessionDeleteTemplateMutation;

import React, { useCallback, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import { graphql, useMutation } from "react-relay/hooks";
import CpError from "components/common/CpError";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const templateDeleteMutation = _CpPackageSessionDeleteTemplateMutation !== void 0 ? _CpPackageSessionDeleteTemplateMutation : _CpPackageSessionDeleteTemplateMutation = require("./__generated__/CpPackageSessionDeleteTemplateMutation.graphql");
const localStyles = {
  packageName: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-weight: bold;
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const localTranslations = defineMessages({
  noPackage: {
    id: "xRcUgf",
    defaultMessage: "No package template is selected for deletion. Please refresh and try again."
  }
});

/**
 * Renders the delete confirmation screen for a package template and handles the delete mutation
 */
const CpPackageSessionDeleteTemplate = ({
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      packageTemplate
    }
  } = usePatientSessionContext();
  const handleReturnToTemplates = useCallback(() => dispatchPatientSession({
    payload: {
      modalStep: ModalSteps.PackageSelectTemplate,
      packageResources: [],
      packageTemplate: null
    },
    type: PatientSessionActions.SetSelectedPackages
  }), [dispatchPatientSession]); // Mutation error and action handling

  const [error, setError] = useState();
  const [deleteTemplate, isDeletingTemplate] = useMutation(templateDeleteMutation);
  const handleDeleteTemplate = useCallback(() => {
    if (!packageTemplate?.id) {
      // Guard to ensure mutation has an `id` - The only way to this screen is via the `TemplateSelect` screen, and by selecting a package. So I doubt this case can occur
      setError(localTranslations.noPackage);
    } else {
      Log.info("CpPackageSessionTemplatesSelect - Deleting saved package template: ", {
        packageId: packageTemplate.id
      });
      deleteTemplate({
        onCompleted: () => {
          Log.info("CpPackageSessionDeleteTemplate - successfully deleted package template.");
          handleReturnToTemplates();
        },
        onError: setError,
        variables: {
          target: packageTemplate.id
        }
      });
    }
  }, [deleteTemplate, handleReturnToTemplates, packageTemplate?.id]);
  return <View style={style}>
      <_StyledPrText accessibilityRole="header" $_css={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
        <FormattedMessage id="8/ylyT" defaultMessage="Delete Template" />
      </_StyledPrText>
      <_StyledView $_css2={styles.paddingHorizontalSingle}>
        <_StyledPrText2 $_css3={styles.paddingTopDouble}>
          <FormattedMessage id="S4BkiU" defaultMessage="You have selected the following template for deletion:" />
        </_StyledPrText2>
        <_StyledPrText3 $_css4={localStyles.packageName}>{packageTemplate?.name}</_StyledPrText3>
        <_StyledView2 $_css5={styles.paddingVerticalSingle}>
          <PrText>
            <FormattedMessage id="8zs6cr" defaultMessage="Are you sure you want to delete this template?" />
          </PrText>
          <PrText>
            <FormattedMessage id="78Xcl8" defaultMessage="Doing so is permanent, and cannot be reversed." />
          </PrText>
        </_StyledView2>

        <CpError error={error} />

        <_StyledView3 $_css6={styles.paddingTopSingle}>
          <_StyledPrButton busy={isDeletingTemplate} color="destructive" onPress={handleDeleteTemplate} testID="CpPackageSessionDeleteTemplate-delete" type="raised" $_css7={styles.sessionStyles.button}>
            <_StyledPrText4 $_css8={styles.sessionStyles.buttonText}>
              <FormattedMessage id="D5uhWD" defaultMessage="Yes, delete this template" />
            </_StyledPrText4>
          </_StyledPrButton>
          <_StyledPrButton2 accessibilityHint={formatMessage(translations.accessibility.hint.packageTemplates)} color="primary" onPress={handleReturnToTemplates} testID="CpPackageSessionDeleteTemplate-return" type="outline" $_css9={styles.sessionStyles.button}>
            <_StyledPrText5 $_css10={styles.sessionStyles.buttonText}>
              <FormattedMessage id="7e8ouT" defaultMessage="Return to templates" />
            </_StyledPrText5>
          </_StyledPrButton2>
        </_StyledView3>
      </_StyledView>
    </View>;
};

export default CpPackageSessionDeleteTemplate;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrText",
  componentId: "sc-1kd7nii-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledView",
  componentId: "sc-1kd7nii-1"
})(["", ""], p => p.$_css2);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrText2",
  componentId: "sc-1kd7nii-2"
})(["", ""], p => p.$_css3);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrText3",
  componentId: "sc-1kd7nii-3"
})(["", ""], p => p.$_css4);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledView2",
  componentId: "sc-1kd7nii-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledView3",
  componentId: "sc-1kd7nii-5"
})(["", ""], p => p.$_css6);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrButton",
  componentId: "sc-1kd7nii-6"
})(["", ""], p => p.$_css7);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrText4",
  componentId: "sc-1kd7nii-7"
})(["", ""], p => p.$_css8);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrButton2",
  componentId: "sc-1kd7nii-8"
})(["", ""], p => p.$_css9);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPackageSessionDeleteTemplate___StyledPrText5",
  componentId: "sc-1kd7nii-9"
})(["", ""], p => p.$_css10);