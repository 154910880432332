/**
 * @generated SignedSource<<27920526d601f0ecb82eec666dabe1e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlResourceTreeSectionQuery$variables = {
  locale?: Locale | null;
  resourceTreeId: string;
  revisionId: string;
};
export type QlResourceTreeSectionQuery$data = {
  readonly resourceNodeByRevision: {
    readonly " $fragmentSpreads": FragmentRefs<"CpResourceTreeSection_resourceNode">;
  } | null;
};
export type QlResourceTreeSectionQuery = {
  response: QlResourceTreeSectionQuery$data;
  variables: QlResourceTreeSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "resourceTreeId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "revisionId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "revisionId",
    "variableName": "revisionId"
  },
  {
    "kind": "Variable",
    "name": "rootRevisionId",
    "variableName": "resourceTreeId"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v5 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLeaf",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Resource",
  "kind": "LinkedField",
  "name": "resource",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v4/*: any*/),
      "kind": "ScalarField",
      "name": "embedSnippet",
      "storageKey": null
    },
    (v6/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlResourceTreeSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeByRevision",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CpResourceTreeSection_resourceNode"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlResourceTreeSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ResourceNode",
        "kind": "LinkedField",
        "name": "resourceNodeByRevision",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ResourceNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69a21ccc121730fc31349ecb241d8b45",
    "id": null,
    "metadata": {},
    "name": "QlResourceTreeSectionQuery",
    "operationKind": "query",
    "text": "query QlResourceTreeSectionQuery(\n  $resourceTreeId: String!\n  $revisionId: String!\n  $locale: Locale\n) {\n  resourceNodeByRevision(rootRevisionId: $resourceTreeId, revisionId: $revisionId) {\n    ...CpResourceTreeSection_resourceNode\n    id\n  }\n}\n\nfragment CpResourceTreeSection_resourceNode on ResourceNode {\n  children {\n    children {\n      icon(locale: $locale)\n      id\n      isLeaf\n      names(locale: $locale)\n      rank\n      resource {\n        authors\n        embedSnippet(locale: $locale)\n        id\n      }\n      resourceNodeRevisionId\n      revisionId\n    }\n    icon(locale: $locale)\n    id\n    isLeaf\n    names(locale: $locale)\n    rank\n    resource {\n      authors\n      embedSnippet(locale: $locale)\n      id\n    }\n    resourceNodeRevisionId\n    revisionId\n  }\n  revisionId\n}\n"
  }
};
})();

(node as any).hash = "a98e6e96177cf835560c7511e5c3f632";

export default node;
