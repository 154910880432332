import _styled31 from "styled-components";
import _styled30 from "styled-components";
import _styled29 from "styled-components";
import _styled28 from "styled-components";
import _styled27 from "styled-components";
import _styled26 from "styled-components";
import _styled25 from "styled-components";
import _styled24 from "styled-components";
import _styled23 from "styled-components";
import _styled22 from "styled-components";
import _styled21 from "styled-components";
import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import { useNavigation, useRoute } from "@react-navigation/native";
import CpAppFooter from "components/app/CpAppFooter";
import CpScroll from "components/common/CpScroll";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import CpPublisherAttribution from "components/topic/CpPublisherAttribution";
import CpResourceNode from "components/topic/CpResourceNode";
import CpShortcutButton from "components/topic/CpShortcutButton";
import CpTopicHeading from "components/topic/CpTopicHeading";
import CpTopicName from "components/topic/CpTopicName";
import useAppTheme from "hooks/useAppTheme";
import { useHideRules } from "hooks/useHideRules";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlShortcuts from "query/QlShortcuts";
import QlTopic from "query/QlTopic";
import QlTopicToc from "query/QlTopicToc";
import { useIntl } from "react-intl";
import { AppTool } from "services/Constants";
import { useBackendSupportedLocale } from "services/Intl";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
import CpProgressTracker from "./CpProgressTracker";
import CpTopicPresentationTableOfContents from "./CpTopicPresentationTableOfContents";
import CpTopicSiblingNavigator from "./CpTopicSiblingNavigator";
import CpTopicTableOfContents from "./CpTopicTableOfContents";
const localStyles = {
  contentContainer: css`
    flex-direction: row;
  `,
  contentContainerSmall: css`
    /* Margin to account for the content fade */
    margin-top: 15px;
  `,
  fabTop: css`
    ${styles.fab};
    /* NB: This position is directly related to the TOC width and should change in step if we alter the width. */
    right: ${({
    theme
  }) => 260 + theme.spacing.quadruple + theme.spacing.double}px;
  `,
  fade: css`
    ${styles.fade};
    bottom: -15px;
    height: 15px;
    position: absolute;
    width: 100%;
  `,
  headerContainer: css`
    ${styles.rowAndCenter};
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    justify-content: space-between;
  `,
  heading: css`
    flex: 1;
    flex-wrap: wrap;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  headingContainer: css`
    flex-direction: row;
    justify-content: space-between;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  headingTitleContainer: css`
    align-items: center;
    flex: 1;
    flex-direction: row;
  `,
  headingTitleContainerSmall: css`
    ${styles.rowAndCenter};
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  // Removing bottom border and radius to allow menu to tuck itself neatly below menu button
  menuOpen: css`
    background-color: ${({
    theme
  }) => theme.brandColors.selected};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
  navigatorContainerSmallScreen: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorBase};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-right-width: 2px;
    margin-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  progressTracker: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  progressTrackerSmall: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  searchPicker: css`
    flex: 1;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectedResourceNodeLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  separator: css`
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    width: 100%;
  `,
  shortcutButton: css`
    align-self: center;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  sidebar: css`
    ${styles.paddingTopDouble};
    ${styles.screen};
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  subheading: css`
    flex: 1;
    flex-wrap: wrap;
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  tableOfContentsButton: css`
    ${styles.rowAndCenter};
    border-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorBase};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 2px;
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  tableOfContentsLabel: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  toc: css`
    width: 260px;
  `,
  topicBody: css`
    color: ${({
    theme
  }) => theme.textColors.default};
  `
};
/**
 * Wraps and renders the correct TopicLayout for the current screen size.
 * Also handles establishing context for presentation type nodes.
 */

const CpTopic = ({
  hideFooter,
  hideHeader,
  hideSiblingNavigator,
  hideToc,
  resourceId,
  rootRevisionId,
  selectedRevisionId,
  style
}) => {
  const locale = useBackendSupportedLocale();
  const {
    appConfig,
    user
  } = useImdSession();
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    buttonColors
  } = useAppTheme();
  const {
    canGoBack,
    goBack,
    navigate
  } = useNavigation();
  const headerHidden = useHideRules({
    rules: appConfig?.appHeader?.hideRules
  });
  const shortcutTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Shortcuts);
  const isShortcutsDisabled = shortcutTool?.status !== "active";
  const patientEducationTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Educate);
  const isPatientEducationEnabled = patientEducationTool?.status === "active" || !patientEducationTool;
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      isLockedResource,
      isPatientSession
    }
  } = usePatientSessionContext();
  const [menuVisible, setMenuVisible] = useState(isWideScreen);
  const handleToggleMenu = useCallback(() => setMenuVisible(currentValue => !currentValue), [setMenuVisible]);
  const handleGoBack = useCallback(() => {
    canGoBack() ? goBack() : navigate(Routes.home);
  }, [canGoBack, goBack, navigate]); // Add the topic to the list of viewedResources - Resources will be added in CpResourceNode

  useEffect(() => {
    // Guard - only add a resource to a patient education session if:
    // - It is an authenticated non-kiosk/patient user
    // - The education tool is not disabled
    if (user && !user.isPatient && isPatientEducationEnabled) {
      dispatchPatientSession({
        // By default, we don't want topics to be `selected` for PatientSessions
        payload: {
          isTopic: true,
          revisionId: rootRevisionId,
          selected: false
        },
        type: PatientSessionActions.AddResource
      });
    }
  }, [dispatchPatientSession, rootRevisionId, user, isPatientSession, isPatientEducationEnabled, patientEducationTool]);
  const route = useRoute();
  const viewResourceId = route.params?.viewResourceId; // Ensure we only run this query once per topic

  const rootResourceNodeQueryVariables = useMemo(() => ({
    extraResourceId: viewResourceId,
    locale,
    revisionId: selectedRevisionId,
    rootRevisionId
  }), [viewResourceId, locale, selectedRevisionId, rootRevisionId]);
  const tocVariables = useMemo(() => ({
    locale,
    rootRevisionId
  }), [rootRevisionId, locale]);
  const shortcutsVariables = useMemo(() => ({}), []);
  const displayToc = !useHideRules({
    rules: appConfig?.appTopic?.tableOfContentsHideRules
  }) && !hideToc && !isLockedResource;
  const toc = displayToc ? <QlTopicToc variables={tocVariables}>
      {response => {
      if (!response) {
        // Response is loading, so show an activity indicator
        return <PrActivityIndicator />;
      } else {
        if (isWideScreen) {
          // Large Screen
          if (response?.normalToc) {
            return <_StyledCpTopicTableOfContents rootResourceNodeKey={response.normalToc} $_css={localStyles.toc} />;
          } else if (response?.presentationToc) {
            return <_StyledCpTopicPresentationTableOfContents rootResourceNodeKey={response?.presentationToc} $_css2={localStyles.toc} />;
          }
        } else if (menuVisible) {
          // Small screen, with menu visible
          if (response?.normalToc) {
            return <_StyledView $_css3={localStyles.navigatorContainerSmallScreen}>
                  <CpTopicTableOfContents onSelect={handleToggleMenu} rootResourceNodeKey={response.normalToc} />
                </_StyledView>;
          } else if (response?.presentationToc) {
            return <_StyledView2 $_css4={localStyles.navigatorContainerSmallScreen}>
                  <CpTopicPresentationTableOfContents hideHeader={true} onSelect={handleToggleMenu} rootResourceNodeKey={response?.presentationToc} />
                </_StyledView2>;
          }
        }

        return null;
      }
    }}
    </QlTopicToc> : null;
  const body = <QlTopic variables={rootResourceNodeQueryVariables}>
      {response => response ? <>
            <_StyledCpResourceNode comboKey={response} resourceId={resourceId} $_css5={localStyles.topicBody} />
            <CpPublisherAttribution comboKey={response} resourceId={resourceId} />
          </> : <PrActivityIndicator />}
    </QlTopic>;
  const header = hideHeader ? null : <QlTopic variables={rootResourceNodeQueryVariables}>
      {response => {
      if (response) {
        if (isWideScreen) {
          return <_StyledView3 $_css6={localStyles.headingTitleContainer}>
                <_StyledView4 $_css7={styles.flexSingle}>
                  {!hideSiblingNavigator && <CpTopicSiblingNavigator comboKey={response} resourceId={resourceId} />}
                  <_StyledCpTopicHeading comboKey={response} $_css8={localStyles.heading} />
                  <_StyledCpTopicName comboKey={response} resourceId={resourceId} $_css9={localStyles.subheading} />
                </_StyledView4>
                {isShortcutsDisabled ? null : <QlShortcuts variables={shortcutsVariables}>
                    {shortcutsResponse => shortcutsResponse?.currentUser ? <_StyledCpShortcutButton comboKey={response} userMembershipKey={shortcutsResponse?.currentUser} $_css10={localStyles.shortcutButton} /> : null}
                  </QlShortcuts>}
              </_StyledView3>;
        } else {
          return <>
                <_StyledView5 $_css11={styles.flexSingle}>
                  {headerHidden ? // Provide users a way to get back home when the header is disabled
              <_StyledView6 $_css12={[styles.row, styles.paddingSingle]}>
                      <PrButton onPress={handleGoBack} type="outline">
                        <PrIcon fill={buttonColors.primary} icon={IconName.back} />
                      </PrButton>
                    </_StyledView6> : null}

                  <_StyledView7 $_css13={localStyles.headingTitleContainerSmall}>
                    <_StyledView8 $_css14={styles.flexSingle}>
                      <_StyledCpTopicHeading2 comboKey={response} $_css15={localStyles.heading} />
                      <_StyledCpTopicName2 comboKey={response} resourceId={resourceId} $_css16={localStyles.subheading} />
                    </_StyledView8>
                    {isShortcutsDisabled ? null : <QlShortcuts variables={shortcutsVariables}>
                        {shortcutsResponse => shortcutsResponse?.currentUser ? <_StyledCpShortcutButton2 comboKey={response} userMembershipKey={shortcutsResponse?.currentUser} $_css17={localStyles.shortcutButton} /> : null}
                      </QlShortcuts>}
                  </_StyledView7>
                  <_StyledView9 $_css18={localStyles.fade} />
                </_StyledView5>
              </>;
        }
      } else {
        return <PrActivityIndicator />;
      }
    }}
    </QlTopic>;

  if (isWideScreen) {
    // Desktop View
    return <_StyledView10 $_css19={[styles.flexSingle, styles.row]}>
        {displayToc && <_StyledView11 $_css20={localStyles.sidebar}>
            <_StyledCpProgressTracker accessibilityLabel={formatMessage(translations.accessibility.label.progressPresentation)} $_css21={localStyles.progressTracker} />
            {toc}
          </_StyledView11>}
        <CpScroll style={style}>
          {header ? <>
              <_StyledView12 $_css22={localStyles.headingContainer}>{header}</_StyledView12>
              <_StyledView13 $_css23={localStyles.separator} />
            </> : null}
          <_StyledView14 $_css24={localStyles.contentContainer}>
            <_StyledView15 $_css25={styles.flexSingle}>{body}</_StyledView15>
          </_StyledView14>
          {hideFooter ? null : <CpAppFooter />}
        </CpScroll>
      </_StyledView10>;
  } else {
    // Mobile View
    return <CpScroll preventNestedScroll={true} stickyHeaderIndices={[0]} style={style}>
        <_StyledView16 $_css26={localStyles.headerContainer}>{header}</_StyledView16>

        <_StyledView17 $_css27={localStyles.contentContainerSmall}>
          {displayToc && <>
              <_StyledCpProgressTracker2 accessibilityLabel={formatMessage(translations.accessibility.label.progressPresentation)} $_css28={localStyles.progressTrackerSmall} />
              <_StyledPrPressable accessibilityHint={formatMessage(translations.accessibility.hint.tableOfContentsButton)} accessibilityState={{
            expanded: menuVisible
          }} onPress={handleToggleMenu} testID="CpTopicLayoutSmallTableOfContentsButton" $_css29={[localStyles.tableOfContentsButton, menuVisible && localStyles.menuOpen]}>
                <PrIcon fill={buttonColors.primary} icon={IconName.list} size={iconSizes.medium} />
                <_StyledView18 $_css30={styles.flexSingle}>
                  <_StyledPrText children={translations.labels.tableOfContents} $_css31={localStyles.tableOfContentsLabel} />
                </_StyledView18>
              </_StyledPrPressable>
              {toc}
            </>}
          {body}
        </_StyledView17>
        {hideFooter ? null : <CpAppFooter />}
      </CpScroll>;
  }
};

export default CpTopic;

var _StyledCpTopicTableOfContents = _styled(CpTopicTableOfContents).withConfig({
  displayName: "CpTopic___StyledCpTopicTableOfContents",
  componentId: "sc-hhgxw6-0"
})(["", ""], p => p.$_css);

var _StyledCpTopicPresentationTableOfContents = _styled(CpTopicPresentationTableOfContents).withConfig({
  displayName: "CpTopic___StyledCpTopicPresentationTableOfContents",
  componentId: "sc-hhgxw6-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "CpTopic___StyledView",
  componentId: "sc-hhgxw6-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView2",
  componentId: "sc-hhgxw6-3"
})(["", ""], p => p.$_css4);

var _StyledCpResourceNode = _styled(CpResourceNode).withConfig({
  displayName: "CpTopic___StyledCpResourceNode",
  componentId: "sc-hhgxw6-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView3",
  componentId: "sc-hhgxw6-5"
})(["", ""], p => p.$_css6);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView4",
  componentId: "sc-hhgxw6-6"
})(["", ""], p => p.$_css7);

var _StyledCpTopicHeading = _styled(CpTopicHeading).withConfig({
  displayName: "CpTopic___StyledCpTopicHeading",
  componentId: "sc-hhgxw6-7"
})(["", ""], p => p.$_css8);

var _StyledCpTopicName = _styled(CpTopicName).withConfig({
  displayName: "CpTopic___StyledCpTopicName",
  componentId: "sc-hhgxw6-8"
})(["", ""], p => p.$_css9);

var _StyledCpShortcutButton = _styled(CpShortcutButton).withConfig({
  displayName: "CpTopic___StyledCpShortcutButton",
  componentId: "sc-hhgxw6-9"
})(["", ""], p => p.$_css10);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView5",
  componentId: "sc-hhgxw6-10"
})(["", ""], p => p.$_css11);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView6",
  componentId: "sc-hhgxw6-11"
})(["", ""], p => p.$_css12);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView7",
  componentId: "sc-hhgxw6-12"
})(["", ""], p => p.$_css13);

var _StyledView8 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView8",
  componentId: "sc-hhgxw6-13"
})(["", ""], p => p.$_css14);

var _StyledCpTopicHeading2 = _styled(CpTopicHeading).withConfig({
  displayName: "CpTopic___StyledCpTopicHeading2",
  componentId: "sc-hhgxw6-14"
})(["", ""], p => p.$_css15);

var _StyledCpTopicName2 = _styled(CpTopicName).withConfig({
  displayName: "CpTopic___StyledCpTopicName2",
  componentId: "sc-hhgxw6-15"
})(["", ""], p => p.$_css16);

var _StyledCpShortcutButton2 = _styled(CpShortcutButton).withConfig({
  displayName: "CpTopic___StyledCpShortcutButton2",
  componentId: "sc-hhgxw6-16"
})(["", ""], p => p.$_css17);

var _StyledView9 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView9",
  componentId: "sc-hhgxw6-17"
})(["", ""], p => p.$_css18);

var _StyledView10 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView10",
  componentId: "sc-hhgxw6-18"
})(["", ""], p => p.$_css19);

var _StyledView11 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView11",
  componentId: "sc-hhgxw6-19"
})(["", ""], p => p.$_css20);

var _StyledCpProgressTracker = _styled(CpProgressTracker).withConfig({
  displayName: "CpTopic___StyledCpProgressTracker",
  componentId: "sc-hhgxw6-20"
})(["", ""], p => p.$_css21);

var _StyledView12 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView12",
  componentId: "sc-hhgxw6-21"
})(["", ""], p => p.$_css22);

var _StyledView13 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView13",
  componentId: "sc-hhgxw6-22"
})(["", ""], p => p.$_css23);

var _StyledView14 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView14",
  componentId: "sc-hhgxw6-23"
})(["", ""], p => p.$_css24);

var _StyledView15 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView15",
  componentId: "sc-hhgxw6-24"
})(["", ""], p => p.$_css25);

var _StyledView16 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView16",
  componentId: "sc-hhgxw6-25"
})(["", ""], p => p.$_css26);

var _StyledView17 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView17",
  componentId: "sc-hhgxw6-26"
})(["", ""], p => p.$_css27);

var _StyledCpProgressTracker2 = _styled(CpProgressTracker).withConfig({
  displayName: "CpTopic___StyledCpProgressTracker2",
  componentId: "sc-hhgxw6-27"
})(["", ""], p => p.$_css28);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpTopic___StyledPrPressable",
  componentId: "sc-hhgxw6-28"
})(["", ""], p => p.$_css29);

var _StyledView18 = _styled(View).withConfig({
  displayName: "CpTopic___StyledView18",
  componentId: "sc-hhgxw6-29"
})(["", ""], p => p.$_css30);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopic___StyledPrText",
  componentId: "sc-hhgxw6-30"
})(["", ""], p => p.$_css31);