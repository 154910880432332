import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpShortcutButton_resourceNode, _CpShortcutButton_userShortcut, _CpShortcutButtonCreateMutation, _CpShortcutButtonDeleteMutation;

import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, defineMessages } from "react-intl";
import { Modal, View } from "react-native";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import CpError from "components/common/CpError";
import CpPopup from "components/common/CpPopup";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import { ActivityIndicatorType } from "components/primitives/PrActivityIndicator/PrActivityIndicator.types";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import { QlTopic_comboFragment } from "query/QlTopic";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const shortcutResourceNodeFragment = _CpShortcutButton_resourceNode !== void 0 ? _CpShortcutButton_resourceNode : _CpShortcutButton_resourceNode = require("./__generated__/CpShortcutButton_resourceNode.graphql");
const userShortcutFragment = _CpShortcutButton_userShortcut !== void 0 ? _CpShortcutButton_userShortcut : _CpShortcutButton_userShortcut = require("./__generated__/CpShortcutButton_userShortcut.graphql");
const shortcutCreateMutation = _CpShortcutButtonCreateMutation !== void 0 ? _CpShortcutButtonCreateMutation : _CpShortcutButtonCreateMutation = require("./__generated__/CpShortcutButtonCreateMutation.graphql");
const shortcutDeleteMutation = _CpShortcutButtonDeleteMutation !== void 0 ? _CpShortcutButtonDeleteMutation : _CpShortcutButtonDeleteMutation = require("./__generated__/CpShortcutButtonDeleteMutation.graphql"); // Menu width selected to fit shorter labels + icon without being too wide while still containing longer labels

const POPUP_WIDTH = 350;
const localStyles = {
  error: css`
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  infoBody: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  infoButtonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: normal;
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `,
  infoContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    max-width: 650px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  infoHeader: css`
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    font-weight: bold;
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    align-self: center;
  `,
  infoSubtext: css`
    align-self: center;
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  menuContainer: css`
    border: 2px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    margin: -1px;
  `,
  menuIcon: css`
    min-width: ${({
    theme
  }) => theme.iconSizes.medium + theme.spacing.double}px;
  `,
  menuLabel: css`
    ${styles.uppercase};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  shortcutButton: css`
    ${styles.rowAndCenter};
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    justify-content: space-between;
  `,
  shortcutLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    line-height: 110%;
  `,
  shortcutMenuOpen: css`
    background: ${({
    theme
  }) => theme.brandColors.selected};
  `
};
const localTranslations = defineMessages({
  infoAccessShortcuts: {
    id: "CDUBua",
    defaultMessage: "To access your collection of shortcuts in the future, look for the {starOutline} in the menu."
  },
  infoFirstShortcut: {
    id: "Vs0LoB",
    defaultMessage: "This looks to be the first shortcut you've created. You can use this shortcut to quickly return to this topic at any time."
  },
  infoHeader: {
    id: "DR7xoX",
    defaultMessage: "Shortcut Created"
  },
  infoSubtext: {
    id: "eK7l1G",
    defaultMessage: "(Don't worry, we won't show you this popup again!)"
  },
  menuLabel: {
    id: "8LJsIE",
    defaultMessage: "Add/Remove Shortcut"
  }
});

/**
 * Component for adding and removing a users personal shortcuts
 */
const CpShortcutButton = ({
  comboKey,
  style,
  userMembershipKey
}) => {
  const locale = useBackendSupportedLocale();
  const {
    width: screenWidth
  } = useScreenLayout();
  const {
    appConfig,
    user
  } = useImdSession();
  const {
    buttonColors,
    iconSizes,
    spacing
  } = useAppTheme();
  const shortcutTool = appConfig?.appTools?.find(tool => tool.name === "Shortcuts");
  const isShortcutsDisabled = shortcutTool?.status !== "active";
  const comboData = useFragment(QlTopic_comboFragment, comboKey); // Establishing topic, resourceNode and user shortcut data

  const data = useFragment(shortcutResourceNodeFragment, comboData);
  const userShortcuts = useFragment(userShortcutFragment, userMembershipKey);
  const ancestorNodes = data.ancestors ?? [];
  const topicData = ancestorNodes.length > 0 ? ancestorNodes[0] ?? data : data;
  const topicRevisionId = topicData.revisionId;
  const topicName = getNameVariant(topicData);
  const nodeRevisionId = data.revisionId;
  const nodeName = getNameVariant(data, ["short", "default"]);
  const topicShortcut = useMemo(() => userShortcuts.currentMembership?.personalShortcuts.find(shortcut => shortcut.resourceNode.revisionId === topicRevisionId), [topicRevisionId, userShortcuts.currentMembership?.personalShortcuts]);
  const resourceNodeShortcut = useMemo(() => userShortcuts.currentMembership?.personalShortcuts.find(shortcut => shortcut.resourceNode.revisionId === nodeRevisionId), [nodeRevisionId, userShortcuts.currentMembership?.personalShortcuts]); // Shortcut Menu State, Callbacks and Layout

  const [error, setError] = useState();
  const [showShortcutMenu, setShowShortcutMenu] = useState(false);
  const handleShowShortcutMenu = useCallback(() => setShowShortcutMenu(true), []);
  const handleHideShortcutMenu = useCallback(() => {
    setError("");
    setShowShortcutMenu(false);
  }, []);
  const [shortcutMenuLayout, setShortcutMenuLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleShortcutLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    // Ensure the menu can actually fit on the screen
    const menuWidth = screenWidth < POPUP_WIDTH ? screenWidth - spacing.double : POPUP_WIDTH; // The trigger button should be right-justified on the edge of the screen and the menu comes out to the left.

    const leftPosition = screenWidth - menuWidth - spacing.single;
    setShortcutMenuLayout({
      width: menuWidth,
      x: leftPosition,
      y: layout.top + iconSizes.large + spacing.single
    });
  }, [iconSizes.large, screenWidth, spacing]); // Information modal state and callbacks

  const [showInformationModal, setShowInformationModal] = useState(false);
  const handleShowInformationModal = useCallback(() => setShowInformationModal(true), []);
  const handleHideInformationModal = useCallback(() => setShowInformationModal(false), []); // Shortcut mutation state

  const [submitShortcut, isSubmittingShortcut] = useMutation(shortcutCreateMutation);
  const [submitTopicShortcut, isSubmittingTopicShortcut] = useMutation(shortcutCreateMutation);
  const [deleteShortcut, isDeletingShortcut] = useMutation(shortcutDeleteMutation);
  const [deleteTopicShortcut, isDeletingTopicShortcut] = useMutation(shortcutDeleteMutation);
  const isProcessingResourceNodeMutation = isSubmittingShortcut || isDeletingShortcut;
  const isProcessingTopicMutation = isDeletingTopicShortcut; // Shortcut CREATE Mutation Logic

  const handleCreateShortcut = useCallback(({
    commitMutation,
    revisionId
  }) => {
    if (!userShortcuts?.currentMembership) {
      // Guard - adding shortcuts shouldn't be visible to unauthenticated users
      return;
    }

    const membershipGraphqlId = userShortcuts.currentMembership.id;
    const membershipTransparentId = userShortcuts.currentMembership._id;
    Log.info("CpTopic - Creating personal shortcut for: ", {
      revisionId
    }); // Create new ResourceNodeShortcut record

    commitMutation({
      // Updater allows us to modify our new record - this allows us to add it to the users membership immediately so no page refresh required
      onCompleted: () => {
        if (userShortcuts.currentMembership?.personalShortcuts && userShortcuts.currentMembership.personalShortcuts.length === 0) {
          // It's a users first time creating a shortcut, this will open the modal which should display details on what that means
          handleShowInformationModal();
        }
      },
      onError: setError,

      updater(recordProxy) {
        // Find the shortcut we just created
        const recordShortcut = recordProxy.getRootField("resourceNodeShortcutCreate").getLinkedRecord("resourceNodeShortcut"); // Find the current membership we're going to be updating shortcuts for

        const currentMembership = recordProxy.get(membershipGraphqlId); // get all the existing personal shortcuts (if any)

        const personalShortcuts = currentMembership?.getLinkedRecords("personalShortcuts") || []; // add the newest shortcut to the existing ones

        const newPersonalShortcuts = [...personalShortcuts, recordShortcut]; // Finally, replace old shortcuts with the new ones

        currentMembership?.setLinkedRecords(newPersonalShortcuts, "personalShortcuts");
      },

      variables: {
        input: {
          membershipId: membershipTransparentId,
          resourceNodeRevisionId: revisionId,
          rootTopicNodeRevisionId: topicRevisionId
        },
        locale
      }
    });
  }, [userShortcuts.currentMembership, topicRevisionId, locale, handleShowInformationModal]); // Shortcut DELETE Mutation Logic

  const handleDeleteShortcut = useCallback(({
    commitMutation,
    targetId
  }) => {
    if (!userShortcuts?.currentMembership) {
      // Guard - adding shortcuts shouldn't be visible to unauthenticated users
      return;
    }

    const membershipGraphqlId = userShortcuts.currentMembership.id;
    Log.info("CpTopic - Deleting personal shortcut for: ", {
      targetId
    }); // Create new ResourceNodeShortcut record

    commitMutation({
      // Updater allows us to modify our user record - this allows us to delete it from the users membership immediately so no page refresh required
      onError: setError,

      updater(recordProxy) {
        // Find the shortcut we just deleted
        const recordShortcut = recordProxy.get(targetId); // Find the current membership we're going to be updating shortcuts for

        const currentMembership = recordProxy.get(membershipGraphqlId); // get all the existing personal shortcuts (if any remain)

        const personalShortcuts = currentMembership?.getLinkedRecords("personalShortcuts") || []; // create new set of shortcuts by filtering out the one we just deleted

        const newPersonalShortcuts = personalShortcuts.filter(shortcut => shortcut !== recordShortcut); // Finally, replace old shortcuts with the new ones

        currentMembership?.setLinkedRecords(newPersonalShortcuts, "personalShortcuts");
      },

      variables: {
        target: targetId
      }
    });
  }, [userShortcuts?.currentMembership]); // Shortcut callback handlers

  const handleResourceNodeShortcutPress = useCallback(() => {
    if (resourceNodeShortcut) {
      handleDeleteShortcut({
        commitMutation: deleteShortcut,
        targetId: resourceNodeShortcut.id
      });
    } else {
      handleCreateShortcut({
        commitMutation: submitShortcut,
        revisionId: nodeRevisionId
      });
    }
  }, [deleteShortcut, handleCreateShortcut, handleDeleteShortcut, resourceNodeShortcut, nodeRevisionId, submitShortcut]);
  const handleTopicShortcutPress = useCallback(() => {
    if (topicShortcut) {
      handleDeleteShortcut({
        commitMutation: deleteTopicShortcut,
        targetId: topicShortcut.id
      });
    } else {
      handleCreateShortcut({
        commitMutation: submitTopicShortcut,
        revisionId: topicRevisionId
      });
    }
  }, [deleteTopicShortcut, handleCreateShortcut, handleDeleteShortcut, topicShortcut, submitTopicShortcut, topicRevisionId]); // Guard - shortcuts should only be available if:
  // - It is an authenticated non-kiosk/patient user
  // - The shortcut tool is not disabled

  if (!user || user.isKiosk || user.isPatient || !shortcutTool || isShortcutsDisabled) {
    return null;
  }

  return <React.Fragment>
      {
      /* Trigger */
    }
      <_StyledPrButton onLayout={handleShortcutLayout} onPress={handleShowShortcutMenu} style={style} type="text" $_css={[styles.center, showShortcutMenu ? localStyles.shortcutMenuOpen : undefined]}>
        <_StyledView $_css2={styles.center}>
          <PrIcon fill={buttonColors.primary} icon={// Show full icon if user has added shortcuts for both the topic and the current resourceNode
        topicShortcut && resourceNodeShortcut ? IconName.star : IconName.starOutline} size={iconSizes.large} />
        </_StyledView>
      </_StyledPrButton>

      {
      /* Shortcut Menu */
    }
      <CpPopup layout={shortcutMenuLayout} onBackgroundPress={handleHideShortcutMenu} visible={showShortcutMenu}>
        <_StyledView2 $_css3={localStyles.menuContainer}>
          <_StyledPrText children={localTranslations.menuLabel} $_css4={localStyles.menuLabel} />
          {
          /* Topic shortcut button */
        }
          <_StyledPrPressable disabled={isSubmittingTopicShortcut} onPress={handleTopicShortcutPress} $_css5={localStyles.shortcutButton}>
            <_StyledPrText2 children={topicName} numberOfLines={2} $_css6={localStyles.shortcutLabel} />
            {isProcessingTopicMutation ? <PrActivityIndicator color={buttonColors.primary} type={ActivityIndicatorType.circular} /> : <_StyledPrIcon fill={buttonColors.primary} icon={topicShortcut ? IconName.star : IconName.starOutline} size={iconSizes.medium} $_css7={localStyles.menuIcon} />}
          </_StyledPrPressable>
          {
          /* ResourceNode shortcut button */
        }
          <_StyledPrPressable2 disabled={isSubmittingShortcut} onPress={handleResourceNodeShortcutPress} $_css8={localStyles.shortcutButton}>
            <_StyledPrText3 children={nodeName} numberOfLines={2} $_css9={localStyles.shortcutLabel} />
            {isProcessingResourceNodeMutation ? <PrActivityIndicator color={buttonColors.primary} type={ActivityIndicatorType.circular} /> : <_StyledPrIcon2 fill={buttonColors.primary} icon={resourceNodeShortcut ? IconName.star : IconName.starOutline} size={iconSizes.medium} $_css10={localStyles.menuIcon} />}
          </_StyledPrPressable2>
          <_StyledCpError error={error} $_css11={localStyles.error} />
        </_StyledView2>
      </CpPopup>

      {
      /* Information Modal */
    }
      <Modal onRequestClose={handleHideInformationModal} transparent={true} visible={showInformationModal}>
        <_StyledView3 $_css12={styles.viewOverlay}>
          <_StyledView4 $_css13={localStyles.infoContainer}>
            <_StyledPrText4 children={localTranslations.infoHeader} $_css14={localStyles.infoHeader} />
            <_StyledView5 $_css15={styles.paddingVerticalDouble}>
              <_StyledPrText5 children={localTranslations.infoFirstShortcut} $_css16={localStyles.infoBody} />
              <_StyledPrText6 $_css17={localStyles.infoBody}>
                <FormattedMessage {...localTranslations.infoAccessShortcuts} values={{
                starOutline: <PrIcon icon={IconName.starOutline} size={iconSizes.xsmall} fill={buttonColors.primary} />
              }} />
              </_StyledPrText6>
              {
              /* <PrText children="To remove an existing shortcut, simply click on the any existing shortcut that has this icon {star}." /> */
            }
              <_StyledPrText7 children={localTranslations.infoSubtext} $_css18={localStyles.infoSubtext} />
            </_StyledView5>
            <PrButton onPress={handleHideInformationModal} type="outline">
              <_StyledPrText8 children={translations.buttons.continue} $_css19={localStyles.infoButtonText} />
            </PrButton>
          </_StyledView4>
        </_StyledView3>
      </Modal>
    </React.Fragment>;
};

export default CpShortcutButton;

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpShortcutButton___StyledPrButton",
  componentId: "sc-8j8w86-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpShortcutButton___StyledView",
  componentId: "sc-8j8w86-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpShortcutButton___StyledView2",
  componentId: "sc-8j8w86-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText",
  componentId: "sc-8j8w86-3"
})(["", ""], p => p.$_css4);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpShortcutButton___StyledPrPressable",
  componentId: "sc-8j8w86-4"
})(["", ""], p => p.$_css5);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText2",
  componentId: "sc-8j8w86-5"
})(["", ""], p => p.$_css6);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpShortcutButton___StyledPrIcon",
  componentId: "sc-8j8w86-6"
})(["", ""], p => p.$_css7);

var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpShortcutButton___StyledPrPressable2",
  componentId: "sc-8j8w86-7"
})(["", ""], p => p.$_css8);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText3",
  componentId: "sc-8j8w86-8"
})(["", ""], p => p.$_css9);

var _StyledPrIcon2 = _styled(PrIcon).withConfig({
  displayName: "CpShortcutButton___StyledPrIcon2",
  componentId: "sc-8j8w86-9"
})(["", ""], p => p.$_css10);

var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpShortcutButton___StyledCpError",
  componentId: "sc-8j8w86-10"
})(["", ""], p => p.$_css11);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpShortcutButton___StyledView3",
  componentId: "sc-8j8w86-11"
})(["", ""], p => p.$_css12);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpShortcutButton___StyledView4",
  componentId: "sc-8j8w86-12"
})(["", ""], p => p.$_css13);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText4",
  componentId: "sc-8j8w86-13"
})(["", ""], p => p.$_css14);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpShortcutButton___StyledView5",
  componentId: "sc-8j8w86-14"
})(["", ""], p => p.$_css15);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText5",
  componentId: "sc-8j8w86-15"
})(["", ""], p => p.$_css16);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText6",
  componentId: "sc-8j8w86-16"
})(["", ""], p => p.$_css17);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText7",
  componentId: "sc-8j8w86-17"
})(["", ""], p => p.$_css18);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpShortcutButton___StyledPrText8",
  componentId: "sc-8j8w86-18"
})(["", ""], p => p.$_css19);