import _styled from "styled-components";
import React from "react";
import CpGrid from "components/common/CpGrid";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import { FormattedMessage } from "react-intl";
import { useBackendSupportedLocale } from "services/Intl";
import { css } from "services/Theme";
import CpCommonTopicsSection from "./CpCommonTopicsSection";
import CpContentLinksSection from "./CpContentLinksSection";
import CpTopicTreeSection from "./CpTopicTreeSection";
const localStyles = {
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * Renders a home screen tab based on the legacy v5 tab configuration
 */
const CpLegacyHomeTab = () => {
  const locale = useBackendSupportedLocale();
  const {
    contentMinHeight
  } = useScreenLayout();
  const {
    licensee,
    organization
  } = useImdSession();
  const legacySettings = organization?.settings ?? licensee?.settings;
  const tabs = legacySettings.home_screen?.tabs;
  const tabCount = tabs?.length ?? 0;
  return <_StyledCpGrid $_css={[localStyles.container, css`
          min-height: ${contentMinHeight}px;
        `]}>
      {tabs && tabCount > 0 ? tabs.map(tab => tab.sections.map(section => {
      switch (section.section_type) {
        case "commonTopicsSection":
          return <CpCommonTopicsSection key={`commonTopicsSection ${tab?.tab_title} ${section.section_title[locale]}`} sectionTitle={section.section_title[locale] || ""} />;

        case "customSection":
          return <CpContentLinksSection key={`customSection ${tab?.tab_title} ${section.section_title[locale]}`} sectionTitle={section.section_title[locale] || ""} shortcuts={section.content} />;

        case "topicSection":
          return <CpTopicTreeSection key={`topicSection ${tab?.tab_title} ${section.section_title[locale]}`} topicTreeId={section.uuid} />;

        default:
          return null;
      }
    })) : <PrText>
          <FormattedMessage id="9tbqgA" defaultMessage="Contact IMD Health about setting up featured items for your clinic" />
        </PrText>}
    </_StyledCpGrid>;
};

export default CpLegacyHomeTab;

var _StyledCpGrid = _styled(CpGrid).withConfig({
  displayName: "CpLegacyHomeTab___StyledCpGrid",
  componentId: "sc-1dxpof2-0"
})(["", ""], p => p.$_css);