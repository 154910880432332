/**
 * @generated SignedSource<<90fd223153c0d8e2f7ece6bc8068c5c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MembershipRole = "admin" | "member" | "superadmin" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CpMembershipItem_membership$data = {
  readonly _id: string;
  readonly organization: {
    readonly _id: string;
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CpOrganizationItem_organization">;
  };
  readonly role: MembershipRole;
  readonly " $fragmentType": "CpMembershipItem_membership";
};
export type CpMembershipItem_membership$key = {
  readonly " $data"?: CpMembershipItem_membership$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpMembershipItem_membership">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpMembershipItem_membership",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpOrganizationItem_organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "Membership",
  "abstractKey": null
};
})();

(node as any).hash = "99b931e94019cbbd3b686075cfb5d609";

export default node;
