import React, { useCallback, useState } from "react"
import { View } from "react-native"
import WebView from "react-native-webview"
import { useFragment } from "react-relay/hooks"

import CpUpdatePageTitle from "components/app/CpUpdatePageTitle"
import { CpResourceCommonProps, resourceFragment } from "components/topic/CpResourceCommon"
import { getNameVariant } from "services/Graphql"

/**
 * Renders an iframe resource
 */
const CpIframeResource: React.FC<CpResourceCommonProps> = ({ resource, style }) => {
  const data = useFragment(resourceFragment, resource)
  const [layoutHeight, setLayoutHeight] = useState<number>(0)
  const handleSetLayoutHeight = useCallback(
    (event) => setLayoutHeight(event.nativeEvent.layout.height),
    [setLayoutHeight],
  )

  const additionalIframeAttributes = data?.additionalIframeAttributes ?? undefined
  const url: undefined | null | string = data?.url
  if (!url) return null

  // Why doesn't the iframe flex?  Without a specific height it's not filling the screen
  // TODO: Flex iframe if possible?
  return (
    <View onLayout={handleSetLayoutHeight} style={style} testID="CpIframeResource">
      <CpUpdatePageTitle pageType="article" title={getNameVariant(data)} />
      <WebView
        originWhitelist={["*"]}
        source={
          // Nesting an iFrame that renders PDF's doesn't work, bypass the iFrame in these instances so something renders
          url.includes(".pdf")
            ? { uri: url }
            : {
                // Accommodating built in iframe margin - otherwise parent view scrolls
                html: `<iFrame height="${
                  layoutHeight - 25
                }px" width="100%" src="${url}" ${additionalIframeAttributes} />`,
              }
        }
        style={{ height: layoutHeight }}
      />
    </View>
  )
}

export default CpIframeResource
