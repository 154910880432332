import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconHeadache: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M241.25 173.89c-.84 7.34-1.85 14.66-2.4 22a8.7 8.7 0 001.79 6c15.12 15.85 13.55 31.61-4.43 43.86a8.6 8.6 0 00-2.81 4.65c-8 29-24.13 52.51-47.83 70.67-21.64 16.59-43 17.79-66 3.08-27.29-17.44-45.42-42-53.24-73.78a7 7 0 00-3.14-4.15c-17.86-8.86-20.22-33.37-4-45.18 3.63-2.65 4.65-4.8 4.16-9.37-1.58-14.54-1.62-29.15 2.18-43.45 8.59-32.35 30-51.24 62.29-58.35a110.11 110.11 0 0149.16.42c41.21 9.62 65.02 40.78 64.27 83.6zM200.6 122l-1-.54c-9.38 10.9-18.76 21.8-29.1 33.8h16.3l-11.19 25.87 1.09.65 31.3-33.46h-17.59c3.68-9.49 6.93-17.89 10.19-26.32zM211.19 85c3.32-7.72 6.63-15.45 10.17-23.7l-10.81-1.12c3.44-11 6.63-21.56 10.13-32 .38-1.15 2.46-2.36 3.81-2.44 5-.29 10.07-.12 16.42-.12L227.37 52l10.34.65-25.56 32.75zM237.43 81.83c8.91-8 17.25-15.48 25.74-22.83a4.46 4.46 0 014-.43c4.69 2.58 9.18 5.51 14.51 8.8l-25 15.24 8.53 5.89-38.74 15-.55-.83L246.73 88zM311.71 115.24H282c1.77 3.71 3 6.34 4.71 9.87l-41.55-6.29.11-.87 25.61-2.54-5-9.84c11.74-2.53 23-5 34.39-7.28.94-.19 2.66 1.29 3.34 2.4 2.57 4.31 4.91 8.78 8.1 14.55z" />
    </Svg>
  )
}

export default IconHeadache
