import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconSexuality: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M129.81 114.06c.9-1.56 1.38-2.59 2-3.49a59.47 59.47 0 0178.34-16.67c3.5 2.07 5.14 1.56 7.55-1.3 6-7.18 12.4-14.08 18.31-22.33-4.87 0-9.77-.25-14.63.08-4.17.28-5.87-.94-5.5-5.35a122 122 0 000-13.25c-.06-2.51.62-3.79 3.45-3.78q26.73.12 53.48 0c2.75 0 3.53 1.18 3.52 3.75q-.1 26.52 0 53c0 2.68-.86 3.67-3.55 3.6-5.15-.15-10.32-.13-15.47 0-2.51 0-3.36-1-3.31-3.41.11-6 0-12 0-19a38.49 38.49 0 00-3.3 2.83c-6 6.68-11.89 13.48-18 20-2.17 2.32-2.36 4-.74 6.85a59.73 59.73 0 01-21.61 81.66 60.49 60.49 0 01-11.5 5.15c-30.23 10.19-64-6.12-74.91-36.12-1-2.64-.76-4.59 1.64-6.07a53.94 53.94 0 018-4.62c7.86-3.18 8.74-2.71 12.07 5 7.24 16.79 26.45 26.2 43.8 21.45 18.34-5 30.18-21.91 28.15-40.18-2.11-19-17.13-33.13-35.89-33.86a39.84 39.84 0 00-20.37 4.81c-1.89 1-4.37 1.07-6.59 1.11-7.94.21-16 .14-24.94.14z" />
      <Path d="M194.36 214.05a58 58 0 01-25 22.35 63.38 63.38 0 01-10.36 3.79c-2.51.69-3.26 2-3.16 4.4.14 3.53.16 7.08 0 10.6-.13 2.91 1.07 3.9 3.89 3.85 7.51-.11 15 .09 22.54-.09 3.33-.08 4.43 1.18 4.3 4.4-.19 4.71-.17 9.43 0 14.14.09 2.92-1.11 3.89-3.92 3.85-7.22-.11-14.45.19-21.65-.12-4.21-.18-5.35 1.37-5.19 5.36.27 6.91 0 13.85.13 20.77.09 3.34-.86 4.87-4.47 4.67a130.43 130.43 0 00-13.7 0c-3.39.16-4.32-1.27-4.24-4.43.16-6.78 0-13.56 0-20.33 0-5.93 0-5.94-5.74-5.95-6.92 0-13.85-.1-20.77.05-3 .06-4.27-.86-4.13-4 .21-4.7.24-9.43 0-14.14-.17-3.41 1.22-4.3 4.4-4.23 7.36.18 14.74-.08 22.1.13 3.37.09 4.42-1.14 4.2-4.37-.29-4.1 1.13-9.09-.75-12.08-1.65-2.62-6.85-3-10.47-4.37-28.18-10.78-44.37-41.49-36.86-70.11 8.11-30.81 36.28-49.89 67.08-45 22.85 3.63 38.37 16.71 47.21 38 1.26 3 .94 5.22-1.86 6.68-3.88 2-7.86 3.85-11.89 5.54-3.68 1.53-5.56.33-7-3.72a37.59 37.59 0 10-22.78 48 38.67 38.67 0 005.34-2.39c2.94-1.65 5.54-1.63 8.73-1.45 7.71.5 15.48.2 24.02.2z" />
    </Svg>
  )
}

export default IconSexuality
