export enum DisplayDirection {
  Horizontal = "horizontal",
  Vertical = "vertical",
  Auto = "auto",
}

export enum FormNecessity {
  None = "none",
  Optional = "optional",
  Required = "required",
}
