var _QlVerifyPasswordResetTokenQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";

/**
 * Checks if a user reset password token is valid
 */
export const QlVerifyPasswordResetTokenRequest = _QlVerifyPasswordResetTokenQuery !== void 0 ? _QlVerifyPasswordResetTokenQuery : _QlVerifyPasswordResetTokenQuery = require("./__generated__/QlVerifyPasswordResetTokenQuery.graphql");

const QlVerifyPasswordResetToken = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlVerifyPasswordResetTokenRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlVerifyPasswordResetToken;