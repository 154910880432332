import { AxiosInstance, AxiosResponse } from "axios"
import {
  Environment,
  GraphQLResponse,
  GraphQLResponseWithData,
  Network,
  RecordSource,
  Store,
} from "relay-runtime"

import hasKey from "helpers/hasKey"
import { GraphqlError } from "services/Error"

export function responseHasData(response: GraphQLResponse): response is GraphQLResponseWithData {
  return Boolean(response) && hasKey(response, "data")
}

export const makeRelayEnvironment = (backend: AxiosInstance): Environment =>
  new Environment({
    network: Network.create(async (request, variables) => {
      const response = await backend.request<unknown, AxiosResponse<GraphQLResponse>>({
        data: { query: request.text, variables },
        method: "POST",
        url: "/api/graphql",
      })
      if (responseHasData(response.data) && response.data.errors) {
        throw new GraphqlError(response.data.errors)
      }
      return response.data
    }),
    store: new Store(new RecordSource()),
  })
