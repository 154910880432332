import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Share: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M34.9586612,7.00270543 L34.9586612,10.9692812 L11.4116629,10.9692812 L11.4116629,10.9692812 C11.0445554,10.9692812 10.6638772,11.1567714 10.6638772,11.704793 L10.6638772,51.4732651 L10.6638772,51.4732651 C10.6638772,52.148815 10.8953101,52.3049037 11.5839424,52.3049037 L53.8796899,52.3049037 L53.8796899,52.3049037 C54.4622493,52.3049037 54.6713775,52.0428446 54.6713775,51.4732651 L54.6713775,37.5312839 L54.6713775,37.5312839 L58.6610156,33.5426551 L58.6610156,51.569349 L58.6610156,51.569349 C58.6610156,54.5616048 56.3828897,56.3216018 53.8796899,56.3216018 L11.3523045,56.3216018 L11.3523045,56.3216018 C9.21351702,56.3216018 6.65866117,54.7223548 6.65866117,51.569349 L6.65866117,11.7978982 L6.65866117,11.7978982 C6.65866117,9.14326427 8.55101399,7.00270543 11.3523045,7.00270543 L34.9586612,7.00270543 L34.9586612,7.00270543 Z M40.9435915,7.00192501 L58.6078831,25.001925 L40.9403497,43.001925 L40.9435915,32.501925 C27.9156009,32.1159118 19.7396499,33.1904576 16.1802545,46.9450335 C15.8668937,48.06749 15.8054287,49.4789222 15.736621,49.5494036 C15.6678132,49.6198849 14.7094591,48.3405991 14.2190071,46.9450335 C11.2293804,37.0463313 15.6232072,27.8918879 20.1041017,24.3588468 C28.1186826,18.0396078 31.2648891,17.501925 40.9403497,17.501925 L40.9435915,7.00192501 Z" />
    </Svg>
  )
}

export default Share
