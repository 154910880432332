/**
 * @generated SignedSource<<f1ce8fb63f5ea33ba38fd0c599ee41cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlPatientPackagesQuery$variables = {
  locale?: Locale | null;
  organizationId: string;
  practitionerId: string;
};
export type QlPatientPackagesQuery$data = {
  readonly patientPackages: ReadonlyArray<{
    readonly _id: string;
    readonly children: ReadonlyArray<{
      readonly commonTopic: {
        readonly _id: string;
        readonly icon: string | null;
        readonly names: any;
      } | null;
      readonly patientPackageId: string;
      readonly resourceNodeRevisionId: string;
      readonly topicNode: {
        readonly icon: string | null;
        readonly names: any;
        readonly parentNames: any;
        readonly treeId: string | null;
      } | null;
      readonly topicNodeTreeId: string | null;
    }>;
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }> | null;
};
export type QlPatientPackagesQuery = {
  response: QlPatientPackagesQuery$data;
  variables: QlPatientPackagesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "practitionerId",
    "variableName": "practitionerId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v6 = {
  "alias": null,
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "parentNames",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "treeId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientPackageId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "topicNodeTreeId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlPatientPackagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PatientPackage",
        "kind": "LinkedField",
        "name": "patientPackages",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientPackageResourceNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommonTopic",
                "kind": "LinkedField",
                "name": "commonTopic",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TopicNode",
                "kind": "LinkedField",
                "name": "topicNode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlPatientPackagesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "PatientPackage",
        "kind": "LinkedField",
        "name": "patientPackages",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientPackageResourceNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommonTopic",
                "kind": "LinkedField",
                "name": "commonTopic",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TopicNode",
                "kind": "LinkedField",
                "name": "topicNode",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92c2269bdcd48a2aafa0965bf370b33b",
    "id": null,
    "metadata": {},
    "name": "QlPatientPackagesQuery",
    "operationKind": "query",
    "text": "query QlPatientPackagesQuery(\n  $organizationId: String!\n  $practitionerId: String!\n  $locale: Locale\n) {\n  patientPackages(organizationId: $organizationId, practitionerId: $practitionerId) {\n    _id\n    children {\n      commonTopic {\n        _id\n        icon(locale: $locale)\n        names(locale: $locale)\n        id\n      }\n      topicNode {\n        icon(locale: $locale)\n        names(locale: $locale)\n        parentNames(locale: $locale)\n        treeId\n        id\n      }\n      patientPackageId\n      resourceNodeRevisionId\n      topicNodeTreeId\n      id\n    }\n    description\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "63f64ba1ba3645275f2e3c84bc7fa79f";

export default node;
