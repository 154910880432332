/**
 * @generated SignedSource<<d47c38aa395298e5c4378342068ee7dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useDescendantsAsTree_rootResourceNode$data = {
  readonly _id: string;
  readonly descendants: ReadonlyArray<{
    readonly _id: string;
    readonly hasTaggedResources: boolean;
    readonly id: string;
    readonly isSectionNode: boolean;
    readonly names: any;
    readonly parentId: string | null;
    readonly pinSelfToBottom: boolean;
    readonly pinSelfToTop: boolean;
    readonly rank: number;
    readonly resourceId: string | null;
    readonly resourceNodeRevisionId: string | null;
    readonly revisionId: string;
    readonly shouldSortChildrenByRank: boolean;
    readonly type: string;
  }>;
  readonly hasTaggedResources: boolean;
  readonly id: string;
  readonly isSectionNode: boolean;
  readonly names: any;
  readonly parentId: string | null;
  readonly pinSelfToBottom: boolean;
  readonly pinSelfToTop: boolean;
  readonly rank: number;
  readonly resourceId: string | null;
  readonly resourceNodeRevisionId: string | null;
  readonly revisionId: string;
  readonly shouldSortChildrenByRank: boolean;
  readonly type: string;
  readonly " $fragmentType": "useDescendantsAsTree_rootResourceNode";
};
export type useDescendantsAsTree_rootResourceNode$key = {
  readonly " $data"?: useDescendantsAsTree_rootResourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDescendantsAsTree_rootResourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTaggedResources",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToBottom",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToTop",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldSortChildrenByRank",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentId",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSectionNode",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "useDescendantsAsTree_rootResourceNode",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "descendants",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        (v10/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        (v13/*: any*/),
        (v14/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/),
    (v11/*: any*/),
    (v12/*: any*/),
    (v13/*: any*/),
    (v14/*: any*/)
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "822dc96315dae162e5c9e5e3b1da4e8f";

export default node;
