/**
 * @generated SignedSource<<8e28fba18f2bb7d31725bcb500477aa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QlTopicSubtree_resourceNode$data = {
  readonly _id: string;
  readonly icon: string | null;
  readonly id: string;
  readonly isLeaf: boolean;
  readonly isSectionNode: boolean;
  readonly names: any;
  readonly parentId: string | null;
  readonly pinSelfToBottom: boolean;
  readonly pinSelfToTop: boolean;
  readonly resourceNodeRevisionId: string | null;
  readonly resourceType: string | null;
  readonly revisionId: string;
  readonly shouldSortChildrenByRank: boolean;
  readonly taggedResources: ReadonlyArray<{
    readonly _id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CpTopicSubtreeResourceItem_resource">;
  }> | null;
  readonly type: string;
  readonly " $fragmentType": "QlTopicSubtree_resourceNode";
};
export type QlTopicSubtree_resourceNode$key = {
  readonly " $data"?: QlTopicSubtree_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"QlTopicSubtree_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "QlTopicSubtree_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLeaf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSectionNode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "names",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinSelfToBottom",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pinSelfToTop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceNodeRevisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldSortChildrenByRank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "taggedResources",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpTopicSubtreeResourceItem_resource"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "c1556cef47f66e2ef9680e59e0b821cd";

export default node;
