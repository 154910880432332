import _styled from "styled-components";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import CpTextCallToActionButton from "components/app/header/CpTextCallToActionButton";
import CpHeaderButton from "components/common/CpHeaderButton";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import { Linking } from "react-native";
import { styles } from "services/Theme";
import translations from "translations";
/**
 * IMD for professionals header action button
 */

const ImdForProfessionalsButton = ({
  type = ActionButtonUserType.AccountMenu
}) => {
  const {
    formatMessage
  } = useIntl();
  const navigateToPractitionerApp = useCallback(() => {
    const url = "https://provider.imdhealth.app/";
    Linking.openURL(url); // Do we want to track this event?  If so, what kind of event is it?
  }, []);

  if (type === ActionButtonUserType.NavHeader) {
    return <CpTextCallToActionButton onPress={navigateToPractitionerApp} text={<_StyledPrText children={translations.buttons.practitionerCta} numberOfLines={3} accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderCTA)} $_css={styles.navHeaderStyles.callToActionText} />} type={ActionButtonUserType.NavHeader} />;
  }

  return <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
    pageName: formatMessage(translations.buttons.practitionerCta)
  })} icon={IconName.iconConsultation} label={translations.buttons.practitionerCta} onPress={navigateToPractitionerApp} shortLabel={translations.buttons.practitionerCta} testID={type === ActionButtonUserType.AccountMenu ? "ImdForProfessionalsButton-accountMenu" : "ImdForProfessionalsButton-header"} type={type} />;
};

export default ImdForProfessionalsButton;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "ImdForProfessionalsButton___StyledPrText",
  componentId: "sc-1x6ovml-0"
})(["", ""], p => p.$_css);