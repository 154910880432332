import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpSelectOrganization from "components/organization/CpSelectOrganization";
import { FormattedMessage } from "react-intl";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * Screen for selecting a users organization
 */
const ScSelectOrganization = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={[styles.screen, styles.flexSingle]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CpSelectOrganization headerMessage={<FormattedMessage id="mvQ5ZO" defaultMessage="Where are you practicing today?" />} />
    </_StyledSafeAreaView>;
};

export default ScSelectOrganization;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScSelectOrganization___StyledSafeAreaView",
  componentId: "sc-1v7po22-0"
})(["", ""], p => p.$_css);