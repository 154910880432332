import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - add
const Add: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 24 24" width={height} {...otherProps}>
      <Path d="M18.984 12.984h-6v6h-1.969v-6h-6v-1.969h6v-6h1.969v6h6v1.969z" />
    </Svg>
  )
}

export default Add
