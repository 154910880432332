import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconFaq: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M218.5 52c16.5 0 30.5 11.4 34.4 26.8 5.5 1.7 10.7 4.7 15 9 7 7 10.5 16.2 10.5 25.5 0 9.2-3.5 18.4-10.5 25.5-3.2 3.2-6.9 5.7-10.8 7.5 4.8 4.6 8.1 10.3 9.7 16.5 2.3 8.7 1.4 18.3-3.3 26.8-.1.3-.3.7-.5.9-5 8.4-12.9 14-21.6 16.4-8.6 2.3-18 1.4-26.4-3.1-.2 2.3-.5 4.5-1.1 6.7-2.3 8.8-8 16.6-16.4 21.6-.3.2-.6.4-.9.5-8.5 4.7-18.1 5.6-26.8 3.3s-16.5-7.9-21.5-16.2l-.6-.9c-4.8-8.5-5.7-18.1-3.4-26.9 2.3-8.8 8-16.6 16.4-21.6.3-.2.6-.4.9-.5 8.5-4.7 18.1-5.6 26.7-3.3 2.7.7 5.3 1.8 7.8 3.1.4-5 1.8-10.1 4.4-14.8.1-.3.3-.6.5-.9 4-6.7 9.7-11.6 16.3-14.5l-.4-.4c-4.3-4.3-7.2-9.3-8.9-14.7h-53.8c-.9 7.8-4.3 15.4-10.3 21.5-7 7-16.2 10.5-25.5 10.5-9.2 0-18.4-3.5-25.5-10.5-7-7-10.5-16.2-10.5-25.5S86 101.6 93 94.6c3.3-3.3 7-5.8 11-7.5.3-9.8 3.7-17.9 10.4-24.6 6.4-6.4 15.3-10.4 25-10.4h79.1V52zm26.3 77.5l-6.6-11.5c-5 3.4-10.9 5.5-17.3 5.9 1.1 2.3 2.6 4.4 4.5 6.3 2.8 2.8 6.2 4.8 9.7 5.9 2.1.2 4.1.5 6.1 1.1h1.1c6.1 0 12.3-2.3 17-7 4.7-4.7 7-10.8 7-17 0-6.1-2.3-12.3-7-17-1.7-1.7-3.6-3.1-5.7-4.2-.7 6.6-2.8 12.3-6.6 17.4l8.1 14-10.3 6.1zm-119.9-8.6l-8.8 15.3-10.4-6 9.3-16c-4.2-4-7.3-8.9-9.2-14.5-1.5.9-2.9 2.1-4.2 3.4-4.7 4.7-7 10.8-7 17 0 6.1 2.3 12.3 7 17 4.7 4.7 10.8 7 17 7 6.1 0 12.3-2.3 17-7 3.7-3.7 5.9-8.2 6.7-13h-2.8c-5.3-.1-10.2-1.2-14.6-3.2zm114.4 28.3c-2.1-.2-4.2-.6-6.3-1.1-2.4-.1-4.9.2-7.2.8-5.9 1.6-11.3 5.4-14.6 11.2-3.3 5.7-4 12.3-2.4 18.2 1.6 5.9 5.4 11.3 11.2 14.6 5.7 3.3 12.3 4 18.2 2.4 5.9-1.6 11.3-5.4 14.6-11.2 3.3-5.7 4-12.3 2.4-18.2-1.6-5.9-5.3-11.1-10.9-14.4-.1-.1-.2-.1-.3-.2-1.5-.9-3.1-1.6-4.7-2.1zm-72.4 31c-5.7 3.3-9.6 8.6-11.2 14.6-1.6 5.9-.9 12.5 2.4 18.2.1.1.1.2.2.3 3.3 5.6 8.6 9.3 14.4 10.9 5.9 1.6 12.5.9 18.2-2.4 5.7-3.3 9.6-8.6 11.2-14.6 1.6-5.9.9-12.5-2.4-18.2-3.3-5.7-8.6-9.6-14.6-11.2-5.9-1.5-12.4-.9-18.2 2.4zM139.4 64C126.5 64 116 74.6 116 87.5c0 7.1 2 12.8 6.9 17.7 4.2 4.2 10.1 6.9 16.5 6.9h79.1c12.9 0 23.4-10.5 23.4-23.4 0-7.1-2-12.8-6.9-17.7-4.2-4.2-10.1-6.9-16.5-6.9l-79.1-.1z" />
      <Path
        clipRule="evenodd"
        d="M184.9 185.8v30.4h-12v-30.4h12zm-24.3 77.9c10.2-10.2 26.6-10.2 36.8 0 10.2 10.2 10.2 26.6 0 36.8a25.953 25.953 0 01-36.8 0c-10.2-10.2-10.2-26.7 0-36.8zm2.3 12.3h32v12h-32v-12zm15.5-173.9V74h-39c-3.6 0-6.9 1.4-9.5 4-2.5 2.5-4 5.9-4 9.5v1.2c0 3.6 1.4 6.9 4 9.5s5.9 4 9.5 4h39v-.1zm69.7 67.5l-26.4 15.2-6-10.4 26.4-15.2 6 10.4z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconFaq
