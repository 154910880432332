import { StyleProp, ViewStyle } from "react-native"

export enum ActivityIndicatorType {
  circular,
  linear,
}

export interface PrActivityIndicatorProps {
  color?: string
  size?: number
  style?: StyleProp<ViewStyle>
  type?: ActivityIndicatorType
}
