import { defineMessages } from "react-intl";
export default defineMessages({
  appInitialization: {
    id: "QFl9Cr",
    defaultMessage: "An initialization error has occurred. You can retry or reload this page. If the problem persists, we will try to restore service shortly."
  },
  customError: {
    id: "ZFzflD",
    defaultMessage: "{customError} Please, try again. If the problem persists please contact {supportEmail}"
  },
  default: {
    id: "6Xzqyw",
    defaultMessage: "An error has occurred. Please retry and if the problem continues, please contact support."
  },
  disclaimerNotAccepted: {
    id: "94konj",
    defaultMessage: "In order to proceed, you must accept the disclaimer."
  },
  genericError: {
    id: "tGNvDJ",
    defaultMessage: "Something went wrong."
  },
  invalid: {
    id: "mCkTNj",
    defaultMessage: "Some of the data you entered is invalid. Please correct and try again."
  },
  invalidConfirmationCode: {
    id: "Yp/M9S",
    defaultMessage: "That code is not correct. If you requested multiple codes, please ensure you are using the most recent code provided. Codes are only good for 24 hours. If the code has expired, use the button below to request a new one."
  },
  invalidSignin: {
    id: "ZzSbYD",
    defaultMessage: "The password or the account email is incorrect. Please correct and try again, or request a password reset. If the issue persists or you think this is in error, ensure you are accessing the correct region."
  },
  network: {
    id: "s/TdbO",
    defaultMessage: "Network error. Please check your Internet connection and try again."
  },
  notFound: {
    id: "mrACba",
    defaultMessage: "Sorry, this item is not available"
  },
  nothingInPackage: {
    id: "vwQZHw",
    defaultMessage: "Unable to continue. Please add at least one item to your package for saving."
  },
  nothingSelected: {
    id: "vRF4+E",
    defaultMessage: "Unable to continue. Please select at least one topic or resource for sending."
  },
  nothingToReview: {
    id: "+TUel0",
    defaultMessage: "Unable to continue. Return and try again when you have some resources available to review."
  },
  otherSessionInProgress: {
    id: "zCD9V5",
    defaultMessage: "Unable to proceed with your request while a {otherSession} is in progress. Conclude that session and try again."
  },
  staleEntityError: {
    id: "rbNcOy",
    defaultMessage: "Unable to save your changes. Looks like this item has been updated elsewhere, likely by another user. Reload and try again."
  },
  tokenExpired: {
    id: "pxvMYW",
    defaultMessage: "Your IMD Health access token has expired. To retrieve a new token, please refresh the page."
  },
  unauthenticated: {
    id: "A3HIqw",
    defaultMessage: "Sorry, you need to log in to do this."
  },
  unauthorized: {
    id: "DKOZOY",
    defaultMessage: "You are not authorized to access this resource"
  },
  userNotConfirmed: {
    id: "MhV5BN",
    defaultMessage: "Before you can sign in to your account, you need to confirm the email address associated with your account"
  },
  userNotFound: {
    id: "8+686N",
    defaultMessage: "Unable to find an account with that email. Please correct the email, or sign up for a new account."
  },
  usernameExists: {
    id: "2gnhOk",
    defaultMessage: "An account for that email address already exists. Try signing in or resetting the password."
  }
});