import * as accessibility from "translations/accessibility"
import appNames from "translations/appNames"
import * as attributes from "translations/attributes"
import buttons from "translations/buttons"
import dates from "translations/dates"
import entities from "translations/entities"
import * as enums from "translations/enums"
import errors from "translations/errors"
import labels from "translations/labels"
import practitionerTypes from "translations/practitionerTypes"
import prefixes from "translations/prefixes"
import regions from "translations/regions"
import screens from "translations/screens"
import specialties from "translations/specialties"
import validations from "translations/validations"

import { patientSessionTranslations } from "components/patientSession/PatientSession"
import { pspTranslations } from "components/psp/Psp"

export default {
  accessibility,
  appNames,
  attributes,
  buttons,
  dates,
  entities,
  enums,
  errors,
  labels,
  patientSessionTranslations,
  practitionerTypes,
  prefixes,
  pspTranslations,
  regions,
  screens,
  specialties,
  validations,
}
