var _CtTopicSubtreeNavigator_data;

import React, { useMemo } from "react";
import { View } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpTopicSubtree from "components/topic/CpTopicSubtree";
import QlTopicSubtree from "query/QlTopicSubtree";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
export const CtTopicSubtreeNavigator_dataFragment = _CtTopicSubtreeNavigator_data !== void 0 ? _CtTopicSubtreeNavigator_data : _CtTopicSubtreeNavigator_data = require("./__generated__/CtTopicSubtreeNavigator_data.graphql");

/**
 * Given a pointer to a resource node key, render that node's subtree
 */
const CtTopicSubtreeNavigator = ({
  resourceNodeKey,
  style
}) => {
  const locale = useBackendSupportedLocale();
  const resourceNodeData = useFragment(CtTopicSubtreeNavigator_dataFragment, resourceNodeKey);
  const queryVariables = useMemo(() => ({
    id: resourceNodeData.id,
    locale
  }), [locale, resourceNodeData.id]);
  return <View style={style}>
      <CpUpdatePageTitle title={getNameVariant(resourceNodeData)} />
      <QlTopicSubtree variables={queryVariables}>
        {subtreeRoot => <CpTopicSubtree node={subtreeRoot} />}
      </QlTopicSubtree>
    </View>;
};

export default CtTopicSubtreeNavigator;