import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - folder_open
const FolderOpen: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M26.688 24v-13.313h-21.375v13.313h21.375zM26.688 8q1.063 0 1.844 0.813t0.781 1.875v13.313q0 1.063-0.781 1.875t-1.844 0.813h-21.375q-1.063 0-1.844-0.813t-0.781-1.875v-16q0-1.063 0.781-1.875t1.844-0.813h8l2.688 2.688h10.688z" />
    </Svg>
  )
}

export default FolderOpen
