import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpAppBusy from "components/app/CpAppBusy";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtHome from "components/containers/CtHome";
import useNavigationRedirect from "hooks/useNavigationRedirect";
import Routes from "navigators/Routes";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * The home page.
 */
const ScHome = ({
  route
}) => {
  const {
    pendingUrl
  } = useNavigationRedirect();

  if (pendingUrl?.includes(Routes.topic)) {
    // If we're going to redirect to the topic page after login, we need to show the busy screen instead of flashing the home page
    return <CpAppBusy />;
  } else {
    // If we're not going to redirect to the topic page after login, we can show the home page
    return <_StyledSafeAreaView $_css={styles.flexSingle}>
        <CpUpdatePageTitle title={translations.screens[route.name]} />
        <CtHome />
      </_StyledSafeAreaView>;
  }
};

export default ScHome;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScHome___StyledSafeAreaView",
  componentId: "sc-13lzijw-0"
})(["", ""], p => p.$_css);