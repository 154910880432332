import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom - converted official Facebook png to svg
const Facebook: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M46.2687431,13.1926434 C46.2687431,13.6113316 46.2687431,16.2582988 46.2687431,21.1335451 C45.2530641,21.1335451 44.0036445,21.1335451 42.5204844,21.1335451 C38.971126,21.0387934 37.1359804,22.8190482 37.0270156,25.8985706 C37.0270156,26.369201 37.0263517,28.463247 37.025024,32.1807087 L45.8947034,32.1807087 L44.468565,41.5093292 L37.0270156,41.5093292 C37.0270156,56.354639 37.0270156,63.8515293 37.0270156,64 C52.2789007,61.5471651 64,48.1878844 64,32.1659098 C64,14.4011683 49.673112,0 32,0 C14.326888,0 0,14.4011683 0,32.1659098 C0,48.2332477 11.6868377,61.6018054 27.0022735,64 C27.0022735,61.2391054 27.0022735,53.7435707 27.0022735,41.5133961 L18.8715972,41.5133961 L18.8715972,32.1807087 L27.0022735,32.1807087 C27.0022735,28.4687522 27.0022735,25.9111086 27.0022735,24.5077781 C27.0800564,22.7517735 27.3223873,17.508945 32,14.4284933 C35.9173229,12.0694297 39.7926354,12.3145118 46.2687431,13.1926434 Z" />
    </Svg>
  )
}

export default Facebook
