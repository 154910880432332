import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconTravel: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M136.66 283.5c-5-10.5-4.4-22.9-10.8-33.9-2.6 1.8-4.5 3.2-6.4 4.5s-3.9 3.4-6.1 4.2c-4 1.3-8.7 3-12-.9-3.2-3.7-1.8-8.2.4-12 2.3-4 5.1-7.6 8.7-12.7-7.9-2.2-14.9-4.2-21.9-6.1-3.4-.9-7-1.4-10.4-2.6-4.4-1.7-5.5-4.9-2.5-8.3 5.4-6.4 11-12.4 21.3-11.3 11 1.1 22.1.2 33.2.3 4.1 0 7.6-.5 10.6-3.8 10.6-11.6 21.4-23 32.1-34.6.9-1 1.3-2.4 2.5-4.8-3.9-1.6-7.3-3.2-10.9-4.4q-51.9-16.95-103.7-33.8c-4.3-1.4-8.6-3.1-12.7-4.7 2.4-10.6 9.7-16.7 19.5-16.7h113.7c14.7 0 29.3-.2 44 .1a14.7 14.7 0 0012.2-5.2c11.6-12.6 23.6-24.9 35.3-37.5 7-7.5 15.4-11.4 25.6-11.4s14.2 4.3 13.5 14.7c-.6 8.8-3.4 16.5-10.2 22.7-12.4 11.3-24.1 23.4-36.6 34.6-5.3 4.8-7 9.9-6.9 16.8.2 50-.2 100.1.4 150.1.1 12-5 19.4-14.2 25.3-5.7-2-5.7-7.3-7-11.4Q220 248.5 203 196.1c-1.2-3.6-2.5-7.2-4.2-11.7-3.1 1.7-5.5 2.4-7.2 3.9-10.8 9.9-21.4 20.1-32.2 29.9-3 2.7-3.6 5.9-3.6 9.6-.1 13.2-.5 26.4-.3 39.7.1 5.4-3.1 8.4-6.3 11.8-3.44 3.1-6.34 6.3-12.54 4.2z" />
    </Svg>
  )
}

export default IconTravel
