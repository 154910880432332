/**
 * @generated SignedSource<<dd7b6b72a2dd77db6edadd7d40ed8fef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type FormSubmissionCreateInput = {
  locale: string;
  organizationId: string;
  resourceId: string;
  resourceNodeRevisionId: string;
  userData: any;
  userId?: string | null;
};
export type CpFormResourceMutation$variables = {
  input: FormSubmissionCreateInput;
};
export type CpFormResourceMutation$data = {
  readonly formSubmissionCreate: {
    readonly formSubmission: {
      readonly locale: Locale;
      readonly organizationId: string;
      readonly resourceId: string;
      readonly userData: any | null;
      readonly userId: string | null;
    };
  } | null;
};
export type CpFormResourceMutation = {
  response: CpFormResourceMutation$data;
  variables: CpFormResourceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "locale",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userData",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpFormResourceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FormSubmissionCreatePayload",
        "kind": "LinkedField",
        "name": "formSubmissionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormSubmission",
            "kind": "LinkedField",
            "name": "formSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpFormResourceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FormSubmissionCreatePayload",
        "kind": "LinkedField",
        "name": "formSubmissionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormSubmission",
            "kind": "LinkedField",
            "name": "formSubmission",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90a826e10b8a4014a56087292c7b4bcc",
    "id": null,
    "metadata": {},
    "name": "CpFormResourceMutation",
    "operationKind": "mutation",
    "text": "mutation CpFormResourceMutation(\n  $input: FormSubmissionCreateInput!\n) {\n  formSubmissionCreate(input: $input) {\n    formSubmission {\n      locale\n      organizationId\n      resourceId\n      userData\n      userId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5989309c509dcc8e78093cb6c4ef09c9";

export default node;
