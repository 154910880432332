import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconArm: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M224.76 202.31c.8-39.2-2.3-74.3-34.7-100.1-13.2 25.8-26.2 28.7-54.1 12.6-25.9-14.9-26.9-19.9-9.6-47.1 23.1-36.3 22.3-33.7 65.2-21 38.3 11.3 62.4 33.1 76.5 69.9 9 23.7 22.6 45.6 33.7 68.5 13 26.9 10.1 45.3-13.7 62.2-51.7 36.8-109 62.9-171.5 73.9-15.5 2.7-36.5-3.7-49.5-13.4-23.7-17.5-21.2-25.9 3.4-43.3 5.8-4.1 10.8-9.8 15-15.5 25.6-35.2 54.9-60.9 103.3-47.1 10.6 3 22.9.4 36 .4z" />
    </Svg>
  )
}

export default IconArm
