import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconHealthTopics: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M93.55 239.5h172.9v-88.2a10.82 10.82 0 00-10.8-10.8H185a13.94 13.94 0 01-12.6-7.5l-1.7-3.1a14.13 14.13 0 00-12.6-7.5h-53.6a10.82 10.82 0 00-10.8 10.8v106.3zm75.6-18h-57.6l3.3-25.7a10 10 0 016.4-7.9l7.9-3.3 11.2 12.8 11.2-12.8 7.9 3.3a9.83 9.83 0 016.4 7.9l3.3 25.7zm-28.8-72a15.3 15.3 0 1015.3 15.3 15.3 15.3 0 00-15.3-15.3zm45 28.8h40.5v-10.8h-40.5v10.8zm0 43.2h17.1v-10.8h-17.1v10.8zm27.9 0h35.1v-10.8h-35.1v10.8zm-27.9-21.6h63v-10.8h-63v10.8z"
        fillRule="evenodd"
      />
      <Path
        d="M295.25 207.1v77.4a10.82 10.82 0 01-10.8 10.8H75.55a10.82 10.82 0 01-10.8-10.8v-77.4a10.82 10.82 0 0110.8-10.8h7.2v54h194.5v-54h7.2a10.82 10.82 0 0110.8 10.8zm-28.9-74.5v-10.1a10.82 10.82 0 00-10.8-10.8h-70.7a13.94 13.94 0 01-12.6-7.5l-1.7-3.1A14.13 14.13 0 00158 93.6h-53.6a10.82 10.82 0 00-10.8 10.8v10.1a22.07 22.07 0 0110.8-2.9H158a24.94 24.94 0 0122.1 13.1c1.5 2.8 2.1 4.9 4.8 4.9h70.7a21.56 21.56 0 0110.8 3zm0-28.8V93.6a10.82 10.82 0 00-10.8-10.8h-70.7a13.94 13.94 0 01-12.6-7.5l-1.7-3.1A14.13 14.13 0 00158 64.7h-53.6a10.82 10.82 0 00-10.8 10.8v10.1a22.07 22.07 0 0110.8-2.9H158a24.94 24.94 0 0122.1 13.1c1.5 2.8 2.1 4.9 4.8 4.9h70.7a21.63 21.63 0 0110.8 3.1z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconHealthTopics
