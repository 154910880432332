import _styled from "styled-components";
import React, { useEffect } from "react";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import CpError from "components/common/CpError";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * Uses the queryReference to call usePreloadedQuery before calling the render function.
 */
const NodeRenderer = function ({
  query,
  queryReference,
  render
}) {
  const queryData = usePreloadedQuery(query, queryReference);
  return queryData.node ? render(queryData) : <_StyledCpError error={translations.errors.notFound} $_css={styles.errorBox} />;
};
/**
 * Calls loadQuery and passes the query reference to the NodeRenderer.
 */


const NodeFetcher = function ({
  variables,
  query,
  render
}) {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader(query);
  useEffect(() => {
    loadQuery(variables, {
      fetchPolicy: "store-and-network"
    });
    return disposeQuery;
  }, [disposeQuery, variables, loadQuery]);
  return queryReference ? <NodeRenderer query={query} queryReference={queryReference} render={render} /> : null;
};
/**
 * Fetches a graphql node using the global id and calls the render function with the resulting data.
 * If no id is provided, the render function is called with null as the argument. This is useful for screens like
 * forms that can either be used to create a new entity or edit an existing one.
 */


const CtGraphqlNode = function ({
  query,
  render,
  variables
}) {
  return variables === undefined ? render(null) : <NodeFetcher query={query} render={render} variables={variables} />;
};

export default CtGraphqlNode;

var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CtGraphqlNode___StyledCpError",
  componentId: "sc-11hb0it-0"
})(["", ""], p => p.$_css);