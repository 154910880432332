import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconPediatrics: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M232.77 179.38v38.4h-104.4v-41.3c-5.3 3.4-8.5 4.9-10.8 7.2-12.5 12.2-24.6 24.7-37.1 36.8-8.9 8.6-17.7 9.6-24.2 3.2-7.9-7.7-7.4-15.1 2.6-25.3 21.3-21.8 42.7-43.5 64.7-64.5a31.11 31.11 0 0118.5-7.8c25-.9 50.1-.9 75.1 0 6.4.2 14.1 3.1 18.6 7.4 22.5 21.4 44.2 43.5 65.9 65.7 9.3 9.5 9.8 17 2.5 23.8s-14.4 6.5-24-2.5c-11.5-10.8-22.5-22.2-33.7-33.2-3.4-3.3-7-6.3-10.5-9.4-.97.5-2.1 1-3.2 1.5zM180.87 26.38a42.88 42.88 0 0141.9 43.2c-.3 23.2-19.7 42.5-42.8 42.5-23.7 0-43.2-20.2-42.6-44.2a42.87 42.87 0 0143.5-41.5z" />
      <Path d="M125.47 229.48c12.8 14.1 23.8 26.1 34.9 38.3-5.4 5.8-10.2 10.9-16.2 17.3 6.8 6.9 13.8 13.5 20.2 20.6 7.7 8.6 7.8 16.4 1 23-6.6 6.3-15.3 6.3-23.3-1.4q-22.35-21.6-43.7-44.1c-8.4-8.9-8.1-14.9.1-24.3 8.7-9.8 17.6-19.2 27-29.4zM218.17 287.78c-8.1-8.8-12.7-14-18.4-20.2 11-11.8 21.9-23.5 35.5-38 10.3 11.5 19.4 21.5 28.2 31.6 5.8 6.7 6.1 13.9-.2 20.4-15.6 15.9-31.2 31.8-47.2 47.2-6.8 6.5-15 6.6-21.7-.6s-5.9-15.4.8-21.8c6.83-6.8 15-12.3 23-18.6z" />
    </Svg>
  )
}

export default IconPediatrics
