import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpPersonalShortcuts_data;

import React, { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import CpGridItem from "components/home/tabs/CpGridItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import { CpShortcutSectionStyles, buildShortcutSections } from "components/shortcuts/Shortcuts";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { getNameVariant } from "services/Graphql";
import { styles } from "services/Theme";
import translations from "translations";
import CpSectionHeader from "../topic/CpSectionHeader";
const CpPersonalShortcuts_dataFragment = _CpPersonalShortcuts_data !== void 0 ? _CpPersonalShortcuts_data : _CpPersonalShortcuts_data = require("./__generated__/CpPersonalShortcuts_data.graphql");
/**
 * Compiles and renders the content for a user's personal shortcuts
 */

const CpPersonalShortcuts = ({
  onPress,
  userMembershipFragment
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    personalShortcuts
  } = useFragment(CpPersonalShortcuts_dataFragment, userMembershipFragment); // Memo to extract personal shortcut information and organize them by type. Alternatively returns a "no items found" message

  const compiledPersonalShortcuts = useMemo(() => {
    // Set fallback content if no personal shortcuts exist
    let tabContents = <_StyledView $_css={CpShortcutSectionStyles.noticeContainer}>
        <PrText>
          <FormattedMessage id="un2zaF" defaultMessage="You don’t have any shortcuts saved yet." />
        </PrText>
        <PrText>
          <FormattedMessage id="XaQUVc" defaultMessage="Look for the {shortcutIcon} icon while browsing to create new shortcuts." values={{
          shortcutIcon: <PrIcon style={{
            verticalAlign: "bottom"
          }} fill={buttonColors.primary} size={iconSizes.small} icon={IconName.starOutline} />
        }} />
        </PrText>
      </_StyledView>;

    if (personalShortcuts?.length) {
      // Results will be an array of ShortcutType's and grouped by Type
      // [ResourceNode::Type]: Array<ShortcutType>
      const groupedPersonalShortcutItems = personalShortcuts?.reduce((returnValue, shortcut) => {
        // Group by topic or resource
        // TODO: How can we group by treatment? Do we care
        const type = shortcut.resourceNode.revisionId === shortcut.rootResourceNodeRevisionId ? formatMessage(translations.entities.topic, {
          count: 2
        }) : formatMessage(translations.entities.resource, {
          count: 2
        });
        returnValue[type] = returnValue[type] || [];
        const shortcutComponent = <CpGridItem color="primary" icon={shortcut.icon} key={`CpPersonalShortcuts-${shortcut._id}`} link={{
          link_type: "topic",
          value: ""
        }} onPress={() => {
          onPress(shortcut.rootResourceNodeRevisionId, shortcut.resourceNode.revisionId);
        }} title={getNameVariant(shortcut)} />;
        returnValue[type].push(shortcutComponent);
        return returnValue;
      }, {}) ?? {};
      tabContents = buildShortcutSections(groupedPersonalShortcutItems, isNarrowScreen);
    }

    return tabContents;
  }, [buttonColors.primary, iconSizes.small, personalShortcuts, isNarrowScreen, formatMessage, onPress]);
  return <_StyledView2 $_css2={CpShortcutSectionStyles.contentContainer}>
      <CpSectionHeader label={formatMessage(translations.labels.myShortcuts)} />
      <_StyledPrText $_css3={[styles.paddingVerticalDouble, styles.marginHorizontalSingle]}>
        <FormattedMessage id="4Eo6ZQ" defaultMessage="Shortcuts are quick and easy ways to access your most frequented resources on IMD." />
      </_StyledPrText>
      {compiledPersonalShortcuts}
    </_StyledView2>;
};

export default CpPersonalShortcuts;

var _StyledView = _styled(View).withConfig({
  displayName: "CpPersonalShortcuts___StyledView",
  componentId: "sc-19ub0v2-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPersonalShortcuts___StyledView2",
  componentId: "sc-19ub0v2-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPersonalShortcuts___StyledPrText",
  componentId: "sc-19ub0v2-2"
})(["", ""], p => p.$_css3);