var _QlHelpQuery;

import React, { useMemo } from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
import { useBackendSupportedLocale } from "services/Intl";
const QlHelpRequest = _QlHelpQuery !== void 0 ? _QlHelpQuery : _QlHelpQuery = require("./__generated__/QlHelpQuery.graphql");
/**
 * Query loader for the help page. Revision ID's are hardcoded to match the relevant resource tree.
 */

const QlHelp = ({
  children
}) => {
  const locale = useBackendSupportedLocale();
  const variables = useMemo(() => ({
    locale,
    revisionId: "bbf0365d-5649-4c78-b404-601017462fcf",
    rootRevisionId: "bbf0365d-5649-4c78-b404-601017462fcf"
  }), [locale]);
  return <CtGraphqlQuery query={QlHelpRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlHelp;