import React, { createContext, memo } from "react"

/**
 * Contains information about how much height is available for use.
 */
export const AvailableHeightContext = createContext<undefined | number>(undefined)

/**
 * Contains information about how much height is available for use.
 * This is probably a good candidate to merge into screen layout
 */
const CxAvailableHeight: React.FC<{ children: React.ReactNode; height?: number }> = ({
  children,
  height,
}) => {
  return (
    <AvailableHeightContext.Provider value={height}>{children}</AvailableHeightContext.Provider>
  )
}

export default memo(CxAvailableHeight)
