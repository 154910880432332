import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconCardiogram: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M180.28 80.79c46.55-50.82 128-15.61 128 57.9 0 61.13-81 139.05-128 164.51-34.46-18.66-87.18-65.52-112.87-113.29h13.73A233.34 233.34 0 0098.31 215c24.59 31.62 57.17 59.59 82 74.43 24.8-14.84 57.38-42.81 82-74.43 19.74-25.37 34-52.67 34-76.3 0-22.81-8.93-41.21-22.2-53.36a63.69 63.69 0 00-19.49-12.26 59.48 59.48 0 00-21.93-4.21c-15.33 0-30.95 6.21-43.57 20a8.85 8.85 0 01-.75.75 12 12 0 01-16.87-.75c-12.63-13.79-28.25-20-43.57-20a59.48 59.48 0 00-21.93 4.2 63.69 63.69 0 00-19.52 12.26c-13.27 12.15-22.2 30.55-22.2 53.36a72.27 72.27 0 002.51 18.22H54.47a81.51 81.51 0 01-2.19-18.22c0-73.51 81.45-108.72 128-57.9z"
        fillRule="evenodd"
      />
      <Path d="M52.28 167.91h43.89l19-17.21a6 6 0 018.44.42 6.09 6.09 0 011.21 2L135 182.29l19-66.36a6 6 0 0111.69.71l19.21 84.17 16-59a6 6 0 0111.15-1.11l15.88 27.24h16.54a12 12 0 111.15 12h-21.11a6 6 0 01-5.18-3l-10.59-18.17-18.55 68.33a6 6 0 01-11.61-.26l-19.46-85.2-17.72 62.08a6 6 0 01-11.4.28l-13.47-38.4-13.66 12.4a6 6 0 01-4.42 1.95H52.28v-12z" />
    </Svg>
  )
}

export default IconCardiogram
