import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconEye: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M177.8 279c-61.5-.3-110.5-23.9-145.4-75.8-11.9-17.8-10.7-32.7.7-49.7 42.8-63.6 139.1-90.7 214.5-59.7 32.4 13.3 60.8 31.8 80.6 61.9 10.5 16 10.4 30.4-.3 46.8-35.8 54.22-86.9 77.02-150.1 76.5zm2.9-171.1c-39.9-.5-72 30.7-72.3 70.1-.3 41.4 30.2 73.1 70.7 73.4a72.07 72.07 0 0073.1-70.1c1.1-39.68-31.3-72.88-71.5-73.38z" />
      <Path d="M134.7 170.92c31.4 6.2 43.2-5.2 36.5-35.5 20.7-4.9 38.6 2.4 49.1 20A47.24 47.24 0 01214 212c-13.4 14.1-35.5 18.4-53.2 10.3-20.5-9.3-29.6-27.08-26.1-51.38z" />
    </Svg>
  )
}

export default IconEye
