import React, { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CpHeaderButton from "components/common/CpHeaderButton";
import CpPatientSessionTimer from "components/patientSession/CpPatientSessionTimer";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import { ActionButtonUserType } from "components/primitives/PrActionButton/PrActionButton";
import { IconName } from "components/primitives/PrIcon";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useSnackbar from "hooks/useSnackbar";
import { AppTool } from "services/Constants";
import { SnackbarIds } from "services/Snackbar";
import translations from "translations";

/**
 * Buttons to handle opening the PatientSession modal from either the navHeader or the account menu
 */
const PatientSessionButton = ({
  onPressBeforeDispatch,
  type
}) => {
  const {
    dispatchPatientSession,
    patientSessionData: {
      isPatientSession
    }
  } = usePatientSessionContext();
  const {
    removeSnackbar
  } = useSnackbar();
  const {
    formatMessage
  } = useIntl();
  const {
    appConfig,
    user
  } = useImdSession();
  const userExists = Boolean(user);
  const patientEducationTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Educate);
  const isPatientEducationDisabled = patientEducationTool?.status !== "active";
  const shouldDisablePatientSession = useMemo(() => {
    return !appConfig?.appTools?.length || !patientEducationTool || isPatientEducationDisabled;
  }, [appConfig?.appTools, isPatientEducationDisabled, patientEducationTool]);
  const handleOpenPatientSession = useCallback(() => {
    onPressBeforeDispatch && onPressBeforeDispatch(); // Clearing reminder in case the notification was visible when opening the modal

    removeSnackbar(SnackbarIds.InactivityReminder);
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionStart
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession, onPressBeforeDispatch, removeSnackbar]); // Guard - patient education sessions should only be available if:
  // - It is an authenticated non-kiosk/patient user
  // - The education tool is not disabled

  if (!userExists || user?.isPatient || shouldDisablePatientSession) {
    return null;
  }

  const timer = isPatientSession && type === ActionButtonUserType.NavHeader ? <CpPatientSessionTimer showViewed={true} /> : null;
  return <React.Fragment>
      <CpHeaderButton accessibilityHint={formatMessage(translations.accessibility.hint.navHeaderPatientSessionButton)} icon={isPatientSession ? IconName.iconPatientSessionActive : IconName.iconPatientSession} label={isPatientSession ? <FormattedMessage id="rVmIB7" defaultMessage="Patient Session is in Progress" /> : <FormattedMessage id="b17a0x" defaultMessage="Educate a Patient" />} onPress={handleOpenPatientSession} shortLabel={isPatientSession ? <FormattedMessage id="NaxjIU" defaultMessage="In Progress" /> : <FormattedMessage id="59NNVP" defaultMessage="Educate Patient" />} testID={type === ActionButtonUserType.AccountMenu ? "PatientSessionButton-accountMenu" : "PatientSessionButton-header"} type={type} />
      {timer}
    </React.Fragment>;
};

export default PatientSessionButton;