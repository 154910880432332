import React from "react"
import Svg, { G, Path, SvgProps } from "react-native-svg"

/**
 * Renders the basic standalone IMD logo.
 * NB: This component cannot use the theme because it is used by CpAppLoading.
 */
const CpImdLogo: React.FC<SvgProps> = ({ height = 72, ...props }) => (
  <Svg height={height} viewBox="0 0 664 260" {...props}>
    <G fill="none" fillRule="evenodd" stroke="none">
      <G fillRule="nonzero">
        <Path
          d="M26.9844,0.5387 C12.3004,0.5387 0.3974,12.4417 0.3974,27.1257 L0.3974,233.1407 C0.3974,247.5437 12.2134,258.9927 26.9854,258.9927 C41.0164,258.9927 53.5714,247.5437 53.5714,233.1407 L53.5714,27.1257 C53.5714,12.4417 41.6684,0.5387 26.9844,0.5387"
          fill={props.fill || "#0F1E73"}
        />
        <Path
          d="M375.6372,25.5568 C375.2442,12.8128 365.6192,2.6338 352.9822,0.8698 C352.9462,0.8638 352.9102,0.8648 352.8742,0.8598 C351.6412,0.6918 350.4152,0.4978 349.1322,0.4978 C348.9322,0.4978 348.7482,0.5498 348.5512,0.5538 C342.2042,0.5558 336.1002,2.8848 331.3442,7.1828 C330.9292,7.5468 330.4842,7.8718 330.0942,8.2608 C329.8102,8.5528 329.5692,8.8908 329.2982,9.1988 C328.1452,10.4598 327.1342,11.8248 326.2522,13.2928 L241.0322,151.2928 L155.8152,13.2958 C154.9322,11.8258 153.9192,10.4578 152.7632,9.1958 C152.4942,8.8888 152.2542,8.5518 151.9722,8.2618 C151.5802,7.8708 151.1332,7.5438 150.7162,7.1788 C145.9592,2.8838 139.8602,0.5568 133.5152,0.5538 C133.3182,0.5498 133.1352,0.4978 132.9362,0.4978 C131.6542,0.4978 130.4302,0.6918 129.1992,0.8588 C129.1592,0.8648 129.1202,0.8638 129.0822,0.8698 C116.4472,2.6358 106.8242,12.8108 106.4292,25.5518 C106.4202,25.6868 106.3942,25.8188 106.3872,25.9538 C106.3852,26.0878 106.3472,26.2108 106.3472,26.3458 L106.3472,233.1398 C106.3472,247.5438 118.1642,258.9928 132.9362,258.9928 C146.9672,258.9928 159.5222,247.5438 159.5222,233.1398 L159.5222,120.5038 L218.8162,216.5208 C218.9572,216.7488 219.1472,216.9208 219.2942,217.1428 C219.5432,217.4988 219.8202,217.8288 220.0862,218.1738 C224.2312,223.8068 230.2422,227.2648 236.8052,228.2558 C236.9762,228.2848 237.1452,228.2918 237.3152,228.3178 C238.5482,228.4788 239.7782,228.5948 241.0322,228.5798 C242.2862,228.5948 243.5162,228.4788 244.7492,228.3178 C244.9202,228.2918 245.0892,228.2848 245.2602,228.2558 C251.8212,227.2648 257.8312,223.8078 261.9762,218.1768 C262.2442,217.8308 262.5222,217.4988 262.7722,217.1408 C262.9182,216.9198 263.1082,216.7478 263.2482,216.5208 L322.5432,120.5028 L322.5432,233.1398 C322.5432,247.5438 334.3602,258.9928 349.1322,258.9928 C363.1622,258.9928 375.7182,247.5438 375.7182,233.1398 L375.7182,26.3458 C375.7182,26.2098 375.6792,26.0868 375.6782,25.9528 C375.6712,25.8198 375.6462,25.6898 375.6372,25.5568"
          fill={props.fill || "#0F1E73"}
        />
        <Path
          d="M534.5728,0.6134 L454.1348,0.6134 C439.9738,0.6134 428.4928,12.0924 428.4928,26.2554 C428.4928,40.4184 439.9738,51.8974 454.1348,51.8974 L534.5728,51.8974 C577.5638,51.8974 612.5408,86.8754 612.5408,129.8654 C612.5408,172.8564 577.5638,207.8284 534.5728,207.8284 L454.1348,207.8284 C439.9738,207.8284 428.4928,219.3074 428.4928,233.4714 C428.4928,247.6344 439.9738,259.1134 454.1348,259.1134 L534.5728,259.1134 C605.8428,259.1134 663.8248,201.1334 663.8248,129.8654 C663.8248,58.5934 605.8428,0.6134 534.5728,0.6134"
          fill={props.fill || "#0F1E73"}
        />
        <Path
          d="M479.7173,129.8634 C479.7173,115.7184 468.2503,104.2514 454.1053,104.2514 C439.9603,104.2514 428.4933,115.7184 428.4933,129.8634 C428.4933,144.0084 439.9603,155.4754 454.1053,155.4754 C468.2503,155.4754 479.7173,144.0084 479.7173,129.8634"
          fill={props.fill || "#14D7B4"}
        />
      </G>
    </G>
  </Svg>
)

export default CpImdLogo
