import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Pressable, ScrollView, View } from "react-native";
import CpAppFooter from "components/app/CpAppFooter";
import CpPopup from "components/common/CpPopup";
import CpScroll from "components/common/CpScroll";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations"; // Base height and width for tabs

const TAB_HEIGHT = 60;
const TAB_WIDTH = 90; // Tab widths are required when rendered vertically to know how much space to allocate to the tab bar

const TAB_CONTAINER_WIDTH_FULL = 260;
const TAB_CONTAINER_WIDTH_SMALL = 180; // Height and width of More menu - arbitrarily determined, but should provide enough room to display ~5 hidden tabs

const MORE_MENU_MAX_HEIGHT = 300;
const MORE_MENU_MAX_WIDTH = 300;
const localStyles = {
  closeButtonContainer: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    justify-content: center;
    margin: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  closeButtonText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: bold;
    padding-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  contentMissingText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  fade: css`
    ${styles.fade};
    /* Adding extra rounding to ensure fade isn't visible on outer container */
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.soft + 1}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.soft + 1}px;
    height: 20px;
    top: 0px;
  `,
  fadeLeft: css`
    right: 15px;
  `,
  fadeRight: css`
    left: 0px;
  `,
  hideTab: css`
    top: 1px;
  `,
  item: {
    base: css`
      align-items: center;
      background-color: ${({
      theme
    }) => theme.brandColors.tabBackground};
      border: 1px solid ${({
      theme
    }) => theme.brandColors.tabSeparator};
      flex: 1;
      justify-content: center;
      text-align: center;
    `,
    highlighted: css`
      background-color: ${({
      theme
    }) => theme.brandColors.surface};
      border-color: ${({
      theme
    }) => theme.brandColors.tabBorder};
      border-width: 1px;
      z-index: ${({
      theme
    }) => theme.layers.firstLayer};
    `
  },
  minWidth: css`
    min-width: 100%;
  `,
  moreMenuContainer: css`
    top: -2px;
    z-index: ${({
    theme
  }) => theme.layers.fourthLayer};
  `,
  moreMenuItems: {
    base: css`
      background-color: ${({
      theme
    }) => theme.brandColors.secondaryBackground};
      justify-content: center;
      max-height: ${({
      theme
    }) => theme.buttonHeights.xxlarge}px;
      min-height: ${({
      theme
    }) => theme.buttonHeights.medium}px;
    `,
    highlighted: css`
      background-color: ${({
      theme
    }) => theme.brandColors.surface};
    `
  },
  moreMenuStyle: css`
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
  `,
  tabContent: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: ${({
    theme
  }) => theme.brandColors.tabBorder};
    border-width: 1px;
  `,
  tabScroller: css`
    overflow-y: auto;
    margin-vertical: 15px;
  `,
  text: {
    base: css`
      color: ${({
      theme
    }) => theme.textColors.tabLabel};
      font-family: ${({
      theme
    }) => theme.fontFamilies.default};
    `,
    highlighted: css`
      font-weight: bold;
    `
  }
}; // Styles modifying any matching base styles above for the respective tab layouts

const bottomStyles = {
  container: css`
    flex-direction: column-reverse;
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  fade: css`
    ${localStyles.fade};
    /* Ensure fade is only seen at top of container */
    top: 0px;
  `,
  item: {
    base: css`
      ${localStyles.item.base};
      border-top-color: ${({
      theme
    }) => theme.brandColors.tabBorder};
      border-bottom-left-radius: ${({
      theme
    }) => theme.spacing.single}px;
      border-bottom-right-radius: ${({
      theme
    }) => theme.spacing.single}px;
      height: 90%;
      min-width: ${TAB_WIDTH}px;
    `,
    highlighted: css`
      ${localStyles.item.highlighted};
      border-top-color: transparent;
      height: 100%;
      margin-right: -${({
      theme
    }) => theme.spacing.third}px;
      margin-left: -${({
      theme
    }) => theme.spacing.third}px;
      bottom: 0px;
    `
  },
  tabContainer: css`
    bottom: 1px; /* Pushes the tab view up 1 px to cover the border of the content area*/
    flex-direction: column-reverse;
    /* Ensures the border of the content area gets covered */
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  tabContent: css`
    ${localStyles.tabContent};
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-width: 0px;
  `,
  tabInnerContainer: css`
    flex-direction: row;
    justify-content: center;
    min-height: ${TAB_HEIGHT}px;
    overflow: hidden;
    padding-horizontal: 10%;
  `,
  tabInnerContainerSmall: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `
};
const leftStyles = {
  container: css`
    flex-direction: row;
  `,
  fade: css`
    ${localStyles.fade};
  `,
  item: {
    base: css`
      ${localStyles.item.base};
      border-right-color: ${({
      theme
    }) => theme.brandColors.tabBorder};
      border-bottom-left-radius: ${({
      theme
    }) => theme.spacing.single}px;
      border-top-left-radius: ${({
      theme
    }) => theme.spacing.single}px;
      left: 1px;
      min-height: ${TAB_HEIGHT}px;
    `,
    highlighted: css`
      ${localStyles.item.highlighted};
      border-right-color: transparent;
      margin-bottom: -1px;
      margin-top: -1px;
      min-height: ${TAB_HEIGHT}px;
    `
  },
  tabContainer: css`
    flex-direction: column;
    /* Ensures the border of the content area gets covered */
    z-index: ${({
    theme
  }) => theme.layers.thirdLayer};
  `,
  tabContent: css`
    ${localStyles.tabContent};
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-width: 0px;
  `,
  tabInnerContainer: css`
    align-items: flex-end;
    justify-content: center;
    /* padding-top: 10%; */
  `,
  tabInnerContainerSmall: css``
};
const rightStyles = {
  container: css`
    flex-direction: row-reverse;
  `,
  fade: css`
    ${localStyles.fade};
  `,
  item: {
    base: css`
      ${localStyles.item.base};
      border-left-color: ${({
      theme
    }) => theme.brandColors.tabBorder};
      border-bottom-right-radius: ${({
      theme
    }) => theme.spacing.single}px;
      border-top-right-radius: ${({
      theme
    }) => theme.spacing.single}px;
      min-height: ${TAB_HEIGHT}px;
      right: 1px;
    `,
    highlighted: css`
      ${localStyles.item.highlighted};
      border-left-color: transparent;
      margin-bottom: -1px;
      margin-top: -1px;
      min-height: ${TAB_HEIGHT}px;
    `
  },
  tabContainer: css`
    flex-direction: column;
    /* Ensures the border of the content area gets covered */
    z-index: ${({
    theme
  }) => theme.layers.thirdLayer};
  `,
  tabContent: css`
    ${localStyles.tabContent};
    /* These radii aren't noticeable because the scrollbar renders over the containers corners */
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-width: 0px;
  `,
  tabInnerContainer: css`
    align-items: flex-start;
    justify-content: center;
    /* padding-top: 10%; */
  `,
  tabInnerContainerSmall: css``
};
const topStyles = {
  container: css`
    flex-direction: column;
  `,
  fade: css`
    ${localStyles.fade};
    /* Height of tabs + 1px to ensure fade is only seen below the tab separator */
    top: ${TAB_HEIGHT + 1}px;
  `,
  item: {
    base: css`
      ${localStyles.item.base};
      border-bottom-color: ${({
      theme
    }) => theme.brandColors.tabBorder};
      border-top-left-radius: ${({
      theme
    }) => theme.spacing.single}px;
      border-top-right-radius: ${({
      theme
    }) => theme.spacing.single}px;
      height: 90%;
      min-width: ${TAB_WIDTH}px;
      top: 10%;
    `,
    highlighted: css`
      ${localStyles.item.highlighted};
      border-bottom-color: transparent;
      height: 100%;
      margin-right: -${({
      theme
    }) => theme.spacing.third}px;
      margin-left: -${({
      theme
    }) => theme.spacing.third}px;
      top: 0px;
    `
  },
  tabContainer: css`
    top: 1px; /* Pushes the tab view down 1 px to cover the border of the content area*/
    /* Ensures the border of the content area gets covered */
    z-index: ${({
    theme
  }) => theme.layers.secondLayer};
  `,
  tabContent: css`
    ${localStyles.tabContent};
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    border-bottom-width: 0px;
  `,
  tabInnerContainer: css`
    flex-direction: row;
    justify-content: center;
    min-height: ${TAB_HEIGHT}px;
    overflow: hidden;
    padding-horizontal: 10%;
  `,
  tabInnerContainerSmall: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * Renders a series of tabs and their contents for in-page navigation
 * @param onPress additional functionality outside of internal tab management (e.g. tracking presses)
 * @param tabConfig each array set expects to be led with a string that can be readily rendered (e.g. intl `formatMessage()`), paired with the content to render
 */
const CpTabView = ({
  hideTabs = false,
  onPress,
  style,
  tabConfig,
  tabIndex = 0,
  tabRules
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    contentMinHeight,
    height,
    isNarrowScreen,
    isWideScreen,
    width
  } = useScreenLayout();
  const {
    iconSizes,
    mediaBreaks,
    spacing,
    textColors
  } = useAppTheme(); // MORE menu state && callbacks

  const [showMoreTabs, setShowMoreTabs] = useState(false);
  const handleOpenMoreTabs = useCallback(() => {
    setShowMoreTabs(true);
  }, []);
  const handleCloseMoreTabs = useCallback(() => {
    setShowMoreTabs(false);
  }, []);
  const handlePressMoreMenuItem = useCallback((label, index) => {
    handleCloseMoreTabs();
    onPress(label, index);
  }, [handleCloseMoreTabs, onPress]); // MORE menu layout state and management

  const [moreTabLayout, setMoreTabLayout] = useState({
    width: 0,
    x: 0,
    y: 0
  });
  const handleMoreTabLayout = useCallback(({
    nativeEvent: {
      layout
    }
  }) => {
    // 10% padding on either side of tabs, so we want to account for 1/2 of that
    const tabRightPadding = width * 0.05; // Confirm whether the menu height and widths actually fit on screen as intended

    const menuHeightFits = height - layout.top > MORE_MENU_MAX_HEIGHT;
    const menuWidthFits = width - tabRightPadding - TAB_WIDTH > MORE_MENU_MAX_WIDTH + spacing.double; // Calculate the actual menu width in case we're rendering on a really narrow screen

    const menuWidth = width < MORE_MENU_MAX_WIDTH + spacing.double ? width - spacing.double : MORE_MENU_MAX_WIDTH;
    setMoreTabLayout({
      width: menuWidth,
      // This should align the menu to the left of the More button if space allows
      // If not enough space, we left-align the menu and shrink it accordingly
      x: menuWidthFits ? width - menuWidth - tabRightPadding - TAB_WIDTH : layout.left + spacing.single,
      // This should align the menu just below the full height of the more button
      // If not enough space, we bottom-align the menu
      y: menuHeightFits ? layout.top + spacing.double : height - MORE_MENU_MAX_HEIGHT - spacing.quadruple
    });
  }, [height, spacing, width]); // Determine tab layout - overrides vertical layouts below 'Medium' screen widths to ensure content can be viewed

  const currentLayoutPosition = useMemo(() => {
    const rulePosition = tabRules?.[0].position ?? "TOP"; // Until we really need/want a drawer component, we will override small screen vertical layouts

    const overrideLayout = ["LEFT", "RIGHT"].includes(rulePosition) && width < mediaBreaks.medium;
    return overrideLayout ? "TOP" : rulePosition;
  }, [mediaBreaks.medium, tabRules, width]);
  const hasVerticalTabs = ["LEFT", "RIGHT"].includes(currentLayoutPosition);
  const tabStyles = useMemo(() => {
    switch (currentLayoutPosition) {
      case "BOTTOM":
        return bottomStyles;

      case "LEFT":
        return leftStyles;

      case "RIGHT":
        return rightStyles;

      case "TOP":
      default:
        return topStyles;
    }
  }, [currentLayoutPosition]); // Ensure the tab index is not out of range, and if it is fall back to the first tab

  const actualTabIndex = tabIndex < 0 || tabIndex >= tabConfig.length ? 0 : tabIndex;
  const currentTabContents = useMemo(() => tabConfig?.[actualTabIndex]?.component ?? // Fallback tab contents if no config is present
  <_StyledView $_css={[styles.paddingDouble, styles.marginTopDouble, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
          <_StyledPrText $_css2={localStyles.contentMissingText}>
            <FormattedMessage id="vV58Vd" defaultMessage="Looks like there is no content to display. Please refresh and try again." />
          </_StyledPrText>
          <_StyledPrText2 $_css3={[localStyles.contentMissingText, styles.marginTopDouble]}>
            <FormattedMessage id="N93t6I" defaultMessage="If the issue persists and you think this is in error, please contact support@imdhealth.com" />
          </_StyledPrText2>
        </_StyledView>, [isNarrowScreen, actualTabIndex, tabConfig]); // Calculate the amount of tabs we can safely view horizontally

  const tabFitCount = useMemo(() => {
    if (!["TOP", "BOTTOM"].includes(currentLayoutPosition)) {
      return tabConfig.length;
    } // Calculate the potential width of the tabs


    const usedWidth = tabConfig.length * TAB_WIDTH; // Ensure we can actually fit all the tabs within our screen

    const adjustedWidth = width * 0.9 - TAB_WIDTH; // Calculate how many tabs to cut in case they don't all fit

    const tabsToCut = adjustedWidth >= usedWidth ? 0 : Math.abs(Math.ceil(tabConfig.length - adjustedWidth / TAB_WIDTH));
    return tabConfig.length - tabsToCut;
  }, [currentLayoutPosition, tabConfig.length, width]); // Determine the visible tabs

  const currentTabs = useMemo(() => {
    const compiledTabs = [];

    for (let i = 0; i < tabFitCount; i++) {
      if (!tabConfig[i]) continue;
      const {
        key,
        label
      } = tabConfig[i]; // extracting the label from `tabItem`

      const isCurrentTab = i === actualTabIndex; // Ensuring vertical tabs don't take over the screen at different widths

      const verticalFullTabWidth = isCurrentTab ? TAB_CONTAINER_WIDTH_FULL : TAB_CONTAINER_WIDTH_FULL - 10;
      const verticalNarrowTabWidth = isCurrentTab ? TAB_CONTAINER_WIDTH_SMALL : TAB_CONTAINER_WIDTH_SMALL - 10;
      compiledTabs.push(<_StyledPressable accessibilityRole="tab" key={`CpTabView-${key}`} onPress={() => onPress(label, i)} testID={`CpTabView-${i}`} $_css4={[tabStyles.item.base, isCurrentTab ? tabStyles.item.highlighted : undefined, // Force tab width for vertical layouts
      hasVerticalTabs ? css`
                  width: ${isWideScreen ? verticalFullTabWidth : verticalNarrowTabWidth}px;
                ` : undefined]}>
          <_StyledPrText3 children={label} numberOfLines={2} $_css5={[localStyles.text.base, isCurrentTab ? localStyles.text.highlighted : undefined]} />
        </_StyledPressable>);
    } // Add the 'More' tab button to the tabs if we can't render all tabs horizontally


    let extraButton = null;

    if (["TOP", "BOTTOM"].includes(currentLayoutPosition) && tabFitCount < tabConfig.length) {
      const isInMoreMenu = actualTabIndex >= tabFitCount;
      extraButton = <_StyledPressable2 onPress={handleOpenMoreTabs} testID="CpTabView-moreMenuTab" $_css6={[styles.row, tabStyles.item.base, isInMoreMenu ? tabStyles.item.highlighted : undefined]}>
          <PrIcon fill={textColors.tabLabel} icon={IconName.more} size={iconSizes.small} />
          <_StyledPrText4 children={translations.buttons.more} $_css7={localStyles.text.base} />
        </_StyledPressable2>;
    }

    return <_StyledView2 accessibilityRole="tablist" $_css8={[tabStyles.tabInnerContainer, isNarrowScreen ? tabStyles.tabInnerContainerSmall : undefined]}>
        {compiledTabs}
        {extraButton}
      </_StyledView2>;
  }, [currentLayoutPosition, tabFitCount, tabConfig, tabStyles, isNarrowScreen, actualTabIndex, hasVerticalTabs, isWideScreen, onPress, handleOpenMoreTabs, textColors.tabLabel, iconSizes.small]); // Capture the tabs that are no longer in view, and place them in the 'More' menu

  const moreTabContents = useMemo(() => {
    if (!["TOP", "BOTTOM"].includes(currentLayoutPosition) && tabFitCount < tabConfig.length) {
      return null;
    }

    const moreTabs = [];

    for (let i = tabConfig.length - 1; i >= tabFitCount; i--) {
      if (!tabConfig[i]) continue;
      const {
        key,
        label
      } = tabConfig[i];
      const isSelected = actualTabIndex === i;
      moreTabs.push(<_StyledPressable3 accessibilityHint={formatMessage(translations.accessibility.label.moreMenuTabButton)} accessibilityLabel={formatMessage(translations.accessibility.label.moreMenuTabButton)} accessibilityRole="tab" key={key} onPress={() => handlePressMoreMenuItem(label, i)} testID={`CpTabView-moreMenu-${i}`} $_css9={[localStyles.moreMenuItems.base, isSelected ? localStyles.moreMenuItems.highlighted : undefined]}>
          <_StyledPrText5 children={label} $_css10={[styles.paddingSingle, localStyles.text.base, isSelected ? styles.boldText : undefined]} />
        </_StyledPressable3>);
    } // Ensure the close button is added last in case the user needs it


    moreTabs.push(<_StyledPrPressable key="closeButton" onPress={handleCloseMoreTabs} $_css11={localStyles.closeButtonContainer}>
        <_StyledPrText6 children={translations.buttons.close} $_css12={localStyles.closeButtonText} />
      </_StyledPrPressable>);
    return moreTabs;
  }, [actualTabIndex, currentLayoutPosition, formatMessage, handleCloseMoreTabs, handlePressMoreMenuItem, tabConfig, tabFitCount]); // Close the More menu if open and the screen has expanded to fit all tabs

  useEffect(() => {
    if (tabFitCount >= tabConfig.length && showMoreTabs) {
      handleCloseMoreTabs();
    }
  }, [handleCloseMoreTabs, showMoreTabs, tabConfig.length, tabFitCount]);

  if (!tabConfig) {
    return null;
  } // Trying to keep fade separator from erasing borders while still not impacting the scrollbar


  let fadeWidth = width - 18;
  let fadeStyle = css`
    width: ${fadeWidth}px;
    left: 2px;
  `;

  if (hasVerticalTabs) {
    if (isWideScreen) {
      fadeWidth = width - TAB_CONTAINER_WIDTH_FULL - 35;
    } else {
      fadeWidth = width - TAB_CONTAINER_WIDTH_SMALL - 35;
    }

    fadeStyle = css`
      width: ${fadeWidth}px;
    `;
  }

  return <_StyledView3 style={style} $_css13={[localStyles.minWidth, tabStyles.container]}>
      {
      /* Ensure vertical tabs scroll so we can access all the content */
    }
      {hasVerticalTabs ? <_StyledScrollView // Add to width in event scroller is visible - also prevent side tabs from being pushed off screen
    style={{
      maxWidth: isWideScreen ? TAB_CONTAINER_WIDTH_FULL + 15 : TAB_CONTAINER_WIDTH_SMALL + 15,
      minWidth: isWideScreen ? TAB_CONTAINER_WIDTH_FULL : TAB_CONTAINER_WIDTH_SMALL
    }} $_css14={[tabStyles.tabContainer, localStyles.tabScroller]}>
          {currentTabs}
        </_StyledScrollView> : <_StyledView4 onLayout={handleMoreTabLayout} $_css15={tabStyles.tabContainer}>
          {!hideTabs && currentTabs}
          {
        /* More Tabs Menu */
      }
          <CpPopup layout={moreTabLayout} onBackgroundPress={handleCloseMoreTabs} popupStyle={localStyles.moreMenuStyle} visible={showMoreTabs}>
            <_StyledView5 accessibilityRole="tablist" $_css16={styles.paddingVerticalSingle}>
              <_StyledScrollView2 contentContainerStyle={{
            maxHeight: MORE_MENU_MAX_HEIGHT
          }} $_css17={localStyles.moreMenuContainer}>
                {moreTabContents}
              </_StyledScrollView2>
            </_StyledView5>
          </CpPopup>
        </_StyledView4>}

      <_StyledView6 $_css18={[tabStyles.fade, fadeStyle, // Need to ensure the fade doesn't bleed out of container, while still respecting scrollbar (i.e. for LEFT)
    hasVerticalTabs && currentLayoutPosition === "LEFT" ? localStyles.fadeLeft : undefined, hasVerticalTabs && currentLayoutPosition === "RIGHT" ? localStyles.fadeRight : undefined, // Ensure we adjust the fade position if we're hiding the tabs
    currentLayoutPosition === "TOP" && hideTabs ? localStyles.hideTab : undefined]} />
      <_StyledCpScroll contentContainerStyle={rnStyles.fullWidth} $_css19={tabStyles.tabContent}>
        <_StyledView7 $_css20={contentMinHeight}>
          {currentTabContents}
        </_StyledView7>

        <CpAppFooter />
      </_StyledCpScroll>
    </_StyledView3>;
};

export default CpTabView;

var _StyledView = _styled(View).withConfig({
  displayName: "CpTabView___StyledView",
  componentId: "sc-1si16hg-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTabView___StyledPrText",
  componentId: "sc-1si16hg-1"
})(["", ""], p => p.$_css2);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpTabView___StyledPrText2",
  componentId: "sc-1si16hg-2"
})(["", ""], p => p.$_css3);

var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "CpTabView___StyledPressable",
  componentId: "sc-1si16hg-3"
})(["", ""], p => p.$_css4);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpTabView___StyledPrText3",
  componentId: "sc-1si16hg-4"
})(["", ""], p => p.$_css5);

var _StyledPressable2 = _styled(Pressable).withConfig({
  displayName: "CpTabView___StyledPressable2",
  componentId: "sc-1si16hg-5"
})(["", ""], p => p.$_css6);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpTabView___StyledPrText4",
  componentId: "sc-1si16hg-6"
})(["", ""], p => p.$_css7);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpTabView___StyledView2",
  componentId: "sc-1si16hg-7"
})(["", ""], p => p.$_css8);

var _StyledPressable3 = _styled(Pressable).withConfig({
  displayName: "CpTabView___StyledPressable3",
  componentId: "sc-1si16hg-8"
})(["", ""], p => p.$_css9);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpTabView___StyledPrText5",
  componentId: "sc-1si16hg-9"
})(["", ""], p => p.$_css10);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpTabView___StyledPrPressable",
  componentId: "sc-1si16hg-10"
})(["", ""], p => p.$_css11);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpTabView___StyledPrText6",
  componentId: "sc-1si16hg-11"
})(["", ""], p => p.$_css12);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpTabView___StyledView3",
  componentId: "sc-1si16hg-12"
})(["", ""], p => p.$_css13);

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpTabView___StyledScrollView",
  componentId: "sc-1si16hg-13"
})(["", ""], p => p.$_css14);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpTabView___StyledView4",
  componentId: "sc-1si16hg-14"
})(["", ""], p => p.$_css15);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpTabView___StyledView5",
  componentId: "sc-1si16hg-15"
})(["", ""], p => p.$_css16);

var _StyledScrollView2 = _styled(ScrollView).withConfig({
  displayName: "CpTabView___StyledScrollView2",
  componentId: "sc-1si16hg-16"
})(["", ""], p => p.$_css17);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpTabView___StyledView6",
  componentId: "sc-1si16hg-17"
})(["", ""], p => p.$_css18);

var _StyledCpScroll = _styled(CpScroll).withConfig({
  displayName: "CpTabView___StyledCpScroll",
  componentId: "sc-1si16hg-18"
})(["", ""], p => p.$_css19);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpTabView___StyledView7",
  componentId: "sc-1si16hg-19"
})(["min-height:", "px;"], p => p.$_css20);