var _QlPackageSessionDataQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";

/**
 * Used to fetch an ResourceNodes related to a Package Session from an organizations CommonTopics or specified TopicNodes
 */
export const QlPackageSessionDataQueryRequest = _QlPackageSessionDataQuery !== void 0 ? _QlPackageSessionDataQuery : _QlPackageSessionDataQuery = require("./__generated__/QlPackageSessionDataQuery.graphql");

const QlPackageSessionData = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlPackageSessionDataQueryRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlPackageSessionData;