import {
  NavigationContainer,
  NavigationState,
  createNavigationContainerRef,
  getPathFromState,
} from "@react-navigation/native"
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"

import CpAppLoading from "components/app/CpAppLoading"
import { SessionType } from "components/patientSession/PatientSession"
import useAnalytics from "hooks/useAnalytics"
import useAppBackend from "hooks/useAppBackend"
import useAppTheme from "hooks/useAppTheme"
import useImdSession from "hooks/useImdSession"
import usePatientSessionContext from "hooks/usePatientSessionContext"
import Routes, { RouteParams, navigationConfig } from "navigators/Routes"
import { AnalyticsEventName } from "services/Analytics"
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics"
import Log from "services/Log"

export const navigationRef = createNavigationContainerRef<RouteParams>()

const analyticsParamsMap = {
  resourceId: "selectedResourceId",
  rootRevisionId: "rootRevisionId",
  selectedRevisionId: "selectedRevisionId",
}

/**
 * Set up the global navigation container
 */
const CtNavigation: React.FC<{ children: React.ReactNode; prefixes: string[] }> = ({
  children,
  prefixes,
}) => {
  const [ready, setReady] = useState(false)

  const linking = useMemo(() => {
    return {
      config: navigationConfig,
      prefixes: prefixes,
    }
  }, [prefixes])

  const { brandColors, buttonColors, name, textColors } = useAppTheme()

  const {
    persistentHeaders: { membershipId },
  } = useAppBackend()

  const { authTokenSignedOut, initialized, licensee, user } = useImdSession()

  const { trackEvent, trackGoogleEvent } = useAnalytics()
  const { patientSessionData } = usePatientSessionContext()

  // useReduxDevToolsExtension(navigationRef)

  const navigationPathRef = useRef<string>("")

  // Make sure the app is initialized and we have a valid auth session before proceeding
  // Also redirect them to the membership picker if membership is ambiguous
  useEffect(() => {
    if (!initialized) return

    if (!navigationRef.current?.isReady()) return

    if (authTokenSignedOut) {
      // They had a session before, so they probably want to signin again
      navigationRef.current?.navigate(Routes.signin)
    }

    if (user) {
      // Force users to select a membership if they don't already have one in context
      // Including currentMembership to ensure that user isn't redirected before userSettings can be properly initialized
      // Patients aren't expected to have a membership, so we can ignore those users
      if (!membershipId && !user.currentMembership && !user.isPatient) {
        Log.info("CtNavigation - No membership set in context, directing user to change clinic")
        navigationRef.current?.navigate(Routes.change_clinic)
      }

      // Don't leave me sitting on the signin screen
      if (navigationRef.current?.getCurrentRoute()?.name === Routes.signin) {
        Log.info("CtNavigation - User logged in and still on signin page, redirecting home")
        navigationRef.current?.navigate(Routes.home)
      }
    }
  }, [authTokenSignedOut, initialized, user, membershipId, ready])

  // Sends pageView to analytics and update the path ref
  const capturePageView = useCallback(
    (path: string, params: Record<string, undefined> = {}) => {
      // Filter the params down to the ones used in analytics meta
      const data = Object.keys(params).reduce((acc, key) => {
        const analyticsParam = analyticsParamsMap[key as keyof typeof analyticsParamsMap]
        if (analyticsParam) {
          acc[analyticsParam] = params[key]
        }
        return acc
      }, {} as Record<string, unknown>)

      if (path === "") return

      const [pathOnly, queryString] = path.split("?")
      let patientSessionId = patientSessionData.viewSessionParams?.patientSessionId || ""
      let patientSessionType

      if (patientSessionData.isPackageSession) {
        patientSessionType = SessionType.PackageSession
      } else if (patientSessionData.isPatientSession) {
        patientSessionType = SessionType.PatientSession
      } else if (patientSessionData.isViewingSession) {
        patientSessionType = SessionType.ViewingSession
      } else if (path.includes("/view_session")) {
        // view packageSession (path = "/view_session/xxx/yyy")
        patientSessionType = SessionType.ViewingSession
        patientSessionId = path.split("/")[2] // ["", "view_session", "xxx", "yyy"]
      } else if (patientSessionData.viewSessionData?.isPsp) {
        patientSessionType = SessionType.PspSession
      } else {
        patientSessionType = SessionType.ImdSession
      }

      trackGoogleEvent({
        action: pathOnly,
        name: "pageView",
        options: {
          dimensions: {
            // 2
            [GoogleAnalyticsDimension.subdivision]:
              user?.currentOrganization?.subdivisionCode || undefined,

            // 3
            [GoogleAnalyticsDimension.organizationId]:
              user?.currentOrganization?._id || licensee?._id,
            // 4
            [GoogleAnalyticsDimension.queryString]: queryString,

            // 6
            [GoogleAnalyticsDimension.practitionerType]:
              user?.practitionerProfile?.type || undefined,

            // 10
            [GoogleAnalyticsDimension.userType]: user?.practitionerProfile?.type || undefined,

            // 14
            [GoogleAnalyticsDimension.patientSessionType]: patientSessionType,

            // 15
            [GoogleAnalyticsDimension.patientSessionId]: patientSessionId,
          },
        },
      })
      trackEvent({ data, eventType: AnalyticsEventName.pageview })
      navigationPathRef.current = path
    },
    [
      licensee,
      trackEvent,
      trackGoogleEvent,
      user?.currentOrganization?._id,
      user?.currentOrganization?.subdivisionCode,
      user?.practitionerProfile?.type,
      patientSessionData,
    ],
  )

  // Detect initial pageView
  const handleReady = useCallback(() => {
    const rootState = navigationRef.current?.getRootState()
    const path = rootState ? getPathFromState(rootState, navigationConfig) : ""
    const params = rootState?.routes[rootState.index].params
    capturePageView(path, params as Record<string, undefined>)
    setReady(true)
  }, [capturePageView])

  // Detect subsequent pageViews
  const handleStateChange = useCallback(
    async (state?: NavigationState) => {
      const path = state ? getPathFromState(state, navigationConfig) : ""
      const params = state?.routes[state.index].params
      if (path !== navigationPathRef.current) {
        capturePageView(path, params as Record<string, undefined>)
      }
    },
    [capturePageView],
  )

  const navTheme = useMemo(() => {
    return {
      colors: {
        background: brandColors.background,
        border: brandColors.primary,
        card: brandColors.surface,
        notification: buttonColors.destructive,
        primary: buttonColors.text,
        text: textColors.default,
      },
      dark: name === "dark",
    }
  }, [brandColors, buttonColors, name, textColors])

  return (
    <NavigationContainer
      // Disabled because of a bug where it retitles the page you are leaving (which messes up browser history)
      documentTitle={{ enabled: false }}
      fallback={<CpAppLoading />}
      linking={linking}
      onReady={handleReady}
      onStateChange={handleStateChange}
      ref={navigationRef}
      theme={navTheme}
    >
      {children}
    </NavigationContainer>
  )
}

export default memo(CtNavigation)
