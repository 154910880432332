/**
 * @generated SignedSource<<9b5fe72c0bb0de6de7c9b1f24377a11f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpOrganizationItem_organization$data = {
  readonly _id: string;
  readonly address: string;
  readonly id: string;
  readonly name: string;
  readonly parent: {
    readonly _id: string;
    readonly name: string;
  } | null;
  readonly type: string;
  readonly " $fragmentType": "CpOrganizationItem_organization";
};
export type CpOrganizationItem_organization$key = {
  readonly " $data"?: CpOrganizationItem_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpOrganizationItem_organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpOrganizationItem_organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "b8b6fe50e66751a601737aa5564d117a";

export default node;
