import { DevTool } from "@hookform/devtools"
import React from "react"
import { FieldValues, FormProvider, UseFormReturn } from "react-hook-form"
import { StyleProp, View, ViewProps } from "react-native"

import Config from "services/Config"
export interface CpFormProps<TFieldValues extends FieldValues> extends UseFormReturn<TFieldValues> {
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
  style?: StyleProp<ViewProps>
}

/**
 * An enclosing form view that establishes form context which makes for simpler form components.
 * On the web it provides the enclosing form element.
 */
const CpForm = <TFieldValues extends FieldValues>({
  children,
  control,
  onSubmit,
  style,
  ...otherProps
}: React.PropsWithChildren<CpFormProps<TFieldValues>>): React.ReactElement => {
  return (
    <View style={style}>
      <form onSubmit={onSubmit}>
        <FormProvider control={control} {...otherProps}>
          {children}
        </FormProvider>
      </form>
      {Config.NODE_ENV === "development" && !Config.IS_TEST && (
        <DevTool control={control} placement="top-right" />
      )}
    </View>
  )
}

export default CpForm
