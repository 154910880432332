import { defineMessages } from "react-intl";
export default defineMessages({
  anatomical_pathology: {
    id: "ZTOYsr",
    defaultMessage: "Anatomical Pathology"
  },
  anesthesia: {
    id: "DtaAjD",
    defaultMessage: "Anesthesia"
  },
  cardiac_surgery: {
    id: "Gb8QZm",
    defaultMessage: "Cardiac Surgery"
  },
  cardiology: {
    id: "/Pg839",
    defaultMessage: "Cardiology"
  },
  cardiothoracic_surgery: {
    id: "h7hqkx",
    defaultMessage: "Cardiothoracic Surgery"
  },
  cardiovascular_thoracic_surgery: {
    id: "rRFYml",
    defaultMessage: "Cardiovascular/Thoracic Surgery"
  },
  clinical_immunology_allergy: {
    id: "lAkMUr",
    defaultMessage: "Clinical Immunology/Allergy"
  },
  community_medicine: {
    id: "Vzu3e+",
    defaultMessage: "Community Medicine"
  },
  critical_care: {
    id: "23pJ+O",
    defaultMessage: "Critical Care Medicine"
  },
  dermatology: {
    id: "FFhvO3",
    defaultMessage: "Dermatology"
  },
  diseases: {
    id: "ch7VgE",
    defaultMessage: "Diseases"
  },
  electroencephalography: {
    id: "1vq1rN",
    defaultMessage: "Electroencephalography"
  },
  emergency_family_med: {
    id: "U0zgfz",
    defaultMessage: "Emergency Family Medicine"
  },
  emergency_medicine: {
    id: "EJ7PsY",
    defaultMessage: "Emergency Medicine"
  },
  endocrinology: {
    id: "ipHi+o",
    defaultMessage: "Endocrinology"
  },
  family_medicine: {
    id: "PaHKzp",
    defaultMessage: "Family Medicine"
  },
  gastroenterology: {
    id: "5kzHUO",
    defaultMessage: "Gastroenterology"
  },
  general_pathology: {
    id: "IpdO/m",
    defaultMessage: "General Pathology"
  },
  general_surgery: {
    id: "KyzuNp",
    defaultMessage: "General Surgery"
  },
  general_surgical_oncology: {
    id: "dXl2zS",
    defaultMessage: "General Surgical Oncology"
  },
  geriatrics: {
    id: "x9Du35",
    defaultMessage: "Geriatric Medicine"
  },
  gynecologic_oncology: {
    id: "2GrerB",
    defaultMessage: "Gynecologic Oncology"
  },
  hematological_pathology: {
    id: "l8yM15",
    defaultMessage: "Hematological Pathology"
  },
  hematology: {
    id: "E3OFvo",
    defaultMessage: "Hematology"
  },
  industrial_medicine: {
    id: "P5bdeB",
    defaultMessage: "Industrial Medicine"
  },
  infectious: {
    id: "LZKlbO",
    defaultMessage: "Infectious Diseases"
  },
  internal_medicine: {
    id: "WO0VD5",
    defaultMessage: "Internal Medicine"
  },
  med_scientist: {
    id: "NxAIdn",
    defaultMessage: "Med Scientist"
  },
  medical_biochemistry: {
    id: "0B2HZ3",
    defaultMessage: "Medical Biochemistry"
  },
  medical_genetics: {
    id: "aeyaXu",
    defaultMessage: "Medical Genetics & Genomics"
  },
  medical_microbiology: {
    id: "MeL0Bv",
    defaultMessage: "Medical Microbiology & Infectious Diseases"
  },
  medical_oncology: {
    id: "/bL9bf",
    defaultMessage: "Medical Oncology"
  },
  nephrology: {
    id: "Hd1Ox4",
    defaultMessage: "Nephrology"
  },
  neurology: {
    id: "3XXe+J",
    defaultMessage: "Neurology"
  },
  neuropathology: {
    id: "LN73XW",
    defaultMessage: "Neuropathology"
  },
  neurosurgery: {
    id: "dnh0E6",
    defaultMessage: "Neurosurgery"
  },
  np_adult: {
    id: "QW8zVl",
    defaultMessage: "NP-Adult"
  },
  np_family: {
    id: "r4HQAJ",
    defaultMessage: "NP-Family"
  },
  np_pediatrics: {
    id: "huj6lz",
    defaultMessage: "NP-Pediatrics"
  },
  np_primary_health_care: {
    id: "l0nDc/",
    defaultMessage: "NP-Primary Health Care"
  },
  nuclear_medicine: {
    id: "ItTcYD",
    defaultMessage: "Nuclear Medicine"
  },
  obgyn: {
    id: "oM0tlm",
    defaultMessage: "Obstetrics & Gynecology"
  },
  occupational_med: {
    id: "xbUDuS",
    defaultMessage: "Occupational Medicine"
  },
  ophthalmology: {
    id: "fzCjsl",
    defaultMessage: "Ophthalmology"
  },
  orthopedic_surgery: {
    id: "JABLij",
    defaultMessage: "Orthopedic Surgery"
  },
  otolaryngology: {
    id: "7/C9Ua",
    defaultMessage: "Otolaryngology"
  },
  pediatric_cardiology: {
    id: "dPkQIp",
    defaultMessage: "Pediatric Cardiology"
  },
  pediatric_emergency_medicine: {
    id: "EkwkSY",
    defaultMessage: "Pediatric Emergency Medicine"
  },
  pediatric_general_surgery: {
    id: "Z8KV+5",
    defaultMessage: "Pediatric General Surgery"
  },
  pediatric_hematology_oncology: {
    id: "6Vn0JT",
    defaultMessage: "Pediatric Hematology Oncology"
  },
  pediatrics: {
    id: "Ex7K53",
    defaultMessage: "Pediatrics"
  },
  physical_medicine: {
    id: "p4RB6F",
    defaultMessage: "Physical Medicine & Rehabilitation"
  },
  plastic_surgery: {
    id: "zdxI38",
    defaultMessage: "Plastic Surgery"
  },
  psychiatry: {
    id: "Ip1yMR",
    defaultMessage: "Psychiatry"
  },
  public_health: {
    id: "DDQk7N",
    defaultMessage: "Public Health & Preventive Medicine"
  },
  radiation_oncology: {
    id: "hCu5my",
    defaultMessage: "Radiation Oncology"
  },
  radiology: {
    id: "aOKP7i",
    defaultMessage: "Diagnostic-Radiology"
  },
  respirology: {
    id: "m25eXC",
    defaultMessage: "Respirology"
  },
  rheumatology: {
    id: "bgbXwc",
    defaultMessage: "Rheumatology"
  },
  thoracic_surgery: {
    id: "J8Q/AB",
    defaultMessage: "Thoracic Surgery"
  },
  urology: {
    id: "lbDAZ9",
    defaultMessage: "Urology"
  },
  vascular: {
    id: "qwjeyg",
    defaultMessage: "Vascular Surgery"
  }
});