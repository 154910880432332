import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import PrIcon from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css, rnStyles, styles } from "services/Theme";
const fabBase = css`
  align-items: center;
  background-color: ${({
  theme
}) => theme.brandColors.surface};
  border: 3px solid;
  justify-content: center;
`;
const labelBase = css`
  font-family: ${({
  theme
}) => theme.fontFamilies.button};
  font-weight: bold;
`;
const localStyles = {
  fabBgLarge: css`
    border-radius: ${({
    theme
  }) => theme.buttonHeights.xxlarge / 2}px;
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
    min-width: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  fabBgSmall: css`
    border-radius: ${({
    theme
  }) => theme.buttonHeights.medium / 2}px;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    min-width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  fabLarge: css`
    ${fabBase};
    border-radius: ${({
    theme
  }) => theme.buttonHeights.xxlarge / 2}px;
    height: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
    min-width: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  fabSmall: css`
    ${fabBase};
    border-radius: ${({
    theme
  }) => theme.buttonHeights.medium / 2}px;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    min-width: ${({
    theme
  }) => theme.buttonHeights.medium}px;
  `,
  iconSeparator: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  labelLarge: css`
    ${labelBase};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  labelSmall: css`
    ${labelBase};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};

/**
 * Renders a Floating Action Button (FAB) with an icon and/or label.
 */
const CpFabButton = ({
  color,
  icon,
  label,
  onPress,
  style,
  testID,
  ...otherProps
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    brandColors,
    iconSizes
  } = useAppTheme();
  const localColor = color ? brandColors[color] : brandColors.primary;
  const buttonStyle = css`
    border-color: ${localColor};
  `; // we have bg color to be surface so FABs are opaque

  const fabStyle = [style, {
    backgroundColor: brandColors.surface
  }];
  return <_StyledView style={fabStyle} $_css={[isNarrowScreen ? localStyles.fabBgSmall : localStyles.fabBgLarge, buttonStyle]}>
      <_StyledPrPressable onPress={onPress} style={rnStyles.menuShadow} testID={testID} {...otherProps} $_css2={[isNarrowScreen ? localStyles.fabSmall : localStyles.fabLarge, buttonStyle]}>
        <_StyledView2 $_css3={[styles.center, styles.row, isNarrowScreen ? styles.paddingHalf : styles.paddingSingle]}>
          {icon && <_StyledPrIcon fill={localColor} icon={icon} size={isNarrowScreen ? iconSizes.xsmall : iconSizes.small} $_css4={label ? localStyles.iconSeparator : undefined} />}
          {label && <_StyledPrText children={label} $_css5={isNarrowScreen ? localStyles.labelSmall : localStyles.labelLarge} />}
        </_StyledView2>
      </_StyledPrPressable>
    </_StyledView>;
};

export default CpFabButton;

var _StyledView = _styled(View).withConfig({
  displayName: "CpFabButton___StyledView",
  componentId: "sc-1uhfyc9-0"
})(["", ""], p => p.$_css);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpFabButton___StyledPrPressable",
  componentId: "sc-1uhfyc9-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpFabButton___StyledView2",
  componentId: "sc-1uhfyc9-2"
})(["", ""], p => p.$_css3);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpFabButton___StyledPrIcon",
  componentId: "sc-1uhfyc9-3"
})(["", ""], p => p.$_css4);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpFabButton___StyledPrText",
  componentId: "sc-1uhfyc9-4"
})(["", ""], p => p.$_css5);