import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Star: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M45.8045754,60.8548724 C45.6889794,60.8082532 45.5783024,60.754273 45.4627064,60.6880245 L31.9945425,53.0228356 L18.5263787,60.6904782 C18.4107827,60.7567266 18.2976461,60.8107068 18.1795907,60.864687 C16.5784631,61.6105953 14.6207095,61.4339328 13.1917462,60.3960407 C11.6545653,59.2845392 10.8921236,57.3437056 11.270885,55.4593057 L14.4091934,40.273693 C13.1819082,39.1621916 11.2536685,37.4102886 9.1557241,35.5062596 L2.94059443,29.8628747 C2.85205281,29.7819044 2.76105171,29.6886658 2.68480753,29.5954273 C1.45506284,28.2999024 1.01481424,26.4228635 1.57065884,24.7151262 C2.13880089,23.0000279 3.60465656,21.7314931 5.37548892,21.4149728 C5.48616594,21.3879827 5.59438348,21.3683535 5.70997948,21.3560853 L21.1260589,19.6508016 L27.5084339,5.54970026 C27.5650021,5.42456434 27.6264894,5.3043357 27.705193,5.18901436 C28.5635548,3.64076398 30.2114127,2.66666667 31.997002,2.66666667 C33.7776723,2.66666667 35.4206112,3.64076398 36.288811,5.18656071 C36.3576767,5.3043357 36.4240829,5.42456434 36.4855701,5.55215391 L42.8728641,19.6581626 L58.2889435,21.3634463 C58.3996205,21.3732608 58.5127571,21.39289 58.6308125,21.4198801 C60.3991854,21.746215 61.8650411,23.0098425 62.4282641,24.729848 C62.9865682,26.4351317 62.5438602,28.3219852 61.3141155,29.6175101 C61.2354118,29.7033877 61.1468702,29.7941726 61.0583286,29.8726893 L49.5872701,40.2884149 L52.7255785,55.4421302 C53.0969614,57.3633347 52.3394387,59.2968075 50.7899604,60.4107626 C49.963572,61.0069985 48.9674788,61.3309068 47.9246553,61.3309068 C47.1868084,61.3333333 46.4686375,61.1689391 45.8045754,60.8548724 Z" />
    </Svg>
  )
}

export default Star
