import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconBlood: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M169 52.65h1.4c16.1 21.3 31.4 43.1 45.3 65.9 15.4 25.5 29.4 51.6 38.3 80.2 5 16.1 8 32.5 5.7 49.6a90 90 0 01-69.3 75.9c-5.2 1.2-10.6 1.8-15.9 2.7h-8.8c-5.6-.9-11.4-1.5-16.9-2.8-45.6-10.8-76.4-55.1-68-104.2 3.2-18.7 9.8-36.2 17.6-53.3 16.5-36 37.9-69 61.3-100.8 3.18-4.3 6.18-8.8 9.3-13.2zm-40.5 110.5c-.2-.1-.3-.1-.5-.2a7 7 0 00-.8 1c-7.8 15.8-15.1 31.7-19.6 48.8-5.5 21.1-3.1 40.9 8.3 59.5a27.64 27.64 0 0017.5 12.9c6.9 1.7 13.3-.3 16.9-5.4s3.2-9.9-1.4-16.5c-2.1-3.1-4.3-6.1-6.3-9.2q-22.5-33.6-16.9-73.7c.78-5.7 1.78-11.35 2.78-17.2zM250.88 33.05h.4A246.89 246.89 0 01266 54.55c5 8.3 9.6 16.8 12.5 26.1 1.6 5.3 2.6 10.6 1.9 16.1a29.28 29.28 0 01-22.6 24.7c-1.7.4-3.4.6-5.2.9h-2.9c-1.8-.3-3.7-.5-5.5-.9a29.43 29.43 0 01-22.1-33.9 68.89 68.89 0 015.7-17.3c5.4-11.7 12.3-22.4 19.9-32.8 1.18-1.45 2.18-3 3.18-4.4zM237.68 69c-.1 0-.1 0-.2-.1-.1.1-.2.2-.2.3a88.08 88.08 0 00-6.4 15.9 24.11 24.11 0 002.7 19.4 8.73 8.73 0 005.7 4.2 4.88 4.88 0 005.5-1.8c1.2-1.6 1-3.2-.5-5.4l-2.1-3a33.46 33.46 0 01-5.5-24c.4-1.75.7-3.65 1-5.5z" />
    </Svg>
  )
}

export default IconBlood
