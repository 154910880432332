import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpSelectSubOrganizationSearchFragment;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import { graphql, useFragment } from "react-relay";
import CpDebouncedTextInput from "components/common/CpDebouncedTextInput";
import CpOrganizationItem from "components/organization/CpOrganizationItem";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import QlSelectSubOrganization from "query/QlSelectSubOrganization";
import { css, styles } from "services/Theme";
import labels from "translations/labels";
const CpSelectSubOrganizationSearchFragmentItem = _CpSelectSubOrganizationSearchFragment !== void 0 ? _CpSelectSubOrganizationSearchFragment : _CpSelectSubOrganizationSearchFragment = require("./__generated__/CpSelectSubOrganizationSearchFragment.graphql");
const localStyles = {
  container: css`
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  header: css`
    ${styles.textHeader1};
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  noResults: css`
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    color: ${({
    theme
  }) => theme.textColors.error};
  `,
  searchBox: css`
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    margin-bottom: -1px;
  `,
  searchBoxText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

const SubOrgSearchResults = ({
  onSelect,
  onShowSearchBox,
  organizationSelected,
  searchText,
  selectSubOrgKey,
  showSearchBox
}) => {
  const subOrgData = useFragment(CpSelectSubOrganizationSearchFragmentItem, selectSubOrgKey);
  const subOrgs = useMemo(() => subOrgData?.edges ?? [], [subOrgData?.edges]);
  useEffect(() => onShowSearchBox(Boolean(subOrgs.length > 0 || searchText)), [subOrgs.length, onShowSearchBox, searchText]);

  if (subOrgs.length > 0) {
    // Result Display
    return <>
        {subOrgs.map((edge, index) => {
        return edge?.node ? <CpOrganizationItem isFirst={!showSearchBox && index === 0} isLast={index === subOrgs.length - 1} key={edge.cursor} onSelect={onSelect} organization={edge.node} /> : null;
      })}
      </>;
  } else if (subOrgs.length === 0 && !organizationSelected) {
    // No results after searching
    return searchText ? <_StyledPrText $_css={localStyles.noResults}>
        <FormattedMessage id="A0sDz4" defaultMessage="Your search for {searchText} returned no results. Modify your search and try again." values={{
        searchText: `"${searchText}"`
      }} />
      </_StyledPrText> : // No subOrg results
    <PrText>
        <FormattedMessage id="xFRd9i" defaultMessage="There are no sub-clinics available to choose from for your selected membership. If you would like to expand this network please contact support@imdhealth.com" />
      </PrText>;
  } else {
    return null;
  }
};

/**
 * This component renders a searchable list of organizations associated with a users membership to select from
 */
const CpSelectSubOrganization = ({
  currentOrganizationId,
  onSelect,
  organizationSelected
}) => {
  const {
    textColors
  } = useAppTheme();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [organizationFilters, setOrganizationFilters] = useState();
  const handleSearchTextUpdated = useCallback(value => {
    setOrganizationFilters(value ? {
      text: value
    } : undefined);
  }, []); // Local callbacks for searchbox toggle and select

  const handleShowSearchBox = useCallback(shouldShowSearch => setShowSearchBox(shouldShowSearch), []);
  useEffect(() => {
    if (currentOrganizationId) {
      setOrganizationFilters(undefined);
    }
  }, [currentOrganizationId]);
  const commonQueryVariables = useMemo(() => ({
    id: currentOrganizationId
  }), [currentOrganizationId]);
  return <_StyledView $_css2={localStyles.container}>
      {showSearchBox && // Only show searchbox if we have a lot of results to filter through
    <_StyledView2 $_css3={localStyles.searchBox}>
          <_StyledCpDebouncedTextInput onChangeText={handleSearchTextUpdated} placeholder={labels.searchClinicsPlaceholder} placeholderTextColor={textColors.muted} testID="CpSelectSubOrganization-textInput" $_css4={localStyles.searchBoxText} />
        </_StyledView2>}

      <QlSelectSubOrganization commonVariables={commonQueryVariables} organizationFilters={organizationFilters}>
        {suborgResponse => suborgResponse ? <SubOrgSearchResults onSelect={onSelect} onShowSearchBox={handleShowSearchBox} organizationSelected={organizationSelected} searchText={organizationFilters?.text} selectSubOrgKey={suborgResponse} showSearchBox={showSearchBox} /> : <PrActivityIndicator />}
      </QlSelectSubOrganization>
    </_StyledView>;
};

export default CpSelectSubOrganization;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpSelectSubOrganization___StyledPrText",
  componentId: "sc-1w7lx18-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpSelectSubOrganization___StyledView",
  componentId: "sc-1w7lx18-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpSelectSubOrganization___StyledView2",
  componentId: "sc-1w7lx18-2"
})(["", ""], p => p.$_css3);

var _StyledCpDebouncedTextInput = _styled(CpDebouncedTextInput).withConfig({
  displayName: "CpSelectSubOrganization___StyledCpDebouncedTextInput",
  componentId: "sc-1w7lx18-3"
})(["", ""], p => p.$_css4);