import axios from "axios"

export interface S3UploadFields {
  contentType: string
  file: File
  key: string
  policy: string
  xAmzAlgorithm: string
  xAmzCredential: string
  xAmzDate: string
  xAmzMetaOriginalFilename: string
  xAmzSecurityToken: string
  xAmzSignature: string
}

interface UploadResponse {
  assetUrl?: string
  status: "error" | "success"
}

export const FAKE_TEST_URL = "http://localhost:3000/fake"

export const uploadToS3 = async (url: string, fields: S3UploadFields): Promise<UploadResponse> => {
  try {
    if (url !== FAKE_TEST_URL) {
      await axios.put(
        url,
        fields.file,
        // sortier-ignore-nodes - S3 wants the fields on that order or it complains, so we leave it in this order
        {
          headers: {
            "Content-Type": fields.contentType,
            "x-ms-blob-type": "BlockBlob",
            "x-ms-date": new Date().toUTCString(),
            "x-ms-version": "2021-10-04",
          },
        },
      )
    }
    return {
      assetUrl: fields.xAmzMetaOriginalFilename,
      status: "success",
    }
  } catch (err) {
    return {
      status: "error",
    }
  }
}

export const convertBase64ToFile = async (
  base64: string,
  filename: string,
  filetype: string,
): Promise<File> => {
  const response = await fetch(base64)
  const blob = await response.blob()

  return new File([blob], filename, { type: filetype })
}
