/**
 * @generated SignedSource<<58c2deff504ea540eeaedef1b4f97e5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpPersonalShortcuts_data$data = {
  readonly personalShortcuts: ReadonlyArray<{
    readonly _id: string;
    readonly icon: string | null;
    readonly id: string;
    readonly names: any;
    readonly resourceNode: {
      readonly revisionId: string;
      readonly type: string;
    };
    readonly rootResourceNodeRevisionId: string;
  }>;
  readonly " $fragmentType": "CpPersonalShortcuts_data";
};
export type CpPersonalShortcuts_data$key = {
  readonly " $data"?: CpPersonalShortcuts_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpPersonalShortcuts_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpPersonalShortcuts_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNodeShortcut",
      "kind": "LinkedField",
      "name": "personalShortcuts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "names",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceNode",
          "kind": "LinkedField",
          "name": "resourceNode",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revisionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rootResourceNodeRevisionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Membership",
  "abstractKey": null
};
})();

(node as any).hash = "cf5310d586d9b7f54e73787b8320cecb";

export default node;
