import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconMuscle: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M290.09 74.7c-3.86-4.59-10.85-6.55-10.85-6.55s-15 16.6-20 21.2-7 6-37.67 18.47-45 19.2-82.59 55.11C107 193.4 97.27 226 97.27 226s-23.22 39.39-26.72 44.6-8.16 7.36-8.2 8.58c-.05 2.12 10.35 15.72 13.16 12.08 3.8-4.92 32.51-22.09 64.59-37.45s55-34.13 78.15-59.36 47.22-69.58 53.16-80.26S295.74 89.28 297.35 88s-3.35-8.71-7.26-13.3zm-137.34 82.23c30-26.69 63.31-41.54 63.84-40.95s-32.22 16.42-62.2 43.12-47.67 57.2-48.19 56.61 16.57-32.09 46.55-58.78zm-48.49 79.34c-.54-.57 24.19-33.08 57.19-64.56s69.56-56.42 70.1-55.85-35.13 26.42-68.13 57.9-58.61 63.04-59.16 62.51zm6.35 9c.56-.5 30.34-28.94 63.35-60.42s68.42-64.39 69-63.83-34 34.4-67 65.88c-66.82 63.68-70.05 62.76-65.35 58.34zm8.6 7.15c-.14-.05-.1-.1 0 0zm0 0c1.27 0 33.93-23.94 66.56-55.06 33-31.48 61.2-66 61.75-65.45s-27.43 35.23-60.43 66.72c-56.61 54-66.7 54.17-67.84 53.77zm76.39-43.89c-30.88 29.47-61.77 46.26-62.3 45.68s31.83-20.64 62.71-50.1 54.38-62.76 54.93-62.19-24.42 37.13-55.3 66.59z" />
    </Svg>
  )
}

export default IconMuscle
