/**
 * @generated SignedSource<<7e69d4deb98465246a8a55f2829ba1eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PatientPackageToSessionCreateInput = {
  commonTopicsInput?: CommonTopicsInput | null;
  emails: ReadonlyArray<string>;
  note?: string | null;
  patientPackageId?: string | null;
  topicNodesInput?: ReadonlyArray<TopicNodesInput> | null;
};
export type CommonTopicsInput = {
  ids: ReadonlyArray<CommonTopicsInputIds>;
  organizationId: string;
};
export type CommonTopicsInputIds = {
  commonTopicId: string;
  resourceNodeRevisionId: string;
};
export type TopicNodesInput = {
  resourceNodeRevisionIds: ReadonlyArray<string>;
  topicTreeId: string;
};
export type CpPackageSessionSendMutation$variables = {
  input: PatientPackageToSessionCreateInput;
};
export type CpPackageSessionSendMutation$data = {
  readonly patientPackageToSessionCreate: {
    readonly patientSessions: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
};
export type CpPackageSessionSendMutation = {
  response: CpPackageSessionSendMutation$data;
  variables: CpPackageSessionSendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PatientPackageToSessionCreatePayload",
    "kind": "LinkedField",
    "name": "patientPackageToSessionCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PatientSession",
        "kind": "LinkedField",
        "name": "patientSessions",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpPackageSessionSendMutation",
    "selections": (v1/*: any*/),
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpPackageSessionSendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ec8656c3b715166a46be8e9c98daaa2c",
    "id": null,
    "metadata": {},
    "name": "CpPackageSessionSendMutation",
    "operationKind": "mutation",
    "text": "mutation CpPackageSessionSendMutation(\n  $input: PatientPackageToSessionCreateInput!\n) {\n  patientPackageToSessionCreate(input: $input) {\n    patientSessions {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7fadc5d17d8db72b4b47560470c3c1c5";

export default node;
