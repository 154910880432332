/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Config from "services/Config"
import CrashReporter from "services/CrashReporting"

/**
 * The Log service extends console to add messages as Sentry breadcrumbs.
 * Log.error will report the message as a Sentry issue.
 *
 * Using console directly will cause a lint error, so use it for debugging, but be sure to remove those statements prior
 * to checking in your code.
 *
 * When using with chrome dev tools, blackbox these patterns to show the code that actually called the Logging service:
 *   /src/services/Log.ts
 *   @sentry/browser/esm/integrations/breadcrumbs.js
 */
export default {
  // These will be logged to sentry as a message or exception
  error(message: string, error?: Error, data: { [key: string]: unknown } = {}): void {
    try {
      if (error) {
        CrashReporter.reportError(error, data)
      } else {
        CrashReporter.reportMessage(message, data)
      }
    } catch (e) {
      console.debug("Sentry Error", e)
    }

    if (Config.IS_DEV_ENV) {
      console.error(message, data)
    }
  },

  // The rest will be added as breadcrumbs
  count: (label?: string): void => {
    if (Config.IS_DEV_ENV) {
      // console.count(`\x1b[32m# ${label}`)
      console.info(label)
    }
  },
  debug(message?: any, optionalParams?: Record<string, any>): void {
    try {
      CrashReporter.addBreadcrumb({
        data: optionalParams,
        level: "debug",
        message,
      })
    } catch (e) {
      console.debug("Sentry Error", e)
    }

    if (Config.IS_DEV_ENV) {
      optionalParams ? console.debug(message, optionalParams) : console.debug(message)
    }
  },
  info(message?: any, optionalParams?: Record<string, any>): void {
    try {
      CrashReporter.addBreadcrumb({
        data: optionalParams,
        level: "info",
        message,
      })
    } catch (e) {
      console.debug("Sentry Error", e)
    }

    if (Config.IS_DEV_ENV) {
      optionalParams ? console.info(message, optionalParams) : console.info(message)
    }
  },
  warn(message?: any, optionalParams?: Record<string, any>): void {
    try {
      CrashReporter.addBreadcrumb({
        data: optionalParams,
        level: "warning",
        message,
      })
    } catch (e) {
      console.debug("Sentry Error", e)
    }

    if (Config.IS_DEV_ENV) {
      optionalParams ? console.warn(message, optionalParams) : console.warn(message)
    }
  },
}
