import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconNews: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M105 221V120H94.5a15.4 15.4 0 011.5 6.5V221zm60.6-96.2a8.24 8.24 0 01.4 3.5v22.9a.79.79 0 01-.8.8H159a1 1 0 01-.9-.9V129c0-1 0-1-1-1l-9.3-.1a.74.74 0 00-.8.8v22.4c0 .6-.3.8-.8.8H140c-.6 0-.8-.3-.8-.8v-30.3a.79.79 0 01.8-.8l20.9.1a3.09 3.09 0 00.8.2l3.9 4.5zm14.7 2.8a.22.22 0 00-.2.2v4.9c0 .4 3.6.2 4 .2h8.9a.79.79 0 01.8.8v4.5c0 .6-.3.8-.8.8h-12.7a.22.22 0 00-.2.2v4.8a.22.22 0 00.2.2h15a.79.79 0 01.8.8c0 1.3.2 5.7-.2 6.2s-4.1.2-5.4.2l-13-.1c-.3-.1-.6-.1-.8-.3l-4.1-4.1a7.28 7.28 0 01-.4-2.9l.1-18.6a6.89 6.89 0 01.3-.8l4.1-4.1a5.91 5.91 0 012.6-.4h16a.79.79 0 01.8.8c0 1.3.2 5.8-.2 6.2s-3 .2-4 .2h-11.6zm55-7.3a.79.79 0 01.8.8l-.1 25.2a3.09 3.09 0 00-.2.8l-4.1 4.1c-.6.5-2.8.4-3.5.4l-19.8-.1c-.3-.1-.6-.1-.8-.3l-4.1-4.1a5.69 5.69 0 01-.4-2.6v-23.4a.79.79 0 01.8-.8h6.2a.79.79 0 01.8.8V144h5v-22.9a.79.79 0 01.8-.8c1.3 0 5.8-.2 6.2.2a.73.73 0 01.2.6V144h5v-22.9a.74.74 0 01.8-.8zm31.4 16.9c.6 1.7.4 6.4.4 8.4l-.4 1.6-4.1 4.5a5.69 5.69 0 01-2.6.4h-16c-.6 0-.9-.3-.8-.8V145a.79.79 0 01.8-.8h15.2c1.3 0 .8-1.8.8-4.1a.79.79 0 00-.8-.8l-10.8-.1c-.3-.1-.6-.1-.8-.2l-4.1-4.1c-.6-.9-.4-7.2-.4-8.4l.4-1.6 4.1-4.1a4.88 4.88 0 012.4-.4h15.2c.5 0 .9.4.8.8 0 1.4.2 6.1-.3 6.6s-2.6.2-3.7.2h-11.1c-.5 0-.9.3-.8.8 0 4.9-.6 4.2 3.7 4.2l8 .1c.3.1.6-.1.8 0l4.1 4.1zM117 253c0 6.1-1 14.2-5 17h160c9.3 0 16-6.6 16-16V90H117v163zm-57 .5V126a16.79 16.79 0 0110.8-16.3 20.13 20.13 0 017-1.8H105l-.1-23.4a6.49 6.49 0 016.5-6.5h181.9a6.68 6.68 0 016.7 6.6v169c0 16.5-12.2 28.5-28.7 28.5H88.7a30.53 30.53 0 01-11.5-2.2c-9.6-4-17.2-12.8-17.2-26.4zM72 125v128.5c0 9.4 7.6 15.4 16.7 15.4 8.9 0 16.3-6.1 16.3-15.5v-20.5l-14.5.1a6.49 6.49 0 01-6.5-6.5V125c0-6.1-12-7-12 0z" />
      <Path
        d="M235 171h16v16h16v16h-16v16h-16v-16h-16v-16h16v-16zm-96 58h128v8H139v-8zm0-54h66v8h-66v-8zm0 18h66v8h-66v-8zm0 18h66v8h-66v-8z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconNews
