import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconKidney: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M137.5 120.42c-15 7.2-25.1 1.1-33.6-10.3-9.9-13.2-11.6-27.7-6.2-42.8 6.9-19.2 22-29.1 40.9-33.7 43.2-10.7 105 6 133.7 60.8 23 44.1 26.7 90.5 13.2 138.3-5.7 20-13.2 39.2-25.8 56.1-23.4 31.4-59.4 44.9-92.9 34.4-24.2-7.7-40.1-24-46.4-48.7a53.23 53.23 0 01-.8-19.9c2.8-19 13.3-25.2 32.5-20.4-4.4-4.4-8.2-9.6-13.2-13.1-8.3-5.7-17.3-4-24.1 3.5-6.6 7.3-9.5 16.1-10.2 26-1.4 20.1 3.1 38.9 12 56.8 3.2 6.4 6.3 12.8 10.7 21.7-9.1 0-15.8.9-22.1-.3-3.7-.7-7.8-4.3-9.8-7.6a201.88 201.88 0 01-26.8-73.7c-3.3-20.7-2.5-41.1 3.6-61.3 6.5-21.5 19.5-37.5 39.6-47.7 8.9-4.5 17.6-9.4 26.4-14.1-.2-1.42-.5-2.7-.7-4zm6 40.1c11.3 8.3 21.8 8.5 33 2.4-2.1-8.4-3.9-15.8-5.8-23.2-17.4 3.28-19.7 5-27.2 20.8zm-2.7 36.1c10 10.9 20.6 15.6 34.3 15.1 1.4-8.2 2.6-15 4.1-23.1-13.7-1.7-25.9-4-38.4 8z" />
    </Svg>
  )
}

export default IconKidney
