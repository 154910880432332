import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - search
const Search: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M12.688 18.688q2.5 0 4.25-1.75t1.75-4.25-1.75-4.25-4.25-1.75-4.25 1.75-1.75 4.25 1.75 4.25 4.25 1.75zM20.688 18.688l6.625 6.625-2 2-6.625-6.625v-1.063l-0.375-0.375q-2.375 2.063-5.625 2.063-3.625 0-6.156-2.5t-2.531-6.125 2.531-6.156 6.156-2.531 6.125 2.531 2.5 6.156q0 1.313-0.625 2.969t-1.438 2.656l0.375 0.375h1.063z" />
    </Svg>
  )
}

export default Search
