import { shouldPolyfill as shouldPolyfillDateTimeFormat } from "@formatjs/intl-datetimeformat/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillNumberFormat } from "@formatjs/intl-numberformat/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from "@formatjs/intl-relativetimeformat/should-polyfill";
import React, { createContext, memo, useEffect, useState } from "react";
import { getLocales } from "react-native-localize";
import CpAppLoading from "components/app/CpAppLoading";
import useAppRegion from "hooks/useAppRegion";
import QlCountryRegions from "query/QlCountryRegions";
import { backendSupportedLocales } from "services/Intl";
import Log from "services/Log";
export const initialData = {
  countryRegions: undefined,
  locale: undefined
};
export const I18nContext = createContext({ ...initialData,
  setI18nContext: () => undefined // The context provider will set the real fn

});
export const intlFormats = {
  date: {
    timestamp: {
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      month: "short",
      year: "numeric"
    }
  }
};
export const loadMessages = async locale => {
  const language = locale.split("-")[0];

  switch (language) {
    case "es":
      return import("i18n/es.json");

    case "fr":
      return import("i18n/fr.json");

    default:
      return import("i18n/en.json");
  }
};
/*
 * This container component wraps the app in a react-intl provider.
 * It also provides the intl polyfill, if required.
 * It will throw a Promise to Suspend rendering until the language resources are loaded.
 */

const CxI18n = ({
  children,
  i18nContext,
  setI18nContext
}) => {
  const [messages, setMessages] = useState(); // Configure i18n when locale changes

  useEffect(() => {
    const updateIntl = async () => {
      if (!i18nContext.locale) return;
      Log.info("CxI18n. Locale changed", {
        locale: i18nContext.locale
      });
      const language = i18nContext.locale.split("-")[0]; // Load any polyfills with locale resources
      // TODO: react-intl is dumb, they have separate resources for every locale, but who is going to dynamically import every one?
      // so for now, we're just dynamically importing the base language resource

      if (shouldPolyfillDateTimeFormat()) {
        Log.info("CxI18n. Polyfilling Intl.DateTimeFormat");
        await import("@formatjs/intl-datetimeformat/polyfill");
        const dataPolyfills = [import("@formatjs/intl-datetimeformat/add-all-tz")];

        switch (language) {
          case "es":
            dataPolyfills.push(import("@formatjs/intl-datetimeformat/locale-data/es"));
            break;

          case "fr":
            dataPolyfills.push(import("@formatjs/intl-datetimeformat/locale-data/fr"));
            break;

          default:
            dataPolyfills.push(import("@formatjs/intl-datetimeformat/locale-data/en"));
            break;
        }

        await Promise.all(dataPolyfills);
      }

      if (shouldPolyfillNumberFormat()) {
        Log.info("CxI18n. Polyfilling Intl.NumberFormat");
        await import("@formatjs/intl-numberformat/polyfill");

        switch (language) {
          case "es":
            await import("@formatjs/intl-numberformat/locale-data/es");
            break;

          case "fr":
            await import("@formatjs/intl-numberformat/locale-data/fr");
            break;

          default:
            await import("@formatjs/intl-numberformat/locale-data/en");
            break;
        }
      }

      if (shouldPolyfillPluralRules()) {
        Log.info("CxI18n. Polyfilling Intl.PluralRules");
        await import("@formatjs/intl-pluralrules/polyfill");

        switch (language) {
          case "es":
            await import("@formatjs/intl-pluralrules/locale-data/es");
            break;

          case "fr":
            await import("@formatjs/intl-pluralrules/locale-data/fr");
            break;

          default:
            await import("@formatjs/intl-pluralrules/locale-data/en");
            break;
        }
      }

      if (shouldPolyfillRelativeTimeFormat()) {
        Log.info("CxI18n. Polyfilling Intl.RelativeTimeFormat");
        await import("@formatjs/intl-relativetimeformat/polyfill");

        switch (language) {
          case "es":
            await import("@formatjs/intl-relativetimeformat/locale-data/es");
            break;

          case "fr":
            await import("@formatjs/intl-relativetimeformat/locale-data/fr");
            break;

          default:
            await import("@formatjs/intl-relativetimeformat/locale-data/en");
            break;
        }
      }

      const localeMessages = await loadMessages(i18nContext.locale);
      setMessages(localeMessages.default);
    };

    updateIntl();
  }, [i18nContext.locale]); // One time setup

  useEffect(() => {
    const initIntl = async () => {
      // Load any polyfills without locale resources
      if (shouldPolyfillLocale()) {
        // The other polyfills depend on this, so it needs to be done first
        Log.info("CxI18n. Polyfilling Intl.Locale");
        await import("@formatjs/intl-locale/polyfill");
      } // Find the first user preferred locale with a language supported by the backend


      const userPreferredLocales = getLocales();
      const userLocale = userPreferredLocales.find(candidateLocale => backendSupportedLocales.includes(candidateLocale.languageCode));
      Log.info("CxI18n. Locale selected based on device settings.", {
        preferredLocale: userPreferredLocales,
        userLocale: userLocale?.languageTag
      });
      setI18nContext({
        locale: userLocale?.languageCode
      });
    };

    if (!i18nContext.locale) {
      // Hopefully this only happens once
      initIntl();
    }
  }, [i18nContext.locale, setI18nContext]);
  const {
    currentRegionSlug
  } = useAppRegion();
  return i18nContext.locale && messages ? <QlCountryRegions variables={{
    locale: i18nContext.locale,
    region: currentRegionSlug.toLocaleUpperCase()
  }}>
      {response => {
      if (response.countryRegions && i18nContext.locale) {
        return <I18nContext.Provider value={{ ...i18nContext,
          countryRegions: response,
          setI18nContext
        }}>
              {children}
            </I18nContext.Provider>;
      }

      return <CpAppLoading />;
    }}
    </QlCountryRegions> : <CpAppLoading />;
};

export default memo(CxI18n);