import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconGenetics: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M226.13 295.3c-1.8-15.4-11-25.3-22.6-32.8-9.8-6.4-20.5-11.7-31.1-16.8-16.4-7.9-32-16.9-43.8-31.1-18.5-22.4-17.6-50.4 2.1-71.8 1.4-1.5 2.9-3 4.4-4.4 14.7-14.3 14.7-14.3 33.3-3.3-3.4 2.5-6.7 4.8-11.5 8.3h44c.3-.6.5-1.2.8-1.7-7.4-3.9-15.1-7.3-22.2-11.6-15.4-9.3-31.5-17.7-45.4-28.9-17.5-14-20-34.8-18.1-55.9.2-2.7 4.5-6.8 7.2-7s6.3 3 8.7 5.5c1.4 1.4 1.1 4.4 1.7 7.3h92.2c.3-.5.9-.9 1-1.4 1-6 1.8-12.6 10.1-11.7s7.4 7.6 7.6 13.7c1 23.6-7.5 42.7-26.4 57.2-6.1 4.7-11.7 7.7-18.7 1.9-2.2-1.8-5.2-2.8-8.5-4.4 14.7-10.1 30.5-18.8 34.9-38.8h-91.2c.3 9.4 4.2 13.1 13.7 12.7 17.2-.7 34.4-.2 51.6-.1 2.8 0 5.6.3 10 .5-8.7 8.6-15.9 16-28.2 14.7-8.4-.9-16.9-.2-25.3-.2-.3 1-.6 1.9-.9 2.9 10.3 5.4 20.8 10.3 30.8 16.2 12.5 7.3 25.8 13.7 36.5 23.1 28.9 25.3 28.9 58.7 1.2 84.9-14.2 13.5-14.2 13.5-32.6 2.7 3.4-2.5 6.7-4.8 11.9-8.6-18.8-1.3-38 4.3-50.1-15.6h33.3c8.3 0 16.7-.6 24.9.1 9.5.9 12.4-4.3 14.2-12.7h-91.3c2.8 11.5 10 19.4 18.6 25.6 8.9 6.3 18.9 10.9 28.2 16.6 15.1 9.2 31 17.5 44.6 28.5 17.5 14.1 20 34.8 18.3 55.9-.2 2.7-4.3 6.6-7.2 7.2-2.4.6-5.8-2.6-8.6-4.5-2.3-1.6-4.2-5.3-6.3-5.4-29.3-.4-58.6-.2-88.1-.2-.8 2.1-.9 4.4-2 5.2-2.8 2.1-6.5 5.3-9.2 4.8s-6.3-4.8-6.6-7.7c-3-28 6.7-50.6 30.8-66 2.3-1.5 6.5-1.3 9.4-.5 3.9 1.1 7.4 3.5 11.1 5.4a9.52 9.52 0 011.5 1.2c-5.8 3.9-11.4 7.7-17 11.4.1.7.2 1.5.3 2.2 11.3 0 22.6.6 33.8-.2 12-.9 19.1 5.7 27 15.7h-27.7c-13 0-26.1.6-39.1-.2-9.2-.6-12.3 3.4-12.1 12.3 30.5-.2 60.97-.2 92.1-.2zm-91.8-123.5h90.5c-2.3-11.5-3.4-12.6-13.3-12.6-20.7-.1-41.4.4-62-.2-9.3-.4-13.7 3.2-15.2 12.8z" />
    </Svg>
  )
}

export default IconGenetics
