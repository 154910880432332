import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { View } from "react-native";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { useIntl } from "react-intl";
import { css, styles } from "services/Theme";
import translations from "translations";
export const notificationStyles = {
  actionButtonBase: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    justify-content: center;
    min-height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  actionText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: bold;
  `,
  containerBase: css`
    ${styles.rowAndCenter};
    flex: 1;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  label: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: bold;
  `,
  sidebarBase: css`
    width: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  text: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `
};
const typeStyles = {
  error: {
    actionButton: css`
      ${notificationStyles.actionButtonBase};
      border: 2px solid ${({
      theme
    }) => theme.brandColors.snackbarAccentError};
    `,
    container: css`
      ${notificationStyles.containerBase};
      background-color: ${({
      theme
    }) => theme.brandColors.snackbarBackgroundError};
    `,
    sidebar: css`
      ${notificationStyles.sidebarBase};
      background-color: ${({
      theme
    }) => theme.brandColors.snackbarAccentError};
    `
  },
  primary: {
    actionButton: css`
      ${notificationStyles.actionButtonBase};
      border: 2px solid ${({
      theme
    }) => theme.brandColors.snackbarAccentPrimary};
    `,
    container: css`
      ${notificationStyles.containerBase};
      background-color: ${({
      theme
    }) => theme.brandColors.snackbarBackgroundPrimary};
    `,
    sidebar: css`
      ${notificationStyles.sidebarBase};
      background-color: ${({
      theme
    }) => theme.brandColors.snackbarAccentPrimary};
    `
  },
  warning: {
    actionButton: css`
      ${notificationStyles.actionButtonBase};
      border: 2px solid ${({
      theme
    }) => theme.brandColors.snackbarAccentWarning};
    `,
    container: css`
      ${notificationStyles.containerBase};
      background-color: ${({
      theme
    }) => theme.brandColors.snackbarBackgroundWarning};
    `,
    sidebar: css`
      ${notificationStyles.sidebarBase};
      background-color: ${({
      theme
    }) => theme.brandColors.snackbarAccentWarning};
    `
  }
};

/**
 * Renders a notification to be consumed by `useSnackbar`'s `addSnackbar()`. Accepts an additional `content` prop that can render custom content beneath the label & text.
 * @param alert When true, informs screen readers of the notification and reads it immediately when displayed
 */
const CpNotification = ({
  action,
  alert = false,
  content,
  label,
  style,
  testID,
  text,
  type = "primary"
}) => {
  // Destructure props so we can get the rest 'actionProps'
  const {
    label: actionLabel,
    onPress,
    ...actionProps
  } = action || {};
  const {
    formatMessage
  } = useIntl();
  const notificationTypeStyles = useMemo(() => {
    switch (type) {
      case "error":
        return typeStyles.error;

      case "warning":
        return typeStyles.warning;

      case "primary":
      default:
        return typeStyles.primary;
    }
  }, [type]);
  return <React.Fragment>
      <_StyledView $_css={notificationTypeStyles.sidebar} />
      <_StyledView2 accessibilityHint={formatMessage(translations.accessibility.hint.notificationDismiss)} // Notifications can be dismissed on press, as the base component is a technically a button
    accessibilityRole="button" style={style} testID={testID} {...alert ? {
      accessibilityRole: "alert"
    } : undefined} $_css2={notificationTypeStyles.container}>
        <_StyledView3 $_css3={styles.flexSingle}>
          {label && <_StyledPrText children={label} $_css4={notificationStyles.label} />}
          {text && <_StyledPrText2 children={text} $_css5={notificationStyles.text} />}
          {content ? content : null}
        </_StyledView3>
        {action && <_StyledPrPressable onPress={onPress} {...actionProps} $_css6={notificationTypeStyles.actionButton}>
            <_StyledPrText3 children={actionLabel} $_css7={notificationStyles.actionText} />
          </_StyledPrPressable>}
      </_StyledView2>
    </React.Fragment>;
};

export default CpNotification;

var _StyledView = _styled(View).withConfig({
  displayName: "CpNotification___StyledView",
  componentId: "sc-15rv0c4-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpNotification___StyledView2",
  componentId: "sc-15rv0c4-1"
})(["", ""], p => p.$_css2);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpNotification___StyledView3",
  componentId: "sc-15rv0c4-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpNotification___StyledPrText",
  componentId: "sc-15rv0c4-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpNotification___StyledPrText2",
  componentId: "sc-15rv0c4-4"
})(["", ""], p => p.$_css5);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpNotification___StyledPrPressable",
  componentId: "sc-15rv0c4-5"
})(["", ""], p => p.$_css6);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpNotification___StyledPrText3",
  componentId: "sc-15rv0c4-6"
})(["", ""], p => p.$_css7);