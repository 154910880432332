import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpMediaSectionNodeQuery;

import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Linking, View } from "react-native";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpThumbnailItem, { thumbnailButtonSizes } from "components/common/CpThumbnailItem";
import CtApiSuspense from "components/containers/CtApiSuspense";
import { IconName } from "components/primitives/PrIcon";
import useScreenLayout from "hooks/useScreenLayout";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import { css, styles } from "services/Theme";
const mediaResourceNodeQuery = _CpMediaSectionNodeQuery !== void 0 ? _CpMediaSectionNodeQuery : _CpMediaSectionNodeQuery = require("./__generated__/CpMediaSectionNodeQuery.graphql");
const localStyles = {
  container: css`
    align-items: center;
  `,
  marginTop: css`
    margin-top: ${({
    theme
  }) => 3 * theme.spacing.single}px;
  `,
  wrap: css`
    flex-direction: row;
    flex-wrap: wrap;
  `
};

/**
 * Renders each individual child media node as a list of thumbnail tiles
 */
const MediaResourceNode = ({
  queryReference,
  style
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const navigation = useNavigation();
  const {
    rootRevisionId
  } = useTopicContext();
  const data = usePreloadedQuery(mediaResourceNodeQuery, queryReference).node;
  /* On larger screen we want to determine the available screen space in order to set optimal width of display area for content. This ensures that the buttons don't stick to the left side of the screen whenever it wraps (e.g. has more than a single row of items)
    Moot on smaller screens as we just want to ensure buttons stretch to 100% of screen width
  */

  const [nodeViewWidth, setNodeViewWidth] = useState();
  const handleLayout = useCallback(({
    nativeEvent: {
      layout: {
        width
      }
    }
  }) => {
    if (isNarrowScreen) {
      setNodeViewWidth("100%");
    } else {
      // large buttons have 16px margin to account for
      const buttonWidth = thumbnailButtonSizes.largeButton.width + 16;
      const buttonsPerRow = Math.floor(width / buttonWidth);
      setNodeViewWidth(`${buttonWidth * buttonsPerRow}px`);
    }
  }, [isNarrowScreen]);
  const handleMediaPress = useCallback(selectedRevisionId => {
    navigation.navigate(Routes.topic, {
      rootRevisionId,
      selectedRevisionId
    });
  }, [navigation, rootRevisionId]);
  const handleAuxMediaPress = useCallback(selectedRevisionId => {
    Linking.openURL(`${location.origin}/${Routes.topic}/${rootRevisionId}/${selectedRevisionId}`);
  }, [rootRevisionId]);
  const renderChildren = useMemo(() => {
    return data?.children?.map(child => {
      if (!child?.revisionId) return null;
      const name = getNameVariant(child) ?? "";
      const revisionId = child?.revisionId; // Temporary until we have thumbnails for videos
      // TODO: Scrap the icon in favour of video thumbnails

      const icon = child?.resourceType === "Resource::Video" ? IconName.video : undefined;
      return <CpThumbnailItem icon={icon} key={revisionId} name={name} onAuxCallback={handleAuxMediaPress} onPressCallback={handleMediaPress} onPressValue={revisionId} size={isNarrowScreen ? "Small" : "Large"} uri={child?.thumbnail} />;
    });
  }, [data?.children, handleAuxMediaPress, handleMediaPress, isNarrowScreen]);
  if (!data) return null;
  return <_StyledView onLayout={handleLayout} style={style} $_css={[localStyles.container, isNarrowScreen && localStyles.marginTop]}>
      <_StyledView2 $_css2={nodeViewWidth} $_css3={!isNarrowScreen && localStyles.wrap}>
        {renderChildren}
      </_StyledView2>
      <CpUpdatePageTitle title={getNameVariant(data)} />
    </_StyledView>;
};

/**
 * Renders a media (e.g. diagram/video) node by fetching its children and rendering them as tiles
 */
const CpMediaSectionNode = ({
  resourceNodeId,
  style
}) => {
  const locale = useBackendSupportedLocale();
  const [mediaNodeQueryReference, loadMediaNodeQuery, disposeMediaNodeQuery] = useQueryLoader(mediaResourceNodeQuery);
  useEffect(() => {
    loadMediaNodeQuery({
      id: resourceNodeId,
      locale
    });
    return disposeMediaNodeQuery;
  }, [disposeMediaNodeQuery, loadMediaNodeQuery, locale, resourceNodeId]);
  return <_StyledView3 style={style} $_css4={styles.paddingDouble}>
      <CtApiSuspense>
        {mediaNodeQueryReference && <MediaResourceNode queryReference={mediaNodeQueryReference} />}
      </CtApiSuspense>
    </_StyledView3>;
};

export default CpMediaSectionNode;

var _StyledView = _styled(View).withConfig({
  displayName: "CpMediaSectionNode___StyledView",
  componentId: "sc-ln0ueh-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpMediaSectionNode___StyledView2",
  componentId: "sc-ln0ueh-1"
})(["width:", ";", ";"], p => p.$_css2, p => p.$_css3);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpMediaSectionNode___StyledView3",
  componentId: "sc-ln0ueh-2"
})(["", ""], p => p.$_css4);