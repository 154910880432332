import { useContext } from "react"

import { DrawingToolsContext, DrawingToolsType } from "contexts/CxDrawingTools"

/**
 * Hook for accessing Drawing Tools context
 */
function useDrawingToolsContext(): DrawingToolsType {
  const drawingToolsContext = useContext(DrawingToolsContext)

  if (!drawingToolsContext) throw new Error("Drawing Tools Context is required")

  return drawingToolsContext
}

export default useDrawingToolsContext
