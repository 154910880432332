import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Back: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path
        d="M37.8225164,7.27705559 L58.5794884,28.4285121 L37.818707,49.5799687 L37.8225164,37.241619 C22.5135732,36.7880222 12.9061691,38.0507006 8.72359152,54.2134402 C8.35536729,55.5324175 8.28314088,57.1909645 8.20228625,57.2737858 C8.12143161,57.3566071 6.99528797,55.8533427 6.41896716,54.2134402 C2.90591385,42.5816641 8.06901588,31.8244524 13.3344295,27.6728433 C22.7522106,20.247226 26.4492578,19.6154052 37.818707,19.6154052 L37.8225164,7.27705559 Z"
        transform="translate(31.923485, 32.277056) scale(-1, 1) translate(-31.923485, -32.277056)"
      />
    </Svg>
  )
}

export default Back
