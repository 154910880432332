import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpAlert, { AlertType } from "components/common/CpAlert";
import CpError from "components/common/CpError";
import CtTopicSubtreeNavigator from "components/containers/CtTopicSubtreeNavigator";
import { IconName } from "components/primitives/PrIcon";
import CpImageResourceNode from "components/topic/CpImageResourceNode";
import CpMediaSectionNode from "components/topic/CpMediaSectionNode";
import CpQuickResource from "components/topic/CpQuickResource";
import CpRelatedTopics from "components/topic/CpRelatedTopics";
import CpResource from "components/topic/CpResource";
import CpTaggedResourceNode from "components/topic/CpTaggedResourceNode";
import CpVideoResourceNode from "components/topic/CpVideoResourceNode";
import useAvailableHeight from "hooks/useAvailableHeight";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { getNameVariant } from "services/Graphql";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
import CpTopicDepthOneAncestorButton from "./backButton/CpTopicDepthOneAncestorButton";
const localStyles = {
  headerContainer: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const localTranslations = defineMessages({
  unavailableLanguage: {
    id: "mjRCzN",
    defaultMessage: "This resource is currently unavailable in your selected language. It will be displayed in its original language instead. Press to close."
  }
});

/**
 * Returns whether the current topic is a folder for related topics.
 */
function isRelatedTopicsFolder(resourceNodeChildren) {
  return resourceNodeChildren.every(child => child?.type === "ResourceNode::Relation");
}
/**
 * Renders a ResourceNode as content
 */


const CpResourceNodeContent = ({
  medicationsNodeRevisionId,
  resourceId,
  resourceNodeKey,
  selectedResourceNodeData,
  style
}) => {
  const availableViewportHeight = useAvailableHeight();
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    patientSessionData: {
      isLockedResource
    }
  } = usePatientSessionContext();
  const {
    formatMessage
  } = useIntl();
  const [languageWarningVisible, setLanguageWarningVisible] = React.useState(selectedResourceNodeData?.resource?.localeIsMissing);
  const handleCloseLanguageWarning = useCallback(() => {
    setLanguageWarningVisible(false);
  }, [setLanguageWarningVisible]);
  const selectedResourceNodeName = getNameVariant(selectedResourceNodeData) ?? "";
  const resource = selectedResourceNodeData.resource || selectedResourceNodeData.relatedNode?.resource || null;
  const resourceStyle = css`
    min-height: ${availableViewportHeight}px;
  `;
  const ancestorNodes = selectedResourceNodeData.ancestors ?? [];
  const showParentButton = ancestorNodes.length > 1 && !isLockedResource;
  const renderLanguageAlert = useMemo(() => {
    return <_StyledView $_css={styles.paddingHorizontalDouble}>
        <CpAlert icon={IconName.language} message={formatMessage(localTranslations.unavailableLanguage)} onClose={handleCloseLanguageWarning} onPress={handleCloseLanguageWarning} type={AlertType.Warning} visible={languageWarningVisible} />
      </_StyledView>;
  }, [formatMessage, handleCloseLanguageWarning, languageWarningVisible]);
  const parentButton = showParentButton ? <_StyledView2 style={style} $_css2={localStyles.headerContainer}>
      <CpTopicDepthOneAncestorButton resourceNodeKey={resourceNodeKey} />
    </_StyledView2> : null;

  if (resource) {
    // This is a leaf node with an associated resource.
    // Special Resource Handling
    if (resource.type === "Resource::Image") {
      // Image resource nodes sometimes render sibling nodes in a media carousel
      return <View style={style}>
          {parentButton}
          {resource.localeIsMissing ? renderLanguageAlert : null}
          <_StyledCpImageResourceNode resourceNodeId={selectedResourceNodeData.id} style={style} $_css3={[resourceStyle, styles.marginTopDouble]} />
        </View>;
    } else if (resource.type === "Resource::Video") {
      // Video resource nodes sometimes render sibling nodes in a media carousel
      return <>
          {parentButton}
          {resource.localeIsMissing ? renderLanguageAlert : null}
          <_StyledCpVideoResourceNode resourceNodeId={selectedResourceNodeData.id} style={style} $_css4={[resourceStyle, styles.marginTopDouble]} />
        </>;
    } else if (resource.type === "Resource::Html") {
      // TODO: This should have proper metadata or subtype
      if (["Overview", "Aperçu", "Resumen", "Diagnosis & Treatment", "Diagnostic et traitement", "Diagnóstico y tratamiento"].includes(getNameVariant(selectedResourceNodeData, "short") ?? "")) {
        return <>
            {parentButton}
            {resource.localeIsMissing ? renderLanguageAlert : null}
            <_StyledCpQuickResource medicationsNodeRevisionId={medicationsNodeRevisionId} resource={resource} shortcodeMapping={selectedResourceNodeData.shortcodeMapping} style={style} $_css5={[resourceStyle, isWideScreen && styles.paddingHorizontalDouble]} />
          </>;
      }
    } // Default leaf resource node handling


    return <View style={style}>
        {parentButton}
        {resource.localeIsMissing ? renderLanguageAlert : null}
        <_StyledCpResource resource={resource} resourceNodeRevisionId={selectedResourceNodeData.revisionId} resourceType={resource.type} $_css6={resourceStyle} />
      </View>;
  } else {
    if (selectedResourceNodeData.isLeaf) {
      if (selectedResourceNodeData.type === "ResourceNode::Taggable::Resource") {
        // This is a leaf node referencing tagged data
        return <>
            {parentButton}
            <_StyledCpTaggedResourceNode resourceId={resourceId} resourceNodeId={selectedResourceNodeData.id} style={style} $_css7={resourceStyle} />
          </>;
      } else if (selectedResourceNodeData.relatedNode) {
        // Otherwise render a subtree navigator
        return <_StyledView3 $_css8={styles.paddingTopDouble}>
            {parentButton}
            <_StyledCtTopicSubtreeNavigator resourceNodeKey={selectedResourceNodeData.relatedNode || resourceNodeKey} style={style} $_css9={styles.fullWidth} />
          </_StyledView3>;
      } else {
        // This leaf node does not have a resource, and is not taggable. We don't know what it is
        Log.error(`Unknown resource node: ${selectedResourceNodeData.id} ${selectedResourceNodeData.names}`);
        return <CpError children={translations.labels.unknown} />;
      }
    } else {
      // This is not a leaf node, so check if we need special handling
      const resourceNodeChildren = selectedResourceNodeData.children ?? [];
      const videoChildCount = resourceNodeChildren.map(resourceNode => resourceNode?.resource?.type === "Resource::Video").filter(Boolean).length ?? 0;
      const imageChildCount = resourceNodeChildren.map(resourceNode => resourceNode?.resource?.type === "Resource::Image").filter(Boolean).length ?? 0;
      const isInherentlyMedia = ["Supporting Diagrams", "Diagrammes de soutien", "Diagramas de apoyo", "Videos", "Vidéos"].includes(selectedResourceNodeName);

      if (isInherentlyMedia || videoChildCount + imageChildCount === resourceNodeChildren.length) {
        // All the children are media nodes, so use a media view
        return <>
            {parentButton}
            <_StyledCpMediaSectionNode resourceNodeId={selectedResourceNodeData.id} style={style} $_css10={resourceStyle} />
          </>;
      } else if (isRelatedTopicsFolder(resourceNodeChildren)) {
        // Is it a related topic?  We'll want to render that in a special way.
        return <>
            {parentButton}
            <CpRelatedTopics id={selectedResourceNodeData.id} />
          </>;
      } else {
        // Otherwise render a subtree navigator
        return <_StyledView4 $_css11={styles.paddingTopDouble}>
            {parentButton}
            <_StyledCtTopicSubtreeNavigator2 resourceNodeKey={selectedResourceNodeData.relatedNode || resourceNodeKey} style={style} $_css12={styles.fullWidth} />
          </_StyledView4>;
      }
    }
  }
};

export default CpResourceNodeContent;

var _StyledView = _styled(View).withConfig({
  displayName: "CpResourceNodeContent___StyledView",
  componentId: "sc-15w3c0q-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpResourceNodeContent___StyledView2",
  componentId: "sc-15w3c0q-1"
})(["", ""], p => p.$_css2);

var _StyledCpImageResourceNode = _styled(CpImageResourceNode).withConfig({
  displayName: "CpResourceNodeContent___StyledCpImageResourceNode",
  componentId: "sc-15w3c0q-2"
})(["", ""], p => p.$_css3);

var _StyledCpVideoResourceNode = _styled(CpVideoResourceNode).withConfig({
  displayName: "CpResourceNodeContent___StyledCpVideoResourceNode",
  componentId: "sc-15w3c0q-3"
})(["", ""], p => p.$_css4);

var _StyledCpQuickResource = _styled(CpQuickResource).withConfig({
  displayName: "CpResourceNodeContent___StyledCpQuickResource",
  componentId: "sc-15w3c0q-4"
})(["", ""], p => p.$_css5);

var _StyledCpResource = _styled(CpResource).withConfig({
  displayName: "CpResourceNodeContent___StyledCpResource",
  componentId: "sc-15w3c0q-5"
})(["", ""], p => p.$_css6);

var _StyledCpTaggedResourceNode = _styled(CpTaggedResourceNode).withConfig({
  displayName: "CpResourceNodeContent___StyledCpTaggedResourceNode",
  componentId: "sc-15w3c0q-6"
})(["", ""], p => p.$_css7);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpResourceNodeContent___StyledView3",
  componentId: "sc-15w3c0q-7"
})(["", ""], p => p.$_css8);

var _StyledCtTopicSubtreeNavigator = _styled(CtTopicSubtreeNavigator).withConfig({
  displayName: "CpResourceNodeContent___StyledCtTopicSubtreeNavigator",
  componentId: "sc-15w3c0q-8"
})(["", ""], p => p.$_css9);

var _StyledCpMediaSectionNode = _styled(CpMediaSectionNode).withConfig({
  displayName: "CpResourceNodeContent___StyledCpMediaSectionNode",
  componentId: "sc-15w3c0q-9"
})(["", ""], p => p.$_css10);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpResourceNodeContent___StyledView4",
  componentId: "sc-15w3c0q-10"
})(["", ""], p => p.$_css11);

var _StyledCtTopicSubtreeNavigator2 = _styled(CtTopicSubtreeNavigator).withConfig({
  displayName: "CpResourceNodeContent___StyledCtTopicSubtreeNavigator2",
  componentId: "sc-15w3c0q-11"
})(["", ""], p => p.$_css12);