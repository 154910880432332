import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - file_download
const Download: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 24 24" width={height} {...otherProps}>
      <Path d="M5.016 18h13.969v2.016h-13.969v-2.016zM18.984 9l-6.984 6.984-6.984-6.984h3.984v-6h6v6h3.984z" />
    </Svg>
  )
}

export default Download
