import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { DisplayDirection } from "components/form/types";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { css } from "services/Theme";
const localStyles = {
  radioButtonText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    /* This doesn't normally take units, but apparently 'styled-components' complains if you don't include any */
    line-height: 18px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  radioHorizontal: css`
    align-items: center;
    border-bottom-width: 0;
    border-right-width: 1px;
    flex: 1;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    justify-content: center;
    text-align: center;
  `,
  radioOption: css`
    border-color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    padding: ${({
    theme
  }) => theme.spacing.half * 3}px;
  `,
  radioOptionHorizontalLeft: css`
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
  `,
  radioOptionHorizontalRight: css`
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-right-width: 0;
  `,
  radioOptionHorizontalSelected: css`
    border-bottom-width: 4px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.double - 4}px;
  `,
  radioOptionSelected: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackgroundLabelActive};
    border-bottom-width: 1px;
    border-color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    padding: ${({
    theme
  }) => theme.spacing.half * 3}px;
  `,
  radioOptionVerticalBottom: css`
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-bottom-width: 0;
  `,
  radioOptionVerticalTop: css`
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
  `,
  radioVerticalBottomBorder: css`
    border-bottom-width: 1px;
  `,
  radioVerticalSelected: css`
    border-left-width: 4px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double - 4}px;
  `
};

/**
 * Smallest part of the Radio Segmented Button form, the button itself.
 */
const CpRadioButton = ({
  children,
  displayDirection,
  isFirst,
  isLast,
  onPress,
  selectedOption,
  testID,
  value
}) => {
  const displayStyle = useMemo(() => {
    if (displayDirection === DisplayDirection.Horizontal) {
      return [localStyles.radioHorizontal, selectedOption === value ? localStyles.radioOptionSelected : localStyles.radioOption, isFirst && localStyles.radioOptionHorizontalLeft, isLast && localStyles.radioOptionHorizontalRight, selectedOption === value && localStyles.radioOptionHorizontalSelected];
    } else {
      return [localStyles.radioVerticalBottomBorder, selectedOption === value ? localStyles.radioOptionSelected : localStyles.radioOption, isFirst && localStyles.radioOptionVerticalTop, isLast && localStyles.radioOptionVerticalBottom, selectedOption === value && localStyles.radioVerticalSelected];
    }
  }, [displayDirection, selectedOption, value, isFirst, isLast]);
  const handlePress = useCallback(() => onPress(value), [onPress, value]);
  return <_StyledPrPressable accessibilityRole="radio" accessibilityState={{
    checked: selectedOption === value
  }} onPress={handlePress} testID={testID} $_css={displayStyle}>
      <_StyledPrText $_css2={localStyles.radioButtonText}>{children}</_StyledPrText>
    </_StyledPrPressable>;
};

export default CpRadioButton;

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpRadioButton___StyledPrPressable",
  componentId: "sc-1u8hliz-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpRadioButton___StyledPrText",
  componentId: "sc-1u8hliz-1"
})(["", ""], p => p.$_css2);