import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - mail
const Mail: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M26.688 10.688v-2.688l-10.688 6.688-10.688-6.688v2.688l10.688 6.625zM26.688 5.313q1.063 0 1.844 0.813t0.781 1.875v16q0 1.063-0.781 1.875t-1.844 0.813h-21.375q-1.063 0-1.844-0.813t-0.781-1.875v-16q0-1.063 0.781-1.875t1.844-0.813h21.375z" />
    </Svg>
  )
}

export default Mail
