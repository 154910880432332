export type SortableNode = {
  readonly rank?: number
  readonly names: Record<string, string>
  readonly pinSelfToTop?: boolean
  readonly pinSelfToBottom?: boolean
}

const compareByRank = (a: SortableNode, b: SortableNode): number => {
  return (a.rank ?? 0) - (b.rank ?? 0)
}

/**
 * Sorts nodes alphabetically. If a node has the "Pin Self to Top" flag, it will go to the top.
 * Similarly, if a node has the "Pin Self to Bottom" flag, it will go to the bottom
 */
const compareByPinAndName = (a: SortableNode, b: SortableNode): number => {
  if ((a.pinSelfToTop && !b.pinSelfToTop) || (!a.pinSelfToBottom && b.pinSelfToBottom)) {
    return -1
  }
  if ((!a.pinSelfToTop && b.pinSelfToTop) || (a.pinSelfToBottom && !b.pinSelfToBottom)) {
    return 1
  }
  // If both nodes are marked as "Pin to Top" or "Pin To Bottom", do not sort
  // Note that this typically shouldn't happen
  if ((a.pinSelfToTop && b.pinSelfToTop) || (a.pinSelfToBottom && b.pinSelfToBottom)) {
    return 0
  }

  const aName = a.names?.default ?? ""
  const bName = b.names?.default ?? ""

  if (aName > bName) {
    return 1
  }
  if (aName < bName) {
    return -1
  }
  return 0
}

/**
 * Sorts nodes by either their rank (if "Order children manually?" is checked in the admin console)
 * or alphabetically and "pin to top/bottom" status.
 */
export default function (children: SortableNode[], shouldSortChildrenByRank?: boolean): void {
  if (shouldSortChildrenByRank) {
    children.sort(compareByRank)
  } else {
    children.sort(compareByPinAndName)
  }
}
