import { useContext } from "react"

import { NavigationRedirect, NavigationRedirectType } from "contexts/CxNavigationRedirect"

/**
 * Returns the current navigation redirect context.
 */
function useNavigationRedirect(): NavigationRedirectType {
  const navigationContext = useContext(NavigationRedirect)
  if (!navigationContext) throw new Error("Nav Context is required")
  return navigationContext
}

export default useNavigationRedirect
