/**
 * @generated SignedSource<<e0f40a34fa45d9c5ec1d1fff0a71025a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Locale = "en" | "es" | "fr" | "%future added value";
export type CpVideoResourceNodeQuery$variables = {
  id: string;
  locale?: Locale | null;
};
export type CpVideoResourceNodeQuery$data = {
  readonly node: {
    readonly ancestors?: ReadonlyArray<{
      readonly names: any;
      readonly revisionId: string;
    }>;
    readonly resource?: {
      readonly aspectRatio: number | null;
      readonly embedMarkup: string | null;
      readonly names: any;
      readonly thumbnailUrl: string | null;
    } | null;
    readonly revisionId?: string;
    readonly siblings?: ReadonlyArray<{
      readonly resource: {
        readonly type: string;
      } | null;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"CpMediaNodeNavigatorCommon_resourceNode">;
  } | null;
};
export type CpVideoResourceNodeQuery = {
  response: CpVideoResourceNodeQuery$data;
  variables: CpVideoResourceNodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v4 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "aspectRatio",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "embedMarkup",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "thumbnailUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpVideoResourceNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "siblings",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Resource",
                    "kind": "LinkedField",
                    "name": "resource",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "ancestors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Resource",
                "kind": "LinkedField",
                "name": "resource",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "CpMediaNodeNavigatorCommon_resourceNode"
              }
            ],
            "type": "ResourceNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpVideoResourceNodeQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "siblings",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Resource",
                    "kind": "LinkedField",
                    "name": "resource",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/),
                  (v12/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "ancestors",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Resource",
                "kind": "LinkedField",
                "name": "resource",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Resource",
                "kind": "LinkedField",
                "name": "taggedResources",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  (v10/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v8/*: any*/),
                  (v11/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "type": "ResourceNode",
            "abstractKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18c8d15c7993cefbc1d2a72bc2b5bd26",
    "id": null,
    "metadata": {},
    "name": "CpVideoResourceNodeQuery",
    "operationKind": "query",
    "text": "query CpVideoResourceNodeQuery(\n  $id: ID!\n  $locale: Locale\n) {\n  node(id: $id) {\n    __typename\n    ... on ResourceNode {\n      siblings {\n        resource {\n          type\n          id\n        }\n        id\n      }\n      ancestors {\n        names(locale: $locale)\n        revisionId\n        id\n      }\n      resource {\n        aspectRatio(locale: $locale)\n        embedMarkup(locale: $locale)\n        names(locale: $locale)\n        thumbnailUrl(locale: $locale)\n        id\n      }\n      revisionId\n      ...CpMediaNodeNavigatorCommon_resourceNode\n    }\n    id\n  }\n}\n\nfragment CpMediaNodeNavigatorCommon_resourceNode on ResourceNode {\n  _id\n  ancestors {\n    revisionId\n    id\n  }\n  revisionId\n  siblings {\n    _id\n    names(locale: $locale)\n    resource {\n      caption(locale: $locale)\n      embedMarkup(locale: $locale)\n      url(locale: $locale)\n      id\n    }\n    revisionId\n    id\n  }\n  taggedResources {\n    _id\n    caption(locale: $locale)\n    embedMarkup(locale: $locale)\n    names(locale: $locale)\n    thumbnailUrl(locale: $locale)\n    url(locale: $locale)\n    id\n  }\n  type\n}\n"
  }
};
})();

(node as any).hash = "d9c235ac987b22ff008c176f53b83866";

export default node;
