import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import CpGridItem, { GridItemHeights, GridItemWidths } from "components/home/tabs/CpGridItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { getNameVariant } from "services/Graphql";
import { getTimeFromNow } from "services/PatientSession";
import { css, styles } from "services/Theme";
import translations from "translations";
const containerSizes = {
  buttonLargeWidth: css`
    width: ${GridItemWidths.large}px;
  `,
  buttonSmallWidth: css`
    width: ${GridItemWidths.small}px;
  `,
  largeHeight: css`
    height: ${GridItemHeights.large}px;
  `,
  largeWidth: css`
    width: ${({
    theme
  }) => theme.buttonHeights.xxlarge}px;
  `,
  smallHeight: css`
    height: ${GridItemHeights.small}px;
  `,
  smallWidth: css`
    width: ${({
    theme
  }) => theme.buttonHeights.large}px;
  `
};
const localStyles = {
  backgroundDisabled: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `,
  backgroundEnabled: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
  `,
  historyContainer: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  historySelectingLabel: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-weight: bold;
    justify-content: center;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  marginRightLarge: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  marginRightSmall: css`
    margin-right: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  paddingVerticalHalf: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  showMoreButton: css`
    ${styles.rowAndCenter};
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    justify-content: center;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    margin-left: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  showMoreButtonText: css`
    color: ${({
    theme
  }) => theme.buttonColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: normal;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const timeContainerStyles = {
  base: css`
    align-items: center;
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    justify-content: center;
    padding: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  connector: css`
    height: ${({
    theme
  }) => theme.spacing.double}px;
    position: absolute;
    top: -${({
    theme
  }) => theme.spacing.single}px;
    width: 2px;
  `,
  large: css`
    ${containerSizes.largeHeight};
    ${containerSizes.largeWidth};
    ${localStyles.marginRightLarge};
  `,
  now: css`
    ${localStyles.marginRightLarge};
  `,
  small: css`
    ${containerSizes.smallHeight};
    ${containerSizes.smallWidth};
    ${localStyles.marginRightSmall};
  `,
  standard: css`
    background-color: ${({
    theme
  }) => theme.brandColors.primary};
  `,
  text: css`
    color: ${({
    theme
  }) => theme.textColors.inverse};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    text-align: center;
  `,
  upcoming: css`
    background-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorSeparator};
  `,
  upcomingContainer: css`
    /* Height set to match the button */
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    ${localStyles.marginRightLarge};
  `,
  upcomingText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    text-align: center;
  `
};

/**
 * Renders the most recent Topic(s) viewed by the user to select as a starting point for a PatientSession not explicitly started.
 * Provides user with a button for displaying the next oldest topic, one at a time.
 */
const CpPatientSessionHistorySelect = ({
  nodes,
  onPress
}) => {
  const {
    buttonColors,
    buttonHeights,
    iconSizes
  } = useAppTheme();
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    patientSessionData: {
      viewedResources
    }
  } = usePatientSessionContext(); // This style determines the height for the line connecting the current item to the previous one

  const connectorPositionStyle = useMemo( // Subtract 1 from 'left' to better centre the connector
  () => css`
      left: ${(isWideScreen ? buttonHeights.xxlarge : buttonHeights.large) / 2 - 1}px;
    `, [buttonHeights, isWideScreen]); // History state, callback, and memoized list of topics to render as components

  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
  const handleSetCurrentTopicIndex = useCallback(() => {
    setCurrentTopicIndex(currentTopicIndex + 1);
  }, [currentTopicIndex]);
  const topics = useMemo(() => {
    const allTopics = (nodes ?? [] // Find all (and only) the topics within the nodes
    ).filter(currentNode => currentNode?.revisionId === currentNode?.rootRevisionId) // Find the corresponding viewedResource entry, and return its timestamp along with the node data
    .map(topic => {
      const relatedResource = viewedResources.find(viewedResource => viewedResource.revisionId === topic?.revisionId);
      return {
        data: topic,
        time: relatedResource?.time
      };
    }).filter(topic => Boolean(topic)) // Ensure our topics are properly sorted in reverse order
    .sort((a, b) => b.time - a.time);
    return allTopics.map(({
      data,
      time
    }, topicIndex) => {
      let formattedTime = getTimeFromNow(time);

      if (formattedTime.includes("s")) {
        const timePieces = formattedTime.split(" "); // One piece means we only have seconds and want to display as "< 1m", otherwise we want to strip the seconds entirely

        formattedTime = timePieces.length > 1 ? timePieces.slice(0, -1).toString().replace(",", " ") : "< 1m";
      }

      const nodeIcon = data?.type === "ResourceNode::Condition" && data?.relatedNode?.icon ? data?.relatedNode?.icon : data?.icon;
      return {
        Component: <_StyledView key={time} $_css={styles.rowAndCenter}>
            <_StyledView2 $_css2={[timeContainerStyles.connector, timeContainerStyles.standard, connectorPositionStyle]} />
            <_StyledView3 $_css3={[timeContainerStyles.base, timeContainerStyles.standard, isWideScreen ? timeContainerStyles.large : timeContainerStyles.small]}>
              <_StyledPrText2 children={{
              message: translations.labels.ago,
              values: {
                timePeriod: <_StyledPrText children={formattedTime} $_css4={[styles.boldText, timeContainerStyles.text]} />
              }
            }} $_css5={timeContainerStyles.text} />
            </_StyledView3>
            <CpGridItem color="primary" icon={nodeIcon} link={{
            link_type: "topic",
            value: ""
          }} onPress={() => onPress(data?.revisionId ?? "", nodes)} testID={`CpPatientSessionHistorySelect-${topicIndex}`} title={getNameVariant(data)} />
          </_StyledView>,
        viewedTime: formattedTime
      };
    });
  }, [nodes, viewedResources, connectorPositionStyle, isWideScreen, onPress]);
  const topicsToDisplay = useMemo(() => {
    const localTopics = [];

    for (let topicIndex = 0; topicIndex < currentTopicIndex + 1; topicIndex++) {
      localTopics.push(topics[topicIndex].Component);
    }

    return localTopics;
  }, [currentTopicIndex, topics]);
  const hasMoreTopics = Boolean(topics[currentTopicIndex + 1]?.viewedTime);
  return <_StyledView4 $_css6={localStyles.historyContainer}>
      <View>
        <_StyledView5 $_css7={styles.rowAndCenter}>
          <_StyledView6 $_css8={[timeContainerStyles.base, timeContainerStyles.standard, isWideScreen ? containerSizes.largeWidth : containerSizes.smallWidth, isWideScreen ? localStyles.marginRightLarge : localStyles.marginRightSmall, styles.marginVerticalSingle]}>
            <_StyledPrText3 children={translations.labels.now} $_css9={[styles.boldText, timeContainerStyles.text, localStyles.paddingVerticalHalf]} />
          </_StyledView6>
          <_StyledPrText4 $_css10={localStyles.historySelectingLabel}>
            <FormattedMessage id="5Y86fE" defaultMessage="Selecting session starting point..." />
          </_StyledPrText4>
        </_StyledView5>

        {topicsToDisplay}

        {
        /* Button to select the next topic to display*/
      }
        <_StyledView7 $_css11={styles.rowAndCenter}>
          <_StyledView8 $_css12={[timeContainerStyles.connector, timeContainerStyles.upcoming, connectorPositionStyle]} />
          <_StyledView9 $_css13={[timeContainerStyles.base, isWideScreen ? timeContainerStyles.large : timeContainerStyles.small, timeContainerStyles.upcoming, timeContainerStyles.upcomingContainer]}>
            {hasMoreTopics ? <>
                <_StyledPrText6 children={{
              message: translations.labels.ago,
              values: {
                timePeriod: <_StyledPrText5 children={topics[currentTopicIndex + 1].viewedTime} $_css14={[styles.boldText, timeContainerStyles.upcomingText]} />
              }
            }} $_css15={timeContainerStyles.upcomingText} />
              </> : <_StyledPrText7 children="---" $_css16={[styles.boldText, timeContainerStyles.upcomingText]} />}
          </_StyledView9>

          <_StyledPrPressable disabled={!hasMoreTopics} onPress={handleSetCurrentTopicIndex} testID="CpPatientSessionHistorySelect-more" $_css17={[localStyles.showMoreButton, isWideScreen ? containerSizes.buttonLargeWidth : containerSizes.buttonSmallWidth, hasMoreTopics ? localStyles.backgroundEnabled : localStyles.backgroundDisabled]}>
            <PrIcon fill={buttonColors.primary} icon={hasMoreTopics ? IconName.check : IconName.close} size={iconSizes.small} />
            <_StyledPrText8 children={hasMoreTopics ? translations.buttons.showMore : translations.labels.noMore} $_css18={localStyles.showMoreButtonText} />
          </_StyledPrPressable>
        </_StyledView7>
      </View>
    </_StyledView4>;
};

export default CpPatientSessionHistorySelect;

var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView",
  componentId: "sc-8scrz4-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView2",
  componentId: "sc-8scrz4-1"
})(["", ""], p => p.$_css2);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView3",
  componentId: "sc-8scrz4-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText",
  componentId: "sc-8scrz4-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText2",
  componentId: "sc-8scrz4-4"
})(["", ""], p => p.$_css5);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView4",
  componentId: "sc-8scrz4-5"
})(["", ""], p => p.$_css6);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView5",
  componentId: "sc-8scrz4-6"
})(["", ""], p => p.$_css7);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView6",
  componentId: "sc-8scrz4-7"
})(["", ""], p => p.$_css8);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText3",
  componentId: "sc-8scrz4-8"
})(["", ""], p => p.$_css9);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText4",
  componentId: "sc-8scrz4-9"
})(["", ""], p => p.$_css10);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView7",
  componentId: "sc-8scrz4-10"
})(["", ""], p => p.$_css11);

var _StyledView8 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView8",
  componentId: "sc-8scrz4-11"
})(["", ""], p => p.$_css12);

var _StyledView9 = _styled(View).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledView9",
  componentId: "sc-8scrz4-12"
})(["", ""], p => p.$_css13);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText5",
  componentId: "sc-8scrz4-13"
})(["", ""], p => p.$_css14);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText6",
  componentId: "sc-8scrz4-14"
})(["", ""], p => p.$_css15);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText7",
  componentId: "sc-8scrz4-15"
})(["", ""], p => p.$_css16);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrPressable",
  componentId: "sc-8scrz4-16"
})(["", ""], p => p.$_css17);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionHistorySelect___StyledPrText8",
  componentId: "sc-8scrz4-17"
})(["", ""], p => p.$_css18);