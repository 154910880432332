/**
 * @generated SignedSource<<8ac2416227dbe5364dc93759ac81553d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ThemeColors_configFragment$data = {
  readonly additionalBodyStyles: string | null;
  readonly additionalFooterStyles: string | null;
  readonly additionalHeaderStyles: string | null;
  readonly additionalHomeContainerStyles: string | null;
  readonly additionalHomeHeaderStyles: string | null;
  readonly additionalHomeSearchBarStyles: string | null;
  readonly additionalHomeTaglineStyles: string | null;
  readonly brandColors: {
    readonly background: string;
    readonly dropShadow: string;
    readonly errorBackground: string;
    readonly homeSearchBacksplash: string;
    readonly inverseBackground: string;
    readonly navHeaderBackground: string;
    readonly nodeNavigatorBase: string;
    readonly nodeNavigatorIcon: string;
    readonly nodeNavigatorOpenHeaderBackground: string;
    readonly nodeNavigatorPresentationSectionNode: string;
    readonly nodeNavigatorSelected: string;
    readonly nodeNavigatorSeparator: string;
    readonly nodeNavigatorTreeChild1: string;
    readonly nodeNavigatorTreeChild2: string;
    readonly nodeNavigatorTreeChild3: string;
    readonly nodeNavigatorTreeChild4: string;
    readonly primary: string;
    readonly screenOverlay: string;
    readonly searchPopupBorder: string;
    readonly secondary: string;
    readonly secondaryBackground: string;
    readonly selected: string;
    readonly separator: string;
    readonly snackbarAccentError: string;
    readonly snackbarAccentPrimary: string;
    readonly snackbarAccentWarning: string;
    readonly snackbarBackgroundError: string;
    readonly snackbarBackgroundPrimary: string;
    readonly snackbarBackgroundWarning: string;
    readonly surface: string;
    readonly tabBackground: string;
    readonly tabBorder: string;
    readonly tabSeparator: string;
    readonly tertiary: string;
    readonly transparent: string;
    readonly warningBackground: string;
  };
  readonly buttonColors: {
    readonly destructive: string;
    readonly paginationSelected: string;
    readonly primary: string;
    readonly secondary: string;
    readonly surface: string;
    readonly tertiary: string;
    readonly text: string;
    readonly textInputActive: string;
    readonly textInputDisabled: string;
    readonly textInverse: string;
  };
  readonly formColors: {
    readonly errorBackground: string;
    readonly fieldBackground: string;
    readonly fieldBackgroundError: string;
    readonly fieldBackgroundLabelActive: string;
    readonly fieldBorderActive: string;
    readonly fieldBorderError: string;
    readonly fieldBorderInactive: string;
    readonly fieldError: string;
    readonly fieldLabelActive: string;
    readonly fieldLabelInactive: string;
    readonly fieldPasswordProgressBar: string;
    readonly fieldPasswordSecureBox: string;
    readonly fieldPasswordSecureText: string;
  };
  readonly headerBackgroundColor: string;
  readonly textColors: {
    readonly default: string;
    readonly disabled: string;
    readonly error: string;
    readonly footer: string;
    readonly heading1: string;
    readonly heading2: string;
    readonly inverse: string;
    readonly link: string;
    readonly muted: string;
    readonly mutedInverse: string;
    readonly mutedPrimary: string;
    readonly navHeader: string;
    readonly tabLabel: string;
    readonly textInputBackground: string;
    readonly textInputPlaceholder: string;
    readonly textInputSecondaryBackground: string;
  };
  readonly " $fragmentType": "ThemeColors_configFragment";
};
export type ThemeColors_configFragment$key = {
  readonly " $data"?: ThemeColors_configFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeColors_configFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errorBackground",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "primary",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "secondary",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surface",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tertiary",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ThemeColors_configFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalBodyStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalFooterStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalHeaderStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalHomeContainerStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalHomeHeaderStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalHomeSearchBarStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalHomeTaglineStyles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerBackgroundColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BrandColors",
      "kind": "LinkedField",
      "name": "brandColors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "background",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dropShadow",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "homeSearchBacksplash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inverseBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "navHeaderBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorBase",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorIcon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorOpenHeaderBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorPresentationSectionNode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorSelected",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorSeparator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorTreeChild1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorTreeChild2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorTreeChild3",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nodeNavigatorTreeChild4",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "screenOverlay",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "searchPopupBorder",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondaryBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selected",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "separator",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snackbarAccentError",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snackbarAccentPrimary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snackbarAccentWarning",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snackbarBackgroundError",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snackbarBackgroundPrimary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "snackbarBackgroundWarning",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabBorder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabSeparator",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "transparent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "warningBackground",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ButtonColors",
      "kind": "LinkedField",
      "name": "buttonColors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "destructive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paginationSelected",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textInputActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textInputDisabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textInverse",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormColors",
      "kind": "LinkedField",
      "name": "formColors",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldBackgroundError",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldBackgroundLabelActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldBorderActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldBorderError",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldBorderInactive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldError",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldLabelActive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldLabelInactive",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldPasswordProgressBar",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldPasswordSecureBox",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldPasswordSecureText",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TextColors",
      "kind": "LinkedField",
      "name": "textColors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "footer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "heading1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "heading2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "inverse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "link",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "muted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mutedInverse",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mutedPrimary",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "navHeader",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textInputBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textInputSecondaryBackground",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "textInputPlaceholder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AppColors",
  "abstractKey": null
};
})();

(node as any).hash = "81f436480fb95dec0c1010e7c83601ac";

export default node;
