import { debounce } from "debounce"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { TextInput } from "react-native"

import PrTextInput, { PrTextInputProps } from "components/primitives/PrTextInput"

/**
 * A debounced text input.
 */
const CpDebouncedTextInput: React.FC<PrTextInputProps> = ({
  forwardRef,
  onChangeText = () => undefined,
  value,
  ...otherProps
}) => {
  // Track the value of the input field locally
  const [localValue, setLocalValue] = useState<undefined | string>(value)

  // Create a debounced function that calls onChange after a 400ms settle time
  const onChangeDebounced = useMemo(() => debounce(onChangeText, 400), [onChangeText])

  // Cancel any pending debounced fn if the component unmounts
  useEffect(() => onChangeDebounced.clear, [onChangeDebounced])

  // Update the local value when the value prop changes
  useEffect(() => {
    setLocalValue(value)
  }, [value])

  // Set the local value and call onChange
  const handleChangeText = useCallback(
    (newValue) => {
      setLocalValue(newValue)
      if (newValue !== localValue) onChangeDebounced(newValue)
    },
    [localValue, onChangeDebounced],
  )

  return (
    <PrTextInput
      onChangeText={handleChangeText}
      ref={forwardRef}
      value={localValue}
      {...otherProps}
    />
  )
}

export default React.forwardRef<InstanceType<typeof TextInput>, PrTextInputProps>((props, ref) => (
  <CpDebouncedTextInput forwardRef={ref} {...props} />
))
