import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconLaptop: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M276.28 79h-191c-.69 0-1 .31-1 1v146c0 .69.31 1 1 1h191c.69 0 1-.3 1-1V80c0-.69-.31-1-1-1zM52.43 271.68a13 13 0 011.38-5.55l14.07-29.21c1-2.14 3.8-3.48 6.38-4.68a13.1 13.1 0 01-1.9-6.78L72.28 80.4a13.2 13.2 0 0113-13.35h191c7.25 0 13 6.23 13 13.49v145.03a13 13 0 01-1.9 6.78c2.59 1.2 5.34 2.53 6.31 4.69l13.15 29.09a13.53 13.53 0 011.29 5.56l.15 15.34a6 6 0 01-6 6h-244a6 6 0 01-6-6l.15-15.35zm164.16-5.63a1.86 1.86 0 002-2l-.67-10 65.45.12c2.37 0 3-1.79 2-3.78-.55-1.09-3.53-10.08-4.58-10.78a7.13 7.13 0 00-3.42-1.42c-63.53 0-129.57-.11-193.1-.11a7.13 7.13 0 00-3.42 1.42c-1 .7-4.1 9.68-4.65 10.77-1 2-.37 3.78 2 3.78h64.13l-.67 10a1.85 1.85 0 002 2z"
        fillRule="evenodd"
      />
      <Path d="M165.28 108.04h30v30h30v30h-30v30h-30v-30h-30v-30h30v-30z" fillRule="evenodd" />
    </Svg>
  )
}

export default IconLaptop
