import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { ScrollView, View } from "react-native";
import CpItem from "components/common/CpItem";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import { css } from "services/Theme";
const localStyles = {
  groupTitle: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
  `,
  navGroup: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  option: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  optionDesktop: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding: 0;
  `
};

/**
 * Navigation dropdown menu for mobile and desktop
 */
const CpMenuDropdown = ({
  group
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  return <ScrollView>
      <View key={group.title ? group.title : group.options[0].name}>
        {group.title ? <_StyledPrText children={group.title} $_css={localStyles.groupTitle} /> : null}
        {group.options.map(option => {
        return <CpItem key={option.name} onPress={option.onPress}>
              <_StyledPrText2 $_css2={[localStyles.option, isWideScreen ? localStyles.optionDesktop : null]}>
                {option.name}
              </_StyledPrText2>
            </CpItem>;
      })}
      </View>
    </ScrollView>;
};

export default CpMenuDropdown;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpMenuDropdown___StyledPrText",
  componentId: "sc-e5fpfz-0"
})(["", ""], p => p.$_css);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpMenuDropdown___StyledPrText2",
  componentId: "sc-e5fpfz-1"
})(["", ""], p => p.$_css2);