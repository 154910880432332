import { NavigationProp, useNavigation } from "@react-navigation/native"
import React, { useCallback, useState } from "react"

import { Collapse, CollapseBody, CollapseHeader } from "accordion-collapse-react-native"
import useAnalytics from "hooks/useAnalytics"
import useTopicContext from "hooks/useTopicContext"
import Routes, { RouteParams } from "navigators/Routes"
import { QlSubtreeRoot, QlTopicSubtreeFragment } from "query/QlTopicSubtree"
import { Linking } from "react-native"
import { useFragment } from "react-relay"
import { AnalyticsEventName } from "services/Analytics"
import { getNameVariant } from "services/Graphql"
import Log from "services/Log"
import CpTopicCard from "../common/CpTopicCard"
import CpTopicCardItemView from "../common/CpTopicCardItemView"
import CpTopicSubtreeResourceItem from "./CpTopicSubtreeResourceItem"

export interface CpTopicSubtreeResourceNodeItemProps {
  depth: number // How "deep" an item is within a card. 0 represents the card root. Note that depth is relative to a card view
  node: Required<QlSubtreeRoot>
}

/**
 * Extracts the required data for a resourceNode and renders a SubtreeItem
 */
const CpTopicSubtreeResourceNodeItem: React.FC<CpTopicSubtreeResourceNodeItemProps> = ({
  depth,
  node,
}) => {
  const { trackEvent } = useAnalytics()

  const resourceNodeData = useFragment(QlTopicSubtreeFragment, node.fragmentKey)
  const { icon, isLeaf, resourceNodeRevisionId, resourceType, revisionId, taggedResources } =
    resourceNodeData

  const [expanded, setExpanded] = useState(false)

  const navigation = useNavigation<NavigationProp<RouteParams, Routes.topic>>()
  const { rootRevisionId } = useTopicContext()

  const label = getNameVariant(resourceNodeData) ?? ""
  const handleSelect = useCallback(() => {
    if (isLeaf && !taggedResources?.length) {
      Log.info("Leaf ResourceNode selected", { revisionId })
      navigation.navigate(Routes.topic, { rootRevisionId, selectedRevisionId: revisionId })
    } else if (resourceNodeRevisionId) {
      Log.info("Related root ResourceNode selected", { resourceNodeRevisionId })
      navigation.navigate(Routes.topic, {
        rootRevisionId: resourceNodeRevisionId,
      })
      trackEvent({
        data: { targetRevisionId: resourceNodeRevisionId },
        eventType: AnalyticsEventName.nav_related,
      })
    } else {
      setExpanded(!expanded)
    }
  }, [
    expanded,
    isLeaf,
    resourceNodeRevisionId,
    revisionId,
    navigation,
    rootRevisionId,
    taggedResources,
    trackEvent,
  ])

  const handleAuxSelect = useCallback(() => {
    if (isLeaf && !taggedResources?.length) {
      Log.info("Leaf ResourceNode selected", { revisionId })
      Linking.openURL(`${location.origin}/${Routes.topic}/${rootRevisionId}/${revisionId}`)
    } else if (resourceNodeRevisionId) {
      Log.info("Related root ResourceNode selected", { resourceNodeRevisionId })
      Linking.openURL(`${location.origin}/${Routes.topic}/${rootRevisionId}`)

      trackEvent({
        data: { targetRevisionId: resourceNodeRevisionId },
        eventType: AnalyticsEventName.nav_related,
      })
    } else {
      setExpanded(expanded)
    }
  }, [
    expanded,
    isLeaf,
    resourceNodeRevisionId,
    revisionId,
    rootRevisionId,
    taggedResources,
    trackEvent,
  ])

  // Safeguard in case this node is a tagged resource folder that doesn't contain any tagged resources
  if (taggedResources && !taggedResources.length) {
    return null
  }

  const pressable = (
    <CpTopicCardItemView
      depth={depth}
      expanded={expanded}
      icon={icon}
      isLeaf={isLeaf && !taggedResources?.length}
      label={label}
      onAuxSelect={handleAuxSelect}
      onSelect={handleSelect}
      resourceType={resourceType}
    />
  )

  const collapsible = node.children ? (
    <Collapse isExpanded={expanded}>
      {/* Mandatory for CollapseBody to appear, even if we don't use it */}
      <CollapseHeader />

      <CollapseBody>
        {node.children.map((childNode) => {
          return (
            <CpTopicSubtreeResourceNodeItem depth={depth + 1} key={childNode.id} node={childNode} />
          )
        })}
        {taggedResources?.map((taggedResource) => {
          return (
            <CpTopicSubtreeResourceItem
              depth={depth + 1}
              key={taggedResource._id}
              resourceKey={taggedResource}
              revisionId={revisionId}
            />
          )
        })}
      </CollapseBody>
    </Collapse>
  ) : null

  const body = (
    <React.Fragment>
      {pressable}
      {collapsible}
    </React.Fragment>
  )

  if (!depth) {
    return <CpTopicCard>{body}</CpTopicCard>
  } else {
    return body
  }
}

export default CpTopicSubtreeResourceNodeItem
