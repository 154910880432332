import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: IcoMoon - check-circle
const CheckCircle: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M13.313 22.688l12-12-1.875-1.938-10.125 10.125-4.75-4.75-1.875 1.875zM16 2.688q5.5 0 9.406 3.906t3.906 9.406-3.906 9.406-9.406 3.906-9.406-3.906-3.906-9.406 3.906-9.406 9.406-3.906z" />
    </Svg>
  )
}

export default CheckCircle
