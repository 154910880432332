import { IconName } from "components/primitives/PrIcon"
import { GraphQLTaggedNode } from "react-relay"
import { PreloadedQuery } from "react-relay/hooks"
import { FragmentRefs } from "relay-runtime"

/**
 * Used to type connection edges in a fragment.
 *
 * @example
 * const renderItem = useCallback({ item }: { item: FragmentEdge<CpListItem_thing$key> }) => { ... }
 */
export type FragmentEdge<
  T extends {
    readonly " $data"?: Record<string, unknown>
    readonly " $fragmentRefs": FragmentRefs<string>
  },
> = null | {
  cursor: string
  node: null | T
}

/**
 * Extracts the query type from a preloaded query reference.
 *
 * @example
 * const { data } = usePaginationFragment<PreloadedQueryType<typeof queryReference>, CpMyFragmentComponent_fragmentThings$key>(thingsFragment, queryResponse)
 */
export type PreloadedQueryType<T> = T extends PreloadedQuery<infer Q> ? Q : never

/**
 * Used by components that want to use a query reference to load fragments without having to know the type for the Query used.
 * The queryReference and the query must both be passed as props.
 *
 * @example
 * interface CpMyFragmentComponentProps extends PreloadedQueryWithFragment<CpMyFragmentComponent_fragmentThings> {
 *  anotherProp: string
 * }
 *
 * const CpMyFragmentComponent: React.FC<CpMyFragmentComponentProps> = ({ query, queryReference }) => {
 *   const queryResponse = usePreloadedQuery(query, queryReference)
 *   const {
 *     data: { fragmentThings }
 *   } = usePaginationFragment<PreloadedQueryType<typeof queryReference>, CpMyFragmentComponent_fragmentThings$key>(thingsFragment, queryResponse)
 * }
 */
export interface PreloadedQueryWithFragment<
  T extends {
    readonly " $refType": string
  },
> {
  query: GraphQLTaggedNode
  queryReference: PreloadedQuery<{
    readonly response: {
      " $fragmentRefs": FragmentRefs<T[" $refType"]>
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    readonly variables: any
  }>
}

/**
 * Extracts a name from a name variants field.
 * If no variant name is provided, "default" is used.
 * If an array of variant names is provided, the first match is returned. There is no default on this option, so you want it to fall back to
 * default you must provide it in the array.
 */
export const getNameVariant = (
  entity: null | { names?: unknown },
  variantName: string | string[] = "default",
): undefined | string => {
  if (!entity?.names) return

  const names = entity.names as Record<string, string>

  if (typeof variantName === "string") {
    return names[variantName]
  } else {
    for (const variant of variantName) {
      const name = names[variant]
      if (name) return name
    }
    return undefined
  }
}

export const getRootNameVariant = (
  entity: null | { rootNames?: unknown },
  variantName: string | string[] = "default",
): undefined | string => {
  if (!entity?.rootNames) return

  const names = entity.rootNames as Record<string, string>

  if (typeof variantName === "string") {
    return names[variantName]
  } else {
    for (const variant of variantName) {
      const name = names[variant]
      if (name) return name
    }
    return undefined
  }
}

/**
 * Given a resource type, return the associated icon from our iconSet
 */
export const iconTypeMapping: Record<string, IconName> = {
  "Resource::Form": IconName.form,
  "Resource::Handlebars": IconName.article,
  "Resource::Html": IconName.article,
  "Resource::Iframe": IconName.article,
  "Resource::Image": IconName.image,
  "Resource::Pdf": IconName.pdf,
  "Resource::Video": IconName.video,
}
