import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - business
const Organization: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M24 20v2.688h-2.688v-2.688h2.688zM24 14.688v2.625h-2.688v-2.625h2.688zM26.688 25.313v-13.313h-10.688v2.688h2.688v2.625h-2.688v2.688h2.688v2.688h-2.688v2.625h10.688zM13.313 9.313v-2.625h-2.625v2.625h2.625zM13.313 14.688v-2.688h-2.625v2.688h2.625zM13.313 20v-2.688h-2.625v2.688h2.625zM13.313 25.313v-2.625h-2.625v2.625h2.625zM8 9.313v-2.625h-2.688v2.625h2.688zM8 14.688v-2.688h-2.688v2.688h2.688zM8 20v-2.688h-2.688v2.688h2.688zM8 25.313v-2.625h-2.688v2.625h2.688zM16 9.313h13.313v18.688h-26.625v-24h13.313v5.313z" />
    </Svg>
  )
}

export default Organization
