import React, { useCallback } from "react"

import CpSignoutButton from "components/common/CpSignoutButton"
import { PatientSessionActions } from "components/patientSession/PatientSession"
import {
  ActionButtonProps,
  ActionButtonUserType,
} from "components/primitives/PrActionButton/PrActionButton"
import usePatientSessionContext from "hooks/usePatientSessionContext"

/**
 * Signout header action button for public app
 */
const SignoutButton: React.FC<ActionButtonProps> = ({
  onPress,
  type = ActionButtonUserType.AccountMenu,
}) => {
  const { dispatchPatientSession } = usePatientSessionContext()

  const handlePatientSessionSignout = useCallback(
    () => dispatchPatientSession({ type: PatientSessionActions.SignOut }),
    [dispatchPatientSession],
  )

  return (
    <CpSignoutButton
      onDispatchPatientSession={handlePatientSessionSignout}
      onPress={onPress}
      type={type}
    />
  )
}

export default SignoutButton
