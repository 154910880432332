import React, { useMemo } from "react"
import { StyleProp, ViewProps } from "react-native"
import { useFragment } from "react-relay/hooks"

import PrText from "components/primitives/PrText"

import { QlTopic_comboFragment } from "query/QlTopic"
import { QlTopic_combo$key } from "query/__generated__/QlTopic_combo.graphql"
import { getNameVariant } from "services/Graphql"

interface CpTopicNameProps {
  comboKey: QlTopic_combo$key
  numberOfLines?: number
  resourceId?: string
  style?: StyleProp<ViewProps>
}

/**
 * Component to render the name of the topic in the mobile TOC button
 */
const CpTopicName: React.FC<CpTopicNameProps> = ({
  comboKey,
  resourceId,
  style,
  ...otherProps
}) => {
  const comboData = useFragment(QlTopic_comboFragment, comboKey)

  const taggedResource = useMemo(
    () => comboData?.taggedResources?.find((resource) => resource._id === resourceId),
    [comboData?.taggedResources, resourceId],
  )
  const resourceNodeName =
    resourceId && taggedResource
      ? getNameVariant(taggedResource, ["brand", "default"])
      : getNameVariant(comboData, ["short", "default"])

  return (
    <PrText
      accessibilityRole="header"
      aria-level="2"
      children={resourceNodeName}
      style={style}
      {...otherProps}
    />
  )
}

export default CpTopicName
