import _styled from "styled-components";
import React from "react";
import CpFormResource from "components/topic/CpFormResource";
import CpHandlebarResource from "components/topic/CpHandlebarResource";
import CpHtmlResource from "components/topic/CpHtmlResource";
import CpIframeResource from "components/topic/CpIframeResource";
import CpImageResource from "components/topic/CpImageResource";
import CpMissingResource from "components/topic/CpMissingResource";
import CpPdfResource from "components/topic/CpPdfResource";
import CpVideoResource from "components/topic/CpVideoResource";
import Log from "services/Log";
import { styles } from "services/Theme";

/**
 * Renders a resource by selecting the correct component based on its type
 */
const CpResource = React.memo(({
  resource,
  resourceNodeRevisionId,
  resourceType,
  style
}) => {
  switch (resourceType) {
    case "Resource::Form":
      return <CpFormResource resource={resource} resourceNodeRevisionId={resourceNodeRevisionId} style={style} />;

    case "Resource::Handlebars":
      return <CpHandlebarResource resource={resource} style={style} />;

    case "Resource::Html":
      return <_StyledCpHtmlResource resource={resource} style={style} $_css={styles.paddingHorizontalDouble} />;

    case "Resource::Iframe":
      return <CpIframeResource resource={resource} style={style} />;

    case "Resource::Image":
      return <CpImageResource resource={resource} style={style} />;

    case "Resource::Pdf":
      return <CpPdfResource resource={resource} style={style} />;

    case "Resource::Video":
      return <CpVideoResource resource={resource} style={style} />;

    default:
      Log.error(`Unable to find a component to render resource type ${resourceType}`);
      return <CpMissingResource style={style} />;
  }
});
export default CpResource;

var _StyledCpHtmlResource = _styled(CpHtmlResource).withConfig({
  displayName: "CpResource___StyledCpHtmlResource",
  componentId: "sc-iq5881-0"
})(["", ""], p => p.$_css);