import React, { useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"

import CpPagination from "components/common/CpPagination"
import useScreenLayout from "hooks/useScreenLayout"

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

/**
 * Custom PDF renderer in case the browser doesn't support displaying PDFs in an iframe natively
 * (ex. Safari on iPad - https://stackoverflow.com/a/20553911)
 */
const CpPdfResourceViewer: React.FC<{ uri: string }> = ({ uri }) => {
  const [pageCount, setPageCount] = useState<number>(1)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [width, setWidth] = useState<number | undefined>(undefined)
  const { width: screenWidth } = useScreenLayout()

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPageCount(numPages)
  }

  function onPageLoad({ originalWidth }: { originalWidth: number }): void {
    setWidth(Math.min(originalWidth, screenWidth))
  }

  return (
    <>
      <Document file={uri} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} width={width} onLoadSuccess={onPageLoad} />
      </Document>
      <CpPagination
        currentPage={pageNumber}
        onPageChange={setPageNumber}
        pageSize={1}
        totalEntries={pageCount}
      />
    </>
  )
}

export default CpPdfResourceViewer
