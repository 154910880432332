import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconUnderstanding: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M159 249h-22.7c-10.06-12.66-18.78-25.19-29.13-36.17C76.41 180.18 69.6 131.91 91.14 93c21.89-39.57 66-59.4 111.52-50.13 41.08 8.37 73.62 45.26 78.12 88.75 3.26 31.57-6.72 58.44-28.2 81.68C242.63 224 234 236 223.33 250h-21.87c-1.28-12.77 2.58-22.73 8.92-31.36 6.67-9.07 14.56-17.25 22.17-25.6 20.6-22.62 26.71-48.68 15.77-77.13-11.18-29.06-33.94-45.47-64.72-47.26-28.27-1.64-51.06 11.17-65.91 35.47-14.56 23.8-15.31 49-1.23 73.23 5.83 10 14.83 18.26 22.54 27.18 10.85 12.56 20.74 25.47 20 44.47zM227.84 258c1.37 19.26-.27 36.45-18.07 46.7-13.13 7.57-25.64 21-42.93 10.95C135.38 297.29 130 287.76 133 258z" />
      <Path d="M137.57 146h-14.14c-3.37-31 20.6-55.46 57.13-57.9.23 4.86.47 9.7.74 15.27-24.74 3.73-40.01 16.63-43.73 42.63z" />
    </Svg>
  )
}

export default IconUnderstanding
