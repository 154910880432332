import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconVideo: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M180.28 272.8c-31.54 0-63.08.11-94.62 0-19-.09-24.64-5.5-24.72-24.35q-.31-68.59 0-137.19c.09-18.64 5.79-24 25-24q93.83-.17 187.66 0c20.07 0 25.39 5.22 25.46 25.08q.22 67.82 0 135.62c-.07 19.57-5.64 24.78-25.77 24.85-30.99.07-62 0-93.01-.01zm-33.05-89.05c0 8.91-.14 17.82 0 26.73.17 8.54 4 11.76 12 7.21 16-9 31.92-18 47.68-27.4 6.69-4 7-9-.07-13.08q-23.73-13.89-47.68-27.42c-8.09-4.6-11.79-1.26-11.95 7.22-.12 8.91.02 17.83.02 26.74zM80.41 106c-7.49-1.65-9.12 2.71-9.5 9.78-.48 8.81 3.75 10.49 11.35 10.53 7.44 0 10.19-2.62 10.28-10.11.1-8.57-3.6-11.5-12.13-10.2zm-9 146.11c-2.39 8.52 3.39 9.31 10.56 9.48 6.81.15 10.58-1.56 10.59-9.37 0-7.54-1.9-11.45-10.41-11.24-7.37.13-12.4 1.27-10.77 11.08zm217.64.68c.2-6.13.63-12.1-8.25-11.78-6.27.23-13.17-1.15-12.79 8.8.23 5.9-.46 12 8.44 11.55 6.09-.36 13.44 1.41 12.55-8.62zM159.83 105.85c-7.87-1.24-9.75 2.71-10.06 9.88-.39 8.76 3.6 10.52 11.25 10.54 7.45 0 10.15-2.67 10.22-10.12.08-8.45-3.52-11.47-11.41-10.3zm50.51 11.38c1-7.38-.9-12-9.86-11.67-6.55.23-11.11.83-11.21 9.08-.08 7.54 1.53 12 10.33 11.58 6.24-.32 11.99-.12 10.74-8.99zm-78.85-1c1.29-8.34-2.89-10.66-10.68-10.7-7.44 0-9.77 2.91-10 10.06-.29 8.36 3.23 10.7 11 10.62 7.19-.04 10.81-2.33 9.68-9.95zm117.75-.41c1.59-7.49-2.21-10.31-10.27-10.27-7 0-10.23 2.06-10.39 9.66-.17 8 2.44 11.17 10.62 11 6.89-.14 11.16-1.68 10.04-10.36zM289 116c.95-7.42-2.18-10.23-9.7-10.4-8.47-.2-10.72 3.44-10.75 11.24 0 8.06 3.9 9.44 10.75 9.39 7.33-.09 10.8-2.79 9.7-10.23zM111.37 249c.09 6.48-1.22 12.81 8 12.56 6.67-.18 12.66.16 12.17-9.51-.33-6.38-.54-11.43-9.05-10.95-6.08.27-12.08-.22-11.15 7.82zm39 .64c-.2 6.15-.81 12.32 8.13 11.84 5.56-.29 12.18 1.35 12-7.86-.14-6.29 1.16-13.1-8.93-12.51-6.15.28-12.11-.11-11.25 8.45zm48.71-8.2c-7.58-1.84-8.94 2.95-9.12 9.83-.18 7 1.73 10.46 9.62 10.43 8.13 0 11-2.84 10.86-11.05-.21-7.91-3.44-10.41-11.44-9.29zm49.85 9.15c1.38-6.62-1.56-9.6-9.11-9.65s-11.23 1.73-11.24 10.28 3.67 10.29 11.19 10.31 10.23-3.25 9.11-11.02z" />
    </Svg>
  )
}

export default IconVideo
