/**
 * @generated SignedSource<<2f923266ff7e9cfc88f0ef8daf5bd1f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpSelectSubOrganizationSearchFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly cursor: string;
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"CpOrganizationItem_organization">;
    } | null;
  } | null> | null;
  readonly pageInfo: {
    readonly endCursor: string | null;
    readonly hasNextPage: boolean;
    readonly hasPreviousPage: boolean;
    readonly startCursor: string | null;
  };
  readonly " $fragmentType": "CpSelectSubOrganizationSearchFragment";
};
export type CpSelectSubOrganizationSearchFragment$key = {
  readonly " $data"?: CpSelectSubOrganizationSearchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpSelectSubOrganizationSearchFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpSelectSubOrganizationSearchFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CpOrganizationItem_organization"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PageInfo",
      "kind": "LinkedField",
      "name": "pageInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endCursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPreviousPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startCursor",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationConnection",
  "abstractKey": null
};

(node as any).hash = "1591b6c207e24dd348182f16613fcbcc";

export default node;
