import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { View } from "react-native";
import CpToggleSwitch from "components/common/CpToggleSwitch";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useAppThemeHelpers from "hooks/useAppThemeHelpers";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  darkModeContainer: css`
    justify-content: flex-start;
  `,
  switchContainer: css`
    align-items: center;
    flex-direction: row;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
/**
 * Dark Mode switch for our dark mode
 */

const CpDarkMode = () => {
  const {
    name
  } = useAppTheme();
  const {
    setThemeColor
  } = useAppThemeHelpers();
  const [toggled, setToggled] = useState(name === "dark");
  const handleToggleSwitch = useCallback(() => {
    setThemeColor(toggled ? "light" : "dark");
    setToggled(!toggled);
  }, [setThemeColor, toggled]);
  return <_StyledView $_css={localStyles.darkModeContainer}>
      <_StyledPrText children={translations.labels.colorScheme} $_css2={styles.smallCapsMenuHeader} />
      <_StyledView2 $_css3={localStyles.switchContainer}>
        <CpToggleSwitch onToggle={handleToggleSwitch} toggled={toggled} />
        <PrText children={translations.labels.darkMode} />
      </_StyledView2>
    </_StyledView>;
};

export default CpDarkMode;

var _StyledView = _styled(View).withConfig({
  displayName: "CpDarkMode___StyledView",
  componentId: "sc-16w002v-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpDarkMode___StyledPrText",
  componentId: "sc-16w002v-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpDarkMode___StyledView2",
  componentId: "sc-16w002v-2"
})(["", ""], p => p.$_css3);