import React, { useCallback, useState } from "react"

import CpHeaderButton from "components/common/CpHeaderButton"
import {
  ActionButtonProps,
  ActionButtonUserType,
} from "components/primitives/PrActionButton/PrActionButton"
import { IconName } from "components/primitives/PrIcon"
import useImdSession from "hooks/useImdSession"
import useNavigationRedirect from "hooks/useNavigationRedirect"
import Routes from "navigators/Routes"
import translations from "translations"

interface CpSignoutButtonProps extends ActionButtonProps {
  onDispatchPatientSession?: () => void
  onHideMenuPopup?: () => void
}

/**
 * Signout header action button
 */
const CpSignoutButton: React.FC<CpSignoutButtonProps> = ({
  onDispatchPatientSession,
  onHideMenuPopup,
  onPress,
  type = ActionButtonUserType.AccountMenu,
}) => {
  const { signout, user } = useImdSession()
  const { setPendingUrl } = useNavigationRedirect()

  const [busy, setBusy] = useState(false)

  const userExists = Boolean(user)

  const handleSignoutPress = useCallback(async () => {
    onDispatchPatientSession && onDispatchPatientSession()
    onPress && onPress()

    setBusy(true)

    onHideMenuPopup && onHideMenuPopup()
    await signout()
    setPendingUrl(Routes.signin)
  }, [onDispatchPatientSession, onHideMenuPopup, onPress, setPendingUrl, signout])

  if (!userExists || user?.isPatient) {
    return null
  }

  return (
    <CpHeaderButton
      busy={busy}
      icon={IconName.signOut}
      label={translations.buttons.signout}
      onPress={handleSignoutPress}
      testID="CpSignoutButton"
      type={type}
    />
  )
}

export default CpSignoutButton
