import _styled from "styled-components";
import { useNavigation } from "@react-navigation/core";
import React, { useCallback } from "react";
import { Linking, Pressable } from "react-native";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import { AnalyticsEventName } from "services/Analytics";
import { css } from "services/Theme";
const localStyles = {
  text: css`
    color: ${({
    theme
  }) => theme.textColors.link};
    font-weight: bold;
    text-decoration-color: ${({
    theme
  }) => theme.textColors.link};
  `,
  textHovered: css`
    text-decoration: underline;
  `
};

/**
 * A specialized button specifically for rendering links to other routes or an external URL.
 */
const CpLinkButton = ({
  accessibilityRole = "link",
  children,
  route,
  url,
  ...otherProps
}) => {
  // NB: Typing on this was kind of a hack. Obviously this can be used to navigate to places other than topic.
  const navigation = useNavigation();
  const {
    trackEvent
  } = useAnalytics();
  const handlePress = useCallback(() => {
    if (route) {
      // The link button may be used to navigate to any valid route without arguments. Typing this is annoying, so we just don't
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      navigation.navigate(route);
    }

    if (url) {
      Linking.openURL(url);
      trackEvent({
        data: {
          targetUrl: url
        },
        eventType: AnalyticsEventName.nav_external
      });
    }
  }, [navigation, route, trackEvent, url]);
  return <Pressable accessibilityRole={accessibilityRole} onPress={handlePress} {...otherProps}>
      {({
      hovered
    }) => <_StyledPrText children={children} $_css={[localStyles.text, hovered ? localStyles.textHovered : undefined]} />}
    </Pressable>;
};

export default CpLinkButton;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpLinkButton___StyledPrText",
  componentId: "sc-131eo3-0"
})(["", ""], p => p.$_css);