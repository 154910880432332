import { defineMessages } from "react-intl";
export default defineMessages({
  resource: {
    id: "IqJAaf",
    defaultMessage: "{count, plural, one {Resource} other {Resources}}"
  },
  resourceCount: {
    id: "csPw+Q",
    defaultMessage: "{count} {count, plural, one {Resource} other {Resources}}"
  },
  topic: {
    id: "YAzTw3",
    defaultMessage: "{count, plural, one {Topic} other {Topics}}"
  },
  user: {
    id: "lUonQ4",
    defaultMessage: "{count, plural, one {User} other {Users}}"
  }
});