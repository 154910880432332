import {
  Duration,
  closestIndexTo,
  formatDuration,
  intervalToDuration,
  millisecondsToSeconds,
  sub,
} from "date-fns"

import {
  ActiveSegmentObject,
  CommonTopicResource,
  PatientSessionResource,
  SelectedPatientSessionResource,
  TopicTreeResource,
} from "contexts/CxPatientSession"
import {
  CommonTopicsInput,
  TopicNodesInput,
} from "query/__generated__/QlPackageSessionDataQuery.graphql"
import { QlPatientSessionResourceNodeQuery$data } from "query/__generated__/QlPatientSessionResourceNodeQuery.graphql"
import Log from "services/Log"

/**
 * This service/helper is intended for reusable tasks that are relevant to the use and management of Patient Sessions
 */

/**
 * Ends the most recent activeSegment and returns a frozen ActiveSegmentObject ready for inclusion in the reducer state.
 */
export const endActiveSegments = (
  activeSegments: ReadonlyArray<ActiveSegmentObject>,
): ReadonlyArray<ActiveSegmentObject> => {
  // Capture all but the last segment
  const currentSegments = activeSegments.slice(0, -1)
  const lastSegment = activeSegments[activeSegments.length - 1]
  // End the current active segment upon modal open, if not already ended
  const endSegment = lastSegment?.end ? lastSegment : { ...lastSegment, end: Date.now() }

  return Object.freeze([...currentSegments, endSegment])
}

/**
 * TODO: Unable to format output from `formatDuration` without a workaround.
 * Details - https://github.com/date-fns/date-fns/issues/2134 .
 * Set our own locale with the formatting we want - luckily En/Fr/Es all have the same initials for hours, minutes and seconds
 */
const formatDurationWorkaround = (
  duration: Duration,
  format: Array<"hours" | "minutes" | "seconds">,
): string => {
  const formatDistanceLocales = {
    xHours: "{{count}}h",
    xMinutes: "{{count}}m",
    xSeconds: "{{count}}s",
  }
  // Now override the standard format with our custom locale
  const shortenDurationLabel = {
    formatDistance: (token: keyof typeof formatDistanceLocales, count: number) =>
      formatDistanceLocales[token].replace("{{count}}", count.toString()),
  }

  // Format our interval to only display the minutes and seconds we are interested in (and hours... just in case)
  return formatDuration(duration, {
    format,
    // Here is where we plug in our workaround
    locale: shortenDurationLabel,
  })
}

/**
 * Returns a subset of activeSegments for the currently selected resources.
 */
export const getSelectedActiveSegments = (
  activeSegments: ReadonlyArray<ActiveSegmentObject>,
  selectedResources: ReadonlyArray<SelectedPatientSessionResource>,
): ActiveSegmentObject[] => {
  // Spread array into new variable that ISN'T ReadOnly
  const localActiveSegments = [...activeSegments]

  const firstResource = selectedResources[0]

  if (!firstResource) {
    // If no resources is selected, don't return any active segments
    return []
  } else {
    // If we're calculating session duration based on a selected topic, we need to first find the activeSegment during which that topic was viewed
    const selectedTime = firstResource.time
    let selectedSegmentIndex = localActiveSegments.findIndex(
      ({ end = 0, start = 0 }) =>
        (start <= selectedTime && end >= selectedTime) ||
        start === selectedTime ||
        end === selectedTime,
    )
    if (selectedSegmentIndex < 0) {
      // This scenario is unlikely to happen as the root issue should have been resolved, but keeping it as a safeguard just in case
      Log.warn(
        "PatientSession - getSelectedActiveSegments could not find the index for the selected ActiveSegment based on the selectedTime: ",
        { activeSegments, selectedTime },
      )
      const segmentsArray: Array<number> = []
      localActiveSegments.forEach((segment) => {
        segmentsArray.push(segment.start)
        segment?.end && segmentsArray.push(segment.end)
      })
      // date-fns provides a method that conveniently finds the index of the date closest to a provided date
      const closestIndex = closestIndexTo(selectedTime, segmentsArray)
      if (!closestIndex || closestIndex < 0) {
        // There are no active segments even close... Somehow
        Log.error(
          "PatientSession - getSelectedActiveSegments could not find any activeSegment close to the selectedTime: ",
        )
        selectedSegmentIndex = 0
      } else {
        selectedSegmentIndex = localActiveSegments.findIndex(
          (segment) =>
            segmentsArray[closestIndex] === segment.start ||
            segmentsArray[closestIndex] === segment?.end,
        )
      }
    }

    // Then we replace the segments start time with the time the topic was viewed
    const activeSegment = localActiveSegments[selectedSegmentIndex]
    if (activeSegment) activeSegment.start = selectedTime

    // Finish off by returning the getment list
    return activeSegments.slice(selectedSegmentIndex)
  }
}

/**
 * Returns a subset of selectedResources based on provided viewedResources. Used when determining a PatientSession start point retroactively.
 */
export const getSelectedResourcesFromViewedResources = ({
  nodes,
  selectedResources,
  selectedRevisionId,
  viewedResources,
}: {
  nodes: NonNullable<QlPatientSessionResourceNodeQuery$data["resourceNodesByRevision"]>["nodes"]
  selectedResources: ReadonlyArray<SelectedPatientSessionResource>
  selectedRevisionId: string
  viewedResources: ReadonlyArray<PatientSessionResource>
}): Array<{
  isTopic: boolean
  revisionId: string
  selected: boolean
  time: number
}> => {
  // Find the selected topic from our viewedResources
  const selectedIndex = viewedResources.findIndex(
    ({ revisionId }) => revisionId === selectedRevisionId,
  )
  // Create a subset of viewedResources (from 'selected' to most recent)
  return viewedResources
    .slice(selectedIndex) // capture all resources that came after the selected topic
    .map((resource) => {
      // Use existing 'selected' status if it exists, otherwise only the resources should be 'selected'
      const currentSelectedResource = selectedResources.find(
        ({ revisionId }) => revisionId === resource.revisionId,
      )
      const currentNode = nodes?.find((node) => node?.revisionId === resource.revisionId)
      const isTopic =
        currentSelectedResource?.isTopic ?? currentNode?.rootRevisionId === currentNode?.revisionId
      return {
        ...resource,
        isTopic,
        selected: currentSelectedResource ? currentSelectedResource.selected : !isTopic,
      }
    })
}

/**
 * Returns a human-readable value of the total duration of a PatientSession based on all active segments
 */
export const getSessionDuration = (
  segments: ReadonlyArray<ActiveSegmentObject>,
  asInteger = false,
): number | string => {
  // The difference between each `start` and `end`, added together to get session duration
  const segmentTotal = segments.reduce((prevValue, { end, start }) => {
    let segmentDuration = prevValue
    if (end) {
      segmentDuration += end - start
    } else {
      segmentDuration += Date.now() - start
    }
    return segmentDuration
  }, 0)

  // Acquire a duration object by subtracting the duration from the time right now
  const duration = intervalToDuration({
    end: Date.now(),
    start: sub(Date.now(), { seconds: millisecondsToSeconds(segmentTotal) }),
  })
  // Return the formatted duration, or the segmentTotal if we want the integer value
  return asInteger
    ? segmentTotal
    : formatDurationWorkaround(duration, ["hours", "minutes", "seconds"])
}

/**
 * Returns a human-readable value of the distance between now and an earlier time
 */
export const getTimeFromNow = (time: number): string => {
  const duration = intervalToDuration({
    end: Date.now(),
    start: time,
  })
  return formatDurationWorkaround(duration, ["hours", "minutes", "seconds"])
}

/**
 * Given packageResources from PatientSessionContextData, creates Graphql query ready input value groups for commonTopics and topicNodes
 */
export const getPackageInputGroups = (
  packageResources: ReadonlyArray<CommonTopicResource | TopicTreeResource>,
  organizationId: string,
): { commonTopicsInput: CommonTopicsInput; topicNodesInput: Array<TopicNodesInput> } => {
  // Get a mutable copy of the packageResources
  const currentPackages = [...packageResources]

  const selectedCommonTopics = currentPackages.filter(
    (resource) => resource.type === "commonTopicsSection",
  ) as unknown as Array<CommonTopicResource>
  // Map through and collect the ids for any common topics that may have been selected
  const commonTopicsInput: CommonTopicsInput = {
    ids: selectedCommonTopics.map(({ commonTopicId, revisionId }) => ({
      commonTopicId,
      resourceNodeRevisionId: revisionId,
    })),
    organizationId,
  }

  // Reduce topic node selections to group relevant nodes by their tree id
  const topicNodesInput = currentPackages.reduce(
    (
      prevValue: Array<{
        // Explicitly typing prevValue to allow for pushing revisionIds below
        resourceNodeRevisionIds: Array<string>
        topicTreeId: string
      }>,
      resource,
    ) => {
      if (resource.type === "topicSection") {
        const { revisionId, topicTreeId } = resource
        // Find the topicNode group based on the topicTreeId
        const currentIndex = prevValue.findIndex(
          (topicGroup) => topicGroup.topicTreeId === topicTreeId,
        )
        const isInPrevValue = currentIndex >= 0

        // Either append to existing group, or create a new entry for the topicTree
        const currentGroup = isInPrevValue
          ? prevValue[currentIndex]
          : {
              resourceNodeRevisionIds: [],
              topicTreeId,
            }

        // Then add the resource to the list of ids and update the group values
        currentGroup.resourceNodeRevisionIds.push(revisionId)
        if (isInPrevValue) prevValue[currentIndex] = currentGroup
        else prevValue.push(currentGroup)
      }
      return prevValue
    },
    [],
  )

  return { commonTopicsInput, topicNodesInput }
}
