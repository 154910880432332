import React from "react"
import Svg, { G, Path, SvgProps } from "react-native-svg"

// Iconset: Material - language
const Language: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} x="20" {...otherProps}>
      {/* Icon is off center, so shift it over 1px */}
      <G x="1">
        <Path d="M14.985,0 C6.705,0 0,6.72 0,15 C0,23.28 6.705,30 14.985,30 C23.28,30 30,23.28 30,15 C30,6.72 23.28,0 14.985,0 L14.985,0 Z M25.38,9 L20.955,9 C20.475,7.125 19.785,5.325 18.885,3.66 C21.645,4.605 23.94,6.525 25.38,9 L25.38,9 Z M15,3.06 C16.245,4.86 17.22,6.855 17.865,9 L12.135,9 C12.78,6.855 13.755,4.86 15,3.06 L15,3.06 Z M3.39,18 C3.15,17.04 3,16.035 3,15 C3,13.965 3.15,12.96 3.39,12 L8.46,12 C8.34,12.99 8.25,13.98 8.25,15 C8.25,16.02 8.34,17.01 8.46,18 L3.39,18 L3.39,18 Z M4.62,21 L9.045,21 C9.525,22.875 10.215,24.675 11.115,26.34 C8.355,25.395 6.06,23.49 4.62,21 L4.62,21 Z M9.045,9 L4.62,9 C6.06,6.51 8.355,4.605 11.115,3.66 C10.215,5.325 9.525,7.125 9.045,9 L9.045,9 Z M15,26.94 C13.755,25.14 12.78,23.145 12.135,21 L17.865,21 C17.22,23.145 16.245,25.14 15,26.94 L15,26.94 Z M18.51,18 L11.49,18 C11.355,17.01 11.25,16.02 11.25,15 C11.25,13.98 11.355,12.975 11.49,12 L18.51,12 C18.645,12.975 18.75,13.98 18.75,15 C18.75,16.02 18.645,17.01 18.51,18 L18.51,18 Z M18.885,26.34 C19.785,24.675 20.475,22.875 20.955,21 L25.38,21 C23.94,23.475 21.645,25.395 18.885,26.34 L18.885,26.34 Z M21.54,18 C21.66,17.01 21.75,16.02 21.75,15 C21.75,13.98 21.66,12.99 21.54,12 L26.61,12 C26.85,12.96 27,13.965 27,15 C27,16.035 26.85,17.04 26.61,18 L21.54,18 L21.54,18 Z" />
      </G>
    </Svg>
  )
}

export default Language
