import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconPregnancy: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M311.32 145.43c-1.4-32.8-12.4-62.4-40.9-83.4-36.2-26.7-86.7-21.7-120.6 9.7-10.8 10-20 21.6-25.6 35.2-10.2 24.8-26.9 44.9-43.7 65.1a315.32 315.32 0 00-23.2 31.8c-23.1 36.6 2.8 95.6 45.6 107.2 25.4 6.9 49.9 4.8 74.5-2.3 41.1-11.9 74.9-34.7 100.7-68.7 20.88-27.4 32.3-58.5 33.2-94.6zM186 173.53c-3.2-9.5-5.9-16.7-8.1-24.1-.6-2.1-.2-4.5-.2-6.8.1-4.7 1-9.4.2-14-2.6-16.2 6.5-36.1 20.4-43.6 22.1-12 54.4-1.6 65.4 20.9 7.9 16.2 5 35.1-8.2 48.5-6 6.1-11.9 12.4-10 21.4 4.5 21.4-2.5 40.1-11.4 58.9-11.4 24-31.3 38.2-54.7 47.9-16.7 6.9-35.4-2.9-40.6-20.3-1.9-6.4-2.1-13.3-3.1-20-.4-3-.6-6-1-10.6-6.3 5-11.5 8.7-16.1 13-4.4 4.2-8.2 9-12.3 13.6-12.3-5.5-19.3-14-23.9-24.5-4.2-9.5-2.7-14.2 7.1-22.4 3.8 5.8 7.6 11.6 11.7 17.9 8-11.5 14.7-21.6 21.9-31.4 7-9.5 14.5-18.7 29.6-15.6-8.8-11.4-22.7-15.6-27.8-28.6 10.9-5.6 10.9-5.6 27.4-2.2 5.52 15 20.32 15.3 33.7 22z" />
    </Svg>
  )
}

export default IconPregnancy
