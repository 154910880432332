/**
 * @generated SignedSource<<33292c94f1c15747f50dc5a86a71c65e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlRelatedTopicsQuery$variables = {
  id: string;
  locale?: Locale | null;
};
export type QlRelatedTopicsQuery$data = {
  readonly node: {
    readonly children?: ReadonlyArray<{
      readonly icon: string | null;
      readonly names: any;
      readonly relatedNode: {
        readonly icon: string | null;
      } | null;
      readonly resourceNodeRevisionId: string | null;
      readonly revisionId: string;
      readonly type: string;
    }>;
  } | null;
};
export type QlRelatedTopicsQuery = {
  response: QlRelatedTopicsQuery$data;
  variables: QlRelatedTopicsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QlRelatedTopicsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceNode",
                    "kind": "LinkedField",
                    "name": "relatedNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ResourceNode",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QlRelatedTopicsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceNode",
                    "kind": "LinkedField",
                    "name": "relatedNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ResourceNode",
            "abstractKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e70dad0b0635718e2c7696a9a01dedea",
    "id": null,
    "metadata": {},
    "name": "QlRelatedTopicsQuery",
    "operationKind": "query",
    "text": "query QlRelatedTopicsQuery(\n  $id: ID!\n  $locale: Locale\n) {\n  node(id: $id) {\n    __typename\n    ... on ResourceNode {\n      children {\n        icon(locale: $locale)\n        names(locale: $locale)\n        resourceNodeRevisionId\n        relatedNode {\n          icon(locale: $locale)\n          id\n        }\n        revisionId\n        type\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "267842223610ac1571df838e0d2fe2fa";

export default node;
