import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconFamily: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M240.76 307.38h-3a4 4 0 01-4-4v-23.57-36.77l-3.3-3.26c-7.37-7.3-14.41-14.26-21-25.16a4 4 0 01.49-5.07l3-3 .18-.16a4 4 0 015.62.34 153.17 153.17 0 0010.81 11.16 64.33 64.33 0 008.64 6.79 37.84 37.84 0 019.42-1.24 36.22 36.22 0 0111.09 1.72 4 4 0 011.91 1.2 85 85 0 0112.78 19.3 114.31 114.31 0 018.32 23.89 4 4 0 01-1.74 4.27l-3.32 2.11a3.85 3.85 0 01-1.09.6 4 4 0 01-5.11-2.42 131.15 131.15 0 00-6.71-15.85c-.63-1.23-1.29-2.43-2-3.61v48.72a4 4 0 01-4 4h-3a4 4 0 01-4-3.82l-.75-18.17h-4.38l-.81 17.88v.12a4 4 0 01-4 4z" />
      <Path d="M267.46 198.65c-1.53 9.54-5.6 16-10.56 19.49a15.46 15.46 0 01-18.17 0c-5-3.44-9-9.95-10.56-19.49-1.6-10 2.44-16.92 8.5-20.64A21.76 21.76 0 01259 178c6.06 3.72 10.1 10.62 8.5 20.64zM249.31 183a6.15 6.15 0 00-1.21 2.37 3.38 3.38 0 000 1.78 1.39 1.39 0 00.63.82 4 4 0 002 .39 3 3 0 010 6 9.74 9.74 0 01-5-1.24 7.27 7.27 0 01-3.33-4.22 9.3 9.3 0 01-.09-4.86 10.62 10.62 0 00-1.42.74c-3.49 2.14-5.78 6.31-4.78 12.56 1.15 7.18 3.91 11.87 7.21 14.16a7.63 7.63 0 009.11 0c3.3-2.29 6.06-7 7.21-14.16 1-6.25-1.29-10.42-4.78-12.56a13 13 0 00-5.49-1.8z" />
      <Path
        d="M143.4 52a24.07 24.07 0 1024 24.07 24 24 0 00-24-24.07zm27.43 115.36V297a11 11 0 01-11 11h-4a11 11 0 01-10.95-10.77l-1.74-78.45h-4.58l-1.77 78.47A11 11 0 01125.61 308h-3.78a11 11 0 01-11-11V145.84l-10 13.21 6.68 35a11 11 0 11-21.51 4.1l-7.6-39.77a11 11 0 012-8.71l25-33.1a10.93 10.93 0 015.17-3.77l3.44-1.38 2.62-.84a34.69 34.69 0 00-.07 9.81 55.69 55.69 0 004.88 16.12 9.74 9.74 0 00-2.6 3.44 11.19 11.19 0 00-.51 7.46l4.33 17.12a3 3 0 003.63 2.19 2.27 2.27 0 00.46-.16l2.67-1a3 3 0 00-1.77-5.72L124.16 146a5.35 5.35 0 01.16-3.53 3.76 3.76 0 012.07-1.95 3.71 3.71 0 012.85.08 5.32 5.32 0 012.45 2.53l6.54 12.73a3 3 0 002.44 5.47l2.8-1.06a3 3 0 001.75-3.87c-.05-.12-.1-.24-.16-.36L137 140.33a11.18 11.18 0 00-5.33-5.26 9.59 9.59 0 00-4.67-.82 49.94 49.94 0 01-4.5-14.61 26.22 26.22 0 01.76-11.17l6.06-1.93h.39l10.46 15.19v-7.59l3-3 3 3 1.3 7.14 6.14-14.87 11.77 4.59-6 15.32h-.64a9 9 0 106.14 2.42l6.12-15.57 3.35 1.29.45.27a11 11 0 015.11 7l13.25 43.69 17.58 24.22A11 11 0 11193 202.55l-18.42-25.38a10.92 10.92 0 01-1.89-3.63l-1.87-6.18z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconFamily
