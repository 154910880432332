import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpLinkButton from "components/common/CpLinkButton";
import CpModal from "components/common/CpModal";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useDrawingToolsContext from "hooks/useDrawingToolsContext";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  buttonContainer: css`
    flex-direction: row;
    justify-content: flex-end;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
    width: 100%;
  `,
  buttonRightSpacing: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  disclaimer: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  mobileButtonSpacing: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const localTranslations = defineMessages({
  clearedCanvas: {
    id: "GNBQ6h",
    defaultMessage: "Your drawing has been cleared and saved, you can start a new drawing now."
  },
  exitWithoutSaving: {
    id: "xh1DE8",
    defaultMessage: "Exit without saving"
  },
  exitWithoutSavingMessage: {
    id: "lyZDFX",
    defaultMessage: "You have unsaved changes in your drawing. Are you sure you want to exit?"
  },
  noPatientSession: {
    id: "/+b+vn",
    defaultMessage: "You do not have a Patient Education Session in progress. Should you start one after closing this warning your drawing may be lost. To prevent any potential loss, start a session using the button below where you'll be asked to select your start point from your recent history."
  },
  saveAndExit: {
    id: "ok/0+L",
    defaultMessage: "Save and exit"
  },
  saveError: {
    id: "lQiTU6",
    defaultMessage: "We were not able to save your drawing."
  },
  startSessionAndSave: {
    id: "/Uzn5t",
    defaultMessage: "Save and start session"
  },
  successfulSaving: {
    id: "cfqDyf",
    defaultMessage: "Your drawing has been saved, and is ready to be included in your current Patient Session. You can update or clear your image from this page."
  },
  unsavedChanges: {
    id: "miLJAH",
    defaultMessage: "Unsaved changes"
  },
  warning: {
    id: "p6dIS1",
    defaultMessage: "Warning"
  }
});
export let CpDrawingModalState;

(function (CpDrawingModalState) {
  CpDrawingModalState["Error"] = "Error";
  CpDrawingModalState["Success"] = "Success";
  CpDrawingModalState["SaveAndExitWarning"] = "SaveAndExitWarning";
  CpDrawingModalState["SessionWarning"] = "SessionWarning";
})(CpDrawingModalState || (CpDrawingModalState = {}));

/**
 * Modal for the drawing tools save error handling or the exit without saving warning
 * Also handles the warning about when a user tries to save without starting a patient session
 */
const CpDrawingModal = ({
  closeModalOnConfirm,
  isBusy,
  isOpen,
  onClose,
  onCloseFullScreen,
  onExitWithoutSaving,
  onSave,
  onSubmit,
  state
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    drawingToolsData: {
      uploadedImage
    }
  } = useDrawingToolsContext();
  const {
    dispatchPatientSession
  } = usePatientSessionContext();
  const handleStartSessionAndSave = useCallback(() => {
    onSave();
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionStartFromHistory
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession, onSave]);
  const handleSaveAfterWarning = useCallback(() => {
    onSave();
  }, [onSave]);
  const title = useMemo(() => {
    switch (state) {
      case CpDrawingModalState.Error:
        return formatMessage(translations.labels.error);

      case CpDrawingModalState.SaveAndExitWarning:
        return formatMessage(localTranslations.unsavedChanges);

      case CpDrawingModalState.SessionWarning:
        return formatMessage(localTranslations.warning);

      case CpDrawingModalState.Success:
        return formatMessage(translations.labels.success);

      default:
        return formatMessage(localTranslations.unsavedChanges);
    }
  }, [formatMessage, state]);
  const message = useMemo(() => {
    switch (state) {
      case CpDrawingModalState.Error:
        return formatMessage(localTranslations.saveError);

      case CpDrawingModalState.SaveAndExitWarning:
        return formatMessage(localTranslations.exitWithoutSavingMessage);

      case CpDrawingModalState.SessionWarning:
        return formatMessage(localTranslations.noPatientSession);

      case CpDrawingModalState.Success:
        return uploadedImage ? formatMessage(localTranslations.successfulSaving) : formatMessage(localTranslations.clearedCanvas);

      default:
        return formatMessage(localTranslations.exitWithoutSavingMessage);
    }
  }, [formatMessage, state, uploadedImage]);
  const renderButtons = useMemo(() => {
    switch (state) {
      case CpDrawingModalState.Error:
        return <_StyledPrButton onPress={onClose} testID="CpDrawingModalConfirmButton" $_css={[isWideScreen ? undefined : localStyles.mobileButtonSpacing]}>
            <_StyledPrText children={translations.buttons.continue} $_css2={localStyles.buttonText} />
          </_StyledPrButton>;

      case CpDrawingModalState.SaveAndExitWarning:
        return <>
            <_StyledPrButton2 color="destructive" onPress={onExitWithoutSaving} testID="CpDrawingModalExitButton" type="text" $_css3={localStyles.buttonRightSpacing}>
              <_StyledPrText2 children={localTranslations.exitWithoutSaving} $_css4={localStyles.buttonText} />
            </_StyledPrButton2>
            <PrButton busy={isBusy} onPress={onSubmit} testID="CpDrawingModalSaveButton">
              <_StyledPrText3 children={localTranslations.saveAndExit} $_css5={localStyles.buttonText} />
            </PrButton>
          </>;

      case CpDrawingModalState.SessionWarning:
        return <>
            <_StyledPrButton3 busy={isBusy} onPress={handleSaveAfterWarning} testID="CpDrawingModalCancelButton" type="text" $_css6={localStyles.buttonRightSpacing}>
              <_StyledPrText4 children={translations.buttons.continue} $_css7={localStyles.buttonText} />
            </_StyledPrButton3>
            <PrButton busy={isBusy} onPress={handleStartSessionAndSave} testID="CpDrawingModalStartSessionAndSaveButton">
              <_StyledPrText5 children={formatMessage(localTranslations.startSessionAndSave)} $_css8={localStyles.buttonText} />
            </PrButton>
          </>;

      case CpDrawingModalState.Success:
        return <_StyledPrButton4 onPress={closeModalOnConfirm ? onCloseFullScreen : onClose} testID="CpDrawingModalConfirmButton" $_css9={[isWideScreen ? undefined : localStyles.mobileButtonSpacing]}>
            <_StyledPrText6 children={translations.buttons.continue} $_css10={localStyles.buttonText} />
          </_StyledPrButton4>;

      default:
        return null;
    }
  }, [closeModalOnConfirm, formatMessage, handleSaveAfterWarning, handleStartSessionAndSave, isBusy, isWideScreen, onClose, onCloseFullScreen, onExitWithoutSaving, onSubmit, state]);
  return <_StyledCpModal onBackgroundPress={onClose} visible={isOpen} $_css11={styles.marginHorizontalSingle}>
      <_StyledView testID="CpDrawingModalModal" $_css12={styles.modalContainer}>
        <_StyledPrText7 accessibilityRole="header" children={title} $_css13={styles.modalHeaderText} />
        <View>
          <_StyledPrText8 children={state === CpDrawingModalState.Error ? formatMessage(translations.errors.customError, {
          customError: message,
          supportEmail: <CpLinkButton url="mailto:support@imdhealth.com">
                        <PrText>support@imdhealth.com</PrText>
                      </CpLinkButton>
        }) : message} $_css14={localStyles.disclaimer} />
        </View>
        <_StyledView2 $_css15={localStyles.buttonContainer}>{renderButtons}</_StyledView2>
      </_StyledView>
    </_StyledCpModal>;
};

export default CpDrawingModal;

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpDrawingModal___StyledPrButton",
  componentId: "sc-jum1cc-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText",
  componentId: "sc-jum1cc-1"
})(["", ""], p => p.$_css2);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpDrawingModal___StyledPrButton2",
  componentId: "sc-jum1cc-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText2",
  componentId: "sc-jum1cc-3"
})(["", ""], p => p.$_css4);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText3",
  componentId: "sc-jum1cc-4"
})(["", ""], p => p.$_css5);

var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpDrawingModal___StyledPrButton3",
  componentId: "sc-jum1cc-5"
})(["", ""], p => p.$_css6);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText4",
  componentId: "sc-jum1cc-6"
})(["", ""], p => p.$_css7);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText5",
  componentId: "sc-jum1cc-7"
})(["", ""], p => p.$_css8);

var _StyledPrButton4 = _styled(PrButton).withConfig({
  displayName: "CpDrawingModal___StyledPrButton4",
  componentId: "sc-jum1cc-8"
})(["", ""], p => p.$_css9);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText6",
  componentId: "sc-jum1cc-9"
})(["", ""], p => p.$_css10);

var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CpDrawingModal___StyledCpModal",
  componentId: "sc-jum1cc-10"
})(["", ""], p => p.$_css11);

var _StyledView = _styled(View).withConfig({
  displayName: "CpDrawingModal___StyledView",
  componentId: "sc-jum1cc-11"
})(["", ""], p => p.$_css12);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText7",
  componentId: "sc-jum1cc-12"
})(["", ""], p => p.$_css13);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpDrawingModal___StyledPrText8",
  componentId: "sc-jum1cc-13"
})(["", ""], p => p.$_css14);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpDrawingModal___StyledView2",
  componentId: "sc-jum1cc-14"
})(["", ""], p => p.$_css15);