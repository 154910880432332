import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconChild: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M186.66 73.79c18.71 3.31 29.27 21.2 27.47 38.56 11.9-.28 2.3 18.9-4 15-12.69 28.79-46.79 29-59.62 0-6.34 3.94-15.94-15.25-4-15-1.61-15.57 6.81-31.9 22.38-37.25-5-7-1.72-18.32 4.83-21.39a10.43 10.43 0 015.64-1.71 8.7 8.7 0 015 1.65c5.46 4 4.33 12.58-.37 11.25a3 3 0 01-1.99-3.71 2.53 2.53 0 00-1.08-2.69c-1.75-1.25-4.85 0-5.44 2.08s.36 4.8 1.75 6.79a17.39 17.39 0 009.47 6.43zM180.37 258h-4c-1.64 0-3 2.5-3 7 0 11.64.2 33.71-2.28 37.55-2.1 3.28-7 5.45-13.82 5.45h-17.1c-14.59 0-11.09-13.85-6-16 11.23-4.78 10.72-2.45 11.2-6.12.23-1.81-1.41-56.84 2.59-88.95a3 3 0 00-5.34-2.25c-8 9.82-12.61 21.85-17 35.15-2.94 8.88-12.58 9.28-18.2 4.63-4-3.35-3.25-8.49-2.06-12.81 8.49-31.05 29.11-54.63 44.93-63.47 3.39-1.89 8.24-3.61 10.86-1.84a33.85 33.85 0 0038.5 0c2.62-1.77 7.47-.05 10.86 1.84 15.82 8.84 36.43 32.42 44.93 63.47 1.18 4.32 2 9.46-2.06 12.81-5.62 4.65-15.26 4.25-18.2-4.63-4.41-13.3-9-25.33-17-35.15a3 3 0 00-5.35 2.25c4 32.11 2.54 87.14 2.77 88.95.48 3.67 0 1.34 11.2 6.12 5.06 2.15 8.55 16-6 16h-17.28c-6.86 0-11.71-2.17-13.82-5.45-2.47-3.84-2.27-25.91-2.27-37.55 0-4.5-1.39-7-3-7zm-.09-41.33a8 8 0 11-8 8 8 8 0 018-8zm0-33.5a8 8 0 11-8 8 8 8 0 018-8zm4.17-97.61c-15-2.54-26.22 8.42-26.22 23.08 0 8.26 4 17.39 8.58 22.21 7.73 8.17 19.18 8.19 26.91 0 4.58-4.85 8.61-13.94 8.61-22.21 0-10.7-6.45-21.14-17.88-23.08z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconChild
