import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconEar: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M182 27.9c47.3-.2 86.2 33.7 95 82.8.6 3.2 1.3 6.4 1.6 9.6.7 7.2-3.2 12.9-9.8 14.6s-12.3-1.8-14.7-9.5c-1.4-4.7-2-9.6-3.1-14.4-8.4-36.3-34.2-58.1-68.9-58-37 0-65.5 23.6-73.2 60.2-5.1 24.6-.3 47.8 10.4 69.8 5.9 12.2 13.8 23.5 21.2 34.9 4.7 7.3 10.2 14.2 15.4 21.2 9.3 12.5 15.2 26 15.9 42.1.8 17.5 8 23.7 25.8 25.1a8.6 8.6 0 011.6.1c24.4 1.4 28.8-2.6 30.2-26.9.3-4.3-.1-9.4 2.1-12.6 2.4-3.6 7.2-7.3 11.3-7.8 5.6-.6 10.7 3.4 11.4 9.6 1.8 17.1.8 33.8-10.5 48.2a17.14 17.14 0 01-2.2 2.5c-17.6 15.6-38.3 14.2-58.8 9.6-19.2-4.3-34-17.2-34.7-36.7-.9-24.7-14.5-41.5-27.7-59.3-13.4-18.1-24.6-37.3-31.7-58.7-7.9-23.8-9.8-48.3-3.4-72.5C96.48 58 136.48 28.1 182 27.9z" />
      <Path d="M215.18 188.4c5.2 9.6 13.4 12.5 21.3 16.2 3.2 1.5 7.3 6.6 6.7 9.2-1.2 5.7-7 6.2-12.1 4.5-17.8-5.7-31.2-16.8-35.2-35.4-4-18.8-2.5-37.2 16-49.4 1.8-1.2 3.7-2.4 5.2-3.4-10.1-15.6-19.9-20.3-33.1-16.7-14 3.8-22.7 14.6-22.2 30.1.3 8.5 2.7 17 5.2 25.2 1.4 4.5 4.8 8.5 7.4 12.7 5.6 9.1 5.9 14.1.6 17-7.5 4.1-11.1-1.9-14.6-6.5-8.9-12-15-25.3-17.2-40.2-3.5-24.1 12.3-48.7 35.8-55.8 21.1-6.3 45.7 6.8 56 29.1 9.2 19.8 5 37-8.8 52.7-2.9 3.8-6.8 6.8-11 10.7z" />
    </Svg>
  )
}

export default IconEar
