import React from "react"
import { SvgProps } from "react-native-svg"

import useAppTheme from "hooks/useAppTheme"
import Log from "services/Log"

// Icon imports
// Publicly Available Icons (e.g. Feather, Material, FontAwesome, etc.)
import Add from "components/primitives/PrIcon/icons/Add"
import ArrowDropDown from "components/primitives/PrIcon/icons/ArrowDropDown"
import ArrowDropUp from "components/primitives/PrIcon/icons/ArrowDropUp"
import Brush from "components/primitives/PrIcon/icons/Brush"
import CancelCircle from "components/primitives/PrIcon/icons/CancelCircle"
import Check from "components/primitives/PrIcon/icons/Check"
import CheckCircle from "components/primitives/PrIcon/icons/CheckCircle"
import CheckboxChecked from "components/primitives/PrIcon/icons/CheckboxChecked"
import CheckboxUnchecked from "components/primitives/PrIcon/icons/CheckboxUnchecked"
import Close from "components/primitives/PrIcon/icons/Close"
import Cogs from "components/primitives/PrIcon/icons/Cogs"
import ColorLens from "components/primitives/PrIcon/icons/ColorLens"
import DoneAll from "components/primitives/PrIcon/icons/DoneAll"
import Download from "components/primitives/PrIcon/icons/Download"
import EditPencil from "components/primitives/PrIcon/icons/EditPencil"
import Eraser from "components/primitives/PrIcon/icons/Eraser"
import Error from "components/primitives/PrIcon/icons/Error"
import EyeClosed from "components/primitives/PrIcon/icons/EyeClosed"
import EyeOpen from "components/primitives/PrIcon/icons/EyeOpen"
import FolderOpen from "components/primitives/PrIcon/icons/FolderOpen"
import Fullscreen from "components/primitives/PrIcon/icons/Fullscreen"
import FullscreenExit from "components/primitives/PrIcon/icons/FullscreenExit"
import Home from "components/primitives/PrIcon/icons/Home"
import Info from "components/primitives/PrIcon/icons/Info"
import KeyboardArrowDown from "components/primitives/PrIcon/icons/KeyboardArrowDown"
import KeyboardArrowLeft from "components/primitives/PrIcon/icons/KeyboardArrowLeft"
import KeyboardArrowRight from "components/primitives/PrIcon/icons/KeyboardArrowRight"
import KeyboardArrowUp from "components/primitives/PrIcon/icons/KeyboardArrowUp"
import Language from "components/primitives/PrIcon/icons/Language"
import List from "components/primitives/PrIcon/icons/List"
import Mail from "components/primitives/PrIcon/icons/Mail"
import Menu from "components/primitives/PrIcon/icons/Menu"
import Moon from "components/primitives/PrIcon/icons/Moon"
import More from "components/primitives/PrIcon/icons/More"
import Organization from "components/primitives/PrIcon/icons/Organization"
import Play from "components/primitives/PrIcon/icons/Play"
import RadioChecked from "components/primitives/PrIcon/icons/RadioChecked"
import RadioUnchecked from "components/primitives/PrIcon/icons/RadioUnchecked"
import Redo from "components/primitives/PrIcon/icons/Redo"
import Save from "components/primitives/PrIcon/icons/Save"
import Search from "components/primitives/PrIcon/icons/Search"
import SignIn from "components/primitives/PrIcon/icons/SignIn"
import SignOut from "components/primitives/PrIcon/icons/SignOut"
import Undo from "components/primitives/PrIcon/icons/Undo"
import Verified from "components/primitives/PrIcon/icons/Verified"

// Modified Publicly Available Icons OR Generic Custom Icons
import ArrowRight from "components/primitives/PrIcon/icons/ArrowRight"
import Back from "components/primitives/PrIcon/icons/Back"
import Bookmark from "components/primitives/PrIcon/icons/Bookmark"
import ExternalLink from "components/primitives/PrIcon/icons/ExternalLink"
import Facebook from "components/primitives/PrIcon/icons/Facebook"
import LinkedIn from "components/primitives/PrIcon/icons/LinkedIn"
import LockClosed from "components/primitives/PrIcon/icons/LockClosed"
import MedicalSave from "components/primitives/PrIcon/icons/MedicalSave"
import Pending from "components/primitives/PrIcon/icons/Pending"
import Share from "components/primitives/PrIcon/icons/Share"
import Star from "components/primitives/PrIcon/icons/Star"
import StarOutline from "components/primitives/PrIcon/icons/StarOutline"
import Top from "components/primitives/PrIcon/icons/Top"
import Trash from "components/primitives/PrIcon/icons/Trash"
import Twitter from "components/primitives/PrIcon/icons/Twitter"
import YouTube from "components/primitives/PrIcon/icons/YouTube"

// Custom IMD Icons
import IconAllergies from "components/primitives/PrIcon/icons/IconAllergies"
import IconArm from "components/primitives/PrIcon/icons/IconArm"
import IconArticle from "components/primitives/PrIcon/icons/IconArticle"
import IconAsthma from "components/primitives/PrIcon/icons/IconAsthma"
import IconBacteria from "components/primitives/PrIcon/icons/IconBacteria"
import IconBalance from "components/primitives/PrIcon/icons/IconBalance"
import IconBites from "components/primitives/PrIcon/icons/IconBites"
import IconBlood from "components/primitives/PrIcon/icons/IconBlood"
import IconBrain from "components/primitives/PrIcon/icons/IconBrain"
import IconBreastCancer from "components/primitives/PrIcon/icons/IconBreastCancer"
import IconCaffeineTobacco from "components/primitives/PrIcon/icons/IconCaffeineTobacco"
import IconCancer from "components/primitives/PrIcon/icons/IconCancer"
import IconCannabis from "components/primitives/PrIcon/icons/IconCannabis"
import IconCardiogram from "components/primitives/PrIcon/icons/IconCardiogram"
import IconChild from "components/primitives/PrIcon/icons/IconChild"
import IconContent from "components/primitives/PrIcon/icons/IconContent"
import IconDiabetes from "components/primitives/PrIcon/icons/IconDiabetes"
import IconDigitalEnrolment from "components/primitives/PrIcon/icons/IconDigitalEnrolment"
import IconDrugs from "components/primitives/PrIcon/icons/IconDrugs"
import IconEar from "components/primitives/PrIcon/icons/IconEar"
import IconElearning from "components/primitives/PrIcon/icons/IconElearning"
import IconExercise from "components/primitives/PrIcon/icons/IconExercise"
import IconEye from "components/primitives/PrIcon/icons/IconEye"
import IconFamily from "components/primitives/PrIcon/icons/IconFamily"
import IconFaq from "components/primitives/PrIcon/icons/IconFaq"
import IconFaqCircle from "components/primitives/PrIcon/icons/IconFaqCircle"
import IconFemale from "components/primitives/PrIcon/icons/IconFemale"
import IconFemaleReproductive from "components/primitives/PrIcon/icons/IconFemaleReproductive"
import IconFoot from "components/primitives/PrIcon/icons/IconFoot"
import IconGastro from "components/primitives/PrIcon/icons/IconGastro"
import IconGenetics from "components/primitives/PrIcon/icons/IconGenetics"
import IconGoalsSetting from "components/primitives/PrIcon/icons/IconGoalsSetting"
import IconHand from "components/primitives/PrIcon/icons/IconHand"
import IconHead from "components/primitives/PrIcon/icons/IconHead"
import IconHeadache from "components/primitives/PrIcon/icons/IconHeadache"
import IconHealthAssociations from "components/primitives/PrIcon/icons/IconHealthAssociations"
import IconHealthTopics from "components/primitives/PrIcon/icons/IconHealthTopics"
import IconHealthyLifestyle from "components/primitives/PrIcon/icons/IconHealthyLifestyle"
import IconHeart from "components/primitives/PrIcon/icons/IconHeart"
import IconHeartCare from "components/primitives/PrIcon/icons/IconHeartCare"
import IconHypertension from "components/primitives/PrIcon/icons/IconHypertension"
import IconImd from "components/primitives/PrIcon/icons/IconImd"
import IconIvDrugs from "components/primitives/PrIcon/icons/IconIvDrugs"
import IconKidney from "components/primitives/PrIcon/icons/IconKidney"
import IconKnee from "components/primitives/PrIcon/icons/IconKnee"
import IconLaptop from "components/primitives/PrIcon/icons/IconLaptop"
import IconLifestyle from "components/primitives/PrIcon/icons/IconLifestyle"
import IconLiver from "components/primitives/PrIcon/icons/IconLiver"
import IconLungs from "components/primitives/PrIcon/icons/IconLungs"
import IconMale from "components/primitives/PrIcon/icons/IconMale"
import IconMaleReproductive from "components/primitives/PrIcon/icons/IconMaleReproductive"
import IconMedicalSupervision from "components/primitives/PrIcon/icons/IconMedicalSupervision"
import IconMentalHealth from "components/primitives/PrIcon/icons/IconMentalHealth"
import IconMindfulness from "components/primitives/PrIcon/icons/IconMindfulness"
import IconMouth from "components/primitives/PrIcon/icons/IconMouth"
import IconMuscle from "components/primitives/PrIcon/icons/IconMuscle"
import IconNausea1 from "components/primitives/PrIcon/icons/IconNausea1"
import IconNausea2 from "components/primitives/PrIcon/icons/IconNausea2"
import IconNeedle from "components/primitives/PrIcon/icons/IconNeedle"
import IconNerves from "components/primitives/PrIcon/icons/IconNerves"
import IconNewMouth from "components/primitives/PrIcon/icons/IconNewMouth"
import IconNews from "components/primitives/PrIcon/icons/IconNews"
import IconNose from "components/primitives/PrIcon/icons/IconNose"
import IconNotebook from "components/primitives/PrIcon/icons/IconNotebook"
import IconNutrition from "components/primitives/PrIcon/icons/IconNutrition"
import IconOffers from "components/primitives/PrIcon/icons/IconOffers"
import IconOfficeErgonomics from "components/primitives/PrIcon/icons/IconOfficeErgonomics"
import IconOutdoors from "components/primitives/PrIcon/icons/IconOutdoors"
import IconPacing from "components/primitives/PrIcon/icons/IconPacing"
import IconPackageClosed from "components/primitives/PrIcon/icons/IconPackageClosed"
import IconPackageOpen from "components/primitives/PrIcon/icons/IconPackageOpen"
import IconPain from "components/primitives/PrIcon/icons/IconPain"
import IconPancreas from "components/primitives/PrIcon/icons/IconPancreas"
import IconPatientSession from "components/primitives/PrIcon/icons/IconPatientSession"
import IconPatientSessionActive from "components/primitives/PrIcon/icons/IconPatientSessionActive"
import IconPediatrics from "components/primitives/PrIcon/icons/IconPediatrics"
import IconPelvisHipLeg from "components/primitives/PrIcon/icons/IconPelvisHipLeg"
import IconPhone from "components/primitives/PrIcon/icons/IconPhone"
import IconPills from "components/primitives/PrIcon/icons/IconPills"
import IconPregnancy from "components/primitives/PrIcon/icons/IconPregnancy"
import IconProcedure from "components/primitives/PrIcon/icons/IconProcedure"
import IconRadiation from "components/primitives/PrIcon/icons/IconRadiation"
import IconReports from "components/primitives/PrIcon/icons/IconReports"
import IconRheumatology from "components/primitives/PrIcon/icons/IconRheumatology"
import IconRibs from "components/primitives/PrIcon/icons/IconRibs"
import IconSelfCare from "components/primitives/PrIcon/icons/IconSelfCare"
import IconSexuality from "components/primitives/PrIcon/icons/IconSexuality"
import IconShoulder from "components/primitives/PrIcon/icons/IconShoulder"
import IconSkin from "components/primitives/PrIcon/icons/IconSkin"
import IconSleep from "components/primitives/PrIcon/icons/IconSleep"
import IconSmokingCessation from "components/primitives/PrIcon/icons/IconSmokingCessation"
import IconSocialDistancing from "components/primitives/PrIcon/icons/IconSocialDistancing"
import IconSpine from "components/primitives/PrIcon/icons/IconSpine"
import IconStds from "components/primitives/PrIcon/icons/IconStds"
import IconStethoscope from "components/primitives/PrIcon/icons/IconStethoscope"
import IconStress from "components/primitives/PrIcon/icons/IconStress"
import IconSurveys from "components/primitives/PrIcon/icons/IconSurveys"
import IconTablet from "components/primitives/PrIcon/icons/IconTablet"
import IconTestTube from "components/primitives/PrIcon/icons/IconTestTube"
import IconThroat from "components/primitives/PrIcon/icons/IconThroat"
import IconTimeManagement from "components/primitives/PrIcon/icons/IconTimeManagement"
import IconTools from "components/primitives/PrIcon/icons/IconTools"
import IconTopic from "components/primitives/PrIcon/icons/IconTopic"
import IconTravel from "components/primitives/PrIcon/icons/IconTravel"
import IconTreatmentOtc from "components/primitives/PrIcon/icons/IconTreatmentOtc"
import IconTreatmentRx from "components/primitives/PrIcon/icons/IconTreatmentRx"
import IconUnderstanding from "components/primitives/PrIcon/icons/IconUnderstanding"
import IconUrology from "components/primitives/PrIcon/icons/IconUrology"
import IconVascular from "components/primitives/PrIcon/icons/IconVascular"
import IconVideo from "components/primitives/PrIcon/icons/IconVideo"
import IconVirus from "components/primitives/PrIcon/icons/IconVirus"
import IconWaitingRoom from "components/primitives/PrIcon/icons/IconWaitingRoom"
import IconWeightScale from "components/primitives/PrIcon/icons/IconWeightScale"
import IconWhiplash from "components/primitives/PrIcon/icons/IconWhiplash"
import IconXray from "components/primitives/PrIcon/icons/IconXray"

// Menu icons
import IconConsultation from "components/primitives/PrIcon/icons/IconConsultation"
import IconDiagnose from "components/primitives/PrIcon/icons/IconDiagnose"
import IconDiagrams from "components/primitives/PrIcon/icons/IconDiagrams"
import IconRelated from "components/primitives/PrIcon/icons/IconRelated"
import IconTreatment from "components/primitives/PrIcon/icons/IconTreatment"

import ResourceArticle from "components/primitives/PrIcon/icons/ResourceArticle"
import ResourceForm from "components/primitives/PrIcon/icons/ResourceForm"
import ResourceImage from "components/primitives/PrIcon/icons/ResourceImage"
import ResourcePdf from "components/primitives/PrIcon/icons/ResourcePdf"
import ResourceVideo from "components/primitives/PrIcon/icons/ResourceVideo"

export enum IconName {
  add = "add",
  arrowDropDown = "arrowDropDown",
  arrowDropUp = "arrowDropUp",
  arrowRight = "arrowRight",
  article = "article",
  back = "back",
  bookmark = "bookmark",
  brush = "brush",
  cancelCircle = "cancelCircle",
  check = "check",
  checkboxChecked = "checkboxChecked",
  checkboxUnchecked = "checkboxUnchecked",
  checkCircle = "checkCircle",
  close = "close",
  cogs = "cogs",
  colorLens = "colorLens",
  doneAll = "doneAll",
  download = "download",
  editPencil = "editPencil",
  eraser = "eraser",
  error = "error",
  externalLink = "externalLink",
  eyeClosed = "eyeClosed",
  eyeOpen = "eyeOpen",
  facebook = "facebook",
  folderOpen = "folderOpen",
  form = "form",
  fullscreen = "fullscreen",
  fullscreenExit = "fullscreenExit",
  home = "home",
  iconAllergies = "iconAllergies",
  iconArm = "iconArm",
  iconArticle = "iconArticle",
  iconAsthma = "iconAsthma",
  iconBacteria = "iconBacteria",
  iconBalance = "iconBalance",
  iconBites = "iconBites",
  iconBlood = "iconBlood",
  iconBrain = "iconBrain",
  iconBreastCancer = "iconBreastCancer",
  iconCaffeineTobacco = "iconCaffeineTobacco",
  iconCancer = "iconCancer",
  iconCannabis = "iconCannabis",
  iconCardiogram = "iconCardiogram",
  iconChild = "iconChild",
  iconConsultation = "iconConsultation",
  iconContent = "iconContent",
  iconDiabetes = "iconDiabetes",
  iconDiagnose = "iconDiagnose",
  iconDiagrams = "iconDiagrams",
  iconDigitalEnrolment = "iconDigitalEnrolment",
  iconDrugs = "iconDrugs",
  iconEar = "iconEar",
  iconElearning = "iconElearning",
  iconExercise = "iconExercise",
  iconEye = "iconEye",
  iconFamily = "iconFamily",
  iconFaq = "iconFaq",
  iconFaqCircle = "iconFaqCircle",
  iconFemale = "iconFemale",
  iconFemaleReproductive = "iconFemaleReproductive",
  iconFoot = "iconFoot",
  iconGastro = "iconGastro",
  iconGenetics = "iconGenetics",
  iconGoalsSetting = "iconGoalsSetting",
  iconHand = "iconHand",
  iconHead = "iconHead",
  iconHeadache = "iconHeadache",
  iconHealthAssociations = "iconHealthAssociations",
  iconHealthTopics = "iconHealthTopics",
  iconHealthyLifestyle = "iconHealthyLifestyle",
  iconHeart = "iconHeart",
  iconHeartCare = "iconHeartCare",
  iconHypertension = "iconHypertension",
  iconImd = "iconImd",
  iconIvDrugs = "iconIvDrugs",
  iconKidney = "iconKidney",
  iconKnee = "iconKnee",
  iconLaptop = "iconLaptop",
  iconLifestyle = "iconLifestyle",
  iconLiver = "iconLiver",
  lockClosed = "lockClosed",
  iconLungs = "iconLungs",
  iconMale = "iconMale",
  iconMaleReproductive = "iconMaleReproductive",
  iconMedicalSupervision = "iconMedicalSupervision",
  iconMentalHealth = "iconMentalHealth",
  iconMindfulness = "iconMindfulness",
  iconMouth = "iconMouth",
  iconMuscle = "iconMuscle",
  iconNausea1 = "iconNausea1",
  iconNausea2 = "iconNausea2",
  iconNeedle = "iconNeedle",
  iconNerves = "iconNerves",
  iconNewMouth = "iconNewMouth",
  iconNews = "iconNews",
  iconNose = "iconNose",
  iconNotebook = "iconNotebook",
  iconNutrition = "iconNutrition",
  iconOffers = "iconOffers",
  iconOfficeErgonomics = "iconOfficeErgonomics",
  iconOutdoors = "iconOutdoors",
  iconPacing = "iconPacing",
  iconPackageClosed = "iconPackageClosed",
  iconPackageOpen = "iconPackageOpen",
  iconPain = "iconPain",
  iconPancreas = "iconPancreas",
  iconPatientSession = "iconPatientSession",
  iconPatientSessionActive = "iconPatientSessionActive",
  iconPediatrics = "iconPediatrics",
  iconPelvisHipLeg = "iconPelvisHipLeg",
  iconPhone = "iconPhone",
  iconPills = "iconPills",
  iconPregnancy = "iconPregnancy",
  iconProcedure = "iconProcedure",
  iconRadiation = "iconRadiation",
  iconRelated = "iconRelated",
  iconReports = "iconReports",
  iconRheumatology = "iconRheumatology",
  iconRibs = "iconRibs",
  iconSelfCare = "iconSelfCare",
  iconSexuality = "iconSexuality",
  iconShoulder = "iconShoulder",
  iconSkin = "iconSkin",
  iconSleep = "iconSleep",
  iconSmokingCessation = "iconSmokingCessation",
  iconSocialDistancing = "iconSocialDistancing",
  iconSpine = "iconSpine",
  iconStds = "iconStds",
  iconStethoscope = "iconStethoscope",
  iconStress = "iconStress",
  iconSurveys = "iconSurveys",
  iconTablet = "iconTablet",
  iconTestTube = "iconTestTube",
  iconThroat = "iconThroat",
  iconTimeManagement = "iconTimeManagement",
  iconTools = "iconTools",
  iconTopic = "iconTopic",
  iconTravel = "iconTravel",
  iconTreatment = "iconTreatment",
  iconTreatmentOtc = "iconTreatmentOtc",
  iconTreatmentRx = "iconTreatmentRx",
  iconUnderstanding = "iconUnderstanding",
  iconUrology = "iconUrology",
  iconVascular = "iconVascular",
  iconVideo = "iconVideo",
  iconVirus = "iconVirus",
  iconWaitingRoom = "iconWaitingRoom",
  iconWeightScale = "iconWeightScale",
  iconWhiplash = "iconWhiplash",
  iconXray = "iconXray",
  image = "image",
  info = "info",
  keyboardArrowDown = "keyboardArrowDown",
  keyboardArrowLeft = "keyboardArrowLeft",
  keyboardArrowRight = "keyboardArrowRight",
  keyboardArrowUp = "keyboardArrowUp",
  language = "language",
  linkedIn = "linkedIn",
  list = "list",
  mail = "mail",
  medicalSave = "medicalSave",
  menu = "menu",
  moon = "moon",
  more = "more",
  organization = "organization",
  pdf = "pdf",
  pending = "pending",
  play = "play",
  radioChecked = "radioChecked",
  radioUnchecked = "radioUnchecked",
  redo = "redo",
  save = "save",
  search = "search",
  share = "share",
  signIn = "signIn",
  signOut = "signOut",
  star = "star",
  starOutline = "starOutline",
  top = "top",
  trash = "trash",
  twitter = "twitter",
  undo = "undo",
  verified = "verified",
  video = "video",
  youTube = "youTube",
}

const getIconComponent = (icon: keyof typeof IconName): React.FC<SvgProps> => {
  switch (icon) {
    case "add":
      return Add
    case "arrowDropDown":
      return ArrowDropDown
    case "arrowDropUp":
      return ArrowDropUp
    case "arrowRight":
      return ArrowRight
    case "article":
      return ResourceArticle
    case "back":
      return Back
    case "bookmark":
      return Bookmark
    case "brush":
      return Brush
    case "cancelCircle":
      return CancelCircle
    case "check":
      return Check
    case "checkCircle":
      return CheckCircle
    case "checkboxChecked":
      return CheckboxChecked
    case "checkboxUnchecked":
      return CheckboxUnchecked
    case "close":
      return Close
    case "cogs":
      return Cogs
    case "colorLens":
      return ColorLens
    case "doneAll":
      return DoneAll
    case "download":
      return Download
    case "editPencil":
      return EditPencil
    case "eraser":
      return Eraser
    case "error":
      return Error
    case "externalLink":
      return ExternalLink
    case "eyeClosed":
      return EyeClosed
    case "eyeOpen":
      return EyeOpen
    case "facebook":
      return Facebook
    case "folderOpen":
      return FolderOpen
    case "form":
      return ResourceForm
    case "fullscreen":
      return Fullscreen
    case "fullscreenExit":
      return FullscreenExit
    case "home":
      return Home
    case "iconAllergies":
      return IconAllergies
    case "iconArm":
      return IconArm
    case "iconArticle":
      return IconArticle
    case "iconAsthma":
      return IconAsthma
    case "iconBacteria":
      return IconBacteria
    case "iconBalance":
      return IconBalance
    case "iconBites":
      return IconBites
    case "iconBlood":
      return IconBlood
    case "iconBrain":
      return IconBrain
    case "iconBreastCancer":
      return IconBreastCancer
    case "iconCaffeineTobacco":
      return IconCaffeineTobacco
    case "iconCancer":
      return IconCancer
    case "iconCannabis":
      return IconCannabis
    case "iconCardiogram":
      return IconCardiogram
    case "iconChild":
      return IconChild
    case "iconConsultation":
      return IconConsultation
    case "iconContent":
      return IconContent
    case "iconDiabetes":
      return IconDiabetes
    case "iconDiagnose":
      return IconDiagnose
    case "iconDiagrams":
      return IconDiagrams
    case "iconDigitalEnrolment":
      return IconDigitalEnrolment
    case "iconDrugs":
      return IconDrugs
    case "iconEar":
      return IconEar
    case "iconElearning":
      return IconElearning
    case "iconExercise":
      return IconExercise
    case "iconEye":
      return IconEye
    case "iconFamily":
      return IconFamily
    case "iconFaq":
      return IconFaq
    case "iconFaqCircle":
      return IconFaqCircle
    case "iconFemale":
      return IconFemale
    case "iconFemaleReproductive":
      return IconFemaleReproductive
    case "iconFoot":
      return IconFoot
    case "iconGastro":
      return IconGastro
    case "iconGenetics":
      return IconGenetics
    case "iconGoalsSetting":
      return IconGoalsSetting
    case "iconHand":
      return IconHand
    case "iconHead":
      return IconHead
    case "iconHeadache":
      return IconHeadache
    case "iconHealthAssociations":
      return IconHealthAssociations
    case "iconHealthTopics":
      return IconHealthTopics
    case "iconHealthyLifestyle":
      return IconHealthyLifestyle
    case "iconHeart":
      return IconHeart
    case "iconHeartCare":
      return IconHeartCare
    case "iconHypertension":
      return IconHypertension
    case "iconImd":
      return IconImd
    case "iconIvDrugs":
      return IconIvDrugs
    case "iconKidney":
      return IconKidney
    case "iconKnee":
      return IconKnee
    case "iconLaptop":
      return IconLaptop
    case "iconLifestyle":
      return IconLifestyle
    case "iconLiver":
      return IconLiver
    case "iconLungs":
      return IconLungs
    case "iconMale":
      return IconMale
    case "iconMaleReproductive":
      return IconMaleReproductive
    case "iconMedicalSupervision":
      return IconMedicalSupervision
    case "iconMentalHealth":
      return IconMentalHealth
    case "iconMindfulness":
      return IconMindfulness
    case "iconMouth":
      return IconMouth
    case "iconMuscle":
      return IconMuscle
    case "iconNausea1":
      return IconNausea1
    case "iconNausea2":
      return IconNausea2
    case "iconNeedle":
      return IconNeedle
    case "iconNerves":
      return IconNerves
    case "iconNewMouth":
      return IconNewMouth
    case "iconNews":
      return IconNews
    case "iconNose":
      return IconNose
    case "iconNotebook":
      return IconNotebook
    case "iconNutrition":
      return IconNutrition
    case "iconOffers":
      return IconOffers
    case "iconOfficeErgonomics":
      return IconOfficeErgonomics
    case "iconOutdoors":
      return IconOutdoors
    case "iconPacing":
      return IconPacing
    case "iconPackageClosed":
      return IconPackageClosed
    case "iconPackageOpen":
      return IconPackageOpen
    case "iconPain":
      return IconPain
    case "iconPancreas":
      return IconPancreas
    case "iconPatientSession":
      return IconPatientSession
    case "iconPatientSessionActive":
      return IconPatientSessionActive
    case "iconPediatrics":
      return IconPediatrics
    case "iconPelvisHipLeg":
      return IconPelvisHipLeg
    case "iconPhone":
      return IconPhone
    case "iconPills":
      return IconPills
    case "iconPregnancy":
      return IconPregnancy
    case "iconProcedure":
      return IconProcedure
    case "iconRadiation":
      return IconRadiation
    case "iconRelated":
      return IconRelated
    case "iconReports":
      return IconReports
    case "iconRheumatology":
      return IconRheumatology
    case "iconRibs":
      return IconRibs
    case "iconSelfCare":
      return IconSelfCare
    case "iconSexuality":
      return IconSexuality
    case "iconShoulder":
      return IconShoulder
    case "iconSkin":
      return IconSkin
    case "iconSleep":
      return IconSleep
    case "iconSmokingCessation":
      return IconSmokingCessation
    case "iconSocialDistancing":
      return IconSocialDistancing
    case "iconSpine":
      return IconSpine
    case "iconStds":
      return IconStds
    case "iconStethoscope":
      return IconStethoscope
    case "iconStress":
      return IconStress
    case "iconSurveys":
      return IconSurveys
    case "iconTablet":
      return IconTablet
    case "iconTestTube":
      return IconTestTube
    case "iconThroat":
      return IconThroat
    case "iconTimeManagement":
      return IconTimeManagement
    case "iconTools":
      return IconTools
    case "iconTopic":
      return IconTopic
    case "iconTravel":
      return IconTravel
    case "iconTreatment":
      return IconTreatment
    case "iconTreatmentOtc":
      return IconTreatmentOtc
    case "iconTreatmentRx":
      return IconTreatmentRx
    case "iconUnderstanding":
      return IconUnderstanding
    case "iconUrology":
      return IconUrology
    case "iconVascular":
      return IconVascular
    case "iconVideo":
      return IconVideo
    case "iconVirus":
      return IconVirus
    case "iconWaitingRoom":
      return IconWaitingRoom
    case "iconWeightScale":
      return IconWeightScale
    case "iconWhiplash":
      return IconWhiplash
    case "iconXray":
      return IconXray
    case "image":
      return ResourceImage
    case "info":
      return Info
    case "keyboardArrowDown":
      return KeyboardArrowDown
    case "keyboardArrowLeft":
      return KeyboardArrowLeft
    case "keyboardArrowRight":
      return KeyboardArrowRight
    case "keyboardArrowUp":
      return KeyboardArrowUp
    case "language":
      return Language
    case "linkedIn":
      return LinkedIn
    case "list":
      return List
    case "lockClosed":
      return LockClosed
    case "mail":
      return Mail
    case "medicalSave":
      return MedicalSave
    case "menu":
      return Menu
    case "moon":
      return Moon
    case "more":
      return More
    case "organization":
      return Organization
    case "pdf":
      return ResourcePdf
    case "pending":
      return Pending
    case "play":
      return Play
    case "radioChecked":
      return RadioChecked
    case "radioUnchecked":
      return RadioUnchecked
    case "redo":
      return Redo
    case "save":
      return Save
    case "search":
      return Search
    case "share":
      return Share
    case "signIn":
      return SignIn
    case "signOut":
      return SignOut
    case "star":
      return Star
    case "starOutline":
      return StarOutline
    case "top":
      return Top
    case "trash":
      return Trash
    case "twitter":
      return Twitter
    case "undo":
      return Undo
    case "verified":
      return Verified
    case "video":
      return ResourceVideo
    case "youTube":
      return YouTube
  }
}

export interface PrIconProps extends SvgProps {
  icon: keyof typeof IconName
  size?: number
}

/**
 * Displays an SVG icon.
 */
const PrIcon: React.FC<PrIconProps> = ({ fill, icon, size, ...otherProps }) => {
  const { iconSizes, textColors } = useAppTheme()
  let IconComponent = getIconComponent(icon)

  // If icon is invalid, use the default icon.
  if (!IconComponent) {
    Log.warn(`No icon component found for ${icon}, using IMD fallback`)
    IconComponent = getIconComponent("iconImd")
  }

  return React.createElement(IconComponent, {
    fill: fill || textColors.default,
    height: size || iconSizes.medium,
    ...otherProps,
  })
}

export default PrIcon
