import React, { createContext, useEffect, useState } from "react"

import useAppParams from "hooks/useAppParams"
import Routes from "navigators/Routes"

/**
 * The navigation redirect context type
 */
export type NavigationRedirectType = {
  /**
   * URL that's pending redirect
   */
  pendingUrl: undefined | Routes | string

  /**
   * Set the pending URL
   */
  setPendingUrl: React.Dispatch<React.SetStateAction<undefined | string>>
}

/**
 * Context used to store information about where to navigate after login
 */
export const NavigationRedirect = createContext<undefined | NavigationRedirectType>(undefined)

/**
 * Context responsible for storing a route that's pending redirect so we can redirect navigation between protected and
 * public routes.
 */
const CxNavigationRedirect: React.FC = ({ children }) => {
  const { stringParams, url } = useAppParams()

  const [pendingUrl, setPendingUrl] = useState<undefined | Routes | string>(() => {
    // Arriving at root with a topic_id is the same as navigating to a topic
    if (url.pathname === "/" && stringParams.topic_id) {
      return `${Routes.topic}/${stringParams.topic_id}`
    }
    return undefined
  })

  useEffect(() => {
    // If we try to access a topic url while logged out, we need to redirect to the topic after login
    if (url.pathname.includes(Routes.topic)) {
      setPendingUrl(
        (url.pathname.startsWith("/") ? url.pathname.substring(1) : url.pathname) + url.search,
      )
    }
  }, [url])

  return (
    <>
      <NavigationRedirect.Provider
        value={{
          pendingUrl,
          setPendingUrl,
        }}
      >
        {children}
      </NavigationRedirect.Provider>
    </>
  )
}

export default CxNavigationRedirect
