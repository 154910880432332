import Color from "color"

import common, { commonColors } from "services/Theme/common"
import { BrandColors, ButtonColors, FormColors, TextColors, ThemeTemplate } from "."

const headerBackgroundColor = `linear-gradient(
  180deg,
  #003a57 0px,
  #023350 25px,
  #032d49 50px,
  #042642 75px,
  #05203b 100px,
  #051a34 125px,
  #06132d 150px,
  #050a26 175px
)`

const brandColors: BrandColors = {
  // Unused
  inverseBackground: commonColors.magnolia,
  nodeNavigatorOpenHeaderBackground: "rgba(20, 215, 180, .1)",
  tertiary: commonColors.pink,
  warningBackground: Color(commonColors.red).alpha(0.3).string(),

  background: "#252733",
  dropShadow: commonColors.lightBlue10,
  errorBackground: "#401010",
  homeSearchBacksplash: commonColors.black30,
  navHeaderBackground: "#252733",
  primary: "#AAB4F2",
  screenOverlay: "rgba(250, 250, 250, 0.25)",
  searchPopupBorder: "#AAB4F2",
  secondary: "#14D7B4",
  secondaryBackground: "#181A26",
  selected: "rgba(23, 195, 230, .1)",
  separator: "rgba(255, 255, 255, 0.05)",
  surface: "#10111A",
  transparent: Color(commonColors.darkGrey).alpha(0).string(),

  tabBackground: "#181A26",
  tabBorder: "rgba(255, 255, 255, 0.05)",
  tabSeparator: commonColors.black10,

  // TOC Specific (mostly)
  nodeNavigatorBase: "#17C3E6",
  nodeNavigatorIcon: commonColors.mediumLightGrey,
  nodeNavigatorPresentationSectionNode: "#1F2026",
  nodeNavigatorSelected: commonColors.green10,
  nodeNavigatorSeparator: "rgba(170, 180, 242, 0.2)",

  // Subtree Navigator specific
  nodeNavigatorTreeChild1: "#AAB4F2",
  nodeNavigatorTreeChild2: "#17C3E6",
  nodeNavigatorTreeChild3: "#7FC7EA",
  nodeNavigatorTreeChild4: "#89EBD9",

  // Snackbar notification colours
  snackbarAccentError: "#F28585",
  snackbarAccentPrimary: "#17C3E6",
  snackbarAccentWarning: "#FCBB01",
  snackbarBackgroundError: "#401010",
  snackbarBackgroundPrimary: "#0A3240",
  snackbarBackgroundWarning: "#4D3309",
}

const buttonColors: ButtonColors = {
  // Unused
  tertiary: commonColors.pink,
  textInputActive: commonColors.orange,
  textInverse: commonColors.black,

  destructive: "#F28585",
  // Confirm this colour for dark mode - can it just be `brandColors.selected`?
  paginationSelected: Color("#17C3E6").alpha(0.5).string(),
  primary: "#17C3E6",
  secondary: "#AAB4F2",
  surface: "#141726",
  text: "#15161A",
  textInputDisabled: "#A7A7A7", // "textDisabled"
}

const formColors: FormColors = {
  // Unused
  fieldBackgroundError: Color(commonColors.red).alpha(0.1).string(),
  fieldPasswordProgressBar: commonColors.darkBlue,

  errorBackground: "#401010",
  fieldBackground: "#10111A",
  fieldBackgroundLabelActive: Color("#17C3E6").alpha(0.1).string(),
  fieldBorderActive: "#17C3E6",
  fieldBorderError: "#F28585",
  fieldBorderInactive: "#545454",
  fieldError: "#F28585",
  fieldLabelActive: "#17C3E6",
  fieldLabelInactive: "#B3B4B5",
  fieldPasswordSecureBox: "#1F2626",
  fieldPasswordSecureText: "#17C3E6",
}

const textColors: TextColors = {
  // Unused
  heading2: "#eee",
  mutedInverse: "#222222aa",
  mutedPrimary: commonColors.mediumLightGrey,
  navHeader: commonColors.white,

  default: "#DADCE6",
  disabled: "#B5B1B1",
  error: "#F28585",
  footer: "#C1C3CC",
  heading1: "#DADCE6",
  inverse: "#222",
  link: "#17C3E6",
  muted: "#B1B2BA",
  tabLabel: "#DADCE6",
  textInputBackground: "#181A26",
  textInputPlaceholder: "#A6A8B3",
  textInputSecondaryBackground: "#252838",
}

const dark: ThemeTemplate = {
  ...common,
  brandColors,
  buttonColors,
  formColors,
  headerBackgroundColor,
  name: "dark",
  navHeaderStatusBarStyle: "dark-content",
  statusBarStyle: "light-content",
  textColors,
}

export default dark
