/**
 * @generated SignedSource<<61f050cc47e5d37b24fa1aee089ed223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpTopicSubtreeResourceItem_resource$data = {
  readonly _id: string;
  readonly icon: string | null;
  readonly names: any;
  readonly type: string;
  readonly " $fragmentType": "CpTopicSubtreeResourceItem_resource";
};
export type CpTopicSubtreeResourceItem_resource$key = {
  readonly " $data"?: CpTopicSubtreeResourceItem_resource$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicSubtreeResourceItem_resource">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicSubtreeResourceItem_resource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "names",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Resource",
  "abstractKey": null
};
})();

(node as any).hash = "02f7c6610b668017e300e035cea9ec4c";

export default node;
