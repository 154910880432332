import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconThroat: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M56 301h-2c-.6-10.5-2.4-21.1-1.6-31.4 1.9-25.5 5.8-31.9 29.1-38.3 13.6-3.7 27.5-6.4 41.3-9.3 7.9-1.7 12.1-6.5 12.6-14.3.9-13.1 1.9-26.3 1.8-39.4 0-5-2.5-10.1-4.6-14.8-3.6-8-7.6-15.8-11.8-23.5-1.2-2.2-3.6-5-5.7-5.3-7-.8-8.6-5.9-9.1-11a144.12 144.12 0 01-.6-23.7c.7-13.3 2.2-14.2 16.6-12.3V59h98c16.5 0 16.5-.1 19.7 15.4.4 2.1 1.2 4.2 1.7 6.1 16.6.6 17 .5 17.8 16.6a183.38 183.38 0 01-1.1 27.7c-1.1 11.5-3 12.6-15.2 9.5-3.6 9-6.3 18.6-11 27.1-10.5 18.9-7 37.4-1 56.3 2.4 7.6 7.5 11.3 15.2 12 8.2.7 16.5.8 24.6 2.2 10.9 1.8 25.1-.4 28.5 14.3 3.9 17.1 5.6 34.7 8.6 53.7H62.5A33.94 33.94 0 0056 301zm154.2-102.6c-6.3 5.7-11.2 11.4-17.2 15.2-7.4 4.7-10.3 11.1-7.4 18.3 1.5 3.6 7.8 6.9 12.1 7.3 8.1.6 12.8-5.1 13.1-13 .5-9.5-.3-19.1-.6-27.8zm-57.6.5c-.4 8.1-1.5 17.2-1.2 26.2.2 7.5 4.3 13.2 12.3 14.3 6.3.9 11.9-.7 13.9-7.8 2.2-7.8-1.2-13-7.2-17.5s-11.4-9.7-17.8-15.2zm2.9-75.9c12.3 13.8 37.6 15.3 47.4 0zm-.9-4.2h47.8c-9.8-11.8-30.8-12.5-47.8 0zm12.8-26c11 11.4 15.9 11.3 26.5 0z" />
    </Svg>
  )
}

export default IconThroat
