import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpPublisherAttribution_resourceNode;

import React, { useCallback, useState } from "react";
import { FormattedDate, FormattedMessage, defineMessages } from "react-intl";
import { Image, Modal, ScrollView, TouchableOpacity, View } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useScreenLayout from "hooks/useScreenLayout";
import { QlTopic_comboFragment } from "query/QlTopic";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
const resourceNodeFragment = _CpPublisherAttribution_resourceNode !== void 0 ? _CpPublisherAttribution_resourceNode : _CpPublisherAttribution_resourceNode = require("./__generated__/CpPublisherAttribution_resourceNode.graphql");
const localTranslations = defineMessages({
  defaultAttribution: {
    id: "iQJx7j",
    defaultMessage: "Content provided to you by {publisherName}"
  },
  mayoClinic: {
    id: "bYVQwA",
    defaultMessage: "\xA9 1998-{currentYear} Mayo Foundation for Medical Education and Research (MFMER). All rights reserved."
  },
  medlinePlus: {
    id: "vIF0rj",
    defaultMessage: "Courtesy of MedlinePlus from the National Library of Medicine. MedlinePlus brings together authoritative health information from the National Library of Medicine (NLM), the National Institutes of Health (NIH), and other government agencies and health-related organizations."
  }
});
const localStyles = {
  containerLarge: css`
    flex-direction: row;
    justify-content: space-between;
  `,
  containerSmall: css`
    flex-direction: column-reverse;
    flex-wrap: wrap;
  `,
  image: css`
    height: 70px;
    width: 70px;
    margin-right: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  label: css`
    ${styles.uppercase};
    font-size: ${({
    theme
  }) => theme.fontSize("xsmall")};
  `,
  termsOfUse: css`
    color: ${({
    theme
  }) => theme.textColors.link};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    text-decoration-color: ${({
    theme
  }) => theme.textColors.link};
  `,
  termsOfUseCloseButton: css`
    align-items: flex-end;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  termsOfUseCloseText: css`
    ${styles.uppercase};
    color: ${({
    theme
  }) => theme.brandColors.primary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: bold;
  `,
  termsOfUseHovered: css`
    text-decoration: underline;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
  `,
  termsOfUseInnerContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    max-width: 650px;
    max-height: 650px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  text: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  updatedContainerLarge: css`
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  updatedContainerSmall: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * Renders a publishers attribution for the currently displayed resource and its last updated date.
 */
const CpPublisherAttribution = ({
  comboKey,
  resourceId,
  style
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const handleShowTermsOfUse = useCallback(() => setShowTermsOfUse(true), []);
  const handleHideTermsOfUse = useCallback(() => setShowTermsOfUse(false), []);
  const comboData = useFragment(QlTopic_comboFragment, comboKey);
  const data = useFragment(resourceNodeFragment, comboData);
  const resource = data.type === "ResourceNode::Taggable::Resource" ? data.taggedResources?.find(taggedResource => taggedResource._id === resourceId) : data.resource;
  const termsOfUse = resource?.publisher?.termsOfUse;
  const publisherName = resource?.publisher?.name ?? "";
  const imageUri = resource?.publisher?.icon;
  const currentYear = new Date().getFullYear();
  let dateUpdated = new Date(data.updatedAt); // Fallback value, we actually prefer either of the following dates

  const lastUpdated = resource?.lastUpdated;
  const lastImported = resource?.publisher?.lastImported;

  if (lastUpdated || lastImported) {
    // Extract and explicitly create new Date for simplified date string in order to ensure date is correctly managed
    const simpleDateString = (lastUpdated || lastImported) ?? ""; // For whatever reason this will throw an error if we don't have a fallback

    const [year, month, day] = simpleDateString.split("-");
    dateUpdated = new Date(Number(year), Number(month) - 1, Number(day));
  } // Some publishers have specific attributions we need to use, otherwise we use the generic fallback


  let publisherAttribution = null;

  switch (publisherName) {
    case "Mayo Clinic":
      publisherAttribution = localTranslations.mayoClinic;
      break;

    case "U.S. National Library of Medicine":
      publisherAttribution = localTranslations.medlinePlus;
      break;

    default:
      publisherAttribution = localTranslations.defaultAttribution;
  }

  if (publisherName && !imageUri && publisherName !== "Mayo Clinic") {
    // With the exception of Mayo, who doesn't want us to show their logo anymore, if we have a publisherName but no image, we want to log it
    Log.warn(`CpPublisherAttribution - No image found for publisher: ${publisherName}`);
  }

  if (!data || !publisherName) {
    // If we don't have a publisher name, we won't have much to show
    return null;
  }

  return <_StyledView style={style} $_css={[styles.paddingDouble, // Smaller screens apply `reverse-column` to ensure last updated date is seen first
  isNarrowScreen ? localStyles.containerSmall : localStyles.containerLarge]}>
      {
      /* Display publisher logo, attribution and terms of use if provided */
    }
      {!isNarrowScreen && imageUri && <_StyledImage resizeMode="contain" source={{
      uri: imageUri
    }} $_css2={localStyles.image} />}
      <_StyledView2 $_css3={!isNarrowScreen ? styles.flexSingle : undefined}>
        <_StyledPrText $_css4={localStyles.label}>
          <FormattedMessage id="jGct59" defaultMessage="Publisher" />
        </_StyledPrText>
        <_StyledView3 $_css5={styles.rowAndCenter}>
          {isNarrowScreen && imageUri && <_StyledImage2 resizeMode="contain" source={{
          uri: imageUri
        }} $_css6={localStyles.image} />}
          <_StyledPrText2 $_css7={localStyles.text}>
            <FormattedMessage {...publisherAttribution} values={{
            currentYear,
            publisherName
          }} />
          </_StyledPrText2>
        </_StyledView3>
        {termsOfUse && <_StyledView4 $_css8={styles.rowAndCenter}>
            <_StyledPrPressable onPress={handleShowTermsOfUse} $_css9={styles.paddingNone}>
              {({
            hovered
          }) => <_StyledPrText3 $_css10={[localStyles.termsOfUse, hovered ? localStyles.termsOfUseHovered : undefined]}>
                  <FormattedMessage id="/n6STn" defaultMessage="Terms of Use" />
                </_StyledPrText3>}
            </_StyledPrPressable>
            <_StyledView5 $_css11={styles.flexSingle} />
          </_StyledView4>}
      </_StyledView2>

      {
      /* Display last updated date */
    }
      <_StyledView6 $_css12={isNarrowScreen ? localStyles.updatedContainerSmall : localStyles.updatedContainerLarge}>
        <_StyledPrText4 $_css13={localStyles.label}>
          <FormattedMessage id="X4HPje" defaultMessage="Last updated" />
        </_StyledPrText4>
        <_StyledPrText5 $_css14={localStyles.text}>
          <FormattedDate day="2-digit" month="long" value={dateUpdated} year="numeric" />
        </_StyledPrText5>
      </_StyledView6>

      {
      /* Terms of Use Modal */
    }
      {termsOfUse && showTermsOfUse && <Modal onRequestClose={handleHideTermsOfUse} transparent={true} visible={showTermsOfUse}>
          <_StyledTouchableOpacity onPress={handleHideTermsOfUse} $_css15={styles.viewOverlay}>
            <_StyledView7 $_css16={localStyles.termsOfUseInnerContainer}>
              <ScrollView>
                <CpDivHtmlWeb content={termsOfUse} />
              </ScrollView>
              <_StyledPrPressable2 onPress={handleHideTermsOfUse} $_css17={localStyles.termsOfUseCloseButton}>
                <_StyledPrText6 children={translations.buttons.close} $_css18={localStyles.termsOfUseCloseText} />
              </_StyledPrPressable2>
            </_StyledView7>
          </_StyledTouchableOpacity>
        </Modal>}
    </_StyledView>;
};

export default CpPublisherAttribution;

var _StyledView = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView",
  componentId: "sc-1g5zdhb-0"
})(["", ""], p => p.$_css);

var _StyledImage = _styled(Image).withConfig({
  displayName: "CpPublisherAttribution___StyledImage",
  componentId: "sc-1g5zdhb-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView2",
  componentId: "sc-1g5zdhb-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPublisherAttribution___StyledPrText",
  componentId: "sc-1g5zdhb-3"
})(["", ""], p => p.$_css4);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView3",
  componentId: "sc-1g5zdhb-4"
})(["", ""], p => p.$_css5);

var _StyledImage2 = _styled(Image).withConfig({
  displayName: "CpPublisherAttribution___StyledImage2",
  componentId: "sc-1g5zdhb-5"
})(["", ""], p => p.$_css6);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPublisherAttribution___StyledPrText2",
  componentId: "sc-1g5zdhb-6"
})(["", ""], p => p.$_css7);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView4",
  componentId: "sc-1g5zdhb-7"
})(["", ""], p => p.$_css8);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPublisherAttribution___StyledPrPressable",
  componentId: "sc-1g5zdhb-8"
})(["", ""], p => p.$_css9);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPublisherAttribution___StyledPrText3",
  componentId: "sc-1g5zdhb-9"
})(["", ""], p => p.$_css10);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView5",
  componentId: "sc-1g5zdhb-10"
})(["", ""], p => p.$_css11);

var _StyledView6 = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView6",
  componentId: "sc-1g5zdhb-11"
})(["", ""], p => p.$_css12);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPublisherAttribution___StyledPrText4",
  componentId: "sc-1g5zdhb-12"
})(["", ""], p => p.$_css13);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPublisherAttribution___StyledPrText5",
  componentId: "sc-1g5zdhb-13"
})(["", ""], p => p.$_css14);

var _StyledTouchableOpacity = _styled(TouchableOpacity).withConfig({
  displayName: "CpPublisherAttribution___StyledTouchableOpacity",
  componentId: "sc-1g5zdhb-14"
})(["", ""], p => p.$_css15);

var _StyledView7 = _styled(View).withConfig({
  displayName: "CpPublisherAttribution___StyledView7",
  componentId: "sc-1g5zdhb-15"
})(["", ""], p => p.$_css16);

var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpPublisherAttribution___StyledPrPressable2",
  componentId: "sc-1g5zdhb-16"
})(["", ""], p => p.$_css17);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPublisherAttribution___StyledPrText6",
  componentId: "sc-1g5zdhb-17"
})(["", ""], p => p.$_css18);