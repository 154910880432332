import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconMale: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M180.28 64.05c-16.71 0-24 14.6-21.8 29.4 1.36 9.31 4.49 17.19 8.78 22.64 7.82 9.94 18.22 9.94 26 0 4.86-6.18 9.14-17.69 9.14-27.66 0-13.51-7.78-24.38-22.16-24.38zm0 81.82c8.11-1 15.24-5.05 21.51-10.59a4 4 0 016.54 2.18l2.3 9.63a2 2 0 011.28-.06c14.69 4.1 34.49 13.73 39.83 22.41 3 5.94 17 62 17 91 0 5.57-.07 28.27-.82 47.56h-27.77c0-20.33-.75-54.35-7.41-76.56-2.23 26-2.86 57.83-3.41 76.56h-98.1c-.55-18.73-1.18-50.53-3.41-76.56-6.66 22.21-7.39 56.23-7.41 76.56H92.64c-.75-19.29-.82-42-.82-47.56 0-29 14-85.06 17-91 5.34-8.68 25.13-18.31 39.83-22.41a2 2 0 011.28.06l2.3-9.63a4 4 0 016.54-2.18c6.27 5.53 13.4 9.62 21.51 10.59zm25.8 15.29l-13.66 10.45a4 4 0 01-6.07-1.55l-6.07-11.59-6.55 12.39a4 4 0 01-5.59.75l-13.67-10.45c5.28 15.87 15.42 36.72 25.81 46.28 10.39-9.56 20.53-30.41 25.8-46.28zm-11.51-11.39a42.58 42.58 0 01-8.25 3.06l5 9.57 12.49-9.56-1.81-7.55a52.28 52.28 0 01-7.44 4.48zm-20.33 3.06a46.75 46.75 0 01-15.69-7.54l-1.81 7.55 12.49 9.56 5-9.57zm.5-100.38c25.7-3.74 42.9 17.3 39.2 42.69-1.67 11.44-5.68 21.31-11.22 28.36-12.66 16.09-32.22 16.09-44.88 0-6.45-8.21-11.72-22.5-11.72-35.07 0-17.54 10.22-33.3 28.62-36z" />
    </Svg>
  )
}

export default IconMale
