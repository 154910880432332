/*import axios from "axios"
import Config from "services/Config"
import Log from "services/Log" */
import { GoogleAnalyticsEventOptions } from "./GoogleAnalytics"

export type AnalyticsCleanup = () => void

export enum AnalyticsEventName {
  language_change = "language_change",
  nav_breadcrumb = "nav_breadcrumb",
  nav_external = "nav_external",
  nav_home_button = "nav_home_button",
  nav_home_item = "nav_home_item",
  nav_home_tab = "nav_home_tab",
  nav_logo = "nav_logo",
  nav_related = "nav_related",
  nav_search_item = "nav_search_item",
  nav_topic_node = "nav_topic_node",
  pageview = "pageview",
  session_end = "session_end",
}

export interface AnalyticsLanguageChangeEvent {
  data: {
    languageSelected: string
  }
  eventType: AnalyticsEventName.language_change
}

export interface AnalyticsNavBreadcrumbEvent {
  data: {
    level: number
  }
  eventType: AnalyticsEventName.nav_breadcrumb
}

export interface AnalyticsNavExternalEvent {
  data: {
    targetUrl: string
  }
  eventType: AnalyticsEventName.nav_external
}

export interface AnalyticsNavHomeButtonEvent {
  data: {
    url?: string
  }
  eventType: AnalyticsEventName.nav_home_button
}

export interface AnalyticsNavHomeItemEvent {
  data: {
    targetType: string
    targetValue: string
  }
  eventType: AnalyticsEventName.nav_home_item
}

export interface AnalyticsNavHomeTabEvent {
  data: {
    tabIndex: number
    tabName: string
  }
  eventType: AnalyticsEventName.nav_home_tab
}

export interface AnalyticsNavLogoEvent {
  data: {
    url?: string
  }
  eventType: AnalyticsEventName.nav_logo
}

export interface AnalyticsNavRelatedEvent {
  data: {
    targetRevisionId: string
  }
  eventType: AnalyticsEventName.nav_related
}

export interface AnalyticsNavSearchItemEvent {
  data: {
    targetRevisionId: string
  }
  eventType: AnalyticsEventName.nav_search_item
}

export interface AnalyticsNavTopicNodeEvent {
  data: {
    targetRevisionId: string
  }
  eventType: AnalyticsEventName.nav_topic_node
}

export interface AnalyticsPageviewEvent {
  data: {
    pageTitle?: string
    url?: string
  }
  eventType: AnalyticsEventName.pageview
}

export interface AnalyticsSessionEndEvent {
  data: {
    url?: string
  }
  eventType: AnalyticsEventName.session_end
}

export type AnalyticsEvent =
  | AnalyticsLanguageChangeEvent
  | AnalyticsNavBreadcrumbEvent
  | AnalyticsNavExternalEvent
  | AnalyticsNavHomeButtonEvent
  | AnalyticsNavHomeItemEvent
  | AnalyticsNavHomeTabEvent
  | AnalyticsNavLogoEvent
  | AnalyticsNavRelatedEvent
  | AnalyticsNavSearchItemEvent
  | AnalyticsNavTopicNodeEvent
  | AnalyticsPageviewEvent
  | AnalyticsSessionEndEvent

export type GoogleEvent = {
  action: string
  name: string
  options?: GoogleAnalyticsEventOptions
}

export interface GoogleAnalyticsParams {
  utm_campaign?: string
  utm_content?: string
  utm_medium?: string
  utm_source?: string
  utm_term?: string
}
interface RawAnalyticsEvent {
  data:
    | AnalyticsLanguageChangeEvent["data"]
    | AnalyticsNavBreadcrumbEvent["data"]
    | AnalyticsNavExternalEvent["data"]
    | AnalyticsNavHomeButtonEvent["data"]
    | AnalyticsNavHomeItemEvent["data"]
    | AnalyticsNavHomeTabEvent["data"]
    | AnalyticsNavLogoEvent["data"]
    | AnalyticsNavRelatedEvent["data"]
    | AnalyticsNavSearchItemEvent["data"]
    | AnalyticsNavTopicNodeEvent["data"]
    | AnalyticsPageviewEvent["data"]
    | AnalyticsSessionEndEvent["data"]
  eventType: AnalyticsEventName
  metadata: GoogleAnalyticsParams & {
    agentLocale: string
    appBuild: string
    appCodepushBundle?: string
    appLocale: string
    appName: string
    appVersion: string
    hostname: string
    organizationCountry?: string
    organizationId?: string
    organizationSubdivision?: string
    pathname: string
    referrer: string
    rootRevisionId?: string
    screenHeight: number
    screenWidth: number
    selectedResourceId?: string
    selectedRevisionId?: string
    url: string
    userAgent: string
  }
  sessionId: string
}

/* const analyticsBackend = axios.create({
  baseURL: Config.PROD
    ? "https://api.analytics.imdhealth.app/event"
    : "https://api.analytics.imdhealth.dev/event",
  headers: {
    "Content-Type": "application/json",
  },
})*/

export const trackEvent = async (_event: RawAnalyticsEvent): Promise<void> => {
  /*
  try {
    await analyticsBackend.request({
      data: {
        ...event,
        timestamp: new Date().toISOString(),
      },
      method: "post",
    })
  } catch (error) {
    Log.debug("Analytics Request Failed", { error })
  }*/
}
