import React from "react"
import { View } from "react-native"
import { useFragment } from "react-relay/hooks"

import CpUpdatePageTitle from "components/app/CpUpdatePageTitle"
import CpDivHtmlWeb from "components/common/CpDivHtmlWeb"
import { CpResourceCommonProps, resourceFragment } from "components/topic/CpResourceCommon"
import { getNameVariant } from "services/Graphql"
import { fontFamilies } from "services/Theme/common"

/**
 * Renders a Handlebar resource
 */
const CpHandlebarResource: React.FC<CpResourceCommonProps> = ({ resource, style }) => {
  const data = useFragment(resourceFragment, resource)

  const html = data?.embedMarkup
  if (!html) return null

  const styledHtml = `
    <div style="font-family: ${fontFamilies.default};">
      ${html}
    </div>
  `

  return (
    <View style={style} testID="CpHandlebarResource">
      <CpUpdatePageTitle pageType="article" title={getNameVariant(data)} />
      <CpDivHtmlWeb content={styledHtml} />
    </View>
  )
}

export default CpHandlebarResource
