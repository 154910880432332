import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { NavTitles } from "components/app/header.types";
import CpHeaderButton from "components/common/CpHeaderButton";
import CpMenuDropdown from "components/common/CpMenuDropdown";
import CpModal from "components/common/CpModal";
import PrButton from "components/primitives/PrButton";
import { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import Routes from "navigators/Routes";
import { ScrollView, View } from "react-native";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  closeButton: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    height: ${({
    theme
  }) => theme.buttonHeights.large}px;
    width: 100%;
  `,
  modalContainer: css`
    max-height: 80vh;
  `,
  navGroup: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  optionContainer: css`
    max-height: 100%;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  title: css`
    align-self: center;
    color: ${({
    theme
  }) => theme.textColors.default};
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * A component for admin menu options for mobile
 */
const CpAdminMobileNavButton = ({
  handleHideMenuPopup
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    navigate
  } = useNavigation(); // Admin Menu Modal Handlers

  const [dropdownOpen, setDropdownOpen] = useState({
    [NavTitles.Content]: false
  });
  const handleShowContentModal = useCallback(() => {
    setDropdownOpen({ ...dropdownOpen,
      [NavTitles.Content]: true
    });
  }, [dropdownOpen]);
  const handleHideContentModal = useCallback(() => {
    setDropdownOpen({ ...dropdownOpen,
      [NavTitles.Content]: false
    });
  }, [dropdownOpen]); // Admin Menu Route Callbacks

  const handleOrganizationsPress = useCallback(() => {
    handleHideContentModal();
    handleHideMenuPopup();
    navigate(Routes.organizations);
  }, [handleHideContentModal, handleHideMenuPopup, navigate]);
  return <>
      {
      /* Content */
    }
      <CpHeaderButton icon={IconName.iconContent} label={translations.labels.content} onPress={handleShowContentModal} />
      <CpModal onBackgroundPress={handleHideContentModal} visible={dropdownOpen[NavTitles.Content]}>
        <_StyledView $_css={localStyles.modalContainer}>
          <ScrollView>
            <_StyledView2 $_css2={localStyles.optionContainer}>
              <_StyledPrText children={NavTitles.Content} $_css3={[styles.textHeader1, localStyles.title]} />
              <CpMenuDropdown group={{
              options: [{
                name: formatMessage(translations.labels.organizations),
                onPress: handleOrganizationsPress
              }]
            }} />
            </_StyledView2>
          </ScrollView>
          <_StyledView3 $_css4={localStyles.closeButton}>
            <PrButton onPress={handleHideContentModal} type="outline">
              {translations.buttons.close}
            </PrButton>
          </_StyledView3>
        </_StyledView>
      </CpModal>
    </>;
};

export default CpAdminMobileNavButton;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAdminMobileNavButton___StyledView",
  componentId: "sc-1ue7hc8-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAdminMobileNavButton___StyledView2",
  componentId: "sc-1ue7hc8-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAdminMobileNavButton___StyledPrText",
  componentId: "sc-1ue7hc8-2"
})(["", ""], p => p.$_css3);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpAdminMobileNavButton___StyledView3",
  componentId: "sc-1ue7hc8-3"
})(["", ""], p => p.$_css4);