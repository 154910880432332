var _PspPartnerMutation;

import { defineMessages } from "react-intl";
import { graphql } from "react-relay";
export const patientSupportProgramSessionMutation = _PspPartnerMutation !== void 0 ? _PspPartnerMutation : _PspPartnerMutation = require("./__generated__/PspPartnerMutation.graphql");
export let Partner;

(function (Partner) {
  Partner["Innomar"] = "innomar";
  Partner["Tezspire"] = "tezspire";
})(Partner || (Partner = {}));

export let PatientType;

(function (PatientType) {
  PatientType["Innomar"] = "Identity::Patient::Innomar";
  PatientType["Tezspire"] = "Identity::Patient::Tezspire";
})(PatientType || (PatientType = {}));

export const pspTranslations = defineMessages({
  invalidId: {
    id: "vEqIQW",
    defaultMessage: "Invalid Patient ID"
  },
  patientId: {
    id: "eU4zSP",
    defaultMessage: "Patient ID"
  },
  patientInfo: {
    id: "Mo6DbV",
    defaultMessage: "To load a program, please enter your patient identification number below."
  },
  startProgram: {
    id: "+gQPVw",
    defaultMessage: "Load Program"
  }
});