import { createContext } from "react"

export interface CxPresentationSupportData {
  currentIndex?: number
  presentationCompletion: {
    [key: string]: boolean
  }
  presentationIds: string[]
}

export interface CxPresentationSupportType extends CxPresentationSupportData {
  setPresentationContext: React.Dispatch<React.SetStateAction<CxPresentationSupportData>>
}

export const initialPresentationData: CxPresentationSupportData = {
  currentIndex: undefined,
  presentationCompletion: {},
  presentationIds: [],
}

/**
 * Contains tracking information for the current presentation/training topic.
 */
const CxPresentationSupport = createContext<CxPresentationSupportType>({
  ...initialPresentationData,
  setPresentationContext: () => undefined,
})

export default CxPresentationSupport
