import {
  DefaultNavigatorOptions,
  ParamListBase,
  StackActionHelpers,
  StackActions,
  StackNavigationState,
  StackRouterOptions,
  createNavigatorFactory,
  useNavigationBuilder,
} from "@react-navigation/native"

import { EventArg, useNavigation } from "@react-navigation/core"

import { StackView } from "@react-navigation/stack"
import {
  StackHeaderMode,
  StackNavigationConfig,
  StackNavigationEventMap,
  StackNavigationOptions,
} from "@react-navigation/stack/lib/typescript/src/types"
import StackRouterWithHistory from "navigators/StackRouterWithHistory"
import React, { useEffect } from "react"
import warnOnce from "warn-once"

type Props = DefaultNavigatorOptions<
  ParamListBase,
  StackNavigationState<ParamListBase>,
  StackNavigationOptions,
  StackNavigationEventMap
> &
  StackNavigationConfig &
  StackRouterOptions

const StateSyncronizer: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const navigation = useNavigation()
  const state = navigation.getState()

  useEffect(() => {
    // TODO: This is valid according to https://reactnavigation.org/docs/navigation-events#navigationaddlistener
    // but not valid based on the types. If one or the other changes then this should be updated.
    const current = navigation as {
      addListener: (event: "tabPress", callback: (e: EventArg<"tabPress", true>) => void) => void
    }

    current.addListener("tabPress", (e: EventArg<"tabPress", true>) => {
      const isFocused = navigation.isFocused()

      // Run the operation in the next frame so we're sure all listeners have been run
      // This is necessary to know if preventDefault() has been called
      requestAnimationFrame(() => {
        if (state.index > 0 && isFocused && !e.defaultPrevented) {
          // When user taps on already focused tab and we're inside the tab,
          // reset the stack to replicate native behaviour
          navigation.dispatch({
            ...StackActions.popToTop(),
            target: state.key,
          })
        }
      })
    })
  }, [navigation, state.index, state.key])

  return children
}

function StackNavigatorWithHistory({
  children,
  initialRouteName,
  screenListeners,
  screenOptions,
  ...rest
}: Props): JSX.Element {
  // @ts-expect-error: mode is deprecated
  const mode = rest.mode as undefined | "card" | "modal"

  // @ts-expect-error: headerMode='none' is deprecated
  const headerMode = rest.headerMode as undefined | StackHeaderMode | "none"

  warnOnce(
    mode != null,
    `Stack Navigator: 'mode="${mode}"' is deprecated. Use 'presentation: "${mode}"' in 'screenOptions' instead.`,
  )

  warnOnce(
    headerMode === "none",
    "Stack Navigator: 'headerMode=\"none\"' is deprecated. Use 'headerShown: false' in 'screenOptions' instead.",
  )

  warnOnce(
    headerMode != null && headerMode !== "none",
    "Stack Navigator: 'headerMode' is moved to 'options'. Moved it to 'screenOptions' to keep current behavior.",
  )

  const { NavigationContent, descriptors, navigation, state } = useNavigationBuilder<
    StackNavigationState<ParamListBase>,
    StackRouterOptions,
    StackActionHelpers<ParamListBase>,
    StackNavigationOptions,
    StackNavigationEventMap
  >(StackRouterWithHistory, {
    children,
    defaultScreenOptions: () => ({
      headerMode: headerMode && headerMode !== "none" ? headerMode : undefined,
      headerShown: headerMode ? headerMode !== "none" : true,
      presentation: mode,
    }),
    initialRouteName,
    screenListeners,
    screenOptions,
  })

  return (
    <NavigationContent>
      <StateSyncronizer>
        <StackView {...rest} descriptors={descriptors} navigation={navigation} state={state} />
      </StateSyncronizer>
    </NavigationContent>
  )
}
const createStackNavigatorWithHistory = createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  StackNavigationOptions,
  StackNavigationEventMap,
  typeof StackNavigatorWithHistory
>(StackNavigatorWithHistory)

export default createStackNavigatorWithHistory
