import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - fullscreen-exit
const FullscreenExit: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M21.313 10.688h4v2.625h-6.625v-6.625h2.625v4zM18.688 25.313v-6.625h6.625v2.625h-4v4h-2.625zM10.688 10.688v-4h2.625v6.625h-6.625v-2.625h4zM6.688 21.313v-2.625h6.625v6.625h-2.625v-4h-4z" />
    </Svg>
  )
}

export default FullscreenExit
