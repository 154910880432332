var _useDescendantsAsTree_rootResourceNode;

import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import SortNodeChildren from "../services/SortNodeChildren";
const useDescendantsAsTree_Query = _useDescendantsAsTree_rootResourceNode !== void 0 ? _useDescendantsAsTree_rootResourceNode : _useDescendantsAsTree_rootResourceNode = require("./__generated__/useDescendantsAsTree_rootResourceNode.graphql");
export const isAncestorRevisionId = (revisionId, node) => {
  if (node.revisionId === revisionId) return true;
  if (node.parent) return isAncestorRevisionId(revisionId, node.parent);
  return false;
};
/**
 * Given a ref to a node and a full list of all descendant nodes
 */

const useDescendantsAsTree = ref => {
  const fragmentData = useFragment(useDescendantsAsTree_Query, ref);
  return useMemo(() => {
    const {
      descendants,
      ...rootData
    } = fragmentData;
    const rootNode = { ...rootData,
      children: []
    };
    const nodeById = {
      [rootData._id]: rootNode
    };
    descendants.forEach(descendantNode => {
      if (!descendantNode) return; // Create node with data, inheriting any existing children

      const currentNode = {
        children: nodeById[descendantNode._id]?.children ?? [],
        ...descendantNode
      };
      nodeById[descendantNode._id] = currentNode; // Asking the parent, creating a placeholder if it hasn't been populated yet

      if (descendantNode.parentId) {
        if (!nodeById[descendantNode.parentId]) {
          nodeById[descendantNode.parentId] = {
            children: []
          };
        }

        currentNode.parent = nodeById[descendantNode.parentId];
      } // Add the node to the parent's children array


      currentNode.parent?.children?.push(currentNode);
    }); // After all the children have been added, sort them in place

    Object.values(nodeById).forEach(node => {
      if (node.children) {
        SortNodeChildren(node.children, node.shouldSortChildrenByRank);
      }
    });
    return rootNode;
  }, [fragmentData]);
};

export default useDescendantsAsTree;