import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpTopicPresentationTableOfContents_topicPresentationTocFragment;

import React, { useCallback, useContext, useState } from "react";
import { ScrollView, View } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import { useNavigation } from "@react-navigation/native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import CpTopicTableOfContentsItem from "components/topic/CpTopicTableOfContentsItem";
import CpTopicTableOfContentsPresentationItem from "components/topic/CpTopicTableOfContentsPresentationItem";
import CxPresentationSupport from "contexts/CxPresentationSupport";
import useAppTheme from "hooks/useAppTheme";
import useDescendantsAsTree, { isAncestorRevisionId } from "hooks/useDescendantsAsTree";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  childItem: css`
    /* Need to accommodate for nested sidebar as it'll throw off the contents of the childItem */
    width: calc(100% - ${({
    theme
  }) => theme.spacing.single}px);
  `,
  childItemSidebar: css`
    background-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorBase};
    width: ${({
    theme
  }) => theme.spacing.single}px;
    height: 100%;
  `,
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorBase};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
  `,
  title: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-weight: bold;
    margin-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  titleContainer: css`
    align-items: center;
    flex-direction: row;
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    /* 2px border radius to fit flush within borderRadii.sharp border surrounding it */
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-width: 2px;
    border-color: ${({
    theme
  }) => theme.brandColors.nodeNavigatorBase};
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
export const topicPresentationTocFragment = _CpTopicPresentationTableOfContents_topicPresentationTocFragment !== void 0 ? _CpTopicPresentationTableOfContents_topicPresentationTocFragment : _CpTopicPresentationTableOfContents_topicPresentationTocFragment = require("./__generated__/CpTopicPresentationTableOfContents_topicPresentationTocFragment.graphql");

/**
 * Renders first level descendants of a topic as a table of contents given a ResourceNode.
 * Presentation/training type nodes will render in their entirety with markers for completion, and may be nested within an accordion section node.
 */
const CpTopicPresentationTableOfContents = ({
  hideHeader,
  onSelect,
  rootResourceNodeKey,
  style
}) => {
  const {
    presentationCompletion
  } = useContext(CxPresentationSupport);
  const {
    brandColors,
    buttonColors,
    iconSizes
  } = useAppTheme();
  const navigation = useNavigation();
  const rootResourceNodeData = useFragment(topicPresentationTocFragment, rootResourceNodeKey);
  const defaultRevisionId = rootResourceNodeData.defaultResourceNode?.revisionId;
  const [showSection, setShowSection] = useState({});
  const handleShowSection = useCallback(node => {
    setShowSection({ ...showSection,
      [node._id]: !showSection[node._id]
    });
  }, [showSection]);
  let {
    selectedRevisionId
  } = useTopicContext();
  selectedRevisionId = selectedRevisionId || defaultRevisionId;

  if (selectedRevisionId) {
    let currentRevisionId = selectedRevisionId; // open all ancesstors of selectedRevisionId

    while (currentRevisionId) {
      const node = rootResourceNodeData.descendants.find(descendant => descendant.revisionId === currentRevisionId); // open section node if closed

      if (node && !node.isLeaf && !showSection[node._id]) {
        setShowSection({ ...showSection,
          [node._id]: true
        });
      }

      currentRevisionId = node?.parentRevisionId;
    }
  }

  const treeRoot = useDescendantsAsTree(rootResourceNodeData);
  const handlePress = useCallback(node => {
    // PSP pageview from Table of content (if psp)
    navigation.navigate(Routes.topic, // Is it a related topic node?
    node.resourceNodeRevisionId ? {
      resourceId: undefined,
      rootRevisionId: node.resourceNodeRevisionId,
      selectedRevisionId: undefined
    } : {
      resourceId: node.resourceId ?? undefined,
      rootRevisionId: rootResourceNodeData.revisionId,
      selectedRevisionId: node.revisionId
    });
    onSelect && onSelect(node);
  }, [rootResourceNodeData.revisionId, navigation, onSelect]);
  return <_StyledScrollView style={style} $_css={localStyles.container}>
      {!hideHeader && <_StyledView $_css2={localStyles.titleContainer}>
          <PrIcon fill={buttonColors.primary} icon={IconName.list} size={iconSizes.small} />
          <_StyledPrText children={translations.labels.tableOfContents} $_css3={localStyles.title} />
        </_StyledView>}
      {treeRoot.children.map((tocDataItem, index) => {
      const isPresentationType = ["ResourceNode::Presentation", "ResourceNode::Training"].includes(tocDataItem.type);
      const rootChildrenCount = treeRoot.children?.length ?? 0;
      /**
       * Section nodes (non-leaf presentation types) - will have an accordion icon and can expand/collapse to show their children
       * - Children of a section node are displayed if the parent is expanded, and have special nesting style
       * Otherwise display the item as a regular TOC item
       */

      if (tocDataItem.children?.length && isPresentationType) {
        // Section node handling
        return <React.Fragment key={tocDataItem.revisionId}>
              <CpTopicTableOfContentsPresentationItem icon={showSection?.[tocDataItem._id] ? IconName.keyboardArrowUp : IconName.keyboardArrowDown} isLastItem={index === rootChildrenCount - 1} isSectionNode={true} key={tocDataItem._id} node={tocDataItem} onPress={handleShowSection} />
              {showSection[tocDataItem._id] && tocDataItem.children.map(child => child ? <_StyledView2 key={child.revisionId} $_css4={styles.rowAndCenter}>
                      <_StyledView3 $_css5={localStyles.childItemSidebar} />
                      <_StyledCpTopicTableOfContentsPresentationItem fill={brandColors.secondary} icon={presentationCompletion[child.revisionId] ? IconName.checkCircle : undefined} isSelected={child.revisionId === selectedRevisionId} key={child._id} node={child} onPress={handlePress} $_css6={localStyles.childItem} />
                    </_StyledView2> : null)}
            </React.Fragment>;
      } else {
        const ItemComponent = isPresentationType ? CpTopicTableOfContentsPresentationItem : CpTopicTableOfContentsItem;
        return <ItemComponent fill={brandColors.secondary} icon={isPresentationType && presentationCompletion[tocDataItem.revisionId] ? IconName.checkCircle : undefined} isLastItem={index === rootChildrenCount - 1} isSelected={selectedRevisionId ? tocDataItem.revisionId === selectedRevisionId || isAncestorRevisionId(selectedRevisionId, tocDataItem) : false} key={tocDataItem._id} node={tocDataItem} onPress={handlePress} />;
      }
    })}
    </_StyledScrollView>;
};

export default CpTopicPresentationTableOfContents;

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpTopicPresentationTableOfContents___StyledScrollView",
  componentId: "sc-pxw56n-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicPresentationTableOfContents___StyledView",
  componentId: "sc-pxw56n-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicPresentationTableOfContents___StyledPrText",
  componentId: "sc-pxw56n-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpTopicPresentationTableOfContents___StyledView2",
  componentId: "sc-pxw56n-3"
})(["", ""], p => p.$_css4);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpTopicPresentationTableOfContents___StyledView3",
  componentId: "sc-pxw56n-4"
})(["", ""], p => p.$_css5);

var _StyledCpTopicTableOfContentsPresentationItem = _styled(CpTopicTableOfContentsPresentationItem).withConfig({
  displayName: "CpTopicPresentationTableOfContents___StyledCpTopicTableOfContentsPresentationItem",
  componentId: "sc-pxw56n-5"
})(["", ""], p => p.$_css6);