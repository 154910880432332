import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconLifestyle: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M281.38 195.72c-2.2 38.26-19.07 75.18-50.48 105.34-12.58 12.07-27.84 15.22-44.41 10.3a40.67 40.67 0 00-24.1-.06c-17.77 5.23-33.71 1.39-46.54-12.14-33.41-35.25-54.44-75.9-46.52-125.75 6.24-39.28 31.58-55.77 71-50.35 29.1 4 29.12 4 20.31-24.17-1.3-4.15-2.77-8.25-4.4-12.27-2.05-5-2.28-9.9 3.17-12.48 6.25-3 9.89.91 12.43 6.29a113.17 113.17 0 0110.32 36.12c.88 8.06 3.18 10.5 11.76 8.79 13.2-2.63 26.6-4.54 40.31-1.88 30.29 5.88 46.9 29.12 47.15 72.26zm-168.8-37.32c-12.72 7-19.54 33.21-13.12 43.86 1.05 1.75 2.55 3.48 4.4 2.37a5.51 5.51 0 001.87-4.71c-2.99-14.56.45-28.05 6.85-41.52z" />
      <Path d="M220.87 96.31c-2.59-.46-8.91-1-14.79-2.83-7.68-2.44-17.85-4.59-19.35-13C185 70.7 193.67 64 201.18 58.84c19-13.11 39.36-10.29 59.61-3.74 9.52 3.08 25.25 5.52 25.75 12.81.69 9.89-14.3 13.26-23.3 18.15-11.95 6.5-24.93 10.15-42.37 10.25z" />
    </Svg>
  )
}

export default IconLifestyle
