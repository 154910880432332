import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconBacteria: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M157.77 50.58c58.5.2 105.5 48.9 105 109-.4 60.6-48.1 109.7-106.5 109.6s-105.7-49.6-105.6-110.3c.2-60.5 47.6-108.5 107.1-108.3zM75 158.88c-.1 47.3 36 85.3 81.3 85.7 44.9.3 83-38.3 83.2-84.3.2-46.8-36.5-85-81.8-85.1-46.43-.1-82.63 36.6-82.7 83.7zM224.87 261.48c12.9-14.1 23.4-25.6 33-36.1 14.3 13.6 30.1 27.4 44.5 42.5 10.2 10.7 8.7 26.2-1.4 35.4-9.9 9-23.2 8-34.3-2.4-14.1-13.2-28.1-26.5-41.8-39.4z" />
      <Path d="M181.07 155.58c-.3 7.8-7.5 12.2-13.4 8.1a292.73 292.73 0 01-29-23c-1.9-1.7-1.8-8.2-.1-10.5s8-3.8 10-2.4c10.2 6.9 19.8 14.7 29.4 22.5 1.7 1.3 2.4 4 3.1 5.3zM127 139.28c-8.9 15.6-15.4 27.5-22.6 38.9-.8 1.2-6.6.9-8.6-.7s-3.6-6.3-2.7-8.3a304.76 304.76 0 0116.8-31.4c1.1-1.8 5.7-2.5 8.6-2.1s5.97 2.5 8.5 3.6zM189 167.48c4.9-12.5 8.6-23.4 13.5-33.8 1-2.1 6.5-3.9 8.9-3.1s5.7 5.8 5.1 8A268.59 268.59 0 01204 172c-.9 2.1-6.6 4.2-8.7 3.3-2.93-1.3-4.63-5.52-6.3-7.82zM153.77 176c2.9 1.6 4.9 2.3 6.3 3.6 7.6 6.9 15.6 13.5 22.3 21.2 2 2.3.5 7.8.5 11.8-3.7-.1-8.5 1.3-10.8-.5a241.75 241.75 0 01-23.6-21.6c-1.7-1.8-2.3-6.1-1.4-8.6s4.4-4.02 6.7-5.9zM117.87 186.28c1.2-1.3 3.7-6.2 6-6.1 3.3.1 8.8 3.3 9.4 6.1a201.45 201.45 0 015 32.8c.2 2.5-4.2 7.4-6.8 7.6s-7.6-3.5-8.2-6.2c-2.4-10.4-3.4-21-5.4-34.2zM200.67 105.58c-12.1 4.8-22.6 9.3-33.5 13-2.3.8-7.2-.7-8.1-2.5-1.4-2.9-1.8-7.8-.1-10.2 7.03-10.3 30.5-10.88 41.7-.3zM195.57 216.78c1.7-8.4 2.5-17.2 5.5-25.1 1.3-3.6 7-5.5 10.7-8.2 1.2 4.5 4.8 10.4 3.1 13.4-4.5 8-11.2 14.8-17.1 22a21.32 21.32 0 00-2.2-2.1zM141.67 89.88c-3.3 8.8-5 15.7-8.4 21.7-1.5 2.6-6.3 3.2-9.6 4.7-1.5-3.6-4.7-7.6-4.2-10.9 1.6-11.1 11.2-12.38 22.2-15.5zM91.37 136.78c2.1-4.9 4.1-9.8 7.7-18.3 2.5 6.7 5.8 11 4.8 13.7-1.2 3.2-6 5.1-9.3 7.6-1.1-1-2.2-2-3.2-3z" />
    </Svg>
  )
}

export default IconBacteria
