import _styled from "styled-components";
import React from "react";
import { ScrollView } from "react-native";
import CpAppFooter from "components/app/CpAppFooter";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpHelp from "components/common/CpHelp";
import useScreenLayout from "hooks/useScreenLayout";
import QlHelp from "query/QlHelp";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
/**
 * Container for the help page that fetches the Help & How-To tree for rendering
 */

const CtHelp = () => {
  const {
    contentMinHeight
  } = useScreenLayout();
  return <ScrollView contentContainerStyle={rnStyles.flexSingle}>
      <CpBackgroundHeader header={translations.screens.help}>
        <QlHelp>
          {response => {
          return response.resourceNodeByRevision?.descendants ? <_StyledCpHelp nodes={response.resourceNodeByRevision.descendants} $_css={[styles.marginVerticalDouble, css`
                    min-height: ${contentMinHeight}px;
                  `]} /> : null;
        }}
        </QlHelp>
        <CpAppFooter />
      </CpBackgroundHeader>
    </ScrollView>;
};

export default CtHelp;

var _StyledCpHelp = _styled(CpHelp).withConfig({
  displayName: "CtHelp___StyledCpHelp",
  componentId: "sc-4o70go-0"
})(["", ""], p => p.$_css);