import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconGastro: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M267.23 262.89c-5.8-8.4-13.8-6.5-20.8-6.6-41.7-.2-83.3.2-125-.3a136.35 136.35 0 01-34.2-4.8c-19.5-5.5-28.4-18-29.5-38.2-1-18.6 8.1-34.2 26.5-40.5a115.57 115.57 0 0135.5-6.1c41.6-.7 83.3-.2 125-.3 5.3 0 10.6.1 15.7-1.1 2.4-.6 6.1-4.1 5.9-5.9-.4-2.9-3-7.1-5.5-7.9-5.4-1.7-11.4-1.9-17.1-1.9-41.1-.1-82.3.3-123.4-.3a141.91 141.91 0 01-34.2-4.9c-16.8-4.6-26.7-16.4-28.2-34.1-1.7-19 4.9-34.4 22.5-43.1 6.9-3.4 14.9-5.1 22.6-6.3 9.9-1.5 20-1.3 29.9-2.7 12.5-1.9 24.5-4.4 31-18.1 4.2-8.9 15-11 23.2-6.7 7.5 3.9 10.5 12.4 7.7 21.6-4 13.6-13.7 22.2-25.7 28.5-16.3 8.5-34 10.6-52 11.8-5.3.4-10.8-.2-15.7 1.3-3.3 1.1-7.3 4.7-8.2 7.9-1.4 4.9 3 7.4 7.6 8 6.8.9 13.6 2 20.4 2q63.3.3 126.5 0c11.2 0 22 1.5 32.1 6.7 15.5 8.1 23 20.7 22.5 38.4s-8.9 30.6-24.9 36.8c-10 3.9-21.4 5.6-32.3 5.8-42.2.6-84.4.2-126.5.3a139.48 139.48 0 00-15.8.9c-4.9.6-9.8 2.3-9.9 8.3s4.8 7.6 9.8 8c5.3.4 10.5.5 15.8.5 41.1 0 82.3-.3 123.4.3a110.33 110.33 0 0132.3 5.3c20.4 6.9 28.9 20.5 28 40.7-.8 18.4-11.5 31.1-31.7 36.2-7.5 1.9-15.6 1.9-23.4 2.4-16.8 1-33.6 1.7-50.4 2.8-7.1.5-12.4 2.9-15.8 10.6-4.6 10.3-13.2 13-22 9.3s-12.3-11.2-10.4-22.1c2.4-13.2 10.5-22.4 22.6-26.3a232.93 232.93 0 0143-8.9c12.5-1.5 25.3-.3 37.9-.5 5.5.1 11.7.8 15.2-6.8z" />
    </Svg>
  )
}

export default IconGastro
