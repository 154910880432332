/**
 * @generated SignedSource<<7720293f470485285f5ed34dbea81a6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpTopicHeading_resourceNode$data = {
  readonly ancestors: ReadonlyArray<{
    readonly names: any;
  }>;
  readonly names: any;
  readonly " $fragmentType": "CpTopicHeading_resourceNode";
};
export type CpTopicHeading_resourceNode$key = {
  readonly " $data"?: CpTopicHeading_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicHeading_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "locale",
      "variableName": "locale"
    }
  ],
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicHeading_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "c2495a60be9a4392055cb12d825227f7";

export default node;
