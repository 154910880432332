var _QlTopicToc_resourceNodeTreeQuery;

import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
import React from "react";
import { graphql } from "react-relay";

/**
 * Used to fetch information about a subtree of a resource node tree
 */
const resourceNodeTreeQuery = _QlTopicToc_resourceNodeTreeQuery !== void 0 ? _QlTopicToc_resourceNodeTreeQuery : _QlTopicToc_resourceNodeTreeQuery = require("./__generated__/QlTopicToc_resourceNodeTreeQuery.graphql");

const QlTopicToc = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={resourceNodeTreeQuery} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlTopicToc;