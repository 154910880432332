import { useContext } from "react"

import { ImdSessionContext, ImdSessionContextType } from "contexts/CxImdSession"

/**
 * Returns the Imd Context
 */
function useImdSession(): ImdSessionContextType {
  const imdSessionContext = useContext(ImdSessionContext)
  if (!imdSessionContext) throw new Error("IMD Session Context is required")
  return imdSessionContext
}

export default useImdSession
