/**
 * @generated SignedSource<<8a3e271febc5919d6ab651be7c17c3b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpTopicSiblingNavigator_resourceNode$data = {
  readonly parent: {
    readonly shouldSortChildrenByRank: boolean;
  } | null;
  readonly revisionId: string;
  readonly root: {
    readonly revisionId: string;
  };
  readonly siblings: ReadonlyArray<{
    readonly names: any;
    readonly pinSelfToBottom: boolean;
    readonly pinSelfToTop: boolean;
    readonly rank: number;
    readonly revisionId: string;
  }>;
  readonly " $fragmentType": "CpTopicSiblingNavigator_resourceNode";
};
export type CpTopicSiblingNavigator_resourceNode$key = {
  readonly " $data"?: CpTopicSiblingNavigator_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicSiblingNavigator_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicSiblingNavigator_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shouldSortChildrenByRank",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "root",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "siblings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "locale",
              "variableName": "locale"
            }
          ],
          "kind": "ScalarField",
          "name": "names",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinSelfToBottom",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pinSelfToTop",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "daf917b7fafbfc48fd545b72a2328231";

export default node;
