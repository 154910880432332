import React from "react"

import { SupportedHeaderActions } from "contexts/__generated__/CxImdSession_appConfigFragment.graphql"
import Config from "services/Config"
import { AppName } from "services/Constants"

// Admin App exclusive button imports
import AdminSignoutButton from "components/primitives/PrActionButton/buttons/AdminSignoutButton"

// Button imports
import ChangeClinicButton from "components/primitives/PrActionButton/buttons/ChangeClinicButton"
import DarkModeButton from "components/primitives/PrActionButton/buttons/DarkModeButton"
import HelpButton from "components/primitives/PrActionButton/buttons/HelpButton"
import ImdForProfessionalsButton from "components/primitives/PrActionButton/buttons/ImdForProfessionalsButton"
import LanguageAndRegionButton from "components/primitives/PrActionButton/buttons/LanguageAndRegionButton"
import LanguageButton from "components/primitives/PrActionButton/buttons/LanguageButton"
import ManageAccountButton from "components/primitives/PrActionButton/buttons/ManageAccountButton"
import PackageSessionButton from "components/primitives/PrActionButton/buttons/PackageSessionButton"
import PatientSessionButton from "components/primitives/PrActionButton/buttons/PatientSessionButton"
import PickRegionButton from "components/primitives/PrActionButton/buttons/PickRegionButton"
import ShortcutButton from "components/primitives/PrActionButton/buttons/ShortcutButton"
import SignoutButton from "components/primitives/PrActionButton/buttons/SignoutButton"

const getActionButtonComponent = (
  actionButton: SupportedHeaderActions,
): React.FC<ActionButtonProps> => {
  switch (actionButton) {
    case "CHANGE_CLINIC":
      return ChangeClinicButton
    case "EDUCATE":
      return PatientSessionButton
    case "HELP":
      return HelpButton
    case "IMD_FOR_PROFESSIONALS":
      return ImdForProfessionalsButton
    case "MANAGE_ACCOUNT":
      return ManageAccountButton
    case "MODE":
      return DarkModeButton
    case "PACKAGES":
      return PackageSessionButton
    case "PICK_LANGUAGE":
      return LanguageButton
    case "PICK_LANGUAGE_AND_REGION":
      return LanguageAndRegionButton
    case "PICK_REGION":
      return PickRegionButton
    case "SHORTCUTS":
      return ShortcutButton
    case "SIGN_OUT":
      return Config.APP_NAME === AppName.ImdAdmin ? AdminSignoutButton : SignoutButton
    default:
      throw new Error(`ActionButton ${actionButton} not found`)
  }
}

export enum ActionButtonUserType {
  AccountMenu,
  NavHeader,
}

export interface ActionButtonProps {
  onPress?: () => void
  type: ActionButtonUserType
}

export interface PrActionButtonProps extends Omit<ActionButtonProps, "onComponentWidth"> {
  actionButton: SupportedHeaderActions
}

/**
 * Displays the header action button with its correct type
 */
const PrActionButton: React.FC<PrActionButtonProps> = ({ actionButton, onPress, type }) => {
  const ActionButtonComponent = getActionButtonComponent(actionButton)

  return React.createElement(ActionButtonComponent, {
    onPress,
    type,
  })
}

export default PrActionButton
