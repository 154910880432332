import React, { useEffect, useState } from "react"
import { useFragment } from "react-relay"

import CxPresentationSupport, {
  CxPresentationSupportData,
  initialPresentationData,
} from "contexts/CxPresentationSupport"

import useImdSession from "hooks/useImdSession"

import { topicPresentationTocFragment } from "components/topic/CpTopicPresentationTableOfContents"
import { CpTopicPresentationTableOfContents_topicPresentationTocFragment$key } from "components/topic/__generated__/CpTopicPresentationTableOfContents_topicPresentationTocFragment.graphql"
import Log from "services/Log"

/**
 * Wraps the standard topic layout and establishes PresentationContext
 */
const CxPresentation: React.FC<{
  children: React.ReactNode
  currentRevisionId: string
  rootResourceNodeKey: CpTopicPresentationTableOfContents_topicPresentationTocFragment$key
}> = ({ children, currentRevisionId, rootResourceNodeKey }) => {
  const { user } = useImdSession()

  const presentationToc = useFragment(topicPresentationTocFragment, rootResourceNodeKey)

  const [presentationContext, setPresentationContext] =
    useState<CxPresentationSupportData>(initialPresentationData)

  useEffect(() => {
    // If presentationIds doesn't already exist, we want to populate it
    if (presentationToc.descendants?.length && presentationContext.presentationIds?.length === 0) {
      Log.info("CpTopic - Establishing PresentationContext for: ", presentationToc)
      const localPresentationIds: string[] = []
      const localPresentationCompletion: { [index: string]: boolean } = {}

      presentationToc.descendants?.forEach((descendant) => {
        // For populating Context, we're only concerned with leaf nodes of type Presentation/Training
        if (
          descendant &&
          descendant.isLeaf &&
          ["ResourceNode::Presentation", "ResourceNode::Training"].includes(descendant.type)
        ) {
          const revisionId = descendant.revisionId
          // Add current descendant to array of all P/T descendants that are to be included in presentation.
          localPresentationIds.push(revisionId)
          // Set current descendants default viewed status. This gets updated in CpResourceNode when the matching resource node is viewed
          localPresentationCompletion[revisionId] =
            // Training node progression is persistent, so we want to establish the correct 'viewed' status here
            descendant.type === "ResourceNode::Training" && user
              ? !!presentationToc.trainingNodeCompletions?.find(
                  (completion) => completion.resourceNodeRevisionId === revisionId,
                )
              : revisionId === currentRevisionId
        }
      })
      setPresentationContext({
        currentIndex: localPresentationIds.indexOf(currentRevisionId),
        presentationCompletion: localPresentationCompletion,
        presentationIds: localPresentationIds,
      })
    }
  }, [
    presentationToc,
    presentationContext.presentationIds,
    setPresentationContext,
    user,
    currentRevisionId,
  ])

  return (
    <CxPresentationSupport.Provider
      value={{
        ...presentationContext,
        setPresentationContext,
      }}
    >
      {children}
    </CxPresentationSupport.Provider>
  )
}

export default CxPresentation
