import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpContentSearchPicker_fragmentData;

import { useNavigation, useRoute } from "@react-navigation/core";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Linking, Pressable, ScrollView, View } from "react-native";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
// eslint-disable-line no-restricted-imports
import CpSearchBar from "components/common/CpSearchBar";
import CpContentSearchItem from "components/content/CpContentSearchItem";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useBackendSupportedLocale from "hooks/useBackendSupportedLocale";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import QlHomeSearch from "query/QlHomeSearch";
import { AnalyticsEventName } from "services/Analytics";
import { css, styles } from "services/Theme";
import translations from "translations";
const CpContentSearchPicker_fragment = _CpContentSearchPicker_fragmentData !== void 0 ? _CpContentSearchPicker_fragmentData : _CpContentSearchPicker_fragmentData = require("./__generated__/CpContentSearchPicker_fragmentData.graphql");
const localStyles = {
  overlay: css`
    bottom: 0px;
    left: 0px;
    position: fixed;
    right: 0px;
    top: 0px;
  `,
  textInput: css`
    background-color: ${({
    theme
  }) => theme.textColors.textInputBackground};
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    border-radius: 30px;
    border-width: 0px;
    color: ${({
    theme
  }) => theme.textColors.default};
    height: 48px;
    outline-width: 0px;
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("xlarge")};
    padding-left: ${({
    theme
  }) => theme.spacing.single * 3}px;
    padding-right: ${({
    theme
  }) => theme.spacing.single * 3}px;
  `,
  textInputFocus: css`
    border-width: 1px;
  `
};

/**
 * A context search text input that presents search options for selection.
 *
 * The height and borderWidth are passed as props because they affect the
 * layout of the results menu which has to be calculated.
 */
const CpContentSearchPicker = ({
  onLayout,
  placeholder,
  placeholderTextColor,
  searchConfig = null,
  style,
  textInputStyle
}) => {
  const locale = useBackendSupportedLocale();
  const {
    navigate
  } = useNavigation();
  const {
    appConfig,
    licensee,
    organization
  } = useImdSession();
  const {
    params
  } = useRoute();
  const searchConfigData = useFragment(CpContentSearchPicker_fragment, searchConfig);
  const searchMethod = searchConfigData?.searchMethod ?? "org_accessible";
  let searchId;

  if (searchConfigData?.searchMethod === "single_tab") {
    const tabIndex = params?.tab ? parseInt(params?.tab, 10) : 0;
    searchId = appConfig?.appHome?.tabs?.[tabIndex]._id;
  }

  const orgSearchId = organization?.id || licensee?.id;
  const {
    height: windowHeight
  } = useScreenLayout();
  const theme = useAppTheme();
  const {
    trackEvent
  } = useAnalytics();
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const hideSearchResults = useCallback(() => setSearchResultsVisible(false), []);
  const [activeSearchQuery, setActiveSearchQuery] = useState(undefined);
  const handleActiveSearchQuery = useCallback(value => {
    if (value) {
      setSearchResultsVisible(true);
      setActiveSearchQuery(value);
    }
  }, []);
  const ref = useRef(null); // Handles selection of a search result

  const handleSelect = useCallback(contentItem => {
    // Clear the search bar before navigating
    ref.current?.clear();

    if (contentItem.revisionId === contentItem.rootRevisionId) {
      navigate(Routes.topic, {
        rootRevisionId: contentItem.revisionId
      });
    } else {
      navigate(Routes.topic, {
        rootRevisionId: contentItem.rootRevisionId,
        selectedRevisionId: contentItem.revisionId
      });
    }

    setSearchResultsVisible(false);
    trackEvent({
      data: {
        targetRevisionId: contentItem.revisionId
      },
      eventType: AnalyticsEventName.nav_search_item
    });
  }, [navigate, trackEvent]);
  const handleAuxSelect = useCallback(contentItem => {
    // Clear the search bar before navigating
    ref.current?.clear();

    if (contentItem.revisionId === contentItem.rootRevisionId) {
      Linking.openURL(`${location.origin}/${Routes.topic}/${contentItem.revisionId}`);
      navigate(Routes.topic, {
        rootRevisionId: contentItem.revisionId
      });
    } else {
      Linking.openURL(`${location.origin}/${Routes.topic}/${contentItem.rootRevisionId}/${contentItem.revisionId}`);
    }

    trackEvent({
      data: {
        targetRevisionId: contentItem.revisionId
      },
      eventType: AnalyticsEventName.nav_search_item
    });
  }, [navigate, trackEvent]); // The layout rectangle where the search results are to be positioned

  const [inputLayout, setInputLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const popupStyle = useMemo(() => {
    const borderRadius = inputLayout.height / 2;
    return {
      backgroundColor: theme.brandColors.background,
      borderBottomLeftRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      borderColor: theme.brandColors.searchPopupBorder,
      borderWidth: 1,
      height: windowHeight - (inputLayout?.top ?? 0) - inputLayout.height - theme.spacing.quadruple,
      left: inputLayout.x,
      marginTop: -borderRadius,
      paddingBottom: 20,
      paddingTop: borderRadius,
      position: "absolute",
      shadowColor: "black",
      shadowOffset: {
        height: 3,
        width: 0
      },
      shadowOpacity: 0.33,
      shadowRadius: 3.33,
      top: inputLayout.y + inputLayout.height,
      width: inputLayout.width,
      zIndex: -1
    };
  }, [inputLayout, theme, windowHeight]);
  const homeSearchVariables = useMemo(() => ({
    contentFilters: {
      text: activeSearchQuery
    },
    locale,
    organizationId: orgSearchId ?? "",
    searchId,
    searchMethod
  }), [activeSearchQuery, locale, orgSearchId, searchId, searchMethod]);
  return <View onLayout={onLayout} style={style}>
      <CpSearchBar onInputLayout={setInputLayout} onQueryChanged={handleActiveSearchQuery} placeholder={placeholder} placeholderTextColor={placeholderTextColor} ref={ref} textInputStyle={textInputStyle} />

      {
      /* Popup and background overlay */
    }
      {searchResultsVisible && activeSearchQuery && <View style={popupStyle}>
          <_StyledPressable accessibilityRole="button" onPress={hideSearchResults} $_css={localStyles.overlay} />
          <QlHomeSearch variables={homeSearchVariables}>
            {homeSearchResponse => {
          if (homeSearchResponse) {
            const edges = homeSearchResponse.node?.content?.edges ?? [];
            return edges.length === 0 ? <_StyledPrText children={translations.labels.noResultsFound} $_css2={styles.paddingDouble} /> : <ScrollView accessibilityRole="list">
                    {edges.map(edge => {
                return edge?.node ? <CpContentSearchItem contentItem={edge.node} key={edge.cursor} onAuxPress={handleAuxSelect} onPress={handleSelect} /> : null;
              })}
                  </ScrollView>;
          } else {
            return <PrActivityIndicator />;
          }
        }}
          </QlHomeSearch>
        </View>}
    </View>;
};

export default CpContentSearchPicker;

var _StyledPressable = _styled(Pressable).withConfig({
  displayName: "CpContentSearchPicker___StyledPressable",
  componentId: "sc-2rkj6a-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpContentSearchPicker___StyledPrText",
  componentId: "sc-2rkj6a-1"
})(["", ""], p => p.$_css2);