import { useRoute } from "@react-navigation/native"
import { useMemo } from "react"

import {
  ScreenLayoutHeights,
  ScreenLayoutWidth,
} from "contexts/__generated__/CxImdSession_appConfigFragment.graphql"
import useScreenLayout from "hooks/useScreenLayout"
import { ScreenSizes } from "services/Constants"

export interface HideRuleProps {
  rules:
    | undefined
    | null
    | ReadonlyArray<{
        readonly hide: null | boolean
        readonly invertMatch: null | boolean
        readonly rank: null | number
        readonly routes: ReadonlyArray<string>
        readonly routesInvert: null | boolean
        readonly routesRank: null | number
        readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>
        readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>
      }>
}

/**
 * A hook that decides if a customization should be shown or not depending on the set rules.
 * For now we support both routes and screen width/height as rules.
 * Returns true when the thing in question should be hidden.
 */
export const useHideRules = ({ rules }: HideRuleProps): null | boolean => {
  const { isMediumScreen, isNarrowScreen, isShortScreen, isTallScreen, isWideScreen } =
    useScreenLayout()
  const route = useRoute()

  return useMemo(() => {
    if (!rules) {
      return false
    }

    const headerRules = rules
      ? [...rules].sort((a, b) => (a.rank || Infinity) - (b.rank || Infinity))
      : []

    for (const rule of headerRules) {
      // Will also match all routes if no routes are specified
      const isRouteIncluded = rule.routes.includes(route.name) || rule.routes.length === 0
      const invertRoute = rule.routesInvert

      const isScreenHeightIncluded =
        (isShortScreen && rule.screenHeightIs.includes(ScreenSizes.IsShortScreen)) ||
        (isTallScreen && rule.screenHeightIs.includes(ScreenSizes.IsTallScreen))

      const isScreenWidthIncluded =
        (isNarrowScreen && rule.screenWidthIs.includes(ScreenSizes.IsNarrowScreen)) ||
        (isMediumScreen && rule.screenWidthIs.includes(ScreenSizes.IsMediumScreen)) ||
        (isWideScreen && rule.screenWidthIs.includes(ScreenSizes.IsWideScreen))
      const invertScreenMatch = rule.invertMatch

      // Will match the current rule if:
      // 1. the route is included in the rule, or if it's not and the rule is inverted
      // 2. the screen height is included in the rule, or if it's not and the rule is inverted
      // 3. the screen width is included in the rule, or if it's not and the rule is inverted
      if (
        (isRouteIncluded && !invertRoute) ||
        (!isRouteIncluded && invertRoute) ||
        (isScreenHeightIncluded && !invertScreenMatch) ||
        (!isScreenHeightIncluded && invertScreenMatch) ||
        (isScreenWidthIncluded && !invertScreenMatch) ||
        (!isScreenWidthIncluded && invertScreenMatch)
      ) {
        return rule.hide
      }
    }
    return false
  }, [isMediumScreen, isNarrowScreen, isShortScreen, isTallScreen, isWideScreen, route.name, rules])
}
