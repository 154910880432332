import React from "react"

import PrActivityIndicator from "components/primitives/PrActivityIndicator"

import CtGraphqlQueryWithoutIndicator, {
  CtGraphqlQueryWithoutIndicatorProps,
} from "components/containers/CtGraphqlQueryWithoutIndicator"

interface BaseQueryType {
  response: unknown
  variables: Record<string, unknown>
}

/**
 * Props for the fetcher with indicator
 */
interface CtGraphqlQueryProps<
  QueryType extends BaseQueryType,
  ProcessedType = QueryType["response"],
> extends Omit<CtGraphqlQueryWithoutIndicatorProps<QueryType, ProcessedType>, "children"> {
  children: (response: ProcessedType, refresh: () => void) => null | React.ReactElement
}

/**
 * Fetches a graphql query in the background.
 *   For first load shows a spinner
 *   For all subsequent loads shows old content until new content is available
 */
function CtGraphqlQuery<QueryType extends BaseQueryType, ProcessedType = QueryType["response"]>(
  props: CtGraphqlQueryProps<QueryType, ProcessedType>,
): null | React.ReactElement {
  const { children, ...queryProps } = props
  return (
    <CtGraphqlQueryWithoutIndicator<QueryType, ProcessedType> {...queryProps}>
      {(response, refresh) => {
        if (!response) {
          return <PrActivityIndicator />
        } else {
          return children(response, refresh)
        }
      }}
    </CtGraphqlQueryWithoutIndicator>
  )
}

export default CtGraphqlQuery
