import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { View } from "react-native";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import PrText from "components/primitives/PrText";
import translations from "translations";
/**
 * A fallback component for when a Resource is not available to render
 */

const CpMissingResource = ({
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  return <View style={style}>
      <CpUpdatePageTitle title={formatMessage(translations.labels.missingResourceTitle)} />
      <PrText>
        <FormattedMessage id="z2UdI/" defaultMessage="Unable to find resource. If the problem persists contact support@imdhealth.com" />
      </PrText>
    </View>;
};

export default CpMissingResource;