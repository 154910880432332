/**
 * @generated SignedSource<<1e1c69e68eb8e9f079053a5ae9226d6c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpTopicTableOfContents_rootResourceNode$data = {
  readonly children: ReadonlyArray<{
    readonly _id: string;
    readonly id: string;
    readonly names: any;
    readonly rank: number;
    readonly resourceId: string | null;
    readonly resourceNodeRevisionId: string | null;
    readonly revisionId: string;
    readonly type: string;
  }>;
  readonly defaultResourceNode: {
    readonly revisionId: string;
  } | null;
  readonly id: string;
  readonly revisionId: string;
  readonly " $fragmentSpreads": FragmentRefs<"useDescendantsAsTree_rootResourceNode">;
  readonly " $fragmentType": "CpTopicTableOfContents_rootResourceNode";
};
export type CpTopicTableOfContents_rootResourceNode$key = {
  readonly " $data"?: CpTopicTableOfContents_rootResourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicTableOfContents_rootResourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicTableOfContents_rootResourceNode",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDescendantsAsTree_rootResourceNode"
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": (v0/*: any*/),
          "kind": "ScalarField",
          "name": "names",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resourceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "resourceNodeRevisionId",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "defaultResourceNode",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    (v1/*: any*/)
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "ba594d2e414231731dad7ab29546a7d2";

export default node;
