/**
 * @generated SignedSource<<29d212d9aeb8cdc09aff4e356b0aafd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpTopicDepthOneAncestorButton_resourceNode$data = {
  readonly ancestors: ReadonlyArray<{
    readonly names: any;
    readonly parentRevisionId: string | null;
    readonly revisionId: string;
  }>;
  readonly rootRevisionId: string;
  readonly " $fragmentType": "CpTopicDepthOneAncestorButton_resourceNode";
};
export type CpTopicDepthOneAncestorButton_resourceNode$key = {
  readonly " $data"?: CpTopicDepthOneAncestorButton_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicDepthOneAncestorButton_resourceNode">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicDepthOneAncestorButton_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "locale",
              "variableName": "locale"
            }
          ],
          "kind": "ScalarField",
          "name": "names",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "revisionId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentRevisionId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootRevisionId",
      "storageKey": null
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};

(node as any).hash = "0dccca03fe2df87b935ac56d65fa383b";

export default node;
