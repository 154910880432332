import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconCannabis: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M50 204s35.7-2.4 71.1 12.6c-6.6-7.8-13.1-16.4-19-25.9C72.9 143 70.2 91.4 70.2 91.4s44.8 25.6 74.1 73.3c4.3 7 8 14.1 11.2 21.1-2-11.7-3.3-24.2-3.3-37.4 0-61 27-110.5 27-110.5s27 49.5 27 110.5c0 13.1-1.3 25.7-3.3 37.4 3.2-7 6.9-14.1 11.2-21.1 29.3-47.6 74.1-73.3 74.1-73.3s-2.6 51.6-31.9 99.2c-5.9 9.6-12.4 18.2-19 25.9 35.4-15 71.1-12.6 71.1-12.6s-21.6 26.9-57.1 42c-13.5 5.8-27.1 9-38.8 10.7 5.5.4 11.4 1.2 17.3 2.7 25.8 6.3 43.8 22.1 43.8 22.1s-23.9 5.6-49.8-.8c-16.8-4.1-30.1-12.1-37.5-17.3l6.2 49.4c.3 2.3-.4 4.7-1.8 6.5-1.4 1.8-3.5 2.8-5.7 2.8h-11.8c-2.2 0-4.2-1-5.7-2.8-1.4-1.8-2.1-4.1-1.8-6.5l6.2-49.4c-7.4 5.2-20.7 13.2-37.5 17.3-25.9 6.3-49.8.8-49.8.8s18-15.8 43.8-22.1c6-1.5 11.8-2.3 17.4-2.7-11.7-1.8-25.3-5-38.8-10.7-35.4-15-57-41.9-57-41.9z" />
    </Svg>
  )
}

export default IconCannabis
