import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom - converted official LinkedIn png to svg
const LinkedIn: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 C14.326888,64 0,49.673112 0,32 C0,14.326888 14.326888,0 32,0 Z M22.464,26.208 L15.424,26.208 L15.424,48.768 L22.464,48.768 L22.464,26.208 Z M40.2231469,25.5900944 C37.9004594,25.5900944 35.2569661,26.5123134 33.5842826,29.3472077 L33.5842826,29.3472077 L33.5842826,26.208 L26.816,26.208 L26.816,48.768 L33.8646253,48.768 L33.8646253,36.7721081 C34.0107637,33.5337839 35.252015,31.8547475 37.9074498,31.8547475 C40.5628846,31.8547475 41.5206623,33.2294304 41.6797611,36.7721081 L41.6797611,36.7721081 L41.6797611,48.768 L48.6923605,48.768 L48.6923605,36.3856449 C48.6923605,31.3287182 48.073705,25.5900944 40.2231469,25.5900944 Z M18.944,14.976 C16.6995148,14.976 14.88,16.8026782 14.88,19.056 C14.88,21.3093218 16.6995148,23.136 18.944,23.136 C21.1884852,23.136 23.008,21.3093218 23.008,19.056 C23.008,16.8026782 21.1884852,14.976 18.944,14.976 Z" />
    </Svg>
  )
}

export default LinkedIn
