export enum DrawingToolsActions {
  ClearContext = "CLEAR_CONTEXT",
  GenerateStageRef = "GENERATE_STAGE_REF",
  SetCanvasPaths = "SET_CANVAS_PATHS",
  SetDrawingOptions = "SET_DRAWING_OPTIONS",
  SetUploadedImage = "SET_UPLOADED_IMAGE",
}

export enum DrawingMode {
  Pen = "pen",
  Eraser = "eraser",
}
