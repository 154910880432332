import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Bookmark: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M36.0805369,0 C37.6778523,0 39.0637584,0.609375 40.238255,1.828125 C41.4127517,3.046875 42,4.453125 42,6.046875 L42,54 L21,45 L0,54 L0,6.046875 C0,4.453125 0.587248322,3.046875 1.76174497,1.828125 C2.93624161,0.609375 4.32214765,0 5.91946309,0 L36.0805369,0 Z M20.9986867,9.5 C20.216521,9.5 19.4946876,9.93172007 19.1186878,10.6179049 C19.0842122,10.6690153 19.0572781,10.7223007 19.0324988,10.7777609 L16.236741,17.0273712 L9.48382797,17.7831531 C9.43319189,17.7885904 9.38578791,17.7972901 9.33730656,17.8092521 C8.56160499,17.9495339 7.91949648,18.5117487 7.67062556,19.2718806 C7.42714146,20.02875 7.61998948,20.8606539 8.15867113,21.4348307 C8.19206939,21.4761541 8.23193183,21.5174774 8.27071691,21.5533635 L10.9932139,24.0545125 C11.9122048,24.8983784 12.7568576,25.6748221 13.2944619,26.1674397 L11.9197464,32.8977055 C11.7538324,33.7328718 12.087815,34.5930496 12.7611671,35.0856672 C13.3871151,35.5456612 14.2446963,35.623958 14.9460598,35.2933713 C14.9977733,35.2694473 15.047332,35.2455233 15.097968,35.216162 L20.9976094,31.8178616 L26.8972507,35.2150745 C26.9478868,35.2444358 26.9963682,35.2683599 27.0470042,35.2890215 C27.3378923,35.4282159 27.6524824,35.5010755 27.9756914,35.5 C28.4324934,35.5 28.8688255,35.3564438 29.2308196,35.092192 C29.9095585,34.5984869 30.2413864,33.7415715 30.0787045,32.8900934 L28.703989,26.1739645 L33.7288113,21.5577133 C33.7675964,21.5229147 33.8063815,21.4826788 33.8408571,21.4446179 C34.3795387,20.870441 34.5734641,20.0341873 34.3289027,19.2784053 C34.0821865,18.5160986 33.440078,17.9560587 32.6654538,17.811427 C32.6137403,17.799465 32.5641816,17.7907653 32.5157003,17.7864155 L25.7627872,17.0306335 L22.9648747,10.7788484 C22.9379407,10.7223007 22.9088519,10.6690153 22.8786857,10.6168174 C22.4983764,9.93172007 21.7786978,9.5 20.9986867,9.5 Z" />
    </Svg>
  )
}

export default Bookmark
