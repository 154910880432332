import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { Bling } from "@atmedia/react-gpt";
import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { View } from "react-native";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import { styles } from "services/Theme";
import translations from "translations";
Bling.enableSingleRequest();
// These slots are defined in Google Ad Manager
const slotMap = {
  hcpBox: {
    adUnitPath: "/120774461/hcp-box",
    slotSize: [728, 360]
  },
  largeHcpLeaderboard: {
    adUnitPath: "/120774461/hcp-leaderboard",
    slotSize: [600, 100]
  },
  largePatientLeaderboard: {
    adUnitPath: "/120774461/patient-leaderboard",
    slotSize: [600, 100]
  },
  smallPatientLeaderboard: {
    adUnitPath: "/120774461/patient-leaderboard-small",
    slotSize: [300, 50]
  }
}; // Enums matched from app_advertisement_type's AdvertisementPlacement

export let AdPlacement;
/**
 * An advertising inventory slot. To prevent ads inadvertently showing while loading configs, external guards should be placed around this component.
 */

(function (AdPlacement) {
  AdPlacement["PatientSessionStart"] = "patient_session_start";
  AdPlacement["ViewPatientSession"] = "view_patient_session";
})(AdPlacement || (AdPlacement = {}));

const CpAdSlot = ({
  adManagerId,
  adUnit,
  labelPlacement = "horizontal",
  slot
}) => {
  const {
    locale
  } = useIntl();
  const {
    appConfig,
    licensee,
    organization
  } = useImdSession();
  const adPath = adManagerId && adUnit ? `/${adManagerId}/${adUnit}` : slotMap[slot]?.adUnitPath;
  const slotSize = slotMap[slot]?.slotSize;
  const adOrg = organization || licensee;
  const targeting = {
    countryCode: adOrg?.countryCode,
    locale,
    organizationIds: [adOrg?._id, ...(adOrg?.ancestorIds ?? [])],
    organizationType: adOrg?.type,
    postalCode: adOrg?.postalCode,
    subdivisionCode: adOrg?.subdivisionCode
  }; // we have to wait for the ad to load to render the Advertisement label in case the user
  // has adblock enabled, otherwise the label will be rendered even without an ad.

  const [renderLabel, setRenderLabel] = useState(false);
  const handleRenderLabel = useCallback(() => {
    setRenderLabel(true);
  }, []); // Internal guard to ensure we only show ads if:
  //   - ads are not disabled for the organization
  //   - path or slot are defined
  // Additionally, we prevent loading PATIENT facing ads for IMD Health memberships, as that scenario shouldn't be encountered.
  // This doubles as a workaround on ViewSummary to prevent ads from showing on licensee load, and then disappearing afterwards.

  if (appConfig?.appAdvertisement?.hideAds || !adPath || !slotSize || licensee?._id === organization?._id && adPath.includes("patient")) {
    return null;
  }

  return <_StyledView $_css={labelPlacement === "vertical" ? styles.rowAndCenter : styles.center}>
      <_StyledView2 $_css2={labelPlacement === "vertical" ? styles.rowAndCenter : undefined}>
        {renderLabel && <_StyledPrText children={translations.labels.advertisement} $_css3={[styles.advertisementText, labelPlacement === "vertical" ? styles.verticalText : undefined]} />}

        <Bling adUnitPath={adPath} onSlotOnload={handleRenderLabel} slotSize={slotSize} targeting={targeting} />
      </_StyledView2>
    </_StyledView>;
};

export default CpAdSlot;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAdSlot___StyledView",
  componentId: "sc-wrs9pr-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAdSlot___StyledView2",
  componentId: "sc-wrs9pr-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAdSlot___StyledPrText",
  componentId: "sc-wrs9pr-2"
})(["", ""], p => p.$_css3);