/**
 * @generated SignedSource<<9f058a3970299c96045bcb88e22d8e99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpShortcutButton_userShortcut$data = {
  readonly currentMembership: {
    readonly _id: string;
    readonly id: string;
    readonly personalShortcuts: ReadonlyArray<{
      readonly id: string;
      readonly resourceNode: {
        readonly revisionId: string;
      };
    }>;
  } | null;
  readonly " $fragmentType": "CpShortcutButton_userShortcut";
};
export type CpShortcutButton_userShortcut$key = {
  readonly " $data"?: CpShortcutButton_userShortcut$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpShortcutButton_userShortcut">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpShortcutButton_userShortcut",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Membership",
      "kind": "LinkedField",
      "name": "currentMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceNodeShortcut",
          "kind": "LinkedField",
          "name": "personalShortcuts",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ResourceNode",
              "kind": "LinkedField",
              "name": "resourceNode",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "revisionId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "d6c5ecba5cd8253a84779565dfde33e7";

export default node;
