import { defineMessages } from "react-intl";
export default defineMessages({
  back: {
    id: "q40/aQ",
    defaultMessage: "Back"
  },
  backTo: {
    id: "lOoSdV",
    defaultMessage: "Back to {label}"
  },
  cancel: {
    id: "qhBYn3",
    defaultMessage: "Cancel"
  },
  changeCountryOrRegion: {
    id: "79LeQQ",
    defaultMessage: "Change Country/Region"
  },
  changeMembership: {
    id: "ph4LQF",
    defaultMessage: "Change Membership"
  },
  clearAndReturnHome: {
    id: "l8MB9+",
    defaultMessage: "Clear history and return home"
  },
  close: {
    id: "tl6ue5",
    defaultMessage: "Close"
  },
  confirm: {
    id: "ChV9sB",
    defaultMessage: "Confirm"
  },
  continue: {
    id: "SM9d14",
    defaultMessage: "Continue"
  },
  continueAs: {
    id: "yTDrGp",
    defaultMessage: "Continue as {name}"
  },
  continueSession: {
    id: "yVs445",
    defaultMessage: "Continue session"
  },
  createAccount: {
    id: "qWVHVf",
    defaultMessage: "Create Account"
  },
  delete: {
    id: "YsmRNp",
    defaultMessage: "Delete"
  },
  details: {
    id: "W/9YKS",
    defaultMessage: "Details"
  },
  draw: {
    id: "9ldr01",
    defaultMessage: "Draw"
  },
  edit: {
    id: "VCgd3K",
    defaultMessage: "Edit"
  },
  emailCSV: {
    id: "QjCkbh",
    defaultMessage: "Email CSV"
  },
  forgotPassword: {
    id: "kuUFBI",
    defaultMessage: "I forgot my password"
  },
  fullscreen: {
    id: "l/fMje",
    defaultMessage: "Fullscreen"
  },
  fullscreenClose: {
    id: "OX6SEI",
    defaultMessage: "Close Fullscreen"
  },
  home: {
    id: "SY/eXr",
    defaultMessage: "Back to home"
  },
  learnMore: {
    id: "9DZLP4",
    defaultMessage: "Learn more"
  },
  less: {
    id: "ca5Q1f",
    defaultMessage: "Less"
  },
  loadOrSavePackages: {
    id: "irZ4ep",
    defaultMessage: "Load or Save Packages"
  },
  more: {
    id: "zRDZO8",
    defaultMessage: "More"
  },
  new: {
    id: "0RroJi",
    defaultMessage: "New"
  },
  next: {
    id: "v6m4Z7",
    defaultMessage: "Next"
  },
  practitionerCta: {
    id: "SIoiHY",
    defaultMessage: "IMD for Healthcare Professionals"
  },
  previous: {
    id: "1h1KnC",
    defaultMessage: "Previous"
  },
  resendPasswordReset: {
    id: "J3hjte",
    defaultMessage: "Email a new reset code"
  },
  resendSignup: {
    id: "/QWK0n",
    defaultMessage: "Email a new confirmation code"
  },
  restart: {
    id: "PvTf0e",
    defaultMessage: "Restart"
  },
  reviewAndSend: {
    id: "K3zfCk",
    defaultMessage: "Review & Send"
  },
  root: {
    id: "XCx5nh",
    defaultMessage: "Go to Root"
  },
  saveAsTemplate: {
    id: "UZFM+F",
    defaultMessage: "Save current selection as template"
  },
  saveChanges: {
    id: "Jdfgw3",
    defaultMessage: "Save Changes"
  },
  savedPackages: {
    id: "TK9/c6",
    defaultMessage: "Saved Packages"
  },
  selectSection: {
    id: "b86Fa8",
    defaultMessage: "Select Section"
  },
  send: {
    id: "h2uMe+",
    defaultMessage: "Send"
  },
  showMore: {
    id: "5+Dbkl",
    defaultMessage: "Show more"
  },
  signin: {
    id: "FJQF2l",
    defaultMessage: "Sign in"
  },
  signinOrSignup: {
    id: "L0qeHM",
    defaultMessage: "Sign In or Sign Up"
  },
  signout: {
    id: "VF52yh",
    defaultMessage: "Sign out"
  },
  signup: {
    id: "M0hE5H",
    defaultMessage: "Sign up"
  },
  skip: {
    id: "yPRB7y",
    defaultMessage: "Skip"
  },
  start: {
    id: "dVsUCA",
    defaultMessage: "Start"
  },
  stop: {
    id: "sQ2zSt",
    defaultMessage: "Stop"
  },
  submit: {
    id: "WJiqOl",
    defaultMessage: "Submit"
  },
  submitPasswordReset: {
    id: "Kds4yF",
    defaultMessage: "Set new account password"
  },
  top: {
    id: "YiCAf8",
    defaultMessage: "Top"
  },
  up: {
    id: "DUvPwd",
    defaultMessage: "Go Up"
  },
  view: {
    id: "2CjK8z",
    defaultMessage: "View"
  }
});