/**
 * @generated SignedSource<<1f91165b897bc541bdb826dfa9669df5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PersonalProfileCreateInput = {
  firstName: string;
  lastName: string;
  preferredLocale: string;
  prefix?: string | null;
};
export type CpAccountPersonalProfileWizardMutation$variables = {
  input: PersonalProfileCreateInput;
};
export type CpAccountPersonalProfileWizardMutation$data = {
  readonly personalProfileCreate: {
    readonly user: {
      readonly name: string | null;
      readonly personalProfile: {
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly preferredLocale: string;
        readonly prefix: string | null;
      } | null;
    };
  } | null;
};
export type CpAccountPersonalProfileWizardMutation = {
  response: CpAccountPersonalProfileWizardMutation$data;
  variables: CpAccountPersonalProfileWizardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferredLocale",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "prefix",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpAccountPersonalProfileWizardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PersonalProfileCreatePayload",
        "kind": "LinkedField",
        "name": "personalProfileCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalProfile",
                "kind": "LinkedField",
                "name": "personalProfile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpAccountPersonalProfileWizardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PersonalProfileCreatePayload",
        "kind": "LinkedField",
        "name": "personalProfileCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PersonalProfile",
                "kind": "LinkedField",
                "name": "personalProfile",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e78f8da0ed0f9de6e273e5af3f4e6968",
    "id": null,
    "metadata": {},
    "name": "CpAccountPersonalProfileWizardMutation",
    "operationKind": "mutation",
    "text": "mutation CpAccountPersonalProfileWizardMutation(\n  $input: PersonalProfileCreateInput!\n) {\n  personalProfileCreate(input: $input) {\n    user {\n      name\n      personalProfile {\n        firstName\n        lastName\n        preferredLocale\n        prefix\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "852c0ac67108fd715f3141e5e61f6b71";

export default node;
