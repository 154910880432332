import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import camelcase from "camelcase";
import Color from "color";
import CxContentLockedModal from "contexts/CxContentLockedModal";
import React, { useCallback, useContext, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ImageBackground, Linking, StyleSheet, // eslint-disable-line no-restricted-imports
View } from "react-native";
import { SessionType } from "components/patientSession/PatientSession";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { AnalyticsEventName } from "services/Analytics";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import Log from "services/Log";
import { css, styles } from "services/Theme";
import translations from "translations";
export const GridItemHeights = {
  large: 90,
  small: 65
};
export const GridItemWidths = {
  large: 416,
  small: 280
};
const localRnStyles = StyleSheet.create({
  coverImage: {
    resizeMode: "contain"
  }
});
const localStyles = {
  buffer: css`
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  container: {
    large: css`
      height: ${GridItemHeights.large}px;
      width: ${GridItemWidths.large}px;
      ${styles.marginSingle};
    `,
    small: css`
      height: ${GridItemHeights.small}px;
      width: ${GridItemWidths.small}px;
      ${styles.marginSingle};
    `
  },
  counter: css`
    background-color: ${({
    theme
  }) => theme.brandColors.primary};
    padding: ${({
    theme
  }) => theme.spacing.half}px;
    position: absolute;
    right: 0;
    top: 0;
  `,
  counterText: css`
    color: ${({
    theme
  }) => theme.textColors.inverse};
  `,
  externalLinkBuffer: css`
    margin-right: ${({
    theme
  }) => theme.spacing.triple}px;
  `,
  topRightIcon: css`
    position: absolute;
    right: 6px;
    top: 6px;
  `,
  largeItem: css`
    flex: 9;
  `,
  smallItem: css`
    flex: 3;
  `,
  iconStyle: css`
    align-self: center;
    margin-horizontal: auto;
    ${styles.paddingHorizontalSingle};
  `,
  imageStyle: css`
    align-self: center;
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  innerView: css`
    flex-direction: column;
    justify-content: center;
  `,
  isNew: css`
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    display: block;
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-weight: bold;
    left: ${({
    theme
  }) => theme.spacing.half}px;
    position: absolute;
    top: -${({
    theme
  }) => theme.spacing.single}px;
    z-index: ${({
    theme
  }) => theme.layers.firstLayer};
    ${styles.paddingHalf};
  `,
  mobileBuffer: css`
    padding: ${({
    theme
  }) => theme.spacing.triple}px;
  `,
  pressable: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    flex: 1;
    overflow: hidden;
  `,
  tileImage: css`
    height: 100%;
  `,
  tileItem: css`
    flex-direction: row;
  `
};

/**
 * A Home Screen Shortcut Item used in Bottom Tabs
 * @param color: background color name of the icon (primary || secondary)
 * @param image: background cover image
 * @param isNew
 * @param link: any url
 * @param resourceNodeRevisionId: redirect to url (revisionId only for now)
 * @param icon: icon name formatted as icon-mental-health for example, supported svg list included PrIcon
 * @param style: optional style to apply to the view wrapping component
 * @param title: text
 */
const CpGridItem = ({
  accessibilityLabel,
  accessibilityRole = "link",
  color,
  completed,
  disabled = false,
  icon,
  image,
  isNew,
  link,
  onAuxClick,
  onPress,
  selected = false,
  style,
  testID,
  title,
  total
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    brandColors,
    iconSizes,
    textColors
  } = useAppTheme();
  const {
    navigate
  } = useNavigation();
  const linkTo = useLinkTo();
  const {
    trackEvent,
    trackGoogleEvent
  } = useAnalytics();
  const {
    patientSessionData: {
      viewSessionData,
      viewSessionParams
    }
  } = usePatientSessionContext();
  const iconName = useMemo(() => {
    if (icon) {
      const _camelCaseIcon = camelcase(icon);

      if (Object.values(IconName).includes(_camelCaseIcon)) {
        return _camelCaseIcon;
      } else {
        Log.warn(`CpGridItem: Attempted to render an icon that doesn't exist: ${_camelCaseIcon}`);
        return "iconImd";
      }
    } else {
      return "iconImd";
    }
  }, [icon]); // Establish 'new' button color, and that text is legible

  const newComplement = color === "primary" ? "secondary" : "primary";
  const complementIsLight = Color(brandColors[newComplement]).isLight();
  const defaultIsDark = Color(textColors.default).isDark();
  const {
    openContentLockedModal
  } = useContext(CxContentLockedModal);
  const newTextColor = complementIsLight && defaultIsDark ? textColors.default : textColors.inverse;
  const handleClick = useCallback(() => {
    if (onPress) {
      onPress();
    } else {
      switch (link.link_type) {
        case "external":
          Linking.openURL(link.value);
          trackEvent({
            data: {
              targetUrl: link.value
            },
            eventType: AnalyticsEventName.nav_external
          });
          break;

        case "resource":
          navigate(Routes.topic, {
            rootRevisionId: link.value,
            selectedRevisionId: link?.secondaryValue
          });
          break;

        case "topic":
          if (link.is_locked) {
            openContentLockedModal();
          } else {
            navigate(Routes.topic, {
              rootRevisionId: link.value
            });
          }

          break;

        default:
          //TODO: maybe these should be enumarated in the schema, also what is default? throw an error?
          linkTo(link.value);
      }

      if (link.link_type === "topic" && viewSessionData?.isPsp && viewSessionParams?.patientSessionId) {
        trackGoogleEvent({
          action: "TopicSelected",
          name: "pspSession",
          options: {
            dimensions: {
              [GoogleAnalyticsDimension.patientSessionId]: viewSessionParams?.patientSessionId,
              [GoogleAnalyticsDimension.patientSessionType]: SessionType.PspSession,
              [GoogleAnalyticsDimension.rootResourceNodeRevisionId]: link.value
            }
          }
        });
      }

      trackEvent({
        data: {
          targetType: link.link_type,
          targetValue: link.value
        },
        eventType: AnalyticsEventName.nav_home_item
      });
    }
  }, [link, linkTo, navigate, onPress, trackGoogleEvent, trackEvent, openContentLockedModal, viewSessionData, viewSessionParams]);
  const handleAuxClick = useCallback(() => {
    if (onAuxClick) {
      onAuxClick();
    } else {
      switch (link.link_type) {
        case "external":
          Linking.openURL(link.value);
          trackEvent({
            data: {
              targetUrl: link.value
            },
            eventType: AnalyticsEventName.nav_external
          });
          break;

        case "topic":
          Linking.openURL(`${location.origin}/${Routes.topic}/${link.value}`);
          break;

        default:
          //TODO: maybe these should be enumarated in the schema, also what is default? throw an error?
          linkTo(link.value);
      }

      trackEvent({
        data: {
          targetType: link.link_type,
          targetValue: link.value
        },
        eventType: AnalyticsEventName.nav_home_item
      });
    }
  }, [link, linkTo, onAuxClick, trackEvent]);
  const isExternalLink = link.link_type === "external";
  const isLockedLink = link.is_locked;
  return <_StyledView style={style} $_css={isWideScreen ? localStyles.container.large : localStyles.container.small}>
      {isNew && <_StyledPrText $_css2={localStyles.isNew} $_css3={newTextColor} $_css4={brandColors[newComplement]}>
          <FormattedMessage id="WUcUZB" defaultMessage="new" />
        </_StyledPrText>}
      <_StyledPrPressable accessibilityLabel={accessibilityLabel} accessibilityRole={accessibilityRole} disabled={disabled} onAuxClick={handleAuxClick} onPress={handleClick} testID={testID ? testID : "CpGridItem"} {...isExternalLink ? {
      accessibilityHint: formatMessage(translations.accessibility.hint.externalLink)
    } : undefined} $_css5={[localStyles.pressable, localStyles.tileItem, css`
            box-shadow: 0px 1px 3px ${brandColors[color]};
          `, selected && styles.selected]}>
        {image ? <_StyledImageBackground accessibilityLabel="" imageStyle={localRnStyles.coverImage} source={{
        uri: image
      }} $_css6={[localStyles.imageStyle, localStyles.tileImage, localStyles.smallItem]} /> : <_StyledPrIcon accessibilityLabel="" fill={brandColors[color]} icon={iconName} size={isWideScreen ? iconSizes.xlarge : iconSizes.large} $_css7={[localStyles.iconStyle, localStyles.smallItem]} />}

        <_StyledView2 $_css8={[localStyles.innerView, localStyles.largeItem, isExternalLink ? localStyles.externalLinkBuffer : undefined]}>
          <_StyledPrText2 numberOfLines={isWideScreen ? 3 : 2} $_css9={styles.headingFont}>
            {title}
          </_StyledPrText2>
        </_StyledView2>
        {isExternalLink && <_StyledPrIcon2 accessibilityLabel="" fill={textColors.muted} icon={IconName.externalLink} size={iconSizes.small} $_css10={localStyles.topRightIcon} />}
        {isLockedLink && <_StyledPrIcon3 accessibilityLabel="" fill={textColors.muted} icon={IconName.lockClosed} size={iconSizes.small} $_css11={localStyles.topRightIcon} />}
        {total && completed !== undefined ? <>
            {
          /* Buffer div to allow it to have enough space to render the counter instead of eating some of the text */
        }
            <_StyledView3 $_css12={isWideScreen ? localStyles.buffer : localStyles.mobileBuffer} />
            <_StyledView4 $_css13={localStyles.counter}>
              <_StyledPrText3 $_css14={localStyles.counterText}>{`${completed}/${total}`}</_StyledPrText3>
            </_StyledView4>
          </> : null}
      </_StyledPrPressable>
    </_StyledView>;
};

export default CpGridItem;

var _StyledView = _styled(View).withConfig({
  displayName: "CpGridItem___StyledView",
  componentId: "sc-1dlib97-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpGridItem___StyledPrText",
  componentId: "sc-1dlib97-1"
})(["", " color:", ";background-color:", ";"], p => p.$_css2, p => p.$_css3, p => p.$_css4);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpGridItem___StyledPrPressable",
  componentId: "sc-1dlib97-2"
})(["", ""], p => p.$_css5);

var _StyledImageBackground = _styled(ImageBackground).withConfig({
  displayName: "CpGridItem___StyledImageBackground",
  componentId: "sc-1dlib97-3"
})(["", ""], p => p.$_css6);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CpGridItem___StyledPrIcon",
  componentId: "sc-1dlib97-4"
})(["", ""], p => p.$_css7);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpGridItem___StyledView2",
  componentId: "sc-1dlib97-5"
})(["", ""], p => p.$_css8);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpGridItem___StyledPrText2",
  componentId: "sc-1dlib97-6"
})(["", ""], p => p.$_css9);

var _StyledPrIcon2 = _styled(PrIcon).withConfig({
  displayName: "CpGridItem___StyledPrIcon2",
  componentId: "sc-1dlib97-7"
})(["", ""], p => p.$_css10);

var _StyledPrIcon3 = _styled(PrIcon).withConfig({
  displayName: "CpGridItem___StyledPrIcon3",
  componentId: "sc-1dlib97-8"
})(["", ""], p => p.$_css11);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpGridItem___StyledView3",
  componentId: "sc-1dlib97-9"
})(["", ""], p => p.$_css12);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpGridItem___StyledView4",
  componentId: "sc-1dlib97-10"
})(["", ""], p => p.$_css13);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpGridItem___StyledPrText3",
  componentId: "sc-1dlib97-11"
})(["", ""], p => p.$_css14);