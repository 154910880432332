import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - play_arrow
const Play: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M10.688 6.688l14.625 9.313-14.625 9.313v-18.625z" />
    </Svg>
  )
}

export default Play
