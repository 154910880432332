import * as React from "react"
import Svg, { G, Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconArticle: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M105 221V120H94.5a15.39 15.39 0 011.5 6.5V221zm12 32c0 6.1-1 14.2-5 17h160c9.3 0 16-6.6 16-16V90H117zm-57 .5V126a16.79 16.79 0 0110.8-16.3 20.05 20.05 0 017-1.8H105l-.1-23.4a6.5 6.5 0 016.48-6.5H293.3a6.67 6.67 0 016.7 6.6v169c0 16.5-12.2 28.5-28.7 28.5H88.7a30.43 30.43 0 01-11.5-2.2c-9.6-4-17.2-12.8-17.2-26.4zM72 125v128.5c0 9.4 7.6 15.4 16.7 15.4 8.9 0 16.3-6.1 16.3-15.5v-20.5l-14.5.1a6.5 6.5 0 01-6.5-6.48V125c0-6.1-12-7-12 0z" />
      <Path
        d="M235 171h16v16h16v16h-16v16h-16v-16h-16v-16h16zm-96 58h128v8H139zm0-54h66v8h-66zm0 18h66v8h-66zm0 18h66v8h-66z"
        fillRule="evenodd"
      />
      <G>
        <Path d="M153.37 146.22h-7l-1 3.31H139l7.53-20h6.76l7.53 20h-6.48zm-1.28-4.33l-2.21-7.21-2.19 7.21zM163.47 149.53v-20h10.32a14.92 14.92 0 014.39.49 4.69 4.69 0 012.45 1.82 5.56 5.56 0 01.93 3.25 5.64 5.64 0 01-.71 2.88 5.49 5.49 0 01-2 2 7.19 7.19 0 01-2.17.79 6.5 6.5 0 011.61.74 6.32 6.32 0 011 1.06 8.66 8.66 0 01.87 1.24l3 5.81h-7l-3.31-6.12a4.64 4.64 0 00-1.12-1.55 2.62 2.62 0 00-1.52-.46h-.55v8.13zm6.22-11.92h2.61a8.9 8.9 0 001.65-.28 1.64 1.64 0 001-.62 2 2 0 00-.23-2.66 3.76 3.76 0 00-2.31-.52h-2.72zM184.08 129.49h18.82v4.95h-6.32v15.09h-6.19v-15.09h-6.31zM206.51 129.49h6.21v20h-6.21zM231.06 141.33l5.43 1.64a10 10 0 01-1.72 3.81 7.48 7.48 0 01-2.92 2.31 10.91 10.91 0 01-4.44.78 12.75 12.75 0 01-5.33-1 8.35 8.35 0 01-3.58-3.34 11.32 11.32 0 01-1.5-6.12q0-5 2.64-7.64c1.77-1.78 4.26-2.67 7.49-2.67a10.16 10.16 0 016 1.53 9 9 0 013.22 4.7l-5.47 1.22a4.62 4.62 0 00-.6-1.34 3.61 3.61 0 00-1.27-1.1 3.71 3.71 0 00-1.68-.38 3.63 3.63 0 00-3.23 1.69 7.26 7.26 0 00-.85 3.95c0 2.22.34 3.74 1 4.57a4 4 0 005.54.24 6.26 6.26 0 001.27-2.85zM240.06 129.49h6.19v15.1h9.67v4.94h-15.86zM259.28 129.49h16.6v4.28h-10.39V137h9.64v4h-9.64v4h10.69v4.54h-16.9z" />
      </G>
    </Svg>
  )
}

export default IconArticle
