import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconTablet: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M213.63 289.81H91.23A16.31 16.31 0 0175 273.54V62.19a16.31 16.31 0 0116.23-16.28h162.69a16.31 16.31 0 0116.27 16.28v154.59a17.2 17.2 0 00-13.07-.82V74.37a3.23 3.23 0 00-3.2-3.2H91.23A3.23 3.23 0 0088 74.33V259a3.22 3.22 0 003.2 3.19h92.42c.29 3.17 3.59 6.48 6.13 7.82a90.76 90.76 0 0122.87 18.7l1 1.12zm-15.46-83.38a8.8 8.8 0 0116-7.4c4.88 10.49 9.24 20.9 13.14 31.46a8.78 8.78 0 0114.24-1.89 8.77 8.77 0 0113.76-1.17 8.78 8.78 0 0114.94.16c9.57 15.82 13.63 37.87 15.4 56.13-4.51 8.65-31.58 19.39-51.92 18.37-7.73-10.09-18.62-27.34-39.98-39.42a8.8 8.8 0 018-15.67 83.74 83.74 0 0116.07 10.53q-1.64-5.38-3.44-10.72a392.54 392.54 0 00-16.22-40.38zM171.38 271a5.09 5.09 0 11-5.09 5.09 5.09 5.09 0 015.09-5.09z"
        fillRule="evenodd"
      />
      <Path
        d="M157.57 121.67h30v30h30v30h-30v30h-30v-30h-30v-30h30v-30zm15-29A74 74 0 01229.43 214c-1.16-2.82-2.35-5.62-3.56-8.42a65.84 65.84 0 10-27.28 21.74c1 2.47 2 4.94 2.95 7.43a74 74 0 11-29-142.11z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconTablet
