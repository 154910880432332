import debounce from "debounce"
import { DispatchWithoutAction, useMemo, useReducer } from "react"

const reducer = (v: number): number => v + 1

/**
 * Hook to force a debounced render of a component, use sparingly.
 * Will fire 100ms after it's first triggered to allow for processing of data
 */
const useForceRender = (): DispatchWithoutAction => {
  const [, dispatchCounter] = useReducer(reducer, 0)
  return useMemo(() => debounce(dispatchCounter, 100), [dispatchCounter])
}

export default useForceRender
