import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtResetPassword from "components/containers/CtResetPassword";
import { rnStyles, styles } from "services/Theme";
import translations from "translations";

/**
 * The reset password screen
 */
const ScResetPassword = ({
  route
}) => {
  const {
    params
  } = route;
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} $_css2={styles.screen}>
        <main>
          <CtResetPassword token={params.passwordResetToken} />
        </main>
      </_StyledScrollView>
    </_StyledSafeAreaView>;
};

export default ScResetPassword;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScResetPassword___StyledSafeAreaView",
  componentId: "sc-1rl94oa-0"
})(["", ""], p => p.$_css);

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "ScResetPassword___StyledScrollView",
  componentId: "sc-1rl94oa-1"
})(["", ""], p => p.$_css2);