import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View } from "react-native";
import CpAppLoading from "components/app/CpAppLoading";
import CpFilterBar from "components/common/CpFilterBar";
import CpContentLinksSection from "components/home/tabs/CpContentLinksSection";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import PrText from "components/primitives/PrText";
import isUrlString from "helpers/isUrlString";
import useBackendSupportedLocale from "hooks/useBackendSupportedLocale";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import QlTopicTreeSection from "query/QlTopicTreeSection";
import { getNameVariant } from "services/Graphql";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  paragraphContainer: css`
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  paragraphText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  resultsContainer: css`
    align-items: center;
    justify-content: center;
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    margin: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  resultsText: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `
};
var TrainingTopicNameVariantKeys;

(function (TrainingTopicNameVariantKeys) {
  TrainingTopicNameVariantKeys["Completed"] = "completed";
  TrainingTopicNameVariantKeys["Default"] = "default";
  TrainingTopicNameVariantKeys["Resume"] = "resume";
  TrainingTopicNameVariantKeys["Start"] = "start";
})(TrainingTopicNameVariantKeys || (TrainingTopicNameVariantKeys = {}));

const TopicTreeShortcutsList = ({
  filterPlaceholder,
  filterSearchMethod,
  linkStyle,
  refresh,
  renderWithoutHeaders,
  topicNode,
  ...otherProps
}) => {
  const locale = useBackendSupportedLocale();
  const {
    dispatchPatientSession,
    patientSessionData: {
      trainingNodeViewed
    }
  } = usePatientSessionContext();
  const topicTreeId = topicNode?.treeId ?? "";
  const showFilter = filterSearchMethod === "filter_content"; // Refreshes the topic query whenever we complete a training node

  useEffect(() => {
    if (trainingNodeViewed) {
      refresh();
      dispatchPatientSession({
        payload: false,
        type: PatientSessionActions.SetTrainingNodeViewed
      });
    }
  }, [refresh, trainingNodeViewed, dispatchPatientSession]); // Flattening children to ensure we are able to effectively filter them if necessary

  const flattenedChildren = useMemo(() => renderWithoutHeaders ? topicNode?.children?.flatMap(child => child) : topicNode?.children?.flatMap(child => child?.children?.map(secondChild => secondChild)), [renderWithoutHeaders, topicNode?.children]); // Handling of filtered results - store data and handle filtering responses

  const [filteredData, setFilteredData] = useState(undefined);
  const handleFilterContent = useCallback(response => setFilteredData(response ? response : undefined), []); // Generic handler for determining whether to match filtered content or not

  const contentItemToReturn = useCallback(item => {
    if (!showFilter || showFilter && !filteredData?.length) {
      return item;
    } else {
      const foundItem = filteredData?.find(filteredItem => filteredItem.item.resourceNodeRevisionId === item.link.value);
      return foundItem ? item : null;
    }
  }, [filteredData, showFilter]);
  const sections = useMemo(() => {
    if (renderWithoutHeaders) {
      // This is intended for TopicNodes with a single top-level node as parent, rendering immediate children as tiles.
      return [{
        content: topicNode?.children?.map(shortcut => {
          const {
            icon,
            isLocked,
            resourceNodeRevisionId
          } = shortcut;
          const title = {
            [locale]: getNameVariant(shortcut) || ""
          };
          const currentChild = {
            color: "primary",
            icon: icon && !isUrlString(icon) ? icon : undefined,
            image: icon && isUrlString(icon) ? icon : undefined,
            link: {
              is_locked: isLocked,
              link_type: "topic",
              value: resourceNodeRevisionId || ""
            },
            snippet: shortcut.resourceNodeEmbedSnippet,
            title
          };
          return contentItemToReturn(currentChild);
        }).filter(child => child),
        // Filtering to remove empty items
        "section-title": "UNUSED"
      }];
    } else {
      // Standard use-case - immediate children will be rendered as section headers with their children as tiles
      const sectionsToReturn = topicNode?.children?.map(nodeChild => {
        const childrenToReturn = nodeChild.children.map(shortcut => {
          const {
            icon,
            isLocked,
            resourceNodeRevisionId
          } = shortcut;
          const completion = shortcut.resourceNodeCompletion;
          let nameVariant = TrainingTopicNameVariantKeys.Default;

          if (completion?.total) {
            if (completion?.completed === 0) {
              nameVariant = TrainingTopicNameVariantKeys.Start;
            } else if (completion?.completed === completion.total) {
              nameVariant = TrainingTopicNameVariantKeys.Completed;
            } else {
              nameVariant = TrainingTopicNameVariantKeys.Resume;
            }
          }

          const title = {
            [locale]: getNameVariant(shortcut, nameVariant) || getNameVariant(shortcut) || ""
          };
          const currentChild = {
            color: "primary",
            completed: shortcut.resourceNodeCompletion?.completed,
            icon: icon && !isUrlString(icon) ? icon : undefined,
            image: icon && isUrlString(icon) ? icon : undefined,
            link: {
              is_locked: isLocked,
              link_type: "topic",
              value: resourceNodeRevisionId || ""
            },
            snippet: shortcut.resourceNodeEmbedSnippet,
            title,
            total: shortcut.resourceNodeCompletion?.total
          };
          return contentItemToReturn(currentChild);
        });
        return {
          // Filtering to remove empty items
          content: childrenToReturn.filter(child => child),
          "section-title": getNameVariant(nodeChild)
        };
      }); // Ensure we only show sections with content (i.e. when filtering)

      return sectionsToReturn?.filter(section => section?.content?.length);
    }
  }, [renderWithoutHeaders, topicNode?.children, locale, contentItemToReturn]); // Patient Package handlers

  const handleAddItemToPackage = useCallback((sectionHeader, revisionId) => {
    dispatchPatientSession({
      payload: {
        ids: [{
          revisionId
        }],
        sectionHeader,
        topicTreeId,
        type: "topicSection"
      },
      type: PatientSessionActions.AddToPackage
    });
  }, [dispatchPatientSession, topicTreeId]);
  const handleAddSectionToPackage = useCallback((sectionHeader, revisionIds) => {
    dispatchPatientSession({
      payload: {
        ids: revisionIds.map(revisionId => ({
          revisionId
        })),
        sectionHeader,
        topicTreeId,
        type: "topicSection"
      },
      type: PatientSessionActions.AddToPackage
    });
  }, [dispatchPatientSession, topicTreeId]);

  if (sections) {
    return <>
        {showFilter && <_StyledCpFilterBar data={flattenedChildren} filterKeys={["names.default"]} onQueryChanged={handleFilterContent} placeholder={filterPlaceholder || filterPlaceholder === "" ? filterPlaceholder : translations.labels.placeholderFilter} $_css={styles.marginSingle} />}
        {sections.map(section => {
        if (section.content.length) {
          return <CpContentLinksSection addToPackage={renderWithoutHeaders ? {
            onPressItem: handleAddItemToPackage
          } : {
            onPressItem: handleAddItemToPackage,
            onPressSection: handleAddSectionToPackage
          }} key={section["section-title"]} linkStyle={linkStyle} renderWithoutHeaders={renderWithoutHeaders} sectionTitle={section["section-title"] || ""} shortcuts={section.content} topicTreeId={topicTreeId} {...otherProps} />;
        } else {
          // In case we're filtering content but have no results, or have no content
          return <_StyledView $_css2={localStyles.resultsContainer}>
                <_StyledPrText children={showFilter ? translations.labels.noResultsFound : translations.labels.noData} $_css3={localStyles.resultsText} />
              </_StyledView>;
        }
      })}
      </>;
  } else {
    return null;
  }
};
/**
 * Fetches a topic tree and uses the data to create CpContentLinksSection(s)
 */


const CpTopicTreeSection = ({
  linkStyle,
  renderWithoutHeaders = false,
  tabParagraph,
  tabParagraphContainerStyles,
  tabParagraphStyles,
  topicTreeId,
  ...otherProps
}) => {
  const locale = useBackendSupportedLocale();
  return <QlTopicTreeSection // TODO: ./feature/imd_public/psp specs fail with useMemo version. FIgure out why and fix
  // variables={useMemo(
  //   () => ({
  //     locale,
  //     topicTreeId,
  //   }),
  //   [locale, topicTreeId],
  // )}
  variables={{
    locale,
    topicTreeId
  }}>
      {(response, refresh) => {
      if (response) {
        return <>
              {tabParagraph && <_StyledView2 $_css4={[localStyles.paragraphContainer, tabParagraphContainerStyles]}>
                  <_StyledPrText2 children={tabParagraph} $_css5={[localStyles.paragraphText, tabParagraphStyles]} />
                </_StyledView2>}
              <TopicTreeShortcutsList linkStyle={linkStyle} refresh={refresh} renderWithoutHeaders={renderWithoutHeaders} topicNode={response} {...otherProps} />
            </>;
      }

      return <CpAppLoading />;
    }}
    </QlTopicTreeSection>;
};

export default CpTopicTreeSection;

var _StyledCpFilterBar = _styled(CpFilterBar).withConfig({
  displayName: "CpTopicTreeSection___StyledCpFilterBar",
  componentId: "sc-1rhvr6i-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicTreeSection___StyledView",
  componentId: "sc-1rhvr6i-1"
})(["", ""], p => p.$_css2);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicTreeSection___StyledPrText",
  componentId: "sc-1rhvr6i-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpTopicTreeSection___StyledView2",
  componentId: "sc-1rhvr6i-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpTopicTreeSection___StyledPrText2",
  componentId: "sc-1rhvr6i-4"
})(["", ""], p => p.$_css5);