import { StackRouter } from "@react-navigation/routers"

const StackRouterWithHistory: typeof StackRouter = (options) => {
  const stackRouter = StackRouter(options)
  return {
    ...stackRouter,
    getStateForAction(state, action, routerOptions) {
      const nextState = stackRouter.getStateForAction(state, action, routerOptions)
      const prevHistory = state?.history ?? [{ type: "root" }]
      let nextHistory: unknown[] = prevHistory
      switch (action.type) {
        case "NAVIGATE":
          nextHistory = prevHistory.concat({ name: action.payload.name, type: "nav" })
          break
        case "POP":
          nextHistory = prevHistory.slice(0, -1)
          break
        case "POP_TO_TOP":
          nextHistory = []
          break
        case "PUSH":
          nextHistory = prevHistory.concat({ name: action.payload.name, type: "nav" })
          break
        case "REPLACE":
          nextHistory = prevHistory
          break
        case "GO_BACK":
          nextHistory = prevHistory.slice(0, -1)
      }

      if (nextState) {
        return {
          ...nextState,
          history: nextHistory,
        }
      } else {
        return null
      }
    },
  }
}

export default StackRouterWithHistory
