import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpPatientSessionSendMutation;

import { useNavigation } from "@react-navigation/native";
import CpForm from "components/form/CpForm";
import CpFormTextInput from "components/form/CpFormTextInput";
import { FormNecessity } from "components/form/types";
import CpPatientSessionStats from "components/patientSession/CpPatientSessionStats";
import CpSessionDisclaimerAndError from "components/patientSession/CpSessionDisclaimerAndError";
import CpSessionProgress from "components/patientSession/CpSessionProgress";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import { millisecondsToSeconds } from "date-fns";
import useAnalytics from "hooks/useAnalytics";
import useFormResolver from "hooks/useFormResolver";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay/hooks";
import { maxEmailLength } from "services/Constants";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import Log from "services/Log";
import { getSelectedActiveSegments, getSessionDuration } from "services/PatientSession";
import { css, styles } from "services/Theme";
import { emailListValidation, emailValidation } from "services/Validation";
import translations from "translations";
import * as zod from "zod";
const patientSessionCreateMutation = _CpPatientSessionSendMutation !== void 0 ? _CpPatientSessionSendMutation : _CpPatientSessionSendMutation = require("./__generated__/CpPatientSessionSendMutation.graphql");
const localTranslations = defineMessages({
  sendToPatient: {
    id: "ZEOoiH",
    defaultMessage: "Send to patient"
  }
});
const localStyles = {
  buttonContainer: css`
    align-items: center;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonTextDisabled: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
  `,
  subtext: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  subtextContainer: css`
    margin-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: -${({
    theme
  }) => theme.spacing.half}px;
  `
};

/**
 * Renders the form for creating a PatientSession record for sending to a patient.
 */
const CpPatientSessionSend = ({
  onClose,
  style
}) => {
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    organization,
    user
  } = useImdSession();
  const navigation = useNavigation();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    formatMessage
  } = useIntl();
  const {
    dispatchPatientSession,
    patientSessionData: {
      activeSegments,
      annotations,
      isPatientSession,
      selectedResources
    }
  } = usePatientSessionContext(); // PatientSessionDispatch callbacks

  const handleConfirmSelection = useCallback(() => {
    trackGoogleEvent({
      action: "Sent",
      name: "patientSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionSuccess
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession, organization?._id, trackGoogleEvent, user]);
  const handleClearAndReturn = useCallback(() => {
    // RESET_SESSION will close the modal for us
    dispatchPatientSession({
      type: PatientSessionActions.ResetSession
    });
    trackGoogleEvent({
      action: "Cleared",
      name: "patientSession",
      options: {
        dimensions: {
          [GoogleAnalyticsDimension.organizationId]: organization?._id,
          [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
        }
      }
    });
    navigation.navigate(Routes.home);
  }, [dispatchPatientSession, navigation, organization?._id, trackGoogleEvent, user]);
  const handleBack = useCallback(() => {
    dispatchPatientSession({
      payload: {
        modalStep: ModalSteps.SessionReview
      },
      type: PatientSessionActions.SetModal
    });
  }, [dispatchPatientSession]); // Field refs

  const noteFieldRef = useRef(null);
  const additionalEmailFieldRef = useRef(null); // Mutation and form submit handlers

  const [submitPatientSession, isSubmittingPatientSession] = useMutation(patientSessionCreateMutation);
  const form = useForm({
    mode: "onBlur",
    resolver: useFormResolver(useMemo(() => zod.object({
      additionalEmails: zod.string().refine(...emailListValidation()).optional(),
      // This may not be the best solution for a boolean, but this at least allows us to provide a proper error message - see useFormResolver `invalid_literal`
      disclaimer: zod.literal(true),
      note: zod.string().optional(),
      patientEmail: zod.string().refine(...emailValidation()),
      // patient-session locale
      sessionLocale: zod.string().optional()
    }), []))
  });
  const [formError, setFormError] = useState();
  const {
    locale
  } = useIntl();
  const handleSubmit = useMemo(() => form.handleSubmit(values => {
    const {
      additionalEmails,
      note,
      patientEmail
    } = values;
    Log.info("CpPatientSessionSend - Submitting PatientSession to patient: ", {
      patientEmail
    }); // Compile the final list of resources to add to the PatientSession

    const resourceIdsToSubmit = [];
    selectedResources.forEach(resource => {
      resource.selected && resourceIdsToSubmit.push(resource.revisionId);
    });
    const duration = getSessionDuration(getSelectedActiveSegments(activeSegments, selectedResources), true); // Clean up additionalEmails string, splitting at commas and clear spaces

    const additionalEmailArray = additionalEmails?.split(",").filter(String).map(email => email.trim());

    const submit = async () => {
      const firstSelectedResource = selectedResources[0];
      const startedTime = firstSelectedResource ? new Date(firstSelectedResource.time).toISOString() : "";
      submitPatientSession({
        onCompleted: ({
          patientSessionCreate
        }) => {
          Log.info("CpPatientSessionSend - PatientSession successfully created: ", {
            sessionId: patientSessionCreate?.patientSessionId
          });
          handleConfirmSelection();
        },
        onError: setFormError,
        variables: {
          input: {
            additionalEmails: additionalEmailArray,
            annotations: annotations.filter(annotation => annotation.selected),
            duration: millisecondsToSeconds(duration),
            note,
            patientEmail,
            // patientId: TODO - this should be the SSO user, or PSP ID
            // patientType: TODO - this should be one of:
            // ["Identity::Patient::Az","Identity::Patient::General", "Identity::Patient::Innomar"]
            resourceIds: resourceIdsToSubmit,
            sessionLocale: locale,
            started: new Date(startedTime).toISOString()
          }
        }
      });
    };

    submit();
  }), [activeSegments, locale, form, handleConfirmSelection, annotations, selectedResources, submitPatientSession]);
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <CpSessionProgress accessibilityLabel={formatMessage(translations.accessibility.label.progressPatientSession)} accessibilityValue={{
      text: formatMessage(translations.labels.send)
    }} // PatientSessions do not have a History step, account for that here
    completedSteps={isPatientSession ? 1 : 2} showStart={!isPatientSession} totalSteps={isPatientSession ? 1 : 2} />
      <_StyledPrText accessibilityRole="header" $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]}>
        <FormattedMessage id="2XgmDx" defaultMessage="Send this Session" />
      </_StyledPrText>
      <_StyledView $_css3={styles.paddingHorizontalSingle}>
        <CpPatientSessionStats />

        {
        /* Form */
      }
        <CpForm onSubmit={handleSubmit} {...form}>
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.patientEmail)} autoCapitalize="none" autoComplete="off" autoCorrect={false} autoFocus={true} keyboardType="email-address" label={translations.labels.patientEmail} maxLength={maxEmailLength} name="patientEmail" onSubmitEditing={noteFieldRef.current?.focus} placeholder={translations.labels.placeholderPatientEmail} returnKeyType="next" testID="CpPatientSessionSend-emailField" textContentType="emailAddress" />
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.notesToPatient)} autoCapitalize="sentences" autoComplete="off" autoCorrect={true} forwardRef={noteFieldRef} keyboardType="default" label={translations.labels.notesToPatient} multiline={true} name="note" necessity={FormNecessity.Optional} numberOfLines={3} onSubmitEditing={additionalEmailFieldRef.current?.focus} returnKeyType="next" testID="CpPatientSessionSend-notesField" textContentType="none" />
          <CpFormTextInput accessibilityLabel={formatMessage(translations.labels.additionalEmails)} autoCapitalize="none" autoComplete="off" autoCorrect={false} forwardRef={additionalEmailFieldRef} keyboardType="email-address" label={translations.labels.additionalEmails} name="additionalEmails" necessity={FormNecessity.Optional} onSubmitEditing={handleSubmit} returnKeyType="done" testID="CpPatientSessionSend-additionalEmailField" textContentType="emailAddress" />
          {
          /* Need to wrap subtext in a View with the required horizontal margin to ensure margin is respected when the line breaks on smaller screens */
        }
          <_StyledView2 $_css4={localStyles.subtextContainer}>
            <_StyledPrText2 $_css5={localStyles.subtext}>
              <FormattedMessage id="dLD9kW" defaultMessage="Include selected family members, caregivers, etc., by adding additional emails, separated by commas." />
            </_StyledPrText2>
          </_StyledView2>
          <CpSessionDisclaimerAndError formError={formError} />

          {
          /* Buttons */
        }
          <_StyledView3 $_css6={[localStyles.buttonContainer, !isNarrowScreen ? styles.sessionStyles.buttonContainerLarge : undefined]}>
            <_StyledView4 $_css7={isNarrowScreen ? styles.fullWidth : [styles.rowAndCenter, styles.marginRightDouble]}>
              <_StyledPrButton accessibilityLabel={formatMessage(localTranslations.sendToPatient)} busy={isSubmittingPatientSession} disabled={isSubmittingPatientSession} onPress={handleSubmit} testID="CpPatientSessionSend-submit" type="raised" $_css8={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : styles.marginRightDouble]}>
                <_StyledPrText3 children={localTranslations.sendToPatient} $_css9={styles.sessionStyles.buttonText} />
              </_StyledPrButton>
              <_StyledPrButton2 accessibilityLabel={formatMessage(translations.buttons.continue)} onPress={onClose} testID="CpPatientSessionSend-continue" type="outline" $_css10={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
                <_StyledPrText4 children={translations.buttons.continueSession} $_css11={styles.sessionStyles.buttonText} />
              </_StyledPrButton2>
            </_StyledView4>
            <_StyledPrButton3 accessibilityLabel={formatMessage(translations.buttons.clearAndReturnHome)} color="destructive" onPress={handleClearAndReturn} testID="CpPatientSessionSend-end" type="text" $_css12={[styles.sessionStyles.button, isNarrowScreen ? styles.fullWidth : undefined]}>
              <_StyledPrText5 children={translations.buttons.clearAndReturnHome} $_css13={styles.sessionStyles.buttonText} />
            </_StyledPrButton3>
          </_StyledView3>

          <_StyledView5 $_css14={isNarrowScreen ? styles.fullWidth : styles.flexEnd}>
            <_StyledPrButton4 onPress={handleBack} testID="CpPackageSessionSend-back" type="outline" $_css15={styles.marginTopDouble}>
              <_StyledPrText6 children={translations.buttons.back} $_css16={styles.sessionStyles.buttonText} />
            </_StyledPrButton4>
          </_StyledView5>
        </CpForm>
      </_StyledView>
    </_StyledScrollView>;
};

export default CpPatientSessionSend;

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPatientSessionSend___StyledScrollView",
  componentId: "sc-k6lthj-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSend___StyledPrText",
  componentId: "sc-k6lthj-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionSend___StyledView",
  componentId: "sc-k6lthj-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionSend___StyledView2",
  componentId: "sc-k6lthj-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSend___StyledPrText2",
  componentId: "sc-k6lthj-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionSend___StyledView3",
  componentId: "sc-k6lthj-5"
})(["", ""], p => p.$_css6);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpPatientSessionSend___StyledView4",
  componentId: "sc-k6lthj-6"
})(["", ""], p => p.$_css7);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionSend___StyledPrButton",
  componentId: "sc-k6lthj-7"
})(["", ""], p => p.$_css8);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSend___StyledPrText3",
  componentId: "sc-k6lthj-8"
})(["", ""], p => p.$_css9);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionSend___StyledPrButton2",
  componentId: "sc-k6lthj-9"
})(["", ""], p => p.$_css10);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSend___StyledPrText4",
  componentId: "sc-k6lthj-10"
})(["", ""], p => p.$_css11);

var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionSend___StyledPrButton3",
  componentId: "sc-k6lthj-11"
})(["", ""], p => p.$_css12);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSend___StyledPrText5",
  componentId: "sc-k6lthj-12"
})(["", ""], p => p.$_css13);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CpPatientSessionSend___StyledView5",
  componentId: "sc-k6lthj-13"
})(["", ""], p => p.$_css14);

var _StyledPrButton4 = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionSend___StyledPrButton4",
  componentId: "sc-k6lthj-14"
})(["", ""], p => p.$_css15);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSend___StyledPrText6",
  componentId: "sc-k6lthj-15"
})(["", ""], p => p.$_css16);