import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import { defineMessages } from "react-intl";
import { View } from "react-native";
import CpFormInputError from "components/form/CpFormInputError";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { Controller, useFormContext } from "react-hook-form";
import { css } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    flex-direction: row;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    padding: 0px;
  `,
  disclaimer: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  icon: css`
    /* Prevent checkbox from shrinking on smaller screens */
    min-width: ${({
    theme
  }) => theme.iconSizes.small}px;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  point: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    line-height: 110%;
  `,
  pointBullet: css`
    background-color: ${({
    theme
  }) => theme.textColors.muted};
    border-radius: 50%;
    height: 4px;
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
    margin-top: ${({
    theme
  }) => 2 * theme.spacing.third}px;
    width: 4px;
  `,
  pointContainer: css`
    flex-direction: row;
    padding-vertical: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  textContainer: css`
    flex: 1;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `
};
const localTranslations = defineMessages({
  disclaimerBase: {
    id: "nmoRHV",
    defaultMessage: "I hereby attest that I am the patient recipient OR the patient's health care provider (clinician, nurse, pharmacist, Drug Access Navigator) and prior to sending any electronic messages to the patient I have obtained the patient\u2019s consent to:"
  },
  point1: {
    id: "te02RD",
    defaultMessage: "Send the patient an email so that the patient may thereafter consult the information modules sent;"
  },
  point2: {
    id: "2KZskh",
    defaultMessage: "Use the patient\u2019s email address, which will be accessed and used by IMD Health Global Corp., the third party provider which supplies and maintains the IMD Health\xAE platform, only for the dispatching of this request."
  },
  point3: {
    id: "Ivzx12",
    defaultMessage: "The patient also acknowledges that this email might be sent to a device which may be viewable by others."
  }
});

/**
 * Form component that renders a checkbox with the IMD Health disclaimer for sending emails to patients
 */
const FmDisclaimer = ({
  defaultValue = false,
  disabled,
  name,
  style,
  testID,
  ...otherProps
}) => {
  const {
    iconSizes,
    textColors
  } = useAppTheme();
  const formContext = useFormContext();
  const {
    formState: {
      errors
    }
  } = formContext;
  const error = errors[name];
  const renderDisclaimer = useCallback(({
    field
  }) => <_StyledPrPressable // Excluding accessibilityLabel here as the disclaimer should be read fully by assistive devices
  accessibilityRole="checkbox" accessibilityState={{
    checked: field.value,
    disabled
  }} disabled={disabled} onPress={() => field.onChange(!field.value)} style={style} testID={testID} {...otherProps} $_css={localStyles.container}>
        <_StyledPrIcon fill={disabled ? textColors.disabled : textColors.default} icon={field.value ? IconName.checkboxChecked : IconName.checkboxUnchecked} size={iconSizes.small} $_css2={localStyles.icon} />
        <_StyledView $_css3={localStyles.textContainer}>
          <_StyledPrText children={localTranslations.disclaimerBase} $_css4={localStyles.disclaimer} />
          <_StyledView2 $_css5={localStyles.pointContainer}>
            <_StyledView3 $_css6={localStyles.pointBullet} />
            <_StyledPrText2 children={localTranslations.point1} $_css7={localStyles.point} />
          </_StyledView2>
          <_StyledView4 $_css8={localStyles.pointContainer}>
            <_StyledView5 $_css9={localStyles.pointBullet} />
            <_StyledPrText3 children={localTranslations.point2} $_css10={localStyles.point} />
          </_StyledView4>
          <_StyledView6 $_css11={localStyles.pointContainer}>
            <_StyledView7 $_css12={localStyles.pointBullet} />
            <_StyledPrText4 children={localTranslations.point3} $_css13={localStyles.point} />
          </_StyledView6>
        </_StyledView>
      </_StyledPrPressable>, [disabled, iconSizes.small, otherProps, style, testID, textColors.default, textColors.disabled]);
  return <>
      <Controller defaultValue={defaultValue} name={name} render={renderDisclaimer} />
      {error && <CpFormInputError>{error.message}</CpFormInputError>}
    </>;
};

export default FmDisclaimer;

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "FmDisclaimer___StyledPrPressable",
  componentId: "sc-1n5qsp3-0"
})(["", ""], p => p.$_css);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "FmDisclaimer___StyledPrIcon",
  componentId: "sc-1n5qsp3-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView",
  componentId: "sc-1n5qsp3-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "FmDisclaimer___StyledPrText",
  componentId: "sc-1n5qsp3-3"
})(["", ""], p => p.$_css4);

var _StyledView2 = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView2",
  componentId: "sc-1n5qsp3-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView3",
  componentId: "sc-1n5qsp3-5"
})(["", ""], p => p.$_css6);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "FmDisclaimer___StyledPrText2",
  componentId: "sc-1n5qsp3-6"
})(["", ""], p => p.$_css7);

var _StyledView4 = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView4",
  componentId: "sc-1n5qsp3-7"
})(["", ""], p => p.$_css8);

var _StyledView5 = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView5",
  componentId: "sc-1n5qsp3-8"
})(["", ""], p => p.$_css9);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "FmDisclaimer___StyledPrText3",
  componentId: "sc-1n5qsp3-9"
})(["", ""], p => p.$_css10);

var _StyledView6 = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView6",
  componentId: "sc-1n5qsp3-10"
})(["", ""], p => p.$_css11);

var _StyledView7 = _styled(View).withConfig({
  displayName: "FmDisclaimer___StyledView7",
  componentId: "sc-1n5qsp3-11"
})(["", ""], p => p.$_css12);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "FmDisclaimer___StyledPrText4",
  componentId: "sc-1n5qsp3-12"
})(["", ""], p => p.$_css13);