import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useLinkTo, useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { ImageBackground, Linking, View } from "react-native";
import { GridItemWidths } from "components/home/tabs/CpGridItem";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import Routes from "navigators/Routes";
import { AnalyticsEventName } from "services/Analytics";
import { css, rnStyles, styles } from "services/Theme";
export const CardItemHeights = {
  large: 420,
  small: 300
};
const localStyles = {
  authors: css`
    color: ${({
    theme
  }) => theme.buttonColors.secondary};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  cardImage: css`
    width: 100%;
  `,
  cardItem: css`
    flex-direction: column;
  `,
  containerCard: css`
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    height: ${CardItemHeights.large}px;
    width: ${GridItemWidths.small}px;
    ${styles.marginSingle};
  `,
  imageStyle: css`
    align-self: center;
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    height: 156px;
    width: 100%;
  `,
  imageStyleHovered: css`
    background-color: ${({
    theme
  }) => theme.brandColors.primary};
    opacity: 0.7;
  `,
  innerView: css`
    flex-direction: column;
    justify-content: center;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  label: css`
    color: ${({
    theme
  }) => theme.buttonColors.secondary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-weight: bold;
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  pressable: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.surface};
    border-radius: ${({
    theme
  }) => theme.borderRadii.soft}px;
    flex: 1;
    padding: 0;
    overflow: hidden;
  `,
  snippet: css`
    color: ${({
    theme
  }) => theme.buttonColors.secondary};
    font-family: ${({
    theme
  }) => theme.fontFamilies.default};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

const CpGridCard = ({
  accessibilityLabel,
  accessibilityRole = "link",
  authors,
  disabled = false,
  image,
  link,
  onPress,
  snippet,
  style,
  testID,
  title // Props not yet implemented but may be required if 'cards' stick around
  // color,
  // completed,
  // icon,
  // isNew,
  // onAuxClick,
  // selected = false,
  // total,

}) => {
  const {
    navigate
  } = useNavigation();
  const linkTo = useLinkTo();
  const {
    trackEvent
  } = useAnalytics();
  const handleClick = useCallback(() => {
    if (onPress) {
      onPress();
    } else {
      switch (link.link_type) {
        case "external":
          Linking.openURL(link.value);
          trackEvent({
            data: {
              targetUrl: link.value
            },
            eventType: AnalyticsEventName.nav_external
          });
          break;

        case "resource":
          navigate(Routes.topic, {
            rootRevisionId: link.value,
            selectedRevisionId: link?.secondaryValue
          });
          break;

        case "topic":
          navigate(Routes.topic, {
            rootRevisionId: link.value
          });
          break;

        default:
          //TODO: maybe these should be enumarated in the schema, also what is default? throw an error?
          linkTo(link.value);
      }

      trackEvent({
        data: {
          targetType: link.link_type,
          targetValue: link.value
        },
        eventType: AnalyticsEventName.nav_home_item
      });
    }
  }, [link.link_type, link?.secondaryValue, link.value, linkTo, navigate, onPress, trackEvent]);
  return <_StyledView style={[rnStyles.buttonShadow, style]} $_css={localStyles.containerCard}>
      <_StyledPrPressable accessibilityLabel={accessibilityLabel} accessibilityRole={accessibilityRole} disabled={disabled} onPress={handleClick} testID={testID ? testID : "CpGridCard"} $_css2={localStyles.pressable}>
        {({
        hovered
      }) => <React.Fragment>
            <_StyledImageBackground resizeMode="cover" source={{
          uri: image || ""
        }} $_css3={[localStyles.imageStyle, hovered ? localStyles.imageStyleHovered : undefined]} />

            <_StyledView2 $_css4={localStyles.innerView}>
              {authors && <_StyledPrText children={authors} numberOfLines={2} $_css5={localStyles.authors} />}
              <_StyledPrText2 children={title} $_css6={localStyles.label} />
              <_StyledPrText3 children={snippet?.trim()} numberOfLines={6} $_css7={localStyles.snippet} />
            </_StyledView2>
          </React.Fragment>}
      </_StyledPrPressable>
    </_StyledView>;
};

export default CpGridCard;

var _StyledView = _styled(View).withConfig({
  displayName: "CpGridCard___StyledView",
  componentId: "sc-14b7xnj-0"
})(["", ""], p => p.$_css);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpGridCard___StyledPrPressable",
  componentId: "sc-14b7xnj-1"
})(["", ""], p => p.$_css2);

var _StyledImageBackground = _styled(ImageBackground).withConfig({
  displayName: "CpGridCard___StyledImageBackground",
  componentId: "sc-14b7xnj-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpGridCard___StyledView2",
  componentId: "sc-14b7xnj-3"
})(["", ""], p => p.$_css4);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpGridCard___StyledPrText",
  componentId: "sc-14b7xnj-4"
})(["", ""], p => p.$_css5);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpGridCard___StyledPrText2",
  componentId: "sc-14b7xnj-5"
})(["", ""], p => p.$_css6);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpGridCard___StyledPrText3",
  componentId: "sc-14b7xnj-6"
})(["", ""], p => p.$_css7);