import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { View } from "react-native"; // eslint-disable-next-line no-restricted-imports

import CpReadonlyInput from "components/common/CpReadonlyInput";
import CpFormInputError from "components/form/CpFormInputError";
import { FormNecessity } from "components/form/types";
import PrText from "components/primitives/PrText";
import PrTextInput from "components/primitives/PrTextInput";
import { useFormLabel } from "hooks/useFormLabel";
import { css, styles } from "services/Theme";
const localStyles = {
  container: css`
    background-color: ${({
    theme
  }) => theme.formColors.fieldBackground};
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 1px;
  `,
  containerFocus: css`
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderActive};
  `,
  containerFocusError: css`
    border-color: ${({
    theme
  }) => theme.formColors.fieldBorderError};
  `,
  label: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelInactive};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    font-weight: bold;
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  labelFocus: css`
    color: ${({
    theme
  }) => theme.formColors.fieldLabelActive};
  `,
  labelFocusError: css`
    color: ${({
    theme
  }) => theme.formColors.fieldBorderError};
  `,
  wrapper: css`
    margin-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * A form component for text input.
 */
const CpFormTextInput = ({
  accessibilityLabel,
  defaultValue = "",
  fallbackReadonlyValue,
  label,
  name,
  necessity = FormNecessity.None,
  onBlur,
  onFocus,
  readonly,
  skipError,
  style,
  wrapperStyle,
  ...otherProps
}) => {
  const formattedLabel = useFormLabel({
    label,
    necessity
  });
  const {
    numberOfLines
  } = otherProps;
  const formContext = useFormContext();
  const {
    formState: {
      errors
    },
    getValues
  } = formContext;
  const error = errors[name];
  const valuePresent = getValues()[name]?.length > 0;
  const complete = valuePresent && !error;
  const [hasFocus, setHasFocus] = useState(false);
  const handleBlur = useCallback(e => {
    setHasFocus(false);
    onBlur && onBlur(e);
  }, [onBlur, setHasFocus]);
  const handleFocus = useCallback(e => {
    setHasFocus(true);
    onFocus && onFocus(e);
  }, [onFocus, setHasFocus]);
  const renderTextInput = useCallback(({
    field
  }) => {
    return <_StyledPrTextInput accessibilityLabel={accessibilityLabel} onBlur={e => {
      handleBlur(e);
      field.onBlur();
    }} onChangeText={field.onChange} onFocus={handleFocus} value={field.value} {...otherProps} $_css={[// Ensuring text field doesn't collapse but instead grows to a suitable size when allowing more than one line of input
    numberOfLines ? css`
                  min-height: ${({
      theme
    }) => theme.buttonHeights.small * numberOfLines}px;
                ` : undefined]} />;
  }, [accessibilityLabel, handleBlur, handleFocus, numberOfLines, otherProps]);

  if (readonly) {
    return <CpReadonlyInput fallbackValue={fallbackReadonlyValue} label={label} value={getValues(name)} />;
  }

  return <_StyledView accessible={true} $_css2={[localStyles.wrapper, wrapperStyle]}>
      <_StyledView2 style={style} $_css3={styles.formField} $_css4={localStyles.container} $_css5={hasFocus ? localStyles.containerFocus : undefined} $_css6={hasFocus && error ? localStyles.containerFocusError : undefined}>
        <_StyledPrText children={formattedLabel} $_css7={localStyles.label} $_css8={hasFocus || complete ? localStyles.labelFocus : undefined} $_css9={error ? localStyles.labelFocusError : undefined} />
        <Controller defaultValue={defaultValue} name={name} render={renderTextInput} />
      </_StyledView2>
      {
      /* If the password of the the new password kind (on signup for example), don't use this error message, instead use its own */
    }
      {error && !skipError && <CpFormInputError>{error.message}</CpFormInputError>}
    </_StyledView>;
};

export default CpFormTextInput;

var _StyledPrTextInput = _styled(PrTextInput).withConfig({
  displayName: "CpFormTextInput___StyledPrTextInput",
  componentId: "sc-1z072p6-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpFormTextInput___StyledView",
  componentId: "sc-1z072p6-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpFormTextInput___StyledView2",
  componentId: "sc-1z072p6-2"
})(["", ";", " ", " ", ""], p => p.$_css3, p => p.$_css4, p => p.$_css5, p => p.$_css6);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpFormTextInput___StyledPrText",
  componentId: "sc-1z072p6-3"
})(["", ";", " ", ""], p => p.$_css7, p => p.$_css8, p => p.$_css9);