import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconMedicalSupervision: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M154.46 53.84h51.08a11.65 11.65 0 0111.62 11.61v51.09a11.65 11.65 0 01-11.62 11.61h-51.08a11.65 11.65 0 01-11.62-11.61V65.45a11.65 11.65 0 0111.62-11.61zm16.25 9.29h18.58v18.58h18.58v18.58h-18.58v18.58h-18.58v-18.58h-18.58V81.71h18.58V63.13z"
        fillRule="evenodd"
      />
      <Path d="M53 197a6 6 0 0112 0v109.16H53V197zm177 17.16h-50v-26h-8v26h-8v-26H97a22.05 22.05 0 00-22 22v24h210v-30.83a3 3 0 00-3.77-2.9L230 214.13zm-155 92v-62h210v62h-12v-50H87v50zM295 153.7a6 6 0 0112 0v152.46h-12V153.7z" />
      <Path
        d="M247.17 198.77c-.08-.32-.17-.65-.26-1a23.08 23.08 0 00-28.16-16.34L194.14 188h-6.42v18h32v.08zM266 155.24a19 19 0 11-19 19 19 19 0 0119-19z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconMedicalSupervision
