import React, { Suspense, useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { IntlProvider } from "react-intl";
import { MenuProvider } from "react-native-popup-menu";
import { SafeAreaProvider } from "react-native-safe-area-context";
import CpAppBusy from "components/app/CpAppBusy";
import CpAppLoading from "components/app/CpAppLoading";
import CxAnalytics from "contexts/CxAnalytics";
import CxAppBackend from "contexts/CxAppBackend";
import CxAppParams from "contexts/CxAppParams";
import CxAppRegion from "contexts/CxAppRegion";
import CxI18n, { intlFormats, loadMessages } from "contexts/CxI18n";
import CxImdSession from "contexts/CxImdSession";
import CxNavigationRedirect from "contexts/CxNavigationRedirect";
import { CxPatientSession } from "contexts/CxPatientSession";
import CxRelayEnvironment from "contexts/CxRelayEnvironment";
import CxScreenLayout from "contexts/CxScreenLayout";
import CxTheme from "contexts/CxTheme";
import Config from "services/Config";
import { backendSupportedLocales } from "services/Intl";
import { SnackbarProvider } from "services/Snackbar";
let initialLocale = navigator.language;
new URLSearchParams(window.location.search).forEach((value, key) => {
  if ((key === "locale" || key === "language") && backendSupportedLocales.includes(value)) {
    initialLocale = value;
  }
});
/**
 * This container component wraps the app with the providers it needs to operate in a web environment.
 *
 * Components in this file are common to all apps. They should handle their own errors and loading indicators.
 *
 * This component enables `useIntl`, `useAppBackend`, `useAppRegion`
 */

const CtAppInitializers = ({
  children
}) => {
  const [i18nContext, setI18nContext] = useState({
    locale: backendSupportedLocales.find(languagecode => initialLocale.startsWith(languagecode))
  });
  const [messages, setMessages] = useState();
  useEffect(() => {
    if (i18nContext.locale) {
      loadMessages(i18nContext.locale).then(localeMessages => {
        setMessages(localeMessages.default);
      });
    }
  }, [i18nContext.locale]); // Initializes Google Analytics

  if (!Config.IS_DEV_ENV && Config.GA_TRACKING_ID) {
    ReactGA.initialize(Config.GA_TRACKING_ID);
  }

  return <Suspense fallback={<CpAppLoading />}>
      <SafeAreaProvider>
        <IntlProvider defaultLocale="en" formats={intlFormats} key={i18nContext.locale} // keyed to locale so that React updates upon locale change
      locale={i18nContext.locale ?? "en"} messages={messages}>
          <CxAppParams>
            <CxTheme>
              {
              /* App contexts should go below this line. Only thing that should go above are things needed to display an error */
            }
              <CxAppBackend>
                <CxPatientSession>
                  <CxNavigationRedirect>
                    <CxAppRegion>
                      <CxRelayEnvironment>
                        <CxI18n i18nContext={i18nContext} setI18nContext={setI18nContext}>
                          <CxImdSession>
                            <CxAnalytics>
                              <CxScreenLayout>
                                <SnackbarProvider>
                                  <MenuProvider>
                                    {
                                    /* The suspense should wrap children, to prevent extra re-rendering */
                                  }
                                    <Suspense fallback={<CpAppBusy />}>{children}</Suspense>
                                    {
                                    /* The suspense should wrap children, to prevent extra re-rendering */
                                  }
                                  </MenuProvider>
                                </SnackbarProvider>
                              </CxScreenLayout>
                            </CxAnalytics>
                          </CxImdSession>
                        </CxI18n>
                      </CxRelayEnvironment>
                    </CxAppRegion>
                  </CxNavigationRedirect>
                </CxPatientSession>
              </CxAppBackend>
              {
              /* App contexts should go above this line. Only thing that should go below are things needed to display an error */
            }
            </CxTheme>
          </CxAppParams>
        </IntlProvider>
      </SafeAreaProvider>
    </Suspense>;
};

export default CtAppInitializers;