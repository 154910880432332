/**
 * @generated SignedSource<<93a2e27b75b9959ecfa2066b4c0ede9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MembershipRole = "admin" | "member" | "superadmin" | "%future added value";
export type UserStatus = "active" | "inactive" | "pending" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CxImdSession_currentUserFragment$data = {
  readonly _id: string;
  readonly currentMembership: {
    readonly _id: string;
    readonly id: string;
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly settings: any | null;
    };
    readonly role: MembershipRole;
  } | null;
  readonly currentOrganization: {
    readonly _id: string;
    readonly ancestorIds: ReadonlyArray<string>;
    readonly countryCode: string;
    readonly icon: string | null;
    readonly id: string;
    readonly name: string;
    readonly postalCode: string | null;
    readonly settings: any | null;
    readonly subdivisionCode: string | null;
    readonly type: string;
  } | null;
  readonly email: string | null;
  readonly id: string;
  readonly isKiosk: boolean;
  readonly isPatient: boolean;
  readonly kioskId: string | null;
  readonly name: string | null;
  readonly personalProfile: {
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly preferredLocale: string;
    readonly prefix: string | null;
  } | null;
  readonly practitionerProfile: {
    readonly externalId: string | null;
    readonly graduationYear: string | null;
    readonly licenseCountryCode: string | null;
    readonly licenseSubdivisionCode: string | null;
    readonly signupOrganizationName: string | null;
    readonly specialties: ReadonlyArray<string> | null;
    readonly student: boolean;
    readonly type: string | null;
  } | null;
  readonly status: UserStatus;
  readonly weakPassword: boolean | null;
  readonly " $fragmentType": "CxImdSession_currentUserFragment";
};
export type CxImdSession_currentUserFragment$key = {
  readonly " $data"?: CxImdSession_currentUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CxImdSession_currentUserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "settings",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CxImdSession_currentUserFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Membership",
      "kind": "LinkedField",
      "name": "currentMembership",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "currentOrganization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ancestorIds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countryCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "locale",
              "variableName": "locale"
            }
          ],
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subdivisionCode",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isKiosk",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPatient",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kioskId",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PersonalProfile",
      "kind": "LinkedField",
      "name": "personalProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prefix",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferredLocale",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PractitionerProfile",
      "kind": "LinkedField",
      "name": "practitionerProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "externalId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "graduationYear",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseCountryCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "licenseSubdivisionCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signupOrganizationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specialties",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "student",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weakPassword",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "a4fa83907de121ea81fafdf031c6db84";

export default node;
