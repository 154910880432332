import { AvailableHeightContext } from "contexts/CxAvailableHeight"
import { useContext } from "react"

/**
 * Hook used to communicate to descendents the height available for content
 */
const useAvailableHeight = (): number => {
  return useContext(AvailableHeightContext) ?? window.innerHeight
}

export default useAvailableHeight
