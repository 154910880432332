import { defineMessages } from "react-intl";
export default defineMessages({
  Epidemiologist: {
    id: "JtEos8",
    defaultMessage: "Epidemiologist"
  },
  HealthEducator: {
    id: "fBX+jx",
    defaultMessage: "Health Educator"
  },
  MentalHealth: {
    id: "3pri+d",
    defaultMessage: "Mental Health / Psychiatry"
  },
  Nursing: {
    id: "lZfz2C",
    defaultMessage: "Nursing"
  },
  Nutritionist: {
    id: "tg2zyF",
    defaultMessage: "Nutritionist / Dietician"
  },
  Other: {
    id: "r97I3t",
    defaultMessage: "Other"
  },
  "Other::AlliedHealth": {
    id: "Ujy4Sx",
    defaultMessage: "Allied Health (Other)"
  },
  "Other::Clinic": {
    id: "TUzh/i",
    defaultMessage: "Clinic (Other)"
  },
  "Other::Corporate": {
    id: "scFo+i",
    defaultMessage: "Corporate (Other)"
  },
  Paramedic: {
    id: "YiozCG",
    defaultMessage: "EMT / Paramedic"
  },
  PersonalSupportWorker: {
    id: "0SxeIC",
    defaultMessage: "Personal Support Worker"
  },
  Pharmacy: {
    id: "GDFo4j",
    defaultMessage: "Pharmacy"
  },
  Physician: {
    id: "sYKzUW",
    defaultMessage: "Physician"
  },
  Physiotherapist: {
    id: "VcoMoq",
    defaultMessage: "Physioterapist / Physical Therapist / Occupational Therapist / Chiropractor"
  },
  ProductRepresentative: {
    id: "S8LZA+",
    defaultMessage: "Product Representative"
  },
  RespiratoryTherapist: {
    id: "9KCapu",
    defaultMessage: "Respiratory Therapist"
  }
});