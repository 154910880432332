/**
 * @generated SignedSource<<8eb3ebb69b93d68b8ab904af270ee4c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AdvertisementPlacement = "patient_session_start" | "view_patient_session" | "%future added value";
export type AdvertisementSlot = "hcpBox" | "largeHcpLeaderboard" | "largePatientLeaderboard" | "smallHcpLeaderboard" | "smallPatientLeaderboard" | "%future added value";
export type AppToolName = "Draw" | "Educate" | "Packages" | "Shortcuts" | "%future added value";
export type AppToolStatus = "active" | "expired" | "inactive" | "%future added value";
export type ScreenLayoutHeights = "isShortScreen" | "isTallScreen" | "%future added value";
export type ScreenLayoutWidth = "isMediumOrSmallerScreen" | "isMediumScreen" | "isNarrowScreen" | "isWideScreen" | "%future added value";
export type SupportedHeaderActions = "CHANGE_CLINIC" | "EDUCATE" | "HELP" | "IMD_FOR_PROFESSIONALS" | "MANAGE_ACCOUNT" | "MODE" | "NAVIGATE" | "PACKAGES" | "PICK_LANGUAGE" | "PICK_LANGUAGE_AND_REGION" | "PICK_REGION" | "SHORTCUTS" | "SIGN_OUT" | "SUBMENU" | "%future added value";
export type SupportedHeaderPlacement = "COMMON_ACTION" | "MENU_ACTION" | "%future added value";
export type SupportedHeaderSectionOptions = "LOGO" | "MENU" | "SEARCH_BAR" | "%future added value";
export type SupportedLogoActions = "GO_TO_URL" | "NAVIGATE_TO" | "SEND_IFRAME_MESSAGE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CxImdSession_appConfigFragment$data = {
  readonly appAdvertisement: {
    readonly adSpots: ReadonlyArray<{
      readonly adManagerId: string;
      readonly adUnitId: string;
      readonly placement: AdvertisementPlacement;
      readonly rank: number | null;
      readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
      readonly slot: AdvertisementSlot;
    }> | null;
    readonly hideAds: boolean | null;
  } | null;
  readonly appColors: ReadonlyArray<{
    readonly colorScheme: string;
    readonly " $fragmentSpreads": FragmentRefs<"ThemeColors_configFragment">;
  }>;
  readonly appFooter: {
    readonly disclaimer: string | null;
    readonly hideRules: ReadonlyArray<{
      readonly hide: boolean | null;
      readonly invertMatch: boolean | null;
      readonly rank: number | null;
      readonly routes: ReadonlyArray<string>;
      readonly routesInvert: boolean | null;
      readonly routesRank: number | null;
      readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>;
      readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
    }> | null;
  } | null;
  readonly appHeader: {
    readonly hideMenu: boolean | null;
    readonly hideRules: ReadonlyArray<{
      readonly hide: boolean | null;
      readonly invertMatch: boolean | null;
      readonly rank: number | null;
      readonly routes: ReadonlyArray<string>;
      readonly routesInvert: boolean | null;
      readonly routesRank: number | null;
      readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>;
      readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
    }> | null;
    readonly justifyContent: string | null;
    readonly logoAction: {
      readonly action: SupportedLogoActions;
      readonly actionPayload: string;
    } | null;
    readonly menuActions: ReadonlyArray<{
      readonly actionOptions: any | null;
      readonly actionPlacement: SupportedHeaderPlacement | null;
      readonly actionType: SupportedHeaderActions;
      readonly rank: number | null;
    }> | null;
    readonly search: {
      readonly hideRules: ReadonlyArray<{
        readonly hide: boolean | null;
        readonly invertMatch: boolean | null;
        readonly rank: number | null;
        readonly routes: ReadonlyArray<string>;
        readonly routesInvert: boolean | null;
        readonly routesRank: number | null;
        readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>;
        readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
      }> | null;
      readonly orgScope: string | null;
      readonly placeholder: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"CpContentSearchPicker_fragmentData">;
    } | null;
    readonly sections: {
      readonly left: SupportedHeaderSectionOptions;
      readonly middle: SupportedHeaderSectionOptions;
      readonly right: SupportedHeaderSectionOptions;
    } | null;
  } | null;
  readonly appHome: {
    readonly header: string | null;
    readonly hide: boolean | null;
    readonly hideBacksplash: boolean | null;
    readonly hideSearch: boolean | null;
    readonly search: {
      readonly orgScope: string | null;
      readonly placeholder: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"CpContentSearchPicker_fragmentData">;
    } | null;
    readonly tabs: ReadonlyArray<{
      readonly _id: string;
    }>;
    readonly tagline: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"CpHomeTabView_tabsConfig">;
  } | null;
  readonly appTools: ReadonlyArray<{
    readonly name: AppToolName;
    readonly status: AppToolStatus;
  }> | null;
  readonly appTopic: {
    readonly tableOfContentsHideRules: ReadonlyArray<{
      readonly hide: boolean | null;
      readonly invertMatch: boolean | null;
      readonly rank: number | null;
      readonly routes: ReadonlyArray<string>;
      readonly routesInvert: boolean | null;
      readonly routesRank: number | null;
      readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>;
      readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
    }> | null;
  } | null;
  readonly buttonFontName: string | null;
  readonly defaultFontName: string | null;
  readonly fontFaceCss: string | null;
  readonly headingFontName: string | null;
  readonly organizationId: string | null;
  readonly showingProfessionalContent: boolean | null;
  readonly " $fragmentType": "CxImdSession_appConfigFragment";
};
export type CxImdSession_appConfigFragment$key = {
  readonly " $data"?: CxImdSession_appConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CxImdSession_appConfigFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "screenWidthIs",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hide",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "invertMatch",
    "storageKey": null
  },
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "routes",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "routesInvert",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "routesRank",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "screenHeightIs",
    "storageKey": null
  },
  (v1/*: any*/)
],
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CpContentSearchPicker_fragmentData"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orgScope",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "placeholder",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CxImdSession_appConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppColors",
      "kind": "LinkedField",
      "name": "appColors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "colorScheme",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ThemeColors_configFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fontFaceCss",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "buttonFontName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultFontName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headingFontName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showingProfessionalContent",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppAdvertisement",
      "kind": "LinkedField",
      "name": "appAdvertisement",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideAds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AdvertisementSpot",
          "kind": "LinkedField",
          "name": "adSpots",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slot",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adManagerId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adUnitId",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppHeader",
      "kind": "LinkedField",
      "name": "appHeader",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LogoAction",
          "kind": "LinkedField",
          "name": "logoAction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "action",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actionPayload",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideMenu",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeaderHideRule",
          "kind": "LinkedField",
          "name": "hideRules",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "justifyContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeaderActions",
          "kind": "LinkedField",
          "name": "menuActions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actionPlacement",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actionType",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actionOptions",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AppSearchConfig",
          "kind": "LinkedField",
          "name": "search",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchHideRule",
              "kind": "LinkedField",
              "name": "hideRules",
              "plural": true,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HeaderSections",
          "kind": "LinkedField",
          "name": "sections",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "left",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "right",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "middle",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppHome",
      "kind": "LinkedField",
      "name": "appHome",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "header",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideBacksplash",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hideSearch",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AppSearchConfig",
          "kind": "LinkedField",
          "name": "search",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v5/*: any*/),
            (v4/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tagline",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "tabs",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpHomeTabView_tabsConfig"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppFooter",
      "kind": "LinkedField",
      "name": "appFooter",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disclaimer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FooterHideRule",
          "kind": "LinkedField",
          "name": "hideRules",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppTool",
      "kind": "LinkedField",
      "name": "appTools",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AppTopic",
      "kind": "LinkedField",
      "name": "appTopic",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TableOfContentsHideRule",
          "kind": "LinkedField",
          "name": "tableOfContentsHideRules",
          "plural": true,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AppConfig",
  "abstractKey": null
};
})();

(node as any).hash = "bb4959748a72059774c04e7f4eeac24e";

export default node;
