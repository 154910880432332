import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { View } from "react-native";
import CpLinkButton from "components/common/CpLinkButton";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import { css, styles } from "services/Theme";
import translations from "translations";
const localTranslations = defineMessages({
  graduationYear: {
    id: "hzv8Th",
    defaultMessage: "Graduation year"
  },
  licenseCountry: {
    id: "qlQrQO",
    defaultMessage: "License country"
  },
  licenseNumber: {
    id: "5x9kJd",
    defaultMessage: "License number"
  },
  licenseTerritory: {
    id: "6I2VjF",
    defaultMessage: "License {territory}"
  },
  student: {
    id: "GJ767M",
    defaultMessage: "Student"
  }
});
const localStyles = {
  contactText: css`
    font-weight: normal;
  `,
  headingText: css`
    color: ${({
    theme
  }) => theme.textColors.default};
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  licenseText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  supportText: css`
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
    text-align: center;
  `
};
/**
 * The profile details license information
 */

const CpLicenseInformation = () => {
  const {
    user
  } = useImdSession();
  const {
    formatMessage
  } = useIntl(); // If no profile license information exists, return null

  if (!(user?.practitionerProfile?.externalId || user?.practitionerProfile?.licenseCountryCode || user?.practitionerProfile?.licenseSubdivisionCode)) {
    return null;
  }

  return <>
      <View>
        <_StyledPrText $_css={[styles.textHeader2, localStyles.headingText]}>
          <FormattedMessage id="FwKnPX" defaultMessage="License Information" />
        </_StyledPrText>
        <_StyledPrText2 $_css2={localStyles.licenseText}>
          <FormattedMessage id="u3CQ2w" defaultMessage="Your license information is used to validate your status as a clinician if applicable." />
        </_StyledPrText2>
        <_StyledPrText3 $_css3={localStyles.licenseText}>
          <FormattedMessage id="+aNSBv" defaultMessage="If something is missing, or any information is incorrect, please let us know." />
        </_StyledPrText3>
        <_StyledCpLinkButton url="mailto:support@imdhealth.com" $_css4={localStyles.supportText}>
          <_StyledPrText4 $_css5={localStyles.contactText}>
            <FormattedMessage id="qgymjr" defaultMessage="Contact IMD Health Support" />
          </_StyledPrText4>
        </_StyledCpLinkButton>
      </View>
      <CpReadonlyInput label={formatMessage(localTranslations.licenseCountry)} value={user?.practitionerProfile?.licenseCountryCode && formatMessage(translations.regions[user?.practitionerProfile?.licenseCountryCode.toLowerCase()])} />
    </>;
};

export default CpLicenseInformation;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpLicenseInformation___StyledPrText",
  componentId: "sc-1jhiua-0"
})(["", ""], p => p.$_css);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpLicenseInformation___StyledPrText2",
  componentId: "sc-1jhiua-1"
})(["", ""], p => p.$_css2);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpLicenseInformation___StyledPrText3",
  componentId: "sc-1jhiua-2"
})(["", ""], p => p.$_css3);

var _StyledCpLinkButton = _styled(CpLinkButton).withConfig({
  displayName: "CpLicenseInformation___StyledCpLinkButton",
  componentId: "sc-1jhiua-3"
})(["", ""], p => p.$_css4);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpLicenseInformation___StyledPrText4",
  componentId: "sc-1jhiua-4"
})(["", ""], p => p.$_css5);