var _CpTopicSubtreeResourceItem_resource;

import { useNavigation } from "@react-navigation/native";
import useTopicContext from "hooks/useTopicContext";
import Routes from "navigators/Routes";
import React, { useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import { Linking } from "react-native";
import { getNameVariant } from "services/Graphql";
import Log from "services/Log";
import CpTopicCard from "../common/CpTopicCard";
import CpTopicCardItemView from "../common/CpTopicCardItemView";
const CpTopicSubtreeResourceItemFragment = _CpTopicSubtreeResourceItem_resource !== void 0 ? _CpTopicSubtreeResourceItem_resource : _CpTopicSubtreeResourceItem_resource = require("./__generated__/CpTopicSubtreeResourceItem_resource.graphql");

/**
 * Extracts the required data for a resource and renders a SubtreeItem.
 */
const CpTopicSubtreeResourceItem = ({
  depth,
  resourceKey,
  revisionId
}) => {
  const data = useFragment(CpTopicSubtreeResourceItemFragment, resourceKey);
  const navigation = useNavigation();
  const {
    rootRevisionId
  } = useTopicContext();
  const label = getNameVariant(data, ["brand", "default"]) ?? "";
  const handleSelect = useCallback(() => {
    Log.info("Tagged Resource selected", {
      resourceId: data._id
    });
    navigation.navigate(Routes.topic, {
      resourceId: data._id,
      rootRevisionId,
      selectedRevisionId: revisionId
    });
  }, [data._id, navigation, revisionId, rootRevisionId]);
  const handleAuxSelect = useCallback(() => {
    Log.info("Tagged Resource selected", {
      resourceId: data._id
    });
    Linking.openURL(`${location.origin}/${Routes.topic}/${rootRevisionId}/${revisionId}?resourceId=${data._id}`);
  }, [data._id, revisionId, rootRevisionId]);
  const body = <CpTopicCardItemView depth={depth} icon={data.icon} isLeaf={true} label={label} onAuxSelect={handleAuxSelect} onSelect={handleSelect} resourceType={data.type} />;

  if (!depth) {
    return <CpTopicCard>{body}</CpTopicCard>;
  } else {
    return body;
  }
};

export default CpTopicSubtreeResourceItem;