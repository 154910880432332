import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpTopicSiblingNavigator_resourceNode;

import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import useAppTheme from "../../hooks/useAppTheme";
import Routes from "../../navigators/Routes";
import { QlTopic_comboFragment } from "../../query/QlTopic";
import { getNameVariant } from "../../services/Graphql";
import SortNodeChildren from "../../services/SortNodeChildren";
import { css, styles } from "../../services/Theme";
import CpItem from "../common/CpItem";
import PrIcon, { IconName } from "../primitives/PrIcon";
import PrText from "../primitives/PrText";
export const resourceNodeSiblingFragment = _CpTopicSiblingNavigator_resourceNode !== void 0 ? _CpTopicSiblingNavigator_resourceNode : _CpTopicSiblingNavigator_resourceNode = require("./__generated__/CpTopicSiblingNavigator_resourceNode.graphql");
const localStyles = {
  linkButton: css`
    ${styles.rowAndCenter}
  `,
  nextSibling: css`
    margin-left: auto;
  `,
  previousSibling: css`
    margin-right: auto;
  `,
  sibling: css`
    max-width: 50%;
  `
};

/**
 * Get the siblings when we are currently viewing a tagged resource.
 */
function getTaggedResourceSiblings(taggedResources, resourceNodeRevisionId, currentResourceId) {
  let prevSibling = null;
  let nextSibling = null;
  const currentTaggedResourceIndex = taggedResources.findIndex(resource => resource._id === currentResourceId);

  if (currentTaggedResourceIndex > 0) {
    const prevSiblingData = taggedResources[currentTaggedResourceIndex - 1];
    prevSibling = {
      label: getNameVariant(prevSiblingData) ?? "",
      resourceId: prevSiblingData._id,
      revisionId: resourceNodeRevisionId
    };
  }

  if (currentTaggedResourceIndex < taggedResources.length - 1) {
    const nextSiblingData = taggedResources[currentTaggedResourceIndex + 1];
    nextSibling = {
      label: getNameVariant(nextSiblingData) ?? "",
      resourceId: nextSiblingData._id,
      revisionId: resourceNodeRevisionId
    };
  }

  return {
    next: nextSibling,
    prev: prevSibling
  };
}
/**
 * Gets the siblings when the current node is not a resource within tagged resources
 */


function getResourceNodeSiblings(data) {
  const siblings = Object.assign([], data.siblings);
  SortNodeChildren(siblings, data.parent?.shouldSortChildrenByRank ?? true);
  let prevSibling = null;
  let nextSibling = null;
  const currentNodeIndex = siblings.findIndex(sibling => sibling.revisionId === data.revisionId);

  if (currentNodeIndex > 0) {
    const prevSiblingData = siblings[currentNodeIndex - 1];
    prevSibling = {
      label: getNameVariant(prevSiblingData) ?? "",
      revisionId: prevSiblingData.revisionId
    };
  }

  if (currentNodeIndex < siblings.length - 1) {
    const nextSiblingData = siblings[currentNodeIndex + 1];
    nextSibling = {
      label: getNameVariant(nextSiblingData) ?? "",
      revisionId: nextSiblingData.revisionId
    };
  }

  return {
    next: nextSibling,
    prev: prevSibling
  };
}

const CpTopicSiblingNavigator = ({
  comboKey,
  resourceId
}) => {
  const comboData = useFragment(QlTopic_comboFragment, comboKey);
  const data = useFragment(resourceNodeSiblingFragment, comboData);
  const {
    buttonColors
  } = useAppTheme();
  const {
    navigate
  } = useNavigation();
  const handlePress = useCallback(sibling => {
    // PSP pageview from Prev/Next (if psp)
    navigate(Routes.topic, {
      resourceId: sibling.resourceId,
      rootRevisionId: data.root.revisionId,
      selectedRevisionId: sibling.revisionId
    });
  }, [data.root.revisionId, navigate]); // data.sibling contains not only a resource node's siblings, but also the resource node.

  const {
    next,
    prev
  } = useMemo(() => {
    if (resourceId && comboData.taggedResources) {
      return getTaggedResourceSiblings(comboData.taggedResources, data.revisionId, resourceId);
    }

    return getResourceNodeSiblings(data);
  }, [comboData, data, resourceId]);
  return <_StyledView $_css={styles.navHeaderStyles.row1Container}>
      {prev && <_StyledCpItem left={<PrIcon fill={buttonColors.primary} icon={IconName.keyboardArrowLeft} />} onPressCallback={handlePress} onPressValue={prev} $_css2={[localStyles.previousSibling, localStyles.sibling]}>
          <PrText numberOfLines={2}>{prev.label}</PrText>
        </_StyledCpItem>}
      {next && <_StyledCpItem2 onPressCallback={handlePress} onPressValue={next} right={<PrIcon fill={buttonColors.primary} icon={IconName.keyboardArrowRight} />} $_css3={[localStyles.nextSibling, localStyles.sibling]}>
          <PrText numberOfLines={2}>{next.label}</PrText>
        </_StyledCpItem2>}
    </_StyledView>;
};

export default CpTopicSiblingNavigator;

var _StyledView = _styled(View).withConfig({
  displayName: "CpTopicSiblingNavigator___StyledView",
  componentId: "sc-1jwq0g9-0"
})(["", ""], p => p.$_css);

var _StyledCpItem = _styled(CpItem).withConfig({
  displayName: "CpTopicSiblingNavigator___StyledCpItem",
  componentId: "sc-1jwq0g9-1"
})(["", ""], p => p.$_css2);

var _StyledCpItem2 = _styled(CpItem).withConfig({
  displayName: "CpTopicSiblingNavigator___StyledCpItem2",
  componentId: "sc-1jwq0g9-2"
})(["", ""], p => p.$_css3);