import React from "react"

import CpSignoutButton from "components/common/CpSignoutButton"
import {
  ActionButtonProps,
  ActionButtonUserType,
} from "components/primitives/PrActionButton/PrActionButton"

/**
 * Signout header action button for admin app
 * We have to separate them because we don't have patient session ctx in admin app
 */
const AdminSignoutButton: React.FC<ActionButtonProps> = ({
  onPress,
  type = ActionButtonUserType.AccountMenu,
}) => {
  return <CpSignoutButton onPress={onPress} type={type} />
}

export default AdminSignoutButton
