import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconTreatmentOtc: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M256.26 222.92c0 19 .19 37.9 0 56.84-.3 26.36-15.43 42-42 42.55-23.21.51-46.44.16-69.67.13-20.6 0-37.06-11-38.54-31.53-3.45-47.69-3-87.92 0-136 1.31-20.54 17.92-31.84 38.56-32 23.27-.22 46.55-.17 69.83 0 25 .15 41.16 16.17 41.67 41.65.44 19.38.13 38.87.15 58.36zm-127 60.86c0 7.72 1.34 14.7 10.85 14.59 8.57-.11 10.52-7.4 10.35-13.81l-1.27-130.65c-.79-6.66-1-13.95-10-13.64-8.36.29-9.91 6.87-9.9 13.94zM179.35 104.63c-34.7 0-41.5-5.29-41.61-32.2-.12-28.25 6.6-34.87 35.47-34.92 4.87 0 9.75-.22 14.61 0 25.09 1.15 34.16 58.15 2.73 66.69zM160 85.43c9 .32 8.87-30.45 1.76-30.67-9.85-.3-8.63 30.42-1.76 30.67z" />
    </Svg>
  )
}

export default IconTreatmentOtc
