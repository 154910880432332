/**
 * @generated SignedSource<<16aaf2d6ca4d0cd71595bfce08b97217>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Locale = "en" | "es" | "fr" | "%future added value";
export type SearchMethods = "all_tabs" | "filter_content" | "org_accessible" | "public_accessible" | "single_tab" | "%future added value";
export type ContentFiltersInput = {
  rootsOnly?: boolean | null;
  startsWith?: string | null;
  text?: string | null;
};
export type QlHomeSearch_SearchQuery$variables = {
  after?: string | null;
  before?: string | null;
  contentFilters?: ContentFiltersInput | null;
  first?: number | null;
  last?: number | null;
  locale: Locale;
  organizationId: string;
  searchId?: string | null;
  searchMethod?: SearchMethods | null;
};
export type QlHomeSearch_SearchQuery$data = {
  readonly node: {
    readonly content?: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly _id: string;
          readonly names: any;
          readonly " $fragmentSpreads": FragmentRefs<"CpContentSearchItem_resourceNode">;
        } | null;
      } | null> | null;
      readonly pageInfo: {
        readonly endCursor: string | null;
        readonly hasNextPage: boolean;
        readonly hasPreviousPage: boolean;
        readonly startCursor: string | null;
      };
      readonly totalCount: number;
    };
  } | null;
};
export type QlHomeSearch_SearchQuery = {
  response: QlHomeSearch_SearchQuery$data;
  variables: QlHomeSearch_SearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "before"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentFilters"
},
v3 = {
  "defaultValue": 100,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": 100,
  "kind": "LocalArgument",
  "name": "last"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchMethod"
},
v9 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v10 = {
  "kind": "Variable",
  "name": "locale",
  "variableName": "locale"
},
v11 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "contentFilters",
    "variableName": "contentFilters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  (v10/*: any*/),
  {
    "kind": "Variable",
    "name": "searchId",
    "variableName": "searchId"
  },
  {
    "kind": "Variable",
    "name": "searchMethod",
    "variableName": "searchMethod"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v14 = [
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": (v14/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPreviousPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlHomeSearch_SearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "ResourceNodeConnection",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResourceNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v15/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CpContentSearchItem_resourceNode"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlHomeSearch_SearchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v11/*: any*/),
                "concreteType": "ResourceNodeConnection",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResourceNode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nodeType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "revisionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": (v14/*: any*/),
                            "kind": "ScalarField",
                            "name": "rootNames",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rootRevisionId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "parentId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "resourceType",
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v18/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3061b6f6a41cc9eb8908700f4bbee6ba",
    "id": null,
    "metadata": {},
    "name": "QlHomeSearch_SearchQuery",
    "operationKind": "query",
    "text": "query QlHomeSearch_SearchQuery(\n  $organizationId: ID!\n  $searchMethod: SearchMethods\n  $searchId: String\n  $contentFilters: ContentFiltersInput\n  $locale: Locale!\n  $first: Int = 100\n  $after: String\n  $last: Int = 100\n  $before: String\n) {\n  node(id: $organizationId) {\n    __typename\n    ... on Organization {\n      content(contentFilters: $contentFilters, searchMethod: $searchMethod, searchId: $searchId, locale: $locale, first: $first, after: $after, last: $last, before: $before) {\n        edges {\n          cursor\n          node {\n            _id\n            names(locale: $locale)\n            ...CpContentSearchItem_resourceNode\n            id\n          }\n        }\n        pageInfo {\n          endCursor\n          startCursor\n          hasNextPage\n          hasPreviousPage\n        }\n        totalCount\n      }\n    }\n    id\n  }\n}\n\nfragment CpContentSearchItem_resourceNode on ResourceNode {\n  names(locale: $locale)\n  nodeType\n  revisionId\n  rootNames(locale: $locale)\n  rootRevisionId\n  parentId\n  resourceType\n}\n"
  }
};
})();

(node as any).hash = "598b31352bab674eefbb8a624a0fde19";

export default node;
