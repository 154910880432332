/**
 * @generated SignedSource<<9862a69e773acc439b0effc2a1260c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlTopicTreeSectionQuery$variables = {
  locale?: Locale | null;
  topicTreeId: string;
};
export type QlTopicTreeSectionQuery$data = {
  readonly topicNode: {
    readonly children: ReadonlyArray<{
      readonly children: ReadonlyArray<{
        readonly icon: string | null;
        readonly id: string;
        readonly isLeaf: boolean;
        readonly isLocked: boolean;
        readonly isProfessionalTool: boolean;
        readonly names: any;
        readonly pinSelfToBottom: boolean;
        readonly pinSelfToTop: boolean;
        readonly rank: number;
        readonly resourceNode: {
          readonly defaultResourceNode: {
            readonly resource: {
              readonly embedSnippet: string | null;
            } | null;
          } | null;
        } | null;
        readonly resourceNodeCompletion: {
          readonly completed: number;
          readonly total: number;
        } | null;
        readonly resourceNodeRevisionId: string | null;
        readonly revisionId: string;
        readonly treeId: string | null;
      }>;
      readonly icon: string | null;
      readonly isLeaf: boolean;
      readonly isLocked: boolean;
      readonly isProfessionalTool: boolean;
      readonly names: any;
      readonly pinSelfToBottom: boolean;
      readonly pinSelfToTop: boolean;
      readonly rank: number;
      readonly resourceNode: {
        readonly defaultResourceNode: {
          readonly resource: {
            readonly embedSnippet: string | null;
          } | null;
        } | null;
      } | null;
      readonly resourceNodeRevisionId: string | null;
      readonly revisionId: string;
      readonly shouldSortChildrenByRank: boolean;
      readonly treeId: string | null;
    }>;
    readonly revisionId: string;
    readonly shouldSortChildrenByRank: boolean;
    readonly treeId: string | null;
  } | null;
};
export type QlTopicTreeSectionQuery = {
  response: QlTopicTreeSectionQuery$data;
  variables: QlTopicTreeSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "topicTreeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "revisionId",
    "variableName": "topicTreeId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v4 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLocked",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLeaf",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isProfessionalTool",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToBottom",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pinSelfToTop",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ResourceNodeCompletion",
  "kind": "LinkedField",
  "name": "resourceNodeCompletion",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "treeId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": (v3/*: any*/),
  "kind": "ScalarField",
  "name": "embedSnippet",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "ResourceNode",
  "kind": "LinkedField",
  "name": "resourceNode",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "defaultResourceNode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            (v17/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shouldSortChildrenByRank",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "ResourceNode",
  "kind": "LinkedField",
  "name": "resourceNode",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "defaultResourceNode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            (v17/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlTopicTreeSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TopicNode",
        "kind": "LinkedField",
        "name": "topicNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopicNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v19/*: any*/),
              (v16/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v19/*: any*/),
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlTopicTreeSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "TopicNode",
        "kind": "LinkedField",
        "name": "topicNode",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TopicNode",
            "kind": "LinkedField",
            "name": "children",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopicNode",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v19/*: any*/),
              (v16/*: any*/),
              (v20/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          (v19/*: any*/),
          (v16/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d07716ffedd698b681cedcdb92aace52",
    "id": null,
    "metadata": {},
    "name": "QlTopicTreeSectionQuery",
    "operationKind": "query",
    "text": "query QlTopicTreeSectionQuery(\n  $topicTreeId: String!\n  $locale: Locale\n) {\n  topicNode(revisionId: $topicTreeId) {\n    children {\n      children {\n        icon(locale: $locale)\n        id\n        isLocked\n        isLeaf\n        isProfessionalTool\n        names(locale: $locale)\n        pinSelfToBottom\n        pinSelfToTop\n        rank\n        resourceNodeCompletion {\n          completed\n          total\n        }\n        resourceNodeRevisionId\n        revisionId\n        treeId\n        resourceNode {\n          defaultResourceNode {\n            resource {\n              embedSnippet(locale: $locale)\n              id\n            }\n            id\n          }\n          id\n        }\n      }\n      icon(locale: $locale)\n      isLocked\n      isLeaf\n      isProfessionalTool\n      names(locale: $locale)\n      pinSelfToBottom\n      pinSelfToTop\n      rank\n      resourceNodeRevisionId\n      revisionId\n      shouldSortChildrenByRank\n      treeId\n      resourceNode {\n        defaultResourceNode {\n          resource {\n            embedSnippet(locale: $locale)\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    revisionId\n    shouldSortChildrenByRank\n    treeId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c50d4e372cfc6148c82b5fbaad7cf83";

export default node;
