/**
 * @generated SignedSource<<de227fb67a9c32e134f42dd74810f65e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpResourceNodeViewTab_tabData$data = {
  readonly resourceNode?: {
    readonly revisionId: string;
    readonly rootRevisionId: string;
  } | null;
  readonly " $fragmentType": "CpResourceNodeViewTab_tabData";
};
export type CpResourceNodeViewTab_tabData$key = {
  readonly " $data"?: CpResourceNodeViewTab_tabData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpResourceNodeViewTab_tabData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpResourceNodeViewTab_tabData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceNode",
          "kind": "LinkedField",
          "name": "resourceNode",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revisionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rootRevisionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "ResourceNodeViewTab",
      "abstractKey": null
    }
  ],
  "type": "AppTab",
  "abstractKey": "__isAppTab"
};

(node as any).hash = "8195fe34826ee1f03e6d26dcb17b66f1";

export default node;
