import { defineMessages } from "react-intl"; // Importing from styled-components to prevent issues when importing navHeaderStyles in styles.ts

import { css } from "styled-components/native";
export let InactivitySteps;

(function (InactivitySteps) {
  InactivitySteps["Active"] = "ACTIVE";
  InactivitySteps["Paused"] = "PAUSED";
  InactivitySteps["Reminded"] = "REMINDED";
  InactivitySteps["Reset"] = "RESET";
})(InactivitySteps || (InactivitySteps = {}));

export let ModalSteps;

(function (ModalSteps) {
  ModalSteps["Close"] = "CLOSE_MODAL";
  ModalSteps["PackageDelete"] = "PACKAGE_DELETE";
  ModalSteps["PackageReview"] = "PACKAGE_REVIEW";
  ModalSteps["PackageSaveTemplate"] = "PACKAGE_SAVE_TEMPLATE";
  ModalSteps["PackageSelectTemplate"] = "PACKAGE_SELECT_TEMPLATE";
  ModalSteps["PackageSend"] = "PACKAGE_SEND";
  ModalSteps["PackageStart"] = "PACKAGE_START";
  ModalSteps["PackageSuccess"] = "PACKAGE_SUCCESS";
  ModalSteps["SessionHistory"] = "SESSION_HISTORY";
  ModalSteps["SessionInfo"] = "SESSION_INFO";
  ModalSteps["SessionReview"] = "SESSION_REVIEW";
  ModalSteps["SessionSend"] = "SESSION_SEND";
  ModalSteps["SessionStart"] = "SESSION_START";
  ModalSteps["SessionStartFromHistory"] = "SESSION_START_FROM_HISTORY";
  ModalSteps["SessionSuccess"] = "SESSION_SUCCESS";
})(ModalSteps || (ModalSteps = {}));

export let SessionType;

(function (SessionType) {
  SessionType["PackageSession"] = "packageSession";
  SessionType["PatientSession"] = "patientSession";
  SessionType["PspSession"] = "pspSession";
  SessionType["ImdSession"] = "imdSession";
  SessionType["ViewingSession"] = "viewingSession";
})(SessionType || (SessionType = {}));

export let PatientSessionActions;

(function (PatientSessionActions) {
  PatientSessionActions["AddAnnotation"] = "ADD_ANNOTATION";
  PatientSessionActions["AddResource"] = "ADD_RESOURCE";
  PatientSessionActions["AddToPackage"] = "ADD_TO_PACKAGE";
  PatientSessionActions["ClearAnnotation"] = "CLEAR_ANNOTATION";
  PatientSessionActions["DeletePackage"] = "DELETE_PACKAGE";
  PatientSessionActions["ResetPackage"] = "RESET_PACKAGE";
  PatientSessionActions["ResetSession"] = "RESET_SESSION";
  PatientSessionActions["SetInactivity"] = "SET_INACTIVITY";
  PatientSessionActions["SetModal"] = "SET_MODAL";
  PatientSessionActions["SetSelectedAnnotation"] = "SET_SELECTED_ANNOTATION";
  PatientSessionActions["SetSelectedPackages"] = "SET_SELECTED_PACKAGES";
  PatientSessionActions["SetSelectedResources"] = "SET_SELECTED_RESOURCES";
  PatientSessionActions["SetTrainingNodeViewed"] = "SET_TRAINING_NODE_VIEWED";
  PatientSessionActions["SetViewingData"] = "SET_VIEWING_DATA";
  PatientSessionActions["SetViewingLock"] = "SET_VIEWING_LOCK";
  PatientSessionActions["SetViewingParams"] = "SET_VIEWING_PARAMS";
  PatientSessionActions["SignIn"] = "SIGN_IN";
  PatientSessionActions["SignOut"] = "SIGN_OUT";
  PatientSessionActions["StartPackage"] = "START_PACKAGE";
  PatientSessionActions["StartSession"] = "START_SESSION";
  PatientSessionActions["StartSessionFromHistory"] = "START_SESSION_FROM_HISTORY";
  PatientSessionActions["StopPackage"] = "STOP_PACKAGE";
})(PatientSessionActions || (PatientSessionActions = {}));

export const PatientSessionStyles = {
  adBox: css(["justify-content:center;align-items:center;"]),
  button: css(["align-items:center;margin-top:", "px;"], ({
    theme
  }) => theme.spacing.single),
  buttonContainer: css(["align-items:center;padding-vertical:", "px;"], ({
    theme
  }) => theme.spacing.double),
  buttonContainerLarge: css(["flex-direction:row;justify-content:space-between;"]),
  buttonText: css(["font-family:", ";font-size:", ";font-weight:normal;padding-horizontal:", "px;"], ({
    theme
  }) => theme.fontFamilies.button, ({
    theme
  }) => theme.fontSize("large"), ({
    theme
  }) => theme.spacing.double),
  reviewSelect: {
    groupContainer: css(["background-color:", ";border:1px solid ", ";border-radius:", "px;margin-vertical:", "px;padding:", "px;"], ({
      theme
    }) => theme.brandColors.secondaryBackground, ({
      theme
    }) => theme.brandColors.separator, ({
      theme
    }) => theme.borderRadii.sharp, ({
      theme
    }) => theme.spacing.single, ({
      theme
    }) => theme.spacing.single),
    headerResource: css(["font-family:", ";padding-bottom:", "px;padding-top:", "px;"], ({
      theme
    }) => theme.fontFamilies.heading, ({
      theme
    }) => theme.spacing.half, ({
      theme
    }) => theme.spacing.single),
    separator: css(["height:1px;width:100%;"])
  },
  scrollView: css(["max-width:95vw;max-height:85vh;margin-vertical:", "px;"], ({
    theme
  }) => theme.spacing.half),
  start: {
    button: css(["align-items:center;flex:1;"]),
    buttonContainer: css(["align-items:center;flex-direction:row;justify-content:space-around;padding-vertical:", "px;"], ({
      theme
    }) => theme.spacing.single),
    buttonText: css(["font-family:", ";font-size:", ";padding-top:", "px;text-align:center;"], ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("large"), ({
      theme
    }) => theme.spacing.half),
    buttonTextDisabled: css(["color:", ";"], ({
      theme
    }) => theme.textColors.muted),
    closeButtonContainer: css(["align-items:center;flex-direction:row;justify-content:flex-end;margin-vertical:", "px;"], ({
      theme
    }) => theme.spacing.single),
    closeButtonText: css(["font-size:", ";padding-horizontal:", "px;"], ({
      theme
    }) => theme.fontSize("large"), ({
      theme
    }) => theme.spacing.double)
  },
  subtext: css(["font-size:", ";padding-bottom:", "px;padding-top:", "px;"], ({
    theme
  }) => theme.fontSize("small"), ({
    theme
  }) => theme.spacing.double, ({
    theme
  }) => theme.spacing.half)
};
export const patientSessionTranslations = defineMessages({
  patientPrivacy: {
    id: "MhtnXv",
    defaultMessage: "To respect patient privacy, only the most recent topic will be displayed as a starting point. Older results can be displayed by selecting {showMore}."
  }
});