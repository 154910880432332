import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import Routes from "navigators/Routes";
import { AnalyticsEventName } from "services/Analytics";
import { styles } from "services/Theme";
import translations from "translations";
import { screenTranslations } from "translations/screens";
/**
 * Renders a button that navigates the user back to the IMD homepage.
 */

const CpHomeButton = () => {
  const {
    trackEvent
  } = useAnalytics();
  const {
    formatMessage
  } = useIntl();
  const navigation = useNavigation();
  const handlePress = useCallback(() => {
    navigation.navigate(Routes.home);
    trackEvent({
      data: {},
      eventType: AnalyticsEventName.nav_home_button
    });
  }, [navigation, trackEvent]);
  return <_StyledPrButton accessibilityHint={formatMessage(translations.accessibility.hint.navigatesTo, {
    pageName: "home"
  })} onPress={handlePress} testID="CpHomeButton" type="text" $_css={styles.navHeaderStyles.navButton}>
      <_StyledPrText children={screenTranslations.home} $_css2={styles.navHeaderStyles.navButtonText} />
    </_StyledPrButton>;
};

export default CpHomeButton;

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpHomeButton___StyledPrButton",
  componentId: "sc-j9vhy8-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpHomeButton___StyledPrText",
  componentId: "sc-j9vhy8-1"
})(["", ""], p => p.$_css2);