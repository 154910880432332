import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconLiver: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M213.43 176.62c.3 41.5-4.1 45.9-32.5 60.1-25.9 13-49.6 30.2-75.2 43.8-10.5 5.6-24 10.9-34.9 9-15.7-2.7-13-19.2-14.8-32-3.2-23.5-7.8-46.8-12.1-70.1C31.73 122.12 79.93 58 151.33 72c31.6 6.2 48.9 25.1 55.2 54.8 4.1 19.32 5.47 39.12 6.9 49.82zM237.63 96.72c18.7 3.2 37.7 5.4 56.1 9.8 25.4 6.1 32.2 27.2 13.8 45.7-19.6 19.7-41.1 37.5-62.5 55.1-4.5 3.7-13.7 6.1-18.5 3.9-3.7-1.7-5-11.2-5.3-17.3-1.5-25-2-50.1-3.4-75.2-.9-18.2 3.1-23 19.8-22z" />
    </Svg>
  )
}

export default IconLiver
