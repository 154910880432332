/**
 * @generated SignedSource<<74b6d7a6c86e2afe7c95a1c88f2bdc17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpShortcuts_userShortcuts$data = {
  readonly _id: string;
  readonly currentMembership: {
    readonly " $fragmentSpreads": FragmentRefs<"CpOrganizationShortcuts_data" | "CpPersonalShortcuts_data">;
  } | null;
  readonly " $fragmentType": "CpShortcuts_userShortcuts";
};
export type CpShortcuts_userShortcuts$key = {
  readonly " $data"?: CpShortcuts_userShortcuts$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpShortcuts_userShortcuts">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpShortcuts_userShortcuts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Membership",
      "kind": "LinkedField",
      "name": "currentMembership",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpPersonalShortcuts_data"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CpOrganizationShortcuts_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e05655d704c33fb2cac5a2398ca31178";

export default node;
