import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpResourceTreeSection_resourceNode;

import React, { useMemo } from "react";
import { View } from "react-native";
import { graphql, useFragment } from "react-relay/hooks";
import CpContentLinksSection from "components/home/tabs/CpContentLinksSection";
import PrText from "components/primitives/PrText";
import isUrlString from "helpers/isUrlString";
import { getNameVariant } from "services/Graphql";
import { useBackendSupportedLocale } from "services/Intl";
import { css } from "services/Theme";
import translations from "translations";
const resourceTreeFragment = _CpResourceTreeSection_resourceNode !== void 0 ? _CpResourceTreeSection_resourceNode : _CpResourceTreeSection_resourceNode = require("./__generated__/CpResourceTreeSection_resourceNode.graphql");
const localStyles = {
  resultsContainer: css`
    align-items: center;
    justify-content: center;
    background-color: ${({
    theme
  }) => theme.brandColors.secondaryBackground};
    border: 1px solid ${({
    theme
  }) => theme.brandColors.separator};
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    padding: ${({
    theme
  }) => theme.spacing.double}px;
    margin: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  resultsText: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
  `
};

const CpResourceTreeSection = ({
  // filterPlaceholder,
  // filterSearchMethod,
  linkStyle,
  renderWithoutHeaders = false,
  resourceNodeKey
}) => {
  const locale = useBackendSupportedLocale();
  const resourceTree = useFragment(resourceTreeFragment, resourceNodeKey); // const showFilter = filterSearchMethod === "filter_content"

  const showFilter = false;
  const sections = useMemo(() => {
    // Intended for ResourceNodes with a single top-level node as a parent, rendering all immediate children as tiles/cards
    if (renderWithoutHeaders) {
      return [{
        content: resourceTree?.children?.map(shortcut => {
          const {
            icon,
            revisionId
          } = shortcut;
          const title = {
            [locale]: getNameVariant(shortcut) || ""
          };
          return {
            authors: shortcut?.resource?.authors,
            color: "primary",
            icon: icon && !isUrlString(icon) ? icon : undefined,
            image: icon && isUrlString(icon) ? icon : undefined,
            link: {
              link_type: "resource",
              secondaryValue: revisionId || "",
              value: resourceTree.revisionId
            },
            snippet: shortcut?.resource?.embedSnippet,
            title
          };
        }),
        "section-title": "UNUSED"
      }];
    } else {
      // Standard use-case - immediate children will be rendered as section headers with their children as tiles/cards
      return resourceTree?.children?.map(nodeChild => {
        return nodeChild.children?.map(shortcut => {
          const {
            icon,
            revisionId
          } = shortcut;
          const title = {
            [locale]: getNameVariant(shortcut) || ""
          };
          return {
            authors: shortcut?.resource?.authors,
            color: "primary",
            icon: icon && !isUrlString(icon) ? icon : undefined,
            image: icon && isUrlString(icon) ? icon : undefined,
            link: {
              link_type: "resource",
              secondaryValue: revisionId || "",
              value: resourceTree?.revisionId || ""
            },
            snippet: shortcut?.resource?.embedSnippet,
            title
          };
        });
      });
    }
  }, [locale, renderWithoutHeaders, resourceTree]);

  if (sections) {
    return <>
        {sections.map(section => {
        if (section.content.length) {
          return <CpContentLinksSection // addToPackage={
          //   renderWithoutHeaders
          //     ? { onPressItem: handleAddItemToPackage }
          //     : {
          //         onPressItem: handleAddItemToPackage,
          //         onPressSection: handleAddSectionToPackage,
          //       }
          // }
          key={section["section-title"]} linkStyle={linkStyle} renderWithoutHeaders={renderWithoutHeaders} sectionTitle={section["section-title"] || ""} shortcuts={section.content} // {...otherProps}
          />;
        } else {
          // In case we're filtering content but have no results, or have no content
          return <_StyledView $_css={localStyles.resultsContainer}>
                <_StyledPrText children={showFilter ? translations.labels.noResultsFound : translations.labels.noData} $_css2={localStyles.resultsText} />
              </_StyledView>;
        }
      })}
      </>;
  } else {
    return null;
  }
};

export default CpResourceTreeSection;

var _StyledView = _styled(View).withConfig({
  displayName: "CpResourceTreeSection___StyledView",
  componentId: "sc-msqzvh-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpResourceTreeSection___StyledPrText",
  componentId: "sc-msqzvh-1"
})(["", ""], p => p.$_css2);