import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconPills: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M166 245a24 24 0 11-17-7 23.92 23.92 0 0117 7zm-26.86 12.73a4 4 0 015.65-5.65l14.13 14.13a4 4 0 01-5.65 5.65l-14.13-14.13z"
        fillRule="evenodd"
      />
      <Path
        d="M196.5 238a24 24 0 11-17 7 23.93 23.93 0 0117-7zm-10 28a4 4 0 010-8h20a4 4 0 010 8z"
        fillRule="evenodd"
      />
      <Path d="M116 139h99v12h-99v145h128V139h12v157.05a11.83 11.83 0 01-3.59 8.47 12.1 12.1 0 01-8.47 3.48H116.06a12.09 12.09 0 01-8.47-3.49 11.85 11.85 0 01-3.59-8.46V139z" />
      <Path
        d="M93 127h174v-14H93v14zm174-22V60.93a8.93 8.93 0 00-8.88-8.93H101.88A8.93 8.93 0 0093 60.93V105h21V94.54a4 4 0 018 0V105h23V94.54a4 4 0 018 0V105h23V94.54a4 4 0 018 0V105h23V94.54a4 4 0 018 0V105h23V94.54a4 4 0 018 0V105zM155.78 204a24 24 0 11-7 17 23.92 23.92 0 017-17zm12.73 26.86a4 4 0 01-5.65-5.65l14.14-14.1a4 4 0 015.65 5.65l-14.13 14.13z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconPills
