import { createUseAsyncResource } from "services/AsyncResource"
import Log from "services/Log"
import { OauthTokenResponse, SecureStorageType } from "services/SecureStorage/SecureStorage.types"

// Gets a session if its defined, will otherwise fallback to the global login state
const getAuthData = async (): Promise<undefined | OauthTokenResponse> => {
  try {
    const sessionAccessToken = window.sessionStorage.getItem("accessToken")
    if (sessionAccessToken) {
      return JSON.parse(sessionAccessToken)
    }

    // NB: Don't pass any argument to getGenericPassword or the result will be a value of false
    const rawAccessToken = window.localStorage.getItem("accessToken")
    if (rawAccessToken) {
      return JSON.parse(rawAccessToken)
    }
  } catch (error) {
    Log.info("Error getting access token from async storage", { error })
  }

  return undefined
}

/**
 * A hook that returns the current backend accessToken.
 * Prioritizes accessToken from sessionStorage over localStorage.
 */
export const useAuthData = createUseAsyncResource(async () => getAuthData())

const SecureStorageWeb: SecureStorageType = {
  getAuthData,

  // Local
  removeAccessToken: async (): Promise<void> => {
    try {
      window.localStorage.removeItem("accessToken")
      useAuthData.invalidate()
      Log.info("Access token removed from keychain")
    } catch (error) {
      Log.info("Error resetting access token in async storage", { error })
    }
  },
  setAccessToken: async (accessToken?: OauthTokenResponse): Promise<void> => {
    if (!accessToken) {
      return SecureStorageWeb.removeAccessToken()
    }

    try {
      window.localStorage.setItem("accessToken", JSON.stringify(accessToken))
      useAuthData.invalidate()
      Log.info("Access token saved to keychain")
    } catch (error) {
      Log.info("Error saving access token to async storage", { error })
    }
  },

  // SessionStorage token handlers
  removeSessionAccessToken: async (): Promise<void> => {
    try {
      window.sessionStorage.removeItem("accessToken")
      useAuthData.invalidate()
      Log.info("Access token removed from keychain")
    } catch (error) {
      Log.info("Error resetting access token in async storage", { error })
    }
  },
  setSessionAccessToken: async (accessToken?: OauthTokenResponse): Promise<void> => {
    if (!accessToken) {
      return SecureStorageWeb.removeAccessToken()
    }

    try {
      window.sessionStorage.setItem("accessToken", JSON.stringify(accessToken))
      useAuthData.invalidate()
      Log.info("Access token saved to keychain")
    } catch (error) {
      Log.info("Error saving access token to async storage", { error })
    }
  },
}

export default SecureStorageWeb
