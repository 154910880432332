/**
 * @generated SignedSource<<ceee910eb4597ad1d6fe74de1442ce2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SearchMethods = "all_tabs" | "filter_content" | "org_accessible" | "public_accessible" | "single_tab" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CpContentSearchPicker_fragmentData$data = {
  readonly searchMethod: SearchMethods;
  readonly " $fragmentType": "CpContentSearchPicker_fragmentData";
};
export type CpContentSearchPicker_fragmentData$key = {
  readonly " $data"?: CpContentSearchPicker_fragmentData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpContentSearchPicker_fragmentData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpContentSearchPicker_fragmentData",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchMethod",
      "storageKey": null
    }
  ],
  "type": "AppSearchConfig",
  "abstractKey": null
};

(node as any).hash = "2fe181ffaa97f724ac4058ded330db23";

export default node;
