import _styled20 from "styled-components";
import _styled19 from "styled-components";
import _styled18 from "styled-components";
import _styled17 from "styled-components";
import _styled16 from "styled-components";
import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CtChangeAccountEmailRerequestMutation;

import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { graphql, useMutation } from "react-relay";
import CpAccountChangeEmail from "components/account/CpAccountChangeEmail";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import CpError from "components/common/CpError";
import CpModal from "components/common/CpModal";
import CpReadonlyInput from "components/common/CpReadonlyInput";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import Log from "services/Log";
import { css, rnStyles, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
import translations from "translations";
const rerequestVerificationMutation = _CtChangeAccountEmailRerequestMutation !== void 0 ? _CtChangeAccountEmailRerequestMutation : _CtChangeAccountEmailRerequestMutation = require("./__generated__/CtChangeAccountEmailRerequestMutation.graphql");
const localStyles = {
  accountEmail: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  button: css`
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
    font-weight: normal;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.quadruple}px;
  `,
  changeAccountEmailContainer: css`
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  emailBottomInfo: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
  `,
  heading: css`
    ${styles.headingFont};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
  `,
  rightMargin: css`
    margin-right: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  unconfirmedEmail: css`
    ${styles.italicText};
    ${styles.boldText};
    word-wrap: anywhere;
  `
};
const localTranslations = defineMessages({
  changeEmailAddress: {
    id: "7mcoem",
    defaultMessage: "Change email"
  },
  emailInfoText: {
    id: "DFeE7M",
    defaultMessage: "View the current status of your account email, and update it if necessary."
  },
  emailVerificationRequest: {
    id: "xrpnKC",
    defaultMessage: "Email Verification Request"
  },
  pending: {
    id: "F+ye+O",
    defaultMessage: "pending"
  },
  requestEmailInfoText: {
    id: "I8NhAu",
    defaultMessage: "If you were previously unable to confirm your account, you can rerequest a verification email below."
  },
  requestVerificationEmail: {
    id: "gM0fAv",
    defaultMessage: "Rerequest verification email"
  },
  sentVerificationLink: {
    id: "CBke8c",
    defaultMessage: "We have sent an email to {unconfirmedEmail} with a link to verify your account."
  },
  verificationStatus: {
    id: "Ukphd0",
    defaultMessage: "Verification Status"
  },
  verified: {
    id: "iFIpIr",
    defaultMessage: "verified"
  }
});
/**
 * Container that manages the layout and form that allows a user to see their email status, change their email or to request a new verification email.
 */

const CtChangeAccountEmail = () => {
  const {
    user
  } = useImdSession();
  const {
    brandColors,
    buttonColors
  } = useAppTheme();
  const {
    formatMessage
  } = useIntl();
  const {
    isNarrowScreen
  } = useScreenLayout();
  const navigation = useNavigation();
  const [isEditing, setIsEditing] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestError, setRequestError] = useState("");
  const isUserActive = user?.status === "active";
  const handleCloseRequestModal = useCallback(() => {
    setRequestModalOpen(false);
  }, []);
  const [submitRequestVerificationEmail, isSubmittingRequestVerificationEmail] = useMutation(rerequestVerificationMutation);
  const handleEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const handleCloseEdit = useCallback(() => {
    setIsEditing(false);
  }, []);
  const handleBack = useCallback(() => {
    navigation.navigate(Routes.manage_account);
  }, [navigation]);
  const handleRequestEmail = useCallback(() => {
    setRequestError("");
    submitRequestVerificationEmail({
      onCompleted: ({
        rerequestVerificationEmail
      }) => {
        Log.info(rerequestVerificationEmail?.unconfirmedEmail);
        setRequestModalOpen(true);
      },
      onError: err => {
        Log.error(`CtChangeAccountEmail - ${err}`);
        setRequestError(err.message);
      },
      variables: {
        input: {}
      }
    });
  }, [submitRequestVerificationEmail]); // Resets error state when we change to edit mode or when we open the confirmation modal

  useEffect(() => {
    setRequestError("");
  }, [isEditing, requestModalOpen]);
  return <_StyledScrollView contentContainerStyle={rnStyles.flexSingle} $_css={styles.screen}>
      <CpBackgroundHeader childStyle={styles.flexSingle} header={translations.screens.change_account_email}>
        <_StyledView $_css2={[localStyles.changeAccountEmailContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
          {isEditing ? <CpAccountChangeEmail onClose={handleCloseEdit} /> : <>
              <_StyledCpModal onBackgroundPress={handleCloseRequestModal} visible={requestModalOpen} $_css3={styles.marginHorizontalSingle}>
                <_StyledView2 $_css4={styles.modalContainer}>
                  <_StyledPrText accessibilityLabel="header" children={localTranslations.emailVerificationRequest} $_css5={styles.modalHeaderText} />
                  <_StyledView3 $_css6={[styles.rowAndCenter, styles.paddingVerticalDouble]}>
                    <_StyledPrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} $_css7={[css`
                          min-width: ${iconSizes.large}px;
                        `, localStyles.rightMargin]} />
                    <PrText children={formatMessage(localTranslations.sentVerificationLink, {
                  unconfirmedEmail: <_StyledPrText2 children={user?.email} $_css8={localStyles.unconfirmedEmail} />
                })} />
                  </_StyledView3>
                  <PrButton onPress={handleCloseRequestModal} type="outline">
                    <_StyledPrText3 children={translations.buttons.continue} $_css9={localStyles.buttonText} />
                  </PrButton>
                </_StyledView2>
              </_StyledCpModal>
              <_StyledPrText4 children={localTranslations.emailInfoText} $_css10={localStyles.heading} />
              {!isUserActive && <_StyledPrText5 children={localTranslations.requestEmailInfoText} $_css11={[localStyles.heading, styles.marginVerticalSingle]} />}
              <_StyledView4 $_css12={styles.marginVerticalDouble}>
                <_StyledView5 $_css13={localStyles.accountEmail}>
                  <CpReadonlyInput label={`${formatMessage(translations.labels.accountEmailLabel)} (${isUserActive ? formatMessage(localTranslations.verified) : formatMessage(localTranslations.pending)})`} value={user?.email} />
                  <PrIcon fill={isUserActive ? buttonColors.primary : brandColors.errorBackground} icon={isUserActive ? IconName.verified : IconName.pending} size={iconSizes.medium} />
                </_StyledView5>
                <_StyledPrText6 children={translations.labels.emailInfo} $_css14={localStyles.emailBottomInfo} />
              </_StyledView4>
              <CpError children={requestError} />
              <_StyledPrButton onPress={handleEdit} testID="CtChangeAccountEmailEdit" $_css15={localStyles.button}>
                <_StyledPrText7 children={localTranslations.changeEmailAddress} $_css16={localStyles.buttonText} />
              </_StyledPrButton>
              {!isUserActive && <>
                  <_StyledPrButton2 busy={isSubmittingRequestVerificationEmail} onPress={handleRequestEmail} type="outline" $_css17={localStyles.button}>
                    <_StyledPrText8 children={localTranslations.requestVerificationEmail} $_css18={localStyles.buttonText} />
                  </_StyledPrButton2>
                </>}
              <_StyledPrButton3 onPress={handleBack} type="text" $_css19={localStyles.button}>
                <_StyledPrText9 children={translations.buttons.cancel} $_css20={localStyles.buttonText} />
              </_StyledPrButton3>
            </>}
        </_StyledView>
      </CpBackgroundHeader>
    </_StyledScrollView>;
};

export default CtChangeAccountEmail;

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CtChangeAccountEmail___StyledScrollView",
  componentId: "sc-1r5l7ju-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CtChangeAccountEmail___StyledView",
  componentId: "sc-1r5l7ju-1"
})(["", ""], p => p.$_css2);

var _StyledCpModal = _styled(CpModal).withConfig({
  displayName: "CtChangeAccountEmail___StyledCpModal",
  componentId: "sc-1r5l7ju-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CtChangeAccountEmail___StyledView2",
  componentId: "sc-1r5l7ju-3"
})(["", ""], p => p.$_css4);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText",
  componentId: "sc-1r5l7ju-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CtChangeAccountEmail___StyledView3",
  componentId: "sc-1r5l7ju-5"
})(["", ""], p => p.$_css6);

var _StyledPrIcon = _styled(PrIcon).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrIcon",
  componentId: "sc-1r5l7ju-6"
})(["", ""], p => p.$_css7);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText2",
  componentId: "sc-1r5l7ju-7"
})(["", ""], p => p.$_css8);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText3",
  componentId: "sc-1r5l7ju-8"
})(["", ""], p => p.$_css9);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText4",
  componentId: "sc-1r5l7ju-9"
})(["", ""], p => p.$_css10);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText5",
  componentId: "sc-1r5l7ju-10"
})(["", ""], p => p.$_css11);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CtChangeAccountEmail___StyledView4",
  componentId: "sc-1r5l7ju-11"
})(["", ""], p => p.$_css12);

var _StyledView5 = _styled(View).withConfig({
  displayName: "CtChangeAccountEmail___StyledView5",
  componentId: "sc-1r5l7ju-12"
})(["", ""], p => p.$_css13);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText6",
  componentId: "sc-1r5l7ju-13"
})(["", ""], p => p.$_css14);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrButton",
  componentId: "sc-1r5l7ju-14"
})(["", ""], p => p.$_css15);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText7",
  componentId: "sc-1r5l7ju-15"
})(["", ""], p => p.$_css16);

var _StyledPrButton2 = _styled(PrButton).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrButton2",
  componentId: "sc-1r5l7ju-16"
})(["", ""], p => p.$_css17);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText8",
  componentId: "sc-1r5l7ju-17"
})(["", ""], p => p.$_css18);

var _StyledPrButton3 = _styled(PrButton).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrButton3",
  componentId: "sc-1r5l7ju-18"
})(["", ""], p => p.$_css19);

var _StyledPrText9 = _styled(PrText).withConfig({
  displayName: "CtChangeAccountEmail___StyledPrText9",
  componentId: "sc-1r5l7ju-19"
})(["", ""], p => p.$_css20);