import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// icon source: https://next.material-ui.com/components/material-icons/
const RadioUnchecked: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <Path d="M0 0h24v24H0V0z" fill="none" />
      <Path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </Svg>
  )
}

export default RadioUnchecked
