/**
 * @generated SignedSource<<da4396356a976a9f1cdcd54c3bf1a199>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PatientSupportProgramSessionCreateInput = {
  identifier: string;
  routeParam: string;
  type: string;
};
export type PspPartnerMutation$variables = {
  input: PatientSupportProgramSessionCreateInput;
};
export type PspPartnerMutation$data = {
  readonly patientSupportProgramSessionCreate: {
    readonly patientSession: {
      readonly _id: string;
      readonly patientId: string | null;
    };
  } | null;
};
export type PspPartnerMutation = {
  response: PspPartnerMutation$data;
  variables: PspPartnerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "patientId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PspPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PatientSupportProgramSessionCreatePayload",
        "kind": "LinkedField",
        "name": "patientSupportProgramSessionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientSession",
            "kind": "LinkedField",
            "name": "patientSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PspPartnerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PatientSupportProgramSessionCreatePayload",
        "kind": "LinkedField",
        "name": "patientSupportProgramSessionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PatientSession",
            "kind": "LinkedField",
            "name": "patientSession",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b3aa625cb15dfcd3558a8726f7878ff",
    "id": null,
    "metadata": {},
    "name": "PspPartnerMutation",
    "operationKind": "mutation",
    "text": "mutation PspPartnerMutation(\n  $input: PatientSupportProgramSessionCreateInput!\n) {\n  patientSupportProgramSessionCreate(input: $input) {\n    patientSession {\n      _id\n      patientId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c85b6207909df1ce0350d1672364e963";

export default node;
