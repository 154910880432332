import React, { useEffect, useMemo } from "react"

import { CxImdSession_appConfigFragment$data } from "contexts/__generated__/CxImdSession_appConfigFragment.graphql"
import useAppThemeHelpers from "hooks/useAppThemeHelpers"
import { useFragment } from "react-relay"
import Log from "services/Log"
import { themeFragment } from "services/Theme/ThemeColors"
import {
  ThemeColors_configFragment$data,
  ThemeColors_configFragment$key,
} from "services/Theme/__generated__/ThemeColors_configFragment.graphql"

/**
 * Catches errors and suspense from its children, typically components that make API calls.
 */
const CpThemeUpdater: React.FC<{
  appConfig: CxImdSession_appConfigFragment$data
  darkColorConfigKey: null | ThemeColors_configFragment$key
  lightColorConfigKey: null | ThemeColors_configFragment$key
}> = ({ appConfig, darkColorConfigKey, lightColorConfigKey }) => {
  const { colorScheme, setTheme } = useAppThemeHelpers()
  const darkColorConfig = useFragment(themeFragment, darkColorConfigKey)
  const lightColorConfig = useFragment(themeFragment, lightColorConfigKey)

  // Try to set the desired color scheme, but fall back to the other one if it's available
  let colorConfig: null | ThemeColors_configFragment$data = null
  if (colorScheme === "dark") {
    if (darkColorConfig) colorConfig = darkColorConfig
    else if (lightColorConfig) colorConfig = lightColorConfig
  } else {
    if (lightColorConfig) colorConfig = lightColorConfig
    else if (darkColorConfig) colorConfig = darkColorConfig
  }

  const desiredTheme = useMemo(
    () =>
      appConfig?.appColors
        ? {
            ...colorConfig,
            fontFaceCss: appConfig.fontFaceCss || undefined,
            fontFamilies: {
              ...(appConfig.buttonFontName ? { button: appConfig.buttonFontName } : {}),
              ...(appConfig.defaultFontName ? { default: appConfig.defaultFontName } : {}),
              ...(appConfig.headingFontName ? { heading: appConfig.headingFontName } : {}),
            },
          }
        : undefined,
    [
      appConfig.buttonFontName,
      appConfig?.appColors,
      appConfig.defaultFontName,
      appConfig.fontFaceCss,
      appConfig.headingFontName,
      colorConfig,
    ],
  )

  useEffect(() => {
    if (desiredTheme) {
      Log.info("CpThemeUpdater updating theme")
      setTheme((appTheme) =>
        appTheme
          ? {
              ...appTheme,
              ...desiredTheme,
              fontFamilies: {
                ...appTheme.fontFamilies,
                ...desiredTheme.fontFamilies,
              },
            }
          : undefined,
      )
    }
  }, [desiredTheme, setTheme])

  return null
}

export default CpThemeUpdater
