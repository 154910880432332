import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom - converted official YouTube png to svg
const YouTube: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M32,0 C49.673112,0 64,14.326888 64,32 C64,49.673112 49.673112,64 32,64 C14.326888,64 0,49.673112 0,32 C0,14.326888 14.326888,0 32,0 Z M16.4752341,17.7020641 C13.5027523,18.0403714 11.4281736,19.9350031 11.1595325,24.1875703 C10.6044069,30.0063691 10.5807168,33.9763092 11.1595325,39.7520487 C11.502812,43.9785414 13.3702311,45.8479794 16.4752341,46.2643966 C22.9618532,47.0720103 38.2937003,47.2403634 47.7260333,46.2643966 C51.8103949,45.4504075 52.5640013,42.5450305 52.8319559,39.7520487 C53.4262576,33.7579133 53.3510915,29.2639149 52.8319559,24.1875703 C52.5200707,21.3311114 51.8422089,18.1052308 47.04,17.6029552 C38.2621116,16.7912727 22.9322883,16.7912727 16.4752341,17.7020641 Z M27.6553813,25.6537543 L38.7358307,31.9649999 L27.6553813,38.2762456 L27.6553813,25.6537543 Z" />
    </Svg>
  )
}

export default YouTube
