import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - done_all
const DoneAll: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M0.563 17.875l1.875-1.875 7.438 7.438-1.875 1.875zM29.625 7.438l1.938 1.875-16 16-7.5-7.438 1.938-1.875 5.563 5.563zM24 9.313l-8.438 8.5-1.875-1.875 8.438-8.5z" />
    </Svg>
  )
}

export default DoneAll
