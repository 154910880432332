import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: IcoMoon - checkbox-unchecked
const CheckboxUnchecked: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M28 0h-24c-2.2 0-4 1.8-4 4v24c0 2.2 1.8 4 4 4h24c2.2 0 4-1.8 4-4v-24c0-2.2-1.8-4-4-4zM28 28h-24v-24h24v24z" />
    </Svg>
  )
}

export default CheckboxUnchecked
