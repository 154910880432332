import _styled from "styled-components";

var _CpTopicNodeTab_tabData;

import React from "react";
import CpGrid from "components/common/CpGrid";
import useScreenLayout from "hooks/useScreenLayout";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { css } from "services/Theme";
import CpTopicTreeSection from "./CpTopicTreeSection";
const tabDataFragment = _CpTopicNodeTab_tabData !== void 0 ? _CpTopicNodeTab_tabData : _CpTopicNodeTab_tabData = require("./__generated__/CpTopicNodeTab_tabData.graphql");
const localStyles = {
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * Renders a home screen tab that renders the contents of an entire topic tree
 */
const CpTopicNodeTab = ({
  tab
}) => {
  const {
    contentMinHeight
  } = useScreenLayout();
  const tabData = useFragment(tabDataFragment, tab); // We only allow for content filters as a search method within a TopicNodeTab

  const contentFilterData = tabData?.search?.searchMethod === "filter_content" ? tabData.search : undefined;
  return <>
      {tabData?.topicNode?.revisionId ? <_StyledCpGrid $_css={[localStyles.container, css`
              min-height: ${contentMinHeight}px;
            `]}>
          <CpTopicTreeSection filterPlaceholder={contentFilterData?.placeholder} filterSearchMethod={contentFilterData?.searchMethod} renderWithoutHeaders={tabData?.renderWithoutHeaders ?? false} tabParagraph={tabData?.tabParagraph} tabParagraphContainerStyles={tabData?.tabParagraphContainerStyles} tabParagraphStyles={tabData?.tabParagraphStyles} topicTreeId={tabData.topicNode.revisionId} />
        </_StyledCpGrid> : null}
    </>;
};

export default CpTopicNodeTab;

var _StyledCpGrid = _styled(CpGrid).withConfig({
  displayName: "CpTopicNodeTab___StyledCpGrid",
  componentId: "sc-1rcqptm-0"
})(["", ""], p => p.$_css);