import React from "react"

import CpLanguagePicker from "components/common/CpLanguagePicker"
import {
  ActionButtonProps,
  ActionButtonUserType,
} from "components/primitives/PrActionButton/PrActionButton"

/**
 * Renders the language picker button that also allows user to change regions
 */
const LanguageAndRegionButton: React.FC<ActionButtonProps> = ({
  type = ActionButtonUserType.NavHeader,
}) => {
  return <CpLanguagePicker allowChangeRegion={true} type={type} />
}

export default LanguageAndRegionButton
