import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconHead: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M175.79 23.17c27 .6 48.7 4.2 68.5 15.4a97.1 97.1 0 0128.1 23.8c13.7 17.1 20.9 36.9 23.6 58.1 1.6 12.8 1.6 26 1.2 38.9a17 17 0 003.6 11.5c5.9 8 8.7 17.3 9.5 27.1a44.73 44.73 0 01-8.7 30.5 66.62 66.62 0 01-24.9 21.3 6.64 6.64 0 00-3 3.6c-4 17-11.4 32.3-23.2 45.4a125.08 125.08 0 01-39.7 29.6c-10.3 4.9-20.9 9-32.7 8.4-9.4-.5-18-3.7-26.4-7.6a123.5 123.5 0 01-40-29.4 100.08 100.08 0 01-24.1-46.1c-.7-3.3-2.2-4.8-5.3-6.4-14.2-7.2-25.6-17.4-30.7-33.1-3.4-10.6-2.3-21.4 1.7-31.7 2-5.1 5.8-9.5 8.1-14.5 1.2-2.6 1.3-5.9 1.3-8.9.1-9.8-.5-19.5.2-29.3a150 150 0 013.9-25.9c5.2-21 15.7-39.2 32.2-53.5a105.26 105.26 0 0152-24.1c9.6-1.6 19.5-2.5 24.8-3.1zm-48 79.5c-2.6 6.2-4.8 11.9-7.2 17.5-3.4 7.9-7.9 14.9-16.3 18.5a6.41 6.41 0 00-2.8 4.1c-1.3 7.7-2.6 15.4-3.4 23.1-.9 8.4-3.4 13.1-10.9 14.3a11.34 11.34 0 00-8.4 5.2c-4.6 7-7.9 20-.1 28.4 6 6.4 12.1 12 21.1 14 7.5 1.7 8.9 5.2 10.2 14.4 2.1 15.4 8.6 28.7 18.8 40.3 10.9 12.4 24.2 21.3 39.5 27.5 7.2 2.9 14.5 4.2 21.9.7 2.3-1.1 4.8-2 7.2-3 16.2-6.9 29.8-17.2 40.2-31.5a71 71 0 0013.5-36.5c.4-5.1 3.7-9.5 8.5-10.2 8-1.1 13.6-5.8 18.9-11.2 7.8-7.9 10.1-17 5.8-27.3-2.3-5.4-5.1-10.2-12.3-11-4.9-.5-8.3-4.4-9-9.7s-1.4-10.8-2-16.2a82.06 82.06 0 00-14-36.8c-1.2-1.7-2.5-2.2-4.6-1.2-7.7 3.8-15.6 7.1-23.1 11.1-11.2 5.9-22.5 11.1-35.2 12.8-10 1.3-19.6 0-28.5-5.2-11.9-6.9-19.6-17.4-26-29.1-.5-.9-1.1-1.8-1.8-3z" />
      <Path d="M226.39 219.77c-10.8.9-20-9.4-20.2-18.7-.3-10.8 7.2-21.1 20.2-21 11.5 0 19.4 8.8 19.8 19.2.4 11-8.4 21-19.8 20.5zM134.09 219.77c-11.2.6-20.1-9.2-19.9-20.1.2-11.1 8.6-19.8 20.1-19.7 13.2.1 20 10.4 20 20.6-.1 9.6-9.6 20.2-20.2 19.2z" />
    </Svg>
  )
}

export default IconHead
