import React, { createContext, memo, useEffect, useState } from "react"
import { Linking } from "react-native"

import CpAppLoading from "components/app/CpAppLoading"
import { isInEnum } from "helpers/isInEnum"
import SecureStorage from "services/SecureStorage"

/**
 * The list arguments supported by the system
 */
enum SupportedAppParams {
  AccessToken = "access_token",
  AllowedOrigin = "allowed_origin",
  InitialBackground = "initial_background",
  InitialColorScheme = "initial_color_scheme",
  InitialLogo = "initial_logo",
  KioskId = "kiosk_id",
  LocationId = "location_id",
  MembershipId = "membership_id",
  OrganizatioId = "organization_ids",
  SpinnerColor = "spinner_color",
  TestApiDomainName = "test_api_domain_name",
  TopicId = "topic_id",
}

export type AppParams = {
  arrayParams: Partial<Record<SupportedAppParams, string[]>>
  stringParams: Partial<Record<SupportedAppParams, string>>
  url: URL
}

export const AppParamsContext = createContext<undefined | AppParams>(undefined)

/**
 * Provides information about the query params and the full URL that the app was started with
 */
const CxAppParams: React.FC<{ children: React.ReactNode }> = memo(({ children }) => {
  const [initialLink] = useState<undefined | Promise<null | string>>(() => Linking.getInitialURL())
  const [appParams, setAppParams] = useState<undefined | AppParams>()

  // Extract the initial params
  useEffect(() => {
    initialLink?.then(async (linkText) => {
      // Load the initial params
      const arrayParams: AppParams["arrayParams"] = {}
      const stringParams: AppParams["stringParams"] = {}
      const url = new URL(linkText ?? location.href ?? "www.imdhealth.app")

      if (linkText) {
        new URLSearchParams(url.search).forEach((value, key) => {
          if (isInEnum(SupportedAppParams, key)) {
            arrayParams[key] ||= []
            arrayParams[key]?.push(value)

            // With string params, the last one wins
            stringParams[key] = value
          }
        })
      }

      // If the query string spefies an access token, then store it to the access token store
      if (stringParams.access_token) {
        await SecureStorage.setAccessToken({
          access_token: stringParams.access_token,
          source: "url",
          token_type: "Bearer",
        })
      }

      if (
        stringParams.initial_color_scheme &&
        !["light", "dark"].includes(stringParams.initial_color_scheme)
      ) {
        delete arrayParams.initial_color_scheme
      }

      // Update the app params
      setAppParams({
        arrayParams,
        stringParams,
        url,
      })
    })
  }, [initialLink])

  if (!appParams) {
    return <CpAppLoading />
  } else {
    return <AppParamsContext.Provider value={appParams}>{children}</AppParamsContext.Provider>
  }
})

export default CxAppParams
