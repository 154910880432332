import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconSkin: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M164.37 249.2c0 5.9-.1 10.7 0 15.6.4 12.4 8.5 20.4 20.4 20.3a20.83 20.83 0 0020.4-22.1 115.58 115.58 0 00-1.7-11.8c8.6-3.4 66.8-4.3 88.2-1.4V305H70.37c-2.4-10-2.8-40.4-.6-55.7 31.1-.1 62.3-.1 94.6-.1zM168 166.2c-.1 26.2.8 51.5-2.1 77.1H70.27c-2.3-10.3-2.6-62.2-.5-77.1zM291.07 166.2V242c-10.7 2.2-63 2.8-86.9 1.1-3.8-25.3-7.6-50.7-11.6-76.9z" />
      <Path d="M151.37 55c4.3 8.7 9.3 17.2 12.9 26.2a396.53 396.53 0 0125.1 100c3 25.8 5.1 51.7 7.6 77.5.6 5.7-.6 10.7-5.4 14.4-7.3 5.6-17 2-18.5-7-.5-3.1-.1-6.3-.1-9.4.2-23.8.9-47.7.4-71.5-.9-42.8-6.8-84.8-23-124.8a23 23 0 01-.9-4.7 11.45 11.45 0 001.9-.7zM251.87 139.1c-2.5-10.4-4.1-18.8-6.4-26.9s-5.5-15.8-7.1-24c11 14.8 15.6 32.4 23 50.2h29.5v21.4h-100c-1-3.5-2.1-6.8-2.9-10.2-.7-3-.9-6-1.5-10.4 21.53-.1 42.4-.1 65.4-.1z" />
      <Path d="M69.07 159v-19.7c10.4-.3 20.4-.6 31.4-.9-4.1-11.7-7.7-22-11.3-32.3.8-.3 1.5-.6 2.3-.9 5.4 10.9 10.7 21.8 16.5 33.5h57.4c3.3 7 2.3 13.6 2.1 20-10.47 2.3-84.8 2.6-98.4.3z" />
    </Svg>
  )
}

export default IconSkin
