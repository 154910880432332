import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const ResourceArticle: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M43.2537039,2.99984454 L52,13.6200404 L52,13.7258445 L52.0484562,13.7266978 L52.0484562,13.7266978 L52.0484562,16.5 L52,16.4998445 L52,56.9340337 C52,60.8805946 51.098955,61.9999965 46.995199,61.9999965 L17.0346491,61.9999965 C13.1665147,61.9999965 12,60.8805946 12,56.9340337 L12,7.26352898 C12,3.48154628 13.6209619,3.01914798 16.8144734,3.00046459 L43.2537039,2.99984454 Z M40.801,5.99984454 L16.4093302,6.00004783 C15.0660742,6.00381096 15.0029611,6.077771 15.0001287,7.32828911 L15,7.47786402 L15,57.6313841 C15,58.6458992 15.1422743,58.9729669 16.399661,58.9984613 L16.5700718,59.0001081 L47.6911219,59.0001081 C48.7551277,59.0001081 48.9804432,58.774172 48.998728,57.7788635 L49,57.6313841 L48.999,16.4998445 L44.5367934,16.5 L44.5367934,16.5 C42.4999999,16.5 40.801013,14.696104 40.801013,11.7718645 L40.801,5.99984454 Z M40,49 L40,51 L20,51 L20,49 L40,49 Z M44,43 L44,45 L20,45 L20,43 L44,43 Z M44,37 L44,39 L20,39 L20,37 L44,37 Z M40,31 L40,33 L20,33 L20,31 L40,31 Z M44,25 L44,27 L20,27 L20,25 L44,25 Z M41,19 L41,21 L20,21 L20,19 L41,19 Z M36,13 L36,15 L20,15 L20,13 L36,13 Z M48.249,13.7258445 L43.321,7.76784454 L43.3212233,10.5 L43.3212233,10.5 C43.3212233,13.5612261 43.8279366,13.7182121 45.6442083,13.7262626 L48.249,13.7258445 Z" />
    </Svg>
  )
}

export default ResourceArticle
