import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - info
const Info: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M17.313 12v-2.688h-2.625v2.688h2.625zM17.313 22.688v-8h-2.625v8h2.625zM16 2.688q5.5 0 9.406 3.906t3.906 9.406-3.906 9.406-9.406 3.906-9.406-3.906-3.906-9.406 3.906-9.406 9.406-3.906z" />
    </Svg>
  )
}

export default Info
