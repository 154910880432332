import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - check
const Check: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M12 21.563l14.125-14.125 1.875 1.875-16 16-7.438-7.438 1.875-1.875z" />
    </Svg>
  )
}

export default Check
