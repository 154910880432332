import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - fullscreen
const Fullscreen: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M18.688 6.688h6.625v6.625h-2.625v-4h-4v-2.625zM22.688 22.688v-4h2.625v6.625h-6.625v-2.625h4zM6.688 13.313v-6.625h6.625v2.625h-4v4h-2.625zM9.313 18.688v4h4v2.625h-6.625v-6.625h2.625z" />
    </Svg>
  )
}

export default Fullscreen
