/* eslint-disable no-console */
import Config from "services/Config"
import * as Sentry from "services/CrashReporting/SentryProxy"
import { getVersion } from "services/Version"

/*
 * Service to handle setup and sending of crash/error reports
 */

let isSentryEnabled = Boolean(Config.SENTRY_DSN)
try {
  if (isSentryEnabled) {
    Sentry.init({
      debug: false,
      denyUrls: [
        // Google tags sometime has it's own opinions about things
        /www\.googletagmanager\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      dsn: Config.SENTRY_DSN,
      environment: Config.DEPLOYMENT_NAME,
      release: Config.PROD ? getVersion() : `${getVersion()}-${Config.DEPLOYMENT_NAME}`,
    })
    if (Config.IS_DEV_ENV) {
      console.info("CrashReporting installing Sentry")
    }
  } else {
    if (Config.IS_DEV_ENV) {
      console.info("CrashReporting skipping Sentry installation")
    }
  }
} catch (e) {
  console.debug("Sentry failed to start")
  isSentryEnabled = false
}

function addBreadcrumb(options: {
  data?: { [key: string]: unknown }
  level: Sentry.SeverityLevel
  message: string
}): void {
  if (isSentryEnabled) {
    Sentry.addBreadcrumb({ category: "imd", ...options })
  }
}

function clearUserContext(): void {
  if (isSentryEnabled) {
    addBreadcrumb({ level: "info", message: "clear user context" })
    Sentry.configureScope((scope) => scope.setUser({ email: "", id: "", username: "" }))
  }
}

function reportError(err: Error, extras: { [key: string]: unknown }): void {
  if (isSentryEnabled) {
    Sentry.withScope((scope) => {
      scope.setExtras(extras)
      Sentry.captureException(err)
    })
  }
}

function reportMessage(message: string, extra: { [key: string]: unknown }): void {
  if (isSentryEnabled) {
    Sentry.withScope((scope) => {
      scope.setExtras(extra)
      Sentry.captureMessage(message, "fatal")
    })
  }
}

// Use to override version - e.g. when running a codepush JS bundle
function setRelease(release: string): void {
  if (isSentryEnabled) {
    Sentry.setRelease(release)
  }
}

function setUserContext(id: string, email: string): void {
  if (isSentryEnabled) {
    addBreadcrumb({
      level: "info",
      message: `set userContext id: ${id}, email: ${email}`,
    })
    Sentry.configureScope((scope) => scope.setUser({ email, id, username: "" }))
  }
}

function triggerJavascriptCrash(): void {
  if (isSentryEnabled) {
    const message = "Triggering javascript crash..."
    addBreadcrumb({ level: "info", message })
    if (Config.IS_DEV_ENV) {
      console.info(message)
    }
    throw new Error(message)
  }
}

function triggerNativeCrash(): void {
  if (isSentryEnabled) {
    const message = "Triggering native crash..."
    addBreadcrumb({ level: "info", message })
    if (Config.IS_DEV_ENV) {
      console.info(message)
    }
    Sentry.nativeCrash()
  }
}

export default {
  addBreadcrumb,
  clearUserContext,
  reportError,
  reportMessage,
  setRelease,
  setUserContext,
  triggerJavascriptCrash,
  triggerNativeCrash,
}
