import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconRheumatology: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M98.22 284.66c-18.4 2-28.6-4.1-32.6-18.9-2.9-10.7 2.8-21 13.3-24a11.17 11.17 0 014.4-.6c23.9 3.5 38.9-9.5 52.6-26.6 28.4-35.6 57.6-70.5 86.1-106 9.7-12.1 9.6-20.8 1.5-33.7-5.3-8.4-4.9-14.8 3-20.5 12-8.6 25.6-10.3 39.4-4.7 9.1 3.7 11.1 9.6 7.9 19.2-5.1 15-4.7 15.7 10.9 21.8 11.5 4.5 13.6 12.1 5.7 21.9-2.7 3.4-6.2 6.8-10 8.6-12.2 5.6-20.1 15.6-28.3 25.5q-47.55 58.05-95.3 115.8c-7.4 9-12.9 18.5-14.1 30.4-1.6 15-8.9 21.2-23.2 20.7-14-.5-19.2-5.9-20.6-21.6a56.39 56.39 0 01-.7-7.3z" />
    </Svg>
  )
}

export default IconRheumatology
