var _QlCountryRegionsQuery;

import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
import React from "react";
import { graphql } from "react-relay";

/**
 * Used to fetch the country regions with the locale and region
 */
export const QlCountryRegionsRequest = _QlCountryRegionsQuery !== void 0 ? _QlCountryRegionsQuery : _QlCountryRegionsQuery = require("./__generated__/QlCountryRegionsQuery.graphql");

const QlCountryRegions = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlCountryRegionsRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlCountryRegions;