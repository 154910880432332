var _QlRelatedTopicsQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";
export const QlRelatedTopicsRequest = _QlRelatedTopicsQuery !== void 0 ? _QlRelatedTopicsQuery : _QlRelatedTopicsQuery = require("./__generated__/QlRelatedTopicsQuery.graphql");
/**
 * Query loader for the related topics section of a topic.
 */

const QlRelatedTopics = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlRelatedTopicsRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlRelatedTopics;