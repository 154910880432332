import Color from "color"

import common, { commonColors } from "services/Theme/common"
import { BrandColors, ButtonColors, FormColors, TextColors, ThemeTemplate } from "."

const headerBackgroundColor = `linear-gradient(
  180deg,
  #14d7b4 0px,
  #00bfba 25px,
  #00a7bf 50px,
  #008dbf 75px,
  #0073b8 100px,
  #0059aa 125px,
  #003d92 150px,
  #0f1e73 175px
)`

const brandColors: BrandColors = {
  background: commonColors.lightGrey,
  dropShadow: commonColors.darkBlue10,
  errorBackground: "#fbeeeb",
  homeSearchBacksplash: commonColors.black30,
  inverseBackground: commonColors.darkGrey,
  navHeaderBackground: commonColors.white,
  primary: commonColors.darkBlue,
  screenOverlay: commonColors.black30,
  searchPopupBorder: commonColors.darkBlue,
  secondary: commonColors.green,
  secondaryBackground: commonColors.blueGrey,
  selected: commonColors.lightBlue30,
  separator: commonColors.black10,
  surface: commonColors.white,
  tertiary: commonColors.pink,
  transparent: Color(commonColors.white).alpha(0).string(),
  warningBackground: Color(commonColors.red).alpha(0.3).string(),

  tabBackground: commonColors.blueGrey,
  tabBorder: commonColors.mediumLightGrey,
  tabSeparator: commonColors.black10,

  nodeNavigatorBase: commonColors.blue,
  nodeNavigatorIcon: commonColors.mediumGrey,
  nodeNavigatorOpenHeaderBackground: commonColors.green10,
  nodeNavigatorPresentationSectionNode: commonColors.offWhite,
  nodeNavigatorSelected: commonColors.green10,
  nodeNavigatorSeparator: commonColors.darkBlue20Opaque,

  // Subtree Navigator specific
  nodeNavigatorTreeChild1: commonColors.darkBlue,
  nodeNavigatorTreeChild2: commonColors.lightBlue,
  nodeNavigatorTreeChild3: commonColors.blue,
  nodeNavigatorTreeChild4: commonColors.darkBlue50Opaque,

  // Snackbar notification colours
  snackbarAccentError: commonColors.red,
  snackbarAccentPrimary: commonColors.lightBlue,
  snackbarAccentWarning: commonColors.yellow,
  snackbarBackgroundError: commonColors.red30Opaque,
  snackbarBackgroundPrimary: commonColors.lightBlue30Opaque,
  snackbarBackgroundWarning: commonColors.yellow30Opaque,
}

const buttonColors: ButtonColors = {
  destructive: commonColors.red,
  paginationSelected: Color(commonColors.lightBlue).alpha(0.3).string(),
  primary: commonColors.blue,
  secondary: commonColors.darkBlue,
  surface: commonColors.white,
  tertiary: commonColors.yellow,
  text: commonColors.white,
  textInputActive: commonColors.orange,
  textInputDisabled: commonColors.nickel,
  textInverse: commonColors.black,
}

const formColors: FormColors = {
  errorBackground: "#fbeeeb",
  fieldBackground: commonColors.white,
  fieldBackgroundError: Color(commonColors.red).alpha(0.1).string(),
  fieldBackgroundLabelActive: Color(commonColors.blue).alpha(0.1).string(),
  fieldBorderActive: commonColors.blue,
  fieldBorderError: commonColors.red,
  fieldBorderInactive: commonColors.mediumGrey,
  fieldError: commonColors.red,
  fieldLabelActive: commonColors.blue,
  fieldLabelInactive: commonColors.mediumGrey,
  fieldPasswordProgressBar: commonColors.darkBlue,
  fieldPasswordSecureBox: "#ebffff",
  fieldPasswordSecureText: commonColors.blue,
}

const textColors: TextColors = {
  default: "#222",
  disabled: commonColors.nickel,
  error: commonColors.red,
  footer: commonColors.mediumDarkGrey,
  heading1: commonColors.white,
  heading2: "#222",
  inverse: commonColors.white,
  link: commonColors.blue,
  muted: "#464747",
  mutedInverse: "#ffffffaa",
  mutedPrimary: commonColors.darkBlue70,
  navHeader: "#222",
  tabLabel: "#222",
  textInputBackground: commonColors.white,
  textInputPlaceholder: "#767676",
  textInputSecondaryBackground: commonColors.offWhite,
}

const light: ThemeTemplate = {
  ...common,
  brandColors,
  buttonColors,
  formColors,
  headerBackgroundColor,
  name: "light",
  navHeaderStatusBarStyle: "light-content",
  statusBarStyle: "dark-content",
  textColors,
}

export default light
