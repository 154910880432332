import { Platform } from "react-native"

import {
  BorderRadii,
  FontFamilies,
  FontSizes,
  HeightBreaks,
  IconSizes,
  Layers,
  MediaBreaks,
  Spacing,
  ThemeSize,
  ThemeTemplate,
} from "."

export const borderRadii: BorderRadii = {
  default: 8,
  sharp: 4,
  soft: 12,
  switch: 34,
}

// TODO: These are somewhat arbitrarily selected
export const buttonHeights = {
  large: 50,
  medium: 44,
  small: 34,
  tile: 160,
  xlarge: 54,
  xsmall: 28,
  xxlarge: 60,
  xxsmall: 20,
}

// [colourName]## - colours at a specified opacity
// [colourName##]Opaque - full opacity colours that allow for overlapping without changing colours displayed
export const commonColors = {
  black: "#000000",
  black10: "rgba(0, 0, 0, 0.1)",
  black30: "rgba(0, 0, 0, 0.3)",
  blue: "#005C8A",
  blueGrey: "#F0F0F5", // modified darkBlue
  darkBlue: "#0f1e73",
  darkBlue10: "rgba(15, 30, 115, 0.1)",
  darkBlue20: "rgba(15, 30, 115, 0.2)",
  darkBlue20Opaque: "#C4C7D8",
  darkBlue50: "rgba(15, 30, 115, 0.5)",
  darkBlue50Opaque: "#868EB9",
  darkBlue70: "rgba(15, 30, 115, 0.7)",
  darkGrey: "#0d0d0d", // other end of spectrum from lightGrey
  green: "#14d7b4",
  green10: "rgba(20,215,180, 0.1)", // toggled overlay
  lightBlue: "#0090C1",
  lightBlue10: "rgba(0, 144, 193, 0.1)",
  lightBlue30: "rgba(0, 144, 193, 0.3)", // @ 30% opacity
  lightBlue30Opaque: "#B2DEED",
  lightBlue50: "rgba(0, 144, 193, 0.5)",
  lightBlue70: "rgba(0, 144, 193, 0.7)",
  lightGrey: "#f2f2f2", // a brand color
  magnolia: "#fffaff",
  mediumDarkGrey: "#505050",
  mediumDarkerGrey: "#212121",
  mediumGrey: "#595959",
  mediumLightGrey: "#808080",
  nickel: "#949494",
  offWhite: "#F7F8F8",
  orange: "#D17A22",
  pink: "#ff91b4",
  purple: "#761ACB",
  purple10: "rgba(118,26,203, 0.1)", // selected item
  red: "#A50000",
  red30Opaque: "#D9B8B8",
  white: "#fff",
  white10: "rgba(255, 255, 255, 0.1)",
  white30: "rgba(255, 255, 255, 0.3)",
  yellow: "#FCBA04",
  yellow30Opaque: "#E4D099",
}

export const fontFamilies: FontFamilies = {
  button: "TitilliumWeb",
  default: "Inter",
  heading: "Montserrat",
}

export const fontSizes: Record<string, FontSizes> = {
  pt: {
    large: 18,
    medium: 13,
    small: 11,
    xlarge: 22,
    xsmall: 9,
    xxlarge: 28,
    xxsmall: 7,
  },
  px: {
    large: 22,
    medium: 16,
    small: 14,
    xlarge: 28,
    xsmall: 12,
    xxlarge: 36,
    xxsmall: 10,
  },
}

export const iconSizes: IconSizes = {
  large: 42,
  medium: 30,
  small: 22,
  xlarge: 60,
  xsmall: 16,
  xxlarge: 80,
  xxsmall: 12,
}

// z-index for layers: the bigger the layer, the bigger the z-index
export const layers: Layers = {
  fifthLayer: 50,
  firstLayer: 10,
  fourthLayer: 40,
  secondLayer: 20,
  sixthLayer: 60,
  thirdLayer: 30,
}

export const mediaBreaks: MediaBreaks = {
  medium: 768,
  narrow: 480,
  wide: 1200,
}

export const heightBreaks: HeightBreaks = {
  short: 500,
  tall: 1000,
}

export enum ScreenOrientation {
  Landscape,
  Portrait,
}

export interface SizeBreakdowns {
  isMediumOrSmallerScreen: boolean
  isMediumScreen: boolean
  isNarrowScreen: boolean
  isShortScreen: boolean
  isTallScreen: boolean
  isWideScreen: boolean
  screenOrientation: ScreenOrientation
}

/**
 * This function evaluates the static height and width breakpoints, and returns size helpers for use when making rendering decisions
 */
export const getSizeBreakdowns = (width: number, height: number): SizeBreakdowns => {
  return {
    isMediumOrSmallerScreen: width <= mediaBreaks.medium,
    isMediumScreen: width >= mediaBreaks.medium && width < mediaBreaks.wide,
    isNarrowScreen: width < mediaBreaks.narrow,
    isShortScreen: height < heightBreaks.short,
    isTallScreen: height > heightBreaks.tall,
    isWideScreen: width >= mediaBreaks.wide,
    screenOrientation: width > height ? ScreenOrientation.Landscape : ScreenOrientation.Portrait,
  }
}

export const spacing: Spacing = {
  double: 16,
  half: 4,
  quadruple: 32,
  quarter: 2,
  single: 8,
  third: 3,
  triple: 24,
}

export const relativeFontSizes: { [key in ThemeSize]: string } = {
  large: "large",
  medium: "medium",
  small: "small",
  xlarge: "x-large",
  xsmall: "x-small",
  xxlarge: "xx-large",
  xxsmall: "xx-small",
}

const theme: Omit<
  ThemeTemplate,
  | "brandColors"
  | "buttonColors"
  | "formColors"
  | "headerBackgroundColor"
  | "statusBarStyle"
  | "textColors"
> = {
  borderRadii,
  buttonHeights,
  fontFamilies,
  fontSize: (size: ThemeSize | number, unit?: "pt" | "px") => {
    if (Platform.OS === "web") {
      if (typeof size === "number") {
        return `${size}${unit ?? "pt"}`
      } else {
        return unit ? `${fontSizes[unit][size]}${unit}` : relativeFontSizes[size]
      }
    } else {
      return `${typeof size === "number" ? size : fontSizes[unit ?? "px"][size]}${unit ?? "px"}`
    }
  },
  iconSizes,
  layers,
  mediaBreaks,
  name: "light",
  spacing,
}

export const logoSize = 48

export default theme
