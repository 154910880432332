/* eslint-disable no-restricted-imports */
import React from "react"
import { ActivityIndicator, StyleSheet, View } from "react-native"

const localStyles = StyleSheet.create({
  activityIndicator: {
    marginVertical: 16,
  },
  container: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
})

/**
 * A full screen component that displays when the app is initializing. Used as a top-level Suspense.
 * NB: This component cannot use theme or translation, because it might be waiting for those to load.
 */
const CpAppLoading: React.FC = () => {
  return (
    <View style={localStyles.container}>
      {/* Since we might not have a theme, we can't use theme elements here */}
      <ActivityIndicator size="large" style={localStyles.activityIndicator} />
    </View>
  )
}

export default CpAppLoading
