import React, { createContext, useEffect, useReducer } from "react"

import {
  InactivitySteps,
  ModalSteps,
  PatientSessionActions,
} from "components/patientSession/PatientSession"
import useAppBackend from "hooks/useAppBackend"
import { QlPatientSessionResourceNodeQuery$data } from "query/__generated__/QlPatientSessionResourceNodeQuery.graphql"
import Log from "services/Log"
import { endActiveSegments } from "services/PatientSession"

// Tracks active browsing time to ensure an accurate session length
export interface ActiveSegmentObject {
  end?: number
  start: number
}

// Possible states of inactivity for tracking history - `CtPatientSession` handles the inactivity timer and its effects
interface SetInactivityOptions {
  inactivityStep: InactivitySteps
}

// Possible render states within the PatientSessionModal - `CtPatientSession` determines what is rendered under each step
interface SetModalOptions {
  modalStep: ModalSteps
}

// Data we want to store each item selected for bulk sending
interface PackageSessionResource {
  revisionId: string
  sectionHeader: string
  selected: boolean
}
export interface CommonTopicResource extends PackageSessionResource {
  commonTopicId: string
  type: "commonTopicsSection"
}
export interface TopicTreeResource extends PackageSessionResource {
  topicTreeId: string
  type: "topicSection"
}

export type PackageTemplate = null | {
  id: string
  name: string
}

// Data we want to store for each topic/resource viewed
export interface PatientSessionResource {
  revisionId: string
  time: number
}

export interface SelectedPatientSessionResource extends PatientSessionResource {
  isTopic: boolean
  selected: boolean
}

type UnwrapArray<T> = T extends ReadonlyArray<infer Q> ? Q : T extends Array<infer S> ? S : never
export type PatientSessionData = UnwrapArray<
  NonNullable<QlPatientSessionResourceNodeQuery$data["resourceNodesByRevision"]>["nodes"]
>

export interface PatientSessionResourceData {
  data: PatientSessionData
  time: number
}

export type SelectedPatientSessionResourceData = PatientSessionData & {
  isTopic: boolean
  selected: boolean
}

export type ViewSessionData =
  | undefined
  | {
      isPsp?: null | boolean
      organizationLogo?: null | string
      organizationName: string
    }
export type ViewSessionParams =
  | undefined
  | {
      isCcRecipient: boolean
      patientId: string
      patientSessionId: string
    }

export interface ResourceAnnotation {
  resourceNodeRevisionId: string
  s3Key: string
  selected: boolean
  url: string
}

export interface PatientSessionContextData {
  activeSegments: ReadonlyArray<ActiveSegmentObject>
  annotations: ResourceAnnotation[]
  currentStep: SetModalOptions["modalStep"]
  inactivityTimer: SetInactivityOptions["inactivityStep"]
  // Determines whether or not to provide the patient options for navigating within a topic (e.g. breadcrumbs, TOC, shortcuts) when viewing a resource
  isLockedResource: boolean
  // Determines if a Package Session is active
  isPackageSession: boolean
  // Determines if a Patient Session has been explcitly started
  isPatientSession: boolean
  // Determines if a PatientSession is being viewed in the public app
  isViewingSession: boolean
  // Set of resources to be included in a bulk send package
  packageResources: ReadonlyArray<CommonTopicResource | TopicTreeResource>
  // ID and name of the selected package
  packageTemplate: Readonly<PackageTemplate>
  // Subset of `viewedResources` unless it's a patient session, then it's a duplicate
  selectedResources: ReadonlyArray<SelectedPatientSessionResource>
  // Handling state here allows us to use PatientSessionButtons anywhere in the app without having to worry about the render cycle
  showSessionModal: boolean
  // Checks if the patient has viewed a training node resource in the current session
  trainingNodeViewed: boolean
  // Data required for cross-component rendering when viewing a PatientSession
  viewSessionData: ViewSessionData
  // Params extracted from the base entry URL when a patient first loads a PatientSessino for viewing
  viewSessionParams: ViewSessionParams
  viewedResources: ReadonlyArray<PatientSessionResource>
}

// Explicitly allowed actions to be passed to the reducer below
type PatientSessionActionTypes =
  | {
      payload: (
        | {
            ids: Array<{
              commonTopicId: string
              revisionId: string
            }>
            type: "commonTopicsSection"
          }
        | {
            ids: Array<{
              revisionId: string
            }>
            topicTreeId: string
            type: "topicSection"
          }
      ) & {
        sectionHeader: string
      }
      type: PatientSessionActions.AddToPackage
    }
  | {
      payload: ResourceAnnotation
      type: PatientSessionActions.AddAnnotation
    }
  | {
      payload: SetInactivityOptions
      type: PatientSessionActions.SetInactivity
    }
  | {
      payload: SetModalOptions
      type: PatientSessionActions.SetModal
    }
  | {
      payload: boolean
      type: PatientSessionActions.SetTrainingNodeViewed
    }
  | {
      payload: string
      type: PatientSessionActions.ClearAnnotation
    }
  | {
      payload: string
      type: PatientSessionActions.SetSelectedAnnotation
    }
  | {
      payload: {
        isLockedResource: boolean
      }
      type: PatientSessionActions.SetViewingLock
    }
  | {
      payload: {
        isTopic: boolean
        revisionId: string
        selected: boolean
      }
      type: PatientSessionActions.AddResource
    }
  | {
      payload: {
        isViewingSession: boolean
        viewSessionParams: ViewSessionParams
      }
      type: PatientSessionActions.SetViewingParams
    }
  | {
      payload: {
        modalStep?: ModalSteps
        packageResources: Array<CommonTopicResource | TopicTreeResource> | []
        packageTemplate?: PackageTemplate
      }
      type: PatientSessionActions.SetSelectedPackages
    }
  | {
      payload: {
        modalStep?: ModalSteps
        selectedResources: Array<SelectedPatientSessionResource> | []
      }
      type: PatientSessionActions.SetSelectedResources
    }
  | {
      payload: {
        selectedResources: Array<SelectedPatientSessionResource>
      }
      type: PatientSessionActions.StartSessionFromHistory
    }
  | {
      payload: {
        viewSessionData: ViewSessionData
      }
      type: PatientSessionActions.SetViewingData
    }
  | {
      payload: { id: string; name: string }
      type: PatientSessionActions.DeletePackage
    }
  | {
      type: PatientSessionActions.ResetPackage
    }
  | {
      type: PatientSessionActions.ResetSession
    }
  | {
      type: PatientSessionActions.SignIn
    }
  | {
      type: PatientSessionActions.SignOut
    }
  | {
      type: PatientSessionActions.StartPackage
    }
  | {
      type: PatientSessionActions.StartSession
    }
  | {
      type: PatientSessionActions.StopPackage
    }

export interface PatientSessionType {
  dispatchPatientSession: React.Dispatch<PatientSessionActionTypes>
  patientSessionData: PatientSessionContextData
}

const initialData: PatientSessionContextData = {
  activeSegments: Object.freeze([]),
  annotations: [],
  // Along with `showSessionModal`, this ensures the modal is closed upon initialization
  currentStep: ModalSteps.Close,
  // We don't need to worry about inactivity until a user has actually viewed something
  inactivityTimer: InactivitySteps.Paused,
  isLockedResource: false,
  isPackageSession: false,
  isPatientSession: false,
  isViewingSession: false,
  packageResources: Object.freeze([]),
  packageTemplate: null,
  selectedResources: Object.freeze([]),
  showSessionModal: false,
  trainingNodeViewed: false,
  viewSessionData: undefined,
  viewSessionParams: undefined,
  viewedResources: Object.freeze([]),
}

// InitialData specific to a Patient Information Package Session
const initialPackageData: Partial<PatientSessionContextData> = {
  // Along with `showSessionModal`, this ensures the modal is closed upon initialization
  currentStep: ModalSteps.Close,
  isPackageSession: false,
  packageResources: Object.freeze([]),
  packageTemplate: null,
  showSessionModal: false,
}

// InitialData specific to a Patient Education Session
const initialSessionData: Partial<PatientSessionContextData> = {
  activeSegments: Object.freeze([]),
  // Along with `showSessionModal`, this ensures the modal is closed upon initialization
  currentStep: ModalSteps.Close,
  // We don't need to worry about inactivity until a user has actually viewed something
  inactivityTimer: InactivitySteps.Paused,
  isLockedResource: false,
  isPatientSession: false,
  isViewingSession: false,
  selectedResources: Object.freeze([]),
  showSessionModal: false,
  viewSessionData: undefined,
  viewSessionParams: undefined,
  viewedResources: Object.freeze([]),
}

export const PatientSessionContext = createContext<undefined | PatientSessionType>(undefined)

// This reducer Type checks the action `type`, forcing us to only use actions that we have specified under `PatientSessionActionTypes`
// Important to `freeze()` activeSessions, selectedResources and viewedResources to ensure we get yelled at if we try to mutate the values directly.
const patientSessionReducer: React.Reducer<PatientSessionContextData, PatientSessionActionTypes> = (
  state,
  action,
): PatientSessionContextData => {
  switch (action.type) {
    // Adds an url with a jpeg of an annotation related to one resource node
    // If an annotation for that resource node already exists, it will be overwritten
    case PatientSessionActions.AddAnnotation:
      if (
        state.annotations.find(
          (annotation) =>
            annotation.resourceNodeRevisionId === action.payload.resourceNodeRevisionId,
        )
      ) {
        return {
          ...state,
          annotations: state.annotations.map((annotation) => {
            if (annotation.resourceNodeRevisionId === action.payload.resourceNodeRevisionId) {
              return action.payload
            }
            return annotation
          }),
        }
      }
      return {
        ...state,
        annotations: [...state.annotations, action.payload],
      }
    case PatientSessionActions.AddResource:
      // Checking for existing resources here because React Navigation likes to keep pages in the background.
      // This means that if left to the useEffects in ScTopic/CpResourceNode, they would fire after history is cleared resulting in resources being added when we don't want them to be.
      if (
        !state.viewedResources.find((resource) => resource.revisionId === action.payload.revisionId)
      ) {
        Log.info("CxPatientSession - Adding resource to history: ", {
          revisionId: action.payload.revisionId,
        })
        const currentTime = Date.now()
        const newViewedResource = {
          time: currentTime,
          ...action.payload,
        }
        return {
          ...state,
          // Ensuring we're starting the timer if we haven't already
          activeSegments: state.activeSegments.length
            ? state.activeSegments
            : Object.freeze([{ start: currentTime }]),
          inactivityTimer: InactivitySteps.Active,
          // Automatically add each new resource to the `selectedResources` array if this is an active Patient Session - otherwise this will be done when determining the retroactive starting point
          selectedResources: state.isPatientSession
            ? Object.freeze([...state.selectedResources, { ...newViewedResource }])
            : state.selectedResources,
          viewedResources: Object.freeze([...state.viewedResources, newViewedResource]),
        }
      } else {
        return state
      }
    case PatientSessionActions.AddToPackage:
      // While the type of resource added to a package may differ, there are really only two methods of adding/removing items: individually or as an entire section
      if (action.payload.ids.length === 1) {
        // Individual item
        // Each press of a single item will either add or remove it from the package
        const { ids, sectionHeader, type } = action.payload
        const currentPackages = [...state.packageResources]
        const itemIndex = currentPackages.findIndex(
          (resource) =>
            resource.revisionId === ids[0].revisionId && resource.sectionHeader === sectionHeader,
        )
        const isInPackage = itemIndex >= 0
        Log.info(
          `CxPatientSession - ${isInPackage ? "Removing" : "Adding"} item ${
            isInPackage ? "from" : "to"
          } package`,
          ids[0],
        )

        if (isInPackage) {
          currentPackages.splice(itemIndex, 1)
        } else {
          const baseResource = {
            revisionId: ids[0].revisionId,
            sectionHeader: action.payload?.sectionHeader,
            selected: true,
          }
          currentPackages.push(
            type === "topicSection"
              ? {
                  ...baseResource,
                  topicTreeId: action.payload.topicTreeId,
                  type,
                }
              : {
                  ...baseResource,
                  commonTopicId: ids[0].commonTopicId,
                  type,
                },
          )
        }
        return {
          ...state,
          packageResources: Object.freeze([...currentPackages]),
        }
      } else {
        // Entire section
        // More complex functionality than single items, since we need to check each item but act on the group in context
        const { ids, sectionHeader, type } = action.payload

        const currentPackages = [...state.packageResources]
        const selectedCount = currentPackages.filter((resource) => {
          const isInPackage = ids.some(({ revisionId }) => revisionId === resource.revisionId)
          return isInPackage && resource.sectionHeader === sectionHeader
        }).length
        let logAction = ""
        if (selectedCount === 0) {
          logAction = "Adding ids to package"
        } else if (ids.length === selectedCount) {
          logAction = "Removing ids from package"
        } else if (ids.length !== selectedCount && selectedCount !== 0) {
          logAction = "Updating package to contain ids."
        }

        Log.info(`CxPatientSession - Section containing ${ids.length} items selected.`, {
          ids,
          logAction,
        })

        ids.forEach((payloadIds) => {
          const { revisionId } = payloadIds
          if (selectedCount === ids.length) {
            // All section items exist in package - remove them all
            const itemIndex = currentPackages.findIndex(
              (resource) =>
                resource.revisionId === revisionId && resource.sectionHeader === sectionHeader,
            )
            currentPackages.splice(itemIndex, 1)
          } else {
            const baseResource = {
              revisionId,
              sectionHeader: action.payload?.sectionHeader,
              selected: true,
            }
            // May be that zero or all but one resource exists in the package, add any resources that are missing
            const itemIndex = currentPackages.findIndex(
              (resource) =>
                resource.revisionId === revisionId && resource.sectionHeader === sectionHeader,
            )
            if (itemIndex < 0) {
              const { commonTopicId } = payloadIds as {
                commonTopicId: string
                revisionId: string
              }
              currentPackages.push(
                type === "topicSection"
                  ? {
                      ...baseResource,
                      topicTreeId: action.payload.topicTreeId,
                      type,
                    }
                  : {
                      ...baseResource,
                      commonTopicId,
                      type,
                    },
              )
            }
          }
        })

        return {
          ...state,
          packageResources: Object.freeze([...currentPackages]),
        }
      }
    case PatientSessionActions.ClearAnnotation:
      Log.info("CxPatientSession - Clearing annotation")
      return {
        ...state,
        annotations: state.annotations.filter(
          (annotation) => annotation.resourceNodeRevisionId !== action.payload,
        ),
      }
    case PatientSessionActions.DeletePackage:
      Log.info("CxPatientSession - Marking package for deletion and updating modalStep")
      return {
        ...state,
        currentStep: ModalSteps.PackageDelete,
        packageTemplate: { ...action.payload },
      }
    case PatientSessionActions.ResetPackage:
      Log.info("CxPatientSession - Resetting package.")
      return { ...state, ...initialPackageData }
    case PatientSessionActions.ResetSession:
      Log.info("CxPatientSession - Resetting patient session.")
      return { ...state, ...initialSessionData }
    case PatientSessionActions.SetInactivity:
      if (action.payload.inactivityStep === InactivitySteps.Reset) {
        Log.info("CxPatientSession - Clearing history after continued inactivity.")
        // Clear current active segments, history and pause inactivity timer until more actions are taken
        return {
          ...state,
          activeSegments: Object.freeze([]),
          annotations: [],
          inactivityTimer: InactivitySteps.Paused,
          isPatientSession: false,
          selectedResources: Object.freeze([]),
          viewedResources: Object.freeze([]),
        }
      } else {
        Log.info(`CxPatientSession - Setting inactivity to: ${action.payload.inactivityStep}`)
        return { ...state, inactivityTimer: action.payload.inactivityStep }
      }
    case PatientSessionActions.SetModal:
      Log.info(`CxPatientSession - Setting session modal to: ${action.payload.modalStep}`)

      /**
       * PatientSession Modal conditions
       */
      if (action.payload.modalStep === ModalSteps.SessionStart) {
        // Initial modal screen -- the starting point for all interactions with the patient session modal, pauses timer
        return {
          ...state,
          activeSegments: endActiveSegments(state.activeSegments),
          currentStep: ModalSteps.SessionStart,
          // Since the review flow begins here, we want to pause the inactivity time to prevent distractions and potentially clearing a users history mid-review
          inactivityTimer: InactivitySteps.Paused,
          showSessionModal: true,
        }
      } else if (action.payload.modalStep === ModalSteps.SessionHistory) {
        // Sets modal screen to session HISTORY where a user can select their session start point
        return { ...state, currentStep: ModalSteps.SessionHistory, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.SessionStartFromHistory) {
        // Displays a modified History screen allowing the user to immediately return to their session after their selection
        return {
          ...state,
          activeSegments: endActiveSegments(state.activeSegments),
          currentStep: ModalSteps.SessionStartFromHistory,
          inactivityTimer: InactivitySteps.Paused,
          showSessionModal: true,
        }
      } else if (action.payload.modalStep === ModalSteps.SessionInfo) {
        // Sets modal screen to session INFO where a user can learn more about PatientSessions
        return { ...state, currentStep: ModalSteps.SessionInfo, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.SessionReview) {
        // Sets modal screen to session REVIEW to select the resources to send
        return { ...state, currentStep: ModalSteps.SessionReview, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.SessionSend) {
        // Sets modal screen to session SEND to dispatch the resources to a patient
        return { ...state, currentStep: ModalSteps.SessionSend, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.SessionSuccess) {
        // Sets modal screen to session SUCCESS to confirm a successful session completion
        return { ...state, currentStep: ModalSteps.SessionSuccess, showSessionModal: true }

        /**
         * PackageSession Modal conditions
         */
      } else if (action.payload.modalStep === ModalSteps.PackageStart) {
        // Initial modal screen - starting point for all interactions with the package session modal, pauses patient session timer
        return {
          ...state,
          activeSegments: endActiveSegments(state.activeSegments),
          currentStep: ModalSteps.PackageStart,
          inactivityTimer: InactivitySteps.Paused,
          showSessionModal: true,
        }
      } else if (action.payload.modalStep === ModalSteps.PackageReview) {
        // Sets modal screen to package REVIEW to select the resources to send
        return { ...state, currentStep: ModalSteps.PackageReview, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.PackageSaveTemplate) {
        // Sets modal screen to package SAVE TEMPLATE to save a new package template
        return { ...state, currentStep: ModalSteps.PackageSaveTemplate, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.PackageDelete) {
        // Sets modal screen to package DELETE TEMPLATE to delete an existing package template
        return { ...state, currentStep: ModalSteps.PackageDelete, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.PackageSelectTemplate) {
        // Sets modal screen to package SELECT TEMPLATE to view existing package templates
        return { ...state, currentStep: ModalSteps.PackageSelectTemplate, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.PackageSend) {
        // Sets modal screen to package SEND to dispatch the package to patients
        return { ...state, currentStep: ModalSteps.PackageSend, showSessionModal: true }
      } else if (action.payload.modalStep === ModalSteps.PackageSuccess) {
        // Sets modal screen to package SUCCESS to confirm a successful session completion
        return { ...state, currentStep: ModalSteps.PackageSuccess, showSessionModal: true }

        /**
         * Close Modal
         */
      } else if (action.payload.modalStep === ModalSteps.Close) {
        // Ensure modal isn't visible
        // Add another start time if they're resuming an active session (activeSegments)
        const activeSegments =
          state.activeSegments.length > 0
            ? Object.freeze([...state.activeSegments, { start: Date.now() }])
            : state.activeSegments
        // Resume timer if they had a history of activeSegments
        const inactivityTimer =
          state.activeSegments.length > 0 ? InactivitySteps.Active : InactivitySteps.Paused
        return {
          ...state,
          activeSegments,
          currentStep: ModalSteps.Close,
          inactivityTimer,
          showSessionModal: false,
        }
      } else {
        Log.warn(
          "CxPatientSession - Either a ModalStep was not provided as part of action payload, or the ModalStep has not been initialized.",
          {
            action,
            state,
          },
        )
        return { ...state }
      }
    case PatientSessionActions.SetSelectedAnnotation:
      return {
        ...state,
        annotations: state.annotations.map((annotation) => {
          if (annotation.resourceNodeRevisionId === action.payload) {
            return { ...annotation, selected: !annotation.selected }
          } else {
            return annotation
          }
        }),
      }
    case PatientSessionActions.SetSelectedPackages:
      Log.info(
        action.payload.modalStep
          ? `CxPatientSession - Setting selected resources for Package Session and transitioning to: ${action.payload.modalStep}`
          : "CxPatientSession - Setting selected resources for Package Session",
      )
      return {
        ...state,
        currentStep: action.payload.modalStep ? action.payload.modalStep : state.currentStep,
        packageResources: Object.freeze(action.payload.packageResources),
        packageTemplate: action.payload.packageTemplate
          ? Object.freeze(action.payload.packageTemplate)
          : state.packageTemplate,
      }
    case PatientSessionActions.SetSelectedResources:
      Log.info(
        action.payload.modalStep
          ? `CxPatientSession - Setting selected resources for Patient Session and transitioning to: ${action.payload.modalStep}`
          : "CxPatientSession - Setting selected resources for Patient Session",
      )
      return {
        ...state,
        currentStep: action.payload.modalStep ? action.payload.modalStep : state.currentStep,
        selectedResources: Object.freeze(action.payload.selectedResources),
      }
    case PatientSessionActions.SetTrainingNodeViewed:
      return {
        ...state,
        trainingNodeViewed: action.payload,
      }
    case PatientSessionActions.SignIn:
      Log.info("CxPatientSession - User signing in, initializing PatientSession")
      return { ...initialData }
    case PatientSessionActions.SetViewingData:
      // Strictly for use in the PublicApp when a patient has loaded a PatientSession for viewing
      if (action.payload.viewSessionData) {
        Log.info("CxPatientSession - PatientSession data is set")
        return {
          ...state,
          viewSessionData: { ...action.payload.viewSessionData },
        }
      } else {
        return { ...state }
      }
    case PatientSessionActions.SetViewingParams:
      // Strictly for use in the PublicApp when a patient has loaded a PatientSession for viewing
      if (action.payload.isViewingSession && action.payload.viewSessionParams) {
        Log.info("CxPatientSession - PatientSession is loaded for viewing")
        return {
          ...state,
          isViewingSession: action.payload.isViewingSession,
          viewSessionParams: {
            isCcRecipient: action.payload.viewSessionParams.isCcRecipient,
            patientId: action.payload.viewSessionParams.patientId,
            patientSessionId: action.payload.viewSessionParams.patientSessionId,
          },
        }
      } else {
        return { ...state }
      }
    case PatientSessionActions.SetViewingLock:
      // Strictly for use in the PublicApp when a user is navigating between the session and a resource/topic
      Log.info(
        `CxPatientSession - Patient is loading a ${
          action.payload.isLockedResource ? "resource" : "topic"
        } for viewing`,
      )
      return { ...state, isLockedResource: action.payload.isLockedResource }
    case PatientSessionActions.SignOut:
      // Ensures no remnants of the current session remain in state, just in case
      Log.info(
        "CxPatientSession - User signing out, clearing history and resetting PatientSession state",
      )
      return { ...initialData }
    case PatientSessionActions.StartPackage:
      Log.info("CxPatientSession - Package started")
      // Packages should be unable to start if a patientSession is in progress, but a retroactive session may be in the background that we want to maintain
      return {
        ...state,
        activeSegments: endActiveSegments(state.activeSegments),
        currentStep: ModalSteps.Close,
        inactivityTimer: InactivitySteps.Paused,
        isPackageSession: true,
        isPatientSession: false,
        packageTemplate: null,
        showSessionModal: false,
      }
    case PatientSessionActions.StartSession:
      // Starting a session resets the timer, and clears any recorded history, but we will still want to maintain packageSession state
      Log.info(
        "CxPatientSession - Session started, clearing history and resetting PatientSession state",
      )
      return {
        ...state,
        activeSegments: Object.freeze([{ start: Date.now() }]),
        annotations: [],
        currentStep: ModalSteps.Close,
        inactivityTimer: InactivitySteps.Active,
        isPackageSession: false,
        isPatientSession: true,
        selectedResources: Object.freeze([]),
        showSessionModal: false,
        viewedResources: Object.freeze([]),
      }
    case PatientSessionActions.StartSessionFromHistory:
      // Starting a session from a selected point in history.
      return {
        ...state,
        inactivityTimer: InactivitySteps.Active,
        isPatientSession: true,
        selectedResources: Object.freeze(action.payload.selectedResources),
        showSessionModal: false,
      }
    case PatientSessionActions.StopPackage:
      Log.info("CxPatientSession - Package building stopped")
      return {
        ...state,
        isPackageSession: false,
      }
    default:
      return state
  }
}

/**
 * Initializes the Patient Session context and wraps children with the context provider
 */
export const CxPatientSession: React.FC = (props) => {
  const [state, dispatch] = useReducer(patientSessionReducer, initialData)

  const { setSessionHeader } = useAppBackend()

  // When no longer viewing a patient session, clear the active patient session id
  useEffect(() => {
    if (!state.isViewingSession) {
      setSessionHeader("patientSessionId", undefined)
    }
  }, [setSessionHeader, state.isViewingSession])

  return (
    <PatientSessionContext.Provider
      value={{ dispatchPatientSession: dispatch, patientSessionData: state }}
    >
      {props.children}
    </PatientSessionContext.Provider>
  )
}
