import { AppParams, AppParamsContext } from "contexts/CxAppParams"
import { useContext } from "react"

/**
 * Fetcher the initial application params used to configure the integration experience
 */
const useAppParams = (): AppParams => {
  const appParams = useContext(AppParamsContext)
  if (!appParams) throw new Error("Must be used inside CxAppParams")
  return appParams
}

export default useAppParams
