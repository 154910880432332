import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconPain: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M162.1 252.4c16.1 18.8 32.2 37.6 48.6 56.7H69c1.7-3.3 3.3-6.4 5-9.6 2.5-4.6 5.2-9.1 7.6-13.7a4.3 4.3 0 014.3-2.6c19.9.1 39.8.1 59.7.1h3.8c-2.2-1.9-3.8-3.5-5.6-4.9-8.7-7.3-15.8-15.8-19.5-26.7-3.4-10.1-2.2-20.2 1.5-30.1 4.6-12 13-20.9 23.1-28.4 12.7-9.3 27-15.1 42.2-18.8 11.5-2.8 23.4-2.8 35.2-2.2a53.34 53.34 0 0121.7 5.7l7.1-8a45.11 45.11 0 0125.8-15.1c11.8-2.3 23.6 2 26.5 16.4 1.2 6.1-.5 11.7-2.4 17.2-3.2 9-9.2 16.2-16.5 22.1-8.9 7.2-19.3 11.3-31.1 9.6-3.2-.5-6.2-2.7-9.1-4.4-1.4-.8-2.3-2.3-3.8-3.4.6 18.3 6.1 35.3 13.7 51.7a174.26 174.26 0 0030.7 45.3h-3.1c-9.4 0-18.8 0-28.2-.1a5.18 5.18 0 01-3.3-1.4c-6.1-5.7-10.5-12.7-14.5-19.9-7.6-13.5-13.2-27.8-18.2-42.5-2-6-3.9-12-5.8-17.9-16.3.3-42 11-53.7 24.9z" />
      <Path d="M112.8 229.6c-19.8-3.8-39.5-7.6-59.3-11.5v-.6c18.5-5.3 37-10.7 56.2-16.2-19.4-12.6-38.3-24.8-57.5-37.3h44.4c-6.1-11-12.2-22.1-18.5-33.6 14.4 5.5 28.4 10.8 43 16.3-13.3-25.5-26.2-50.5-39.2-75.5a4.23 4.23 0 01.7-.5c23.1 18.8 46.1 37.7 69.4 56.7a6.78 6.78 0 00.4-1.7c-.5-6-1.1-12-1.6-17.9-.4-4.4-.7-8.9-1.2-13.3-.7-7.2-1.5-14.5-2.2-21.7-.4-4.6-.8-9.3-1.2-13.9-.2-2.6-.5-5.3-.7-7.9.2-.1.5-.2.7-.3 14.4 27.6 28.9 55.2 43.6 83.3 6-15.4 11.9-30.4 18.1-46.1 2.6 7.3 5.1 14 7.7 21.2 7.4-17 14.6-33.4 21.8-49.9.3.1.6.1.9.2-3.3 28.5-6.7 56.9-10 85.3l44-44c.2.2.5.4.7.6-8.6 21.4-17.2 42.9-25.8 64.1-6.8-1.4-13.1-3.4-19.6-3.9-7.1-.6-14.3-.1-21.4.4a144.87 144.87 0 00-16.1 2.2 121.77 121.77 0 00-32 11.2 97.89 97.89 0 00-31.3 24.3 60.84 60.84 0 00-14 30z" />
    </Svg>
  )
}

export default IconPain
