import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconFoot: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M135.76 89.42c4.9-1.4 8.2-2.3 13.1-3.7 1.7 8.2 3.8 15.5 4.6 23 3.5 34 5 67.9-4.4 101.3-6 21.3-15.8 40.4-35.4 52.6a70.94 70.94 0 01-15 7.1c-5.3 1.7-11.1 2.3-16.7 3.1s-11.8 1-13.3 8.1c-1.2 5.2 4.4 12.9 11.2 15a48.44 48.44 0 0015.2 1.9c15.4-.3 30.9-2.2 46.3-1.5 19.5.8 33.3-6.8 44.7-22.3 11.6-15.7 24.5-30.5 37.4-45.2 9.7-11 22.4-18.1 36.2-22.9A119.35 119.35 0 00274 200c17.1-8.5 22.3-20.5 14.4-38.1-4.8-10.7-11.9-20.4-18.4-30.3-11-16.7-23-32.9-33.4-50-5.5-8.9-8.7-19.2-13.2-29.6 5-2.3 8.6-4 11.8-5.5 1.6 1 2.9 1.3 3.2 2 5.9 12 11.2 24.3 17.8 36 5.8 10.3 12.7 20 19.5 29.6 6.6 9.2 14.1 17.8 20.5 27.2S307 161 307.06 173c.2 17.8-7.2 30.7-22.8 39.1-3.6 1.9-7.2 4.2-11.2 5.3-27.2 7.1-44.7 26.7-61.3 47.4-5.8 7.2-11.5 14.6-16.6 22.3-13.3 19.9-33.4 26.1-55.3 23.8a138.4 138.4 0 00-39.7 1.9c-10.2 1.9-19.4-.5-28.6-4.1-7.6-2.9-12.7-8.7-15.9-16-7.6-17.4 1.2-34.5 20.8-35.2 34.4-1.3 48.5-23.2 56.9-51.5 9.6-32.2 8.1-65.3 4.8-98.2-.7-5.68-1.5-11.28-2.4-18.38z" />
      <Path d="M113.86 232.72c-13.1 20.8-34.6 18.7-54.2 21.8 6.2-16.9 27.34-25.52 54.2-21.8z" />
    </Svg>
  )
}

export default IconFoot
