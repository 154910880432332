/**
 * @generated SignedSource<<9c974e27480fae5cbad9089a15cf2f9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlShortcuts_currentUserQuery$variables = {
  locale?: Locale | null;
};
export type QlShortcuts_currentUserQuery$data = {
  readonly currentUser: {
    readonly " $fragmentSpreads": FragmentRefs<"CpShortcutButton_userShortcut" | "CpShortcuts_userShortcuts">;
  } | null;
};
export type QlShortcuts_currentUserQuery = {
  response: QlShortcuts_currentUserQuery$data;
  variables: QlShortcuts_currentUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v6 = {
  "alias": null,
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rootResourceNodeRevisionId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QlShortcuts_currentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CpShortcutButton_userShortcut"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CpShortcuts_userShortcuts"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QlShortcuts_currentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Membership",
            "kind": "LinkedField",
            "name": "currentMembership",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNodeShortcut",
                "kind": "LinkedField",
                "name": "personalShortcuts",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceNode",
                    "kind": "LinkedField",
                    "name": "resourceNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v1/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ResourceNodeShortcut",
                "kind": "LinkedField",
                "name": "organizationShortcuts",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v1/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceNode",
                    "kind": "LinkedField",
                    "name": "resourceNode",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5059df181e6c62f156b9a327b65c411d",
    "id": null,
    "metadata": {},
    "name": "QlShortcuts_currentUserQuery",
    "operationKind": "query",
    "text": "query QlShortcuts_currentUserQuery(\n  $locale: Locale\n) {\n  currentUser {\n    ...CpShortcutButton_userShortcut\n    ...CpShortcuts_userShortcuts\n    id\n  }\n}\n\nfragment CpOrganizationShortcuts_data on Membership {\n  organizationShortcuts {\n    _id\n    id\n    icon(locale: $locale)\n    names(locale: $locale)\n    resourceNode {\n      revisionId\n      type\n      id\n    }\n    rootResourceNodeRevisionId\n  }\n}\n\nfragment CpPersonalShortcuts_data on Membership {\n  personalShortcuts {\n    _id\n    id\n    icon(locale: $locale)\n    names(locale: $locale)\n    resourceNode {\n      revisionId\n      type\n      id\n    }\n    rootResourceNodeRevisionId\n  }\n}\n\nfragment CpShortcutButton_userShortcut on User {\n  currentMembership {\n    id\n    _id\n    personalShortcuts {\n      id\n      resourceNode {\n        revisionId\n        id\n      }\n    }\n  }\n}\n\nfragment CpShortcuts_userShortcuts on User {\n  _id\n  currentMembership {\n    ...CpPersonalShortcuts_data\n    ...CpOrganizationShortcuts_data\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4052a4955367e9b19de639fb792c0f6f";

export default node;
