import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - keyboard_arrow_down
const KeyboardArrowDown: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M9.875 11.438l6.125 6.125 6.125-6.125 1.875 1.875-8 8-8-8z" />
    </Svg>
  )
}

export default KeyboardArrowDown
