/**
 * @generated SignedSource<<3d8ab32360a1b8e24b0da951bb77f2cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type QlCountryRegionsQuery$variables = {
  locale: Locale;
  region: string;
};
export type QlCountryRegionsQuery$data = {
  readonly countryRegions: ReadonlyArray<{
    readonly name: string;
    readonly value: string;
  }>;
};
export type QlCountryRegionsQuery = {
  response: QlCountryRegionsQuery$data;
  variables: QlCountryRegionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locale"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "region"
},
v2 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  },
  {
    "kind": "Variable",
    "name": "region",
    "variableName": "region"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QlCountryRegionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CountryRegion",
        "kind": "LinkedField",
        "name": "countryRegions",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QlCountryRegionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CountryRegion",
        "kind": "LinkedField",
        "name": "countryRegions",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02d6f6b3f065d24d22b1fca4433ab072",
    "id": null,
    "metadata": {},
    "name": "QlCountryRegionsQuery",
    "operationKind": "query",
    "text": "query QlCountryRegionsQuery(\n  $region: String!\n  $locale: Locale!\n) {\n  countryRegions(region: $region, locale: $locale) {\n    name\n    value\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9ab252fd019e8c19360354a02589d0bd";

export default node;
