import { isInEnum } from "helpers/isInEnum"
import { Platform } from "react-native"

import { AppName } from "services/Constants"

interface ConfigType {
  APP_NAME: AppName
  APP_URL_PREFIX: string
  BUILD_YEAR: string
  BUNDLE_IDENTIFIER: string
  CODE_PUSH_DEPLOYMENT_KEY?: string
  COMMIT_HASH?: string
  DEPLOYMENT_NAME: string
  GA_TRACKING_ID: string
  IMD_WEB_SERVICE_BASE_URL: string
  IS_DEV_ENV: boolean
  IS_TEST: boolean
  NODE_ENV: string
  OAUTH_CLIENT_ID: string
  PROD: boolean
  SENTRY_DSN?: string
  AZURE_ASSETS_STORAGE_HOST?: string
}

const isTest = Boolean(process.env.IS_TEST)
const nodeEnv = process.env.NODE_ENV || "development"
const isDevEnv = nodeEnv === "development"
const prod = process.env.PROD

/**
 * A service that consolidates desired configuration values set at build time.
 *
 * Check the README.md for each app to see what environment variables it uses.
 *
 * These process.env vars are replaced at build time using babel-plugin-transform-inline-environment-variables
 * To add a new env var, add it here and whitelist it in each the babel config for each app.
 *
 * NB: Do not perform any operations with process.env members as this will cause some values to silently be dropped
 * from substitution. If processing is required, assign the original value to a const first as above.
 */
const Config: ConfigType = {
  APP_NAME: isInEnum(AppName, process.env.APP_NAME) ? process.env.APP_NAME : AppName.Unspecified,
  APP_URL_PREFIX: process.env.APP_URL_PREFIX || "",
  BUILD_YEAR: process.env.BUILD_YEAR || "",
  BUNDLE_IDENTIFIER: "com.imdhealth.imd-native",
  CODE_PUSH_DEPLOYMENT_KEY: Platform.select({
    android: process.env.CODE_PUSH_DEPLOYMENT_KEY_ANDROID,
    ios: process.env.CODE_PUSH_DEPLOYMENT_KEY_IOS,
  }),
  COMMIT_HASH: process.env.COMMIT_HASH,
  DEPLOYMENT_NAME: process.env.DEPLOYMENT_NAME || "development",
  GA_TRACKING_ID: process.env.GA_TRACKING_ID || "",
  IMD_WEB_SERVICE_BASE_URL: process.env.IMD_WEB_SERVICE_BASE_URL || "http://localhost:3000",
  IS_DEV_ENV: isDevEnv,
  IS_TEST: isTest,
  NODE_ENV: nodeEnv,
  OAUTH_CLIENT_ID: process.env.OAUTH_CLIENT_ID || "to be set by each app",
  PROD: !!prod,
  SENTRY_DSN: "",
  AZURE_ASSETS_STORAGE_HOST: process.env.AZURE_ASSETS_STORAGE_HOST || "",
}

export default Config
