import { defineMessages } from "react-intl";
export default defineMessages({
  disclaimer: {
    id: "KN4GE5",
    defaultMessage: "Disclaimer must be accepted to continue."
  },
  email: {
    id: "ubqhCe",
    defaultMessage: "Not a valid email"
  },
  emailList: {
    id: "I8ApVK",
    defaultMessage: "One or more email addresses are invalid."
  },
  insecure: {
    id: "FU82gZ",
    defaultMessage: "Insecure"
  },
  minLength: {
    id: "9Andau",
    defaultMessage: "Must be at least {minLength} characters"
  },
  passwordLowerCase: {
    id: "rPJ4Au",
    defaultMessage: "One lower case character"
  },
  passwordMinLength: {
    id: "DRjyMc",
    defaultMessage: "8 characters minimum"
  },
  passwordNumber: {
    id: "+NZzHE",
    defaultMessage: "One number"
  },
  passwordSpecialChar: {
    id: "pNLD5S",
    defaultMessage: "One special character e.g. ('{'[!@#$%^&*]'}')"
  },
  passwordUpperCase: {
    id: "83w2/e",
    defaultMessage: "One upper case character"
  },
  required: {
    id: "1Kxxor",
    defaultMessage: "Required"
  },
  secure: {
    id: "JJYuaa",
    defaultMessage: "Secure"
  }
});