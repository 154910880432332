import _styled from "styled-components";
import React from "react";
import { View } from "react-native";
import PrActivityIndicator from "components/primitives/PrActivityIndicator";
import { styles } from "services/Theme";
/**
 * A full screen loader that displays when the app has initialized, but is otherwise busy.
 */

const CpAppBusy = () => {
  return <_StyledView $_css={[styles.center, styles.marginDouble]}>
      <PrActivityIndicator />
    </_StyledView>;
};

export default CpAppBusy;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAppBusy___StyledView",
  componentId: "sc-rniekr-0"
})(["", ""], p => p.$_css);