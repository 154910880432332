var _QlTopic_commonRootResourceNodeQuery, _QlTopic_combo;

import CtGraphqlQueryWithoutIndicator from "components/containers/CtGraphqlQueryWithoutIndicator";
import React from "react";
import { graphql } from "react-relay";

/**
 * Used to fetch the root ResourceNode along with its children to render the TOC
 */
const commonRootResourceNodeQuery = _QlTopic_commonRootResourceNodeQuery !== void 0 ? _QlTopic_commonRootResourceNodeQuery : _QlTopic_commonRootResourceNodeQuery = require("./__generated__/QlTopic_commonRootResourceNodeQuery.graphql");
/**
 * A fragment combining various other fragments necessary to render a topic
 */

export const QlTopic_comboFragment = _QlTopic_combo !== void 0 ? _QlTopic_combo : _QlTopic_combo = require("./__generated__/QlTopic_combo.graphql");
/**
 * Loads data used to display a topic
 */

const QlTopic = ({
  children,
  variables
}) => {
  return <CtGraphqlQueryWithoutIndicator process={response => {
    return response?.resourceNodeByRevision || null;
  }} query={commonRootResourceNodeQuery} variables={variables}>
      {children}
    </CtGraphqlQueryWithoutIndicator>;
};

export default QlTopic;