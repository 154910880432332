import { Partner } from "components/psp/Psp"

/**
 * All routes in the app.
 */
enum Routes {
  app = "app",
  change_account_email = "change_account_email",
  change_clinic = "change_clinic",
  change_password = "change_password",
  change_region = "change_region",
  create_account = "create_account",
  forgot_password = "forgot_password",
  help = "help",
  home = "home",
  manage_account = "manage_account",
  not_found = "not_found",
  organizations = "organizations",
  organization = "organization",
  partner = "partner",
  policies = "policies",
  profile_details = "profile_details",
  profile_wizard = "profile_wizard",
  reset_password = "reset_password",
  signin = "signin",
  signout = "signout",
  support = "support",
  terms_of_use = "terms_of_use",
  topic = "topic",
  verify_email = "verify_email",
  view_session = "view_session",
}

/**
 * The params for each route.
 */
export type RouteParams = {
  [Routes.change_account_email]: undefined
  [Routes.change_clinic]: undefined
  [Routes.change_password]: undefined
  [Routes.change_region]: undefined
  [Routes.create_account]: {
    referralCode?: string
  }
  [Routes.forgot_password]: undefined
  [Routes.help]?: undefined
  [Routes.home]?: {
    tab?: string
  }
  [Routes.manage_account]: undefined
  [Routes.not_found]: undefined
  [Routes.organization]: {
    id: string
    tab?: string
  }
  [Routes.organizations]: undefined
  [Routes.partner]: {
    partner: Partner
  }
  [Routes.policies]: undefined
  [Routes.profile_details]: undefined
  [Routes.profile_wizard]: undefined
  [Routes.reset_password]: {
    passwordResetToken: string
  }
  [Routes.signin]: undefined
  [Routes.signout]: undefined
  [Routes.support]: undefined
  [Routes.terms_of_use]: undefined
  [Routes.topic]: {
    draw?: string
    resourceId?: string
    rootRevisionId: string
    selectedRevisionId?: string
    tab?: string
    viewResourceId?: string
  }
  [Routes.verify_email]: {
    confirmEmailToken: string
  }
  [Routes.view_session]: {
    isCcRecipient?: string
    patientId: string
    patientSessionId: string
    resourceNode?: string
  }
}

/**
 * The react-navigation configuration for all the routes.
 */
export const navigationConfig = {
  screens: {
    [Routes.change_account_email]: {
      path: "change_account_email",
    },
    [Routes.change_clinic]: {
      path: "change_clinic",
    },
    [Routes.change_password]: {
      path: "change_password",
    },
    [Routes.change_region]: {
      path: "change_region",
    },
    [Routes.create_account]: {
      path: "create_account",
    },
    [Routes.forgot_password]: {
      path: "forgot_password",
    },
    [Routes.help]: {
      path: "help",
    },
    [Routes.home]: {
      path: "",
    },
    [Routes.manage_account]: {
      path: "manage_account",
    },
    [Routes.not_found]: "*",
    [Routes.organization]: {
      path: "organization/:id?",
    },
    [Routes.organizations]: {
      path: "organizations",
    },
    [Routes.partner]: {
      path: "partner/:partner",
    },
    [Routes.policies]: {
      path: "policies",
    },
    [Routes.profile_details]: {
      path: "profile_details",
    },
    [Routes.profile_wizard]: {
      path: "profile_wizard",
    },
    [Routes.reset_password]: {
      path: "reset_password/:passwordResetToken",
    },
    [Routes.signin]: {
      path: "signin",
    },
    [Routes.signout]: {
      path: "signout",
    },
    [Routes.support]: {
      path: "support",
    },
    [Routes.terms_of_use]: {
      path: "terms_of_use",
    },
    [Routes.topic]: {
      path: "topic/:rootRevisionId/:selectedRevisionId?",
    },
    [Routes.verify_email]: {
      path: "verify_email/:confirmEmailToken",
    },
    [Routes.view_session]: {
      path: "view_session/:patientSessionId/:patientId/:resourceNode?",
    },
  },
}

export default Routes
