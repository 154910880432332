/**
 * @generated SignedSource<<cc5de41cd79af7d71d89cc92f575da57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PatientSessionCreateInput = {
  additionalEmails?: ReadonlyArray<string> | null;
  annotations?: ReadonlyArray<AnnotationInput> | null;
  duration: number;
  note?: string | null;
  patientEmail: string;
  patientId?: string | null;
  patientType?: string | null;
  resourceIds: ReadonlyArray<string>;
  sessionLocale?: string | null;
  started: string;
};
export type AnnotationInput = {
  resourceNodeRevisionId: string;
  s3Key: string;
  selected?: boolean | null;
  url: string;
};
export type CpPatientSessionSendMutation$variables = {
  input: PatientSessionCreateInput;
};
export type CpPatientSessionSendMutation$data = {
  readonly patientSessionCreate: {
    readonly patientSessionId: string;
  } | null;
};
export type CpPatientSessionSendMutation = {
  response: CpPatientSessionSendMutation$data;
  variables: CpPatientSessionSendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PatientSessionCreatePayload",
    "kind": "LinkedField",
    "name": "patientSessionCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "patientSessionId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpPatientSessionSendMutation",
    "selections": (v1/*: any*/),
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpPatientSessionSendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "16c11f78507f758226c302fd689862dc",
    "id": null,
    "metadata": {},
    "name": "CpPatientSessionSendMutation",
    "operationKind": "mutation",
    "text": "mutation CpPatientSessionSendMutation(\n  $input: PatientSessionCreateInput!\n) {\n  patientSessionCreate(input: $input) {\n    patientSessionId\n  }\n}\n"
  }
};
})();

(node as any).hash = "7c30c6bd6614dab05c03a7d49443a446";

export default node;
