/**
 * @generated SignedSource<<8758de327d140efffc2acf143534d2ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScreenLayoutHeights = "isShortScreen" | "isTallScreen" | "%future added value";
export type ScreenLayoutWidth = "isMediumOrSmallerScreen" | "isMediumScreen" | "isNarrowScreen" | "isWideScreen" | "%future added value";
export type SearchMethods = "all_tabs" | "filter_content" | "org_accessible" | "public_accessible" | "single_tab" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CpResourceNodeSearchTab_tabData$data = {
  readonly defaultQuery?: string | null;
  readonly letterPicker?: boolean | null;
  readonly search?: {
    readonly hideRules: ReadonlyArray<{
      readonly hide: boolean | null;
      readonly invertMatch: boolean | null;
      readonly rank: number | null;
      readonly routes: ReadonlyArray<string>;
      readonly routesInvert: boolean | null;
      readonly routesRank: number | null;
      readonly screenHeightIs: ReadonlyArray<ScreenLayoutHeights>;
      readonly screenWidthIs: ReadonlyArray<ScreenLayoutWidth>;
    }> | null;
    readonly orgScope: string | null;
    readonly placeholder: string | null;
    readonly searchMethod: SearchMethods;
  } | null;
  readonly tabParagraph?: string | null;
  readonly tabParagraphContainerStyles?: string | null;
  readonly tabParagraphStyles?: string | null;
  readonly " $fragmentType": "CpResourceNodeSearchTab_tabData";
};
export type CpResourceNodeSearchTab_tabData$key = {
  readonly " $data"?: CpResourceNodeSearchTab_tabData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpResourceNodeSearchTab_tabData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpResourceNodeSearchTab_tabData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "letterPicker",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "defaultQuery",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AppSearchConfig",
          "kind": "LinkedField",
          "name": "search",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchHideRule",
              "kind": "LinkedField",
              "name": "hideRules",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hide",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invertMatch",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rank",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "routes",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "routesInvert",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "routesRank",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "screenHeightIs",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "screenWidthIs",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "orgScope",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placeholder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "searchMethod",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabParagraph",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabParagraphContainerStyles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabParagraphStyles",
          "storageKey": null
        }
      ],
      "type": "ResourceNodeSearchTab",
      "abstractKey": null
    }
  ],
  "type": "AppTab",
  "abstractKey": "__isAppTab"
};

(node as any).hash = "a9a47e06432a1f8c4ebd481f59b7bd1b";

export default node;
