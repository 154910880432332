import ReactGA from "react-ga4"

import Config from "services/Config"

export enum GoogleAnalyticsDimension {
  licenseeId = "dimension1",
  subdivision = "dimension2",
  organizationId = "dimension3",
  queryString = "dimension4",
  practitionerType = "dimension6",
  practitionerSpecialties = "dimension7",
  resourceNodeRevisionId = "dimension8",
  nodeRevisionNumber = "dimension9",
  userType = "dimension10",
  publisherId = "dimension11",
  rootResourceNodeRevisionId = "dimension12",
  resourceId = "dimension13",
  patientSessionType = "dimension14",
  patientSessionId = "dimension15",
  topicNodeRevisionId = "dimension18",
  rootTopicNodeRevisionId = "dimension19",
  kioskId = "dimension20",
  sessionId = "dimension21",
  isProfessional = "dimension22",
}

type AnalyticsDimensionsType = {
  [key in GoogleAnalyticsDimension]?: string
}

export interface GoogleAnalyticsEventOptions {
  dimensions?: AnalyticsDimensionsType
  label?: string
}

/**
 * Tracks Google Analytics event.
 * New GA data model uses the event name to categorize events.
 * We are passing action and label (optional) as event params so we can view this data on our analytics dashboard.
 * We can also pass dimensions (optional) to track custom data across our events.
 */

export const filterDimensions = (
  dimensions: undefined | AnalyticsDimensionsType,
): AnalyticsDimensionsType => {
  return Object.entries(dimensions || {}).reduce((acc: AnalyticsDimensionsType, [key, value]) => {
    if (value) {
      acc[key as GoogleAnalyticsDimension] = value
    }
    return acc
  }, {})
}

export const trackGoogleAnalyticsEvent = (
  name: string,
  action: string,
  options: GoogleAnalyticsEventOptions = {},
): void => {
  // Guard if we're not in prod + have a GA tracking ID
  if (Config.IS_DEV_ENV || !Config.GA_TRACKING_ID) return

  // Recreate the dimensions object removing the entries with undefined values
  const filteredDimensions = filterDimensions(options.dimensions)

  ReactGA.event(name, {
    action,
    label: options.label,
    ...filteredDimensions,
  })
}
