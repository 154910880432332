import { useIntl } from "react-intl";
import { errorMessageDescriptor } from "services/Error";
import { isMessageBundle, isMessageDescriptor } from "services/Intl";
/**
 * Returns various types as a value that can be used with a Text component.
 */

function useAsText(value) {
  const {
    formatMessage
  } = useIntl();

  if (value instanceof Error) {
    value = errorMessageDescriptor(value); // eslint-disable-line no-param-reassign
  }

  if (isMessageDescriptor(value)) {
    return formatMessage(value);
  } else if (isMessageBundle(value)) {
    if (isMessageDescriptor(value.message)) {
      return formatMessage(value.message, value.values);
    } else {
      return value.message;
    }
  } else {
    return value;
  }
}

export default useAsText;