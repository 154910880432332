import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconNeedle: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M259.72 65.9a34.67 34.67 0 012.9 2.5c8.9 8.9 17.9 17.8 26.8 26.8 3.3 3.3 2.6 7.7-1.4 9.2a5.11 5.11 0 01-5.7-1.4c-2-2-3.9-4.1-5.7-5.9l-25.5 25.5c5.9 5.9 12.1 12 18.2 18.1 1.5 1.5 2.4 3.2 1.8 5.4a5.24 5.24 0 01-8.8 2.5c-2.9-2.8-5.7-5.8-8.7-8.8-.7.6-1.2 1.1-1.7 1.6q-51.75 51.45-103.4 103.2c-2.7 2.7-4.4 2.7-7.1-.1-2.1-2.1-4.1-4.3-6.7-6.9-2 2.2-3.7 4.2-5.6 6.1-2.7 2.8-5.5 5.6-8.3 8.4s-4.4 2.8-7.1.1c-.5-.5-1-.9-1.4-1.3q-21.6 21.75-43.2 43.2c-.2-.1-.4-.1-.6-.2 0-3-.1-6 .1-8.9 0-.6.7-1.2 1.2-1.7 11.5-11.6 23.1-23.1 34.6-34.7.9-.9 1.9-1.6 3.4-2.9a30.49 30.49 0 01-2.8-2c-2.1-2.1-2.1-3.8 0-5.9 4.8-4.8 9.7-9.6 14.7-14.6-2.4-2.4-4.7-4.6-7-6.9s-2.3-4 0-6.3l103.2-103.2a13.59 13.59 0 012.8-1.8c-3.4-3.3-6.1-5.9-8.8-8.5s-3-5.9-.7-8.2 5.3-2 8.2.8c6 6 11.9 12 17.7 17.8 8.5-8.5 16.8-16.9 25.6-25.6-1.5-1.4-3.5-3.2-5.4-5-3.6-3.5-3-7.1 1.4-9.4.3-.1.4-.5.7-.8a9.34 9.34 0 012.3-.2zm-33.9 46.6l-55.1 55.1 19.7 19.7 55.1-55.1z" />
      <Path d="M189.52 159.1c2.8 0 9.4 6.6 9.1 8.6a4.31 4.31 0 01-1.9 2.9 3.92 3.92 0 01-3.5-.2 33.51 33.51 0 01-5.9-6 3.9 3.9 0 01-.1-3.5c.5-.8 1.78-1.4 2.3-1.8zM229.42 137.7c-.7.8-1.3 2.1-2.3 2.6a4.05 4.05 0 01-3.4-.3 43 43 0 01-5.7-5.6 3.26 3.26 0 01.1-4.5c1.2-1.4 3-1.8 4.4-.6a58.55 58.55 0 016.1 6.1c.38.4.38 1.2.8 2.3zM211.22 155.9c-2.9-.1-9.3-6.5-9-8.7a4.31 4.31 0 011.9-2.9 3.43 3.43 0 013.4.3 35.36 35.36 0 015.8 5.8 4.31 4.31 0 01.2 3.7c-.4.9-1.8 1.4-2.3 1.8z" />
    </Svg>
  )
}

export default IconNeedle
