import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import PrProgressTracker from "components/primitives/PrProgressTracker";
import PrText from "components/primitives/PrText";
import CxPresentationSupport from "contexts/CxPresentationSupport";
import { css } from "services/Theme";
const localStyles = {
  completionText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    /* This bottom margin accommodates for the progress indicator "ball" to make sure it doesn't overlap other components if not provided external styling */
    margin-bottom: ${({
    theme
  }) => theme.spacing.half}px;
  `
};

/**
 * Renders a progress bar depicting the progress of a presentation/training topic.
 * Relies on CxPresentationSupport being available.
 */
const CpProgressTracker = ({
  accessibilityLabel,
  style
}) => {
  const {
    presentationCompletion,
    presentationIds
  } = useContext(CxPresentationSupport); // We only want to display the tracker if CxPresentationSupport exists, otherwise we can assume that we're not in a presentation/training node

  if (presentationIds?.length === 0 || Object.keys(presentationCompletion)?.length === 0) {
    return null;
  }

  const completedSteps = Object.values(presentationCompletion).filter(Boolean).length;
  const totalSteps = presentationIds.length;
  const progress = Math.floor(completedSteps / totalSteps * 100);
  return <_StyledView style={style} testID="CpProgressTracker" $_css={localStyles.container}>
      <_StyledPrText children={<FormattedMessage id="bdfUzC" defaultMessage="{progress}% Complete ({completedSteps} of {totalSteps})" values={{
      progress,
      completedSteps,
      totalSteps
    }} />} $_css2={localStyles.completionText} />

      <PrProgressTracker accessibilityLabel={accessibilityLabel} accessibilityValue={{
      min: 1,
      max: totalSteps,
      now: completedSteps
    }} completedSteps={completedSteps} totalSteps={totalSteps} />
    </_StyledView>;
};

export default CpProgressTracker;

var _StyledView = _styled(View).withConfig({
  displayName: "CpProgressTracker___StyledView",
  componentId: "sc-1u8xv10-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpProgressTracker___StyledPrText",
  componentId: "sc-1u8xv10-1"
})(["", ""], p => p.$_css2);