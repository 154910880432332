import _styled from "styled-components";

var _CpResourceNodeTab_tabData;

import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import CpAppLoading from "components/app/CpAppLoading";
import CpGrid from "components/common/CpGrid";
import CpResourceTreeSection from "components/home/tabs/CpResourceTreeSection";
import useScreenLayout from "hooks/useScreenLayout";
import QlResourceTreeSection from "query/QlResourceTreeSection";
import { useBackendSupportedLocale } from "services/Intl";
import { css } from "services/Theme";
const tabDataFragment = _CpResourceNodeTab_tabData !== void 0 ? _CpResourceNodeTab_tabData : _CpResourceNodeTab_tabData = require("./__generated__/CpResourceNodeTab_tabData.graphql");
const localStyles = {
  container: css`
    align-items: center;
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    padding: ${({
    theme
  }) => theme.spacing.double}px;
  `
};

/**
 * Renders a home screen tab that shows the contents of an entire resource tree
 */
const CpResourceNodeTab = ({
  tab
}) => {
  const {
    contentMinHeight
  } = useScreenLayout();
  const locale = useBackendSupportedLocale();
  const tabData = useFragment(tabDataFragment, tab);
  const resourceTreeId = tabData?.resourceNode?.revisionId ?? "";
  const queryVariables = useMemo(() => ({
    locale,
    resourceTreeId,
    revisionId: resourceTreeId
  }), [locale, resourceTreeId]);
  return <>
      {tabData?.resourceNode?.revisionId ? <_StyledCpGrid itemWidth={tabData.linkStyle === "Card" ? "small" : undefined} $_css={[localStyles.container, css`
              min-height: ${contentMinHeight}px;
            `]}>
          <QlResourceTreeSection variables={queryVariables}>
            {response => {
          if (response) {
            return <CpResourceTreeSection linkStyle={tabData.linkStyle} renderWithoutHeaders={tabData?.renderWithoutHeaders ?? false} resourceNodeKey={response.resourceNodeByRevision} />;
          } else {
            return null;
          }

          return <CpAppLoading />;
        }}
          </QlResourceTreeSection>
        </_StyledCpGrid> : null}
    </>;
};

export default CpResourceNodeTab;

var _StyledCpGrid = _styled(CpGrid).withConfig({
  displayName: "CpResourceNodeTab___StyledCpGrid",
  componentId: "sc-9vcqbo-0"
})(["", ""], p => p.$_css);