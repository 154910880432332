import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconKnee: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M160.92 333.13c0-20 .4-39.2-.2-58.4a147.91 147.91 0 00-3.8-30.4 48.59 48.59 0 00-9.4-18c-8.3-10.1-7.4-21.2 4.4-26 23.6-9.7 46.7-5.5 68.6 2.3 1.1-16.7 2.2-35.3 3.6-54 .6-8.8 7.9-6.9 11-4.2 5 4.4 11 10.7 11.5 16.6 1.5 16.3-1.2 32.6-9.3 47.3-2.8 5.2-6.5 9.4-12.4 2.3-18.3 38.9-26.7 79.6-30.1 122.4-10.9.1-21.9.1-33.9.1z" />
      <Path d="M138.62 26.63c7.5 11.2 14.1 22.2 21.8 32.3 12.7 16.5 25.3 33.1 39.4 48.4 15.2 16.4 20.1 35.8 19.8 57-.1 3.6-3.6 8.4-7 10.6a172.8 172.8 0 01-26.2 13.9c-14.7 6.1-35.3-7.8-35-23.7a139.83 139.83 0 014-30.3c2.2-9.2.7-17-4-24.7-12-20-23.6-40.3-36.1-60-3.9-6.1-2.9-8.9 2.9-11.9 6.78-3.6 13.3-7.6 20.4-11.6zM122.62 332.33c.7-35.8 1.5-71.7 19-104.9 8.1 4.5 14.6 20.5 12.4 27.5-7.5 23.7-14.8 47.5-22.1 71.3-1.2 4.1-1.5 9.4-9.3 6.1z" />
    </Svg>
  )
}

export default IconKnee
