import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconCancer: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M135.4 55.49c23.2 96.1 71.4 174.4 153.3 233.4a73.55 73.55 0 00-7.4 5.1c-11.2 9.6-22.6 19-33.4 29-4.4 4-7.7 4.1-11.4-.2-15.4-17.9-31.8-35.2-46-54-25.4-33.8-47.6-69.7-62-109.8-6.5-18-10.9-36.7-7-55.6 3.3-16.1 9.1-31.5 13.9-47.9zM71.3 298.29c25.9-29.5 51.4-58.6 77-87.9.1.2 1.1 1.2 1.8 2.3 7.5 12 14.8 24 22.4 35.9 2.1 3.2 2.6 5.7.3 8.9-13.8 18.7-27.2 37.6-41.3 56.1-6.4 8.4-7 8-16.7 3.7-13.3-5.8-26.7-11.5-40-17.3-1.5-.6-2.9-1.4-3.5-1.7z" />
      <Path d="M217.1 52c16.6 37.4 18.5 75.6 2.1 114.2-4.8 11.2-9.3 22.6-14.6 35.4-2.6-3.5-4.4-5.6-5.8-7.9-6-9.5-11.7-19.1-17.8-28.5-2.3-3.5-2.3-6.4-.3-10.2 14.3-26.8 26.4-54.5 33-84.3 1.4-6.41 2.3-12.81 3.4-18.7z" />
      <Path d="M204.9 87.69c-19.4-16-38.2-12.9-56.8 2.4-4.7-15-9-28.9-7-43.7.3-2.6 2.9-5.9 5.3-7.1 5.2-2.5 10.9-5.1 16.4-5.2a254.1 254.1 0 0139 1.8c9.7 1.3 12.2 5 11.2 14.6-1.2 12.2-3.7 24.4-5.6 36.6a18.29 18.29 0 00-2.5.6z" />
    </Svg>
  )
}

export default IconCancer
