import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconNose: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M241.31 296c16 3.9 29.7-3.9 34-20.8 5-19.6-5.1-39.1-22.4-42.7-5.1-1.1-10.5-1.1-15.7-1.4-7.6-.5-14.1-3-14.1-11.6-.1-8.4 6-11.9 13.7-12.2 20-.9 37.7 4.4 50.8 20.5 19.3 23.6 15.7 61.6-7.2 81.8-14.1 12.4-30.8 13.8-47.7 9.9a232.27 232.27 0 01-44.6-15.2c-9.2-4.2-15.8-5.2-24.2 1.2-12.3 9.3-26 15.7-42.2 15.6-32-.3-57.6-22.9-61.2-54.8a91.21 91.21 0 0112.6-57.8c23.4-39.5 47.4-78.6 71.3-117.7 8-13.1 16.4-25.8 24.7-38.7 1.4-2.2 2.7-4.5 4.3-6.6 5.2-6.8 11.7-9.4 19.1-4.1 6.6 4.7 7.1 11.4 2.9 18.2-8.1 13-16.4 25.8-24.4 38.9-24.1 39.1-48.6 77.9-71.7 117.6-6.4 11-10 24.4-12.2 37.1-2.5 14.2 1.9 27.7 15.1 35.8s27.3 7.1 40.6-.7c10.5-6.1 20.5-13 31-19 19.5-10.9 40.2-13 61-4.9 6.2 2.4 13.9 7.9 15.4 13.5 1 4.4-5.5 11.3-8.9 18.1z" />
    </Svg>
  )
}

export default IconNose
