import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconPhone: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path
        d="M260.28 283.45V76.62A24.48 24.48 0 00236.08 52h-111.6a24.47 24.47 0 00-24.2 24.59v206.86a24.45 24.45 0 0024.2 24.55h111.6a24.45 24.45 0 0024.2-24.58zm-92.67-216.4a2 2 0 010-4h25.33a2 2 0 010 4zm76.67 192V86a12 12 0 00-11.83-12H128.1a12 12 0 00-11.82 12v173a12 12 0 0011.82 12h104.35a12 12 0 0011.83-12zm-76.67 36c-8.28 0-8.28-13.06 0-13.06h25.33c8.29 0 8.29 13.06 0 13.06z"
        fillRule="evenodd"
      />
      <Path
        d="M165.28 93h30v30h30v30h-30v30h-30v-30h-30v-30h30V93zm-30 146h90v8h-90v-8zm0-18h90v8h-90v-8zm0-18h90v8h-90v-8z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconPhone
