import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Top: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M30.0131268,22.2879968 C30.7068058,21.1522803 33.2734181,21.1522803 33.9884093,22.2879968 L33.9884093,22.2879968 L49.8444587,48.3944868 C50.4824167,49.700144 50.9386859,51.1568011 49.8444587,52.2100576 C48.7461332,53.2633141 47.3999306,52.8503011 45.8732744,52.2100576 L45.8732744,52.2100576 L32,45.8897534 L18.1282617,52.2100576 C16.5656527,52.8525105 15.2554029,53.2633141 14.1570774,52.2100576 C13.058752,51.1568011 13.4513326,49.4789693 14.1570774,48.3944868 L14.1570774,48.3944868 Z M53.398503,11.0009341 C54.8072788,10.9639313 56,12.0295236 56,13.6263446 C56,15.2231656 54.7237652,16.2438898 53.398503,16.2438898 L10.330296,16.2438898 C9.23821811,16.2657124 8,15.3014507 8,13.6263446 C8,11.9512385 9.20635734,10.9639313 10.330296,11.0359835 L53.398503,11.0009341 Z" />
    </Svg>
  )
}

export default Top
