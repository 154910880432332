import { StatusBarStyle, ViewStyle } from "react-native"
import * as StyledComponents from "styled-components/native"

import { ThemeColors_configFragment$data } from "services/Theme/__generated__/ThemeColors_configFragment.graphql"
import rnStyles from "services/Theme/rnStyles"
import styles from "services/Theme/styles"

// Colors

// TODO: Add generic base colors

export type BrandColors = ThemeColors_configFragment$data["brandColors"]
export type BrandColorName = keyof BrandColors

export type ButtonColors = ThemeColors_configFragment$data["buttonColors"]
export type ButtonColorName = keyof ButtonColors

export type FormColors = ThemeColors_configFragment$data["formColors"]
export type FormColorName = keyof FormColors

export type TextColors = ThemeColors_configFragment$data["textColors"]
export type TextColorName = keyof TextColors

// Types

export type BorderRadiusName = "default" | "sharp" | "soft" | "switch"

export type BorderRadii = { [key in BorderRadiusName]: number }

export type ButtonHeights = { [key in ThemeSize | "tile"]: number }

export type ButtonSize = "default"

export type FontFamilyName = "button" | "default" | "heading"

export type FontFamilies = { [key in FontFamilyName]: string }

export type FontSizes = { [key in ThemeSize]: number }

export type FontStyle = "italic"

export type FontWeight = "bold"

export type IconSizes = { [key in ThemeSize]: number }

export type Layers = { [key in LayersNames]: number }

export type LayersNames =
  | "fifthLayer"
  | "firstLayer"
  | "fourthLayer"
  | "secondLayer"
  | "sixthLayer"
  | "thirdLayer"

export type MediaBreakTypes = "medium" | "narrow" | "wide"

export type MediaBreaks = { [key in MediaBreakTypes]: number }

export type HeightBreakTypes = "short" | "tall"

export type HeightBreaks = { [key in HeightBreakTypes]: number }

export type SpacingSize =
  | "double"
  | "half"
  | "quadruple"
  | "quarter"
  | "single"
  | "third"
  | "triple"

export type Spacing = { [key in SpacingSize]: number }

export type ThemeSize = "large" | "medium" | "small" | "xlarge" | "xsmall" | "xxlarge" | "xxsmall"

export type ThemeStyles = {
  flexAndCenter: ViewStyle
  flexSingle: ViewStyle
  paddingDouble: ViewStyle
  paddingHalf: ViewStyle
  paddingSingle: ViewStyle
  story: ViewStyle
}

export interface ThemeColors {
  readonly brandColors: BrandColors
  readonly buttonColors: ButtonColors
  readonly formColors: FormColors
  readonly headerBackgroundColor: string
  readonly textColors: TextColors
}

/**
 * An object that represents a specific theme. Used by ThemeProvider to establish the current theme.
 */
export interface ThemeTemplate extends ThemeColors {
  readonly additionalBodyStyles?: null | string
  readonly additionalFooterStyles?: null | string
  readonly additionalHeaderStyles?: null | string
  readonly additionalHomeContainerStyles?: null | string
  readonly additionalHomeHeaderStyles?: null | string
  readonly additionalHomeSearchBarStyles?: null | string
  readonly additionalHomeTaglineStyles?: null | string
  readonly borderRadii: BorderRadii
  readonly buttonHeights: ButtonHeights
  readonly fontFaceCss?: string
  readonly fontFamilies: FontFamilies
  /**
   * You should pretty much always pass size as a ThemeSize. The number value is only there as an escape hatch.
   *
   * You should rarely pass a unit. Its better to let the fontSize float based on the user's preferences. But if
   * we really are in a space pinch, you should prefer a resolution independent unit like pt.
   */
  readonly fontSize: (size: ThemeSize | number, unit?: "pt" | "px") => string
  readonly iconSizes: IconSizes
  readonly layers: Layers
  readonly mediaBreaks: MediaBreaks
  readonly name: string
  readonly navHeaderStatusBarStyle?: StatusBarStyle
  readonly spacing: Spacing
  readonly statusBarStyle?: StatusBarStyle
}

/**
 * Apply theme to styled components, and re-export them for use in the app
 */
const {
  ThemeConsumer,
  ThemeContext: StyledThemeContext,
  ThemeProvider: StyledThemeProvider,
  css,
  default: styled,
} = StyledComponents as unknown as StyledComponents.ReactNativeThemedStyledComponentsModule<ThemeTemplate>

export { StyledThemeContext, StyledThemeProvider, ThemeConsumer, css, rnStyles, styled, styles }
