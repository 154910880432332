/**
 * @generated SignedSource<<1cbbca823b6704182122a8f9b027a891>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CxImdSession_currentLicenseeFragment$data = {
  readonly _id: string;
  readonly ancestorIds: ReadonlyArray<string>;
  readonly countryCode: string;
  readonly id: string;
  readonly name: string;
  readonly postalCode: string | null;
  readonly settings: any | null;
  readonly subdivisionCode: string | null;
  readonly type: string;
  readonly " $fragmentType": "CxImdSession_currentLicenseeFragment";
};
export type CxImdSession_currentLicenseeFragment$key = {
  readonly " $data"?: CxImdSession_currentLicenseeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CxImdSession_currentLicenseeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CxImdSession_currentLicenseeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ancestorIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subdivisionCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3bf639983a0d814711f5157f97493341";

export default node;
