import { defineMessages } from "react-intl";
export default defineMessages({
  ca: {
    id: "6OqBFN",
    defaultMessage: "Canada"
  },
  latam: {
    id: "ayU8JG",
    defaultMessage: "Latin America"
  },
  local: {
    id: "QUyIOv",
    defaultMessage: "Development"
  },
  us: {
    id: "lJHFDd",
    defaultMessage: "United States"
  }
});