import * as React from "react"
import Svg, { Circle, Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconXray: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Circle cx={180.85} cy={70.3} r={34.88} />
      <Path d="M134 324.58l.33-39c0-1.82.18-3.63.28-5.7h91.59v44.69h-39.13v-18.85H174v18.86zM78.62 265.35v-118h202.76v118zm32.69-108.2v98.59H249.7v-98.59zM255.71 132.77H104.06a26.36 26.36 0 012-3.46c8.59-10.11 19.48-15.86 32.78-16q41.86-.33 83.71 0c12.47.1 22.73 5.42 30.8 14.92a22.55 22.55 0 011.93 2.61 6.94 6.94 0 01.43 1.93zM97.17 279.85h28.07c-.31 5.7 0 11.29-1.09 16.57-1.15 5.52-7.38 9.26-13.11 9.15s-11.52-4-12.68-9.71c-1.02-5-.82-10.26-1.19-16.01zM235.17 280h27.68c-.29 5.4 0 10.53-1 15.43-1.25 6.51-7.77 10.72-14.25 10.14a13.58 13.58 0 01-12.41-12.39c-.25-4.32-.02-8.63-.02-13.18z" />
      <Path d="M214.67 224.35c.48 7.39.52 13.73-2.15 19.64-2.59 5.72-8.9 6.33-12.75 1.31-2.06-2.69-3.42-5.91-5.4-8.67-1.7-2.38-3.83-4.44-5.76-6.66l-1.64.72v15.66h-13.83V230.6l-1.56-.85c-1.93 2-4.12 3.72-5.73 5.92-2.28 3.12-3.83 6.78-6.23 9.79-4.06 5.09-11 4-12.69-2.2-1.53-5.7-1.65-11.77-2.51-18.67h28.28v-9.17c-7.89 0-15.74.1-23.58-.08-1.79 0-3.56-1.08-5.34-1.66l.06-2.11c1.84-.55 3.68-1.52 5.54-1.58 6.2-.2 12.41-.06 18.61-.09 1.51 0 3-.18 4.83-.29v-8.7h-31.49c-1.07-4.72 1.3-5 3.78-5.07 7.45-.05 14.9 0 22.34 0 1.67 0 3.33-.14 5.34-.24v-8.5h-12.4c-4.34 0-8.7.13-13-.07-1.65-.07-3.27-.89-4.9-1.36l-.24-2.13c1.81-.64 3.61-1.8 5.44-1.84 8.2-.2 16.4-.09 25.05-.09v-8.17c-7.88-2-16.43 1.5-24-2.07l-.35-2c1.7-.69 3.37-1.92 5.09-2 6.33-.24 12.66-.09 19.12-.09.47-1.7.82-3 1.22-4.44h12.33c.48 1.34.93 2.6 1.38 3.88 7.79 2.18 16.35-1.73 23.88 2.19l.3 2c-1.73.65-3.44 1.79-5.2 1.86-6.3.23-12.62.09-19.24.09v8.71h11.58c4.81 0 9.63-.16 14.43.1 1.6.09 3.14 1.25 4.7 1.92l-.2 2c-1.9.48-3.8 1.33-5.71 1.38-6.51.18-13 .05-19.55.08-1.67 0-3.33.18-5.27.29v8.06c10.79 1.28 21.6-.73 32 1.1.35 4.68-2.18 4.67-4.54 4.68h-21.88c-1.67 0-3.34.14-5.42.23v8.45H202c3.1 0 6.22-.17 9.3.06a23.11 23.11 0 014.82 1.46l.17 2c-1.7.66-3.38 1.84-5.1 1.9-6.35.23-12.72.07-19.08.1-1.5 0-3 .16-4.85.26v8.71z" />
    </Svg>
  )
}

export default IconXray
