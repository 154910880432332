import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const ResourceImage: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M59.9026335,9.9999694 C62.6966911,9.9999694 64.0000306,10.5479427 64.0000306,14 L64.0000306,49 C64.0000306,51.5837404 63.1740923,53 59.9026335,53 L4.08220738,53 C1.00729482,53 1.42108547e-14,52.1410075 1.42108547e-14,49 L1.42108547e-14,14 C1.42108547e-14,11.0959634 1.0370797,9.9999694 4.08220738,9.9999694 L59.9026335,9.9999694 Z M59.0795074,12.9999996 L4.76056429,12.9999996 C3.12842328,12.9999996 3.00624165,13.4150966 2.9998153,14.6269334 L2.99998568,15.0462738 L3.0000409,15.088771 L3.0000409,15.088771 L3.00099829,17.1756513 L3.00102728,17.3098829 L3.00102728,17.3098829 L3.0010057,34.7123584 L3.00098716,34.9540622 L3.00098716,34.9540622 L3.00002037,45.3637729 C3.00001031,45.4613042 3.00000047,45.5567869 2.99999084,45.6501579 L2.99975253,48.2131565 C3.00034273,49.686918 3.31755761,49.9785597 4.60177497,49.9988136 L4.76056429,49.9999996 L59.4178109,49.9999996 C60.8198661,49.9999996 60.9878256,49.4533093 60.9993881,48.3589984 L61.0000978,48.2131565 L61.0000978,14.920905 C61.0000978,13.4814138 60.8613063,12.9999996 59.0795074,12.9999996 Z M21.4031461,24 L41.9890472,45 L8,45 L21.4031461,24 Z M37.6956236,26 L56,45 L45.3476823,45 L32.2592059,31.502691 L37.6956236,26 Z" />
    </Svg>
  )
}

export default ResourceImage
