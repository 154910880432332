import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Pending: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M32,4 L55,14.1818182 L55,29.4545455 C55,43.5818182 45.1866667,56.7927273 32,60 C18.8133333,56.7927273 9,43.5818182 9,29.4545455 L9,29.4545455 L9,14.1818182 L32,4 Z M34.2550336,43.4899329 L29.7449664,43.4899329 L29.7449664,48 L34.2550336,48 L34.2550336,43.4899329 Z M32,16 C29.4944072,16 27.3467562,16.8948546 25.557047,18.6845638 C23.7673378,20.4742729 22.8724832,22.6219239 22.8724832,25.1275168 L27.3825503,25.1275168 C27.3825503,23.9105145 27.8478747,22.836689 28.7785235,21.9060403 C29.7091723,20.9753915 30.7829978,20.5100671 32,20.5100671 C33.2170022,20.5100671 34.2908277,20.9753915 35.2214765,21.9060403 C36.1521253,22.836689 36.6174497,23.9105145 36.6174497,25.1275168 C36.6174497,26.344519 36.1521253,27.4183445 35.2214765,28.3489933 L32.4295302,31.2483221 C30.639821,33.1812081 29.7449664,35.3288591 29.7449664,37.6912752 L29.7449664,38.8724832 L34.2550336,38.8724832 C34.2550336,36.5100671 35.1498881,34.3624161 36.9395973,32.4295302 L38.9798658,30.2818792 C40.4116331,28.8501119 41.1275168,27.1319911 41.1275168,25.1275168 C41.1275168,22.6219239 40.2326622,20.4742729 38.442953,18.6845638 C36.6532438,16.8948546 34.5055928,16 32,16 Z" />
    </Svg>
  )
}

export default Pending
