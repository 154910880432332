import { createContext } from "react"

export interface CxContentLockedModalData {
  visible: boolean
}

export interface CxContentLockedModalType extends CxContentLockedModalData {
  openContentLockedModal: React.Dispatch<React.SetStateAction<void>>
}

export const initialCxContentLockedModalData: CxContentLockedModalData = {
  visible: false,
}

const CxContentLockedModal = createContext<CxContentLockedModalType>({
  ...initialCxContentLockedModalData,
  openContentLockedModal: () => undefined,
})

export default CxContentLockedModal
