import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Font Awesome - sign-out
const SignOut: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 28 32" width={height} {...otherProps}>
      <Path d="M11.429 25.714c0 0.5 0.232 1.714-0.571 1.714h-5.714c-2.839 0-5.143-2.304-5.143-5.143v-12.571c0-2.839 2.304-5.143 5.143-5.143h5.714c0.304 0 0.571 0.268 0.571 0.571 0 0.5 0.232 1.714-0.571 1.714h-5.714c-1.571 0-2.857 1.286-2.857 2.857v12.571c0 1.571 1.286 2.857 2.857 2.857h5.143c0.446 0 1.143-0.089 1.143 0.571zM28 16c0 0.304-0.125 0.589-0.339 0.804l-9.714 9.714c-0.214 0.214-0.5 0.339-0.804 0.339-0.625 0-1.143-0.518-1.143-1.143v-5.143h-8c-0.625 0-1.143-0.518-1.143-1.143v-6.857c0-0.625 0.518-1.143 1.143-1.143h8v-5.143c0-0.625 0.518-1.143 1.143-1.143 0.304 0 0.589 0.125 0.804 0.339l9.714 9.714c0.214 0.214 0.339 0.5 0.339 0.804z" />
    </Svg>
  )
}

export default SignOut
