import { useLinkTo } from "@react-navigation/native"
import React, { useEffect } from "react"

import useAppBackend from "hooks/useAppBackend"
import useImdSession from "hooks/useImdSession"
import useNavigationRedirect from "hooks/useNavigationRedirect"
import Routes from "navigators/Routes"
import Log from "services/Log"

/**
 * Responsible for doing the initial navigation after user auth status change in case we want a redirect.
 * Ideally react-navigation would hande this, but react-navigation is complete shit.
 */
const CpNavigationRedirect: React.FC<{ waitForAuth: boolean }> = ({ waitForAuth: waitForAuth }) => {
  const { isFetching, licensee, user } = useImdSession()
  const { setPendingUrl } = useNavigationRedirect()
  const {
    persistentHeaders: { membershipId },
  } = useAppBackend()
  const linkTo = useLinkTo()

  const userExists = Boolean(user)
  const membershipExists = Boolean(user?.currentMembership && membershipId)

  useEffect(() => {
    const tryRedirect = (): void => {
      setPendingUrl((currentPendingUrl) => {
        // If there's no pending URL, we don't need to do anything
        if (!currentPendingUrl) return currentPendingUrl

        // Don't redirect yet if we are fetching data
        if (isFetching || !licensee) return currentPendingUrl

        if (waitForAuth) {
          const isTopicUrl = currentPendingUrl?.includes("topic")

          // Checks if the user has a valid user and membership, otherwise they will be redirected to select a membership after login
          if (!userExists || (isTopicUrl && !membershipExists)) {
            return currentPendingUrl
          }
        }

        // Don't redirect if navigation is not ready

        Log.info({ navRedirect: currentPendingUrl })

        currentPendingUrl === Routes.home ? linkTo("/") : linkTo(`/${currentPendingUrl}`)
        return undefined
      })
    }

    const timeoutId = setTimeout(tryRedirect, 60)
    return () => clearTimeout(timeoutId)
  }, [isFetching, licensee, linkTo, membershipExists, setPendingUrl, userExists, waitForAuth])

  return null
}

export default CpNavigationRedirect
