/**
 * @generated SignedSource<<d2c83816525b24bc1b07912569971644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpResourceTreeSection_resourceNode$data = {
  readonly children: ReadonlyArray<{
    readonly children: ReadonlyArray<{
      readonly icon: string | null;
      readonly id: string;
      readonly isLeaf: boolean;
      readonly names: any;
      readonly rank: number;
      readonly resource: {
        readonly authors: string | null;
        readonly embedSnippet: string | null;
      } | null;
      readonly resourceNodeRevisionId: string | null;
      readonly revisionId: string;
    }>;
    readonly icon: string | null;
    readonly id: string;
    readonly isLeaf: boolean;
    readonly names: any;
    readonly rank: number;
    readonly resource: {
      readonly authors: string | null;
      readonly embedSnippet: string | null;
    } | null;
    readonly resourceNodeRevisionId: string | null;
    readonly revisionId: string;
  }>;
  readonly revisionId: string;
  readonly " $fragmentType": "CpResourceTreeSection_resourceNode";
};
export type CpResourceTreeSection_resourceNode$key = {
  readonly " $data"?: CpResourceTreeSection_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpResourceTreeSection_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v1 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLeaf",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rank",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Resource",
  "kind": "LinkedField",
  "name": "resource",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "embedSnippet",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpResourceTreeSection_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResourceNode",
          "kind": "LinkedField",
          "name": "children",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "storageKey": null
    },
    (v8/*: any*/)
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "8356427e398ed1fe8372bddcea874522";

export default node;
