import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback } from "react";
import { View } from "react-native";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import PrIcon, { IconName } from "../primitives/PrIcon";
import { css, styles } from "services/Theme";
import translations from "translations";
const localStyles = {
  disabled: css`
    color: ${({
    theme
  }) => theme.formColors.fieldBorderInactive};
  `,
  linkButton: css`
    ${styles.rowAndCenter}
  `
};

/**
 * Renders a pagination component
 */
const CpPrevNextPagination = ({
  onNextPageChange,
  onPrevPageChange,
  pageInfo
}) => {
  const {
    buttonColors
  } = useAppTheme();
  const handlePressNext = useCallback(() => {
    if (pageInfo?.hasNextPage) {
      onNextPageChange(pageInfo);
    }
  }, [pageInfo, onNextPageChange]);
  const handlePressPrev = useCallback(() => {
    if (pageInfo?.hasPreviousPage) {
      onPrevPageChange(pageInfo);
    }
  }, [pageInfo, onPrevPageChange]); // don't return the pagination component if there are no both previous and next pages

  if (!pageInfo?.hasNextPage && !pageInfo?.hasPreviousPage) return null;
  return <_StyledView $_css={styles.navHeaderStyles.row1Container}>
      <PrText>&nbsp;</PrText>

      {pageInfo?.hasPreviousPage ? <PrPressable disabled={!pageInfo?.hasPreviousPage} onPress={handlePressPrev}>
          <_StyledView2 $_css2={localStyles.linkButton}>
            <PrIcon fill={buttonColors.primary} icon={IconName.keyboardArrowLeft} />
            <PrText>{translations.labels.prevPage}</PrText>
          </_StyledView2>
        </PrPressable> : null}

      {pageInfo?.hasNextPage ? <PrPressable disabled={!pageInfo?.hasNextPage} onPress={handlePressNext}>
          <_StyledView3 $_css3={localStyles.linkButton}>
            <PrText>{translations.labels.nextPage}</PrText>
            <PrIcon fill={buttonColors.primary} icon={IconName.keyboardArrowRight} />
          </_StyledView3>
        </PrPressable> : null}

      <PrText>&nbsp;</PrText>
    </_StyledView>;
};

export default CpPrevNextPagination;

var _StyledView = _styled(View).withConfig({
  displayName: "CpPrevNextPagination___StyledView",
  componentId: "sc-1wqbyc8-0"
})(["", ""], p => p.$_css);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPrevNextPagination___StyledView2",
  componentId: "sc-1wqbyc8-1"
})(["", ""], p => p.$_css2);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPrevNextPagination___StyledView3",
  componentId: "sc-1wqbyc8-2"
})(["", ""], p => p.$_css3);