import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import CpAccountPersonalProfileWizard from "components/account/CpAccountPersonalProfileWizard";
import CpAccountPractitionerProfileWizardProfile from "components/account/CpAccountPractitionerProfileWizard";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CpBackgroundHeader from "components/common/CpBackgroundHeader";
import PrProgressTracker from "components/primitives/PrProgressTracker";
import PrText from "components/primitives/PrText";
import useImdSession from "hooks/useImdSession";
import useScreenLayout from "hooks/useScreenLayout";
import Routes from "navigators/Routes";
import { css, rnStyles, styles } from "services/Theme";
import translations from "translations";
const localTranslations = defineMessages({
  personal: {
    id: "711WBh",
    defaultMessage: "Personal"
  },
  practitioner: {
    id: "8F8dgn",
    defaultMessage: "Practitioner"
  },
  profile: {
    id: "MB+JVq",
    defaultMessage: "{profileType} Profile"
  }
});
const localStyles = {
  buttonText: css`
    font-size: ${({
    theme
  }) => theme.fontSize("medium")};
  `,
  container: css`
    padding-top: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  desktopPersonalText: css`
    align-items: flex-start;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  desktopPractitionerText: css`
    align-items: flex-end;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  desktopProfileContainer: css`
    padding-top: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  desktopProgressTracker: css`
    flex: 2;
  `,
  profileContainer: css`
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  `,
  profileText: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.button};
    font-size: ${({
    theme
  }) => theme.fontSize("large")};
    text-align: center;
  `,
  profileWizardContainer: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  progressTracker: css`
    padding-bottom: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  topContainer: css`
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
    padding-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `
};

/**
 * The profile wizard page
 */
const ScAccountWizard = ({
  route
}) => {
  const {
    isNarrowScreen
  } = useScreenLayout();
  const {
    navigate
  } = useNavigation();
  const {
    formatMessage
  } = useIntl();
  const {
    user
  } = useImdSession();
  const [wizardStep, setWizardStep] = useState(1);
  const handleNextWizardStep = useCallback(() => {
    setWizardStep(wizardStep + 1);
  }, [wizardStep]);
  const handleSkip = useCallback(() => {
    navigate(Routes.home);
  }, [navigate]); // Redirects user to the second wizard step if they already have a personal profile
  // or redirects to home if they already filled the signup profile wizard

  useEffect(() => {
    if (user?.personalProfile?.firstName && user?.practitionerProfile) {
      handleSkip();
    } else if (user?.personalProfile?.firstName) {
      setWizardStep(2);
    }
  }, [handleSkip, navigate, user, user?.personalProfile, user?.practitionerProfile]);
  return <_StyledSafeAreaView $_css={[styles.flexSingle, styles.screen]}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <ScrollView contentContainerStyle={rnStyles.flexSingle}>
        <CpBackgroundHeader childStyle={styles.flexSingle} header={formatMessage(localTranslations.profile, {
        profileType: wizardStep === 1 ? formatMessage(localTranslations.personal) : formatMessage(localTranslations.practitioner)
      })}>
          <_StyledView $_css2={[localStyles.profileWizardContainer, !isNarrowScreen ? styles.limitedLargeScreenWidth : undefined]}>
            <_StyledView2 $_css3={[localStyles.profileContainer, !isNarrowScreen ? localStyles.desktopProfileContainer : undefined]}>
              <_StyledView3 $_css4={[localStyles.topContainer, !isNarrowScreen ? localStyles.desktopPersonalText : undefined, !isNarrowScreen ? styles.flexSingle : undefined]}>
                <_StyledPrText2 children={formatMessage(localTranslations.profile, {
                profileType: <_StyledPrText numberOfLines={isNarrowScreen ? 2 : 1} children={formatMessage(localTranslations.personal)} $_css5={localStyles.profileText} />
              })} $_css6={localStyles.profileText} />
              </_StyledView3>
              {!isNarrowScreen ? <_StyledView4 $_css7={localStyles.desktopProgressTracker}>
                  <PrProgressTracker completedSteps={wizardStep} totalSteps={2} />
                </_StyledView4> : null}
              <_StyledView5 $_css8={[localStyles.topContainer, !isNarrowScreen ? localStyles.desktopPractitionerText : undefined, !isNarrowScreen ? styles.flexSingle : undefined]}>
                <_StyledPrText4 children={formatMessage(localTranslations.profile, {
                profileType: <_StyledPrText3 numberOfLines={isNarrowScreen ? 2 : 1} children={formatMessage(localTranslations.practitioner)} $_css9={localStyles.profileText} />
              })} $_css10={localStyles.profileText} />
              </_StyledView5>
            </_StyledView2>
            {isNarrowScreen ? <_StyledView6 $_css11={localStyles.progressTracker}>
                <PrProgressTracker completedSteps={wizardStep} totalSteps={2} />
              </_StyledView6> : null}
            <View>
              {wizardStep === 1 ? <CpAccountPersonalProfileWizard onSubmit={handleNextWizardStep} /> : <CpAccountPractitionerProfileWizardProfile />}
            </View>
          </_StyledView>
        </CpBackgroundHeader>
      </ScrollView>
    </_StyledSafeAreaView>;
};

export default ScAccountWizard;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScAccountWizard___StyledSafeAreaView",
  componentId: "sc-1qpfhn9-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "ScAccountWizard___StyledView",
  componentId: "sc-1qpfhn9-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "ScAccountWizard___StyledView2",
  componentId: "sc-1qpfhn9-2"
})(["", ""], p => p.$_css3);

var _StyledView3 = _styled(View).withConfig({
  displayName: "ScAccountWizard___StyledView3",
  componentId: "sc-1qpfhn9-3"
})(["", ""], p => p.$_css4);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "ScAccountWizard___StyledPrText",
  componentId: "sc-1qpfhn9-4"
})(["", ""], p => p.$_css5);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "ScAccountWizard___StyledPrText2",
  componentId: "sc-1qpfhn9-5"
})(["", ""], p => p.$_css6);

var _StyledView4 = _styled(View).withConfig({
  displayName: "ScAccountWizard___StyledView4",
  componentId: "sc-1qpfhn9-6"
})(["", ""], p => p.$_css7);

var _StyledView5 = _styled(View).withConfig({
  displayName: "ScAccountWizard___StyledView5",
  componentId: "sc-1qpfhn9-7"
})(["", ""], p => p.$_css8);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "ScAccountWizard___StyledPrText3",
  componentId: "sc-1qpfhn9-8"
})(["", ""], p => p.$_css9);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "ScAccountWizard___StyledPrText4",
  componentId: "sc-1qpfhn9-9"
})(["", ""], p => p.$_css10);

var _StyledView6 = _styled(View).withConfig({
  displayName: "ScAccountWizard___StyledView6",
  componentId: "sc-1qpfhn9-10"
})(["", ""], p => p.$_css11);