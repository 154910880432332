import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconFemaleReproductive: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M96.86 101.88c.7-2.7 1.3-5 2.1-8.1 2.5.6 5.1.7 7.3 1.7 7.5 3.5 15.1 7 22.3 11.2 34.5 20.5 68.8 20.5 103.3 0 6.9-4.1 14.4-7.5 21.7-11 2.3-1.1 4.9-1.4 7.6-2.2.7 2.1 1.7 3.8 1.6 5.3s-1.3 3.1-2 4.6c-.2.3-.7.4-1.1.6-17.8 8.6-26.3 23.2-29.3 42.3-3.3 21.1-9.4 41.5-21.7 59.4a214.68 214.68 0 01-15.1 19.1c-6.1 7-9.1 14.6-8 24 .7 5.9.3 11.9.5 17.8.1 3.9-1.8 5.3-5.7 5.3s-5.7-1.6-5.6-5.4c.1-8.5.6-17.1.2-25.6a20.93 20.93 0 00-4-10.7c-6.7-9.5-14.6-18.2-21-27.9-10-15-14.7-32.2-18.5-49.6-1.2-5.5-2.2-11.1-3.5-16.6-2.7-12-9.4-21.1-19.6-27.8-3.7-2-7.36-3.9-11.5-6.4zM298.36 178.18c3.1-9.2 2.2-17.8-4.6-26.5 3.3-.4 5.5-1.4 7.1-.7 12.3 4.9 19-6.2 18.2-18.2-.7-10.4-6.6-17.3-15.4-22.2-7.8-4.4-16.1-6.8-25.1-6.1-3.5.3-6-1-6.6-3.9a35.55 35.55 0 010-10.8c.1-.6 3.1-1 4.8-1.1 18.9-1.4 33.4 7 45.7 20.5 10.4 11.5 11.2 25.3 8.9 39.6-1.2 7.3-6.2 12.3-12 16.7-4.7 3.5-9 7.6-13.7 11.2-1.6 1.3-3.8 1.8-5.8 2.7q-.86-.75-1.5-1.2zM64.86 152.28c-1.7 4.7-3.9 8.7-4.5 12.8s.5 8.5.9 12.9c-3.1 1.7-4.9.2-6.9-1.8a98.14 98.14 0 00-12.1-10.1c-9.2-6.3-13-14.5-14.4-25.8-3.6-28.6 28.8-55.1 56.9-51.9 3.4.4 4.7 1.6 4.5 4.9-.1 1.3.1 2.6 0 4-.5 5.2-2.7 7.1-8.1 7a46.42 46.42 0 00-31.5 11.1 25.28 25.28 0 00-6.7 28.7c2.7 6.3 6.9 8.4 13.7 7.3 2.8-.38 5.9-2.8 8.2.9z" />
      <Path d="M116.46 130.48c4.4 8.4 4.5 8.5-2 12.4a23.69 23.69 0 00-10.5 13c-1.1 3.3-2 6.7-2.9 10.1-4 14.6-20.1 14.9-28.4 10.1-4.5-2.6-5.4-9.4-2.4-15.3 4.2-8.6 11.9-11.7 20.6-11.6 7.5.2 12.5-2.4 16.4-8.5 2.4-3.6 5.8-6.5 9.2-10.2zM244.16 130.68c3.4 3.9 7 7.4 9.8 11.3 3.5 4.8 7.7 7.4 13.8 7.1 5.7-.3 11.4.8 16.1 4.5a26 26 0 013.8 3.6c3.8 4.6 5.1 10 3 15.4-2 5.1-7.5 5.7-12.3 6.2-9.7 1-16.4-3.7-19.4-13.1-1-3.1-1.8-6.3-2.7-9.5a23.93 23.93 0 00-10.9-13.5c-6.1-3.68-6.1-4.4-1.2-12z" />
    </Svg>
  )
}

export default IconFemaleReproductive
