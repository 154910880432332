// For some reason this only works if I put it here. .d.ts files don't seem to work in this package.
/// <reference types="styled-components/cssprop" />
import { AppRegistry } from "react-native"

import ProviderApp from "ProviderApp"
import "react-notifications-component/dist/theme.css"
import "./index.css"

AppRegistry.registerComponent("App", () => ProviderApp)
AppRegistry.runApplication("App", {
  rootTag: document.getElementById("react-root"),
})
