import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Official Twitter svg
const Twitter: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M64,32 C64,49.68 49.68,64 32,64 C14.32,64 0,49.68 0,32 C0,14.32 14.32,0 32,0 C49.68,0 64,14.32 64,32 Z M26.144,48.88 C40.336,48.88 48.096,37.12 48.096,26.928 C48.096,26.592 48.096,26.256 48.08,25.936 C49.584,24.848 50.896,23.488 51.936,21.936 C50.56,22.544 49.072,22.96 47.504,23.152 C49.104,22.192 50.32,20.688 50.896,18.88 C49.408,19.76 47.76,20.4 46,20.752 C44.592,19.248 42.592,18.32 40.368,18.32 C36.112,18.32 32.656,21.776 32.656,26.032 C32.656,26.64 32.72,27.232 32.864,27.792 C26.448,27.472 20.768,24.4 16.96,19.728 C16.304,20.864 15.92,22.192 15.92,23.6 C15.92,26.272 17.28,28.64 19.36,30.016 C18.096,29.984 16.912,29.632 15.872,29.056 C15.872,29.088 15.872,29.12 15.872,29.152 C15.872,32.896 18.528,36 22.064,36.72 C21.424,36.896 20.736,36.992 20.032,36.992 C19.536,36.992 19.056,36.944 18.576,36.848 C19.552,39.92 22.4,42.144 25.776,42.208 C23.136,44.272 19.808,45.504 16.192,45.504 C15.568,45.504 14.96,45.472 14.352,45.392 C17.728,47.6 21.792,48.88 26.144,48.88" />
    </Svg>
  )
}

export default Twitter
