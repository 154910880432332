import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";

var _CpOrganizationItem_organization;

import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { View } from "react-native";
import { useFragment } from "react-relay/hooks";
import { graphql } from "relay-runtime";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import { css, styles } from "services/Theme";
const localStyles = {
  membershipAddress: css`
    color: ${({
    theme
  }) => theme.textColors.muted};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-left: ${({
    theme
  }) => theme.spacing.single}px;
    padding-bottom: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  membershipContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    border-color: ${({
    theme
  }) => theme.brandColors.primary};
    border-width: 1px;
    border-style: solid;
    flex-direction: row;
    /* Clipping bottom margin to thin out borders between membership options */
    margin-bottom: -1px;
    min-height: ${({
    theme
  }) => theme.buttonHeights.large}px;
  `,
  membershipInnerContainer: css`
    flex: 1;
    padding-horizontal: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  membershipInnerContainerSelected: css`
    /* Do we like having the selected membership highlighted like this? */
    background-color: ${({
    theme
  }) => theme.brandColors.selected};
    /* Add margin and replace left-padding to account for indicator bar */
    margin-left: ${({
    theme
  }) => theme.borderRadii.default}px;
    padding-left: ${({
    theme
  }) => theme.spacing.double - theme.borderRadii.default}px;
  `,
  membershipName: css`
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-top: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  membershipSelectedIndicator: css`
    width: ${({
    theme
  }) => theme.borderRadii.default}px;
    background-color: ${({
    theme
  }) => theme.brandColors.primary};
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  `,
  selectorContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
  `,
  selectorInnerContainerSelected: css`
    margin-left: -${({
    theme
  }) => theme.spacing.half}px;
  `,
  selectorInsert: css`
    background-color: ${({
    theme
  }) => theme.buttonColors.primary};
    height: 100%;
    width: ${({
    theme
  }) => theme.spacing.half}px;
  `,
  selectorLabel: css`
    ${styles.uppercase};
    font-size: ${({
    theme
  }) => theme.fontSize("small")};
    padding-bottom: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  selectorText: css`
    padding-left: ${({
    theme
  }) => theme.spacing.double}px;
  `,
  selectorVerticalBase: css`
    align-items: center;
    border: 1px solid ${({
    theme
  }) => theme.buttonColors.primary};
    background-color: ${({
    theme
  }) => theme.brandColors.surface};
    flex-direction: row;
    height: ${({
    theme
  }) => theme.buttonHeights.medium}px;
    margin-bottom: -1px;
    overflow: hidden;
    padding: 0px;
  `,
  selectorVerticalBottom: css`
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    margin-bottom: 0px;
  `,
  selectorVerticalTop: css`
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `
};
const organizationFragment = _CpOrganizationItem_organization !== void 0 ? _CpOrganizationItem_organization : _CpOrganizationItem_organization = require("./__generated__/CpOrganizationItem_organization.graphql");
const VirtualOrganizationType = "Organization::Virtual";
/**
 * Renders an organization result as a list item
 */

const CpOrganizationItem = ({
  isFirst,
  isLast,
  isSelected = false,
  onSelect,
  organization,
  testID,
  ...otherProps
}) => {
  const organizationData = useFragment(organizationFragment, organization);
  const {
    _id,
    address,
    name,
    type
  } = organizationData;
  const handleOnSelect = useCallback(() => {
    onSelect(organizationData);
  }, [onSelect, organizationData]);
  return <_StyledPrPressable onPress={handleOnSelect} testID={testID ? testID : `CpOrganizationItem-${_id}`} {...otherProps} $_css={[localStyles.selectorVerticalBase, isFirst && localStyles.selectorVerticalTop, isLast && localStyles.selectorVerticalBottom, isSelected && styles.selected]}>
      {isSelected && <_StyledView $_css2={localStyles.selectorInsert} />}
      <_StyledView2 $_css3={[localStyles.membershipInnerContainer, isSelected && localStyles.selectorInnerContainerSelected]}>
        <_StyledPrText children={name} numberOfLines={1} $_css4={localStyles.membershipName} />
        <_StyledPrText2 numberOfLines={1} $_css5={localStyles.membershipAddress}>
          {type === VirtualOrganizationType ? <FormattedMessage id="SHW1H+" defaultMessage="Online Platform" /> : address}
        </_StyledPrText2>
      </_StyledView2>
    </_StyledPrPressable>;
};

export default CpOrganizationItem;

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpOrganizationItem___StyledPrPressable",
  componentId: "sc-27zyfd-0"
})(["", ""], p => p.$_css);

var _StyledView = _styled(View).withConfig({
  displayName: "CpOrganizationItem___StyledView",
  componentId: "sc-27zyfd-1"
})(["", ""], p => p.$_css2);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpOrganizationItem___StyledView2",
  componentId: "sc-27zyfd-2"
})(["", ""], p => p.$_css3);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpOrganizationItem___StyledPrText",
  componentId: "sc-27zyfd-3"
})(["", ""], p => p.$_css4);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpOrganizationItem___StyledPrText2",
  componentId: "sc-27zyfd-4"
})(["", ""], p => p.$_css5);