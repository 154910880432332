/**
 * @generated SignedSource<<53e8da7afd6a249d7536c28a902c92f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserStatus = "active" | "inactive" | "pending" | "%future added value";
export type ChangeUserEmailInput = {
  currentPassword: string;
  email: string;
};
export type CpAccountChangeEmailMutation$variables = {
  input: ChangeUserEmailInput;
};
export type CpAccountChangeEmailMutation$data = {
  readonly changeUserEmail: {
    readonly user: {
      readonly email: string | null;
      readonly status: UserStatus;
    };
  } | null;
};
export type CpAccountChangeEmailMutation = {
  response: CpAccountChangeEmailMutation$data;
  variables: CpAccountChangeEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpAccountChangeEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeUserEmailPayload",
        "kind": "LinkedField",
        "name": "changeUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpAccountChangeEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ChangeUserEmailPayload",
        "kind": "LinkedField",
        "name": "changeUserEmail",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0035a5b5d722e3b115ef7f1d272b55dd",
    "id": null,
    "metadata": {},
    "name": "CpAccountChangeEmailMutation",
    "operationKind": "mutation",
    "text": "mutation CpAccountChangeEmailMutation(\n  $input: ChangeUserEmailInput!\n) {\n  changeUserEmail(input: $input) {\n    user {\n      email\n      status\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "88e49c5afb51821028fa61ce782affb9";

export default node;
