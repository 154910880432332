import _styled from "styled-components";

var _CpTopicHeading_resourceNode;

import React, { useMemo } from "react";
import { graphql, useFragment } from "react-relay/hooks";
import PrText from "components/primitives/PrText";
import { QlTopic_comboFragment } from "query/QlTopic";
import { getNameVariant } from "services/Graphql";
import { css } from "services/Theme";
const localStyles = {
  heading: css`
    font-family: ${({
    theme
  }) => theme.fontFamilies.heading};
    font-size: ${({
    theme
  }) => theme.fontSize("xxlarge")};
    font-weight: bold;
  `
};
const resourceNodeFragment = _CpTopicHeading_resourceNode !== void 0 ? _CpTopicHeading_resourceNode : _CpTopicHeading_resourceNode = require("./__generated__/CpTopicHeading_resourceNode.graphql");

/**
 * Renders the topic name as a heading given the selected resource node.
 */
const CpTopicHeading = ({
  comboKey,
  style
}) => {
  const comboData = useFragment(QlTopic_comboFragment, comboKey);
  const data = useFragment(resourceNodeFragment, comboData);
  const topicName = useMemo(() => {
    const ancestorNodes = data.ancestors ?? [];
    const rootNode = ancestorNodes.length > 0 ? ancestorNodes[0] : data;
    return rootNode ? getNameVariant(rootNode) : "";
  }, [data]);
  return <_StyledPrText accessibilityRole="header" aria-level="1" style={style} $_css={localStyles.heading}>
      {topicName}
    </_StyledPrText>;
};

export default CpTopicHeading;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpTopicHeading___StyledPrText",
  componentId: "sc-1vlf285-0"
})(["", ""], p => p.$_css);