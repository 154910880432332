import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ScrollView, View } from "react-native";
import PrButton from "components/primitives/PrButton";
import PrText from "components/primitives/PrText";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * Renders a screen confirming the successful creation of a PatientSession
 */
const CpPatientSessionSuccess = ({
  onClose,
  style
}) => {
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <_StyledPrText children={translations.labels.success} $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]} />
      <_StyledView $_css3={styles.paddingHorizontalSingle}>
        <_StyledPrText2 $_css4={styles.paddingVerticalDouble}>
          <FormattedMessage id="20ZoVP" defaultMessage="Your Patient Session is now complete. Emails should be dispatched to your patient (and any additional recipients) shortly." />
        </_StyledPrText2>

        <_StyledPrButton onPress={onClose} testID="CpPatientSessionSuccess-close" type="raised" $_css5={styles.sessionStyles.button}>
          <_StyledPrText3 children={translations.buttons.close} $_css6={styles.sessionStyles.buttonText} />
        </_StyledPrButton>
      </_StyledView>
    </_StyledScrollView>;
};

export default CpPatientSessionSuccess;

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPatientSessionSuccess___StyledScrollView",
  componentId: "sc-jwgxj9-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSuccess___StyledPrText",
  componentId: "sc-jwgxj9-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionSuccess___StyledView",
  componentId: "sc-jwgxj9-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSuccess___StyledPrText2",
  componentId: "sc-jwgxj9-3"
})(["", ""], p => p.$_css4);

var _StyledPrButton = _styled(PrButton).withConfig({
  displayName: "CpPatientSessionSuccess___StyledPrButton",
  componentId: "sc-jwgxj9-4"
})(["", ""], p => p.$_css5);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionSuccess___StyledPrText3",
  componentId: "sc-jwgxj9-5"
})(["", ""], p => p.$_css6);