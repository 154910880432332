import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconLungs: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M304.28 293.25h-6a43.19 43.19 0 00-6.7-2.8c-20.3-4.9-40.5-10-60.9-14.3-28.9-6.1-36.3-25-36.2-44 .1-19.8 2.1-39.6 3.5-59.4.2-2.1 1.7-4.2 3.3-7.6 6.9 7.4 12.9 13.8 19.1 20.5 4.2-3.3 6.1-6.7 2.3-11.2-10.9-13.1-23.1-24.6-38.4-32.5-5.4-2.8-10.5-2.5-15.6.2-15.3 7.9-27.7 19.2-38.3 32.6-3.7 4.7-1.9 7.8 3 11 7.6-9.1 14.2-19.3 26.7-23.6 1.1 5 2.5 9.2 2.9 13.6 1.3 15.3 2.8 30.7 3.3 46 1 28.2-4.5 49.9-45.1 56.6-.5.1-1 .1-1.5.2l-57.4 14.7h-6c-8.1-5.1-12.5-12.4-13.4-21.9-.5-5.4-1.5-10.8-1.6-16.3-.6-35.3 8-68.8 19.8-101.7 6.9-19.2 15.2-37.7 27.7-54.1 6.5-8.4 14.1-15.4 24.2-19.2 6.7-2.6 13.5-3.3 19.8 1.5 12.4 9.6 15.6 25 6.2 37.4-6.2 8.2-14.2 15-21.6 22.3-2.3 2.3-5.1 4.2-7.8 6.4 2.1 3.2 3.7 5.5 6.6 9.6 8.5-7.4 17-13.4 23.8-21 17.4-19.7 22.7-44.6 26.9-69.5h19.3c8.2 49.2 22.8 74.2 51.9 88.7 1.4-2.8 2.9-5.7 4.4-8.6-5.8-4.5-11.3-8-15.8-12.5-20.1-20.2-18.6-20.8-5.9-44.7 6.6-12.4 15.9-14.3 29.1-8.3 12.3 5.6 20.9 15.3 27.4 26.6 27.6 47.8 42.6 99.2 41.4 154.7 0 11.9-2.9 23.5-14.4 30.6z" />
    </Svg>
  )
}

export default IconLungs
