var _CpCommonTopicsSectionQuery;

import React, { useCallback, useEffect, useMemo } from "react";
import { graphql, usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import CtApiSuspense from "components/containers/CtApiSuspense";
import CpContentLinksSection from "components/home/tabs/CpContentLinksSection";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import useBackendSupportedLocale from "hooks/useBackendSupportedLocale";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { getNameVariant } from "services/Graphql";
const commonTopicsQuery = _CpCommonTopicsSectionQuery !== void 0 ? _CpCommonTopicsSectionQuery : _CpCommonTopicsSectionQuery = require("./__generated__/CpCommonTopicsSectionQuery.graphql");

const CommonTopicsShortCutsList = ({
  queryReference,
  ...otherProps
}) => {
  const data = usePreloadedQuery(commonTopicsQuery, queryReference);
  const locale = useBackendSupportedLocale();
  const {
    dispatchPatientSession
  } = usePatientSessionContext();
  const shortcuts = useMemo(() => {
    return (data.node?.commonTopics ?? []).map(commonTopic => {
      const {
        icon,
        resourceNodeRevisionId
      } = commonTopic;
      const title = {
        [locale]: getNameVariant(commonTopic) || ""
      };
      return {
        color: "primary",
        icon: icon ?? undefined,
        link: {
          link_type: "topic",
          value: resourceNodeRevisionId
        },
        title
      };
    });
  }, [data.node?.commonTopics, locale]); // If there are no commonTopic nodes, this section wouldn't get rendered, and these callbacks wouldn't get called
  // So it should be safe to have these empty string fallbacks

  const handleAddItemToPackage = useCallback((sectionHeader, revisionId) => {
    const commonTopicId = data.node?.commonTopics?.find(commonTopic => commonTopic.resourceNodeRevisionId === revisionId)?._id ?? "";
    dispatchPatientSession({
      payload: {
        ids: [{
          commonTopicId,
          revisionId
        }],
        sectionHeader,
        type: "commonTopicsSection"
      },
      type: PatientSessionActions.AddToPackage
    });
  }, [data.node?.commonTopics, dispatchPatientSession]);
  const handleAddSectionToPackage = useCallback((sectionHeader, revisionIds) => {
    const ids = revisionIds.map(revisionId => {
      const commonTopicId = data.node?.commonTopics?.find(commonTopic => commonTopic.resourceNodeRevisionId === revisionId)?._id ?? "";
      return {
        commonTopicId,
        revisionId
      };
    });
    dispatchPatientSession({
      payload: {
        ids,
        sectionHeader,
        type: "commonTopicsSection"
      },
      type: PatientSessionActions.AddToPackage
    });
  }, [data.node?.commonTopics, dispatchPatientSession]);
  return <CpContentLinksSection addToPackage={{
    onPressItem: handleAddItemToPackage,
    onPressSection: handleAddSectionToPackage
  }} shortcuts={shortcuts} {...otherProps} />;
};
/**
 * Fetches the common topics and pass the data for CpContentLinksSection to render the result
 * @param sectionTitle
 * @constructor
 */


const CpCommonTopicsSection = props => {
  const {
    licensee,
    organization
  } = useImdSession();
  const orgSearchId = organization?.id || licensee?.id;
  const locale = useBackendSupportedLocale();
  const [queryReference, loadQuery] = useQueryLoader(commonTopicsQuery);
  useEffect(() => {
    if (orgSearchId && locale) {
      loadQuery({
        id: orgSearchId,
        locale: locale
      });
    }
  }, [locale, orgSearchId, loadQuery]);
  return <CtApiSuspense>
      {queryReference && <CommonTopicsShortCutsList queryReference={queryReference} {...props} />}
    </CtApiSuspense>;
};

export default CpCommonTopicsSection;