/**
 * @generated SignedSource<<3017f8e581ec556bc235c8fc5ed2a6d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SearchMethods = "all_tabs" | "filter_content" | "org_accessible" | "public_accessible" | "single_tab" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CpTopicNodeTab_tabData$data = {
  readonly renderWithoutHeaders?: boolean | null;
  readonly search?: {
    readonly placeholder: string | null;
    readonly searchMethod: SearchMethods;
  } | null;
  readonly tabParagraph?: string | null;
  readonly tabParagraphContainerStyles?: string | null;
  readonly tabParagraphStyles?: string | null;
  readonly topicNode?: {
    readonly _id: string;
    readonly revisionId: string;
  } | null;
  readonly " $fragmentType": "CpTopicNodeTab_tabData";
};
export type CpTopicNodeTab_tabData$key = {
  readonly " $data"?: CpTopicNodeTab_tabData$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpTopicNodeTab_tabData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpTopicNodeTab_tabData",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "renderWithoutHeaders",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TopicNode",
          "kind": "LinkedField",
          "name": "topicNode",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "_id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "revisionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AppSearchConfig",
          "kind": "LinkedField",
          "name": "search",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "placeholder",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "searchMethod",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabParagraph",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabParagraphContainerStyles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tabParagraphStyles",
          "storageKey": null
        }
      ],
      "type": "TopicNodeTab",
      "abstractKey": null
    }
  ],
  "type": "AppTab",
  "abstractKey": "__isAppTab"
};

(node as any).hash = "468c4769b7f7f303c8e076aca1391e90";

export default node;
