import * as React from "react"
import Svg, { G, Path, SvgProps } from "react-native-svg"

const IconImd: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg
      clipRule="evenodd"
      fillRule="evenodd"
      height={height}
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 665 260"
      width={height}
      {...otherProps}
    >
      <G>
        <Path d="M119.94 40.03c-11.013 0-19.94 8.928-19.94 19.941v154.511c0 10.803 8.862 19.39 19.941 19.39 10.523 0 19.94-8.587 19.94-19.39V59.971c0-11.013-8.928-19.94-19.94-19.94M381.43 58.794c-.295-9.558-7.514-17.192-16.991-18.515-.027-.004-.054-.004-.081-.007-.925-.126-1.845-.272-2.807-.272-.15 0-.288.039-.436.042a19.201 19.201 0 00-12.905 4.972c-.311.273-.645.516-.937.808-.213.22-.394.473-.597.704a19.139 19.139 0 00-2.285 3.07l-63.915 103.5-63.912-103.498a19.08 19.08 0 00-2.29-3.074c-.201-.23-.381-.484-.593-.701-.294-.293-.629-.538-.942-.812a19.206 19.206 0 00-12.9-4.969c-.148-.003-.285-.042-.435-.042-.961 0-1.88.146-2.803.27-.03.005-.059.004-.087.009-9.477 1.324-16.694 8.956-16.99 18.511-.007.102-.026.2-.032.302-.001.1-.03.193-.03.294v155.095c0 10.803 8.863 19.39 19.942 19.39 10.523 0 19.94-8.587 19.94-19.39v-84.477l44.47 72.013c.106.171.248.3.359.467.186.267.394.514.594.773 3.108 4.225 7.617 6.818 12.539 7.561.128.022.255.027.382.047.925.12 1.848.208 2.788.196.94.012 1.863-.075 2.788-.196.128-.02.255-.025.383-.047 4.921-.743 9.428-3.336 12.537-7.559.201-.26.41-.508.597-.777.11-.166.252-.295.357-.465l44.471-72.013v84.477c0 10.803 8.863 19.39 19.942 19.39 10.523 0 19.94-8.587 19.94-19.39V59.386c0-.102-.03-.194-.03-.295-.006-.1-.024-.197-.031-.297M500.631 40.087h-60.328c-10.621 0-19.232 8.61-19.232 19.232s8.611 19.231 19.232 19.231h60.328c32.244 0 58.476 26.233 58.476 58.476 0 32.243-26.232 58.472-58.476 58.472h-60.328c-10.621 0-19.232 8.61-19.232 19.232 0 10.623 8.611 19.232 19.232 19.232h60.328c53.453 0 96.94-43.485 96.94-96.936 0-53.454-43.487-96.939-96.94-96.939" />
        <Path d="M459.49 137.025c0-10.61-8.6-19.21-19.209-19.21s-19.209 8.6-19.209 19.21c0 10.608 8.6 19.209 19.209 19.209s19.209-8.6 19.209-19.21" />
      </G>
    </Svg>
  )
}

export default IconImd
