import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

import Config from "services/Config"
import {
  AuthError,
  BadRequestError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  ServerError,
  StaleEntityError,
  UniversalError,
  UnprocessableError,
} from "services/Error"
import Log from "services/Log"
import SecureStorage from "services/SecureStorage"
import { getAppName, getBuildNumber, getVersion } from "services/Version"

// Converts params object into a query string
export const paramsToQueryString = (
  params?: null | Record<string, unknown>,
  keyPrefix: null | string = null,
): string => {
  const isArray = Array.isArray(params)
  return Object.entries(params || {})
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => {
      // Rails wants object query keys in the form top[second][third][etc...]
      const finalKey = keyPrefix ? `${keyPrefix}[${isArray ? "" : key}]` : key
      if (value != null) {
        if (typeof value !== "function" && typeof value !== "object") {
          return `${finalKey}=${encodeURIComponent(value as string)}`
        } else {
          return [
            // Rails relies on common keys to identify new objects in an array, this guarantees each object will have the first key in common
            isArray && paramsToQueryString({ inArray: true }, finalKey),
            paramsToQueryString(value as Record<string, unknown>, finalKey),
          ]
        }
      } else {
        return null
      }
    })
    .flat(1000)
    .filter(Boolean)
    .join("&")
}

export interface ImdBackendState {
  currentBackend: AxiosInstance
  getBackendHeaders: () => Record<string, string>
  setBackendHeaders: (headers: Record<string, string>) => void
}

const parseConfigForLog = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: AxiosRequestConfig<any>,
): { graphqlName?: string; graphqlOp?: string; restUrl?: string } => {
  if (config.url?.includes("/api/graphql")) {
    let query: string
    try {
      query = typeof config.data === "string" ? JSON.parse(config.data)?.query : config.data?.query
    } catch (e) {
      query = config.data
    }

    const graphqlRequest = query?.match(/(query|mutation|subscription) (\w+)/)
    if (graphqlRequest) {
      return {
        [graphqlRequest[1]]: graphqlRequest[2],
      }
    }
  }

  return {
    restUrl: config.url,
  }
}

/**
 * Creates a backend instance, which can potentially inherit headers from an existing backend instance
 */
export const makeBackend = (
  baseUrl: string = Config.IMD_WEB_SERVICE_BASE_URL, // Defaults to the initial auto-resolving URL
  inheritedBackendState?: ImdBackendState,
): ImdBackendState => {
  const backendInstance = axios.create({
    baseURL: baseUrl,
    paramsSerializer: paramsToQueryString,
  })

  let currentHeaders: Record<string, string> = {
    ...inheritedBackendState?.getBackendHeaders(),
  }

  const headerHelpers = {
    getBackendHeaders: (): Record<string, string> => currentHeaders,
    setBackendHeaders: (newHeaders: Record<string, string>): void => {
      currentHeaders = { ...newHeaders }
    },
  }

  // The instance default headers may change, so set the actual defaults here
  backendInstance.interceptors.request.use(async (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Accept: "application/json",
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "Imdhealth-App-Build": getBuildNumber(),
        "Imdhealth-App-Name": getAppName(),
        "Imdhealth-App-Version": getVersion(),
        Pragma: "no-cache",
        ...currentHeaders,
      },
    }
  })

  // Log request before adding Authorization headers for each request. Also adds session data from async store.
  backendInstance.interceptors.request.use(async (config) => {
    // Don't mess with the headers if this is an oauth endpoint we're calling
    if (config.url === "/oauth/token" || config.url === "/oauth/revoke") {
      Log.info(`ImdBackend oauth request to ${config.url}`)
      return config
    } else {
      // Get bearer token for secure storage
      const authData = await SecureStorage.getAuthData()

      // Log the fact that the request happened

      const logData = parseConfigForLog(config)
      if (logData.restUrl) {
        Log.info("REST request", logData)
      } else {
        Log.info("Graphql request", logData)
      }

      // Return the request with the access toke, but don't log it
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: authData ? `Bearer ${authData.access_token}` : "",
        },
      }
    }
  })

  // Error injector
  backendInstance.interceptors.response.use(
    (response) => {
      const logData = parseConfigForLog(response?.config)
      const status = response?.status || "No Status"

      Log.info("ImdBackend response", { ...logData, status })
      return response
    },
    (error) => {
      if (error?.config) {
        const logData = parseConfigForLog(error?.config)
        const status = error?.response?.status || "No Status"

        Log.info("ImdBackend error", { ...logData, status })
      } else {
        Log.info("Network error", { error })
      }

      if (error?.response) {
        // If you add another error to be handled, make sure to update `JsonapiError` in services/Error.ts as well
        switch (error.response.status) {
          case 400: // Bad request
            throw new BadRequestError(error)
          case 401: // Authentication Failed
            throw new AuthError(error)
          case 403: // Insufficient access
            throw new ForbiddenError(error)
          case 404: // Resource not found
            throw new NotFoundError(error)
          case 409: // Stale entity/Conflict
            throw new StaleEntityError(error)
          case 422: // unprocessable
            throw new UnprocessableError(error)
          case 500: // Server exception or crash
            throw new ServerError(error)
          default:
            throw new UniversalError(error)
        }
      }
      throw new NetworkError(error)
    },
  )

  return { currentBackend: backendInstance, ...headerHelpers }
}
