/**
 * A collection of custom zod validators.
 *
 * Each validator must return a tuple with a function that validates the value and an object that
 * delivers the error payload if validation fails.
 *
 * The validators are used by calling each factory and spreading the result into the zod refine method
 *
 * @example
 * email: zod.string().min(1).refine(...emailValidation())
 *
 */

import { CustomErrorParams, ZodIssueCode } from "zod"

interface PasswordValidationParams {
  minLength?: number
  requireNumbers?: boolean
  requireSymbols?: boolean
}

type Validator<ValueType = string, ParamsType = undefined> = (
  params?: ParamsType,
) => [
  validation: (value?: ValueType) => boolean,
  message:
    | string
    | (CustomErrorParams & { code?: ZodIssueCode })
    | ((arg: ValueType) => CustomErrorParams & { code?: ZodIssueCode }),
]

const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i // eslint-disable-line no-control-regex

export const emailValidation: Validator = () => [
  (value?: string): boolean => emailRegex.test(value || ""),
  {
    code: ZodIssueCode.invalid_string,
    validation: "email",
  },
]

export const emailListValidation: Validator = () => [
  (value?: string): boolean => {
    if (!value) {
      return true
    } else {
      const list = value?.split(",")
      // remove any empty strings, any whitespace from each value, and map through remaining values to test
      const validatedEmails = list.filter(String).map((email) => emailRegex.test(email.trim()))
      // If any email is invalid, flag the field
      return validatedEmails.every((isValid) => isValid)
    }
  },
  { code: ZodIssueCode.custom, params: { code: "emailList" } },
]

// Validates password according to requirements
// Minimum length (defaults to 8), require numbers and require symbols are optionals
export const validatePassword = (
  password: string,
  options?: PasswordValidationParams,
): string[] => {
  const passedChecks: string[] = []
  if (password.length >= (options?.minLength || 8)) {
    passedChecks.push("minLength")
  }
  if (password.match(/(?=.*[a-z])/)) {
    passedChecks.push("lowerCase")
  }
  if (password.match(/(?=.*[A-Z])/)) {
    passedChecks.push("upperCase")
  }
  if (password.match(/(?=.*[0-9])/) && options?.requireNumbers) {
    passedChecks.push("number")
  }
  if (
    // Certain characters didn't seem to be recognized without adding escapes even though they're "useless"
    // eslint-disable-next-line no-useless-escape
    password.match(/(?=.*[{[(<!@#$%^&*?;:,.`'~"\\/\-=\+_\>)\]}])/) &&
    options?.requireSymbols
  ) {
    passedChecks.push("specialChar")
  }
  return passedChecks
}

// Validates the password and sends an array of the passed checks
export const passwordValidation: Validator<string, PasswordValidationParams> = (params) => {
  // Takes the amount of required params and the extra optional params sent and add them to the calculation of how many conditions must pass the check
  const totalRequiredParams = 3
  const totalOptionalParams = Object.keys(params || {}).reduce(
    (acc, key) => (key === "requireNumbers" || key === "requireSymbols" ? acc + 1 : acc),
    0,
  )

  return [
    (value?: string): boolean =>
      validatePassword(value || "", params).length === totalRequiredParams + totalOptionalParams,
    (value?: string) => ({
      code: ZodIssueCode.custom,
      params: {
        passedChecks: validatePassword(value || "", params),
      },
      validation: "password",
    }),
  ]
}
