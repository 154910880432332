import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconHeart: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M136 152.61c-3.4 9.8-6.5 18.9-9.6 27.9.9.6 1.9 1.1 2.8 1.7 9-18.2 22.2-31.3 41.3-35.8.9-2 1.6-2.9 1.7-3.8 1-27.8 9.5-36.3 37.8-37.1 10.9-.3 21.7-.2 32.6.2 11.3.5 14.2 4.2 14.7 17.2.5 14.6-2 18.5-13.5 19.6-5.6.5-11.2.1-17.9.1-.6 11.7 4.7 18.4 11.7 24.9 11 10.2 21.8 20.6 32 31.6 10.6 11.4 16.9 25.4 15.4 41-2 21-5.2 42-9.2 62.7-2.1 10.6-12.4 17.5-23.5 16.3-40.4-4.6-79.5-13.8-113.4-38a26.29 26.29 0 01-3.7-2.9c-15.3-15.2-31.2-29.8-45.6-45.8-13.9-15.5-13.5-35.1-11.7-54.5a31.9 31.9 0 011.4-7.6c8.9-25.4 9.3-50.6-1.8-75.5a14.46 14.46 0 01-.5-3c-6.2-26.1.6-30.2 27.6-27.8 11 1 14 5.8 12.7 17-.9 7.7-2 15.3-3.3 23-3.65 20.99 1.15 38.6 22 48.6z" />
      <Path d="M137.55 66.51c-7.3-15.7-6.9-17.9 5.1-23 13.1-5.5 16.6-3.6 23 13.5 7.4.2 14.9 4.4 21.5-4.8 8-11.2 13.6-11.1 23.5-2 2.9 2.7 5 6.2 7.6 9.6-1.8 2.9-3.1 5.1-4.5 7.2-1.2 1.7-2.5 3.2-3.9 4.9 2.5 4.7 4.8 9.1 8.3 15.8-29.9 2.7-58.1 7.4-57.6 45.1-1.4 1-2.1 2-3 2.2-26.4 4.4-36-3.7-32.6-30.2 1.6-11.81 7.5-23.1 12.6-38.3z" />
    </Svg>
  )
}

export default IconHeart
