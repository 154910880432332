import React from "react"

import CpLanguagePicker from "components/common/CpLanguagePicker"
import {
  ActionButtonProps,
  ActionButtonUserType,
} from "components/primitives/PrActionButton/PrActionButton"

/**
 * Renders the language picker button without allowing to change regions
 */
const LanguageButton: React.FC<ActionButtonProps> = ({ type = ActionButtonUserType.NavHeader }) => {
  return <CpLanguagePicker allowChangeRegion={false} type={type} />
}

export default LanguageButton
