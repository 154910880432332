import _styled2 from "styled-components";
import _styled from "styled-components";
import React from "react";
import { defineMessages } from "react-intl";
import { Pressable, View } from "react-native";
// eslint-disable-line no-restricted-imports
import PrText from "components/primitives/PrText";
import { ExtendableError } from "services/Error";
import { css } from "services/Theme";
const localStyles = {
  errorText: css`
    color: ${({
    theme
  }) => theme.textColors.error};
    text-align: center;
  `,
  retryText: css`
    color: ${({
    theme
  }) => theme.textColors.error};
    margin-top: ${({
    theme
  }) => theme.spacing.single}px;
    text-align: center;
  `
};
const translations = defineMessages({
  errorTryAgain: {
    id: "6t4vW2",
    defaultMessage: "Press to retry"
  }
});

const CpErrorContent = ({
  error,
  textStyle
}) => {
  return <_StyledPrText accessibilityRole="alert" children={error} $_css={localStyles.errorText} $_css2={textStyle} />;
};
/**
 * Renders an error message given an error or any TextValue
 *
 * It can also make the whole thing clickable if you provide an onPress prop.
 */


const CpError = ({
  error,
  onPress,
  style,
  textStyle
}) => {
  if (!error) return null;
  let errorText;

  if (error instanceof ExtendableError) {
    errorText = error.errorDescriptor();
  } else if (error instanceof Error) {
    errorText = error.message;
  } else {
    errorText = error;
  }

  return onPress ? <Pressable accessibilityRole="button" onPress={onPress} style={style} testID="CpErrorRetry">
      <CpErrorContent error={errorText} textStyle={textStyle} />
      <_StyledPrText2 children={translations.errorTryAgain} $_css3={localStyles.retryText} />
    </Pressable> : <View style={style}>
      <CpErrorContent error={errorText} textStyle={textStyle} />
    </View>;
};

export default CpError;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpError___StyledPrText",
  componentId: "sc-u75nj3-0"
})(["", ";", ""], p => p.$_css, p => p.$_css2);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpError___StyledPrText2",
  componentId: "sc-u75nj3-1"
})(["", ""], p => p.$_css3);