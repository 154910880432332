import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconStds: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M109.8 322.05c-2.5-4.4-7-8.8-7-13.2-.6-39.5-.3-79-.3-118.6 0-1.2.2-2.4.5-5.6 12.5 14.7 23.9 26.7 22.4 46.9-1.8 25.2-.2 50.7-.9 76-.1 4.9-4.3 9.6-6.5 14.5zM134.2 322.05c-1.4-3.3-4.1-6.5-4.1-9.8-.3-31.3-.2-62.7-.2-94 7.8-9.9 17.5-24.1 22-27.9.1 2.8.4 5.7.4 8.5 0 36.4.2 72.9-.3 109.3-.1 4.6-3.7 9.2-5.7 13.8-3.9.1-8 .1-12.1.1zM257.5 129.65c-6.7 21.1-11.6 42.9-29.9 59.2-16.5-17.6-22.9-38.8-27.3-61-2.7 25 8.9 44.9 23 64.3-3.3 6.5-6.4 8.4-12.1 1.3-19.1-23.7-27.6-50.7-25.9-81 .8-13.4 6.1-19.2 19.5-19.6 16.5-.6 33.1-.5 49.7 0 11.9.4 15 4 16.2 16.1 3.5 37-8.3 68.4-35.1 94.2-1.7 1.6-3.4 3.8-5.5 4.4-2.8.9-6 .5-9 .7.7-2.9.7-6.4 2.3-8.6 8.1-10.7 17.6-20.5 24.9-31.7a57.3 57.3 0 009.2-38.3zM157.8 130.05c-7.5 19.2-13.4 38.9-29 54.5-16.5-17.2-23.1-38.4-28.1-60.3-2.3 22.7 7.4 41.3 19.9 58.7 3.7 5.2 3.7 8.1-2.8 11.1-24.7-23.7-34.1-53.1-31.2-86.8.9-10.7 7.8-16.2 18.4-16.3 16.6-.2 33.1-.5 49.7.2 12.4.5 15.4 4.7 16.4 17 2.8 35.2-9.1 65-33.2 90.2-3.8 4-9 11.5-14.7 5.5-5.6-5.8 2.4-9.8 6.3-13.5 17.1-16.5 26.9-36.5 28.3-60.3z" />
      <Path d="M227.7 229.05c-.4 3.7-1.2 7.5-1.2 11.2-.1 22.7-.1 45.3 0 68 0 6.9-1.2 12.2-9.7 12.1-8.7-.1-9.3-5.7-9.2-12.4.2-26 .1-51.9.1-79h-24.2c4.6-15.5 8.8-29.6 13.8-46.3 7.2 10 12.4 18.7 19.1 26 11.8 12.8 30.5-10 34.5-15.7 2.1-3 4.6-5.6 7.8-9.6 5.3 15.5 10.3 29.9 15.2 44.3a10.77 10.77 0 01-1.8.6c-22.2 1.6-22.2 1.6-22.2 24.7v52.8c0 6.6 1.7 14.4-8.9 14.7-10.9.4-9.2-7.4-9.2-14.1q0-33.45-.1-67a88 88 0 00-1-10.2c-1-.1-2-.1-3-.1zM229.8 39.25c11.9.6 20.8 11 20 23.4-.7 11.8-10.6 21.6-21.5 21.3-11.3-.3-20.9-11.1-20.5-23.3.2-12.5 10-22 22-21.4zM150.3 60.55c-.1 11.8-9.7 21.7-20.9 21.5C118 82 108.5 72 108.5 60.25s9.8-22.2 20.7-22.3c11.5-.1 21.1 10.2 21.1 22.6z" />
    </Svg>
  )
}

export default IconStds
