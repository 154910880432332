/**
 * @generated SignedSource<<455a14afe810097046704067dead3542>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type QlVerifyPasswordResetTokenQuery$variables = {
  input: string;
};
export type QlVerifyPasswordResetTokenQuery$data = {
  readonly verifyPasswordResetToken: boolean;
};
export type QlVerifyPasswordResetTokenQuery = {
  response: QlVerifyPasswordResetTokenQuery$data;
  variables: QlVerifyPasswordResetTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "verifyPasswordResetToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QlVerifyPasswordResetTokenQuery",
    "selections": (v1/*: any*/),
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QlVerifyPasswordResetTokenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5299d7b6fe715f9507e7622758d20a46",
    "id": null,
    "metadata": {},
    "name": "QlVerifyPasswordResetTokenQuery",
    "operationKind": "query",
    "text": "query QlVerifyPasswordResetTokenQuery(\n  $input: String!\n) {\n  verifyPasswordResetToken(token: $input)\n}\n"
  }
};
})();

(node as any).hash = "98ed30a2b7acf216315b7d28c2aee9da";

export default node;
