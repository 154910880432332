var _QlPatientPackagesQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";

/**
 * Used to fetch a users PatientPackages and their PatientPackageResourceNode children given a user and their organization
 */
export const QlPatientPackagesQueryRequest = _QlPatientPackagesQuery !== void 0 ? _QlPatientPackagesQuery : _QlPatientPackagesQuery = require("./__generated__/QlPatientPackagesQuery.graphql");

const QlPatientPackages = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery fetchEveryTime={true} query={QlPatientPackagesQueryRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlPatientPackages;