import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconSleep: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M56.6 151.75c.1-1.4.3-2.9.3-4.3a8.56 8.56 0 018.6-8.7c2.5 0 4.9-.1 7.4-.1 5.2.2 8.9 3.7 9 8.8.1 7.8 0 15.7 0 23.5v57.7h198.4c5.7 0 11.4.1 17.1 0 5-.1 5-.2 6 4.4v54.8a15.7 15.7 0 01-7.2 7.2h-11.8a7 7 0 00-1-.8c-2.7-1.8-5.2-3.7-5.3-7.3-.1-2.9 0-5.8 0-8.7v-16.2h-41c-11.3 0-22.5.1-33.8.1-12.5 0-24.9-.1-37.4-.1-11.3 0-22.7.1-34 .1-15.8 0-31.6 0-47.3-.1-2.1 0-2.8.7-2.8 2.8.1 6.4.2 12.8 0 19.2-.2 7.8-2.5 10.1-10.2 10.9a2.12 2.12 0 00-.7.3h-3.6a8 8 0 00-1.5-.3c-3.7.1-6.2-1.7-8-4.8a6.53 6.53 0 00-1.3-1.6q.15-68.4.1-136.8z" />
      <Path d="M303.4 216.35c-.4 1.6-.3 3.7-1.3 4.5s-3.1.4-4.7.4c-11.3 0-22.7-.1-34-.1-12.4 0-24.9.1-37.3.1-11.3 0-22.7-.1-34-.1-12.5 0-25.1 0-37.6.1h-7.2c-.1-1.8-.3-3.3-.3-4.8 0-12.9-.1-25.8.1-38.6.1-5.4 3.3-9.3 7.8-12.2a15.17 15.17 0 018.4-2.3c15.8.1 31.5 0 47.3 0 14.8 0 29.7.1 44.5.1 3.9 0 7.8.1 11.8 0 8.9-.2 16.6 3 23.3 8.6s10.8 12.9 12.6 21.5a9.14 9.14 0 00.7 1.4c-.1 7.05-.1 14.2-.1 21.4zM228.6 64.75c.2 4.3.5 8.6.6 12.9 0 .8-1.1 1.7-1.8 2.4-5.9 6.5-11.9 12.9-17.8 19.4-.6.6-1.1 1.3-2.1 2.4h12.1c2.5 0 4.9 0 7.4.1 1.7 0 2.4.9 2.4 2.6-.1 2.6-.1 5.3-.1 7.9 0 1.7-.7 2.2-2.3 2.2-12.2 0-24.4-.1-36.6 0-1.9 0-2.7-.6-2.5-2.5a8.77 8.77 0 000-2.8c-1.2-5.8 1.4-9.8 5.4-13.6 5.6-5.6 10.8-11.6 16.7-18h-9.3c-3.3 0-6.7-.1-10 0-1.9.1-2.9-.7-2.8-2.6a69.47 69.47 0 01.2-7.9 3.18 3.18 0 012.2-2 22.17 22.17 0 014.5-.4c11.3-.1 22.6-.1 33.8-.1zM89.7 196.25c-.4-14.5 11.5-23.9 21.8-24.7 18.3-1.4 27.1 11.9 27.9 22.4a24.92 24.92 0 01-22.3 27.2c-16.7 1.6-28.7-12.6-27.4-24.9zM165.2 100.35h-14.5c-3.4 0-3.6-.2-3.7-3.6-.2-7.9-.7-7.3 7.2-7.2 7.6.1 15.2 0 22.8 0 3.1 0 3 .4 3.4 3.3 1 6.4-2.2 10.5-6.3 14.5-3.3 3.3-6.4 6.8-9.6 10.2a19.67 19.67 0 00-1.9 2.6h15.3c1.8 0 2.6.8 2.6 2.6 0 7.7 0 7.9-7.6 8s-15.2 0-22.8.1c-2.1 0-2.7-.9-3.1-3-.9-5.8 1.3-10 5.4-13.9 4.4-3.95 8.3-8.7 12.8-13.6zM127.6 131c-4.1 0-7.4-.1-10.7 0-1.9 0-2.6-.8-2.7-2.6-.4-5.7.1-6.3 5.9-6.1 5.5.1 10.9.2 16.4.1 2.1 0 2.6.6 2.9 2.7 1.1 6.2-2.9 9.7-6.2 13.6-2.2 2.6-4.3 5.2-6.9 8.2 3.2 0 5.8-.1 8.4 0 1.5.1 3.9.3 4.1.9a18.81 18.81 0 01.3 6.7c0 .4-1.5 1-2.3 1-6.7.1-13.3 0-20 .1-1.8 0-2.5-.8-2.5-2.5s-.2-3.3-.1-4.9a4.93 4.93 0 011.1-2.9c3.9-4.55 7.9-9.15 12.3-14.3z" />
    </Svg>
  )
}

export default IconSleep
