import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - keyboard_arrow_right
const KeyboardArrowRight: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M11.438 22.125l6.125-6.125-6.125-6.125 1.875-1.875 8 8-8 8z" />
    </Svg>
  )
}

export default KeyboardArrowRight
