import React, { createContext, useReducer } from "react"
import { CanvasPath, ReactSketchCanvasRef } from "react-sketch-canvas"

import { DrawingToolsActions } from "components/topic/drawingTools/drawingTools"
import { commonColors } from "services/Theme/common"

export enum StrokeWidthValues {
  Light = "2",
  Medium = "5",
  Heavy = "10",
}

// drawingEnabled vs isDrawing: we use `drawingEnabled` after we click on the `Draw` button the first time, replacing the image with a canvas.
// `isDrawing` is when the user is actively drawing so they have a stroke width and are able to draw on the canvas.
export interface DrawingToolsContextData {
  canvasPaths: CanvasPath[]
  drawingEnabled: boolean
  eraserMode: boolean
  isDrawing: boolean
  stageRef: null | React.RefObject<ReactSketchCanvasRef>
  strokeColor: string
  strokeWidth: string
  uploadedImage: null | string
}

type DrawingToolsActionTypes =
  | {
      payload: CanvasPath[]
      type: DrawingToolsActions.SetCanvasPaths
    }
  | {
      payload: React.RefObject<ReactSketchCanvasRef>
      type: DrawingToolsActions.GenerateStageRef
    }
  | {
      payload: null | string
      type: DrawingToolsActions.SetUploadedImage
    }
  | {
      payload: {
        drawingEnabled?: boolean
        eraserMode?: boolean
        isDrawing?: boolean
        strokeColor?: string
        strokeWidth?: string
      }
      type: DrawingToolsActions.SetDrawingOptions
    }
  | {
      type: DrawingToolsActions.ClearContext
    }

export interface DrawingToolsType {
  dispatchDrawingTools: React.Dispatch<DrawingToolsActionTypes>
  drawingToolsData: DrawingToolsContextData
}

const initialData: DrawingToolsContextData = {
  canvasPaths: [],
  drawingEnabled: false,
  eraserMode: false,
  isDrawing: false,
  stageRef: null,
  strokeColor: commonColors.blue,
  strokeWidth: StrokeWidthValues.Medium,
  uploadedImage: null,
}

export const DrawingToolsContext = createContext<undefined | DrawingToolsType>(undefined)

const drawingToolsReducer: React.Reducer<DrawingToolsContextData, DrawingToolsActionTypes> = (
  state,
  action,
): DrawingToolsContextData => {
  switch (action.type) {
    case DrawingToolsActions.ClearContext:
      return {
        ...state,
        canvasPaths: [],
        drawingEnabled: false,
        eraserMode: false,
        isDrawing: false,
        uploadedImage: null,
      }
    case DrawingToolsActions.GenerateStageRef:
      return {
        ...state,
        stageRef: action.payload,
      }
    case DrawingToolsActions.SetCanvasPaths:
      return {
        ...state,
        canvasPaths: action.payload,
      }
    case DrawingToolsActions.SetDrawingOptions:
      return {
        ...state,
        ...action.payload,
      }
    case DrawingToolsActions.SetUploadedImage:
      return {
        ...state,
        uploadedImage: action.payload,
      }
    default:
      return state
  }
}

/**
 * Initializes the Drawing Tools context and wraps children with the context provider
 */
export const CxDrawingTools: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(drawingToolsReducer, initialData)
  return (
    <DrawingToolsContext.Provider
      value={{ dispatchDrawingTools: dispatch, drawingToolsData: state }}
    >
      {children}
    </DrawingToolsContext.Provider>
  )
}
