import _styled from "styled-components";
import React, { useEffect, useReducer } from "react";
import PrText from "components/primitives/PrText";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { getSelectedActiveSegments, getSessionDuration } from "services/PatientSession";
import { css, styles } from "services/Theme";
const localStyles = {
  durationText: css`
    align-self: center;
    min-width: 64px;
  `
};

const CpPatientSessionTime = ({
  showViewed
}) => {
  const {
    patientSessionData: {
      activeSegments,
      selectedResources
    }
  } = usePatientSessionContext(); // Determine the length of the current session based on activeSegments OR when a selected resource was viewed

  const sessionDuration = getSessionDuration(showViewed ? activeSegments : getSelectedActiveSegments(activeSegments, selectedResources));
  return <_StyledPrText children={sessionDuration} $_css={[styles.navHeaderStyles.navButtonText, localStyles.durationText]} />;
};

const CpPatientSessionTimer = ({
  showViewed
}) => {
  // Force CpPatientSessionTime to call the hook, recalculate the session length, and render.
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEffect(() => {
    const interval = setInterval(() => forceUpdate(), 1000);
    return () => {
      clearInterval(interval);
    };
  });
  return <CpPatientSessionTime showViewed={showViewed} />;
};

export default CpPatientSessionTimer;

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionTimer___StyledPrText",
  componentId: "sc-pv0fvm-0"
})(["", ""], p => p.$_css);