/**
 * @generated SignedSource<<9887b73b6edb8141fb9bf8309ace1da7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeUserPasswordInput = {
  currentPassword: string;
  password: string;
};
export type CtChangeAccountPasswordMutation$variables = {
  input: ChangeUserPasswordInput;
};
export type CtChangeAccountPasswordMutation$data = {
  readonly changeUserPassword: {
    readonly email: string | null;
    readonly weakPassword: boolean | null;
  } | null;
};
export type CtChangeAccountPasswordMutation = {
  response: CtChangeAccountPasswordMutation$data;
  variables: CtChangeAccountPasswordMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weakPassword",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CtChangeAccountPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "changeUserPassword",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CtChangeAccountPasswordMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "changeUserPassword",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d53579ab18e3cd50c76df9d738ee622",
    "id": null,
    "metadata": {},
    "name": "CtChangeAccountPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation CtChangeAccountPasswordMutation(\n  $input: ChangeUserPasswordInput!\n) {\n  changeUserPassword(input: $input) {\n    email\n    weakPassword\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "513a7a2169b57b73e5f73942f526a9d1";

export default node;
