import { useMemo } from "react"

interface Pagination {
  currentPage: number
  isNarrowScreen: boolean
  mobileSiblingCount?: number
  pageSize: number
  siblingCount?: number
  totalEntries: number
}

type usePaginationReturn = [] | [number[], number[], number[]]

// Return type: an array of 3 subarrays
export const usePagination = ({
  currentPage,
  isNarrowScreen,
  mobileSiblingCount = 2,
  pageSize,
  siblingCount = 2,
  totalEntries,
}: Pagination): usePaginationReturn => {
  const range = (start: number, end: number): number[] => {
    const length = end - start + 1
    // Create an array of certain length and set the elements within it from start value to end value.
    return Array.from({ length }, (_, idx) => idx + start)
  }

  return useMemo<usePaginationReturn>(() => {
    if (!totalEntries) return []

    const totalPageCount = Math.ceil(totalEntries / pageSize)
    // Sibling count * 2 (both sides) + first and last elements plus one (or it will show 1-9...9)
    const totalPageNumbers = siblingCount * 2 + 3

    // If mobile, don't show "..." but rather only the 2 closes siblings
    if (isNarrowScreen) {
      if (currentPage + mobileSiblingCount >= totalPageCount) {
        // makes sure the total page count is above 0
        const minRange =
          totalPageCount - mobileSiblingCount * 2 > 0 ? totalPageCount - mobileSiblingCount * 2 : 1
        // For mobile the maximum amount of pages displayed will be: sibling count * 2 + 1
        // Since sibling count defaults to 2, the default value would be 5.
        return [[], range(minRange, totalPageCount), []]
      }
      return currentPage > mobileSiblingCount
        ? [[], range(currentPage - mobileSiblingCount, currentPage + mobileSiblingCount), []]
        : [[], range(1, 5), []]
    }

    // If the number of pages is less than the page numbers we want to show in our paginationComponent, we return the range [1..totalPageCount]
    if (totalPageNumbers >= totalPageCount) {
      return [[], range(1, totalPageCount), []]
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount)

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    // No left dots to show, but rights dots to be shown
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount
      const leftRange = range(1, leftItemCount)

      return [[], [...leftRange], [totalPageCount]]
    }

    // No right dots to show, but left dots to be shown
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount)
      return [[firstPageIndex], [...rightRange], []]
    }

    // Both left and right dots to be shown
    const middleRange = range(leftSiblingIndex, rightSiblingIndex)

    return [[firstPageIndex], [...middleRange], [lastPageIndex]]
  }, [totalEntries, pageSize, siblingCount, isNarrowScreen, currentPage, mobileSiblingCount])
}
