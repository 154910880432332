import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconWaitingRoom: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M128 96V97.7a3.15 3.15 0 01-.22.29h-.05l-.09.09-.3.23h-.06l-13.45 13.46a4 4 0 01-5.66-5.66l13-13V68a4 4 0 118 0v28zm140.12 39a6 6 0 1111.77 2.11l-15.52 86.51a30.81 30.81 0 01-30.49 25.53h-45l51.23 48.54a6 6 0 11-8.3 8.63l-61.67-58.54a6 6 0 013.86-10.66h59.88a18.84 18.84 0 0018.73-15.64L268.13 135zM124 52a44 44 0 11-31.1 12.91A43.86 43.86 0 01124 52zm25.46 18.55A36 36 0 10160 96a35.89 35.89 0 00-10.54-25.45z" />
      <Path
        d="M247.42 78.93a24 24 0 1024 24 24 24 0 00-24-24zM161 297a11 11 0 01-22 0v-54.28l-16.89 38.07a10.95 10.95 0 01-20-8.85l28.65-64.56A22.72 22.72 0 01154 193.82l9.05.89a11 11 0 019.69-10.6l32.26-3.87c5.17-20.48 14.55-42.16 39.84-44.18a11 11 0 0112.34 12.78l-12.33 70.64a11 11 0 01-10.93 9.52H173a12.05 12.05 0 00-12 12v56z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconWaitingRoom
