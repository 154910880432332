import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useMemo } from "react";
import { View } from "react-native";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAppTheme from "hooks/useAppTheme";
import { css, styles } from "services/Theme";
import { iconSizes } from "services/Theme/common";
const localStyles = {
  box: css`
    align-items: center;
    border-radius: ${({
    theme
  }) => theme.borderRadii.sharp}px;
    border-width: 1px;
    margin-vertical: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  icon: css`
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `,
  text: css`
    padding: ${({
    theme
  }) => theme.spacing.single}px;
  `
}; // Add an alert style for each new type of alert we implement in the future
// These styles should only change colors

const warningStyles = {
  alertBox: css`
    background-color: ${({
    theme
  }) => theme.brandColors.snackbarBackgroundWarning};
    border-color: ${({
    theme
  }) => theme.brandColors.snackbarAccentWarning};
    color: ${({
    theme
  }) => theme.brandColors.snackbarAccentWarning};
  `,
  icon: css`
    background-color: ${({
    theme
  }) => theme.brandColors.snackbarAccentWarning};
    color: ${({
    theme
  }) => theme.brandColors.snackbarAccentPrimary};
  `
};
const errorStyles = {
  alertBox: css`
    background-color: ${({
    theme
  }) => theme.brandColors.snackbarBackgroundError};
    border-color: ${({
    theme
  }) => theme.brandColors.snackbarAccentError};
    color: ${({
    theme
  }) => theme.brandColors.snackbarAccentError};
  `,
  icon: css`
    background-color: ${({
    theme
  }) => theme.brandColors.snackbarAccentError};
    color: ${({
    theme
  }) => theme.brandColors.snackbarAccentPrimary};
  `
};
export let AlertType;

(function (AlertType) {
  AlertType["Warning"] = "warning";
  AlertType["Error"] = "error";
})(AlertType || (AlertType = {}));

/**
 * Renders an alert message
 * You can make it closable by passing a onClose prop or/and clickable by passing a onPress prop
 * For now it only supports warning messages, but we can add more types in the future
 */
const CpAlert = ({
  icon,
  message,
  onClose,
  onPress,
  style,
  testID,
  type,
  visible = true
}) => {
  const {
    textColors
  } = useAppTheme();
  const alertStyle = useMemo(() => {
    switch (type) {
      case AlertType.Warning:
        return warningStyles;

      case AlertType.Error:
      default:
        // Consider returning an error if it falls on default when we have more alert types
        return errorStyles;
    }
  }, [type]);
  const renderContent = useMemo(() => {
    return <>
        {icon ? <_StyledView $_css={[localStyles.icon, alertStyle.icon]}>
            <PrIcon fill={textColors.inverse} icon={icon} size={iconSizes.medium} />
          </_StyledView> : null}
        <_StyledPrText children={message} $_css2={[styles.paddingHorizontalDouble, styles.flexAndCenter, localStyles.text]} />
        {onClose ? <_StyledPrPressable focusedBackgroundColor="textInputActive" hoveredBackgroundColor="textInputActive" onPress={onClose} pressedBackgroundColor="textInputActive" $_css3={[styles.fullHeight, styles.center]}>
            <PrIcon fill={textColors.default} icon={IconName.close} size={iconSizes.small} />
          </_StyledPrPressable> : null}
      </>;
  }, [alertStyle.icon, icon, message, onClose, textColors.default, textColors.inverse]);

  if (!visible) {
    return null;
  }

  return onPress ? <_StyledView2 style={style} testID={testID} $_css4={[styles.center, styles.fullWidth]}>
      <_StyledPrPressable2 focusedBackgroundColor="textInputActive" hoveredBackgroundColor="textInputActive" onPress={onPress} pressedBackgroundColor="textInputActive" $_css5={[styles.paddingNone, localStyles.box, styles.fullWidth, styles.row, ...alertStyle.alertBox]}>
        {renderContent}
      </_StyledPrPressable2>
    </_StyledView2> : <_StyledView3 $_css6={styles.fullWidth}>
      <_StyledView4 style={style} testID={testID} $_css7={[styles.center, styles.fullWidth, styles.row, localStyles.box, ...alertStyle.alertBox]}>
        {renderContent}
      </_StyledView4>
    </_StyledView3>;
};

export default CpAlert;

var _StyledView = _styled(View).withConfig({
  displayName: "CpAlert___StyledView",
  componentId: "sc-llkr5f-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpAlert___StyledPrText",
  componentId: "sc-llkr5f-1"
})(["", ""], p => p.$_css2);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpAlert___StyledPrPressable",
  componentId: "sc-llkr5f-2"
})(["", ""], p => p.$_css3);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpAlert___StyledView2",
  componentId: "sc-llkr5f-3"
})(["", ""], p => p.$_css4);

var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpAlert___StyledPrPressable2",
  componentId: "sc-llkr5f-4"
})(["", ""], p => p.$_css5);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpAlert___StyledView3",
  componentId: "sc-llkr5f-5"
})(["", ""], p => p.$_css6);

var _StyledView4 = _styled(View).withConfig({
  displayName: "CpAlert___StyledView4",
  componentId: "sc-llkr5f-6"
})(["", ""], p => p.$_css7);