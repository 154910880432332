import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const Trash: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 64 64" width={height} {...otherProps}>
      <Path d="M51,17 L51,54.7142857 C51,58.1714286 48.15,61 44.6666667,61 L44.6666667,61 L19.3333333,61 C15.85,61 13,58.1714286 13,54.7142857 L13,54.7142857 L13,17 L51,17 Z M24,25 L20,25 L20,53 L24,53 L24,25 Z M34,25 L30,25 L30,53 L34,53 L34,25 Z M44,25 L40,25 L40,53 L44,53 L44,25 Z M43,7 C43,4.73665308 40.5806263,2.16914149 39,2.16914149 C34.5363001,2.16914149 29.2208575,2.16914149 25,2.16914149 C23.1407099,2.16914149 21,4.73665308 21,7 C18.4031681,7 10,7 10,7 L10,13 L54,13 L54,7 C54,7 45.4352584,7 43,7 Z" />
    </Svg>
  )
}

export default Trash
