/**
 * @generated SignedSource<<e83906140448c12ea7d5c0569cf8f0d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpMediaNodeNavigatorCommon_resourceNode$data = {
  readonly _id: string;
  readonly ancestors: ReadonlyArray<{
    readonly revisionId: string;
  }>;
  readonly revisionId: string;
  readonly siblings: ReadonlyArray<{
    readonly _id: string;
    readonly names: any;
    readonly resource: {
      readonly caption: string | null;
      readonly embedMarkup: string | null;
      readonly url: string | null;
    } | null;
    readonly revisionId: string;
  }>;
  readonly taggedResources: ReadonlyArray<{
    readonly _id: string;
    readonly caption: string | null;
    readonly embedMarkup: string | null;
    readonly names: any;
    readonly thumbnailUrl: string | null;
    readonly url: string | null;
  }> | null;
  readonly type: string;
  readonly " $fragmentType": "CpMediaNodeNavigatorCommon_resourceNode";
};
export type CpMediaNodeNavigatorCommon_resourceNode$key = {
  readonly " $data"?: CpMediaNodeNavigatorCommon_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpMediaNodeNavigatorCommon_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "caption",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "embedMarkup",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v2/*: any*/),
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpMediaNodeNavigatorCommon_resourceNode",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "siblings",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "taggedResources",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": (v2/*: any*/),
          "kind": "ScalarField",
          "name": "thumbnailUrl",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "3cca2b1a4c6bdc626b2e9bb9da745602";

export default node;
