import camelcase from "camelcase"
import { selectAll } from "css-select"
import { parseDocument } from "htmlparser2"

/*
  Extract specific chunks of information from a resources `embedMarkup`.
  Expects html to be received to follow a specific template:
  <div class='content'>
    <section class='section-level-1'>
      <h2 class='section-title'>Section Title</h2>
      <div class='section-body'>
        Section Content
        [[media type="image" resource_id="1234-56789-0987=6543-21001"]]
      </div>
    </section>
  </div>
*/

// Regular Expression Formulae
const mediaTagExp = /\[\[(.+?)(?=]])]]/gm

/**
 * Return an array of all section title strings without their tags, or an empty array.
 * When calling, wrap with useMemo()
 */
export const extractSectionTitles = (html?: null | string): string[] => {
  if (!html) return []

  const parsedDom = parseDocument(html)
  const allTitles = selectAll("h2.section-title", parsedDom)
  return allTitles.map((titleNode) => {
    const textNode = titleNode.childNodes[0] as undefined | { nodeValue?: string }
    return textNode?.nodeValue ?? ""
  })
}

/**
 * Returns object containing original html with media tag shortcodes removed, as well as an array of media tag shortcode objects keyed by type and resourceId
 */
export const parseMediaShortcodes = (
  html: string,
): { html: string; media: Array<{ [x: string]: string }> } => {
  const shortcodes = html?.match(mediaTagExp) || []
  const cleanedHtml = html?.replace(mediaTagExp, "")
  let cleanedShortcodes: Array<{ [x: string]: string }> = []

  if (shortcodes.length > 0) {
    cleanedShortcodes = shortcodes.map((shortcode) => {
      const mediaObject: { [x: string]: string } = {}
      shortcode
        // Remove double square brackets
        .replace("[[media ", "")
        .replace("]]", "")
        // split contents into `type="____"` && `resource_id="xxx-xxx"`
        .split(" ")
        // iterate through and return object keyed by `type` && `resourceId`
        .forEach((splitCode) => {
          // eslint-disable-next-line prefer-const
          let [key, value] = splitCode.split("=")
          if (value.includes("&quot;")) {
            // Slicing value to remove character entity
            value = value.slice(6, -6)
          } else {
            // Otherwise, slicing value since it's double-wrapped in quotation marks
            value = value.slice(1, -1)
          }
          mediaObject[camelcase(key)] = value
        })
      return mediaObject
    })
  }
  return { html: cleanedHtml, media: cleanedShortcodes }
}
