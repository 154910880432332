import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconMouth: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M193.83 325.24c-8.1.1-16.2.3-24.3.4-29.9.6-54.8-10.9-75.5-31.9-18.1-18.5-29.6-40.9-38.3-65a312.54 312.54 0 01-15.4-63.1c-.8-6 .2-12.4 1-18.6 2.4-19.2 6.9-38 14.9-55.7 7.9-17.3 18.5-32.5 34.5-43.3 15.6-10.5 33.1-14.4 51.6-13.4 5.6.3 11.1 2.4 16.8 3.1a158.52 158.52 0 0056.6-2.7c4.8-1.1 10.2-.7 15.3-.2 35.5 3.3 57.8 24.1 72.3 55 8.7 18.7 12.9 38.5 15.9 58.8 2.4 16.2-1.6 31.4-5 46.7-5.8 25.4-14.2 49.9-27.9 72.2-12.3 20.1-27.9 36.9-49.2 47.6a86.08 86.08 0 01-43.3 10.1zm95.7-152.7c-1.5-.8-2.8-1.5-4.2-2.2-15.3-7.3-31.2-12.2-48.2-13.5-19.3-1.5-37.7 1.4-54.9 10.6a3.76 3.76 0 01-4.3 0 84.64 84.64 0 00-31.9-10.2 116.82 116.82 0 00-54.3 6.3c-7.1 2.5-13.9 5.6-20.5 8.3-.1 1-.2 1.2-.1 1.4 7.5 21 16.2 41.4 28.9 59.8 4.4 6.3 9.3 12.1 15.9 16.3 7.7 5 15.4 10.2 23.1 15.3a92 92 0 0023.3 11.5c19.1 5.8 36.7 1.9 53.4-7.9 8.8-5.2 17-11.4 25.7-16.7a64.84 64.84 0 0020-19.7c10.7-15.8 18.3-33 25.1-50.7.9-2.8 1.9-5.6 3-8.6zm-145.7-101.9v20.8c0 5.1 3.3 8.7 8.3 8.9 5.7.2 11.5.2 17.2 0 5-.2 8.3-3.7 8.4-8.8.1-3 0-6 0-9v-13c-11.3.3-22.4.7-33.9 1.1zm38.6-.8v21.6c0 5.1 3.3 8.6 8.3 8.8 5.7.2 11.5.2 17.2 0 5.2-.2 8.4-3.8 8.4-9.1v-20.6c-7.7-.3-15.1-.6-22.4-.8-3.6 0-7.4.1-11.5.1zm-43 10.7v-7.8c.1-1.7-.6-2.3-2.3-2.4-5.3-.3-10.6-.8-15.9-1.3-4.5-.4-7.6 2.4-7.6 6.8v12.1c0 4.1 2.3 6.6 6.4 6.8s8.5.2 12.7 0 6.6-2.7 6.7-7c.1-2.4 0-4.8 0-7.2zm81.3-.2v7.5c.1 4.1 2.5 6.7 6.5 6.8 4.2.2 8.5.2 12.7 0s6.5-2.7 6.6-7v-12.1a6.54 6.54 0 00-7.2-6.7c-5.5.4-11 .8-16.5 1.4a3 3 0 00-2 2.1c-.2 2.8-.1 5.4-.1 8zm-124.5 9.3c12 2 14.4-2.4 12.3-13.7-4.2 4.7-8.1 9.06-12.3 13.7zm168 .2c-4.5-4.8-8.5-9.1-12.8-13.7-1.7 12 1.1 15 12.8 13.7z" />
    </Svg>
  )
}

export default IconMouth
