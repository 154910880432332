import Routes from "navigators/Routes";
import { defineMessages } from "react-intl";
export const screenTranslations = defineMessages({
  [Routes.app]: {
    id: "3u3WiS",
    defaultMessage: "IMD Health"
  },
  [Routes.change_account_email]: {
    id: "d9X4w/",
    defaultMessage: "Change Account Email"
  },
  [Routes.change_clinic]: {
    id: "o9dRE+",
    defaultMessage: "Change Clinic"
  },
  [Routes.change_password]: {
    id: "cgoRai",
    defaultMessage: "Change Account Password"
  },
  [Routes.change_region]: {
    id: "M6ojSC",
    defaultMessage: "Change Country/Region"
  },
  [Routes.create_account]: {
    id: "6z8VUn",
    defaultMessage: "Create Account"
  },
  [Routes.forgot_password]: {
    id: "X57nWB",
    defaultMessage: "Forgot Password"
  },
  [Routes.help]: {
    id: "Jx2V3x",
    defaultMessage: "Help & How-To's"
  },
  [Routes.home]: {
    id: "MiHCD8",
    defaultMessage: "Home"
  },
  [Routes.manage_account]: {
    id: "tXcL5R",
    defaultMessage: "Manage Account"
  },
  [Routes.not_found]: {
    id: "0hg+jw",
    defaultMessage: "Page not found"
  },
  [Routes.organization]: {
    id: "5YRwul",
    defaultMessage: "View Organization"
  },
  [Routes.organizations]: {
    id: "i/n4qf",
    defaultMessage: "Organizations"
  },
  [Routes.partner]: {
    id: "UV/yOE",
    defaultMessage: "Partner"
  },
  [Routes.policies]: {
    id: "9qu5ZN",
    defaultMessage: "Policies"
  },
  [Routes.profile_details]: {
    id: "yW8piu",
    defaultMessage: "Profile Details"
  },
  [Routes.profile_wizard]: {
    id: "icJ4qk",
    defaultMessage: "Profile Wizard"
  },
  [Routes.reset_password]: {
    id: "54wDm/",
    defaultMessage: "Reset Password"
  },
  [Routes.signin]: {
    id: "vRawJ+",
    defaultMessage: "IMD Health Signin"
  },
  [Routes.signout]: {
    id: "XO2aiC",
    defaultMessage: "IMD Health Signout"
  },
  [Routes.support]: {
    id: "C7iQ9o",
    defaultMessage: "IMD Health Support"
  },
  [Routes.terms_of_use]: {
    id: "e7ypHT",
    defaultMessage: "Terms of Use"
  },
  [Routes.topic]: {
    id: "ganrhF",
    defaultMessage: "IMD Health Topic"
  },
  [Routes.verify_email]: {
    id: "+kJfwo",
    defaultMessage: "Verify Email"
  },
  [Routes.view_session]: {
    id: "7qHhES",
    defaultMessage: "View My Summary"
  }
});
export default screenTranslations;