import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconHand: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M305.5 131.33c-11-3.2-14.7 4.3-18.5 11-9.8 17.1-19.2 34.4-28.8 51.7-2 3.6-4.1 7.1-6.7 11.8-5.4-1.9-10.6-3.8-16.8-6 1.7-9.4 3-18 4.7-26.5 4.5-21.8 9.3-43.6 13.7-65.4.9-4.5 1.8-9.4 1-13.8-.5-2.9-3.5-6.9-6.2-7.6s-7.7 1-9.1 3.3a49.33 49.33 0 00-6.1 15.7c-5.6 25.3-10.8 50.7-16.2 76-1 4.5-2.2 9-3.6 14.3h-17.2a86.28 86.28 0 01-1-9.4c-.9-36.3-1.6-72.7-2.4-109-.1-2.6-.1-5.2-.2-7.8-.1-5.4-2.9-9.3-8.1-9.8-6.2-.5-9.4 3.8-10.3 9.5a79.39 79.39 0 00-.3 10.9v102.9c0 3.6-.4 7.2-.7 12.3-5.7.4-11 .8-17.4 1.3-5.7-27.9-11.2-54.7-16.6-81.5-1.3-6.6-2.3-13.3-3.8-19.9-2.1-9-6.2-12.6-12.2-11-7.4 2-7.3 7.8-6.4 13.7 2.8 17.9 5.9 35.8 8.5 53.8 3 21 6 42.1 8.2 63.2.8 7.4 1.9 16.4-7.6 20.2-8.7 3.5-13.6-3.6-18.2-8.7-10.9-11.8-21.2-24.2-31.8-36.4-2-2.4-3.7-5.1-5.8-7.3-3.6-3.7-7.8-5.3-12.5-2.1-5.4 3.7-5.2 8.6-2.1 13.6 8.1 12.6 16.4 25.2 24.6 37.7q25.05 38.4 50.2 76.7a63.54 63.54 0 013.9 8.3c-17.8 3.3-17.5 3.1-25.7-9.4-19.9-30.3-40.1-60.5-60.2-90.7-3.2-4.8-6.7-9.3-9.4-14.3-7.6-13.6-4.4-28.7 7.7-37.2a27.77 27.77 0 0136.7 4.2c7 7.6 13.4 15.9 19.9 24 2.5 3.2 4.7 6.7 7 10l3-2.1c.2-2.4.9-4.8.5-7.1-4.6-28.1-9.5-56.2-14.1-84.3a122.38 122.38 0 01-1.7-17c-.2-13 6.2-22.4 17.7-26.4a28.57 28.57 0 0131.1 8.4c2.3 2.6 4.2 5.5 6.8 9 1.5-9.2 2.1-17.4 4.3-25 3.3-11.2 12.5-16.6 23.4-17.7 11.2-1.5 20.6 3.3 26.2 13.2 2.4 4.3 3.4 9.8 3.6 14.8.5 10.3.2 20.7.2 31.9 7.1-2 5.3-7.6 6.6-11.4 5.4-15.9 18.4-24.9 32.9-22.5 15 2.4 23.9 13.7 23.2 30-.3 6.2-2.1 12.2-3.1 18.4-1 5.5-1.9 11-1 17.6 2.6-3.4 5.2-6.8 7.9-10 6.3-7.5 14.2-12.1 24.3-12 13.7.1 23.9 9.4 24.2 23.1.1 6.1-1.5 12.2-2.8 18.3a35.71 35.71 0 01-3.4 8.7c-24.1 47.8-41.9 98.1-58.6 148.8-.8 2.4-1.8 4.7-2.9 7.8h-18.5c1.3-4.6 2.3-8.9 3.7-13 17.1-50.6 34.5-100.9 58.2-148.9 5.9-12.6 5.4-12.8 4.1-24.9z" />
    </Svg>
  )
}

export default IconHand
