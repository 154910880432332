import { AxiosError } from "axios"
import { PayloadError } from "relay-runtime"

import { PrTextValue } from "components/primitives/PrText"
import { GraphqlErrorCode } from "services/Constants"
import Log from "services/Log"
import translations from "translations"

// ============= Types ===============

/**
 * The shape of an individual graphql problem. A problem is the deeper, more meaningful reason behind a graphql error.
 */
export interface GraphqlProblem {
  explanation: string
  path: ReadonlyArray<string>
}

/**
 * This is how the imd backend has elected to represent expected errors through the graphql response payload
 */
export interface GraphqlErrorResponse extends PayloadError {
  extensions?: {
    code: GraphqlErrorCode
    problems: ReadonlyArray<GraphqlProblem>
  }
}

/**
 * v1 jsonapi error response payload as returned from axios
 */
type JsonapiErrorResponse = AxiosError<
  undefined | { errors?: ReadonlyArray<{ detail: string; source: { pointer: string } }> }
>

// ============= Error Classes ===============

/**
 * Base class to allow inheriting from Error
 */
export class ExtendableError extends Error {
  constructor(message: string, name = message) {
    super(message)
    this.stack = new Error().stack
    this.name = name
  }

  /**
   * Express the error as a single error descriptor that can be used with CpError
   */
  errorDescriptor = (): PrTextValue => translations.errors.default
}

/**
 * Error class for when an API request fails because the user is not athenticated - aka 401
 */
export class AuthError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("AuthError")
    Log.warn("AuthError created", { error: this, result })
    this.result = result
  }

  errorDescriptor = (): PrTextValue => translations.errors.unauthenticated
}

/**
 * Error class for when an API request fails because the request was malformed - aka 400
 */
export class BadRequestError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("BadRequestError")
    Log.warn("BadRequestError created", { error: this, result })
    this.result = result
  }
}

/**
 * Error class for when an API request fails because of insufficient permissions - aka 403
 */
export class ForbiddenError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("ForbiddenError")
    Log.warn("ForbiddenError created", { error: this, result })
    this.result = result
  }

  errorDescriptor = (): PrTextValue => translations.errors.unauthorized
}

/**
 *  Error class used when a graphql response contains errors
 */
export class GraphqlError extends ExtendableError {
  result: ReadonlyArray<GraphqlErrorResponse>
  constructor(result: ReadonlyArray<GraphqlErrorResponse>) {
    super(JSON.stringify(result), "GraphqlError")
    Log.warn("GraphqlError created", result)
    this.result = result
  }

  errorDescriptor = (): PrTextValue => {
    // TODO: Process all top level errors. I'm guessing this happens when we batch queries?
    const { extensions } = this.result[0]

    // All our user-facing errors live in extensions. If there's no extensions, we did not expect this error.
    if (!extensions) return translations.errors.default

    // Any of our error codes?
    const { code } = extensions
    switch (code) {
      case GraphqlErrorCode.Forbidden:
        return translations.errors.unauthorized
      case GraphqlErrorCode.NotFound:
        return translations.errors.notFound
      case GraphqlErrorCode.Stale:
        return translations.errors.staleEntityError
      default:
        return translations.errors.default
    }
  }
}

export function isGraphqlError(error: unknown): error is GraphqlError {
  return (error as GraphqlError).result !== undefined
}

/**
 *  Error class for when a network error occurs while trying to call an API
 */
export class NetworkError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("NetworkError")
    Log.warn("NetworkError created", { error: this, result })
    this.result = result
  }

  errorDescriptor = (): PrTextValue => translations.errors.network
}

/**
 *  Error class for when an API request fails due to missing resources - aka 404
 */
export class NotFoundError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("NotFoundError")
    Log.warn("NotFoundError created", { error: this, result })
    this.result = result
  }

  errorDescriptor = (): PrTextValue => translations.errors.notFound
}

/**
 *  Error class for when an API request fails because the servers are burning - aka 500
 */
export class ServerError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("ServerError")
    Log.warn("ServerError created", { error: this, result })
    this.result = result
  }
}

/**
 *  Error class for when an API request fails because the entity is stale - aka 409
 */
export class StaleEntityError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("StaleEntityError")
    Log.warn("StaleEntityError created", { error: this, result })
    this.result = result
  }

  errorDescriptor = (): PrTextValue => translations.errors.staleEntityError
}

/**
 *  Error class for when an API request was unprocessable - aka 422
 */
export class UnprocessableError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("UnprocessableError")
    Log.warn("UnprocessableError created", { error: this, result })
    this.result = result
  }

  errorDescriptor = (): PrTextValue => translations.errors.invalid
}

/**
 * Error class for when something is just plain, old wrong and we don't know why
 */
export class UniversalError extends ExtendableError {
  result: JsonapiErrorResponse
  constructor(result: JsonapiErrorResponse) {
    super("UniversalError")
    Log.warn("UniversalError created", { error: this, result })
    this.result = result
  }
}

// ============= Helper Methods ===============

/**
 * Returns a translated nessage for any error, even no error.
 */
export const errorMessageDescriptor = (error?: Error): PrTextValue => {
  if (error instanceof ExtendableError) {
    return error.errorDescriptor()
  } else {
    return { message: translations.errors.default }
  }
}
