import { useContext } from "react"

import { TopicContext, TopicContextType } from "contexts/CxTopic"

/**
 * Returns the current topic context
 */
function useTopicContext(): TopicContextType {
  const topicContext = useContext(TopicContext)
  if (!topicContext) throw new Error("Topic Context is required")
  return topicContext
}

export default useTopicContext
