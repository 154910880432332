import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconTreatmentRx: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M184.35 158.37l25.26 29.91L236 154.75h52.4c0 3.31-.22 9.78-.22 9.78l-46.1 61.73v9.36s36.46 47.15 53.84 69.81c-1.35 3.13-2.47 5.7-3.84 8.32h-49.86C232 302 220.92 288.58 209 274.15c-10.57 13.28-21.3 26.77-32 40.6h-49.59c-1.36-3.08-2.89-6.06-4.53-9.25l49.19-70 .31-9.4-36.67-51.39-20.63-.07v58.08l-5 5H69l-5-4.94V51l6-5.25 103.73-.5c41.32 15.88 60.95 74.44 10.62 113.12zm-36.49-28.62c18.69-15.75 17-32.32.12-47h-32.9v47z" />
    </Svg>
  )
}

export default IconTreatmentRx
