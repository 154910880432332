import _styled from "styled-components";
import React, { useMemo } from "react";
import { View } from "react-native";
import "react-spinkit/css/chasing-dots.css";
import "react-spinkit/css/three-bounce.css";
import { ActivityIndicatorType } from "components/primitives/PrActivityIndicator/PrActivityIndicator.types";
import useAppTheme from "hooks/useAppTheme";
import { styles } from "services/Theme";

const ChasingDots = ({
  color,
  size
}) => {
  const spinnerStyle = useMemo(() => ({
    color,
    height: size,
    width: size
  }), [color, size]);
  return <div className="sk-spinner sk-chasing-dots sk-fade-in" style={spinnerStyle}>
      <div key="1" />
      <div key="2" />
    </div>;
};

const ThreeBounce = ({
  color,
  size
}) => {
  const spinnerStyle = useMemo(() => ({
    color,
    height: size
  }), [color, size]);
  const dotStyle = useMemo(() => ({
    height: size,
    width: size
  }), [size]);
  return <div className="sk-spinner sk-three-bounce sk-fade-in" style={spinnerStyle}>
      <div key="1" style={dotStyle} />
      <div key="2" style={dotStyle} />
      <div key="3" style={dotStyle} />
    </div>;
};

const mapping = {
  [ActivityIndicatorType.circular]: ChasingDots,
  [ActivityIndicatorType.linear]: ThreeBounce
};
/**
 * An animated indicator indicating that an action is still executing.
 */

const PrActivityIndicator = ({
  color,
  size,
  style,
  type = ActivityIndicatorType.linear,
  ...otherProps
}) => {
  const {
    brandColors,
    iconSizes
  } = useAppTheme();
  const Component = mapping[type];
  return <_StyledView style={style} $_css={[styles.center, styles.marginDouble]}>
      <Component color={color ?? brandColors.primary} size={size ?? iconSizes.medium} {...otherProps} />
    </_StyledView>;
};

export default PrActivityIndicator;

var _StyledView = _styled(View).withConfig({
  displayName: "PrActivityIndicatorweb___StyledView",
  componentId: "sc-2ftv86-0"
})(["", ""], p => p.$_css);