import AsyncStorage from "@react-native-async-storage/async-storage"
import { Platform } from "react-native"

import Log from "services/Log"

let localStorageEnabled: boolean
try {
  localStorage.setItem("feature_test", "yes")
  if (localStorage.getItem("feature_test") === "yes") {
    localStorage.removeItem("feature_test")
    localStorageEnabled = true
  } else {
    localStorageEnabled = false
  }
} catch (e) {
  localStorageEnabled = false
}

// ================ Types ================

/**
 * The data that is stored between app sessions, and is made available to all other sessions
 */
export interface PersistentStorage {
  lastSigninEmail?: string // The last account email used to successfully signin
  membershipId?: string // The ID of the current membership
  organizationId?: string // The ID of the current organization
}

/**
 * The data that is stored while the app is open
 */
export interface SessionStorage {
  locale: string
}

/**
 * Sometimes localStorage is not available, in that case use an object as a fallback
 */
const fallbackPersistentStore: Record<string, string> = {}

/**
 * Fetcher for persisted items, which can live between different sessions of the app
 */
export const getPersistentItem = async (key: keyof PersistentStorage): Promise<null | string> => {
  const currentItem = await Platform.select({
    default: async (): Promise<null | string> => AsyncStorage.getItem(key),
    web: async (): Promise<null | string> =>
      localStorageEnabled ? localStorage.getItem(key) : fallbackPersistentStore[key] || null,
  })()

  try {
    // Get the value from storage
    const { expiry, value } = JSON.parse(currentItem ?? "{}")
    // Return the value if it's not expired
    return !expiry || expiry > Date.now() ? value : null
  } catch (e) {
    if (e instanceof Error) {
      Log.error("getPersistentItem Error", e)
    }
    return null
  }
}

/**
 * Setter for persisted items, which can live between different sessions of the app, and may expire
 */
export const setPersistentItem = async (
  key: keyof PersistentStorage,
  value?: string,
  expiry?: Date,
): Promise<void> => {
  await Platform.select({
    default: async () => {
      value
        ? await AsyncStorage.setItem(key, JSON.stringify({ expiry: expiry?.getTime(), value }))
        : await AsyncStorage.removeItem(key)
    },
    web: async () => {
      if (value) {
        const jsonValue = JSON.stringify({ expiry: expiry?.getTime(), value })
        if (localStorageEnabled) {
          localStorage.setItem(key, jsonValue)
        } else {
          fallbackPersistentStore[key] = jsonValue
        }
      } else {
        localStorageEnabled ? localStorage.removeItem(key) : delete fallbackPersistentStore[key]
      }
    },
  })()
}

// Native doesn't really have the concept of "refreshing a page", so it can just use an object as storage
const nativeSessionStore: Record<string, string> = {}

/**
 * Getter for data that lasts as long as the sessiopn does
 */
export const getSessionItem = async (key: keyof SessionStorage): Promise<null | string> => {
  return Platform.select({
    default: async (): Promise<null | string> => nativeSessionStore[key],
    web: async (): Promise<null | string> => sessionStorage.getItem(key),
  })()
}

/**
 * Setter for persisted items, which can live between different sessions of the app, and may expire
 */
export const setSessionItem = async (
  key: keyof SessionStorage,
  value?: string,
  expiry?: Date,
): Promise<void> => {
  await Platform.select({
    default: async () => {
      if (value) {
        nativeSessionStore[key] = JSON.stringify({ expiry: expiry?.getTime(), value })
      } else {
        delete nativeSessionStore[key]
      }
    },
    web: async () =>
      value
        ? sessionStorage.setItem(key, JSON.stringify({ expiry: expiry?.getTime(), value }))
        : sessionStorage.removeItem(key),
  })()
}
