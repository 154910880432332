import EventEmitter from "eventemitter3"
import { useCallback, useEffect, useState } from "react"
import { PersistentStorage, getPersistentItem, setPersistentItem } from "services/AsyncStorage"

const emitter = new EventEmitter()

/**
 * A hook that returns a LocalStorage value using the AsyncStorage service.
 * A tuple is returned with the value and a function to set the value in LocalStorage.
 */
const usePersistentStorage = function <K extends keyof PersistentStorage>(
  key: K,
): [undefined | PersistentStorage[K], (value: PersistentStorage[K]) => Promise<void>] {
  const setValueInStorage = useCallback(
    async (newValue: PersistentStorage[K]) => {
      setPersistentItem(key, newValue)
      emitter.emit(`${key}Updated`, newValue)
    },
    [key],
  )

  const [value, setValue] = useState<PersistentStorage[K]>()

  const updateValue = useCallback(async () => {
    const fetchedValue = await getPersistentItem(key)
    setValue((prevValue) => {
      if (prevValue === fetchedValue) {
        return prevValue
      } else {
        return fetchedValue === null ? undefined : fetchedValue
      }
    })
  }, [key])

  useEffect(() => {
    updateValue()
    emitter.on(`${key}Updated`, setValue)
    return () => {
      emitter.off(`${key}Updated`, setValue)
    }
  }, [key, setValue, updateValue])

  return [value, setValueInStorage]
}

export default usePersistentStorage
