/**
 * @generated SignedSource<<ae3cc03e8229c771dd5682d66464e800>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpContentSearchItem_resourceNode$data = {
  readonly names: any;
  readonly nodeType: string;
  readonly parentId: string | null;
  readonly resourceType: string | null;
  readonly revisionId: string;
  readonly rootNames: any;
  readonly rootRevisionId: string;
  readonly " $fragmentType": "CpContentSearchItem_resourceNode";
};
export type CpContentSearchItem_resourceNode$key = {
  readonly " $data"?: CpContentSearchItem_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpContentSearchItem_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpContentSearchItem_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "names",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "rootNames",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootRevisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resourceType",
      "storageKey": null
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "42dd5e8e74c59f923dd84c987cef6c3c";

export default node;
