/**
 * @generated SignedSource<<94948cc8dd8cb2714e627ec55db4d5d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpResourceNode_resourceNode$data = {
  readonly ancestors: ReadonlyArray<{
    readonly names: any;
    readonly revisionId: string;
    readonly trainingNodeCompletions: ReadonlyArray<{
      readonly resourceNodeRevisionId: string;
    }> | null;
  }>;
  readonly children: ReadonlyArray<{
    readonly resource: {
      readonly type: string;
    } | null;
    readonly type: string;
  }>;
  readonly id: string;
  readonly isLeaf: boolean;
  readonly medicationsNodeRevisionId: string | null;
  readonly names: any;
  readonly publisherId: string;
  readonly relatedNode: {
    readonly resource: {
      readonly localeIsMissing: boolean;
      readonly type: string;
      readonly " $fragmentSpreads": FragmentRefs<"CpResourceCommon_resource">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"CtTopicSubtreeNavigator_data">;
  } | null;
  readonly resource: {
    readonly _id: string;
    readonly localeIsMissing: boolean;
    readonly type: string;
    readonly " $fragmentSpreads": FragmentRefs<"CpResourceCommon_resource">;
  } | null;
  readonly revision: number;
  readonly revisionId: string;
  readonly rootRevisionId: string;
  readonly shortcodeMapping: any | null;
  readonly type: string;
  readonly " $fragmentType": "CpResourceNode_resourceNode";
};
export type CpResourceNode_resourceNode$key = {
  readonly " $data"?: CpResourceNode_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpResourceNode_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v1 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "localeIsMissing",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CpResourceCommon_resource"
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpResourceNode_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TrainingNodeCompletion",
          "kind": "LinkedField",
          "name": "trainingNodeCompletions",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "resourceNodeRevisionId",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "children",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLeaf",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "medicationsNodeRevisionId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publisherId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        (v4/*: any*/),
        (v3/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "relatedNode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            (v3/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtTopicSubtreeNavigator_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revision",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rootRevisionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortcodeMapping",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "222faca011693a47a08ecbe42a85a1bf";

export default node;
