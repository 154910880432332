import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconStethoscope: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M186 234a34.23 34.23 0 11-12 0v-27.53c-13.64-11.74-27.07-23.83-39.72-36.29a426.13 426.13 0 01-36.8-40.77C89.66 119.39 86 108.26 86 97.85a41.59 41.59 0 013.75-17.43 37.35 37.35 0 0111-13.87c6.81-5.32 15.74-8.54 26.46-8.54a41.88 41.88 0 0116.64 3.48 42.69 42.69 0 014.12 2 13 13 0 11-7.57 11.82 12.28 12.28 0 01.19-2.21c-.49-.25-1-.48-1.48-.69A29.84 29.84 0 00127.17 70c-7.92 0-14.35 2.25-19.1 6a25.64 25.64 0 00-7.45 9.46A29.69 29.69 0 0098 97.85c0 7.9 2.84 16.43 8.9 24.2q5.66 7.24 11.87 14.34L140.62 158a56.09 56.09 0 0078.76 0l21.85-21.61q6.21-7.11 11.87-14.34c6.06-7.77 8.9-16.3 8.9-24.2a29.69 29.69 0 00-2.62-12.42 25.64 25.64 0 00-7.45-9.43c-4.75-3.71-11.18-6-19.1-6a29.84 29.84 0 00-11.9 2.45c-.5.21-1 .44-1.48.69a12.28 12.28 0 01.19 2.21 13 13 0 11-7.57-11.82 42.69 42.69 0 014.12-2A41.88 41.88 0 01232.83 58c10.72 0 19.65 3.22 26.46 8.54a37.35 37.35 0 0111 13.87A41.43 41.43 0 01274 97.85c0 10.41-3.66 21.54-11.48 31.56a426.13 426.13 0 01-36.8 40.77c-12.65 12.46-26.08 24.55-39.72 36.29V234zm-24.52-54.55q9.12 8.3 18.52 16.4 9.38-8.1 18.52-16.4a64.64 64.64 0 01-37 0zM195.74 252a22.26 22.26 0 106.52 15.74 22.18 22.18 0 00-6.52-15.74z" />
      <Path
        d="M180 254.16a13.57 13.57 0 11-13.56 13.57A13.57 13.57 0 01180 254.16z"
        fillRule="evenodd"
      />
    </Svg>
  )
}

export default IconStethoscope
