import Color from "color";
import { Platform } from "react-native";
import { css } from "styled-components/native"; // To avoid circular dependency

import { PatientSessionStyles } from "components/patientSession/PatientSession";
export default {
  /**
   * These styles are common across multiple files within certain contexts
   * E.g. different Navigation Header components, Patient/Package session components, etc.
   */
  formStyles: {
    readonlyLabel: css(["color:", ";font-size:", ";font-weight:bold;padding-top:", "px;"], ({
      theme
    }) => theme.formColors.fieldLabelInactive, ({
      theme
    }) => theme.fontSize("small"), ({
      theme
    }) => theme.spacing.half),
    readonlyText: css(["flex:1;font-family:", ";font-size:", ";outline-width:0px;padding-vertical:", "px;"], ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("large"), ({
      theme
    }) => theme.spacing.single)
  },
  navHeaderStyles: {
    callToActionButton: css(["border-radius:", "px;box-shadow:0px 1px 3px ", ";margin-horizontal:", "px;padding:", "px;"], ({
      theme
    }) => theme.borderRadii.default, ({
      theme
    }) => theme.brandColors.primary, ({
      theme
    }) => theme.spacing.single, ({
      theme
    }) => theme.spacing.quarter),
    callToActionText: css(["color:", ";font-family:", ";font-size:", ";line-height:115%;max-width:100px;text-align:center;"], ({
      theme
    }) => theme.brandColors.primary, ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("small")),
    changeRegionButtonText: css(["color:", ";font-size:", ";font-weight:normal;"], ({
      theme
    }) => theme.textColors.default, ({
      theme
    }) => theme.fontSize("medium")),
    changeRegionLabel: css(["padding-vertical:", "px;text-align:center;"], ({
      theme
    }) => theme.spacing.single),
    container: css(["background-color:", ";box-shadow:0 4px 6px rgba(0,0,0,0.15);"], ({
      theme
    }) => theme.brandColors.navHeaderBackground),
    darkModePopup: css(["border:2px solid ", ";padding-bottom:", "px;"], ({
      theme
    }) => theme.buttonColors.primary, ({
      theme
    }) => theme.spacing.single),
    // Button has hardcoded padding that makes it bloat the parent container
    // Attempting to counter that with negative margin to align it with SignIn
    languageButton: css(["align-items:flex-start;justify-content:center;min-width:", "px;"], ({
      theme
    }) => theme.buttonHeights.medium),
    languageCode: css(["background-color:", ";border-radius:", "px;color:", ";font-family:", ";font-size:", ";letter-spacing:0.55px;line-height:", "px;padding:", "px;text-align:center;text-transform:uppercase;width:38px;"], ({
      theme
    }) => theme.buttonColors.primary, ({
      theme
    }) => theme.borderRadii.default, ({
      theme
    }) => theme.buttonColors.text, ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("medium"), ({
      theme
    }) => theme.borderRadii.soft * 2, ({
      theme
    }) => theme.spacing.half),
    languageMenuItem: css(["align-items:center;flex-direction:row;justify-content:space-between;padding-horizontal:", "px;"], ({
      theme
    }) => theme.spacing.single),
    languageSupportedLabel: css(["font-family:", ";font-size:", ";"], ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("large")),
    logo: css(["margin-horizontal:", "px;padding:0px;width:\"fit-content\";"], ({
      theme
    }) => theme.spacing.single),
    logoAndMenu: css(["flex-direction:row;"]),
    mutedSecondaryButton: css(["align-items:flex-start;justify-content:center;min-width:", "px;"], ({
      theme
    }) => theme.buttonHeights.medium),
    navButton: css(["margin-horizontal:", "px;"], ({
      theme
    }) => theme.spacing.quarter),
    navButtonText: css(["font-size:", ";color:", ";"], ({
      theme
    }) => theme.fontSize("small"), ({
      theme
    }) => theme.brandColors.primary),
    navMenu: css(["flex-direction:row;"]),
    popupBorder: css(["border:2px solid ", ";"], ({
      theme
    }) => theme.buttonColors.primary),
    row1ButtonContainer: css(["flex-direction:row;align-items:flex-end;"]),
    row1Container: css(["align-items:center;justify-content:space-between;flex-direction:row;padding:", "px;"], ({
      theme
    }) => theme.spacing.single),
    row1SearchInput: css(["justify-self:center;flex:1;margin-horizontal:", "px;max-width:30em;"], ({
      theme
    }) => theme.spacing.double),
    row2Container: css(["align-items:center;margin-bottom:", "px;padding-horizontal:", "px;"], ({
      theme
    }) => theme.spacing.half, ({
      theme
    }) => theme.spacing.double),
    row2SearchInput: css(["width:100%;"]),
    secondarySearchTextInput: css(["background-color:", ";"], ({
      theme
    }) => theme.textColors.textInputSecondaryBackground)
  },
  sessionStyles: PatientSessionStyles,
  termsAndPoliciesStyles: {
    date: css(["color:", ";font-size:", ";font-style:italic;padding-top:", "px;"], ({
      theme
    }) => theme.textColors.muted, ({
      theme
    }) => theme.fontSize("small"), ({
      theme
    }) => theme.spacing.half),
    fullscreenButtonContainer: css(["align-self:flex-start;background-color:", ";min-height:", "px;width:100%;"], ({
      theme
    }) => theme.brandColors.surface, ({
      theme
    }) => theme.buttonHeights.large),
    fullscreenCloseButton: css(["flex-direction:row;align-items:center;align-self:flex-end;padding:", "px;padding-right:", "px;height:100%;"], ({
      theme
    }) => theme.spacing.single, ({
      theme
    }) => theme.spacing.double),
    fullscreenCloseText: css(["font-family:", ";font-size:", ";padding-horizontal:", "px;"], ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("large"), ({
      theme
    }) => theme.spacing.double),
    heading: css(["font-family:", ";font-size:", ";font-weight:bold;padding:", "px;"], ({
      theme
    }) => theme.fontFamilies.heading, ({
      theme
    }) => theme.fontSize("xxlarge"), ({
      theme
    }) => theme.spacing.double),
    homeButtonText: css(["color:", ";font-family:", ";font-size:", ";"], ({
      theme
    }) => theme.buttonColors.primary, ({
      theme
    }) => theme.fontFamilies.button, ({
      theme
    }) => theme.fontSize("large")),
    hoveredText: css(["text-decoration:underline;"]),
    iconContainer: css(["align-items:center;justify-content:center;padding-left:", "px;"], ({
      theme
    }) => theme.spacing.single),
    pdfModalContainer: css(["background-color:", ";flex:1;"], ({
      theme
    }) => theme.brandColors.surface),
    pdfModalInnerContainer: css(["height:100%;padding:", "px;width:100%;"], ({
      theme
    }) => theme.spacing.double)
  },

  /**
   * The following are generic styles used across the codebase
   */
  accountIdentifier: css(["font-size:", ";font-weight:bold;margin-vertical:", "px;text-align:center;"], ({
    theme
  }) => theme.fontSize("xlarge"), ({
    theme
  }) => theme.spacing.single),
  adminLimitedLargeScreenWidth: css(["margin-horizontal:auto;width:1080px;"]),
  advertisementText: css(["font-size:", ";font-weight:bold;"], ({
    theme
  }) => theme.fontSize("xsmall")),
  alignItemsCenter: css(["align-items:center;"]),
  alignSelf: css(["align-self:center;"]),
  boldText: css(["font-weight:bold;"]),
  buttonFont: css(["font-family:", ";"], ({
    theme
  }) => theme.fontFamilies.button),
  buttonText: css(["font-size:", ";font-weight:normal;padding-horizontal:", "px;"], ({
    theme
  }) => theme.fontSize("medium"), ({
    theme
  }) => theme.spacing.quadruple),
  capitalise: css(["text-transform:capitalise;"]),
  center: css(["align-items:center;justify-content:center;"]),
  column: css(["flex-direction:column;"]),
  displayNone: css(["display:none;"]),
  errorBox: css(["align-items:center;background-color:", ";border-color:", ";border-radius:", "px;border-width:1px;color:", ";margin-vertical:", "px;padding:", "px;"], ({
    theme
  }) => theme.brandColors.errorBackground, ({
    theme
  }) => theme.textColors.error, ({
    theme
  }) => theme.borderRadii.sharp, ({
    theme
  }) => theme.textColors.error, ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.spacing.single),
  // This style works for displaying a single simple FAB button, any additional FAB buttons will require overriding `bottom` to ensure positioning is correct
  fab: css(["bottom:", "px;position:absolute;right:", "px;z-index:", ";"], ({
    theme
  }) => theme.spacing.quadruple, ({
    theme
  }) => theme.spacing.triple, ({
    theme
  }) => theme.layers.sixthLayer),
  fade: css(["align-self:center;background-image:linear-gradient( ", " 0%,", " 60%,", " 100% );position:absolute;z-index:", ";"], ({
    theme
  }) => theme.brandColors.surface, ({
    theme
  }) => Color(theme.brandColors.surface).alpha(0.6).string(), ({
    theme
  }) => Color(theme.brandColors.surface).alpha(0).string(), ({
    theme
  }) => theme.layers.thirdLayer),
  flexAndCenter: css(["align-items:center;flex:1;justify-content:center;"]),
  flexEnd: css(["align-items:flex-end;"]),
  flexSingle: css(["flex:1;"]),
  fontSizeSmall: css(["font-size:", ";"], ({
    theme
  }) => theme.fontSize("small")),
  formField: css(["margin-vertical:", "px;"], ({
    theme
  }) => theme.spacing.single),
  fullHeight: css(["height:100%;"]),
  fullWidth: css(["width:100%;"]),
  headingFont: css(["font-family:", ";"], ({
    theme
  }) => theme.fontFamilies.heading),
  italicText: css(["font-style:italic;"]),
  justifyFlexEnd: css(["justify-content:flex-end;"]),
  limitedLargeScreenWidth: css(["margin-horizontal:auto;width:720px;"]),
  limitedWidth: css(["margin-horizontal:auto;min-width:", "px;max-width:640px;"], Platform.select({
    web: 640
  })),
  marginBottomDouble: css(["margin-bottom:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginBottomSingle: css(["margin-bottom:", "px;"], ({
    theme
  }) => theme.spacing.single),
  marginDouble: css(["margin:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginHorizontalDouble: css(["margin-horizontal:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginHorizontalSingle: css(["margin-horizontal:", "px;"], ({
    theme
  }) => theme.spacing.single),
  marginLeftDouble: css(["margin-left:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginRightDouble: css(["margin-right:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginSingle: css(["margin:", "px;"], ({
    theme
  }) => theme.spacing.single),
  marginTopDouble: css(["margin-top:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginTopSingle: css(["margin-top:", "px;"], ({
    theme
  }) => theme.spacing.single),
  marginVerticalDouble: css(["margin-vertical:", "px;"], ({
    theme
  }) => theme.spacing.double),
  marginVerticalHalf: css(["margin-vertical:", "px;"], ({
    theme
  }) => theme.spacing.half),
  marginVerticalSingle: css(["margin-vertical:", "px;"], ({
    theme
  }) => theme.spacing.single),
  modalCard: css(["background-color:", ";max-width:640px;margin:", "px;"], ({
    theme
  }) => theme.brandColors.surface, ({
    theme
  }) => theme.spacing.double),
  modalContainer: css(["max-width:650px;"]),
  modalEmailText: css(["font-style:italic;font-weight:bold;word-wrap:anywhere;"]),
  modalHeaderText: css(["align-self:center;font-family:", ";font-size:", ";font-weight:bold;padding-vertical:", "px;"], ({
    theme
  }) => theme.fontFamilies.heading, ({
    theme
  }) => theme.fontSize("xlarge"), ({
    theme
  }) => theme.spacing.single),
  modalIcon: css(["margin-right:", "px;min-width:", "px;"], ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.iconSizes.large),
  navDrawerAccountEmail: css(["color:#fff;padding:", "px;"], ({
    theme
  }) => theme.spacing.single),
  navDrawerAccountIcon: css(["margin-left:", "px;margin-top:", "px;"], ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.spacing.single),
  navDrawerAccountImage: css(["border-color:#fff;border-radius:72px;border-width:2px;height:144px;margin-left:", "px;margin-top:", "px;width:144px;"], ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.spacing.single),
  navDrawerCloseButton: css(["align-items:flex-end;height:52px;justify-content:center;position:absolute;right:0px;top:0px;width:52px;"]),
  noResults: css(["padding-top:", "px;padding-horizontal:", "px;color:", ";"], ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.textColors.error),
  paddingBottomDouble: css(["padding-bottom:", "px;"], ({
    theme
  }) => theme.spacing.double),
  paddingBottomQuadruple: css(["padding-bottom:", "px;"], ({
    theme
  }) => theme.spacing.quadruple),
  paddingDouble: css(["padding:", "px;"], ({
    theme
  }) => theme.spacing.double),
  paddingHalf: css(["padding:", "px;"], ({
    theme
  }) => theme.spacing.half),
  paddingHorizontalDouble: css(["padding-horizontal:", "px;"], ({
    theme
  }) => theme.spacing.double),
  paddingHorizontalNone: css(["padding-left:0;"]),
  paddingHorizontalSingle: css(["padding-horizontal:", "px;"], ({
    theme
  }) => theme.spacing.single),
  paddingNone: css(["padding:0px;"]),
  paddingQuadruple: css(["padding:", "px;"], ({
    theme
  }) => theme.spacing.quadruple),
  paddingSingle: css(["padding:", "px;"], ({
    theme
  }) => theme.spacing.single),
  paddingTopDouble: css(["padding-top:", "px;"], ({
    theme
  }) => theme.spacing.double),
  paddingTopHalf: css(["padding-top:", "px;"], ({
    theme
  }) => theme.spacing.half),
  paddingTopSingle: css(["padding-top:", "px;"], ({
    theme
  }) => theme.spacing.single),
  paddingVerticalDouble: css(["padding-vertical:", "px;"], ({
    theme
  }) => theme.spacing.double),
  paddingVerticalSingle: css(["padding-vertical:", "px;"], ({
    theme
  }) => theme.spacing.single),
  pageHeading: css(["font-family:", ";font-size:", ";font-weight:bold;padding:", "px;"], ({
    theme
  }) => theme.fontFamilies.heading, ({
    theme
  }) => theme.fontSize("xxlarge"), ({
    theme
  }) => theme.spacing.triple),
  popupBorder: css(["border:2px solid ", ";"], ({
    theme
  }) => theme.buttonColors.primary),
  row: css(["flex-direction:row;"]),
  rowAndCenter: css(["flex-direction:row;align-items:center;"]),
  rowAndWrap: css(["flex-direction:row;flex-wrap:wrap;"]),
  rowReverse: css(["flex-direction:row-reverse;"]),
  screen: css(["background-color:", ";"], ({
    theme
  }) => theme.brandColors.surface),
  screenModal: css(["background-color:", ";border-radius:", "px;margin-horizontal:auto;margin-vertical:", "px;"], ({
    theme
  }) => theme.brandColors.surface, ({
    theme
  }) => theme.borderRadii.soft, ({
    theme
  }) => theme.spacing.double),
  screenSpinner: css(["margin:", "px;"], ({
    theme
  }) => theme.spacing.quadruple),
  scroll: css(["overflow-y:auto;"]),
  scrollModal: css(["max-width:95vw;max-height:85vh;"]),
  scrollWithFab: css(["z-index:", ";"], ({
    theme
  }) => theme.layers.firstLayer),
  selected: css(["background-color:", ";"], ({
    theme
  }) => theme.brandColors.selected),
  shrink: css(["flex-shrink:1;"]),
  smallCapsMenuHeader: css(["align-self:center;font-size:", ";letter-spacing:0.55px;padding:", "px;padding-top:", "px;text-transform:uppercase;"], ({
    theme
  }) => theme.fontSize("small"), ({
    theme
  }) => theme.spacing.single, ({
    theme
  }) => theme.spacing.double),
  storyGroup: css(["border-color:#ccc;border-width:1px;margin-vertical:", "px;padding:", "px;"], ({
    theme
  }) => theme.spacing.half, ({
    theme
  }) => theme.spacing.half),
  storyLabel: css(["background-color:#eef;color:#666;margin-vertical:0px;padding:", "px;"], ({
    theme
  }) => theme.spacing.single),
  termsLinkText: css(["color:", ";font-size:", ";font-weight:400;text-align:center;text-decoration-color:", ";"], ({
    theme
  }) => theme.textColors.link, ({
    theme
  }) => theme.fontSize("small"), ({
    theme
  }) => theme.textColors.link),
  textAlignCenter: css(["text-align:center;"]),
  textHeader1: css(["color:", ";font-family:", ";font-size:", ";font-weight:bold;margin-bottom:", "px;"], ({
    theme
  }) => theme.brandColors.primary, ({
    theme
  }) => theme.fontFamilies.heading, ({
    theme
  }) => theme.fontSize("xlarge"), ({
    theme
  }) => theme.spacing.single),
  textHeader2: css(["color:", ";font-family:", ";font-size:", ";font-weight:bold;margin-bottom:", "px;"], ({
    theme
  }) => theme.brandColors.primary, ({
    theme
  }) => theme.fontFamilies.heading, ({
    theme
  }) => theme.fontSize("medium"), ({
    theme
  }) => theme.spacing.single),
  // Uppercase words require additional letter spacing to ensure legibility
  uppercase: css(["letter-spacing:0.55px;text-transform:uppercase;"]),
  verticalText: css(["transform:rotate(-180deg);writing-mode:vertical-lr;"]),
  viewOverlay: css(["align-items:center;background-color:", ";justify-content:center;height:100%;padding:", "px;width:100%;"], ({
    theme
  }) => theme.brandColors.screenOverlay, ({
    theme
  }) => theme.spacing.double)
};