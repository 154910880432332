import * as React from "react"
import Svg, { Circle, Path, SvgProps } from "react-native-svg"

// Iconset: Custom
const IconBreastCancer: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 360 360" width={height} {...otherProps}>
      <Path d="M260.26 152.8a55.07 55.07 0 00-55-55H186a55.25 55.25 0 01-55.22-55.24h-6.24A61.49 61.49 0 00186 104h19.25A49 49 0 01254 153.27v.06c6.06 34.72 4.1 68.06 2 103.36-1 17.61-2 35.87-2 54.13h6.24c0-18.11 1-36.22 2.06-53.74 2.02-34.08 4.1-69.39-2.04-104.28z" />
      <Path d="M202.41 194.32l-.47-.94a3.12 3.12 0 00-.59-.81 15.47 15.47 0 01-3.75-15.92l2.18-6.54a3.13 3.13 0 00-5.93-2l-2.17 6.55a21.67 21.67 0 004.9 21.93l.28.55c10.14 19.86 17.86 45.26 5 63.86-.09.11-.18.22-.26.34a42.49 42.49 0 01-12.26 11.33C163.69 288.75 130.59 284 100.82 260a3.12 3.12 0 00-4.09 4.71l.17.14c19 15.39 40 23.3 59.72 23.3a67.07 67.07 0 0036-10.21c1.41-.88 2.72-1.82 4-2.78-4.61 13.56-11 33.21-11.36 39a3.12 3.12 0 002.92 3.31h.2a3.12 3.12 0 003.11-2.92c.28-4.46 5.76-21.79 11-37.34.82 10.31 1.21 24.12-1.4 32.76a3.13 3.13 0 006 1.8c4.5-14.87 1.11-41.88.28-47.89 11.22-17 9.63-40.94-4.96-69.56z" />
      <Circle cx={167.66} cy={235.87} r={7.96} />
    </Svg>
  )
}

export default IconBreastCancer
