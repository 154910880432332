import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useRef, useState } from "react";
import { FlatList, View } from "react-native";
import { thumbnailButtonSizes } from "components/common/CpThumbnailItem";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import useAppTheme from "hooks/useAppTheme";
import useScreenLayout from "hooks/useScreenLayout";
import { css } from "services/Theme";
const buttonBaseStyle = css`
  align-items: center;
  background-color: ${({
  theme
}) => theme.brandColors.surface};
  border: 1px solid ${({
  theme
}) => theme.brandColors.separator};
  height: 100%;
  justify-content: center;
  width: ${({
  theme
}) => theme.buttonHeights.small}px;
`;
const localStyles = {
  carouselContainer: css`
    background-color: ${({
    theme
  }) => theme.brandColors.background};
    flex-direction: row;
  `,
  sideNextButton: css`
    ${buttonBaseStyle};
    border-bottom-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-left-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `,
  sidePreviousButton: css`
    ${buttonBaseStyle};
    border-bottom-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
    border-top-right-radius: ${({
    theme
  }) => theme.borderRadii.default}px;
  `
};

/**
 * A horizontal list where the items are rendered as tiles with thumbnail images
 * Also provides navigational buttons to the left and right of the inner content that allow for scrolling to see remaining elements.
 * NB: Intended for use on larger screens as the component expects to render a list of thumbnail items of type `medium` with an included `single` spacing on either side -- This allows the flatlist to accurately calculate the width of each item rendered in the list
 */
const CpThumbnailRibbon = ({
  style,
  ...otherProps
}) => {
  const {
    buttonColors,
    iconSizes,
    spacing
  } = useAppTheme();
  const {
    isWideScreen
  } = useScreenLayout();
  const [layout, setLayout] = useState({
    height: 0,
    width: 0,
    x: 0,
    y: 0
  });
  const handleLayout = useCallback(({
    nativeEvent
  }) => {
    setLayout(nativeEvent.layout);
  }, []);
  const flatListRef = useRef(null);
  const flatListNext = useCallback(() => {
    flatListRef.current?.scrollToOffset({
      animated: true,
      offset: layout.width
    });
  }, [layout.width]);
  const flatListPrevious = useCallback(() => {
    flatListRef.current?.scrollToOffset({
      animated: true,
      offset: -layout.width
    });
  }, [layout.width]);
  return <_StyledView style={style} $_css={localStyles.carouselContainer}>
      <_StyledPrPressable onPress={flatListPrevious} $_css2={localStyles.sidePreviousButton}>
        <PrIcon fill={buttonColors.primary} icon={IconName.keyboardArrowLeft} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
      </_StyledPrPressable>

      <FlatList getItemLayout={(_data, index) => ({
      length: thumbnailButtonSizes.mediumButton.width + spacing.double,
      offset: (thumbnailButtonSizes.mediumButton.width + spacing.double) * index,
      index
    })} horizontal={true} onLayout={handleLayout} ref={flatListRef} {...otherProps} />

      <_StyledPrPressable2 onPress={flatListNext} $_css3={localStyles.sideNextButton}>
        <PrIcon fill={buttonColors.primary} icon={IconName.keyboardArrowRight} size={isWideScreen ? iconSizes.large : iconSizes.medium} />
      </_StyledPrPressable2>
    </_StyledView>;
};

export default CpThumbnailRibbon;

var _StyledView = _styled(View).withConfig({
  displayName: "CpThumbnailRibbon___StyledView",
  componentId: "sc-13l8oqk-0"
})(["", ""], p => p.$_css);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpThumbnailRibbon___StyledPrPressable",
  componentId: "sc-13l8oqk-1"
})(["", ""], p => p.$_css2);

var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpThumbnailRibbon___StyledPrPressable2",
  componentId: "sc-13l8oqk-2"
})(["", ""], p => p.$_css3);