var _CpResourceNode_resourceNode, _CpResourceNodeTrainingNodeCompletionCreateMutation;

import React, { useCallback, useContext, useEffect } from "react";
import { graphql, useFragment, useMutation } from "react-relay/hooks";
import { PatientSessionActions } from "components/patientSession/PatientSession";
import CpPresentationNavigator from "components/topic/CpPresentationNavigator";
import CpResourceNodeContent from "components/topic/CpResourceNodeContent";
import CxPresentationSupport from "contexts/CxPresentationSupport";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import { QlTopic_comboFragment } from "query/QlTopic";
import { AppTool } from "services/Constants";
import { getNameVariant } from "services/Graphql";
import Log from "services/Log";
const resourceNodeFragment = _CpResourceNode_resourceNode !== void 0 ? _CpResourceNode_resourceNode : _CpResourceNode_resourceNode = require("./__generated__/CpResourceNode_resourceNode.graphql");
const trainingNodeCompletionCreateMutation = _CpResourceNodeTrainingNodeCompletionCreateMutation !== void 0 ? _CpResourceNodeTrainingNodeCompletionCreateMutation : _CpResourceNodeTrainingNodeCompletionCreateMutation = require("./__generated__/CpResourceNodeTrainingNodeCompletionCreateMutation.graphql");
/**
 * List of resources not intended to be automatically selected for Patient Sessions
 */

const SkipPatientSessionResource = ["Estilo de vida", "Lifestyle", "Mode de vie", "Videos", "Vidéos", "Comprendre", "Entendiendo", "Understanding", "Related Topics", "Sujets connexes", "Temas relacionados", "Diagramas de apoyo", "Diagrammes de soutien", "Supporting Diagrams", "Health & Wellness", "Salud y Bienestar", "Santé et bien-être", "Herramientas profesionales", "Outils professionnels", "Professional Tools", "Medicamentos", "Medications", "Médicaments"];

/**
 * Renders a resource node, and also handles updating CxPresentationSupport for relevant topics.
 */
const CpResourceNode = ({
  comboKey,
  style,
  ...otherProps
}) => {
  const {
    appConfig,
    user
  } = useImdSession();
  const patientEducationTool = appConfig?.appTools?.find(tool => tool.name === AppTool.Educate);
  const isPatientEducationEnabled = patientEducationTool?.status === "active" || !patientEducationTool;
  const {
    // Defaulting to -1 allows us to run the useEffect logic without a chance of matching anything should currentIndex be undefined
    currentIndex = -1,
    presentationCompletion,
    presentationIds,
    setPresentationContext
  } = useContext(CxPresentationSupport);
  const {
    dispatchPatientSession,
    patientSessionData
  } = usePatientSessionContext();
  const comboData = useFragment(QlTopic_comboFragment, comboKey);
  const selectedResourceNodeData = useFragment(resourceNodeFragment, comboData); // TrainingNodeCompletion Mutation

  const [submitTrainingNodeCompletion] = useMutation(trainingNodeCompletionCreateMutation);
  const handleTrainingNodeCompletionCreateMutation = useCallback(({
    resourceNodeRevisionId
  }) => {
    submitTrainingNodeCompletion({
      onCompleted: () => {
        dispatchPatientSession({
          payload: true,
          type: PatientSessionActions.SetTrainingNodeViewed
        });
      },
      onError: error => Log.error("CpResourceNode - Failed to create trainingNodeCompletion", error),
      variables: {
        input: {
          resourceNodeRevisionId
        }
      }
    });
  }, [dispatchPatientSession, submitTrainingNodeCompletion]); // Update CxPresentationSupport

  useEffect(() => {
    const revisionId = selectedResourceNodeData?.revisionId; // Is this the initial visit to the presentation, or have we loaded a new resource?

    if (presentationIds.includes(revisionId) && (presentationIds[currentIndex] !== revisionId || !presentationCompletion[revisionId])) {
      Log.info("CpResourceNode - Updating CxPresentationSupport for selected resource: ", selectedResourceNodeData);
      const localCompletion = presentationCompletion;
      localCompletion[revisionId] = true; // The first ancestor should be the root, which contains the array of trainingNodeCompletions to check against

      const trainingNodeCompletions = selectedResourceNodeData?.ancestors?.[0]?.trainingNodeCompletions;
      const isTrainingCompleted = Boolean(trainingNodeCompletions?.find(completion => completion.resourceNodeRevisionId === revisionId)); // Is this an actual user, and a training node that hasn't been completed yet?

      if (selectedResourceNodeData.type === "ResourceNode::Training" && !user?.isKiosk && !isTrainingCompleted) {
        Log.info("CpResourceNode - Creating trainingNodeCompletion for selected resource: ", selectedResourceNodeData);
        handleTrainingNodeCompletionCreateMutation({
          resourceNodeRevisionId: revisionId
        });
      } // Update context


      setPresentationContext({
        currentIndex: presentationIds.indexOf(revisionId),
        presentationCompletion: localCompletion,
        presentationIds
      });
    }
  }, [currentIndex, handleTrainingNodeCompletionCreateMutation, presentationCompletion, presentationIds, selectedResourceNodeData, setPresentationContext, user?.isKiosk]); // Add the resource to the list of viewedResources - Root topics will be added in CpTopic

  useEffect(() => {
    // Guard - only add a resource to a patient education session if:
    // - It is an authenticated non-kiosk/patient user
    // - The education tool is not disabled
    if (user && !user.isPatient && isPatientEducationEnabled) {
      const resourceName = getNameVariant(selectedResourceNodeData) ?? "";
      dispatchPatientSession({
        // By default we want resources to be `selected` for PatientSessions
        // Unless they are included in the SkipPatientSessionResource list
        payload: {
          isTopic: false,
          revisionId: selectedResourceNodeData.revisionId,
          selected: !SkipPatientSessionResource.includes(resourceName)
        },
        type: PatientSessionActions.AddResource
      });
    }
  }, [dispatchPatientSession, patientSessionData.isPatientSession, user, patientEducationTool, isPatientEducationEnabled, selectedResourceNodeData]); // Data guard

  if (comboKey && !selectedResourceNodeData) {
    return null;
  }

  return ["ResourceNode::Presentation", "ResourceNode::Training"].includes(selectedResourceNodeData.type) ? <CpPresentationNavigator style={style}>
      <CpResourceNodeContent medicationsNodeRevisionId={selectedResourceNodeData?.medicationsNodeRevisionId} resourceNodeKey={comboData} selectedResourceNodeData={selectedResourceNodeData} {...otherProps} />
    </CpPresentationNavigator> : <CpResourceNodeContent medicationsNodeRevisionId={selectedResourceNodeData?.medicationsNodeRevisionId} resourceNodeKey={comboData} selectedResourceNodeData={selectedResourceNodeData} style={style} {...otherProps} />;
};

export default CpResourceNode;