/**
 * Types a token as being in or not in an enum
 *
 * @example
 * // Actor 1: The enum to be checked
 * enum AnEnum { Value = "value", Another = "another" }
 *
 * // Actor 2: The function that accepts only enum
 * function processEnum(input: AnEnum): AnEnum { return AnEnum[input] }
 *
 * // Actor 3: The token that might be in the enum
 * if (isInEnum(AnEnum, someString)) processEnum(someString)
 */
export const isInEnum = <T extends { [key: string]: unknown }>(
  someEnum: T,
  token: unknown,
): token is T[keyof T] => Object.values(someEnum).includes(token)
