import React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

// Iconset: Material - arrow-drop-up
const ArrowDropUp: React.FC<SvgProps> = ({ height, ...otherProps }) => {
  return (
    <Svg height={height} viewBox="0 0 32 32" width={height} {...otherProps}>
      <Path d="M9.313 18.688l6.688-6.688 6.688 6.688h-13.375z" />
    </Svg>
  )
}

export default ArrowDropUp
