import _styled15 from "styled-components";
import _styled14 from "styled-components";
import _styled13 from "styled-components";
import _styled12 from "styled-components";
import _styled11 from "styled-components";
import _styled10 from "styled-components";
import _styled9 from "styled-components";
import _styled8 from "styled-components";
import _styled7 from "styled-components";
import _styled6 from "styled-components";
import _styled5 from "styled-components";
import _styled4 from "styled-components";
import _styled3 from "styled-components";
import _styled2 from "styled-components";
import _styled from "styled-components";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ScrollView, View } from "react-native";
import CpAdSlot, { AdPlacement } from "components/common/CpAdSlot";
import CpAlert, { AlertType } from "components/common/CpAlert";
import CpError from "components/common/CpError";
import CpPatientSessionStats from "components/patientSession/CpPatientSessionStats";
import { ModalSteps, PatientSessionActions } from "components/patientSession/PatientSession";
import PrButton from "components/primitives/PrButton";
import PrIcon, { IconName } from "components/primitives/PrIcon";
import PrPressable from "components/primitives/PrPressable";
import PrText from "components/primitives/PrText";
import useAnalytics from "hooks/useAnalytics";
import useAppTheme from "hooks/useAppTheme";
import useImdSession from "hooks/useImdSession";
import usePatientSessionContext from "hooks/usePatientSessionContext";
import useScreenLayout from "hooks/useScreenLayout";
import { ScreenSizes } from "services/Constants";
import { GoogleAnalyticsDimension } from "services/GoogleAnalytics";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * Renders the entrypoint modal screen for a PatientSession with options for users to start or review their session.
 * Upon opening the active timer is paused, and resumed upon close, or reset on 'start'
 */
const CpPatientSessionStart = ({
  onClose,
  style
}) => {
  const {
    formatMessage
  } = useIntl();
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    appConfig,
    organization,
    user
  } = useImdSession();
  const {
    trackGoogleEvent
  } = useAnalytics();
  const {
    buttonColors,
    iconSizes
  } = useAppTheme();
  const {
    dispatchPatientSession,
    patientSessionData: {
      annotations,
      isPackageSession,
      isPatientSession,
      viewedResources
    }
  } = usePatientSessionContext();
  const [error, setError] = useState(); // Memoize to ensure ads show/hide if screen size changes

  const shouldDisplayAd = useMemo(() => isWideScreen && !appConfig?.appAdvertisement?.hideAds, [appConfig?.appAdvertisement?.hideAds, isWideScreen]); // Ad configuration and randomizer. Including guard to ensure we only need one check at render

  const patientSessionStartAd = useMemo(() => {
    // Guard
    if (!shouldDisplayAd) {
      return null;
    }

    const possibleAds = appConfig?.appAdvertisement?.adSpots?.filter(ad => ad.placement === AdPlacement.PatientSessionStart && ad.screenWidthIs.length <= 0 || ad.screenWidthIs.includes(ScreenSizes.IsWideScreen)) ?? [];

    if (possibleAds.length > 1) {
      //  Select from one of two possible ads we could show
      const randomAdIndex = Math.floor(Math.random() * 2);
      return possibleAds[randomAdIndex];
    } else if (possibleAds.length === 1) {
      return possibleAds[0];
    } else {
      return null;
    }
  }, [appConfig?.appAdvertisement?.adSpots, shouldDisplayAd]);
  const handleReviewAndSend = useCallback(() => {
    if (isPackageSession) {
      setError(formatMessage(translations.errors.otherSessionInProgress, {
        otherSession: formatMessage(translations.labels.patientInformationPackage)
      }));
    } else if (viewedResources.length === 0) {
      setError(formatMessage(translations.errors.nothingToReview));
    } else if (isPatientSession) {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.SessionReview
        },
        type: PatientSessionActions.SetModal
      });
    } else {
      dispatchPatientSession({
        payload: {
          modalStep: ModalSteps.SessionHistory
        },
        type: PatientSessionActions.SetModal
      });
    }
  }, [dispatchPatientSession, formatMessage, isPackageSession, isPatientSession, viewedResources.length]);
  const handlePressStart = useCallback(() => {
    if (isPackageSession) {
      setError(formatMessage(translations.errors.otherSessionInProgress, {
        otherSession: formatMessage(translations.labels.patientInformationPackage)
      }));
    } else {
      // Dispatching START will close the modal, and reset the timer and viewed resources
      dispatchPatientSession({
        type: PatientSessionActions.StartSession
      });
      trackGoogleEvent({
        action: "Started",
        name: "patientSession",
        options: {
          dimensions: {
            [GoogleAnalyticsDimension.organizationId]: organization?._id,
            [GoogleAnalyticsDimension.practitionerType]: user?.practitionerProfile?.type || undefined
          }
        }
      });
    }
  }, [dispatchPatientSession, formatMessage, isPackageSession, organization?._id, trackGoogleEvent, user]);
  const hasAnnotation = annotations.length > 0;
  return <_StyledScrollView style={style} $_css={styles.sessionStyles.scrollView}>
      <_StyledPrText accessibilityRole="header" children={translations.labels.patientEducationSession} $_css2={[styles.modalHeaderText, styles.paddingHorizontalSingle]} />
      <_StyledView $_css3={styles.paddingHorizontalSingle}>
        {isPatientSession ? // Render session details
      <CpPatientSessionStats showViewed={true} /> : // Generic starting point with instructions on getting started
      <View>
            <PrText>
              <FormattedMessage id="EruI9o" defaultMessage="Click {start} to begin a new Patient Session (this will clear your current history)." values={{
            start: <_StyledPrText2 children={translations.buttons.start} $_css4={styles.italicText} />
          }} />
            </PrText>
            <_StyledPrText3 $_css5={styles.paddingVerticalSingle}>
              <FormattedMessage id="VqnfDf" defaultMessage="Already begun, but forgot to hit {start}? Click {reviewAndSend} to select your session starting point based on your viewing history." values={{
            reviewAndSend: <_StyledPrText4 children={translations.buttons.reviewAndSend} $_css6={styles.italicText} />,
            start: <_StyledPrText5 children={translations.buttons.start} $_css7={styles.italicText} />
          }} />
            </_StyledPrText3>
            {hasAnnotation && !isPatientSession && <CpAlert icon={IconName.brush} message={<PrText>
                    <FormattedMessage id="I6FzZ7" defaultMessage="You have one or more drawings that are not linked to a Patient Education Session. Starting a session will destroy these drawings permanently. Select Review & Send to conclude your session without losing progress." />
                  </PrText>} type={AlertType.Warning} />}
          </View>}
        {
        /* Buttons to Start and Review & Send */
      }
        <_StyledView2 $_css8={styles.sessionStyles.start.buttonContainer}>
          <_StyledPrPressable disabled={isPatientSession} onPress={handlePressStart} testID="CpPatientSessionStart-start" $_css9={styles.sessionStyles.start.button}>
            <PrIcon fill={isPatientSession ? buttonColors.textInputDisabled : buttonColors.primary} icon={IconName.play} size={iconSizes.large} />
            <_StyledPrText6 children={translations.buttons.start} $_css10={[styles.sessionStyles.start.buttonText, isPatientSession && styles.sessionStyles.start.buttonTextDisabled]} />
          </_StyledPrPressable>
          <_StyledPrPressable2 onPress={handleReviewAndSend} testID="CpPatientSessionStart-review" $_css11={styles.sessionStyles.start.button}>
            <PrIcon fill={buttonColors.primary} icon={IconName.mail} size={iconSizes.large} />
            <_StyledPrText7 children={translations.buttons.reviewAndSend} $_css12={styles.sessionStyles.start.buttonText} />
          </_StyledPrPressable2>
        </_StyledView2>

        {patientSessionStartAd && <CpAdSlot adManagerId={patientSessionStartAd.adManagerId} adUnit={patientSessionStartAd.adUnitId} slot={patientSessionStartAd.slot} />}

        <_StyledCpError error={error} $_css13={styles.paddingVerticalSingle} />
        <_StyledView3 $_css14={styles.sessionStyles.start.closeButtonContainer}>
          <PrButton onPress={onClose} testID="CpPatientSessionStart-close" type="text">
            <_StyledPrText8 children={translations.buttons.close} $_css15={styles.sessionStyles.start.closeButtonText} />
          </PrButton>
        </_StyledView3>
      </_StyledView>
    </_StyledScrollView>;
};

export default CpPatientSessionStart;

var _StyledScrollView = _styled(ScrollView).withConfig({
  displayName: "CpPatientSessionStart___StyledScrollView",
  componentId: "sc-1ykx8ro-0"
})(["", ""], p => p.$_css);

var _StyledPrText = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText",
  componentId: "sc-1ykx8ro-1"
})(["", ""], p => p.$_css2);

var _StyledView = _styled(View).withConfig({
  displayName: "CpPatientSessionStart___StyledView",
  componentId: "sc-1ykx8ro-2"
})(["", ""], p => p.$_css3);

var _StyledPrText2 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText2",
  componentId: "sc-1ykx8ro-3"
})(["", ""], p => p.$_css4);

var _StyledPrText3 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText3",
  componentId: "sc-1ykx8ro-4"
})(["", ""], p => p.$_css5);

var _StyledPrText4 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText4",
  componentId: "sc-1ykx8ro-5"
})(["", ""], p => p.$_css6);

var _StyledPrText5 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText5",
  componentId: "sc-1ykx8ro-6"
})(["", ""], p => p.$_css7);

var _StyledView2 = _styled(View).withConfig({
  displayName: "CpPatientSessionStart___StyledView2",
  componentId: "sc-1ykx8ro-7"
})(["", ""], p => p.$_css8);

var _StyledPrPressable = _styled(PrPressable).withConfig({
  displayName: "CpPatientSessionStart___StyledPrPressable",
  componentId: "sc-1ykx8ro-8"
})(["", ""], p => p.$_css9);

var _StyledPrText6 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText6",
  componentId: "sc-1ykx8ro-9"
})(["", ""], p => p.$_css10);

var _StyledPrPressable2 = _styled(PrPressable).withConfig({
  displayName: "CpPatientSessionStart___StyledPrPressable2",
  componentId: "sc-1ykx8ro-10"
})(["", ""], p => p.$_css11);

var _StyledPrText7 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText7",
  componentId: "sc-1ykx8ro-11"
})(["", ""], p => p.$_css12);

var _StyledCpError = _styled(CpError).withConfig({
  displayName: "CpPatientSessionStart___StyledCpError",
  componentId: "sc-1ykx8ro-12"
})(["", ""], p => p.$_css13);

var _StyledView3 = _styled(View).withConfig({
  displayName: "CpPatientSessionStart___StyledView3",
  componentId: "sc-1ykx8ro-13"
})(["", ""], p => p.$_css14);

var _StyledPrText8 = _styled(PrText).withConfig({
  displayName: "CpPatientSessionStart___StyledPrText8",
  componentId: "sc-1ykx8ro-14"
})(["", ""], p => p.$_css15);