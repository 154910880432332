import { RouteProp } from "@react-navigation/native"
import { StackNavigationProp } from "@react-navigation/stack"
import React from "react"
import { SafeAreaView } from "react-native-safe-area-context"

import CpNotFound from "components/common/CpNotFound"
import Routes, { RouteParams } from "navigators/Routes"

interface ScNotFoundProps {
  navigation: StackNavigationProp<RouteParams, Routes.not_found>
  route: RouteProp<RouteParams, Routes.not_found>
}

/**
 * The 404 screen
 */
const ScNotFound: React.FC<ScNotFoundProps> = () => {
  return (
    <SafeAreaView>
      <CpNotFound />
    </SafeAreaView>
  )
}

export default ScNotFound
