import { formatDistance } from "date-fns"
import React, { useEffect, useState } from "react"
import { StyleProp, ViewStyle } from "react-native"

import PrText from "components/primitives/PrText"

interface CpCountdownProps {
  end: Date | number
  start?: Date | number
  style?: StyleProp<ViewStyle>
}

/**
 * Renders a human readable countdown timer representing the difference between two dates. Accepts an end time and optional start (defaults to the initial time of rendering).
 * Return value details: https://date-fns.org/v2.28.0/docs/formatDistance
 * Examples: "about 1 hour", "less than a minute", "less than 5 seconds"
 */
const CpCountdown: React.FC<CpCountdownProps> = ({ end, start, style }) => {
  const [countdown, setCountdown] = useState(start ?? Date.now())

  useEffect(() => {
    const reminderInterval = setInterval(() => {
      setCountdown(Date.now())
    }, 1000) // update every second
    return () => clearInterval(reminderInterval)
  }, [countdown])

  return (
    <PrText
      children={formatDistance(countdown, end, {
        includeSeconds: true,
      })}
      style={style}
    />
  )
}

export default CpCountdown
