/**
 * @generated SignedSource<<7a57fc2cb429ff452829ff484f120461>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpPublisherAttribution_resourceNode$data = {
  readonly resource: {
    readonly lastUpdated: string | null;
    readonly publisher: {
      readonly icon: string | null;
      readonly lastImported: string | null;
      readonly name: string;
      readonly termsOfUse: string | null;
    };
  } | null;
  readonly taggedResources: ReadonlyArray<{
    readonly _id: string;
    readonly lastUpdated: string | null;
    readonly publisher: {
      readonly icon: string | null;
      readonly lastImported: string | null;
      readonly name: string;
      readonly termsOfUse: string | null;
    };
  }> | null;
  readonly type: string;
  readonly updatedAt: string;
  readonly " $fragmentType": "CpPublisherAttribution_resourceNode";
};
export type CpPublisherAttribution_resourceNode$key = {
  readonly " $data"?: CpPublisherAttribution_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpPublisherAttribution_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v1 = {
  "alias": null,
  "args": (v0/*: any*/),
  "kind": "ScalarField",
  "name": "lastUpdated",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "publisher",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "icon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastImported",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "termsOfUse",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpPublisherAttribution_resourceNode",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "taggedResources",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "_id",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    }
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "b6abb41c888a3d204c4733463c0f04cc";

export default node;
