import { useContext } from "react";
import { StyledThemeContext } from "services/Theme";
/**
 * Convenience hook to get the current theme.
 */

const useAppTheme = () => {
  return useContext(StyledThemeContext);
};

export default useAppTheme;