var _QlPatientSessionResourceNodeQuery;

import React from "react";
import { graphql } from "react-relay";
import CtGraphqlQuery from "components/containers/CtGraphqlQuery";

/**
 * Used to fetch an array of ResourceNodes related to a Patient Session
 */
export const QlPatientSessionResourceNodeQueryRequest = _QlPatientSessionResourceNodeQuery !== void 0 ? _QlPatientSessionResourceNodeQuery : _QlPatientSessionResourceNodeQuery = require("./__generated__/QlPatientSessionResourceNodeQuery.graphql");

const QlPatientSession = ({
  children,
  variables
}) => {
  return <CtGraphqlQuery query={QlPatientSessionResourceNodeQueryRequest} variables={variables}>
      {children}
    </CtGraphqlQuery>;
};

export default QlPatientSession;