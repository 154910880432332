import _styled from "styled-components";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import CpUpdatePageTitle from "components/app/CpUpdatePageTitle";
import CtChangeAccountEmail from "components/containers/CtChangeAccountEmail";
import { styles } from "services/Theme";
import translations from "translations";

/**
 * The change account email screen
 */
const ScChangeAccountEmail = ({
  route
}) => {
  return <_StyledSafeAreaView $_css={styles.flexSingle}>
      <CpUpdatePageTitle title={translations.screens[route.name]} />
      <CtChangeAccountEmail />
    </_StyledSafeAreaView>;
};

export default ScChangeAccountEmail;

var _StyledSafeAreaView = _styled(SafeAreaView).withConfig({
  displayName: "ScChangeAccountEmail___StyledSafeAreaView",
  componentId: "sc-qd0pmq-0"
})(["", ""], p => p.$_css);