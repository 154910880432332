import { AppRegionContext, AppRegionContextType } from "contexts/CxAppRegion"
import { useContext } from "react"

/**
 * Returns various types as a value that can be used with a Text component.
 */
function useAppRegion(): AppRegionContextType {
  const appRegionContext = useContext(AppRegionContext)
  if (!appRegionContext) throw new Error("App Region Context is required")
  return appRegionContext
}

export default useAppRegion
