import { StyleSheet } from "react-native" // eslint-disable-line no-restricted-imports

export default StyleSheet.create({
  buttonShadow: {
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
  buttonShadowSelected: {
    shadowOffset: { height: 1, width: 0 },
    shadowOpacity: 0.7,
    shadowRadius: 14,
  },
  flexSingle: {
    flex: 1,
  },
  fullWidth: {
    width: "100%",
  },
  homeSearchTagLine: {
    textShadowColor: "black",
    textShadowOffset: { height: 1, width: 4 },
    textShadowRadius: 10,
  },
  menuShadow: {
    shadowColor: "black",
    shadowOffset: { height: 3, width: 0 },
    shadowOpacity: 0.33,
    shadowRadius: 3.33,
  },
})
