/**
 * @generated SignedSource<<51389492e2cc090f66f7be66c3ab1579>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Locale = "en" | "es" | "fr" | "%future added value";
export type CpCommonTopicsSectionQuery$variables = {
  id: string;
  locale?: Locale | null;
};
export type CpCommonTopicsSectionQuery$data = {
  readonly node: {
    readonly commonTopics?: ReadonlyArray<{
      readonly _id: string;
      readonly icon: string | null;
      readonly names: any;
      readonly resourceNodeRevisionId: string;
    }>;
  } | null;
};
export type CpCommonTopicsSectionQuery = {
  response: CpCommonTopicsSectionQuery$data;
  variables: CpCommonTopicsSectionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "locale"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "resourceNodeRevisionId",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "locale",
    "variableName": "locale"
  }
],
v5 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v4/*: any*/),
  "kind": "ScalarField",
  "name": "icon",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CpCommonTopicsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommonTopic",
                "kind": "LinkedField",
                "name": "commonTopics",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "GraphqlQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CpCommonTopicsSectionQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CommonTopic",
                "kind": "LinkedField",
                "name": "commonTopics",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01d7c5d148e5d63f1af893178e580167",
    "id": null,
    "metadata": {},
    "name": "CpCommonTopicsSectionQuery",
    "operationKind": "query",
    "text": "query CpCommonTopicsSectionQuery(\n  $id: ID!\n  $locale: Locale\n) {\n  node(id: $id) {\n    __typename\n    ... on Organization {\n      commonTopics {\n        _id\n        resourceNodeRevisionId\n        names(locale: $locale)\n        icon(locale: $locale)\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "55172c4f5e5cbaaaf8213f766266d034";

export default node;
