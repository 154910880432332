import { defineMessages } from "react-intl";
export default defineMessages({
  "Dr.": {
    id: "Cto0fN",
    defaultMessage: "Dr."
  },
  Miss: {
    id: "6axc3+",
    defaultMessage: "Miss"
  },
  "Mr.": {
    id: "b4CKrt",
    defaultMessage: "Mr."
  },
  "Mrs.": {
    id: "LRZZwl",
    defaultMessage: "Mrs."
  },
  "Ms.": {
    id: "nGvJX7",
    defaultMessage: "Ms."
  },
  "Mx.": {
    id: "V9c1Xs",
    defaultMessage: "Mx."
  },
  NP: {
    id: "QdgfOa",
    defaultMessage: "NP"
  },
  PA: {
    id: "4DPKVV",
    defaultMessage: "PA"
  },
  RN: {
    id: "PLaZSO",
    defaultMessage: "RN"
  },
  RPh: {
    id: "S0bvV6",
    defaultMessage: "RPh"
  },
  other: {
    id: "lF80C5",
    defaultMessage: "Other"
  }
});