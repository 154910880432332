import _styled from "styled-components";
import useScreenLayout from "hooks/useScreenLayout";
import React, { useCallback, useState } from "react";
import { GridItemWidths } from "components/home/tabs/CpGridItem";
import useAppTheme from "hooks/useAppTheme";
import { View } from "react-native";
import { css } from "services/Theme";

/**
 * Calculates its own layout space and renders a centred grid within its constraints.
 * Expects to have content based on GridItemWidths. Passing `itemWidth` can override responsive sizing of GridItem/Cards to ensure consistent layouts.
 */
const CpGrid = ({
  children,
  itemWidth,
  style
}) => {
  const {
    isWideScreen
  } = useScreenLayout();
  const {
    spacing
  } = useAppTheme();
  const [viewWidth, setViewWidth] = useState(0);
  const handleViewLayout = useCallback(event => {
    const layoutWidth = event.nativeEvent.layout.width;
    const responsiveItemWidth = isWideScreen ? GridItemWidths.large : GridItemWidths.small; // We need to calculate our grid width in order to ensure the contents can be centered correctly

    const gridItemWidth = (itemWidth ? GridItemWidths[itemWidth] : responsiveItemWidth) + spacing.double;
    const columns = Math.min(Math.floor(layoutWidth / gridItemWidth), 3);
    const newGridWidth = columns * gridItemWidth;
    setViewWidth(newGridWidth);
  }, [isWideScreen, itemWidth, spacing.double]);
  return <View onLayout={handleViewLayout} style={style}>
      <_StyledView $_css={viewWidth}>
        {children}
      </_StyledView>
    </View>;
};

export default CpGrid;

var _StyledView = _styled(View).withConfig({
  displayName: "CpGrid___StyledView",
  componentId: "sc-1w4gvx8-0"
})(["width:", "px;"], p => p.$_css);