/**
 * @generated SignedSource<<7ce473e37196deba447209f85a9d4b20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CpShortcutButton_resourceNode$data = {
  readonly _id: string;
  readonly ancestors: ReadonlyArray<{
    readonly _id: string;
    readonly names: any;
    readonly revisionId: string;
  }>;
  readonly names: any;
  readonly revisionId: string;
  readonly " $fragmentType": "CpShortcutButton_resourceNode";
};
export type CpShortcutButton_resourceNode$key = {
  readonly " $data"?: CpShortcutButton_resourceNode$data;
  readonly " $fragmentSpreads": FragmentRefs<"CpShortcutButton_resourceNode">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revisionId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "locale",
      "variableName": "locale"
    }
  ],
  "kind": "ScalarField",
  "name": "names",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "locale"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CpShortcutButton_resourceNode",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ResourceNode",
      "kind": "LinkedField",
      "name": "ancestors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/)
  ],
  "type": "ResourceNode",
  "abstractKey": null
};
})();

(node as any).hash = "f482da0b6cc19cd32ab40179981ec0c7";

export default node;
